import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import centerMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-middle.png";
import centerTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-top.png";
import centerBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-bottom.png";
import leftBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-bottom.png";
import leftMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-middle.png";
import leftTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-top.png";
import rightBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-bottom.png";
import rightMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-middle.png";
import rightTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-top.png";
import isScheduleNormal from "ScheduleChecker";
import Scheduling from "utils/Scheduling";
import ScheduleManager from "components/ScheduleManager";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Label
} from "reactstrap";

function AddEditCountdownForm() {
  const location = useLocation();
  const { state } = location;
  const item = state?.countdown;
  const user = useSelector(selectUser);
  const dispatch = useDispatch()
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes);
  const [currentWeek, setCurrentWeek] = useState("A");
  const [uploadAudioModal, setUploadAudioModal] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadedAudioFile, setUploadedAudioFile] = useState();
  const [youtubeURL, setYoutubeURL] = useState('');
  const [youtubeLinkModal, setYoutubeLinkModal] = useState(false);
  const [audioFileErrorMessage, setAudioFileErrorMessage] = useState('');
  const [alert, setAlert] = useState(null);

  const fromForm = (location.pathname === '/countdown-form');

  useEffect(() => {
    Scheduling.addWeeksToSchedule(setData, user, item?.classId);
  }, []);

  const modalOptions = [
    { value: "left-top", label: "Left-Top", image: leftTop },
    { value: "center-top", label: "Center-Top", image: centerTop },
    { value: "right-top-auto-music", label: "Right-Top", image: rightTop },
    { value: "left-middle", label: "Left-Middle", image: leftMiddle },
    { value: "center-middle", label: "Center-Middle", image: centerMiddle },
    { value: "right-middle-auto-music", label: "Right-Middle", image: rightMiddle },
    { value: "left-bottom", label: "Left-Bottom", image: leftBottom },
    { value: "center-bottom", label: "Center-Bottom", image: centerBottom },
    { value: "right-bottom-auto-music", label: "Right-Bottom", image: rightBottom },
  ];
  
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleSelect = (location) => {
    setData((prevData) => ({
      ...prevData,
      modalClassName: location,
    }));
  };

  const [data, setData] = useState({
    startTime: item?.startTime || "00:00",
    duration: item?.duration || 0,
    header: item?.header || '',
    active: item?.active === false ? false : item?.active === true ? true : true,
    alertMessage: item?.alertMessage || '',
    alertSound: item?.alertSound || 'none',
    alertSoundName: item?.alertSoundName || 'None',
    youtube: item?.youtube === false ? false : item?.youtube === true ? true : false,
    youtubeSrc: item?.youtubeSrc || '',
    days: [],
    schedule: item?.schedule || Scheduling.blankSchedule,
    modalClassName: item?.modalClassName || '',
    backdrop: item?.backdrop === false ? false : item?.backdrop === true ? true : true,
    customAudio: item?.customAudio === false ? false : item?.customAudio === true ? true : false,
    customAudioSrc: item?.customAudioSrc || '',
    customAudioName: item?.customAudioName || '',
    flash: item?.flash === false ? false : item?.flash === true ? true : false,
  });

  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const newCountdownTimer = {
  //       startTime: data.startTime,
  //       duration: data.duration,
  //       header: data.header,
  //       active: data.active,
  //       alertMessage: data.alertMessage,
  //       alertSound: data.alertSound,
  //       alertSoundName: data.alertSoundName,
  //       youtube: data.youtube,
  //       youtubeSrc: data.youtubeSrc,
  //       schedule: data.schedule,
  //       modalClassName: data.modalClassName,
  //       backdrop: data.backdrop,
  //       customAudio: data.customAudio,
  //       customAudioSrc: data.customAudioSrc,
  //       customAudioName: data.customAudioName
  //     };

  //     if (fromForm) {
  //       if (!userCopy.countdownTimers) {
  //         userCopy.countdownTimers = [];
  //       }

  //       if (item === undefined) {
  //         userCopy.countdownTimers.push(newCountdownTimer);
  //       } else {
  //         userCopy.countdownTimers[state.index] = data;
  //       }
  //     } else {
  //       if (!userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers) {
  //         userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers = [];
  //       }

  //       if (item === undefined) {
  //         userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers.push(newCountdownTimer);
  //       } else {
  //         userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers[state.index] = data;
  //       }
  //     }

  //     await setDoc(docRef, userCopy);
  //     successAlert();
  //   } catch (error) {
  //     window.alert('Error updating document:', error);
  //   } 
  // };

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    if (!user?.uid) {
      console.error("User ID is missing.");
      return;
    }

    // Construct the new countdown timer object
    const newCountdownTimer = {
      startTime: data.startTime,
      duration: data.duration,
      header: data.header,
      active: data.active,
      alertMessage: data.alertMessage,
      alertSound: data.alertSound,
      alertSoundName: data.alertSoundName,
      youtube: data.youtube,
      youtubeSrc: data.youtubeSrc,
      schedule: data.schedule,
      modalClassName: data.modalClassName,
      backdrop: data.backdrop,
      customAudio: data.customAudio,
      customAudioSrc: data.customAudioSrc,
      customAudioName: data.customAudioName,
    };

    if (fromForm) {
      // **Global countdownTimers at the user level**
      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        console.error("User document not found.");
        return;
      }

      const userData = userSnap.data();
      let updatedCountdownTimers = userData.countdownTimers || [];

      if (item === undefined) {
        updatedCountdownTimers.push(newCountdownTimer);
      } else {
        updatedCountdownTimers[state.index] = data;
      }

      await updateDoc(userRef, { countdownTimers: updatedCountdownTimers });
    } else {
      // **Countdown Timers inside a specific content box**
      if (!state.classId || !contentBoxId) {
        console.error("Class ID or Content Box ID is missing.");
        return;
      }

      const contentBoxRef = doc(
        db,
        FIREBASE_COLLECTIONS.USERS,
        user.uid,
        FIREBASE_COLLECTIONS.CLASSES,
        state.classId,
        FIREBASE_COLLECTIONS.CONTENT_BOXES,
        contentBoxId
      );

      const contentBoxSnap = await getDoc(contentBoxRef);
      if (!contentBoxSnap.exists()) {
        console.error("Content box not found.");
        return;
      }

      let countdownTimers = contentBoxSnap.data().countdownTimers || [];

      if (item === undefined) {
        countdownTimers.push(newCountdownTimer);
      } else {
        countdownTimers[state.index] = data;
      }

      await updateDoc(contentBoxRef, { countdownTimers });
    }

    // 🛑 Manually re-fetch user data after updating Firestore
    await dispatch(fetchUserData(user.uid));

    successAlert();
    console.log("Countdown Timer updated successfully!");
  } catch (error) {
    console.error("Error updating document:", error);
    window.alert("Error updating document: " + error.message);
  }
};


  const potentialInvalidScheduleAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => (data.duration < 10) ? durationSetToZeroAlert(event) : handleSubmit(event)}
        onCancel={() => hidePotentialInvalidScheduleAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        It appears as though your scheduled start time(s) for this countdown timer aren't typical.
      </ReactBSAlert>
    );
  }

  const durationSetToZeroAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => handleSubmit(event)}
        onCancel={() => hideDurationSetToZeroAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        {`Your duration is set to ${data.duration}, which means your timer will only countdown from ${data.duration} seconds.`}
      </ReactBSAlert>
    );
  }

  const hidePotentialInvalidScheduleAlert = () => {
    setAlert(null);
  }

  const hideDurationSetToZeroAlert = (e) => {
    setAlert(null);
  }

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Countdown Timer Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes#timers");
  };

  const handleSingleStartTime = (e) => {
    const startTime = e.target.value;
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));

    for (const group in updatedSchedule) {
      for (const day in updatedSchedule[group]) {
        updatedSchedule[group][day] = { ...updatedSchedule[group][day], startTime };
      }
    }

    setData({ ...data, schedule: updatedSchedule, startTime: startTime });
  };

  const handleFormNonDays = (e) => {
    const { id, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleAudioChange = (e) => {
    const { alertSound, alertSoundName, youtube, customAudio } = JSON.parse(e.target.value);

    if (youtube === true) {
      setData((prevData) => ({
        ...prevData,
        customAudio: false,
        youtube: true,
        alertSoundName: alertSoundName
      }));
    } else if (customAudio === true) {
      setData((prevData) => ({
        ...prevData,
        customAudio: true,
        youtube: false,
        alertSoundName: alertSoundName
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        alertSound: alertSound,
        alertSoundName: alertSoundName,
        youtube: false,
        customAudio: false
      }));
    }
  };

  const handleScheduleChange = (week, day, field, value) => {
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    updatedSchedule[week][day][field] = value;
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  const toggleYoutubeLinkModal = () => {
    setYoutubeLinkModal(!youtubeLinkModal);
  };

  const handleYoutubeLinkSave = () => {
    setData((prevData) => ({
      ...prevData,
      customAudio: false,
      youtube: true,
      youtubeSrc: youtubeURL,
    }));
    toggleYoutubeLinkModal();
  };

  const toggleUploadAudioModal = () => {
    setUploadAudioModal(!uploadAudioModal);
  };

  const handleUploadAudioButtonClick = () => {
    fileInputRef.current.click();
  };

  const allowedTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
  const maxSize = 20 * 1024 * 1024; // 20MB

  const handleUploadAudioFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        window.alert('Please select a valid audio file (MP3, WAV, OGG).');
        return;
      }

      if (file.size > maxSize) {
        window.alert('File size exceeds the 20MB limit.');
        return;
      }

      writeAudioFileToFirebase(file);
    }
  };

  const writeAudioFileToFirebase = async (audioFile) => {
    if (audioFile == null) return;
    const audioRef = ref(storage, `users/${user.uid}/${audioFile.name}`);
    uploadBytes(audioRef, audioFile).then((snapshot) => {
      setData((prevData) => ({
        ...prevData,
        customAudioName: snapshot.metadata.name,
        customAudio: true
      }));
      getDownloadURL(snapshot.ref).then((url) => {
        setData((prevData) => ({
          ...prevData,
          alertSound: url,
          customAudioSrc: url,
          youtube: false
        }));
        setUploadedAudioFile(url);
      });
    });
  };

  return (
    <>
      <Modal isOpen={youtubeLinkModal} modalClassName="youtubeLinkModal">
        <ModalHeader>Insert YouTube Link</ModalHeader>
        <ModalBody>
          <Row>
            <Input
              type="url"
              placeholder="Enter YouTube URL"
              value={youtubeURL}
              onChange={(e) => { setYoutubeURL(e.target.value);  }}
              style={{ marginTop: '10px' }}
            />
          </Row>
          <Row>
            <Button onClick={toggleYoutubeLinkModal}>Cancel</Button>
            <Button onClick={handleYoutubeLinkSave}>Save</Button>
          </Row>
        </ModalBody>
      </Modal>

      <div className="content">
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: fromForm ? '100vh' : null }}>
          <Col md={fromForm ? '8' : null}>
            <Card style={{ padding: '20px' }}>
              <ModalHeader className="add-edit-form-close-toggle" toggle={() =>  history.goBack()} style={{border: 'none', padding: '0px'}}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
                  {item === undefined ? "Add Countdown Timer" : "Edit Countdown Timer"}
                </h4>
              </ModalHeader>
              <CardBody>
                <Form className="form-horizontal" noValidate validated={validated}>
                  <Row>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold"}}>Header Message</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: You should be in your seat by:"
                          type="text"
                          id="header"
                          value={data.header}
                          valid={data?.header.length <= 30}
                          invalid={data?.header.length > 30}
                          style={{borderRadius: '8px'}}
                        />
                        {data.header.length > 30 ? (
                          <FormFeedback invalid>Header Message is too long (max 30 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold"}}>Alert Message</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: TIME'S UP!"
                          type="text"
                          id="alertMessage"
                          value={data.alertMessage}
                          valid={data.alertMessage.length <= 30}
                          invalid={data.alertMessage.length > 30}
                          style={{borderRadius: '8px'}}
                        />
                        {data.alertMessage.length > 30 ? (
                          <FormFeedback invalid>Alert Message is too long (max 30 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-evenly' }}>
                    {!differentTimes && (
                      <Col>
                        <UncontrolledTooltip delay={0} target={"startTime"} placement="top">
                          This is the time you want the countdown timer to appear on the screen and start counting down.
                        </UncontrolledTooltip>
                        <label id="startTime" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Start Time</label>
                        <FormGroup>
                          {/* <Input required onChange={(e) => handleSingleStartTime(e)} type="time" id="startTime" value={data.startTime} style={{borderRadius: '8px'}} /> */}
                          <Input
                            required
                            onChange={(e) => handleSingleStartTime(e)}
                            type="time"
                            id="startTime"
                            value={data.schedule["A"]["Monday"].startTime}
                            step="1"  // Allows seconds input
                            style={{ borderRadius: '8px' }}
                          />        
                        </FormGroup>
                      </Col>
                    )}
                    <UncontrolledTooltip delay={0} target={"duration"} placement="top">
                      This is how long in seconds you want your timer to last.
                    </UncontrolledTooltip>
                    <Col>
                      <label id="duration" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Duration</label>
                      <FormGroup>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <Input required onChange={(e) => handleFormNonDays(e)} type="number" min={1} max={3600} id="duration" value={data.duration} style={{borderRadius: '8px'}}/>
                          <span style={{ fontSize: "10px", fontWeight: "lighter" }}>seconds</span>
                        </div>
                      </FormGroup>
                    </Col>
                    <UncontrolledTooltip delay={0} target={"alertSound"} placement="top">
                      Select the tone that you want to play at the end of the timer
                    </UncontrolledTooltip>
                    <Col>
                      <label id="locationTip" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Location</label>
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} style={{ width: "200px" }}>
                        <DropdownToggle 
                          caret 
                          style={{ 
                            borderRadius: "8px", 
                            width: "100%", 
                            margin: '0px', 
                            backgroundColor: 'white',
                            border: '1px solid #DDDDDD',
                            color: '#66615b',
                          }}
                        >
                          {modalOptions.find((option) => option.value === data.modalClassName)?.label || "Select Position"}
                        </DropdownToggle>
                        <DropdownMenu className="countdown-timer-modal-location-picker">
                          {modalOptions.map((option) => (
                            <DropdownItem
                              key={option.value}
                              value={option.value}
                              onClick={() => handleSelect(option.value)} 
                              style={{ 
                                display: "flex", 
                                flexDirection: "column",
                                alignItems: "center", 
                                justifyContent: "center", 
                                padding: "2px 10px 5px 10px", 
                                textAlign: "center" 
                              }}
                            >
                              <span style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "5px" }}>
                                {option.label}
                              </span>

                              {dropdownOpen && ( // ✅ Only render images when dropdown is open to avoid page height issue
                                <img
                                  src={option.image}
                                  alt={option.label}
                                  style={{ width: "auto", height: "85px" }}
                                />
                              )}

                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                    <Col>
                      <label id="alertSound" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Alert Sound</label>
                      <FormGroup>
                        <Input 
                          onChange={(e) => handleAudioChange(e)} 
                          type="select" 
                          id="alertSound"
                          style={{borderRadius: '8px'}}
                          value={
                            data.youtube ? 
                              JSON.stringify({ youtubeSrc: data.youtubeSrc, youtube: true })
                            : 
                              JSON.stringify({ alertSound: data.alertSound, alertSoundName: data.alertSoundName })
                          }                          
                        >
                          <option value={JSON.stringify({ alertSound: "none", alertSoundName: "None" })} label="None">None</option>
                          <option value={JSON.stringify({ alertSound: "https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Falarm-sounds%2FAlarm-Short.mp3?alt=media&token=b988211b-30b7-4108-a7ad-426dcf2464f4", alertSoundName: "Short" })} label="Short">Short</option>
                          <option value={JSON.stringify({ alertSound: "https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Falarm-sounds%2FAlarm-Medium.mp3?alt=media&token=7a8ab57d-981b-44a1-924c-b002a1979a81", alertSoundName: "Medium" })} label="Medium">Medium</option>
                          <option value={JSON.stringify({ alertSound: "https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Falarm-sounds%2FAlarm-Long.mp3?alt=media&token=cce14722-fac4-4793-a7e5-abe75ee85799", alertSoundName: "Long" })} label="Long">Long</option>
                          {data?.customAudioSrc?.length > 0 && <option value={JSON.stringify({ alertSound: data.customAudioSrc, alertSoundName: data.customAudioName, customAudio: true })} label={data.customAudioName}>{data.customAudioName}</option>}
                          {data?.youtubeSrc?.length > 0 && <option value={JSON.stringify({ alertSound: data.youtubeSrc, alertSoundName: data.youtubeSrc, youtube: true })} label={data.youtubeSrc}>{data.youtubeSrc}</option>}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="uploadAlertSound" style={{ fontSize: '13px', display: 'flex', alignItems: 'left', flexDirection: 'column', fontWeight: "bold" }}>Upload Audio</label>
                      <Row>
                        <Col>
                          <i className="fa fa-upload" style={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => handleUploadAudioButtonClick()}></i>
                          <input
                            type="file"
                            accept=".mp3,.wav,.ogg"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleUploadAudioFileChange}
                          />
                        </Col>
                        <Col>
                          <i className="fa fa-youtube" style={{ fontSize: '32px', cursor: 'pointer' }} onClick={toggleYoutubeLinkModal}></i>
                        </Col>
                      </Row>
                      {audioFileErrorMessage && <p style={{ color: 'red' }}>{audioFileErrorMessage}</p>}
                      <UncontrolledTooltip delay={0} target={"uploadAlertSound"} placement="top">
                        Upload custom audio to play at the end of your countdown.
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "30px", height: '60px', display: 'flex', justifyContent: 'space-evenly' }}>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="flashTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Flash</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"flashTip"} placement="top">
                          If checked, the screen will flash at the conclusion of the timer.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="flash"
                          checked={data.flash}
                          defaultChecked={false}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="backgroundTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Background</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"backgroundTip"} placement="top">
                          If checked, the background behind your timer will be darker.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="backdrop"
                          checked={data.backdrop}
                          defaultChecked={true}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="activeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Active</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"activeTip"} placement="top">
                          If checked, this timer will activate at the time and day requested.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="active"
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>

                  <ScheduleManager 
                    data={data} 
                    handleScheduleChange={handleScheduleChange} 
                    user={user} 
                  />
                  
                </Form>
              </CardBody>
              <CardFooter style={{display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: '20px'}}>
                <Button 
                  className="btn-round button-hover-fix"
                  color="info"
                  type="submit"
                  onClick={(e) => (isScheduleNormal(data.schedule) && data.duration >= 10) ? handleSubmit(e) : isScheduleNormal(data.schedule) === false ? potentialInvalidScheduleAlert(e) : data.duration < 10 ? durationSetToZeroAlert(e) : null}
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}>
                    <i className='fa fa-save' />
                    {' '}Save  
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>


      <Modal
            isOpen={uploadAudioModal}
            toggle={toggleUploadAudioModal}
            backdrop={true}
            scrollable
            style={{ padding: '20px' }}
          >
            <ModalHeader toggle={toggleUploadAudioModal}></ModalHeader>
            <ModalBody>
              <Input type="file"></Input>
            </ModalBody>
            <Button onClick={null}>Save Clock Version</Button>
          </Modal>
    </>
  );
}

export default AddEditCountdownForm;

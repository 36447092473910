import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Scheduling from "/Users/michaeltucker/Documents/coding-projects/client/src/utils/Scheduling.js"
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CardSubtitle,
  Badge
} from "reactstrap";
import { NavLink } from 'react-router-dom';
import ReactBSAlert from "react-bootstrap-sweetalert";

function AutoMusicTable() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch()
  const [alert, setAlert] = useState(null);
  const multiWeek = user.settings?.multiWeek || false;
  const numberOfWeeks = user.settings?.numberOfWeeks || 2;
  const cycleType = user.settings?.cycleType || "week";
  const differentTimes = user.settings.differentTimes || false;

  const warningWithConfirmAndCancelMessage = (segmentId) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => deleteMusicSegment(segmentId)}
        onCancel={() => cancelDelete()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this Music Segment! This will NOT delete the playlist associated with this segment.
      </ReactBSAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your music segment has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your music segment is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // const deleteMusicSegment = async (segmentId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const segmentIndex = user.autoMusic.segments.findIndex((segment) => segment.segmentId === segmentId);
  
  //     if (segmentIndex != -1) {
  //       // Remove the class at the specified index
  //       userCopy.autoMusic.segments.splice(segmentIndex, 1);
  //     }
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //     successDelete()
  
  //     console.log('Music segment deleted successfully!');
  //   } catch (error) {
  //     console.error('Error deleting music segment:', error);
  //   }
  // }

  const deleteMusicSegment = async (segmentId) => {
    try {
        if (!user?.uid || !segmentId) {
            console.error("❌ Missing required parameters: User ID or Segment ID.");
            return;
        }

        // Reference the user document in Firestore
        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

        // Fetch the user document
        const userSnap = await getDoc(userRef);
        if (!userSnap.exists()) {
            console.error("❌ User document not found.");
            return;
        }

        const userData = userSnap.data();

        // Ensure autoMusic exists and contains segments
        if (!userData.autoMusic || !Array.isArray(userData.autoMusic.segments)) {
            console.error("❌ autoMusic or segments not found in user document.");
            return;
        }

        // Remove the segment with the specified segmentId
        const updatedSegments = userData.autoMusic.segments.filter(segment => segment.segmentId !== segmentId);

        // Update Firestore with the modified segments array
        await updateDoc(userRef, {
            "autoMusic.segments": updatedSegments,
        });

        await dispatch(fetchUserData(user.uid));

        successDelete();
        console.log(`✅ Music segment (${segmentId}) deleted successfully!`);
    } catch (error) {
        console.error("❌ Error deleting music segment:", error);
    }
  };

  return (
    <>
      {alert}
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Music</h2>
              </CardHeader>
              <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                Want music to play between classes? With Auto Music you can setup a playlist and music will play during the times you set up.
              </CardSubtitle>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center" id="startTimeTipAutoMusicTable" style={{width: '10%'}}>Start Time</th>
                      <th className="text-center" id="durationTipAutoMusicTable" style={{width: '20%'}}>Duration</th>
                      <th className="text-center" id="daysActiveTipAutoMusicTable" style={{width: '20%'}}>Days Active</th>
                      <th className="text-center" id="playlistTipAutoMusicTable" style={{width: '20%'}}>Playlist</th>
                      <th className="text-center" id="wertshowVideoTipAutoMusicTable" style={{width: '10%'}}>Show Video</th>
                      <th className="text-center" id="hjfgkactiveTipAutoMusicTable" style={{width: '10%'}}>Active</th>
                      <th className="text-center" style={{ width: '10%', paddingBottom: '0px' }}>
                        Actions
                        <div style={{ marginTop: '0px', fontSize: '0.7em', color: '#666', display: 'flex', justifyContent: 'center', gap: '12px' }}>
                          <span>Edit</span>
                          <span>Delete</span>
                        </div>
                      </th>
                    </tr>
                    {user.autoMusic && user.autoMusic.segments && user.autoMusic.segments.length > 0 && (
                      <>
                        <UncontrolledTooltip delay={0} target={"startTimeTipAutoMusicTable"}>The time this music segment will start playing.</UncontrolledTooltip>
                        <UncontrolledTooltip delay={0} target={"durationTipAutoMusicTable"}>The amount of time the music segment will play for.</UncontrolledTooltip>
                        <UncontrolledTooltip delay={0} target={"daysActiveTipAutoMusicTable"}>The days the music segment will play on.</UncontrolledTooltip>
                        <UncontrolledTooltip delay={0} target={"playlistTipAutoMusicTable"}>The playlist of music the music segment will play from.</UncontrolledTooltip>
                        <UncontrolledTooltip delay={0} target={"wertshowVideoTipAutoMusicTable"}>If checked, the YouTube video will play on screen, otherwise the music will play without the video on screen.</UncontrolledTooltip>
                        <UncontrolledTooltip delay={0} target={"hjfgkactiveTipAutoMusicTable"}>If checked the music segment will be turned on and launched at the selected start time on any selected day.</UncontrolledTooltip>
                      </>
                    )}
                  </thead>
                  {user && user?.autoMusic?.segments?.map((segment, index) => {
                    return(
                      <tbody key={index}>
                        <tr>
                          <td className="text-center">{Scheduling.listActiveStartTimes(segment.schedule, user)}</td>
                          <td className="text-center">
                            {Math.floor(segment.duration / 60)} minutes {segment.duration % 60} seconds
                          </td>
                          <td className={multiWeek && cycleType === "week" ? "text-left" : "text-center"}>
                          {multiWeek && cycleType === "day" ? (
                            <div>
                              {Scheduling.getWeeksWithActiveMonday(segment.schedule, user)}
                            </div>
                          ) : (
                            <>
                              {!multiWeek && segment.schedule?.A ? (
                                <div>
                                  {Scheduling.dayOrder
                                    .filter(day => 
                                      segment.schedule.A[Object.keys(Scheduling.dayMapping).find(key => Scheduling.dayMapping[key] === day)]?.active
                                    )
                                    .join(', ') || "--"}
                                </div>
                              ) : null}

                              {multiWeek &&
                                Scheduling.weeksOrder(numberOfWeeks).map((week) => (
                                  <div key={week}>
                                    <strong>
                                      {(cycleType ?? "week") === "week" ? "Week" : "Day"} {week}:
                                    </strong>{" "}
                                    {Scheduling.dayOrder
                                      .filter((day) =>
                                      segment.schedule?.[week]?.[Object.keys(Scheduling.dayMapping).find((key) => Scheduling.dayMapping[key] === day)]?.active
                                      )
                                      .join(', ') || "--"}
                                  </div>
                                ))}
                            </>
                          )}
                          </td>                        
                          <td 
                            className="text-center"
                            style={{
                              backgroundColor: !user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) ? "#ffcccc" : "inherit",
                              color: !user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) ? "red" : "inherit",
                              fontWeight: !user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) ? "bold" : "normal",
                              padding: "10px",
                            }}
                          >
                            {user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId) 
                              ? user.autoMusic.playlists.find((playlist) => playlist.playlistId === segment.playlistId)?.name 
                              : (
                                <>
                                  <i className="fa fa-exclamation-triangle" style={{ marginRight: "5px" }} /> {/* 🚨 Warning Icon */}
                                  <span>Missing Playlist</span>
                                </>
                              )
                            }
                          </td>
                          <td className="text-center">
                            {segment.showVideo ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}
                          </td>                          
                          <td className="text-center">
                            {segment.active ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}
                          </td>                          
                          <td className="text-center">
                          <NavLink to={{ pathname: "/music-form", state: { segment } }}>
                            <Button
                              className="btn-icon btn-neutral"
                              id="music-table-tooltipe029319235"
                              color="info"
                              size="md"
                              type="button"
                              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                              <i className="fa fa-edit icon-edit" />
                            </Button>
                          </NavLink>
                            <Button
                              className="btn-icon btn-neutral"
                              color="danger"
                              id="music-table-tooltip808966390"
                              size="md"
                              type="button"
                              onClick={() => warningWithConfirmAndCancelMessage(segment.segmentId)}
                              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                              <i className="fa fa-trash icon-delete" />
                            </Button>
                            {/* <UncontrolledTooltip
                              delay={0}
                              target="tooltip808966390"
                            >
                              Delete
                            </UncontrolledTooltip> */}
                          </td>
                        </tr>
                      </tbody>
                    )
                  })}
                </Table>
                <NavLink to={{pathname: "/music-form"}} style={{color: 'white'}}>
                  <Button
                    className="button-hover-fix"
                    style={{
                      padding: "10px 20px",
                      borderRadius: "20px",
                      fontSize: ".9rem",
                      fontWeight: "bold",
                      width: '185px'
                    }}
                  >
                  <i className='fa fa-plus' />
                            {' '}Add Music
                  </Button>
                </NavLink>
              </CardBody>
            </Card>
          </Col>         
        </Row>
      </div>
    </>
  );
}

export default AutoMusicTable;

import React, { useState, useEffect }from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, selectClasses, selectClassContentBoxes, fetchUserData } from "features/userSlice";
import Present from "views/Present.js"

var ps;

function Share(props) {
  const location = useLocation()
  const [classRoutes, setClassRoutes] = useState(null);
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [activeColor, setActiveColor] = React.useState("info");
  const dispatch = useDispatch()
  const user = useSelector(selectUser);
  const classes = useSelector(selectClasses);
  const routes = [
    {
      "collapse": true,
      "name": "Present Classes",
      "icon": "nc-icon nc-book-bookmark",
      "state": "pagesCollapse",
    },
  ]

  useEffect(() => {
    if (user) getClassRoutes();
  }, [user?.shareCode, classes]);

  useEffect(() => {
    const shareCode = location.pathname.match(/\/share\/([a-zA-Z0-9]{6})/)?.[1];
    if (!shareCode || user) return; // ✅ Only fetch if user is null
  
    const fetchUser = async () => {
      try {
        const userID = await getUserIDforShareCode(shareCode);
        if (userID && !user) {
          dispatch(fetchUserData(userID));
        }
      } catch (error) {
        console.error("Error fetching user ID for share code:", error);
      }
    };
    fetchUser();
  }, [user, location.pathname]);  

  const getUserIDforShareCode = async (shareCode) => {
    try {
      const docRef = doc(db, "shareCodes", shareCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const shareCodeDataFetch = docSnap.data();
        // console.log("shareCodeDataFetch => ", shareCodeDataFetch);
        return shareCodeDataFetch.uid; // Assuming userID is part of the document data
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  // const fullPages = React.useRef();
  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(fullPages.current);
  //   }
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });

  const mainPanel = React.useRef();

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // Check if mainPanel.current is defined before initializing PerfectScrollbar
      if (mainPanel.current) {
        ps = new PerfectScrollbar(mainPanel.current);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        // Check if ps is defined before destroying it
        if (ps) {
          ps.destroy();
        }
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, [mainPanel]); // Include mainPanel in the dependency array

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    if (mainPanel.current) {
      mainPanel.current.scrollTop = 0;
    }
  }, [location.pathname]); // ✅ Only re-run when the path actually changes  

  const getClassRoutes = () => {
    const classRoutesArray = [];
    user && classes && classes.map((item) => {
      // Limit the name to 23 characters and add "..." if it exceeds that length
      const truncatedName = item.className
        ? item.className.length > 23
          ? item.className.substring(0, 23) + "..."
          : item.className
        : item.classId; //If className is null or undefined, use classId instead.
  
      const classPath = "/" + item.classId;

      classRoutesArray.push({
        path: "/share/" + user.shareCode + classPath,
        name: truncatedName, // Use the truncated name
        mini: item.period != null ? item.period.replace(/ .*/, "") : ">",
        component: Present,
        layout: "/share",
        classId: item.classId,
        showMarquee: item.showMarquee,
        showClock: item.showClock,
        visible: item.active ? true : false,
      });
    });
    const newRoutes = [...routes];
    newRoutes[0].views = classRoutesArray;
    setClassRoutes((prevRoutes) => {
      const prevPaths = prevRoutes?.[0]?.views?.map(route => route.path) || [];
      const newPaths = classRoutesArray.map(route => route.path);
    
      if (JSON.stringify(prevPaths) !== JSON.stringify(newPaths)) {
        return [{ ...routes[0], views: classRoutesArray }];
      }
      return prevRoutes; // Prevent unnecessary re-renders
    });
  };

  const getRoutes = (routes) => {
    return routes && routes?.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/share") {
        return (
          <Route
            path={prop.layout + "/" + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
     <div className="wrapper">
        <Sidebar
          {...props}
          routes={classRoutes}
          bgColor={backgroundColor}
          activeColor={activeColor}
          navigationVisible={true}
        />
        <div className={"main-panel"} ref={mainPanel} style={{background: ''}}>
          {/* <AdminNavbar {...props} handleMiniClick={handleMiniClick} /> */}
          <Switch>
              <Route  
                path="/share" 
                render={(routeProps) => (
                  <Present 
                    {...routeProps} 
                    props={{ 
                      classId: classes?.classId, 
                      showMarquee: classes?.showMarquee, 
                      showClock: classes?.showClock, 
                    }}
                  />
                )} 
              />
            {getRoutes(classRoutes)}
            {/* <Redirect exact from="/" to={classRoutes[0].views[0].path} /> */}
          </Switch>
        </div>
      </div>
    </>
  );
}

export default Share;

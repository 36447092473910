import React from "react";

const InfoBubbleButton = ({ onClick, id }) => {
  return (
        <div
            id={id}
            style={{
                border: "2px solid lightgrey",
                borderRadius: "10px",
                padding: "1px 6px",
                cursor: "pointer",
                margin: "2px",
                color: "grey"
            }}
            onMouseEnter={(e) => (e.currentTarget.style.border = "2px solid grey")}
            onMouseLeave={(e) => (e.currentTarget.style.border = "2px solid lightgrey")}
            onClick={onClick}
        >
        <i className="fa fa-question"></i>
        </div>
    );
};

export default InfoBubbleButton;

/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState, useEffect} from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { auth } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import SidebarNotificationAndAlert from "./SidebarNotificationAndAlert";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";

var ps;

function Sidebar(props) {
  const [collapseStates, setCollapseStates] = React.useState({});
  const sidebar = React.useRef();
  const history = useHistory();
  const [routeForHeader, setRouteForHeader] = useState("");

  const currentPath = useLocation().pathname

  useEffect(() => {
    // Check if props.routes is defined before accessing it
    if (props.routes && props.routes[0] && props.routes[0].views && props.routes[0].views[0]) {
      const headerRoute = "https://www.lessonlaunchpad.com" + props.routes[0].views[0].path;
      setRouteForHeader(headerRoute);
    }
  }, [props.routes]);

  const [notificationAndAlertData, setNotificationAndAlertData] = useState();

  useEffect(() => {
    const docRef = doc(db, "sidebarNotificationAndAlert", "dataModel");
  
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
  
        setNotificationAndAlertData(data);
      } else {
        console.log("No such document!");
      }
    });
  
    return () => unsubscribe();
  }, []);  

  console.log(notificationAndAlertData)

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes && routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.log(`Error attempting to enable fullscreen: ${err.message}`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
    setIsFullscreen(!isFullscreen);
  };

  const handleClick = () => {
    auth.signOut()
      .then(() => {
        history.push("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log("Logout FAILED!!!");
        console.error(error);
      });
  };

  function scroll() {
    const scrollable = document.querySelector(".collapse-scroll");
  
    // Check if the element is already scrolled to the bottom
    const atBottom = scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight;
  
    if (atBottom) {
      // If at the bottom, scroll to the top
      scrollable.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      // Otherwise, scroll down
      scrollable.scrollBy({ top: 100, behavior: "smooth" });
    }
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes && routes.map((prop, key) => {
      let path = prop.name === 'Fullscreen' ? currentPath.pathname : prop.path;
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];
        return (
          <li
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={collapseStates[prop.state]} className="collapse-scroll">

              {/* These are the classes in the Present Classes dropdown */}
              <ul className="nav">{createLinks(prop.views)}</ul>

              {/* Render No Classes alert while not collapsed if there are no classes */}
              {prop.views.length === 0 && collapseStates[prop.state] && (
                <div style={{marginLeft: '50px'}}>
                  <ul><em>No Classes</em></ul>
                </div>
              )}
            </Collapse>
            {prop.views.length > 8 && collapseStates[prop.state] &&
                <div className="scroll-text-in-sidebar" onClick={scroll}>
                  Scroll or click for more classes...
                </div>
            }
          </li>
        );
      }

      return (
        !!(prop.visible) && ( // Use '!!' to cast to boolean
          <li className={activeRoute(prop.path)} key={key}>
            <NavLink
              to={{pathname: path, state: {classId: prop.classId, showMarquee: prop.showMarquee, showClock: prop.showClock}}}
              activeClassName=""
              onClick={() => {
                if (prop.name === 'Fullscreen') {
                  toggleFullscreen();
                } else if (prop.path === '/auth/logout') {
                  handleClick();
                }
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </NavLink>
          </li>
        )
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  React.useEffect(() => {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
    return function cleanup() {
      // we need to destroy the false scrollbar when we navigate
      // to a page that doesn't have this component rendered
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });

  React.useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
    createLinks()
  }, [props]);

  return (
    <div
      className={props.navigationVisible ? "sidebar" : "sidebar-hidden"}
      data-color={props.bgColor}
      data-active-color={props.activeColor}
      // onMouseEnter={() => setIsExpanded(true)}
      // onMouseLeave={() => setIsExpanded(false)}
      // style={{display: user.settings.hideBarsWhenFullscreen && isFullscreen && 'none' || null}}
    >
      <div className="logo">
        <a
          href="https://www.lessonlaunchpad.com"
          className="simple-text logo-mini"
        >
          <div className="logo-img-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
            <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto' }} />
            <SidebarNotificationAndAlert data={notificationAndAlertData}/>
          </div>
        </a>

        <a href={routeForHeader} className="simple-text logo-normal">
          Lesson Launchpad
        </a>
      </div>

      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>{createLinks(props.routes)}</Nav>
      </div>
    </div>
  );
}

export default Sidebar;

import {React, useState} from "react";
import {NavLink, Route} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { setDoc, doc, getDoc, updateDoc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Scheduling from "/Users/michaeltucker/Documents/coding-projects/client/src/utils/Scheduling.js"
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CardSubtitle,
  Badge
} from "reactstrap";

function AutoReminderTable() {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const dispatch = useDispatch()
    const multiWeek = user.settings?.multiWeek || false;
    const numberOfWeeks = user.settings?.numberOfWeeks || 2;
    const cycleType = user.settings?.cycleType || "week";
    const differentTimes = user.settings.differentTimes || false;

    const warningWithConfirmAndCancelMessage = (index) => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteReminder(index)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this timer!
        </ReactBSAlert>
      );
    };
  
    const successDelete = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your reminder has been deleted.
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your reminder is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    // const deleteReminder = async (index) => {
    //   try {
    //     const docRef = doc(db, "users", user.uid);
    
    //     const userCopy = JSON.parse(JSON.stringify(user));
    
    //     // Remove the class at the specified index
    //     userCopy.reminders.splice(index, 1);
    //     console.log(userCopy)
    
    //     // Update the document in Firestore
    //     await setDoc(docRef, userCopy);
  
    //     successDelete()
    
    //     console.log('Countdown Timer deleted successfully!');
    //   } catch (error) {
    //     console.error('Error deleting content box:', error);
    //   }
    // }

  const deleteReminder = async (index) => {
    try {
        if (!user?.uid) {
            console.error("❌ User ID is missing.");
            return;
        }

        // 🔹 Reference the user document in Firestore
        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

        // 🔹 Fetch the current user document
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("❌ User document not found.");
            return;
        }

        // 🔹 Get current reminders (ensure it exists)
        const userData = userSnap.data();
        const reminders = userData.reminders || [];

        if (index < 0 || index >= reminders.length) {
            console.error("❌ Invalid index for reminder deletion.");
            return;
        }

        // 🔹 Remove the reminder at the specified index
        const updatedReminders = reminders.filter((_, i) => i !== index);

        // 🔹 Update Firestore document with the modified reminders array
        await updateDoc(userRef, { reminders: updatedReminders });

        // 🛑 Manually re-fetch user data after updating Firestore
        await dispatch(fetchUserData(user.uid));

        console.log("✅ Reminder deleted successfully!");
        successDelete(); // Trigger success alert
    } catch (error) {
        console.error("❌ Error deleting reminder:", error);
    }
  };

    // const duplicateReminder = async (index) => {
    //   try {
    //     const docRef = doc(db, "users", user.uid);
    
    //     const userCopy = JSON.parse(JSON.stringify(user));
    
    //     // Make a copy of the selected reminder
    //     const reminderCopy = {...userCopy.reminders[index]};

    //     // Add copy to the reminderCopy array
    //     userCopy.reminders.push(reminderCopy);

    //     // Update the document in Firestore
    //     await setDoc(docRef, userCopy);
      
    //   } catch (error) {
    //     console.error('Error deleting content box:', error);
    //   }
    // }

    const duplicateReminder = async (index) => {
      try {
          if (!user?.uid) {
              console.error("❌ User ID is missing.");
              return;
          }
  
          // 🔹 Reference the user document in Firestore
          const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
  
          // 🔹 Fetch the current user document
          const userSnap = await getDoc(userRef);
  
          if (!userSnap.exists()) {
              console.error("❌ User document not found.");
              return;
          }
  
          // 🔹 Get current reminders (ensure it exists)
          const userData = userSnap.data();
          const reminders = userData.reminders || [];
  
          if (index < 0 || index >= reminders.length) {
              console.error("❌ Invalid index for reminder duplication.");
              return;
          }
  
          // 🔹 Make a copy of the selected reminder with a new unique ID
          const reminderCopy = { ...reminders[index], id: crypto.randomUUID() };
  
          // 🔹 Append the duplicated reminder
          const updatedReminders = [...reminders, reminderCopy];
  
          // 🔹 Update Firestore document with the new reminders array
          await updateDoc(userRef, { reminders: updatedReminders });
  
          console.log("✅ Reminder duplicated successfully!");

          // 🛑 Manually re-fetch user data after updating Firestore
          await dispatch(fetchUserData(user.uid));
          
      } catch (error) {
          console.error("❌ Error duplicating reminder:", error);
      }
    };

    function convertTo12HourFormat(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(':').map(Number);
    
      // Determine whether it's AM or PM
      const period = hours >= 12 ? 'PM' : 'AM';
    
      // Convert hours to 12-hour format
      const hours12 = hours % 12 || 12;
    
      // Format the time as "hh:mm AM/PM"
      const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
    
      return time12;
    }

  return (
    <>
      <div className="content">
                <Row>
                    <Col>
                      <Card>
                        <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Reminders</h2>
                        </CardHeader>
                        <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                          Reminders can be used to trigger messages to popup at set times. Have a group of students that are supposed to leave at a certain time? Always forget to take attendance? Lesson Launchpad will automatically launch your message at the time you request.
                        </CardSubtitle>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="reminder-table-uoalkbasad" style={{width: '15%'}}>Start Time</th>
                                <th className="text-center" id="reminder-table-utbakdbenq" style={{width: '15%'}}>Duration</th>
                                <th className="text-center" id="reminder-table-oiqweprihb" style={{width: '15%'}}>Days Active</th>
                                <th className="text-center" id="reminder-table-iakemglaje" style={{width: '25%'}}>Message</th>
                                <th className="text-center" id="reminder-table-qaebasdkli" style={{width: '10%'}}>Active</th>
                                <th className="text-center" id="reminder-table-mzmzmjsiii" style={{paddingBottom: '0px', width: '20%' }}>
                                  Actions
                                  <div style={{ marginTop: '0px', fontSize: '0.6em', color: '#666', display: 'flex', justifyContent: 'center', gap: '12px' }}>
                                    <span>Edit</span>
                                    <span>Duplicate</span>
                                    <span>Delete</span>
                                  </div>
                                </th>
                              </tr>
                              {user.reminders && user.reminders.length > 0 && (
                                <>
                                  <UncontrolledTooltip delay={0} target={"reminder-table-uoalkbasad"}>This is the time at which your reminder will launch.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"reminder-table-utbakdbenq"}>Your reminder will show on the screen for this length of time.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"reminder-table-oiqweprihb"}>Your reminder will be active on these days only.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"reminder-table-iakemglaje"}>This is the reminder message that you want displayed.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"reminder-table-qaebasdkli"}>If checked, this reminder message will trigger at the requested time.</UncontrolledTooltip>
                                </>
                              )}
                            </thead>
                            {user && user.reminders && user.reminders?.map((reminder, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center" >{!differentTimes ? Scheduling.convertTo12HourFormat(reminder.startTime) : Scheduling.listActiveStartTimes(reminder.schedule, user)}</td>
                                <td className="text-center" >{reminder.duration} seconds</td>
                                <td className={multiWeek && cycleType === "week" ? "text-left" : "text-center"}>
                                {multiWeek && cycleType === "day" ? (
                                  <div>
                                    {Scheduling.getWeeksWithActiveMonday(reminder.schedule, user)}
                                  </div>
                                ) : (
                                  <>
                                    {!multiWeek && reminder.schedule?.A ? (
                                      <div>
                                        {Scheduling.dayOrder
                                          .filter(day => 
                                            reminder.schedule.A[Object.keys(Scheduling.dayMapping).find(key => Scheduling.dayMapping[key] === day)]?.active
                                          )
                                          .join(', ') || "--"}
                                      </div>
                                    ) : null}

                                    {multiWeek &&
                                      Scheduling.weeksOrder(numberOfWeeks).map((week) => (
                                        <div key={week}>
                                          <strong>
                                            {(cycleType ?? "week") === "week" ? "Week" : "Day"} {week}:
                                          </strong>{" "}
                                          {Scheduling.dayOrder
                                            .filter((day) =>
                                            reminder.schedule?.[week]?.[Object.keys(Scheduling.dayMapping).find((key) => Scheduling.dayMapping[key] === day)]?.active
                                            )
                                            .join(', ') || "--"}
                                        </div>
                                      ))}
                                  </>
                                )}
                                </td>
                                <td className="text-center">"{reminder.reminderMessage}"</td>
                                <td className="text-center">{reminder.active ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}</td>
                                <td className="text-center">
                                <NavLink to={{ pathname: "/reminder-form", state: { reminder, index } }}>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="success"
                                    id="reminder-table-tooltip159182735"
                                    size="md"
                                    type="button"
                                    style={{ marginRight: '10px' }}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-edit icon-edit" />
                                  </Button>
                                </NavLink>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip> */}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="reminder-table-tooltip8049533849302"
                                    size="md"
                                    type="button"
                                    style={{marginRight: '10px'}}
                                    onClick={() => duplicateReminder(index)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-copy" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip8049533849302"
                                  >
                                    Duplicate
                                  </UncontrolledTooltip> */}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="reminder-table-tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(index)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip> */}
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                          <NavLink to={{pathname: "/reminder-form"}} style={{color: 'white'}}>
                            <Button
                              className="button-hover-fix"
                              style={{
                                padding: "10px 20px",
                                borderRadius: "20px",
                                fontSize: ".9rem",
                                fontWeight: "bold",
                                width: '185px'
                              }}
                            >
                              <i className='fa fa-plus' />
                            {' '}Add Reminder
                            </Button>
                          </NavLink>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default AutoReminderTable;
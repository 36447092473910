import React, { useState, useEffect, useRef, useMemo } from "react";
import ContentBox from "components/ContentBoxes/ContentBox";
import Clock from "components/Clock";
import Marquee from "react-fast-marquee";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from '@reduxjs/toolkit';
import { selectUser, selectClasses, selectClassContentBoxes, fetchUserData } from "features/userSlice";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc, collection, addDoc, getDoc, updateDoc, getDocs, arrayUnion, deleteDoc } from "firebase/firestore";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { createRandomId } from "RandomId";
import {NavLink, useLocation} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import GoogleSlidesBox from "../components/ContentBoxes/GoogleSlidesBox.js"
import "/Users/michaeltucker/Documents/coding-projects/client/src/assets/css/styles.css";
import TimerStopwatchContentBox from "components/ContentBoxes/TimerStopwatchContentBox";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/5.png";
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import Timer from "components/Timer";
import Stopwatch from "components/Stopwatch";
import UseAutoCountdown from "AutoCountdown";
import UseAutoReminder from "AutoReminder";
import UseAutoNavigate from "AutoNavigate";
import UseAutoHappyBirthday from "AutoHappyBirthday";
import CountdownTimer from 'components/CountdownTimer.js';
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import PerfectScrollbar from "perfect-scrollbar";
import { Label, FormGroup, FormFeedback, Badge, Card, CardHeader, CardBody, Container, Input, CardTitle, CardImg, Row, Col, UncontrolledTooltip, ButtonDropdown, Button, ButtonGroup, DropdownToggle, DropdownMenu, DropdownItem, Spinner, Dropdown, Modal, ModalBody, ModalHeader, CardText, ModalFooter } from "reactstrap";
import ContentBoxesTable from "./tables/ContentBoxesTable";
import Whiteboard from "components/Whiteboard";
import Randomizer from "components/Randomizer";
import AgendaBox from "components/ContentBoxes/AgendaBox";
import featureGoogleSlides from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-slides.svg";
import featureStopwatch from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-stopwatch.svg";
import featureTextEditor from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-text-editor.svg";
import featureTimer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-timer.svg";
import featureRandomizer from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-randomizer.svg";
import featureAgenda from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-auto-agenda.svg";
import featureMultiBox from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-multi-box.svg";
import featureWhiteboard from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-whiteboard.svg";
import featureCanva from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-canva.svg";
import featureCountdown from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-countdown.svg";
import featureGoogleDoc from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-google-doc.svg";
import featurePdfBox from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-pdf-box.svg";
import featurePowerPoint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-power-point-box.svg";
import featureWebEmbed from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/feature-web-embed.svg";
import CanvaBox from "components/ContentBoxes/CanvaBox";
import MultiBox from "components/ContentBoxes/MultiBox";
import CountdownBox from "components/ContentBoxes/CountdownBox";
import GoogleDocSheetBox from "components/ContentBoxes/GoogleDocSheetBox";
import CarouselBox from "components/ContentBoxes/CarouselBox";
import PdfBox from "components/ContentBoxes/PdfBox";
import PowerpointBox from "components/ContentBoxes/PowerpointBox";
import IframeBox from "components/ContentBoxes/IframeBox";
import UseAutoMusic from "AutoMusic";
import ActiveDatesDatePicker from "components/ActiveDatesDatePicker.js";
import FilePicker from "components/FilePicker.js";
import { updateContentBox } from "../utils/ContentBoxUtils";
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";
import AddContentBoxModal from "components/AddContentBoxModal.js";
import OnboardingWizardModal from "components/OnboardingWizardModal.js";

// // 3-5-25: This needs to be here to avoid infinite re-renders when loading a class with no content boxes.
// const selectUserClassesAndContentBoxes = createSelector(
//   [selectUser, selectClasses, (state, classId) => state.user.selectedClassContentBoxes[classId] || []],
//   (user, classes, contentBoxes) => ({ user, classes, contentBoxes })
// );

// function Present( props ) { //props are set in the createLinks function of Sidebar.js

//   const classIdRef = useRef(null);
//   const location = useLocation()
//   const dispatch = useDispatch()
//   let newClassId = props?.props?.classId || props.location?.state?.classId || null;
//   // If still null, extract classId from the URL
//   if (!newClassId) {
//     const match = location.pathname.match(/id[a-zA-Z0-9]+/);
//     if (match) {
//         newClassId = match[0]; // Extracts "id123" instead of "/id123"
//     }
//   }

//   const [classId, setClassId] = useState(newClassId);

//   console.log("classId => ", classId)
  
//   useEffect(() => {
//     if (classId !== newClassId) {
//       classId = newClassId; // Update the ref
//       setClassId(newClassId); // Update state only if needed
//     }
//   }, [newClassId]);
  
//   const { user, classes, contentBoxes } = useSelector((state) =>
//     selectUserClassesAndContentBoxes(state, classId)
//   );

function Present(props) { // Props are set in createLinks function of Sidebar.js
  const location = useLocation();
  const dispatch = useDispatch();

  // Extract classId from props, state, or URL
  const extractClassIdFromURL = () => {
      const match = location.pathname.match(/id[a-zA-Z0-9]+/);
      return match?.[0] || null;  // Returns "id123" instead of "/id123"
  };

  const initialClassId = props?.props?.classId || props.location?.state?.classId || extractClassIdFromURL();
  const [classId, setClassId] = useState(initialClassId);

  console.log("classId => ", classId)

  useEffect(() => {
      if (classId !== initialClassId) {
          setClassId(initialClassId);
      }
  }, [initialClassId]);

  const user = useSelector(selectUser);
  const classes = useSelector(selectClasses);
  const contentBoxes = useSelector(state => state.user.selectedClassContentBoxes[classId] || []);

  //END NEW STUFF
  const share = location.pathname.includes('/share/');
  const shareUrl = share && location.pathname
  const partsFromShareUrl = share && shareUrl.split('/')
  const classIdFromShareUrl = share && partsFromShareUrl[partsFromShareUrl.length - 1];
  const classData = classes.find((c) => c.classId === classId);
  const [boxes, setBoxes] = useState();
  const [announcements, setAnnouncements] = useState();
  const [isOpenArray, setIsOpenArray] = useState([]);
  const templates = user?.contentBoxTemplates
  const [view, setView] = useState(); //Can probably be deleted. Need to confirm that no content box is actually using this.
  // const [activeBoxes, setActiveBoxes] = useState() //This is related to the 'show' property, NOT the 'active' property
  // const [orderOfAnnouncements, setOrderOfAnnouncements] = useState();
  const [background, setBackground] = useState(); //This is required unless background picker modal is moved out
  const [backgroundAnnouncements, setBackgroundAnnouncements] = useState(); //This is required unless background picker modal is moved out
  const [backgroundClock, setBackgroundClock] = useState(); //This is required unless background picker modal is moved out
  const [contentBoxesOrder, setContentBoxesOrder] = useState(classData?.contentBoxesOrder || []); //Added this to try to use optimistic updates for reordering content boxes.
  const [showEditButton, setShowEditButton] = useState(false);
  const [showEditAnnouncementButton, setShowEditAnnouncementButton] = useState(false);
  const [clockVersion, setClockVersion] = useState();
  const [clockFontColor, setClockFontColor] = useState();
  const [clockLanguage, setClockLanguage] = useState();
  const [clockKey, setClockKey] = useState(0)
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [showDatePickerContentBoxStorage, setShowDatePickerContentBoxStorage] = useState(false);
  const [noContentBoxesAlert, setNoContentBoxesAlert] = useState(false);
  const [noActiveBoxesAlert, setNoActiveBoxesAlert] = useState(false);
  const [noBoxesSetToShowAlert, setNoBoxesSetToShowAlert] = useState(false);
  const [navigationVisible, setNavigationVisible] = useState(true);
  const [fileUploadModal, setFileUploadModal] = useState(false);
  const [showLayoutTemplates, setShowLayoutTemplates] = useState(false)
  const [navbarMD, setNavbarMD] = useState({logo: '0', marquee: '10', clock: '2'});
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
  const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const suffix = ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });

  const launchButtonModalData = [
    {
      image: featureTextEditor,
      title: "Text Editor Box",
      description: "A box with a text editor.",
    },
    {
      image: featureAgenda,
      title: "Agenda Box",
      description: "An automated Agenda."
    },
    {
      image: featureGoogleSlides,
      title: "Google Slides Box",
      description: "Use a google slides presentation."
    },
    {
      image: featureGoogleDoc,
      title: "Google Doc Box",
      description: "Show a google doc or sheet."
    },
    {
      image: featureCanva,
      title: "Canva Box",
      description: "Use a project from Canva."
    },
    {
      image: featureMultiBox,
      title: "MultiBox",
      description: "Two different boxes stacked vertically."
    },
    {
      image: featureStopwatch,
      title: "Timer/Stopwatch",
      description: "A box with a timer or stopwatch."
    },
    {
      image: featureCountdown,
      title: "Countdown Box",
      description: "Countdown the days to an event."
    },
    // {
    //   image: featureCarousel,
    //   title: "Carousel Box",
    //   description: "Flip through multiple boxes.",
    //   disabled: false
    // },
    {
      image: featurePdfBox,
      title: "PDF Box",
      description: "A box to show a PDF.",
    },
    {
      image: featurePowerPoint,
      title: "PowerPoint Box",
      // beta: <Badge color="danger">Beta</Badge>,
      description: "A box to show a PowerPoint.",
    },
    {
      image: featureWebEmbed,
      title: "Web Box",
      // beta: <Badge color="danger">Beta</Badge>,
      description: "Embed another website.",
    },
  ]

  const launchButtonWidgetData = [
    {
      image: featureRandomizer,
      title: "Randomizer",
      description: "Pick a random name."
    },
    {
      image: featureTimer,
      title: "Timer",
      description: "A timer."
    },
    {
      image: featureStopwatch,
      title: "Stopwatch",
      description: "A stopwatch."
    },
    {
      image: featureWhiteboard,
      title: "Whiteboard",
      description: "A blank surface."
    },
  ]

  // Select the node/element you want to observe
  const targetNode = document.querySelector('.sidebar');

  // Options for the observer (what to observe)
  const config = { attributes: true, attributeFilter: ['class'] };

  // Callback function to execute when mutations are observed
  const callback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            if (mutation.target.className === 'sidebar-hidden') {
              setNavigationVisible(false);
            } else {
              setNavigationVisible(true);
            }
              // console.log('Class attribute changed:', mutation.target.className);
          }
      }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  // Start observing the target node for configured mutations
  if (targetNode) {
      observer.observe(targetNode, config);
  }

/**
 * 📝 Memoized New Announcements Array
 * 
 * This `useMemo` combines multiple operations into a single, efficient process
 * to optimize performance and avoid unnecessary recalculations.
 * 
 * 🔹 Step 1: Filter announcements by classId
 *    - Ensures that only announcements related to the selected class are considered.
 * 
 * 🔹 Step 2: Filter active announcements based on "Active Dates" setting
 *    - If `useActiveDates` is enabled, filter announcements within the date range.
 *    - Otherwise, return only announcements marked as `active: true`.
 * 
 * 🔹 Step 3: Sort announcements based on `classData.orderOfAnnouncements`
 *    - Orders announcements according to the predefined list from the class settings.
 *    - If an announcement is not found in the list, it is placed at the end.
 * 
 * 🔹 Step 4: Generate the final `newAnnouncements` array
 *    - Each announcement is duplicated, adding a "spacer" announcement with blank HTML.
 *    - This ensures proper spacing in the UI.
 * 
 * 📌 Dependencies:
 *    - `user.announcements`: The list of announcements from Firestore.
 *    - `classData.classId`: The selected class's ID.
 *    - `user.settings.useActiveDates`: Determines whether date-based filtering is applied.
 *    - `date`: The currently selected date for filtering.
 *    - `classData.orderOfAnnouncements`: The predefined order for sorting announcements.
 * 
 * 🚀 Optimized for performance: This avoids unnecessary re-renders by memoizing results.
 */
  const newAnnouncements = useMemo(() => {
    if (!user?.announcements) return [];
  
    // 🔹 Step 1: Filter by classId
    const filteredAnnouncements = user.announcements.filter((announcement) =>
      announcement?.classIds?.includes(classData?.classId)
    );
  
    if (!filteredAnnouncements.length) return [];
  
    // 🔹 Step 2: Filter active announcements based on useActiveDates
    let activeAnnouncements = [];
    const orderOfAnnouncements = classData?.orderOfAnnouncements || [];
  
    if (user.settings?.useActiveDates) {
      activeAnnouncements = filteredAnnouncements.filter((announcement) => {
        if (!announcement.startDate) return true;
        if (Array.isArray(announcement.startDate)) {
          return announcement.startDate.includes(date.dayNamePlural);
        }
  
        const startDate = new Date(announcement.startDate);
        startDate.setHours(0, 0, 0, 1);
        const endDate = new Date(announcement.endDate);
        endDate.setHours(24);
        const dayToCompare = new Date(date.year, date.month, date.day);
        dayToCompare.setHours(0, 0, 0, 0);
        return startDate <= dayToCompare && endDate >= dayToCompare;
      });
    } else {
      activeAnnouncements = filteredAnnouncements.filter((announcement) => announcement.active === true);
    }
  
    if (!activeAnnouncements.length) return [];
  
    // 🔹 Step 3: Sort by orderOfAnnouncements
    activeAnnouncements.sort((a, b) => {
      const indexA = orderOfAnnouncements.indexOf(a.id);
      const indexB = orderOfAnnouncements.indexOf(b.id);
      return (indexA === -1 ? Number.MAX_VALUE : indexA) - (indexB === -1 ? Number.MAX_VALUE : indexB);
    });
  
    // 🔹 Step 4: Generate new announcements array
    return activeAnnouncements.flatMap((announcement) => [
      { ...announcement },
      { ...announcement, html: "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>" }
    ]);
  
  }, [user?.announcements, classData?.classId, user.settings?.useActiveDates, date, classData?.orderOfAnnouncements]);

  useEffect(() => {

    if (!user || !classes || !classData || !contentBoxes.length) return; // ✅ Prevent unnecessary runs

      let activeContentBoxes = [];

      // 3-13-25: STARTDATE WAS IN TIMESTAMP FORMAT CAUSING .SPLIT TO FAIL. THIS CODE IS MORE ROBUST.
      if (user.settings?.useActiveDates) {
        activeContentBoxes = contentBoxes?.filter((box) => {
          if (!box.startDate) return true; // ✅ Allow empty dates
          if (Array.isArray(box.startDate)) {
            return box.startDate.includes(date.dayNamePlural);
          }
      
          try {
            let startDate, endDate;
      
            // ✅ Handle properly formatted "YYYY-MM-DD" date
            if (typeof box.startDate === "string" && box.startDate.includes("-")) {
              const [startYear, startMonth, startDay] = box.startDate.split("-");
              const [endYear, endMonth, endDay] = box.endDate?.split("-") || [];
              startDate = new Date(startYear, startMonth - 1, startDay);
              endDate = new Date(endYear, endMonth - 1, endDay);
            } 
            // ✅ Handle cases where the date is a full timestamp string
            else {
              startDate = new Date(box.startDate);
              endDate = new Date(box.endDate);
            }
      
            // ✅ Validate dates
            if (isNaN(startDate) || isNaN(endDate)) {
              console.warn("⚠️ Invalid date format detected:", box.startDate, box.endDate);
              return false;
            }
      
            // ✅ Compare dates
            const dayToCompare = new Date(date.year, date.month, date.day);
            return startDate <= dayToCompare && endDate >= dayToCompare;
          } catch (error) {
            console.error("❌ Error parsing dates:", error);
            return false;
          }
        });
      } else {
        activeContentBoxes = contentBoxes?.filter((x) => x.active === true);
      }

      // ✅ Sort activeContentBoxes using the order in classData.contentBoxesOrder
      console.log("contentBoxesOrder => ", contentBoxesOrder)
      if (contentBoxesOrder) {
        activeContentBoxes.sort((a, b) => {
          const indexA = contentBoxesOrder.indexOf(a.contentBoxId);
          const indexB = contentBoxesOrder.indexOf(b.contentBoxId);
          return (indexA !== -1 ? indexA : Infinity) - (indexB !== -1 ? indexB : Infinity);
        });
      }

      // ✅ Only update `setBoxes` if contentBoxes actually changed
      if (!arraysEqual(boxes, activeContentBoxes)) {
        console.log("Updating boxes...");
        setBoxes(activeContentBoxes);
      }

      // ✅ Only update if the value has changed

      console.log("user before alert set => ", user)
      console.log("classes before alert set => ", classes)
      console.log("contentBoxes before alert set => ", contentBoxes)

      setNoContentBoxesAlert((prev) => (prev !== (contentBoxes.length === 0) ? contentBoxes.length === 0 : prev));

      setNoActiveBoxesAlert((prev) => (prev !== (activeContentBoxes.length === 0) ? activeContentBoxes.length === 0 : prev));

      setNoBoxesSetToShowAlert((prev) => 
        prev !== (activeContentBoxes.length > 0 && !activeContentBoxes.some((box) => box.show)) 
          ? activeContentBoxes.length > 0 && !activeContentBoxes.some((box) => box.show) 
          : prev
      );

      // ✅ Only update announcements if they have changed
      setAnnouncements((prevAnnouncements) => 
        arraysEqual(prevAnnouncements, newAnnouncements) ? prevAnnouncements : newAnnouncements
      );

      // // ✅ Only update templates if they have changed
      // setTemplates((prevTemplates) => 
      //   arraysEqual(prevTemplates, user?.contentBoxTemplates) ? prevTemplates : user?.contentBoxTemplates
      // );

      setLoadComplete(true);
  }, [user, classes, contentBoxes, date.year, date.month, date.day, date.dayNamePlural, classData]);
  
  useEffect(() => {
    if (user && classes && classData) {

      if (share) {
        // classId = classIdFromShareUrl
      } else {
        // classId = props?.props?.classId || props.location?.state?.classId || null;
      }

      // let classData = user.classes.find((x) => x.classId === classId);
      // setClassData(classData);

      if (share) {
        // let classIndexData = classes?.findIndex((x) => x.classId === classId);
        // setClassIndex(classIndexData);
      }

      const targetNode = document.querySelector('.sidebar');
      if (targetNode) {
        setNavigationVisible(true)
      } else {
        setNavigationVisible(false)
      }

      let viewData = classData?.view || 'row';
      setView(viewData);

      // let announcementsData = classData?.orderOfAnnouncements || [];
      // setOrderOfAnnouncements(announcementsData);

      let backgroundData = classData?.background || '';
      // let backgroundData = user.classes[classIndex]?.background || '';
      setBackground(backgroundData);

      let backgroundAnnouncementsData = classData?.backgroundAnnouncements || '';
      // let backgroundAnnouncementsData = user.classes[classIndex]?.backgroundAnnouncements || '';
      setBackgroundAnnouncements(backgroundAnnouncementsData);

      let backgroundClockData = classData?.backgroundClock || '';
      // let backgroundClockData = user.classes[classIndex]?.backgroundClock || '';
      setBackgroundClock(backgroundClockData);

      let clockVersionData = classData?.clockVersion || null;
      // let clockVersionData = user.classes[classIndex]?.clockVersion || null;
      setClockVersion(clockVersionData);

      let clockFontColorData = classData?.clockFontColor || null;
      // let clockFontColorData = user.classes[classIndex]?.clockFontColor || null;
      setClockFontColor(clockFontColorData);

      let clockLanguageData = classData?.clockLanguage || null;
      // let clockLanguageData = user.classes[classIndex]?.clockLanguage || null;
      setClockLanguage(clockLanguageData);

      // setShow({
      //   marquee: classData?.showMarquee || null,
      //   clock: classData?.showClock || null,
      // })
    }

  }, [user, classes, classData]);

  // useEffect(() => {
  //   if (user && user.settings && classData) {
  //     if (!user.settings.logo && show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '10', clock: '2'})
  //     if (!user.settings.logo && show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '12', clock: '0'})
  //     if (!user.settings.logo && !show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '12'})
  //     if (!user.settings.logo && !show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '0'})
  //     if (user.settings.logo && show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '10', clock: '2'})
  //     if (user.settings.logo && show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '12', clock: '0'})
  //     if (user.settings.logo && !show?.marquee && show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '12'})
  //     if (user.settings.logo && !show?.marquee && !show?.clock) setNavbarMD({logo: '0', marquee: '0', clock: '0'})
  //   } else if (user && show) {
  //     if (show.marquee && show.clock) setNavbarMD({logo: '0', marquee: '10', clock: '2'})
  //     if (show.marquee && !show.clock) setNavbarMD({logo: '0', marquee: '12', clock: '0'})
  //     if (!show.marquee && show.clock) setNavbarMD({logo: '0', marquee: '0', clock: '12'})
  //     if (!show.marquee && !show.clock) setNavbarMD({logo: '0', marquee: '0', clock: '0'})
  //   }
  // }, [classData, show]);

  useEffect(() => {
    if (!user || !classData) return;
  
    const hasLogo = user.settings?.logo;
    const showMarquee = classData?.showMarquee;
    const showClock = classData?.showClock;
  
    let navbarValues = { logo: '0', marquee: '0', clock: '0' };
  
    if (showMarquee && showClock) navbarValues = { logo: '0', marquee: '10', clock: '2' };
    else if (showMarquee) navbarValues = { logo: '0', marquee: '12', clock: '0' };
    else if (showClock) navbarValues = { logo: '0', marquee: '0', clock: '12' };
  
    setNavbarMD(navbarValues);
  
  }, [classData, user]);
  

  //perfect scrollbar for bottom content tabs navigation required for windows users
  useEffect(() => {
    let ps;

    if (navigator.platform.indexOf("Win") > -1) {
      const navbarTabsSection = document.querySelector('.present-navbar-tabs-section');

      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");

      if (navbarTabsSection) {
        // Initialize PerfectScrollbar on the .present-navbar-tabs-section element
        ps = new PerfectScrollbar(navbarTabsSection);
      }
    }

    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps) {
          ps.destroy();
        }

        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, []); // Removed mainPanel from dependencies since it's no longer used

  const handleDateForward = () => {
    let dateObject = new Date(date.year, date.month, date.day);
  
    // Move to the next day by incrementing the date, not by adding milliseconds
    dateObject.setDate(dateObject.getDate() + 1);
  
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    };
  
    setDate(dateData);
  };

  const handleDateBackward = () => {
    let dateObject = new Date(date.year, date.month, date.day);
  
    // Move to the previous day by decrementing the date
    dateObject.setDate(dateObject.getDate() - 1);
  
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    };
  
    setDate(dateData);
  };

  const handleDateSet = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePicker(!showDatePicker);
  }

  const handleDateSetContentBoxStorage = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage);
  }

  const numberOfActiveBoxes = boxes && boxes.filter((box) => box.show === true).length

  // useEffect(() => {
  //   if (boxes && boxes.length > 0) {
  //     const newArray = [];
  //     for (let i = 0; i < boxes.length; i++) {
  //       newArray.push(false);
  //     }
  //     setIsOpenArray(newArray);
  //     setActiveBoxes(boxes.filter(box => box.show === true).length)
  //   }
  // }, [boxes]);

  useEffect(() => {
    if (boxes && boxes.length > 0) {
      // Check if isOpenArray needs to change
      if (!isOpenArray || isOpenArray.length !== boxes.length) {
        setIsOpenArray(new Array(boxes.length).fill(false));
      }
  
      // Check if activeBoxes needs to change
      // const newActiveBoxesCount = boxes.filter(box => box.show === true).length;
      // setActiveBoxes(prev => (prev !== newActiveBoxesCount ? newActiveBoxesCount : prev));
    }
  }, [boxes]);

  const toggleDropdown = (index) => {
    const newIsOpenArray = [...isOpenArray];
    newIsOpenArray[index] = !newIsOpenArray[index];
    setIsOpenArray(newIsOpenArray);
  };

  const handleButtonToggle = (index) => {
    // Toggle the corresponding dropdown when a button is clicked
    toggleDropdown(index);
  };

  const [isLaunchButtonOpen, setIsLauncButtonOpen] = useState(false)
  
  // Helper function to compare arrays
  function arraysEqual(arr1, arr2) {
    if (arr1 != undefined && arr2 != undefined) {
      return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
    } else return false
  }

  const toggleFileUpload = () => {
    setFileUploadModal(!fileUploadModal);
  }

  const setBackgroundWithUploadedFile = (filePath) => {
    const formattedFilePath = `url("${filePath}")`

    try {
      saveBackground(formattedFilePath);  
    } catch (error) {
      window.alert("Something went wrong: ", error)
    }
    setFileUploadModal(false);
    setBackgroundModal(false);
  }

  // const handleToggle = async (boxFromClick) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     // Find the class index based on classId
  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );

  //     const contentBoxIndex = userCopy.classes[classIndex].contentBoxes?.findIndex(
  //       (box) => box.contentBoxId === boxFromClick.contentBoxId
  //     );

  //     const copyOfBox = {...boxFromClick, show: !boxFromClick.show}

  //     // Update the content box in the document data
  //     userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = copyOfBox;

  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy); // Replace with the correct path to your document

  //     let newClockKey = clockKey
  //     setClockKey(++newClockKey);
  //   } catch (error) {
  //     console.error('Error updating document:', error);
  //   }
  // };

  const handleToggle = async (boxFromClick) => {
    try {
      if (!user?.uid || !classId || !boxFromClick?.contentBoxId) {
        console.error("❌ Missing required identifiers: User ID, Class ID, or Content Box ID.");
        return;
      }
  
      const newShowState = !boxFromClick.show;
  
      // ✅ Update local state first (optimistic update)
      setBoxes((prevBoxes) =>
        prevBoxes.map((box) =>
          box.contentBoxId === boxFromClick.contentBoxId ? { ...box, show: newShowState } : box
        )
      );
  
      // Reference the specific content box document in Firestore
      const contentBoxRef = doc(
        db,
        FIREBASE_COLLECTIONS.USERS,
        user.uid,
        FIREBASE_COLLECTIONS.CLASSES,
        classId,
        FIREBASE_COLLECTIONS.CONTENT_BOXES,
        boxFromClick.contentBoxId
      );
  
      // Update Firestore document
      await updateDoc(contentBoxRef, { show: newShowState });
  
      console.log(`✅ Content box ${boxFromClick.contentBoxId} visibility toggled successfully.`);
    } catch (error) {
      console.error("❌ Error updating content box visibility:", error);
  
      // ❌ Revert state if Firestore update fails
      setBoxes((prevBoxes) =>
        prevBoxes.map((box) =>
          box.contentBoxId === boxFromClick.contentBoxId ? { ...box, show: boxFromClick.show } : box
        )
      );
    }
  };  

  //This is a fix for previous users contentBoxes not having the active property - 1/8/24
  const addActiveTagToOldContentBoxes = async () => {
      try {
        const docRef = doc(db, "users", user.uid);
  
        const userCopy = JSON.parse(JSON.stringify(user));

        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === classId
        );
  
        // Update the content boxes with active = true
        userCopy.classes[classIndex].contentBoxes.map((x) => x.active = true)
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy);

      } catch (error) {
        console.error('Error updating document:', error);
      }
  }

  // const addContentBox = async (boxFromClick) => {
  //     try {
  //       const docRef = doc(db, "users", user.uid);
  
  //       const userCopy = JSON.parse(JSON.stringify(user));
    
  //       // Make a content box with a new empty contentBox or the box from the template.
  //       const contentBox = boxFromClick ? 
  //         {...boxFromClick,
  //           contentBoxId: createRandomId(),
  //           startDate: date.databaseFormattedDate,
  //           endDate: date.databaseFormattedDate,
  //           active: true
  //         }
  //       : 
  //         {
  //           contentBoxId: createRandomId(),
  //           startDate: date.databaseFormattedDate,
  //           endDate: date.databaseFormattedDate,
  //           heading: "Type here...",
  //           show: true,
  //           active: true
  //         };

  //       const classIndex = userCopy.classes.findIndex(
  //         (c) => c.classId === classId
  //       );
  
  //       // Update the content box in the document data
  //       userCopy.classes[classIndex].contentBoxes.push(contentBox);
  
  //       // Update the document in Firestore
  //       await setDoc(docRef, userCopy);

  //       toggleLaunchButtonModal()

  //       let newClockKey = clockKey
  //       setClockKey(++newClockKey);
  //     } catch (error) {
  //       console.error('Error updating document:', error);
  //     }
  // }

  // const addContentBox = async (boxFromClick) => {
  //   try {
  //     if (!user || !classId) {
  //       console.error("User or classId is missing.");
  //       return;
  //     }

  //     // Generate a new unique contentBoxId
  //     const contentBoxId = createRandomId();

  //     // Reference the specific content box document with the custom ID
  //     const contentBoxRef = doc(db, "usersNew", user.uid, "classes", classId, "contentBoxes", contentBoxId);

  //     // Define the new content box object
  //     const contentBox = boxFromClick
  //       ? {
  //           ...boxFromClick,
  //           contentBoxId, // Ensure ID is stored inside the document
  //           startDate: date.databaseFormattedDate,
  //           endDate: date.databaseFormattedDate,
  //           active: true,
  //         }
  //       : {
  //           contentBoxId,
  //           startDate: date.databaseFormattedDate,
  //           endDate: date.databaseFormattedDate,
  //           heading: "Type here...",
  //           show: true,
  //           active: true
  //         };

  //     // Save the document in Firestore with the same contentBoxId
  //     await setDoc(contentBoxRef, contentBox);

  //     console.log("Box Added")

  //     toggleLaunchButtonModal();

  //     dispatch(fetchUserData(user.uid));

  //     // Increment clockKey to trigger updates
  //     setClockKey((prev) => prev + 1);
  //   } catch (error) {
  //     console.error("Error adding content box:", error);
  //   }
  // };

  const addContentBox = async (boxFromClick) => {
    try {
      if (!user || !classId) {
        console.error("User or classId is missing.");
        return;
      }

      // Generate a new unique contentBoxId
      const contentBoxId = createRandomId();

      // Reference the specific content box document
      const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
      const contentBoxRef = doc(classRef, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

      // Define the new content box object
      const contentBox = boxFromClick
        ? {
            ...boxFromClick,
            contentBoxId, // Ensure ID is stored inside the document
            startDate: date.databaseFormattedDate,
            endDate: date.databaseFormattedDate,
            active: true,
          }
        : {
            contentBoxId,
            startDate: date.databaseFormattedDate,
            endDate: date.databaseFormattedDate,
            heading: "Type here...",
            show: true,
            active: true
          };

      // 🟢 Save the content box in Firestore
      await setDoc(contentBoxRef, contentBox);

      // 🟢 Update the contentBoxesOrder list in the class document
      await updateDoc(classRef, {
        contentBoxesOrder: arrayUnion(contentBoxId) // Append the new ID
      });

      console.log("✅ Box Added & Order Updated");

      toggleLaunchButtonModal();

      // 🛑 Manually re-fetch user data after updating Firestore
      dispatch(fetchUserData(user.uid));

      // 🔄 Increment clockKey to trigger updates
      setClockKey((prev) => prev + 1);
    } catch (error) {
      console.error("❌ Error adding content box:", error);
    }
};


  // //announcments used to belong to a class in the data structure. This code was required to account for new data structure.
  // const changeOldAnnouncementsToNewMapFormatV3 = async () => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     // Create an array to accumulate all announcements
  //     let allAnnouncements = [];
  
  //     // Iterate over each class in userCopy.classes
  //     if (userCopy.classes.length > 0) {
  //       userCopy.classes.forEach((currentClass, classIndex) => {
  //         // Change the announcements array from strings to maps.
  //         if (currentClass.announcements.length > 0) {
  //           const announcementsArrayOfMaps = currentClass.announcements.map((string) => ({
  //             html: string,
  //             text: string.replace(/<\/?[^>]+(>|$)|&nbsp;/g, ""),
  //             active: true,
  //             show: true,
  //             lastEdited: "",
  //             dateCreated: "",
  //             classIds: [currentClass.classId],
  //             id: createRandomId(),
  //           }));
  
  //           // Add the new orderOfAnnouncements array to the class
  //           userCopy.classes[classIndex].orderOfAnnouncements = announcementsArrayOfMaps.map(
  //             (announcement) => announcement.id
  //           );
  
  //           // Concatenate the announcements to the accumulator array
  //           allAnnouncements = allAnnouncements.concat(announcementsArrayOfMaps);
  //         }
  //       });
  
  //       // Delete the existing announcements array from userCopy.classes[classIndex]
  //       if (allAnnouncements.length > 0) {
  //         userCopy.classes.forEach((currentClass, classIndex) => {
  //           currentClass.announcements.splice(0, currentClass.announcements.length);
  //         });
  //       }
  //     }

  //     if (!Array.isArray(userCopy.announcements)) {
  //       userCopy.announcements = [];
  //     }  
  //     // Update the userCopy.announcements with the accumulated array
  //     userCopy.announcements = allAnnouncements;
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  
  //   } catch (error) {
  //     console.error("Error updating document:", error);
  //   }
  // };

  const changeOldAnnouncementsToNewMapFormatV3 = async () => {
      try {
          if (!user?.uid) {
              console.error("❌ Missing required parameter: User ID.");
              return;
          }

          // Reference the user's classes collection
          const classesRef = collection(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES);
          const classesSnap = await getDocs(classesRef);

          if (classesSnap.empty) {
              console.log("🚨 No classes found for this user.");
              return;
          }

          let allAnnouncements = [];

          // Iterate over each class
          for (const classDoc of classesSnap.docs) {
              const classData = classDoc.data();
              const classId = classDoc.id;

              if (!classData.announcements || classData.announcements.length === 0) {
                  continue; // Skip if no announcements
              }

              // Convert old announcements array (strings) to new map format
              const announcementsArrayOfMaps = classData.announcements.map((string) => ({
                  html: string,
                  text: string.replace(/<\/?[^>]+(>|$)|&nbsp;/g, ""),
                  active: true,
                  show: true,
                  lastEdited: "",
                  dateCreated: "",
                  classIds: [classId],
                  id: createRandomId(),
              }));

              // Accumulate announcements for migration
              allAnnouncements = allAnnouncements.concat(announcementsArrayOfMaps);

              // Update the class document to store `orderOfAnnouncements`
              await updateDoc(doc(classesRef, classId), {
                  orderOfAnnouncements: announcementsArrayOfMaps.map((announcement) => announcement.id),
                  announcements: [], // Clear old structure
              });
          }

          // Update user's top-level `announcements` collection
          const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
          await updateDoc(userRef, {
              announcements: allAnnouncements,
          });

          console.log("✅ Announcements migrated successfully.");
      } catch (error) {
          console.error("❌ Error migrating announcements:", error);
      }
  };

  const [alert, setAlert] = useState(null);
  
  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // const updateContentBox = async (contentBoxId, updatedContent) => {
  //     try {
  //       const docRef = doc(db, "users", user.uid);
    
  //       const userCopy = JSON.parse(JSON.stringify(user));
    
  //       // Find the class index based on classId
  //       const classIndex = userCopy.classes.findIndex(
  //         (c) => c.classId === classId
  //       );
    
  //       if (classIndex !== -1) {
  //         // Find the content box index based on contentBoxId
  //         const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
  //           (box) => box.contentBoxId === contentBoxId
  //         );
    
  //         if (contentBoxIndex !== -1) {
  //           // Make a copy of the existing content box using the spread operator
  //           let updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
    
  //           // Update the content box with the new data
  //           // updatedContentBox.content = updatedContent.content;
  //           updatedContentBox = {...updatedContent}
            

  //           //Encountered issue where 
  //           if (!updatedContentBox.userHeading) {
  //             if (heading === undefined) {
  //               updateContentBox.heading = "Heading";
  //             } else {
  //               if (heading.length > 9) {
  //                 updatedContentBox.heading = heading.substring(0, 9) + "..."
  //               } else if (heading.length === 0) {
  //                 updatedContentBox.heading = "Type here..."
  //               } else {
  //                 updatedContentBox.heading = heading
  //               }
  //             }
  //           }
    
  //           // Update the content box in the document data
  //           userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
    
  //           // Update the document in Firestore
  //           await setDoc(docRef, userCopy); // Replace with the correct path to your document
    
  //           // Update the local state with the updated document data
  //           setBoxes(userCopy.classes[classIndex].contentBoxes);
    
  //           successAlert()
  //         }
  //       }
  //     } catch (error) {
  //       console.error('Error updating document:', error);
  //     }
  // };

  const options = [];
  for (let i = 1; i <= 60; i++) {
    options.push(<option key={i} value={i}>{`${i} Second${i !== 1 ? 's' : ''}`}</option>);
  }

  const addTimerStopwatchBoxChoiceRef = useRef();

  // const toggleTimerStopwatchAlert = () => {
  //   setAlert(
  //     <ReactBSAlert
  //       showCancel
  //       title="Choose type"
  //       style={{ display: "block", marginTop: "-100px" }}
  //       onConfirm={() => {
  //         const choice = addTimerStopwatchBoxChoiceRef.current.value;
  //         if (choice === "timer") {
  //           addContentBox({
  //             contentBoxId: createRandomId(),
  //             heading: 'Timer',
  //             show: true,
  //             userHeading: true,
  //             timer: true,
  //             active: true
  //           });
  //         } else if (choice === "stopwatch") {
  //           addContentBox({
  //             contentBoxId: createRandomId(),
  //             heading: 'Stopwatch',
  //             show: true,
  //             userHeading: true,
  //             stopwatch: true,
  //             active: true
  //           });
  //         } else if (choice === "both") {
  //           addContentBox({
  //             contentBoxId: createRandomId(),
  //             heading: 'Timer/Stopw...',
  //             show: true,
  //             userHeading: true,
  //             timer: true,
  //             stopwatch: true,
  //             active: true
  //           });
  //         }
  //         hideAlert();
  //         toggleLaunchButtonModal();
  //       }}
  //       onCancel={() => hideAlert()}
  //       confirmBtnBsStyle="info"
  //       cancelBtnBsStyle="danger"
  //       btnSize=""
  //     >
  //       <div>
  //         <Input
  //           id="timerStopwatchSelect"
  //           name="select"
  //           type="select"
  //           onChange={(e) => addTimerStopwatchBoxChoiceRef.current = e.target}
  //           required={true}
  //           defaultValue={"none"}
  //         >
  //           <option disabled value="none">Pick one...</option>
  //           <option value="timer">Timer</option>
  //           <option value="stopwatch">Stopwatch</option>
  //           <option value="both">Both</option>
  //         </Input>
  //       </div>
  //     </ReactBSAlert>
  //   );
  // }

  const toggleTimerStopwatchAlert = () => {
    setAlert(
        <ReactBSAlert
            showCancel
            showConfirm={false}
            title="Choose Type"
            style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
            onCancel={hideAlert}
            cancelBtnBsStyle="danger"
            cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
            btnSize=""
        >
            <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                {[
                    { type: "timer", title: "Timer", icon: "⏳" },
                    { type: "stopwatch", title: "Stopwatch", icon: "⏱️" },
                    { type: "both", title: "Both", icon: "⏳⏱️" }
                ].map((option, index) => (
                    <Card
                        key={index}
                        className="text-center"
                        style={{
                            padding: "10px",
                            width: "150px",
                            cursor: "pointer",
                            border: "2px solid black",
                            transition: "transform 0.2s",
                        }}
                        onMouseOver={(e) => {
                          (e.currentTarget.style.transform = "scale(1.05)");
                          (e.currentTarget.style.border = "2px solid blue");
                        }}
                        onMouseLeave={(e) => {
                          (e.currentTarget.style.transform = "scale(1)");
                          (e.currentTarget.style.border = "2px solid black");
                        }}
                        onClick={() => {
                            addContentBox({
                                contentBoxId: createRandomId(),
                                heading: option.title,
                                show: true,
                                userHeading: true,
                                timer: option.type === "timer" || option.type === "both",
                                stopwatch: option.type === "stopwatch" || option.type === "both",
                                active: true
                            });
                            hideAlert();
                            toggleLaunchButtonModal();
                        }}
                    >
                        <CardBody>
                            <div style={{ fontSize: "40px" }}>{option.icon}</div>
                            <CardTitle className="font-weight-bold" style={{color: '#004aad'}}>{option.title}</CardTitle>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </ReactBSAlert>
    );
};

const repairIdsAlert = () => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => fixInvalidContentBoxIds()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px'}}
      cancelBtnBsStyle="danger button-hover-fix"
      cancelBtnStyle={{borderRadius: '30px'}}
      confirmBtnText="Continue"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      This is a fix for an issue that is currently being tested for a few users. 
      If you've recently deleted one of the boxes in a Multi Box or "unstacked" a Multi Box and now have a box that you can't delete or edit, this is the solution for you.
      The bug that created this issue has also been patched.
    </ReactBSAlert>
  );
};

/**
 * 🛠️ Function to validate content box document IDs and update Firestore if necessary.
 * - Ensures each document ID matches its stored `contentBoxId`
 * - Renames documents if needed
 * - Ensures `contentBoxesOrder` in the class document contains all valid IDs
 */
 const fixInvalidContentBoxIds = async () => {
  if (!user?.uid || !classId || !contentBoxes?.length) {
    console.warn("❌ User, Class ID, or Content Boxes are missing.");
    return;
  }

  try {
    const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
    const contentBoxesRef = collection(classRef, FIREBASE_COLLECTIONS.CONTENT_BOXES);

    // Fetch class document
    const classSnap = await getDoc(classRef);
    const classData = classSnap.exists() ? classSnap.data() : {};
    let contentBoxesOrder = classData?.contentBoxesOrder || [];

    for (const box of contentBoxes) {
      const { contentBoxId } = box;
      const boxDocRef = doc(contentBoxesRef, box.id); // Using existing Firestore doc ID

      // 🟢 Fix incorrect document ID
      if (box.id !== contentBoxId) {
        console.warn(`⚠️ Fixing mismatched contentBoxId for doc ${box.id} → ${contentBoxId}`);

        const newBoxDocRef = doc(contentBoxesRef, contentBoxId);

        // Copy to correct document
        await setDoc(newBoxDocRef, { ...box, contentBoxId });

        // Delete incorrect document
        await deleteDoc(boxDocRef);
      }

      // 🟢 Ensure contentBoxId is in contentBoxesOrder
      if (!contentBoxesOrder.includes(contentBoxId)) {
        console.warn(`⚠️ Adding missing contentBoxId to order: ${contentBoxId}`);
        contentBoxesOrder.push(contentBoxId);
      }
    }

    // 🟢 Update Firestore with corrected contentBoxesOrder
    if (contentBoxesOrder.length > 0) {
      await updateDoc(classRef, { contentBoxesOrder });
      console.log("✅ contentBoxesOrder updated successfully.");
    }

    // 🔄 Refresh user data after update
    dispatch(fetchUserData(user.uid));

    hideAlert()
  } catch (error) {
    console.error("❌ Error fixing content box IDs:", error);
  }
};

  const deleteTemplateAlert = (index) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Are you sure?"
        onConfirm={() => deleteTemplate(index)}
        onCancel={() => cancelTemplateDelete()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this template!
      </ReactBSAlert>
    );
  };

  const successTemplateDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your template has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelTemplateDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your template is safe :)
      </ReactBSAlert>
    );
  };

  // const deleteTemplate = async (index) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     // Remove the template at the specified index
  //     userCopy.contentBoxTemplates.splice(index, 1);
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //     successTemplateDelete()
  
  //     console.log('Content box deleted successfully!');
  //   } catch (error) {
  //     console.error('Error deleting content box:', error);
  //   }
  // }

  const deleteTemplate = async (index) => {
    try {
        if (!user?.uid) {
            console.error("❌ Missing required parameter: User ID.");
            return;
        }

        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("🚨 User document not found.");
            return;
        }

        const userData = userSnap.data();
        const templates = userData.contentBoxTemplates || [];

        if (index < 0 || index >= templates.length) {
            console.error("❌ Invalid template index.");
            return;
        }

        // Remove the template at the specified index
        const updatedTemplates = [...templates];
        updatedTemplates.splice(index, 1);

        // Update Firestore document
        await updateDoc(userRef, {
            contentBoxTemplates: updatedTemplates,
        });

        successTemplateDelete();

        dispatch(fetchUserData(user.uid));

        console.log("✅ Content box template deleted successfully!");
    } catch (error) {
        console.error("❌ Error deleting content box template:", error);
    }
  };

  const [modal, setModal] = useState(false);
  const [toolType, setToolType] = useState(); // State to track which tool to display
  const TOOL_TYPE = {
    TIMER: 'TIMER',
    STOPWATCH: 'STOPWATCH',
    WHITEBOARD: 'WHITEBOARD',
    RANDOMIZER: 'RANDOMIZER'
  }

  const toggle = () => {
    setModal(!modal);
  };

  const openToolModal = (toolType) => {
    setToolType(toolType); // Set the tool type to display
    toggle(); // Open the modal
  };

  const [clockVersionModal, setClockVersionModal] = useState(false)

  const toggleClockVersionModal = () => {
    setClockVersionModal(!clockVersionModal)
  }

  const [selectedClockFaceOptionCard, setSelectedClockFaceOptionCard] = useState(null);
  const [hoveredClockFaceOptionCard, setHoveredClockFaceOptionCard] = useState(null);

  const handleMouseOverClockFaceOption = (index) => {
    setHoveredClockFaceOptionCard(index);
  };

  const handleMouseLeaveClockFaceOption = () => {
    setHoveredClockFaceOptionCard(null);
  };

  const handleClickClockFaceOption = (index) => {
    setSelectedClockFaceOptionCard(index);
    setClockVersion(index+1)
  };

  const handleClockFontColorChange = (e) => {
    setClockFontColor(e.target.value)
  }

  const handleClockLanguageChange = (e) => {
    setClockLanguage(e.target.value)
  }

  // const saveClockVersion = async () => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);

  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );

  //     userCopy.classes[classIndex].clockVersion = clockVersion;
  //     userCopy.classes[classIndex].clockFontColor = clockFontColor;
  //     userCopy.classes[classIndex].clockLanguage = clockLanguage;

  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  //     toggleClockVersionModal();

  //   } catch (error) {
  //     console.error('Error updating document:', error);
  //   }
  // }

  const saveClockVersion = async () => {
    try {
        if (!user?.uid || !classId) {
            console.error("❌ Missing required parameters: User ID or Class ID.");
            return;
        }

        const classRef = doc(
            db,
            FIREBASE_COLLECTIONS.USERS,
            user.uid,
            FIREBASE_COLLECTIONS.CLASSES,
            classId
        );

        const classSnap = await getDoc(classRef);

        if (!classSnap.exists()) {
            console.error("🚨 Class document not found.");
            return;
        }

        // Update Firestore document
        await updateDoc(classRef, {
            clockVersion,
            clockFontColor,
            clockLanguage,
        });

        toggleClockVersionModal();

        console.log("✅ Clock settings updated successfully!");

        dispatch(fetchUserData(user.uid));

    } catch (error) {
        console.error("❌ Error updating clock settings:", error);
    }
  };

  const [backgroundChangeElement, setBackgroundChangeElement] = useState('Background');

  const [backgroundModal, setBackgroundModal] = useState(false);

  const toggleBackgroundModal = () => {
    setBackgroundModal(!backgroundModal);
    if (backgroundModal) {
      saveBackground()
    }
  };

  // const saveBackground = async (fileFromUpload) => {
  //   console.log("fileFromUpload inside saveBackground => ", fileFromUpload)
  //   try {
  //     const docRef = doc(db, "users", user.uid);

  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );

  //     if (fileFromUpload) {
  //       backgroundChangeElement === 'Background' ? userCopy.classes[classIndex].background = fileFromUpload :
  //       backgroundChangeElement === 'Announcements' ? userCopy.classes[classIndex].backgroundAnnouncements = fileFromUpload :
  //       backgroundChangeElement === 'Clock' ? userCopy.classes[classIndex].backgroundClock = fileFromUpload :
  //       null
  //     } else {
  //       userCopy.classes[classIndex].background = background;
  //       userCopy.classes[classIndex].backgroundAnnouncements = backgroundAnnouncements;
  //       userCopy.classes[classIndex].backgroundClock = backgroundClock;
  //     }

  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //   } catch (error) {
  //     console.error('Error updating document:', error);
  //   }
  // }
  
  const saveBackground = async (fileFromUpload) => {
    // console.log("fileFromUpload inside saveBackground => ", fileFromUpload);

    try {
        if (!user?.uid || !classId) {
            console.error("❌ Missing required parameters: User ID or Class ID.");
            return;
        }

        const classRef = doc(
            db,
            FIREBASE_COLLECTIONS.USERS,
            user.uid,
            FIREBASE_COLLECTIONS.CLASSES,
            classId
        );

        const classSnap = await getDoc(classRef);

        if (!classSnap.exists()) {
            console.error("🚨 Class document not found.");
            return;
        }

        const updateFields = {};

        if (fileFromUpload) {
            if (backgroundChangeElement === "Background") {
                updateFields.background = fileFromUpload;
            } else if (backgroundChangeElement === "Announcements") {
                updateFields.backgroundAnnouncements = fileFromUpload;
            } else if (backgroundChangeElement === "Clock") {
                updateFields.backgroundClock = fileFromUpload;
            }
        } else {
            updateFields.background = background;
            updateFields.backgroundAnnouncements = backgroundAnnouncements;
            updateFields.backgroundClock = backgroundClock;
        }

        // Update Firestore document
        await updateDoc(classRef, updateFields);

        console.log("✅ Background updated successfully!");

        dispatch(fetchUserData(user.uid));

    } catch (error) {
        console.error("❌ Error updating background:", error);
    }
  };

  const [hoveredContentBoxModalOption, setHoveredContentBoxModalOption] = useState(null);

  const handleMouseOverContentBoxModalOption= (index) => {
    setHoveredContentBoxModalOption(index);
  };

  const handleMouseLeaveContentBoxModalOption = () => {
    setHoveredContentBoxModalOption(null);
  };

  const [hoveredWidgetModalOption, setHoveredWidgetModalOption] = useState(null);

  const handleMouseOverWidgetModalOption= (index) => {
    setHoveredWidgetModalOption(index);
  };

  const handleMouseLeaveWidgetModalOption = () => {
    setHoveredWidgetModalOption(null);
  };

  const [hoveredTemplateModalOption, setHoveredTemplateModalOption] = useState(null);

  const handleMouseOverTemplateModalOption= (index) => {
    setHoveredTemplateModalOption(index);
  };

  const handleMouseLeaveTemplateModalOption = () => {
    setHoveredTemplateModalOption(null);
  };

  const handleMoveLeft = (contentBoxId, contentBoxToLeftId) => {
    updateContentBoxOrder(contentBoxId, contentBoxToLeftId, 'left');
  };
  
  const handleMoveRight = (contentBoxId, contentBoxToRightId) => {
    updateContentBoxOrder(contentBoxId, contentBoxToRightId, 'right');
  };
  
  // const updateContentBoxOrder = async (contentBoxId, targetBoxId, direction) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     // Ensure a deep copy of the user object
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );
  
  //     if (classIndex !== -1) {
  //       const contentBoxes = userCopy.classes[classIndex].contentBoxes;
  //       const currentIndex = contentBoxes.findIndex(box => box.contentBoxId === contentBoxId);
  //       const targetIndex = contentBoxes.findIndex(box => box.contentBoxId === targetBoxId);
  
  //       if (currentIndex === -1 || targetIndex === -1) {
  //         console.error('Content box not found');
  //         return;
  //       }

  //       let newIndex;
  //       if (direction === 'left') {
  //         newIndex = targetIndex;
  //         if (currentIndex < newIndex) {
  //           newIndex--; // Adjust index for correct insertion
  //         }
  //       } else if (direction === 'right') {
  //         newIndex = targetIndex + 1;
  //         if (currentIndex < newIndex) {
  //           newIndex--; // Adjust index for correct insertion
  //         }
  //       }
  
  //       if (newIndex < 0 || newIndex >= contentBoxes.length) {
  //         return; // Invalid move
  //       }
  
  //       const [movedBox] = contentBoxes.splice(currentIndex, 1);
  //       contentBoxes.splice(newIndex, 0, movedBox);
  
  //       // Update the document in Firestore
  //       await setDoc(docRef, userCopy);
  //     } else {
  //       console.error('Class not found in user data');
  //     }
  //   } catch (error) {
  //     console.error('Error updating document:', error);
  //   }
  //   let newClockKey = clockKey
  //   setClockKey(++newClockKey); //This code is to prevent Clock and automations from stopping.
  // };


// /**
//  * Updates the order of content boxes in the contentBoxesOrder list of a class.
//  * @param {string} contentBoxId - The ID of the content box being moved
//  * @param {string} targetBoxId - The ID of the target content box (for determining new position)
//  * @param {string} direction - "left" or "right" for movement
//  */
// const updateContentBoxOrder = async (contentBoxId, targetBoxId, direction) => {
//   try {
//     const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
    
//     // 🔹 Fetch the class document
//     const classSnap = await getDoc(classRef);
//     if (!classSnap.exists()) {
//       console.error("Class document not found.");
//       return;
//     }

//     let contentBoxesOrder = classSnap.data().contentBoxesOrder || [];
    
//     const currentIndex = contentBoxesOrder.indexOf(contentBoxId);
//     const targetIndex = contentBoxesOrder.indexOf(targetBoxId);

//     if (currentIndex === -1 || targetIndex === -1) {
//       console.error("Content box not found in order list.");
//       return;
//     }

//     let newIndex;
//     if (direction === "left") {
//       newIndex = targetIndex;
//       if (currentIndex < newIndex) newIndex--; // Adjust index for insertion
//     } else if (direction === "right") {
//       newIndex = targetIndex + 1;
//       if (currentIndex < newIndex) newIndex--; // Adjust index for insertion
//     }

//     if (newIndex < 0 || newIndex >= contentBoxesOrder.length) {
//       return; // Prevent invalid moves
//     }

//     // 🔹 Move contentBoxId to the new position
//     contentBoxesOrder.splice(currentIndex, 1);
//     contentBoxesOrder.splice(newIndex, 0, contentBoxId);

//     // 🔹 Update the `contentBoxesOrder` field in Firestore
//     await updateDoc(classRef, { contentBoxesOrder });

//     dispatch(fetchUserData(user.uid))

//     console.log(`✅ Moved ${contentBoxId} to position ${newIndex}.`);
//   } catch (error) {
//     console.error("❌ Error updating content box order:", error);
//   }
// };

// /**
//  * Updates the order of content boxes in the contentBoxesOrder list of a class.
//  * Ensures missing content boxes are added to the list instead of failing and prevents duplicates.
//  * 
//  * @param {string} contentBoxId - The ID of the content box being moved
//  * @param {string} targetBoxId - The ID of the target content box (for determining new position)
//  * @param {string} direction - "left" or "right" for movement
//  */
//  const updateContentBoxOrder = async (contentBoxId, targetBoxId, direction) => {
//   try {
//     const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
    
//     // 🔹 Fetch the class document
//     const classSnap = await getDoc(classRef);
//     if (!classSnap.exists()) {
//       console.error("❌ Class document not found.");
//       return;
//     }

//     let contentBoxesOrder = classSnap.data().contentBoxesOrder || [];

//     // 🔹 Remove any existing instances of contentBoxId to prevent duplicates
//     contentBoxesOrder = contentBoxesOrder.filter(id => id !== contentBoxId);

//     let targetIndex = contentBoxesOrder.indexOf(targetBoxId);
//     let currentIndex = contentBoxesOrder.indexOf(contentBoxId);

//     // 🔹 Handle missing targetBoxId
//     if (targetIndex === -1) {
//       console.warn(`⚠️ targetBoxId (${targetBoxId}) not found in order list. Adding it.`);
//       contentBoxesOrder.push(targetBoxId);
//       targetIndex = contentBoxesOrder.length - 1;
//     }

//     // 🔹 Handle missing contentBoxId
//     if (currentIndex === -1) {
//       console.warn(`⚠️ contentBoxId (${contentBoxId}) not found in order list. Adding it.`);
//       contentBoxesOrder.push(contentBoxId);
//       currentIndex = contentBoxesOrder.length - 1;
//     }

//     // 🔹 Determine the new index based on direction
//     let newIndex;
//     if (direction === "left") {
//       newIndex = targetIndex;
//     } else if (direction === "right") {
//       newIndex = targetIndex + 1;
//     }

//     // 🔹 Prevent invalid moves
//     if (newIndex < 0 || newIndex > contentBoxesOrder.length) {
//       console.warn("🚫 Invalid move detected.");
//       return;
//     }

//     // 🔹 Remove contentBoxId again (in case it was added at the end)
//     contentBoxesOrder = contentBoxesOrder.filter(id => id !== contentBoxId);

//     // 🔹 Insert contentBoxId at the new position
//     contentBoxesOrder.splice(newIndex, 0, contentBoxId);

//     // 🔹 Update the `contentBoxesOrder` field in Firestore
//     await updateDoc(classRef, { contentBoxesOrder });

//     dispatch(fetchUserData(user.uid));

//     console.log(`✅ Moved ${contentBoxId} to position ${newIndex}.`);
//   } catch (error) {
//     console.error("❌ Error updating content box order:", error);
//   }
// };

/**
 * Optimistically updates the order of content boxes and then syncs it to Firestore.
 * Ensures missing content boxes are added instead of failing and prevents duplicates.
 *
 * @param {string} contentBoxId - The ID of the content box being moved
 * @param {string} targetBoxId - The ID of the target content box (for determining new position)
 * @param {string} direction - "left" or "right" for movement
 */
 const updateContentBoxOrder = async (contentBoxId, targetBoxId, direction) => {
  try {
    const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);

    setContentBoxesOrder((prevOrder) => {
      let newOrder = [...prevOrder];

      // Remove any duplicates of contentBoxId
      newOrder = newOrder.filter(id => id !== contentBoxId);

      let targetIndex = newOrder.indexOf(targetBoxId);
      let currentIndex = newOrder.indexOf(contentBoxId);

      // 🔹 Handle missing targetBoxId
      if (targetIndex === -1) {
        console.warn(`⚠️ targetBoxId (${targetBoxId}) not found in order list. Adding it.`);
        newOrder.push(targetBoxId);
        targetIndex = newOrder.length - 1;
      }

      // 🔹 Handle missing contentBoxId
      if (currentIndex === -1) {
        console.warn(`⚠️ contentBoxId (${contentBoxId}) not found in order list. Adding it.`);
        newOrder.push(contentBoxId);
        currentIndex = newOrder.length - 1;
      }

      // 🔹 Determine new position
      let newIndex = direction === "left" ? targetIndex : targetIndex + 1;
      newIndex = Math.max(0, Math.min(newIndex, newOrder.length - 1)); // Prevent out-of-bounds

      // Remove contentBoxId again (if added at the end)
      newOrder = newOrder.filter(id => id !== contentBoxId);

      // Insert contentBoxId at new position
      newOrder.splice(newIndex, 0, contentBoxId);

      // ✅ Firestore update inside the state setter function
      updateDoc(classRef, { contentBoxesOrder: newOrder })
        .then(() => {
          console.log(`✅ Firestore updated: Moved ${contentBoxId} to ${direction} position.`);
        })
        .catch((error) => console.error("❌ Firestore update failed:", error));

      return newOrder;
    });

    // 🔹 Fetch fresh data after update (optional, but recommended)
    dispatch(fetchUserData(user.uid));

  } catch (error) {
    console.error("❌ Error updating content box order:", error);
  }
};

  const [storageModal, setStorageModal] = useState(false);

  const toggleStorageModal = () => {
    hideAlert()
    setStorageModal(!storageModal)
  }

  const [launchButtonModal, setLaunchButtonModal] = useState(false);

  const toggleLaunchButtonModal = () => {
    setLaunchButtonModal(!launchButtonModal)
    setCurrentIndexLaunchModalContentBoxes(0)
    setCurrentIndexLaunchModalTemplates(0)
    let newClockKey = clockKey
    setClockKey(++newClockKey); //This code is to prevent Clock and automations from stopping.
  }

  const [currentIndexLaunchModalContentBoxes, setCurrentIndexLaunchModalContentBoxes] = useState(0);
  const visibleCards = 5;
  const totalCards = launchButtonModalData.length;

  const handleNextLaunchModalContentBoxes = () => {
      if (currentIndexLaunchModalContentBoxes < (totalCards +1) - visibleCards) {
          setCurrentIndexLaunchModalContentBoxes(currentIndexLaunchModalContentBoxes + 1);
      }
  };

  const handlePrevLaunchModalContentBoxes = () => {
      if (currentIndexLaunchModalContentBoxes > 0) {
          setCurrentIndexLaunchModalContentBoxes(currentIndexLaunchModalContentBoxes - 1);
      }
  };

  const [currentIndexLaunchModalTemplates, setCurrentIndexLaunchModalTemplates] = useState(0);
  const visibleCardsTemplates = 5;
  const totalCardsTemplates = templates?.length;

  const handleNextLaunchModalTemplates = () => {
      if (currentIndexLaunchModalTemplates < (totalCardsTemplates +1) - visibleCardsTemplates) {
        setCurrentIndexLaunchModalTemplates(currentIndexLaunchModalTemplates + 1);
      }
  };

  const handlePrevLaunchModalTemplates = () => {
      if (currentIndexLaunchModalTemplates > 0) {
        setCurrentIndexLaunchModalTemplates(currentIndexLaunchModalTemplates - 1);
      }
  };

  const [openWidgetModal, setOpenWidgetModal] = useState(false);
  const toggleOpenWidgetModal = () => {
    setOpenWidgetModal(!openWidgetModal)
  }

  const demoCountdownTimerProps = {
    duration: 20,
    header: "You must be in your seat by:",
    alertMessage: "Times Up!",
    alertSound: "short",
    youtube: false,
    youtubeSrc: "",
    active: true,
  }

  const [demoCountdownTimerModal, setDemoCountdownTimerModal] = useState()

  const toggleDemoCountdownTimerModal = () => {
    setDemoCountdownTimerModal(!demoCountdownTimerModal)
  }

  const darkMode = false;

  const isMobile = false // /Mobi/.test(navigator.userAgent);

  const [multiBoxChoices, setMultiBoxChoices] = useState();

  const [multiBoxSelectModal, setMultiBoxSelectModal] = useState();

  const toggleMultiBoxSelectModal = () => {
    setMultiBoxSelectModal(!multiBoxSelectModal)
  }

  const handleAddMultiBox = () => {

    if (multiBoxChoices.box1 !== undefined && multiBoxChoices.box2 !== undefined) {
      let firstBox = {}
      if (multiBoxChoices.box1.startsWith("id")) {
        const template = templates.find(x => x.template.contentBoxId === multiBoxChoices.box1)
        firstBox = {...template.template, contentBoxId: createRandomId()}
      } else {
        firstBox = {
          heading: "",
          active: true,
          show: true,
          content: null, // content should be null for all options
          [multiBoxChoices.box1]: true,
          contentBoxId: createRandomId(),
        }
      }
      
      let secondBox = {}
      if (multiBoxChoices.box2.startsWith("id")) {
        const template = templates.find(x => x.template.contentBoxId === multiBoxChoices.box2)
        secondBox = {...template.template, contentBoxId: createRandomId()}
      } else {
        secondBox = {
          heading: "",
          active: true,
          show: true,
          content: null, // content should be null for all options
          [multiBoxChoices.box2]: true,
          contentBoxId: createRandomId(),
        }
      }

      const boxToAdd = {
        multiBox: true,
        active: true,
        show: true,
        startDate: date.databaseFormattedDate,
        endDate: date.databaseFormattedDate,
        content: [firstBox, secondBox],
        heading: 'MultiBox'
      }
      addContentBox(boxToAdd)
      toggleMultiBoxSelectModal()
      toggleLaunchButtonModal()
    } else {
      window.alert('Must select two box choices...')
    }
  }

  //Code to prevent screen from timing out.
  if ('wakeLock' in navigator && user?.settings && user?.settings.screenTimeout !== '0') {
    let wakeLock = null;
    const wakeLockTimeout = (user.settings?.screenTimeout || 60) * 60 * 1000; // 45 minutes in milliseconds
  
    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request('screen');
  
        wakeLock.addEventListener('release', () => {
          console.log('Wake lock released');
          console.timeStamp();
        });
  
        // When the document becomes hidden, release the lock
        document.addEventListener('visibilitychange', async () => {
          if (wakeLock !== null && document.visibilityState === 'hidden') {
            await wakeLock.release();
            wakeLock = null;
          }
        });
  
        // Set timeout to release the wake lock after 45 minutes
        setTimeout(async () => {
          if (wakeLock !== null) {
            await wakeLock.release();
            wakeLock = null;
            console.log(`Wake lock released after ${wakeLockTimeout} minutes`);
          }
        }, wakeLockTimeout);
      } catch (e) {
        console.error(`${e.name}, ${e.message}`);
      }
    };
  
    // Request Wake Lock
    requestWakeLock();
  }




  const getBackgroundStyle = () => {
    if (background?.includes("firebasestorage")) {
      return {
        marginTop: '0px',
        backgroundImage: background, //`url("${background}")`
        backgroundSize: 'cover',  
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat', 
        minHeight: '300px' 
      };
    } else {
      return {
        marginTop: '0px',
        backgroundImage: background
      };
    }
  };

  return (
    <div 
      className="content"
      style={getBackgroundStyle()}
    >
    { user && user.settings && !user.settings?.alteredSchedule ? (
        <>
           <UseAutoNavigate clockKey={clockKey}/>
           <UseAutoCountdown clockKey={clockKey}/>
           <UseAutoReminder clockKey={clockKey}/>
           <UseAutoMusic />
           {classData && classData.autoHappyBirthday?.active && <UseAutoHappyBirthday clockKey={clockKey} classId={classId} classData={classData}/>}
        </>
    ) : null}

    {/* <OnboardingWizardModal isOpen={true} toggle={null} /> */}

{/**************************************************** ANNNOUNCEMENTS AND CLOCK *****************************************************/}

    {user && classes.length ?
      <>
       <Row style={{paddingTop: '12px'}}>
       {alert}
        {navbarMD && navbarMD.logo > 0 ?
        <Col md={navbarMD.logo} className="logo-col">
          <Card style={{height: '88px', marginRight: '5px'}}>
            <CardHeader style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CardTitle tag="h2">
                <img src={logoWithText} alt="react-logo" style={{ width: 'auto', height: '68px', marginTop: '-12px' }} />
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        : null}

        {navbarMD && navbarMD.marquee > 0 ?
        <Col md={navbarMD.marquee} style={{paddingLeft: '6px'}}>
          <Card className="marquee-card" style={{backgroundImage: backgroundAnnouncements}} onMouseEnter={() => setShowEditAnnouncementButton(true)} onMouseLeave={() => setShowEditAnnouncementButton(false)}>
            {showEditAnnouncementButton && !location.pathname.includes('/share/') && (
                <>
                  <NavLink 
                    to={{
                      pathname: "/announcement-form",
                      state: { classId: classId }
                    }}
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      id="EditAnnouncements"
                      type="button"
                      style={{
                        backgroundColor: "rgb(11, 192, 223)",
                        color: "white",
                        position: "absolute",
                        transform: "translateY(-60%)",
                        right: "-10px",
                        borderRadius: "30px",
                        height: "30px",
                        width: "30px",
                        padding: "6px 10px",
                      }}
                      size="sm"
                    >
                      <i className="fa fa-pencil" style={{ color: "white" }} />
                    </Button>
                  </NavLink>
                  <UncontrolledTooltip
                  delay={0}
                  target="EditAnnouncements"
                  placement="top"
                  >
                    Click to edit
                  </UncontrolledTooltip>
                </>
              )}
            <CardHeader style={{padding: '7px 15px 0px'}}>
              <CardTitle tag="h1" style={{cursor: 'default'}}>
                <Marquee speed={user && user.settings && user.settings?.marqueeSpeed ? user.settings?.marqueeSpeed : '125'} gradientWidth="0" style={{overflow: 'hidden'}}>
                {announcements && announcements.map((announcement, index) => (
                    
                    <div
                      key={`023ebadoibas01-${index}`}
                      // style={{ fontSize: '30px', lineHeight: '1'}}
                    >
                    {ReactHtmlParser(announcement.html)}
                    </div>
                ))}
                </Marquee>
              </CardTitle>
            </CardHeader>
          </Card>
        </Col>
        : null}

        {navbarMD && navbarMD.clock > 0 ?
        <Col md={navbarMD.clock} className="clock-col" style={{paddingRight: '6px'}}>
          <Card className="clock-card" style={{backgroundImage: backgroundClock}} onMouseEnter={() => setShowEditButton(!showEditButton)} onMouseLeave={() => setShowEditButton(!showEditButton)}> 
            {showEditButton && !location.pathname.includes('/share/') && (
              <Button
                // className="btn-round btn-icon"
                // id="editClockVersion"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-10px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px'}}
                size="sm"
                onClick={toggleClockVersionModal}
              >
                <i className="fa fa-pencil" />
              </Button>
            )}
            <CardHeader style={{padding: '0px 0px 0px', cursor: 'default'}}>
                <Clock 
                  showSeconds={user && user.settings && user.settings?.showSeconds} 
                  clockVersion={clockVersion || selectedClockFaceOptionCard+1} 
                  clockFontColor={clockFontColor} 
                  clockLanguage={clockLanguage} 
                  clockKey={clockKey}
                />
            </CardHeader>
          </Card>
          <Modal
            isOpen={clockVersionModal}
            toggle={toggleClockVersionModal}
            backdrop={true}
            scrollable
            style={{ padding: '20px' }}
            className="clock-version-modal"
          >
            <ModalHeader toggle={toggleClockVersionModal}>
              <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Set Clock Version</h4>
            </ModalHeader>
            <ModalBody>
              <Container>
                <Row xs="2">
                  {[1, 2, 3, 4, 5].map((clockVersion, index) => (
                    <Col key={`present-clock-version-modal-col-${index}`}>
                      <Card
                        key={`23jaijkasdf023kd-${clockVersion}-${index}`}
                        style={{
                          cursor: 'pointer',
                          height: '95px',
                          border: selectedClockFaceOptionCard === index ? '2px solid blue' : (hoveredClockFaceOptionCard === index ? '1px solid blue' : 'none'),
                          backgroundImage: backgroundClock,
                        }}
                        onMouseOver={() => handleMouseOverClockFaceOption(index)}
                        onMouseLeave={handleMouseLeaveClockFaceOption}
                        onClick={() => handleClickClockFaceOption(index)}
                      >
                        <CardHeader style={{ padding: '0px 0px 0px 0px' }}>
                          <Clock clockVersion={clockVersion} clockFontColor={clockFontColor} showSeconds={user && user.settings && user.settings?.showSeconds} clockLanguage={clockLanguage}/>
                        </CardHeader>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
              <Row>
                {/* <FormGroup> */}
                <Col>
                  <Label for="exampleColor">
                    Font Color
                  </Label>
                  <Input
                    id="exampleColor"
                    name="color"
                    placeholder="color placeholder"
                    type="color"
                    value={clockFontColor}
                    style={{height: '50px'}}
                    onChange={(e) => handleClockFontColorChange(e)}
                  />
                </Col>
                <Col>
                  <Label for="language">
                    Language
                  </Label>
                  <Input
                    id="language"
                    name="language"
                    placeholder="language"
                    type="select"
                    value={clockLanguage}
                    style={{height: '50px'}}
                    onChange={(e) => handleClockLanguageChange(e)}
                  >
                    <option>English</option>
                    <option>French</option>
                    <option>Spanish</option>
                  </Input>
                </Col>
                {/* </FormGroup> */}
              </Row>
            </ModalBody>
            <ModalFooter
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: 'none'
              }}
            >
              <Button
                className="button-hover-fix"
                onClick={saveClockVersion}
                style={{
                  padding: "10px 30px",
                  borderRadius: "30px",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                <i className='fa fa-save'/>{' '}Save Clock Version
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
        : null}
      </Row>


{/**************************************************** CONTENT BOXES *****************************************************/}

          <Modal
            isOpen={multiBoxSelectModal}
            toggle={toggleMultiBoxSelectModal}
            backdrop={true}
            scrollable
            style={{ padding: '20px', maxWidth: '625px' }}
            className="clock-version-modal"
          >
            <ModalHeader toggle={toggleMultiBoxSelectModal}>
              <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>MultiBox Options</h4>
            </ModalHeader>
            <ModalBody>
              <Row xs='2'>
                <FormGroup>
                  <Label for="box1">
                    Box 1 Choice
                  </Label>
                  <Input
                    id="box1"
                    name="box1"
                    placeholder="color placeholder"
                    type="select"
                    value={null}
                    style={{height: '40px', width: '250px', borderRadius: '8px'}}
                    onChange={(e) => setMultiBoxChoices({...multiBoxChoices, box1: e.target.value})}
                    defaultValue={'none'}
                  >
                    <option disabled value="none">Pick one...</option>
                    <option value="textEditor">Text Editor Box</option>
                    <option value="slideshow">Google Slides Box</option>
                    <option value="googleDocSheet">Google Doc Box</option>
                    <option value="canva">Canva Box</option>
                    <option value="timer">Timer Box</option>
                    <option value="stopwatch">Stopwatch Box</option>
                    <option value="countdown">Countdown Box</option>
                    <option value="pdf">PDF Box</option>
                    <option value="powerPoint">PowerPoint Box</option>
                    <option value="iFrame">Web Box</option>
                    <hr></hr>
                    <optgroup label="Your Templates">
                      {templates && templates.map((box) => <option key={`multiBox-template-option1-${box.template.contentBoxId}`} value={box.template.contentBoxId}>{box.name}</option> )}
                    </optgroup>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="box2">
                    Box 2 Choice
                  </Label>
                  <Input
                    id="box2"
                    name="box2"
                    placeholder="Box 2"
                    type="select"
                    value={null}
                    style={{height: '40px', width: '250px', borderRadius: '8px'}}
                    onChange={(e) => setMultiBoxChoices({...multiBoxChoices, box2: e.target.value})}
                    defaultValue={'none'}
                  >
                    <option disabled value="none">Pick one...</option>
                    <option value="textEditor">Text Editor Box</option>
                    <option value="slideshow">Google Slides Box</option>
                    <option value="googleDocSheet">Google Doc Box</option>
                    <option value="canva">Canva Box</option>
                    <option value="timer">Timer Box</option>
                    <option value="stopwatch">Stopwatch Box</option>
                    <option value="countdown">Countdown Box</option>
                    <option value="pdf">PDF Box</option>
                    <option value="powerPoint">PowerPoint Box</option>
                    <option value="iFrame">Web Box</option>
                    <hr></hr>
                    <optgroup label="Your Templates">
                      {templates && templates.map((box) => <option key={`multiBox-template-option2-${box.template.contentBoxId}`} value={box.template.contentBoxId}>{box.name}</option> )}
                    </optgroup>
                  </Input>
                </FormGroup>

              </Row>
            </ModalBody>
            <ModalFooter
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: 'none'
              }}
            >
              <Button
                className="button-hover-fix"
                onClick={handleAddMultiBox}
                style={{
                  padding: "10px 30px",
                  borderRadius: "30px",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                <i className='fa fa-plus'/>{' '}Add MultiBox
              </Button>
            </ModalFooter>
          </Modal>


          
    <div className="present-style-row" style={{top: navbarMD.clock === '0' && navbarMD.marquee === '0' ? '15px' : '120px'}}>
    {noActiveBoxesAlert &&
      <Row style={{ flex: 1, alignContent: "center" }}>
      <Col
      md={{
        offset: 3,
        size: 6
      }}
      sm="12"
    >
      <Card body className="text-center" style={{ marginBottom: '0px', border: '1px solid red'}}>
        <CardHeader className="h2" style={{marginBottom: '0px'}}>{user.settings?.useActiveDates ? "NO CONTENT SCHEDULED" : "NO CONTENT"}</CardHeader>
        <CardBody>
          {/* <div>
            If you haven't added content to this class previously, you can get started by clicking the "Launch" button below.
          </div>
          <br></br> */}
          <div className="h6">
            {/* To create content for this date <span onClick={toggleLaunchButtonModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>. */}
            {user.settings?.useActiveDates ? "To create content for this date click the Launch button." : "To create content for this class click the Launch button."}
          </div>
          <br></br>
          <div className="h6">
            {user.settings?.useActiveDates ? "To access your previously created content and edit the active dates," : "To access your previously created content,"} <span onClick={toggleStorageModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>.
          </div>
        </CardBody>
      </Card>
    </Col>
    </Row>
    }
    {noBoxesSetToShowAlert &&
      <Row style={{ flex: 1, alignContent: "center" }}>
        <Col
          md={{
            offset: 3,
            size: 6
          }}
          sm="12"
        >
          <Card body className="text-center" style={{ marginBottom: '0px', border: '1px solid red'}}>
            <CardHeader className="h2" style={{marginBottom: '0px'}}>NO BOXES SET TO SHOW</CardHeader>
            <CardBody>
              {/* <div>
                If you haven't added content to this class previously, you can get started by clicking the "Launch" button below.
              </div>
              <br></br> */}
              <div className="h6">
                {/* To create content for this date <span onClick={toggleLaunchButtonModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>. */}
                CLICK ONE OF YOUR CONTENT BOX TABS BELOW TO TOGGLE IT TO "SHOW"
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    }
    {loadComplete && boxes && boxes.length > 0 ? (
      <Row style={{ flex: 1 }}>
      <PanelGroup 
        direction="horizontal"
        key={`panelGroup-${classId}`}
        autoSaveId={`panelSaveId-${classId}`}
        style={{overflow: 'visible'}}
      >
      {boxes && boxes.filter(box => box.show === true).map((box, index) => 
          <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
            <Panel 
              defaultSize={30}
              minSize={25}
              maxSize={75}
              style={{overflow: 'visible', position: 'relative'}}
              key={`panel-${classId}-${index}`}
            >
              {box === undefined ?
                <></>
              : box.slideshow ?
                <>
                    <GoogleSlidesBox
                      key={`googleSlidesBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      view={view}
                      user={user}
                      classes={classes}
                      classId={classId}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
              : box.googleDocSheet ?
              <>
                  <GoogleDocSheetBox
                    key={`googleDocBox-${classId}-${index}-${box.contentBoxId}`}
                    content={box}
                    navbarMD={navbarMD}
                    view={view}
                    user={user}
                    classes={classes}
                    classId={classId}
                    multiBox={false}
                    updateContentBox={updateContentBox}
                    navigationVisible={navigationVisible}
                  />
              </>
              : (box.timer || box.stopwatch) ? 
                <>
                    <TimerStopwatchContentBox
                      key={`timerStopwatchBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classId}
                      view={view}
                      user={user}
                      classes={classes}
                      multiBox={false}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.agendaBox) ? 
                <>
                    <AgendaBox
                      key={`agendaBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classId}
                      view={view}
                      user={user}
                      classes={classes}
                      multiBox={false}
                      clockKey={clockKey}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.multiBox) ? 
                <>
                    <MultiBox
                      key={`multiBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classId}
                      view={view}
                      user={user}
                      classes={classes}
                      addContentBox={addContentBox}
                      multiBox={false}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.carousel) ? 
              <>
                  <CarouselBox
                    key={`carouselBox-${classId}-${index}-${box.contentBoxId}`}
                    content={box}
                    navbarMD={navbarMD}
                    classId={classId}
                    view={view}
                    user={user}
                    classes={classes}
                    addContentBox={addContentBox}
                    multiBox={false}
                    navigationVisible={navigationVisible}
                  />
              </>
              : (box.canva) ?
                <>
                    <CanvaBox
                      key={`canvaBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classId}
                      view={view}
                      user={user}
                      classes={classes}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
                : (box.iFrame) ?
                <>
                    <IframeBox
                      key={`IframeBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classId}
                      view={view}
                      user={user}
                      classes={classes}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                      setClockKey={setClockKey}
                    />
                </>
               : (box.pdf) ?
                <>
                   <PdfBox
                     key={`pdfBox-${classId}-${index}-${box.contentBoxId}`}
                     content={box}
                     navbarMD={navbarMD}
                     classId={classId}
                     view={view}
                     user={user}
                     classes={classes}
                     addContentBox={addContentBox}
                     multiBox={false}
                     updateContentBox={updateContentBox}
                     navigationVisible={navigationVisible}
                   />
               </>
               : (box.powerPoint) ?
               <>
                   <PowerpointBox
                     key={`powerPointBox-${classId}-${index}-${box.contentBoxId}`}
                     content={box}
                     navbarMD={navbarMD}
                     classId={classId}
                     view={view}
                     user={user}
                     classes={classes}
                     addContentBox={addContentBox}
                     multiBox={false}
                     updateContentBox={updateContentBox}
                     navigationVisible={navigationVisible}
                   />
               </>
              : (box.countdown) ?
                <>
                    <CountdownBox
                      key={`countdownBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classId}
                      view={view}
                      user={user}
                      classes={classes}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
              : 
                <>
                    <ContentBox
                      key={`contentBox-${classId}-${index}-${box.contentBoxId}`}
                      content={box}
                      updateContentBox={updateContentBox}
                      classId={classId}
                      user={user}
                      classes={classes}
                      navbarMD={navbarMD}
                      view={view}
                      multiBox={false}
                      readOnly={location.pathname.includes('/share/')}
                      singleLineFormattingToolbar={!classData?.showMarquee && !classData?.showClock}
                      navigationVisible={navigationVisible}
                    />
                </>
              }
            </Panel>
            {
              index+1 < numberOfActiveBoxes ? (
                <PanelResizeHandle key={`panelResize-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10px'}}>
                  <i className="fa fa-exchange" style={{margin: '-7px'}}></i>
                </PanelResizeHandle>
              ) : null
            }
        </React.Fragment>
      )}
    </PanelGroup>
    </Row>
  ) : loadComplete && !location.pathname.includes('/share/') ? (
    <></>
  ) : loadComplete && location.pathname.includes('/share/') ? (
    <Row style={{ flex: 1, transform: 'translateY(-60px)' }}>
      <Col
      md={{
        offset: 0,
        size: 5
      }}
      sm="12"
    >
      <Card body className="text-center" style={{ top: '100px' , marginBottom: '0px', border: '1px solid red'}}>
        <CardHeader className="h2" style={{marginBottom: '0px'}}>
          <i className="fa fa-arrow-left" style={{marginRight: '20px'}}/>
          <i className="fa fa-arrow-left" style={{marginRight: '20px'}}/>
          <i className="fa fa-arrow-left" style={{marginRight: '20px'}}/>
          SELECT YOUR CLASS
        </CardHeader>
      </Card>
    </Col>
    </Row>
  ) : (
    <Spinner color="primary">
      Loading...
    </Spinner>
  )}
   </div>

{/**************************************************** BOTTOM NAVBAR *****************************************************/}
    {loadComplete && navigationVisible ?
      <Row className="present-navbar-launch-section">

{/**************************************************** LAUNCH BUTTON *****************************************************/}
        {/* <AddContentBoxModal /> */}
        <Modal className="launch-modal" modalClassName="launch-modal" isOpen={launchButtonModal} toggle={toggleLaunchButtonModal} style={{minWidth: '71vw'}} unmountOnClose={false}>
        <ModalHeader toggle={toggleLaunchButtonModal} />
          <Card style={{marginBottom: '0px', overflowX: 'scroll'}}>
            <CardBody style={{paddingTop: '0px'}}>
              <CardTitle className="h5" style={{ fontWeight: "bold", color: "#004aad" }}>Add Content Box</CardTitle>
              <Container>
                <div style={{ display: 'flex', alignItems: 'center', height: '250px' }}>
                    <i 
                      className="nc-icon nc-minimal-left" 
                      onClick={handlePrevLaunchModalContentBoxes} 
                      style={{ marginRight: '5px', fontSize: '40px', cursor: 'pointer' }}
                    />
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', transform: `translateX(-${currentIndexLaunchModalContentBoxes * 200}px)`, transition: 'transform 0.3s ease' }}>
                            {launchButtonModalData.map((box, index) => (
                                <Col key={`iepcv2309anadf-${index}`} style={{ flex: '0 0 175px', padding: '10px' }}>
                                    <Card
                                        className="text-center"
                                        style={{
                                            padding: hoveredContentBoxModalOption === index ? '0px' : '1px',
                                            border: hoveredContentBoxModalOption === index ? '2px solid blue' : '2px solid black',
                                            cursor: box.disabled ? null : 'pointer',
                                            width: '175px',
                                            backgroundColor: box.disabled ? 'rgba(0,0,0,0.5)' : null
                                        }}
                                        onMouseOver={(e) => {box.disabled ? null : handleMouseOverContentBoxModalOption(index); e.currentTarget.style.transform = "scale(1.02)"}}
                                        onMouseLeave={(e) => {box.disabled ? null : handleMouseLeaveContentBoxModalOption(); e.currentTarget.style.transform = "scale(1)"}}
                                        onClick={() => {
                                            if (box.disabled) {
                                              return
                                            } else if (box.title === "Google Slides Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Google Slides',
                                                    show: true,
                                                    userHeading: true,
                                                    slideshow: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "PowerPoint Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'PowerPoint',
                                                    show: true,
                                                    userHeading: true,
                                                    powerPoint: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Google Doc Box") {
                                              addContentBox({
                                                  contentBoxId: createRandomId(),
                                                  startDate: date.databaseFormattedDate,
                                                  endDate: date.databaseFormattedDate,
                                                  heading: 'Google Doc',
                                                  show: true,
                                                  userHeading: true,
                                                  googleDocSheet: true,
                                                  active: true,
                                                  content: null
                                              });
                                            } else if (box.title === "Countdown Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Countdown',
                                                    show: true,
                                                    userHeading: true,
                                                    countdown: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Canva Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Canva',
                                                    show: true,
                                                    userHeading: true,
                                                    canva: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Web Box") {
                                              addContentBox({
                                                  contentBoxId: createRandomId(),
                                                  startDate: date.databaseFormattedDate,
                                                  endDate: date.databaseFormattedDate,
                                                  heading: 'Web Box',
                                                  show: true,
                                                  userHeading: true,
                                                  iFrame: true,
                                                  active: true,
                                                  content: null
                                              });
                                            } else if (box.title === "PDF Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'PDF',
                                                    show: true,
                                                    userHeading: true,
                                                    pdf: true,
                                                    active: true,
                                                    content: null
                                                });
                                            } else if (box.title === "Timer/Stopwatch") {
                                                toggleTimerStopwatchAlert();
                                            } else if (box.title === "MultiBox") {
                                                toggleMultiBoxSelectModal();
                                            } else if (box.title === "Carousel Box") {
                                                addContentBox({
                                                    carousel: true,
                                                    active: true,
                                                    show: true,
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    content: [],
                                                    heading: 'Carousel Box'
                                                })
                                            } else if (box.title === "Agenda Box") {
                                                addContentBox({
                                                    contentBoxId: createRandomId(),
                                                    startDate: date.databaseFormattedDate,
                                                    endDate: date.databaseFormattedDate,
                                                    heading: 'Agenda',
                                                    show: true,
                                                    userHeading: true,
                                                    agendaBox: true,
                                                    active: true,
                                                    startTime: '00:00',
                                                    steps: []
                                                });
                                            } else if (box.title === "Text Editor Box") {
                                                addContentBox();
                                            }
                                        }}
                                    >
                                        <CardImg
                                            alt="Card image cap"
                                            src={box.image}
                                            style={{
                                                height: box.beta ? 80 : 120,
                                                marginTop: '10px',
                                                color: 'red'
                                            }}
                                            top
                                            width="100%"
                                        />
                                        <CardBody>
                                            {box.beta && 
                                              <CardTitle>
                                                {box.beta}
                                              </CardTitle>
                                            }
                                            <CardTitle className="font-weight-bold" style={{color: '#004aad'}}>
                                              {box.title}
                                            </CardTitle>
                                            <CardText>
                                              {box.description}
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </div>
                    </div>
                    <i className="nc-icon nc-minimal-right" onClick={handleNextLaunchModalContentBoxes} style={{ marginLeft: '5px', fontSize: '40px', cursor: 'pointer' }} />
                </div>
            </Container>
              <hr></hr>
                <CardTitle className="h5" style={{ fontWeight: "bold", color: "#004aad" }}>Your Templates</CardTitle>
                <Container style={{marginBottom: '15px'}}>
                  <div style={{ display: 'flex', alignItems: 'center', height: '250px', paddingTop: '16px' }}>
                    <i className="nc-icon nc-minimal-left" onClick={templates?.length > 4 ? handlePrevLaunchModalTemplates : undefined} style={{ marginRight: '5px', fontSize: '40px', cursor: templates?.length < 5 ? null : 'pointer', color: templates?.length < 5 ? 'white' : 'black' }} />
                    <div style={{ display: 'flex', overflow: 'hidden' }}>
                        <div style={{ display: 'flex', transform: `translateX(-${currentIndexLaunchModalTemplates * 200}px)`, transition: 'transform 0.3s ease', paddingTop: '16px' }}>
                          {templates && templates.length > 0 ? templates.map((template, index) => {
                            return (
                              <Col key={`pasdfioweb823b-${index}`} style={{ flex: '0 0 175px', padding: '10px' }}>
                                <Card
                                  className="text-center"
                                  style={{
                                    padding: hoveredTemplateModalOption === index ? '7px' : '10px',
                                    border: hoveredTemplateModalOption === index ? '7px solid blue' : '7px solid black',
                                    cursor: 'pointer',
                                    zoom: '.33',
                                    borderRadius: '35px',
                                    width: '525px',
                                    height: '700px',
                                  }}
                                  // onMouseOver={(e) => {handleMouseOverTemplateModalOption(index); e.currentTarget.style.transform = "scale(1.02)"}}
                                  // onMouseLeave={(e) => {handleMouseLeaveTemplateModalOption(); e.currentTarget.style.transform = "scale(1)"}}
                                  onMouseOver={(e) => {handleMouseOverTemplateModalOption(index);}}
                                  onMouseLeave={(e) => {handleMouseLeaveTemplateModalOption();}}
                                  onClick={() => addContentBox({...template.template, contentBoxId: createRandomId()})}
                                >
                                  <Button
                                    className="button-delete"
                                    id="deleteContentBox"
                                    type="button"
                                    style={{ color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-36px', borderRadius: '100px', height: '100px', width: '100px', padding: '4px 9px', overflow: 'visible', zIndex: '9999'}}
                                    size="sm"
                                    onClick={(e) => {
                                      e.stopPropagation(); // 👈 Prevents event from bubbling to the Card
                                      deleteTemplateAlert(index);
                                    }}
                                  >
                                    <i className="fa fa-trash" style={{fontSize: '50px'}}/>
                                  </Button>
                                    <UncontrolledTooltip
                                      delay={0}
                                      target="deleteContentBox"
                                      placement="left"
                                    >
                                        Delete
                                    </UncontrolledTooltip>
                                  <div style={{overflow: 'hidden'}}>
                                  {template.template.multiBox ? 
                                    <div>
                                      <br></br>
                                      <h1>{template.template.heading}</h1>
                                      <h1 style={{border: "5px solid black", height: '250px', borderRadius: '20px', marginLeft: "10px", marginRight: '10px'}}>{template.template.content.heading}</h1>
                                    </div> 
                                  : template.template.agendaBox ?
                                    <div>
                                      <AgendaBox content={template.template} readOnly/>
                                    </div>
                                  : template.template.pdf ?
                                   <div>
                                     <PdfBox content={template.template} readOnly/>
                                   </div>
                                  :
                                    <div style={{}}>
                                      <ContentBox content={template.template} readOnly/>
                                    </div>
                                  }
                                  </div>
                                </Card>
                              </Col>
                            )
                          }) : <div>No Templates Added</div>}
                        </div>
                    </div>
                    <i className="nc-icon nc-minimal-right" onClick={templates?.length > 4 ? handleNextLaunchModalTemplates : undefined} style={{ marginRight: '5px', fontSize: '40px', cursor: templates?.length < 5 ? null : 'pointer', color: templates?.length < 5 ? 'white' : 'black' }} />
                  </div>
                </Container>
            </CardBody>
          </Card>
        </Modal>



        <Modal isOpen={openWidgetModal} toggle={toggleOpenWidgetModal} style={{minWidth: '65vw'}} className="open-widget-modal">
        <ModalHeader toggle={toggleOpenWidgetModal} />
          <Card style={{marginBottom: '0px', marginTop: '0px', overflowX: 'scroll'}}>
            <CardBody style={{paddingTop: '0px'}}>
              <CardTitle className="h5" style={{ fontWeight: "bold", color: "#004aad" }}>Open Widget</CardTitle>
                <Container>
                  <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'none !important', overflowX: "scroll", overflowY: 'hidden', height: '250px'}}>
                    {launchButtonWidgetData.map((box, index) => {
                      return(
                        <Col key={`launch-button-widget-picker-choices-${index}`} style={{padding: '10px'}}>
                          <Card 
                            className="text-center"
                            style={{
                              padding: hoveredWidgetModalOption === index ? '0px' : '1px',
                              border: hoveredWidgetModalOption === index ? '2px solid blue' : '1px solid black',
                              cursor: 'pointer',
                              width: '175px',
                            }}
                            onMouseOver={(e) => {handleMouseOverWidgetModalOption(index); e.currentTarget.style.transform = "scale(1.02)"}}
                            onMouseLeave={(e) => {handleMouseLeaveWidgetModalOption(); e.currentTarget.style.transform = "scale(1)"}}
                            onClick={() => {
                              box.title === "Timer" ?  openToolModal(TOOL_TYPE.TIMER) :
                              box.title === "Randomizer" ?  openToolModal(TOOL_TYPE.RANDOMIZER) :
                              box.title === "Stopwatch" ?  openToolModal(TOOL_TYPE.STOPWATCH) :
                              box.title === "Whiteboard" ?  openToolModal(TOOL_TYPE.WHITEBOARD) : null
                             }}
                          >
                            <CardImg
                              alt="Card image cap"
                              src={box.image}
                              style={{
                                height: 120,
                                marginTop: '10px'
                              }}
                              top
                              width="100%"
                            />
                            <CardBody>
                              <CardTitle className="font-weight-bold">
                                {box.title}
                              </CardTitle>
                              <CardText style={{height: '40px'}}>
                                {box.description}
                              </CardText>
                            </CardBody>                      
                          </Card>
                        </Col>
                      )
                    })}
                  </div>
                </Container>
            </CardBody>
          </Card>
        </Modal>
        <Dropdown 
          direction='up' 
          isOpen={isLaunchButtonOpen} 
          toggle={() => !share ? setIsLauncButtonOpen(!isLaunchButtonOpen) : undefined}
          // toggle={toggleLaunchButtonModal}
          style={{ top: 'auto !important', bottom: '0 !important', marginTop: '0', marginBottom: '0.125rem', cursor: share ? 'default' : 'pointer'}}
        >
          {!isLaunchButtonOpen && (
            <UncontrolledTooltip
              delay={0}
              target={"caret"}
              placement="top"
            >
              Click to Add Boxes and Tools
            </UncontrolledTooltip>
          )}
            <DropdownToggle
              className="btn bottom-navbar launch-button"
              id="caret"
            >
              <img src={logo} alt="react-logo" style={{ width: '50px', height: 'auto', marginRight: '5px' }} />
              <div>Launch</div>
            </DropdownToggle>

          <DropdownMenu className="launchButtonDropdown" style={{ maxHeight: '90vh', overflowY: 'scroll'}}>
            <Modal 
              isOpen={backgroundModal}
              toggle={toggleBackgroundModal}
              backdrop={false}
              scrollable
            >
              <ModalHeader toggle={toggleBackgroundModal} className="background-modal-header" />
              <ModalBody style={{padding: '0'}} className="agenda-body">
                <Container >
                  <Row>
                    <ButtonGroup className="w-100">
                      <Col xs={4}>
                        <Button
                          outline
                          block
                          onClick={() => setBackgroundChangeElement('Background')}
                          active={backgroundChangeElement === 'Background'}
                          style={{borderRadius: '30px'}}
                        >
                          Background
                        </Button>
                      </Col>
                      <Col xs={4}>
                        <Button
                          outline
                          block
                          onClick={() => setBackgroundChangeElement('Announcements')}
                          active={backgroundChangeElement === 'Announcements'}
                          style={{borderRadius: '30px'}}

                        >
                          Announcements
                        </Button>
                      </Col>
                      <Col xs={4}>
                        <Button
                          outline
                          block
                          onClick={() => setBackgroundChangeElement('Clock')}
                          active={backgroundChangeElement === 'Clock'}
                          style={{borderRadius: '30px'}}
                        >
                          Clock
                        </Button>
                      </Col>
                    </ButtonGroup>
                  </Row>
                  <Row xs="6">
                  {/* *****The below backgroundOptionsData mapping logic is to add the upload tile here, otherwise all the other background pickers get it as well.***** */}
                  {[
                      backgroundOptionsData[0], // First element remains unchanged
                      { name: 'upload', thumbnail: '/upload.png', image: '' }, // Insert the new map here
                      { name: 'color-picker', thumbnail: '/color-picker.png', image: '' }, // Insert the new map here
                      ...backgroundOptionsData.slice(1) // Spread the remaining elements
                    ].map((option, index) => (
                      <Col 
                        key={`2029340h8q0fbf-${index}`}
                        onClick={() => {
                          option.name === 'upload' ? window.alert("Coming Soon! :)") : //toggleFileUpload()
                          option.name === 'color-picker' ? window.alert("Coming Soon! :)") :
                          option.name === 'transparent' ? window.alert("Coming Soon! :)") :
                          backgroundChangeElement === 'Background' ? setBackground(`url(${option.image})`) :
                          backgroundChangeElement === 'Announcements' ? setBackgroundAnnouncements(`url(${option.image})`) :
                          backgroundChangeElement === 'Clock' ? setBackgroundClock(`url(${option.image})`) :
                          null
                        }}
                        style={{ padding: '0px', cursor: 'pointer' }}
                      >
                        <div className="image-container">
                          <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Container>
              </ModalBody>
            </Modal>
            <FilePicker toggleFilePickerModal={toggleFileUpload} fileType={'images'} isOpen={fileUploadModal} onOpenClick={setBackgroundWithUploadedFile} />

{/**************************************************** LAUNCH Button *****************************************************/}

            <DropdownItem onClick={toggleLaunchButtonModal}>Add Content Box</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={toggleOpenWidgetModal}>Open Tool/Widget</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={toggleBackgroundModal}>Change Background</DropdownItem>
            <DropdownItem divider/>
            <Modal
              onKeyDown={(e) => e.stopPropagation()}
              isOpen={modal}
              toggle={toggle}
              className="widget-modal"
              contentClassName={
                toolType === TOOL_TYPE.WHITEBOARD ? "whiteboard-modal" 
                : toolType === TOOL_TYPE.RANDOMIZER ? "randomizer-modal"
                : null}
              modalClassName={toolType === TOOL_TYPE.WHITEBOARD ? "whiteboard"
                : toolType === TOOL_TYPE.RANDOMIZER ? "randomizer"
                : null}>
              <ModalHeader toggle={toggle} style={{borderBottom: toolType === TOOL_TYPE.STOPWATCH || toolType === TOOL_TYPE.TIMER ? 'none' : null}}/>
              <ModalBody style={{padding: '0'}} className="agenda-body">
                {toolType === TOOL_TYPE.TIMER && <Timer />}
                {toolType === TOOL_TYPE.STOPWATCH && <Stopwatch />}
                {toolType === TOOL_TYPE.WHITEBOARD && <Whiteboard props={{current: classId}}/>}
                {toolType === TOOL_TYPE.RANDOMIZER && <Randomizer props={{current: classId}}/>}
              </ModalBody>
            </Modal>
            <DropdownItem onClick={() => toggleStorageModal()}>View Storage</DropdownItem>
            <DropdownItem divider/>
            <DropdownItem onClick={() => repairIdsAlert()}>⚠️ Repair Content Box Ids</DropdownItem>
            <Modal
              modalClassName="content-boxes-table"
              contentClassName={user.settings.useActiveDates ? "content-box-storage-table-active-dates" : "content-box-storage-table"} 
              isOpen={storageModal} 
              toggle={toggleStorageModal}
            >
              <ModalHeader toggle={toggleStorageModal} style={{padding: '10px'}}>
                <Row id="present-navbar-datepicker-section" style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                  {user.settings.useActiveDates ? (
                    <div className="contentBoxTableDatePicker">
                      <ActiveDatesDatePicker
                        date={date}
                        showDatePicker={showDatePickerContentBoxStorage}
                        setShowDatePicker={setShowDatePickerContentBoxStorage}
                        handleDateSet={handleDateSetContentBoxStorage}
                        handleDateBackward={handleDateBackward}
                        handleDateForward={handleDateForward}
                        noActiveBoxesAlert={null}
                        noBoxesSetToShowAlert={null}
                      />
                    </div>
                  )
                  : null }
                  </Row>
                </ModalHeader>
                <ModalBody style={{padding: '10px 10px'}}>
                  <ContentBoxesTable props={{current: classId}} date={date}/>
                </ModalBody>
              </Modal>
          </DropdownMenu>
        </Dropdown>
        </Row>
      : null}

      {/**************************************************** TOGGLE BOXES TABS *****************************************************/}
      {loadComplete && navigationVisible &&
      <Row className={boxes && boxes.length < 6 ? "present-navbar-tabs-section" : "present-navbar-tabs-section present-navbar-tabs-section-with-scroll"} style={{width: user.settings?.useActiveDates === false && '78%' }}>
        <div style={{display: 'inline-flex'}}>
          {!location.pathname.includes('/share/') && boxes && boxes.map((box, index) => {
            return(
              <div key={`tab${box.contentBoxId}`} style={{marginLeft: '3px'}}>
                <ButtonDropdown 
                  isOpen={isOpenArray[index]} // Use the isOpen state from the array
                  toggle={() => handleButtonToggle(index)}
                  className="bottom-navbar"
                >
                  <DropdownToggle 
                    className="btn bottom-navbar toggle"
                    style={{borderTopLeftRadius: '15px', width: '30px', padding: '0px', backgroundColor: box.show ? 'rgb(11, 192, 223)' : 'white', borderLeft: '2px solid rgb(11, 192, 223)', borderTop: '2px solid rgb(11, 192, 223)', borderRight: 'none !important' }}
                  >
                    <Button
                      className="btn-icon btn-neutral"
                      color="secondary"
                      id="move-box-left"
                      size="md"
                      type="button"
                      disabled={index === 0}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.15)")}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                      onClick={() => {
                        handleMoveLeft(box.contentBoxId, boxes[index-1].contentBoxId)
                      }}
                    >
                    <i className="nc-icon nc-minimal-left" style={{color: box.show ? 'white' : 'rgb(11, 192, 223)'}}/>
                    </Button>
                  </DropdownToggle>
                  <Button
                    key={`asldf2390ba0d9f1-${box.contentBoxId}`}
                    id={`ContentBoxToggle-${box.contentBoxId}`}
                    onClick={() => handleToggle(box)}
                    className={noBoxesSetToShowAlert === true ? "no-boxes-set-to-show-alert" : box.show ? 'btn bottom-navbar box-show' : 'btn bottom-navbar box-hide'}
                  >
                    {box.heading?.length > 10 ? `${box.heading?.substring(0, 9)}...` : box.heading}
                  </Button>
                  <DropdownToggle 
                    // color="primary"
                    className="btn bottom-navbar toggle"
                    style={{borderTopRightRadius: '15px', width: '30px', padding: '0px', backgroundColor: box.show ? 'rgb(11, 192, 223)' : 'white', borderRight: '2px solid rgb(11, 192, 223)', borderTop: '2px solid rgb(11, 192, 223)', borderLeft: '0px !important' }}
                  >
                    <Button
                      className="btn-icon btn-neutral"
                      color="secondary"
                      id="move-box-right"
                      size="md"
                      type="button"
                      disabled={index === boxes.length-1}
                      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.15)")}
                      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                      onClick={() => { 
                        handleMoveRight(box.contentBoxId, boxes[index+1].contentBoxId)
                      }}
                    >
                    <i className="nc-icon nc-minimal-right" style={{color: box.show ? 'white' : 'rgb(11, 192, 223)'}}/>
                    </Button>
                  </DropdownToggle>
                </ButtonDropdown>
              </div>
            )
          })}
        </div>
      </Row>
      }
      {loadComplete && navigationVisible &&
      <Row className="present-navbar-datepicker-section" style={{width: user.settings?.useActiveDates === false && '5%' }} id="present-navbar-datepicker-section">
        {user.settings?.useActiveDates ? (
          <>
            <UncontrolledTooltip
              delay={0}
              target="present-navbar-datepicker-section"
              placement="top"
            >
              Set Active Date for Present Screen
            </UncontrolledTooltip>
            <ActiveDatesDatePicker
              date={date}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              handleDateSet={handleDateSet}
              handleDateBackward={handleDateBackward}
              handleDateForward={handleDateForward}
              noActiveBoxesAlert={noActiveBoxesAlert}
              noBoxesSetToShowAlert={noBoxesSetToShowAlert}
            />
          </>
        )
        : user.settings?.demo ? 
        (
          <div onClick={() => toggleDemoCountdownTimerModal()} style={{cursor: 'pointer', color: 'white'}}>
            click
          </div>
        ) : null }
      </Row>
      }
      <Modal isOpen={demoCountdownTimerModal} toggle={toggleDemoCountdownTimerModal} contentClassName="custom-modal-content" modalClassName="center-middle" backdrop={true}>
        <ModalBody style={{padding: '10px 25px'}}>
          <CountdownTimer props={demoCountdownTimerProps} />
          <Button color="primary" onClick={toggleDemoCountdownTimerModal}>Close</Button>
        </ModalBody>
      </Modal>
    </>
    : 
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center', /* Horizontally center */
          alignContent: 'center', /* Vertically center */
          minHeight: '100vh' /* Ensures it covers the entire viewport height */
        }}
      >
        {user && classes && classes.length === 0 ? (
          <Card body className="text-center" style={{ maxWidth: '500px', marginBottom: '0px', border: '1px solid red'}}>
            <CardHeader className="h2" style={{marginBottom: '0px'}}>NO CLASSES</CardHeader>
            <CardBody>
              {/* <div>
                If you haven't added content to this class previously, you can get started by clicking the "Launch" button below.
              </div>
              <br></br> */}
              <div className="h6">
                <NavLink to="/add-edit-classes">
                {/* To create content for this date <span onClick={toggleLaunchButtonModal} style={{textDecoration: 'underline', cursor: 'pointer'}}>CLICK HERE</span>. */}
                CLICK HERE TO ADD A CLASS
                </NavLink>
              </div>
            </CardBody>
          </Card>
        ) : (
          <Spinner color="primary" size='lg'>
            Loading...
          </Spinner>
        )}
      </Row>
    }
    </div>
  );
}

export default Present;
import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, login, selectUser, fetchUserData, fetchClassContentBoxes } from "features/userSlice";
import { doc, getDoc, onSnapshot, setDoc, getDocs, collection } from "firebase/firestore";
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import AdminLayout from "layouts/Admin.js";
import ShareLayout from "layouts/Share.js";
import LandingPage from "views/LandingPage copy";
import { Spinner, Row } from "reactstrap"
import LogInView from "views/LogInView";
import RegisterView from "views/RegisterView";
import Privacy from "components/Privacy";
import LandingPageTemplate from "views/LandingPageTemplate"
import PricingTable from "components/PricingTable";
import ShowRegisterPay from "views/ShowRegisterPay";
import Contact from "views/Contact";
import Blog from "views/Blog";
import Maintenance from "views/pages/Maintenance";


function App() {
    const location = useLocation();
    // const user = useSelector(selectUser);
    const user = useSelector((state) => state.user.user);
    const classes = useSelector((state) => state.user.classes);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true)
    const [firebaseInitialized, setFirebaseInitialized] = useState(false);
    const [sharedUser, setSharedUser] = useState(null);

    // useEffect(() => {
    // //I copied this code from somewhere so I don't know why this is called unsubscribe.
    // const unsubscribe = auth.onAuthStateChanged(async(userAuth) => {
    //     if (userAuth) {
    //         setFirebaseInitialized(true)
    //         const docRef = doc(db, "users", userAuth.uid);
    //         const docSnap = await getDoc(docRef);
    //         let userData = null;
            
    //         if (docSnap.exists()) {
    //             userData = docSnap.data();
    //         } else {
    //           // docSnap.data() will be undefined in this case
    //           console.log("No such document!");
    //         }
    //     dispatch(login({
    //         uid: userAuth.uid,
    //         email: userData.email,
    //         firstName: userData.firstName,
    //         lastName: userData.lastName,
    //         classes: userData.classes,
    //         contentBoxTemplates: userData.contentBoxTemplates,
    //         premium: userData.premium,
    //         settings: userData.settings,
    //         countdownTimers: userData.countdownTimers,
    //         autoMusic: userData.autoMusic,
    //         trial: userData.trial,
    //         reminders: userData.reminders,
    //         accountCreated: userData.accountCreated,
    //         announcements: userData.announcements,
    //         leadData: userData.leadData,
    //         shareCode: userData.shareCode,
    //         lastLogIn: userData.lastLogIn
    //     })); 
    //     setIsLoading(false)
    //     } else {
    //     //logged out...
    //     dispatch(logout);
    //     }
    //     setIsLoading(false)
    // });
    // return unsubscribe;
    // },[]);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async (userAuth) => {
        if (userAuth) {
          try {
            await dispatch(fetchUserData(userAuth.uid)); // Ensure this completes
          } catch (error) {
            console.error("Error loading user data:", error);
          }
        } else {
          dispatch(logout());
        }
        setIsLoading(false); // Always stop loading, even on error
      });
    
      return () => unsubscribe();
    }, [dispatch]);
    

    // No, you do not need that useEffect in App.js anymore because:
    // You’re now fetching user data with fetchUserData(uid) in Redux, which already gets the user document and subcollections.
    // You're using onAuthStateChanged to trigger data fetching, so you don’t need a separate onSnapshot listener inside App.js.
    // useEffect(() => {
    //     if (firebaseInitialized) {
    //         const currentUser = auth.currentUser;

    //         if (currentUser) {
    //         const docRef = doc(db, "users", currentUser.uid);
        
    //         const unsubscribe = onSnapshot(docRef, (doc) => {
    //             if (doc.exists()) {
    //             const userData = doc.data();
        
    //             dispatch(updateUser({
    //                 uid: currentUser.uid,
    //                 email: userData.email,
    //                 firstName: userData.firstName,
    //                 lastName: userData.lastName,
    //                 classes: userData.classes,
    //                 contentBoxTemplates: userData.contentBoxTemplates,
    //                 premium: userData.premium,
    //                 settings: userData.settings,
    //                 trial: userData.trial,
    //                 countdownTimers: userData.countdownTimers,
    //                 autoMusic: userData.autoMusic,
    //                 reminders: userData.reminders,
    //                 accountCreated: userData.accountCreated,
    //                 announcements: userData.announcements,
    //                 leadData: userData.leadData,
    //                 shareCode: userData.shareCode,
    //                 lastLogIn: userData.lastLogIn
    //             }));
    //             } else {
    //             // Document doesn't exist
    //             console.log("No such document!");
    //             }
    //         });
    //         return () => {
    //             // Unsubscribe from the Firestore listener when the component unmounts
    //             unsubscribe();
    //         };
    //         }
    //     }
    //   }, [firebaseInitialized, auth, dispatch]);

      // useEffect(() => {
      //   if (user) {
      //     if (!user.hasOwnProperty('settings')) {
      //       addSettingsObjectToUser();
      //     }
      //   }
      // }, [user]);

      // const addSettingsObjectToUser = async () => {
      //   try {
      //     const docRef = doc(db, "users", user.uid);
      
      //     // Define the default settings object
      //     const defaultSettings = {
      //       showSeconds: true,
      //       logo: true,
      //       marqueeSpeed: '150',
      //       alteredSchedule: false,
      //       multiWeek: false,
      //       numberOfWeeks: 1,
      //       currentWeek: "A",
      //       differentTimes: false,
      //       useActiveDates: false,
      //       useShareCode: false,
      //       screenTimeout: '60'
      //     };
      
      //     // Update only the settings field in Firestore
      //     await setDoc(docRef, { settings: defaultSettings }, { merge: true });
      
      //     console.log("User settings updated successfully!");
      //   } catch (error) {
      //     console.error("Error updating user settings:", error);
      //   }
      // };

      // //Added for share code functionality
      // useEffect(() => {
      //   const fetchUserData = async () => {
      //     if (location.pathname.includes("share")) {
      //       // Parse share code from URL
      //       const shareCode = location.pathname.match(/\/share\/([a-zA-Z0-9]{6})/)?.[1];
    
      //       if (shareCode) {
      //         try {
      //           // Get userId associated with share code
      //           const userIDforShareCode = await getUserIDforShareCode(shareCode);
    
      //           // Get docRef for share code user
      //           const docRef = doc(db, "users", userIDforShareCode);
    
      //           const unsubscribe = onSnapshot(docRef, (doc) => {
      //             if (doc.exists()) {
      //               const userData = doc.data();

      //               if (userData.settings?.useShareCode) {
      //                 // Dispatch the updateUser action
      //                 dispatch(updateUser({
      //                   uid: userIDforShareCode,
      //                   email: userData.email,
      //                   firstName: userData.firstName,
      //                   lastName: userData.lastName,
      //                   classes: userData.classes,
      //                   contentBoxTemplates: userData.contentBoxTemplates,
      //                   autoMusic: userData.autoMusic,
      //                   premium: userData.premium,
      //                   settings: userData.settings,
      //                   trial: userData.trial,
      //                   countdownTimers: userData.countdownTimers,
      //                   reminders: userData.reminders,
      //                   accountCreated: userData.accountCreated,
      //                   announcements: userData.announcements,
      //                   leadData: userData.leadData,
      //                   shareCode: userData.shareCode,
      //                   lastLogIn: userData.lastLogIn
      //                 }));
      //                 setIsLoading(false)
      //               } else {
      //                 window.alert("This user is not sharing their content.")
      //               }
  
      //             } else {
      //               // Document doesn't exist
      //               console.log("No such document!");
      //             }
      //           });
    
      //           return () => {
      //             // Unsubscribe from the Firestore listener when the component unmounts
      //             unsubscribe();
      //           };
      //         } catch (error) {
      //           console.error('Error fetching user ID for share code:', error);
      //         }
      //       }
      //     }
      //   };
    
      //   fetchUserData();
      // }, [firebaseInitialized, location.pathname, dispatch]);

      // useEffect(() => {
      //   console.log("#1")
      //   console.log("!location.pathname.includes(share)", (!location.pathname.includes("share")) )
      //   console.log("(!firebaseInitialized", (!firebaseInitialized || !location.pathname.includes("share")) )
      //   console.log("(!firebaseInitialized || !location.pathname.includes(share)", (!firebaseInitialized || !location.pathname.includes("share")) )
      //   if (!location.pathname.includes("share")) return;
      //   console.log("#1.1")

      //   const fetchUserData = async () => {
      //     const shareCode = location.pathname.match(/\/share\/([a-zA-Z0-9]{6})/)?.[1];
      //     if (!shareCode) return;
      
      //     try {
      //       const userIDforShareCode = await getUserIDforShareCode(shareCode);
      //       if (!userIDforShareCode) return;
      
      //       const docRef = doc(db, "usersNew", userIDforShareCode);
      //       const unsubscribe = onSnapshot(docRef, (doc) => {
      //         if (!doc.exists()) {
      //           setSharedUser(null);
      //           return;
      //         }
      
      //         const userData = doc.data();
      //         if (!userData.settings?.useShareCode) {
      //           window.alert("This user is not sharing their content.");
      //           setSharedUser(null);
      //           return;
      //         }
      
      //         // Prevent unnecessary updates
      //         setSharedUser((prev) => 
      //           JSON.stringify(prev) !== JSON.stringify(userData)
      //             ? { ...userData, uid: userIDforShareCode }
      //             : prev
      //         );
      //         setIsLoading(false);
      //       });
      
      //       return () => unsubscribe();
      //     } catch (error) {
      //       console.error("Error fetching user ID for share code:", error);
      //     }
      //   };      
      //   fetchUserData();
      // }, [firebaseInitialized, location.pathname]);
      
      

      // useEffect(() => {
      //   if (user && user.uid && classes.length > 0) {
      //     // Fetch contentBoxes for each class and store in Redux
      //     classes.forEach((cls) => {
      //       if (cls.classId) {
      //         dispatch(fetchClassContentBoxes(user.uid, cls.classId));
      //       }
      //     });
      //   }
      // }, [user, classes, dispatch]);

      useEffect(() => {
        console.log("#2")
        if (!user?.uid || classes.length === 0 || location.pathname.includes("share")) return; // ✅ Ensure `user` and `classes` are fully loaded
      
        classes.forEach((cls) => {
          if (cls.classId) {
            dispatch(fetchClassContentBoxes(user.uid, cls.classId));
          }
        });
      }, [user?.uid, classes]); // ✅ No need for `dispatch` in dependencies
      
      
      // const getUserIDforShareCode = async (shareCode) => {
      //   try {
      //     const docRef = doc(db, "shareCodes", shareCode);
      //     const docSnap = await getDoc(docRef);
    
      //     if (docSnap.exists()) {
      //       const shareCodeDataFetch = docSnap.data();
      //       // console.log("shareCodeDataFetch => ", shareCodeDataFetch);
      //       return shareCodeDataFetch.uid; // Assuming userID is part of the document data
      //     } else {
      //       console.log("No such document!");
      //     }
      //   } catch (error) {
      //     console.error('Error fetching document:', error);
      //   }
      // };

      // useEffect(() => {
      //   const fetchSharedClasses = async () => {
      //     if (!sharedUser?.uid) return;
      //     const classesRef = collection(db, `usersNew/${sharedUser.uid}/classes`);
      //     const snapshot = await getDocs(classesRef);
      //     const classes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      //     setSharedUser(prev => ({ ...prev, classes }));
      //   };
      
      //   if (sharedUser) {
      //     fetchSharedClasses();
      //   }
      // }, [sharedUser]);      

      //must also uncomment script in index.html to start using this again.
      //only runs mailchimp script on landingpage.
    //   useEffect(() => {
    //     // Dynamically load Mailchimp script if on the LandingPage component
    //     if (location.pathname === "/" && !user) {
    //         const script = document.createElement("script"); 
    //         script.id = "mcjs";
    //         script.async = true;
    //         script.src = "https://chimpstatic.com/mcjs-connected/js/users/d6aaa500bb03c2821bd199093/1005a6f4accfc551bf159b59e.js";
    //         document.head.appendChild(script);
    //     }
    // }, [location.pathname]);

      return (
        <Switch>
  {/* Show loading indicator while user data is loading */}
  {isLoading && 
    <Route render={() => 
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}
      >
        <Spinner style={{ height: '3rem', width: '3rem' }} color="info">Loading...</Spinner>
      </Row>
    } 
  />}
  
  {/* Auth and public routes */}
  <Route exact path="/auth/register" component={RegisterView} />
  <Route exact path="/auth/login" component={LogInView} />
  <Route exact path="/privacy" component={Privacy} />
  <Route exact path="/contact" component={Contact} />
  <Route exact path="/why-lesson-launchpad" component={LandingPageTemplate} />
  <Route exact path="/show" component={ShowRegisterPay} />
  <Route exact path="/show-pricing" component={PricingTable} />
  <Route path="/pricing" component={PricingTable} />
  <Route exact path="/blog" component={Blog} />
  <Route path="/blog/:blogId" component={Blog} />
  <Route path="/blog/:blogId" component={Blog} />

  {/* Share Layout should only apply to /share routes */}
  <Route path="/share" component={ShareLayout} />

  {/* Ensure user routes go through AdminLayout */}
  {!isLoading && (
    user ? (
      <Route path="/" component={AdminLayout} />
    ) : (
      <Route exact path="/" component={LandingPage} />
    )
  )}
</Switch>

      );
}

export default App;
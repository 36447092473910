import React, {useState} from 'react'
import { selectUser } from "features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { Table, Input, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Row, Col } from "reactstrap";
import { createRandomId } from 'RandomId';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";
import { fetchUserData } from "/Users/michaeltucker/Documents/coding-projects/client/src/features/userSlice.js"; // Import Redux action

function RandomizerCustomListTable({classId, listToEditId, toggleCustomListModal, setCustomLists}) {

    const initialListData = {
        listId: "",
        listName: "",
        list:  [
            {listItem: "", fileName: "", filePath: ""}
        ]
    }

    const dispatch = useDispatch()
    const user = useSelector(selectUser);
    const classes = useSelector((state) => state.user.classes);
    const classIndex = classes?.findIndex((c) => c.classId === classId);
    const [alert, setAlert] = useState(false);
    const [editedCustomListData, setEditedCustomListData] = useState(() => {
        // Get the user's class by classIndex
        const customLists = classes?.[classIndex]?.customLists;
    
        // Find the list with the matching listId, if it exists
        const existingList = customLists?.find((list) => list.listId === listToEditId);
    
        // If the listToEditId exists, use the matching list; otherwise, use initialListData
        return existingList || initialListData;
    });
    const [copyRosterToClassModal, setCopyRosterToClassModal] = useState(false);
    const [classesSelectedForRosterCopy, setClassesSelectedForRosterCopy] = useState([])
    const toggleCopyRosterToClassModal = () => {
        setCopyRosterToClassModal(!copyRosterToClassModal);
        setClassesSelectedForRosterCopy([])
    }

    const handleCheckboxChangeForRosterCopy = (classId) => {
        setClassesSelectedForRosterCopy(prev => {
          if (prev.includes(classId)) {
            // If classId is already selected, remove it
            return prev.filter(id => id !== classId);
          } else {
            // If classId is not selected, add it
            return [...prev, classId];
          }
        });
    };

    // const saveCustomList = async () => {
    //     try {
    //         const docRef = doc(db, "users", user.uid);
    
    //         // Create a deep copy of the user object
    //         const userCopy = JSON.parse(JSON.stringify(user));
    
    //         // Ensure classes exist
    //         if (!userCopy.classes) {
    //             throw new Error("User data is missing 'classes'.");
    //         }
    
    //         // Find the index of the class by classId
    //         const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
    
    //         if (classIndex === -1) {
    //             throw new Error(`Class with ID '${classId}' not found.`);
    //         }
    
    //         // Ensure customLists exists
    //         if (!userCopy.classes[classIndex].customLists) {
    //             userCopy.classes[classIndex].customLists = [];
    //         }
    
    //         // Check if the list exists by listId
    //         const listIndex = userCopy.classes[classIndex].customLists.findIndex(
    //             (list) => list.listId === listToEditId
    //         );
    
    //         if (listIndex !== -1) {
    //             // Update the existing list
    //             userCopy.classes[classIndex].customLists[listIndex] = {
    //                 ...editedCustomListData, // Ensure it's not nested
    //                 listId: listToEditId,   // Maintain the existing listId
    //             };
    //         } else {
    //             // Add a new list
    //             const newList = { 
    //                 ...editedCustomListData, 
    //                 listId: createRandomId() // Add a new unique listId
    //             };
    //             console.log("newList => ", newList)
    //             userCopy.classes[classIndex].customLists.push(newList); // Add directly
    //         }
    //         console.log("userCopy.classes[classIndex].customLists => ", userCopy.classes[classIndex].customLists)

    //         // Save the updated user data
    //         await setDoc(docRef, userCopy);

    //         // ✅ Update parent component immediately
    //         setCustomLists(userCopy.classes[classIndex].customLists);

    //         toggleCustomListModal()
        
    //     } catch (error) {
    //         alert("Error updating document: " + error.message);
    //         console.log("Error updating document:", error);
    //     }
    // };

    const saveCustomList = async () => {
        try {
            if (!user?.uid || !classId) {
                console.error("❌ User ID or Class ID is missing.");
                return;
            }

            // Reference the class document in Firestore
            const classRef = doc(
                db,
                FIREBASE_COLLECTIONS.USERS,
                user.uid,
                FIREBASE_COLLECTIONS.CLASSES,
                classId
            );

            // Fetch the current class data
            const classSnap = await getDoc(classRef);
            if (!classSnap.exists()) {
                console.error(`❌ Class with ID '${classId}' not found.`);
                return;
            }

            const classData = classSnap.data();
            const existingLists = classData.customLists || [];

            // Check if the list already exists
            const existingListIndex = existingLists.findIndex((list) => list.listId === listToEditId);
            let updatedLists;

            if (existingListIndex !== -1) {
                // Update the existing list
                updatedLists = [...existingLists];
                updatedLists[existingListIndex] = {
                    ...editedCustomListData,
                    listId: listToEditId, // Preserve existing listId
                };
            } else {
                // Add a new list
                const newList = {
                    ...editedCustomListData,
                    listId: createRandomId(), // Generate new listId
                };
                updatedLists = [...existingLists, newList];
            }

            // Update Firestore document
            await updateDoc(classRef, { customLists: updatedLists });

            // ✅ Update local state immediately
            setCustomLists(updatedLists);

            // Close modal after save
            toggleCustomListModal();

            dispatch(fetchUserData(user.uid))
            console.log("✅ Custom list saved successfully!");
        } catch (error) {
            alert("❌ Error updating custom list: " + error.message);
            console.error("❌ Error updating document:", error);
        }
    };

    function handleCustomListDataEdit(event, index, key) {
        const { value } = event.target;
    
        setEditedCustomListData((prevData) => {
            if (key === "listName") {
                // Update the listName
                return {
                    ...prevData,
                    listName: value, // Update listName directly
                };
            }
    
            if (key === "listItem" && index < prevData.list.length) {
                // Update a specific listItem in the list
                return {
                    ...prevData,
                    list: prevData.list.map((item, itemIndex) => {
                        if (itemIndex === index) {
                            return { ...item, listItem: value };
                        }
                        return item;
                    }),
                };
            }
    
            return prevData; // Return the unchanged state
        });
    }
    

    const handleRosterRowDelete = (index) => {
        const updatedData = { ...editedCustomListData };
    
        // Create a new array by copying the existing roster array
        updatedData.list = [...updatedData.list];
    
        // Remove the item at the specified index
        updatedData.list.splice(index, 1);
    
        // Update the state with the new roster array
        setEditedCustomListData(updatedData);
    };
    
    const handleAddRow = () => {
    const blankRow = {listItem: "", fileName: "", filePath: ""};
    setEditedCustomListData((prev) => ({ ...prev, list: [...prev.list, blankRow] }));
    };

    const [editMode, setEditMode] = useState(true)

    // const handleListDelete = async () => {
    //     try {
    //         const docRef = doc(db, "users", user.uid);
    //         const userCopy = JSON.parse(JSON.stringify(user));
    
    //         const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
    //         if (classIndex === -1) return;
    
    //         userCopy.classes[classIndex].customLists = userCopy.classes[classIndex].customLists.filter(
    //             (list) => list.listId !== listToEditId
    //         );
    
    //         await setDoc(docRef, userCopy);
    
    //         // ✅ Update parent component immediately
    //         setCustomLists(userCopy.classes[classIndex].customLists);

    //         successDelete()
    
    //         toggleCustomListModal();
        
    //     } catch (error) {
    //         console.error("❌ Error deleting list:", error);
    //         window.alert("❌ Error deleting list: " + error.message);
    //     }
    // };    

    const handleListDelete = async () => {
        try {
            if (!user?.uid || !classId || !listToEditId) {
                console.error("❌ Missing required parameters: User ID, Class ID, or List ID.");
                return;
            }

            // Reference the class document in Firestore
            const classRef = doc(
                db,
                FIREBASE_COLLECTIONS.USERS,
                user.uid,
                FIREBASE_COLLECTIONS.CLASSES,
                classId
            );

            // Fetch the current class data
            const classSnap = await getDoc(classRef);
            if (!classSnap.exists()) {
                console.error(`❌ Class with ID '${classId}' not found.`);
                return;
            }

            const classData = classSnap.data();
            if (!classData.customLists) {
                console.error(`❌ No custom lists found for class ID '${classId}'.`);
                return;
            }

            // Remove the list with the specified listId
            const updatedLists = classData.customLists.filter((list) => list.listId !== listToEditId);

            // Update Firestore document
            await updateDoc(classRef, { customLists: updatedLists });

            // ✅ Update local state immediately
            setCustomLists(updatedLists);

            dispatch(fetchUserData(user.uid))

            successDelete(); // Show success alert
            toggleCustomListModal(); // Close modal
            console.log("✅ Custom list deleted successfully!");
        } catch (error) {
            console.error("❌ Error deleting custom list:", error);
            window.alert("❌ Error deleting list: " + error.message);
        }
    };

    const deleteListAlert = () => {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => handleListDelete()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
            cancelBtnBsStyle="danger"
            cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You will not be able to recover this list.
          </ReactBSAlert>
        );
      };

      const successDelete = () => {
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Your list has been deleted.
          </ReactBSAlert>
        );
      };

      const hideAlert = () => {
        setAlert(null);
      };

    return (
        <div>
            {alert}
            <Row className="mb-3">
                <Col>
                    {editMode ? (
                        <Input
                            type="text"
                            value={editedCustomListData.listName}
                            onChange={(e) => handleCustomListDataEdit(e, 0, 'listName')} // Pass the index of the list
                            invalid={editedCustomListData.listName.length < 1}
                            placeholder="Enter List Name"
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "center",
                                borderRadius: '8px'
                            }}
                        />
                    ) : (
                        <h4 style={{ textAlign: "center", fontWeight: "bold", marginBottom: "10px" }}>
                            {editedCustomListData.listName || "Untitled List"}
                        </h4>
                    )}
                </Col>
            </Row>

        <div className='playlist-table' style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd", borderRadius: "8px" }}>
            <Table>
                <thead style={{color: 'rgb(11, 192, 223)'}}>
                    <tr>
                        <th style={{width: '10%'}}>#</th>
                        <th style={{width: '90%'}}>List Item</th>
                    </tr>
                </thead>
                <tbody>
                    {editedCustomListData?.list?.map((data, index) => (
                        <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                                {editMode ? (
                                    <Input
                                        key={index}
                                        type="text"
                                        name="listItem"
                                        value={data.listItem}
                                        onChange={(e) => handleCustomListDataEdit(e, index, 'listItem')} // Pass the index of the item
                                    />
                                ) : (
                                    <>
                                        {data.listItem}
                                    </>
                                )}
                            </td>
                            <td>
                                {editMode ? (
                                    <Button
                                        className="btn-icon btn-neutral"
                                        color="danger"
                                        id="tooltip808966390"
                                        size="md"
                                        type="button"
                                        onClick={() => handleRosterRowDelete(index)}
                                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.1)")}
                                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                    >
                                        <i className="fa fa-trash icon-delete" />
                                    </Button>
                                ) : (
                                    <>
                                        
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>

            <Row className="text-center" style={{ marginTop: '0px' }}>
                <Col className={editedCustomListData.list.length > 24 ? "add-row-to-playlist-col-disabled" : "add-row-to-playlist-col"} onClick={handleAddRow}>
                    <h6>{editedCustomListData.list.length > 24 ? "No More Rows Allowed" : "Add Row"}</h6>
                </Col>
            </Row>

            <Row className="m-2" style={{ textAlign: 'center' }}>
                <Col md="4"></Col>
                <Col md="4">
                    <Button
                        className='button-hover-fix'
                        disabled={editedCustomListData.listName === ""}
                        onClick={() => saveCustomList()}
                        style={{
                            borderRadius: '30px',
                            width: '100px'
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <i className='fa fa-save'/>{' '}Save
                    </Button>
                </Col>
                <Col md="4" style={{ textAlign: 'right', marginRight: '0px' }}>
                    <Button
                        className='button-delete'
                        onClick={() => deleteListAlert()}
                        style={{
                            borderRadius: '30px',
                            width: '100px',
                            marginRight: '0px'
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <i className='fa fa-trash' />
                        {' '}Delete
                    </Button>
                </Col>
            </Row>

            {/* <Modal scrollable isOpen={copyRosterToClassModal} toggle={toggleCopyRosterToClassModal} style={{width: '350px'}}>
                <ModalBody>
                <h5>Copy roster to:</h5>
                {user && classes && classes.filter(x => x.classId != classId).map((x) => 
                    <FormGroup check key={`classCopy-${classes.classId}`}>
                        <Label check>
                        <Input
                        type="checkbox"
                        defaultChecked={classesSelectedForRosterCopy.length > 0 && classesSelectedForRosterCopy.includes(x.classId)}
                        onChange={() => handleCheckboxChangeForRosterCopy(x.classId)}
                        />                
                        <span className="form-check-sign" />
                        {x.className}
                        </Label>
                    </FormGroup>
                )}
                </ModalBody>
                <ModalFooter>
                    <Button disabled={classesSelectedForRosterCopy.length < 1} onClick={() => copyRosterToClasses()}>Copy</Button>
                    <Button onClick={toggleCopyRosterToClassModal}>Cancel</Button>
                </ModalFooter>
            </Modal> */}
        </div>
    );
}
export default RandomizerCustomListTable;

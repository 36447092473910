import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

function TableOptionsPicker({ editor }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const commands = {
    insertTable: () => { editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run() },
    addColumnBefore: () => { editor.chain().focus().addColumnBefore().run(); },
    addColumnAfter: () => editor.chain().focus().addColumnAfter().run(),
    deleteColumn: () => editor.chain().focus().deleteColumn().run(),
    addRowBefore: () => editor.chain().focus().addRowBefore().run(),
    addRowAfter: () => editor.chain().focus().addRowAfter().run(),
    deleteRow: () => editor.chain().focus().deleteRow().run(),
    deleteTable: () => editor.chain().focus().deleteTable().run(),
    mergeCells: () => editor.chain().focus().mergeCells().run(),
    splitCell: () => editor.chain().focus().splitCell().run(),
    toggleHeaderColumn: () => editor.chain().focus().toggleHeaderColumn().run(),
    toggleHeaderRow: () => editor.chain().focus().toggleHeaderRow().run(),
    toggleHeaderCell: () => editor.chain().focus().toggleHeaderCell().run(),
    mergeOrSplit: () => editor.chain().focus().mergeOrSplit().run(),
    setCellAttribute: (attr, value) => editor.chain().focus().setCellAttribute(attr, value).run(),
  };

  const options = [
    { label: 'Insert Table', command: commands.insertTable },
    { label: 'Add Column Before', command: commands.addColumnBefore },
    { label: 'Add Column After', command: commands.addColumnAfter },
    { label: 'Delete Column', command: commands.deleteColumn },
    { label: 'Add Row Before', command: commands.addRowBefore },
    { label: 'Add Row After', command: commands.addRowAfter },
    { label: 'Delete Row', command: commands.deleteRow },
    { label: 'Delete Table', command: commands.deleteTable },
    { label: 'Merge Cells', command: commands.mergeCells },
    { label: 'Split Cell', command: commands.splitCell },
    { label: 'Toggle Header Column', command: commands.toggleHeaderColumn },
    { label: 'Toggle Header Row', command: commands.toggleHeaderRow },
    { label: 'Toggle Header Cell', command: commands.toggleHeaderCell },
    { label: 'Merge or Split', command: commands.mergeOrSplit },
    { label: 'Set Cell Attribute', command: () => commands.setCellAttribute('backgroundColor', '#000') },
  ];

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown className="table-options-picker" isOpen={dropdownOpen} toggle={toggle} direction='down'>
        <DropdownToggle caret ><i className="fa fa-table"></i></DropdownToggle>
        <DropdownMenu className="table-options-picker-menu">
        {options.map((option, index) => (
          <DropdownItem className="table-options-menu-option" key={index} onClick={() => option.command()}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export default TableOptionsPicker;

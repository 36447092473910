import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { createRandomId } from "/Users/michaeltucker/Documents/coding-projects/client/src/RandomId.js"; // Adjust path if needed
import { FIREBASE_COLLECTIONS } from "../src/config/firebaseCollections";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fetchUserData } from "../src/features/userSlice"; // Import Redux action

/**
 * Saves a content box template to Firestore.
 * @param {Object} user - The user object.
 * @param {string} templateName - Name of the template.
 * @param {Object} contentBox - The content box data.
 * @param {Function} dispatch - Redux dispatch function.
 */
export async function saveContentBoxTemplate(user, templateName, contentBox, dispatch) {
    try {
        if (!user?.uid || !templateName || !contentBox) {
            console.error("🚨 Missing required parameters: User ID, Template Name, or Content Box.");
            return;
        }

        // ✅ Generate a new contentBoxId for the template
        const newContentBoxId = createRandomId();
        const newContentBoxWithNewId = { ...contentBox, contentBoxId: newContentBoxId };

        // ✅ Reference the user document
        const userDocRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
        const userSnap = await getDoc(userDocRef);

        if (!userSnap.exists()) {
            console.error("🚨 User document not found.");
            return;
        }

        // ✅ Get existing templates or initialize an empty array
        const userData = userSnap.data();
        const existingTemplates = userData.contentBoxTemplates || [];

        // ✅ Add new template to the array
        const updatedTemplates = [
            ...existingTemplates,
            {
                name: templateName,
                template: newContentBoxWithNewId,
                createdAt: new Date(), // ✅ Adds timestamp for tracking
            }
        ];

        // ✅ Save updated template list to Firestore
        await setDoc(userDocRef, { contentBoxTemplates: updatedTemplates }, { merge: true });

        console.log(`✅ Template "${templateName}" saved successfully with ID ${newContentBoxId}!`);

        // ✅ Dispatch fetchUserData to refresh state
        if (dispatch) {
            await dispatch(fetchUserData(user.uid));
            console.log("🔄 User data refreshed after template save.");
        }

    } catch (error) {
        console.error("❌ Error saving template:", error);
    }
}

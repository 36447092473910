import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Card, Col, Row, Button, Dropdown, Input, Label, FormGroup, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody} from "reactstrap";
import { selectUser, fetch } from "features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import SpinningWheel from './SpinningWheel';
import RandomizerCustomListTable from './RandomizerCustomListTable';

export default function Randomizer({props}) { 
  const user = useSelector(selectUser);
  const classes = useSelector((state) => state.user.classes);
  const useCustomList = useRef(false);
  const classIndex = classes?.findIndex((c) => c.classId === props.current);
  const [showSingleName, setShowSingleName] = useState(true);
  const [rosterData, setRosterData] = useState(
    {
      roster:  [
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
          {name: "No Roster Data", descriptor1: "", descriptor2: "", birthday: ""},
      ],
      descriptors: {
          descriptor1: "Descriptor 1",
          descriptor2: "Descriptor 2",
      }
    }
  )
  const [customLists, setCustomLists] = useState(classes[classIndex]?.customLists);
  const [selectedCustomList, setSelectedCustomList] = useState();
  const [customListModal, setCustomListModal] = useState(false)
  const [customListToEditId, setCustomListToEditId] = useState()
  const [groupDropdownOpen, setGroupDropdownOpen] = useState(false);
  const groupOptions = [2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]
  const [customListDropdown, setCustomListDropdown] = useState(false);

  const handleCustomListSelect = (list) => {
    const filteredListItems = list.reduce((result, item) => {
      if (item.listItem !== "") {
        result.push(item.listItem);
      }
      return result;
    }, []);
    setSelectedCustomList(filteredListItems);
    useCustomList.current = true;
    setShowSingleName(true);
  }
  
  const descriptor1Name = user && classes[classIndex].rosterData?.descriptors.descriptor1 || 'Descriptor 1';
  const descriptor2Name = user && classes[classIndex].rosterData?.descriptors.descriptor2 || 'Descriptor 2';

  const descriptor1Options = useMemo(() => {
    if (user && classIndex >= 0 && classes[classIndex]?.rosterData?.roster) {
      return [...new Set(classes[classIndex].rosterData.roster.map(item => item.descriptor1).filter(Boolean))];
    }
    return [];
  }, [user, classIndex]);
  
  const descriptor2Options = useMemo(() => {
    if (user && classIndex >= 0 && classes[classIndex]?.rosterData?.roster) {
      return [...new Set(classes[classIndex].rosterData.roster.map(item => item.descriptor2).filter(Boolean))];
    }
    return [];
  }, [user, classIndex]);
  

  const [descriptor1Checked, setDescriptor1Checked] = useState([]);
  const [descriptor2Checked, setDescriptor2Checked] = useState([]);

  useEffect(() => {
    if (descriptor1Options.length || descriptor2Options.length) {
      setDescriptor1Checked(descriptor1Options.map(() => true));
      setDescriptor2Checked(descriptor2Options.map(() => true));
    }
  }, [descriptor1Options, descriptor2Options]);
  
  useEffect(() => {
    if (user && classes[classIndex].rosterData) {
      const roster = classes[classIndex].rosterData.roster;
  
      // console.log("roster inside useEffect => ", roster);
  
      if (descriptor1Options.length > 0 || descriptor2Options.length > 0) {
        const selectedDescriptor1 = descriptor1Options.filter((_, index) => descriptor1Checked[index]);
        const selectedDescriptor2 = descriptor2Options.filter((_, index) => descriptor2Checked[index]);

        if (selectedDescriptor1.length === 0 || selectedDescriptor2.length === 0 && (descriptor1Options.length > 0 && descriptor2Options.length > 0)) {
          setRosterData({ ...rosterData, roster: [] });
        } else {
          // console.log("selectedDescriptor1 inside useEffect => ", selectedDescriptor1);
          // console.log("selectedDescriptor2 inside useEffect => ", selectedDescriptor2);
    
          const filteredRoster = roster.filter(person => {
            const matchesDescriptor1 = selectedDescriptor1.length > 0 && selectedDescriptor1.includes(person.descriptor1);
            const matchesDescriptor2 = selectedDescriptor2.length > 0 && selectedDescriptor2.includes(person.descriptor2);
            // console.log("matchesDescriptor1 inside useEffect => ", matchesDescriptor1);
            // console.log("matchesDescriptor2 inside useEffect => ", matchesDescriptor2);
            if (selectedDescriptor1.length > 0 && selectedDescriptor2.length > 0) {
              // console.log("Should both match true")
              return matchesDescriptor1 && matchesDescriptor2
            } else {
              // console.log("Should just one match true")
              return matchesDescriptor1 || matchesDescriptor2;
            }
          });        
  
        // console.log("filteredRoster inside useEffect => ", filteredRoster);

        setRosterData({ ...rosterData, roster: filteredRoster });

        }
      } else {

        const filteredRoster = roster.filter(person => person.name.trim() !== "");

        setRosterData({ ...rosterData, roster: filteredRoster });

      }
    }
  }, [user, classIndex, descriptor1Checked, descriptor2Checked, descriptor1Options, descriptor2Options, customListModal]);
  

  // console.log("descriptor1Options => ", descriptor1Options)
  // console.log("descriptor2Options => ", descriptor2Options)
  // console.log("descriptor1Checked => ", descriptor1Checked)
  // console.log("descriptor2Checked => ", descriptor2Checked)
  // console.log("rosterData => ", rosterData)

  const handleCheckboxChange = (index, descriptor, setCheckedState) => {
    const updatedChecked = [...descriptor];
    updatedChecked[index] = !updatedChecked[index]; // Toggle checkbox state
    setCheckedState(updatedChecked);
  };
  const [groups, setGroups] = useState([])

  function splitRosterIntoGroups(numberOfGroups) {
    setShowSingleName(false)

    // Shuffle the roster array to randomize the order
    const shuffledRoster = [...rosterData.roster]
        .filter((x) => x.name.length > 0)
        .sort(() => Math.random() - 0.5);

    const totalMembers = shuffledRoster.length;
    const baseGroupSize = Math.floor(totalMembers / numberOfGroups);
    const remainder = totalMembers % numberOfGroups;

    const groups = [];
    let currentIndex = 0;

    for (let i = 0; i < numberOfGroups; i++) {
        // If remainder > 0, add 1 to the current group size to distribute extra members evenly
        const currentGroupSize = baseGroupSize + (i < remainder ? 1 : 0);
        const currentGroup = shuffledRoster.slice(currentIndex, currentIndex + currentGroupSize);
        groups.push(currentGroup);
        currentIndex += currentGroupSize;
    }

    setGroups(groups);
}

  const toggleGroupsDropdown = () => setGroupDropdownOpen((prevState) => !prevState);

  const toggleCustomListDropdown = () => setCustomListDropdown((prevState) => !prevState);

  const toggleCustomListModal = () => {
    if (customListModal) { //If customListModal === true, that means we're closing the modal and need to reset listToEditId
      setCustomListToEditId(undefined)
    }
    setCustomListModal(prev => !prev)
  }

  return (
    <div>
      <Modal contentClassName="add-edit-randomizer-list-modal" toggle={toggleCustomListModal} isOpen={customListModal}>
        <ModalHeader toggle={toggleCustomListModal}>
          <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Add/Edit List</h4>
        </ModalHeader>
            <ModalBody >
              <RandomizerCustomListTable 
                classId={props.current} 
                listToEditId={customListToEditId} 
                toggleCustomListModal={toggleCustomListModal}
                setCustomLists={setCustomLists}  // ✅ Pass the setter function
              />
            </ModalBody>
      </Modal>
      <Row>
        <Col xs="2" style={{ borderRight: 'solid 1px lightgrey' }}>
          <div style={{ paddingLeft: '25px', marginTop: '20px' }}>
            <div>
              <h5 style={{marginBottom: '0px', fontWeight: "bold", color: "#004aad" }}>{descriptor1Name}</h5>
                <div 
                  style={{fontSize: '10px', textDecoration: 'underline', display: 'inline-block', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}}
                  onClick={() => setDescriptor1Checked(descriptor1Options.map(() => true))} // Select All
                  >
                  Select All
                </div>
                <div 
                  style={{fontSize: '10px', textDecoration: 'underline', display: 'inline-block', cursor: 'pointer'}}
                  onClick={() => setDescriptor1Checked(descriptor1Options.map(() => false))} // Clear All
                  >
                  Clear All
                </div>
              <div style={{maxHeight: '200px', overflowY: 'scroll', marginTop: '20px'}}>
              {descriptor1Options.length > 0 ? descriptor1Options.map((item, index) => (
                  <FormGroup check key={`descriptor1Options-${index}`}>
                    <Label check>
                      <Input
                        type="checkbox"
                        id={item}
                        name={item}
                        checked={descriptor1Checked[index]}
                        onChange={() => handleCheckboxChange(index, descriptor1Checked, setDescriptor1Checked)}
                        style={{ marginRight: '5px' }}
                      />
                      <span className="form-check-sign" />
                      {item}
                    </Label>
                  </FormGroup>
              ))
              : 
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginLeft: '10px' }}>
                  Add descriptor data in your class roster for aggregation options...
                </div>
              }
              </div>
              <hr />
            </div>
            <div>
              <h5 style={{marginBottom: '0px', fontWeight: "bold", color: "#004aad" }}>{descriptor2Name}</h5>
                <div 
                  style={{fontSize: '10px', textDecoration: 'underline', display: 'inline-block', marginRight: '10px', marginLeft: '10px', cursor: 'pointer'}}
                  onClick={() => setDescriptor2Checked(descriptor2Options.map(() => true))} // Select All
                  >
                  Select All
                </div>
                <div 
                  style={{fontSize: '10px', textDecoration: 'underline', display: 'inline-block', cursor: 'pointer'}}
                  onClick={() => setDescriptor2Checked(descriptor2Options.map(() => false))} // Clear All
                  >
                  Clear All
                </div>
              <div style={{maxHeight: '200px', overflowY: 'scroll', marginTop: '20px'}}>
              {descriptor2Options.length > 0 ? descriptor2Options.map((item, index) => (
                <div key={item} style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 10px' }}>
                  <FormGroup check key={`descriptor2Options-${index}`}>
                    <Label check>
                      <Input
                        type="checkbox"
                        id={item}
                        name={item}
                        checked={descriptor2Checked[index]}
                        onChange={() => handleCheckboxChange(index, descriptor2Checked, setDescriptor2Checked)}
                        style={{ marginRight: '5px' }}
                      />
                      <span className="form-check-sign" />
                      {item}
                    </Label>
                  </FormGroup>
                </div>
              ))
              : 
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', marginLeft: '10px' }}>
                  Add descriptor data in your class roster for aggregation options...
                </div>
              }
              </div>
              <hr />
            </div>
          </div>
        </Col>

        <Col xs="10">
          <Row style={{borderBottom: 'solid 1px lightgrey'}}>
            <Col className='text-center'>
              <Button className='button-hover-fix' style={{width: '12vw', borderRadius: '30px'}} onClick={() => {setShowSingleName(true); setGroups([]); useCustomList.current = false}}>Single Name</Button>
            </Col>
            <Col className='text-center'>
              <Button className='button-hover-fix' style={{width: '12vw', borderRadius: '30px'}} onClick={() => {splitRosterIntoGroups(1); useCustomList.current = false}}>Random Order</Button>
            </Col>
            <Col className='text-center'>
              <Dropdown isOpen={groupDropdownOpen} toggle={toggleGroupsDropdown} direction="down" >
                <DropdownToggle caret className='button-hover-fix' style={{width: '12vw', borderRadius: '30px'}}>Groups</DropdownToggle>
                <DropdownMenu style={{height: '400px', overflowY: 'auto', borderRadius: '8px'}} className="font-size-picker-menu-real">
                  {groupOptions.map((item, index) => 
                    <DropdownItem key={`groupOptions-${index}`} onClick={() => {splitRosterIntoGroups(item); useCustomList.current = false}}>{item} Groups</DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </Col>
            <Col className='text-center'>
              <Dropdown isOpen={customListDropdown} toggle={toggleCustomListDropdown} direction="down">
                <DropdownToggle caret className='button-hover-fix' style={{width: '12vw', borderRadius: '30px'}}>Custom List</DropdownToggle>
                <DropdownMenu style={{height: '400px', overflowY: 'auto'}} className="font-size-picker-menu">
                  <DropdownItem onClick={() => toggleCustomListModal()}>
                    <i id="removeNames" name="removeNames" style={{ marginRight: '5px' }} className="fa fa-plus" />
                    {' '}Add Custom List
                  </DropdownItem>
                  <DropdownItem divider />
                  {customLists?.map(list => (
                    <DropdownItem key={list.listName} onClick={() => handleCustomListSelect(list.list)} style={{paddingRight: '5px'}}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span>{list.listName}</span>
                        <Button
                          // className="btn-round btn-icon"
                          id="editCountdown"
                          type="button"
                          style={{ background: 'white', color: 'rgb(11, 192, 223)', position: 'absolute', right: '5px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                          size="sm"
                          onClick={() => {setCustomListToEditId(list.listId); toggleCustomListModal()}}
                        >
                          <i
                            id="editCustomList"
                            name="editCustomList"
                            style={{ marginLeft: 'auto', marginRight: '5px', cursor: 'pointer' }}
                            className="fa fa-pencil"
                          />
                        </Button>
                      </div>
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
          <Row>
            {showSingleName && 
              <div className="container mt-5">
                <SpinningWheel 
                  names={useCustomList.current === true ? selectedCustomList : rosterData?.roster.map(person => person.name)}
                  classId={props.current}
                />
              </div>
            }
          </Row>
          <Row className='h4'>
            {groups && groups.map((group, index) => {
                if (groups.length < 2 && group.length > 15) {
                    // Splitting large single group into multiple subgroups of 15
                    const subGroups = [];
                    for (let i = 0; i < group.length; i += 15) {
                        subGroups.push(group.slice(i, i + 15));
                    }
                    return subGroups.map((subGroup, subIndex) => (
                        <Card key={index + '-' + subIndex} style={{minWidth: '275px', margin: '10px', padding: '10px'}}>
                            <ol start={subIndex * 15 + 1}>
                                {subGroup.map((person, personIndex) => (
                                    <li key={personIndex}>{person.name}</li>
                                ))}
                            </ol>
                        </Card>
                    ));
                } else {
                    return (
                        <Card key={index} style={{minWidth: '275px', margin: '10px', padding: '10px'}}>
                            {groups.length < 2 ? (
                                <ol>
                                    {group.map((person, personIndex) => (
                                        <li key={personIndex}>{person.name}</li>
                                    ))}
                                </ol>
                            ) 
                            : 
                            (
                                <ul>
                                    <div className='h3 mb-1'>Group {index+1}</div>
                                    {group.map((person, personIndex) => (
                                        <li key={personIndex}>{person.name}</li>
                                    ))}
                                </ul>
                            )
                            }
                        </Card>
                    );
                }
            })}
          </Row>
        </Col>
      </Row>
    </div>
  )
}

import React, { useState, useEffect } from "react";
import Timer from "../Timer";
import Stopwatch from "../Stopwatch";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { db } from "../../firebase.js";
import { setDoc, doc, getDoc, collection, addDoc, updateDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ActiveDatesModal from "../ActiveDatesModal";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates } from "../../utils/ContentBoxUtils";
import { useDispatch } from "react-redux";

function TimerStopwatchContentBox({ content, classId, navbarMD, view, user, classes, updateContentBoxWithinMultiBox, multiBox, printActive, navigationVisible} ) {
  const dispatch = useDispatch()
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const today = new Date().toISOString().slice(0,10);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

//   const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
//     try {
//       const docRef = doc(db, "users", user.uid);
  
//       const userCopy = JSON.parse(JSON.stringify(user));
  
//       // Find the class index based on classId
//       const classIndex = userCopy.classes.findIndex(
//         (c) => c.classId === classId
//       );
  
//       if (classIndex !== -1) {
//         // Find the content box index based on contentBoxId
//         const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//           (box) => box.contentBoxId === content.contentBoxId
//         );
  
//         if (contentBoxIndex !== -1) {
//           // Make a copy of the existing content box using the spread operator
//           const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
//           // Update the content box with the new data
//           updatedContentBox.startDate = datesFromSetActiveDatesModal.startDate;
//           updatedContentBox.endDate = datesFromSetActiveDatesModal.endDate;
  
//           // Update the content box in the document data
//           userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
//           // Update the document in Firestore
//           await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
//         }
//         toggleSetActiveDatesModal();
//       }
//     } catch (error) {
//       console.error('Error updating document:', error);
//     }
// };

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

  const editBoxNameAlert = (contentBoxId) => {
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="What would you like to name this box?"
        onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
        defaultValue={content.heading}
      />
    );
  };
  
  // const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  //   hideAlert()
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     // Find the class index based on classId
  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );
  
  //     if (classIndex !== -1) {
  //       // Find the content box index based on contentBoxId
  //       const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
  //         (box) => box.contentBoxId === contentBoxId
  //       );
  
  //       if (contentBoxIndex !== -1) {
  //         // Make a copy of the existing content box using the spread operator
  //         const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex], userHeading: true };
  
  //         // Update the content box with the new data
  //         if (updatedHeading.length > 9) {
  //           updatedContentBox.heading = updatedHeading.substring(0, 9) + "..."
  //         } else if (updatedHeading.length === 0) {
  //           updatedContentBox.heading = "Type here..."
  //         } else {
  //           updatedContentBox.heading = updatedHeading
  //         }
  
  //         // Update the content box in the document data
  //         userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
  //         // Update the document in Firestore
  //         await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
  //         // Update the local state with the updated document data
  //         setBoxes(userCopy.classes[classIndex].contentBoxes);
  
  //         successAlert()
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error updating document:', error);
  //   }
  // };

  const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
    hideAlert();
  
    try {
      // Reference the specific content box document in Firestore
      const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);
  
      // Format the heading based on length
      const headingToUpdate =
        updatedHeading.length > 9
          ? updatedHeading.substring(0, 9) + "..."
          : updatedHeading.length === 0
          ? "Type here..."
          : updatedHeading;
  
      // Update the Firestore document
      await updateDoc(contentBoxRef, {
        heading: headingToUpdate,
        userHeading: true,
      });
  
      successAlert();
    } catch (error) {
      console.error("Error updating content box heading:", error);
    }
  };
  
  const warningWithConfirmAndCancelMessage = (contentBoxId) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Are you sure?"
        onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
        onCancel={() => cancelDelete()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this content box!
      </ReactBSAlert>
    );
  };
  
  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your content box has been deleted.
      </ReactBSAlert>
    );
  };
  
  const cancelDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your content box is safe :)
      </ReactBSAlert>
    );
  };
  
  // const deleteContentBox = async (contentBoxId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );
  
  //     const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
  //       (box) => box.contentBoxId === contentBoxId
  //     );
  
  //     // Remove the content box at the specified index
  //     userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  
  //     // // Update the local state with the updated document data
  //     // setStateContent(userCopy);
  
  //     successDelete()
  
  //     console.log('Content box deleted successfully!');
  //   } catch (error) {
  //     console.error('Error deleting content box:', error);
  //   }
  // }
  
  
  const addTemplateAlert = (contentBox) => {
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="What would you like to call this template?"
        onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
        defaultValue={contentBox.heading}
      />
    );
  };
  
  const addTemplateConfirmAlert = (e, contentBox) => {
    saveContentBoxTemplate(user, e, contentBox, dispatch);
    setAlert(
      <ReactBSAlert
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
        title={
          <p>
            You entered: <b>{e}</b>
          </p>
        }
      />
    );
  };
    
  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
      </ReactBSAlert>
    );
  };
  
  const hideAlert = () => {
    setAlert(null);
  };

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const handleSaveBackground = async (imageFileName) => {
  if (multiBox) {
    const backgroundUrl = `url(${imageFileName})`;
    updateContentBoxWithinMultiBox({...content, background: backgroundUrl }, true)
  } else {
    await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  }
};

// const saveBackgroundContentBox = async (imageFileName) => {
//   const thing = `url(${imageFileName})`
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     if (multiBox) {
//       updateContentBoxWithinMultiBox({...content, background: thing }, true)
//     } else {
//       const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//         (box) => box.contentBoxId === content.contentBoxId
//       );
    
//       userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;
    
//       // Update the document in Firestore
//       await setDoc(docRef, userCopy);
//     }

//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
// }

  return (
    <>
      {alert}
      <Col 
        style={{ flex: "1", minHeight: "0", padding: "4px", height: '100%',border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px'}}
        onMouseEnter={() => setShowMainEditButton(true)}
        onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
      >
        <Card 
          className="timerStopwatchContainer"
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: location.pathname !== '/print' ? content.background || '' : null, zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
        {showMainEditButton && location.pathname !== '/add-edit-classes' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
        {showButtons && location.pathname !== '/add-edit-classes' && (
            <>
              {/* <Button
                // className="btn-round btn-icon"
                id="editContentBoxHeading"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px'}}
                size="sm"
                onClick={() => editBoxNameAlert(content.contentBoxId)}
              >
                <i className="fa fa-pencil" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="editContentBoxHeading"
                  placement="left"
                >
                    Edit Heading
                </UncontrolledTooltip> */}
              <Button
               // className="btn-round btn-icon"
               id="changeTimerBoxBackground"
               type="button"
               style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px'}}
               size="sm"
               onClick={() => toggleBackgroundContentBoxModal()}
              >
                <i className="nc-icon nc-palette"></i>             
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changeTimerBoxBackground"
                  placement="left"
                >
                    Change Background
                </UncontrolledTooltip>
              {user.settings.useActiveDates && !multiBox ? (
                <>
                  <Button
                    // className="btn-round btn-icon"
                    id="setContentBoxActiveDates"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => toggleSetActiveDatesModal()}
                  >
                    <i className="fa fa-calendar" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="setContentBoxActiveDates"
                    placement="left"
                  >
                      Set Active Dates
                  </UncontrolledTooltip>
                </>
              ) : !multiBox &&
              (
                <>
                  <Button
                    // className="btn-round btn-icon"
                    id="sendBoxToStorage"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                  >
                    <i className="nc-icon nc-box" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="sendBoxToStorage"
                    placement="left"
                  >
                      Send Box to Storage
                  </UncontrolledTooltip>
                </>
              )}
              {!multiBox &&
                <>
                  <Button
                    className="button-delete"
                    id="deleteContentBox"
                    type="button"
                    style={{ color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', cursor: 'pointer', zIndex: '1049'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="deleteContentBox"
                      placement="left"
                    >
                        Delete
                    </UncontrolledTooltip>
                </>
              }
            </>
            )}
           </>
          )}
        {content && content.timer && content.stopwatch && (
          <>
            <Timer />
            <Stopwatch id={content.contentBoxId}/>
          </>
        )}
        {content && content.timer && !content.stopwatch && (
            <Timer box={content} id={content.contentBoxId}/>
        )}
        {content && content.stopwatch && !content.timer && (
            <Stopwatch id={content.contentBoxId}/>
        )}
        </Card>

        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>

          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}}>
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
      </Col>
    </>
  );
}

export default TimerStopwatchContentBox;

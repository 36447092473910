import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import Confetti from 'react-confetti'
import ReactConfetti from 'react-confetti';

const SpinningWheel = ({ names, classId }) => {
    const [selectedName, setSelectedName] = useState(null);
    const [spinning, setSpinning] = useState(false);
    const [speed, setSpeed] = useState(100); 
    const [useRemoveNames, setUseRemoveNames] = useState(() => {
        // Check localStorage on component load, default to false if not set
        const storedValue = localStorage.getItem(`useRemoveNames-${classId}`);
        return storedValue === 'true'; // Convert string to boolean
    });
    const [namesToRemoveList, setNamesToRemoveList] = useState([]);
    const [localNames, setLocalNames] = useState(names);
    const [currentIndex, setCurrentIndex] = useState(Math.floor(Math.random() * localNames?.length) || 0);

    const toggleUseRemoveNames = () => {
        const newValue = !useRemoveNames;
        setUseRemoveNames(newValue);
        localStorage.setItem(`useRemoveNames-${classId}`, JSON.stringify(newValue));
    }
  
    const refreshNamesToRemoveList = () => {
        setNamesToRemoveList([]);
    }

    useEffect(() => {
        const filteredNames = names.filter((_, index) => !namesToRemoveList.includes(index));
        setLocalNames(filteredNames);
    }, [namesToRemoveList, names]);

    // Filter names based on namesToRemoveList
    const startCarousel = () => {
        if (spinning) return;
    
        setSpinning(true);
        setSelectedName(null);
        setSpeed(100); // Reset speed to fast at the start of each spin
    
        // console.log("localNames => ", localNames);
        // console.log("namesToRemoveList => ", namesToRemoveList);
    
        let initialIndex = Math.floor(Math.random() * localNames.length);
        setCurrentIndex(initialIndex);
    
        let duration = 3000 + Math.random() * 2000; // 3-5 seconds
        let elapsedTime = 0;
    
        const spinInterval = setInterval(() => {
            let randomIndex = Math.floor(Math.random() * localNames.length);
            setCurrentIndex(randomIndex);
            elapsedTime += speed;
    
            if (elapsedTime > duration) {
                clearInterval(spinInterval);
                setSpinning(false);
    
                // Ensure that the correct name is set by using the randomIndex captured here
                const selectedName = localNames[randomIndex];
                setSelectedName(selectedName);
    
                // Map back to the original index in the names array
                if (useRemoveNames) {
                    const originalIndex = names.findIndex((name) => name === selectedName);
                    if (originalIndex !== -1) {
                        setNamesToRemoveList((prev) => [...prev, originalIndex]);
                    }
                }
            }
        }, speed);
    };

    return (
        <div className="carousel-picker-container text-center">
            <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                <div className="card-body">
                    <h1 className={selectedName ? "randomizer-flash" : "card-title"} style={{ fontSize: '125px' }}>{selectedName || spinning && localNames[currentIndex]}</h1>
                </div>
            </div>
            <Row>
                    {selectedName && !spinning && 
                        <>
                            <ReactConfetti
                                height="600"
                                width="1200"
                                recycle={false}
                                gravity={0.13}
                                numberOfPieces={500}
                            />
                        </>
                    }
                <Col md="3"></Col>
                <Col md="6">
                    <Button 
                        onClick={startCarousel} 
                        className="btn btn-primary mt-4 button-hover-fix" 
                        style={{borderRadius: '30px', padding: '0px 30px'}} 
                        disabled={spinning}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        {spinning ? "Randomizing..." : "Start"}
                    </Button>
                </Col>
                <Col md="3">
                    <div style={{ display: 'flex', alignItems: 'center', margin: '30px 0px 0px 20px' }}>
                        <input
                            type="checkbox"
                            id="removeNames"
                            name="removeNames"
                            checked={useRemoveNames}
                            onChange={toggleUseRemoveNames}
                            style={{ marginRight: '10px', transform: 'scale(1.5)' }}
                        />
                        <label style={{ marginTop: '6px' }}>Remove after selection.</label>
                    </div>
                    {useRemoveNames && 
                        <div 
                            onClick={refreshNamesToRemoveList} 
                            style={{ display: 'flex', alignItems: 'center', margin: '0px 0px 0px 20px', cursor: 'pointer' }}
                        >
                            <i 
                                id="removeNames" 
                                name="removeNames" 
                                style={{ marginRight: '10px', transform: 'scale(1.5)' }} 
                                className="fa fa-refresh"
                                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.7)")}
                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1.5)")}
                            />
                            <label style={{ marginTop: '6px' }}>Refresh randomizer list.</label>
                        </div>
                    }
                </Col>
            </Row>
        </div>
    );
};

export default SpinningWheel;


import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, Button, Row, Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc } from "firebase/firestore";
import { db, storage } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { useLocation } from "react-router-dom";
import usePersistentTimer from "/Users/michaeltucker/Documents/coding-projects/client/src/workers/usePersistentTimer.js";

const UseAutoMusic = ({userFromPreview, setUserForPreview}) => {

  // const user1 = {
  //   settings: {
  //     multiWeek: false,
  //   },
  //   autoMusic: {
  //     playlists: [
  //       {
  //         name: "Beginning Trumpet",
  //         playlistId: "playlistId_pysjvai5i9d",
  //         playlistData: [
  //           {
  //               next: false,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
  //               name: "Journey - Don't Stop Believin' (Official Audio)"
  //           },
  //           {
  //               next: false,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
  //               name: "Journey - Don't Stop Believin' (Official Audio)"
  //           },
  //           {
  //               next: true,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=1k8craCGpgs",
  //               name: "Journey - Don't Stop Believin' (Official Audio)"
  //           },
  //           {
  //               next: false,
  //               active: true,
  //               url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
  //               name: "Journey - Don't Stop Believin' (Official Audio)"
  //           }
  //         ]
  //       }
  //     ],
  //     segments: [
  //       {
  //         duration: "200",
  //         playlist: "Beginning Trumpet",
  //         playlistId: "playlistId_pysjvai5i9d",
  //         segmentId: "musicSegmentId_t978conlaip",
  //         showVideo: false,
  //         active: true,
  //         schedule: {
  //           "A": {
  //               "Monday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
  //               "Tuesday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
  //               "Wednesday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
  //               "Thursday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
  //               "Friday": { "active": true, "startTime": "00:27", "endTime": "00:00" },
  //               "Saturday": { "active": true, "startTime": "19:41", "endTime": "00:00" },
  //               "Sunday": { "active": true, "startTime": "04:10", "endTime": "00:00" }
  //             }
  //         }
  //       },
  //     ]
  //   }
  // }

  const user = userFromPreview != undefined ? userFromPreview : useSelector(selectUser);
  const location = useLocation();
  const [showMusicModal, setShowMusicModal] = useState(false);
  const [showVideoMusicModal, setShowVideoMusicModal] = useState(false);
  const [activeSegments, setActiveSegments] = useState([]);
  const [segmentSnapshot, setSegmentSnapshot] = useState({});
  const [currentWeek, setCurrentWeek] = useState(user.settings.multiWeek ? user.settings.currentWeek : "A");
  const [nextSegmentDisplay, setNextSegmentDisplay] = useState();
  const [timeDifferenceDisplay, setTimeDifferenceDisplay] = useState();
  const [currentSystemTimeDisplay, setCurrentSystemTimeDisplay] = useState();
  const [throttled, setThrottled] = useState(false); // State to track if the interval is being throttled
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const playerRef = useRef(null);
  const currentPlaylistRef = useRef()
  const logs = false;
  const { elapsedTime } = usePersistentTimer(); // Reliable ticking timer

  if (logs) console.log("userFromPreview => ", userFromPreview)

  useEffect(() => {
    // Check if YT API is already loaded
    if (window.YT && window.YT.Player) {
      initializeYouTubePlayer();
    } else {
      loadYouTubeAPI();
    }

    // Define global function YouTube API calls when ready
    window.onYouTubeIframeAPIReady = () => {
      if (logs) console.log("YouTube API is ready!");
      initializeYouTubePlayer();
    };
  }, [segmentSnapshot]);

  // Load YouTube API script dynamically
  const loadYouTubeAPI = () => {
    if (!document.getElementById("youtube-api-script")) {
      const script = document.createElement("script");
      script.id = "youtube-api-script";
      script.src = "https://www.youtube.com/iframe_api";
      script.async = true;
      document.body.appendChild(script);
    }
  };

  const onPlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.ENDED) {
      handleSongEnd();
    }
  };
  
  // Initialize YouTube Player once API is loaded
  const initializeYouTubePlayer = () => {
    if (!window.YT || !window.YT.Player) {
      console.warn("YouTube API is not ready yet.");
      return;
    }
  
    playerRef.current = new window.YT.Player("youtubePlayer", {
      events: {
        onReady: (event) => {
          console.log("YouTube Player Ready!");
          // Optionally start the video automatically:
          event.target.playVideo();
        },
        onStateChange: onPlayerStateChange,
      },
    });
  };

  const loadNextVideo = (nextVideoId) => {
    if (playerRef.current && typeof playerRef.current.loadVideoById === 'function') {
      playerRef.current.loadVideoById(nextVideoId);
    } else {
      console.warn("loadVideoById is not available; reinitializing player...");
      initializeYouTubePlayer();
      // Optionally, try again after a short delay
      setTimeout(() => {
        if (playerRef.current && typeof playerRef.current.loadVideoById === 'function') {
          playerRef.current.loadVideoById(nextVideoId);
        } else {
          console.error("Player still not ready");
        }
      }, 1000);
    }
  };

  const handleSongEnd = async () => {
    let currentPlaylist = currentPlaylistRef.current;
    try {
      if (!currentPlaylist) {
        console.warn("No playlist found for playlistId", segmentSnapshot.playlistId);
        return;
      }
  
      // 2. Find the index of the current song (marked with next: true)
      const currentIndex = currentPlaylist.playlistData.findIndex(
        (song) => song.next === true
      );

      const startingIndex = currentIndex === -1 ? 0 : currentIndex;
      
      // 3. Determine the index of the next song (wrap around if needed)
      let nextIndex = startingIndex + 1;
      if (nextIndex >= currentPlaylist.playlistData.length) {
        nextIndex = 0;
      }
      
      // 4. Create an updated playlistData array where only the next song is marked as next: true
      const updatedPlaylistData = currentPlaylist.playlistData.map((song, idx) => ({
        ...song,
        next: idx === nextIndex,
      }));
      
      // 5. If NOT in preview mode, update the database with the new playlistData
      // if (userFromPreview === undefined && user.uid) { //this logic is checking to make sure we're not in preview mode
      //   const docRef = doc(db, "users", user.uid);
      //   // Clone the user so we don't mutate the original Redux store
      //   const userCopy = JSON.parse(JSON.stringify(user));
      //   const playlistIndex = userCopy.autoMusic.playlists.findIndex(
      //     (playlist) => playlist.playlistId === currentPlaylist.playlistId
      //   );
      //   if (playlistIndex === -1) {
      //     console.warn("Playlist not found in user record");
      //     return;
      //   }

      //   // 5.5 Update the currentPlaylistRef
      //   userCopy.autoMusic.playlists[playlistIndex].playlistData = updatedPlaylistData;
      //   currentPlaylistRef.current = userCopy.autoMusic.playlists[playlistIndex];

      //   await setDoc(docRef, userCopy);
      // }
      if (userFromPreview === undefined && user.uid) { // Ensure not in preview mode
        try {
          const userDocRef = doc(db, "usersNew", user.uid); // Updated to use "usersNew"
      
          // Fetch the latest user document snapshot
          const userSnap = await getDoc(userDocRef);
          if (!userSnap.exists()) {
            console.warn("User document not found in Firestore");
            return;
          }
      
          // Get existing user data
          const userData = userSnap.data();
      
          // Find the index of the playlist in autoMusic.playlists
          const playlistIndex = userData?.autoMusic?.playlists?.findIndex(
            (playlist) => playlist.playlistId === currentPlaylist.playlistId
          );
      
          if (playlistIndex === -1 || playlistIndex === undefined) {
            console.warn("Playlist not found in user record");
            return;
          }
      
          // Update the playlist data
          userData.autoMusic.playlists[playlistIndex].playlistData = updatedPlaylistData;
      
          // Update the document in Firestore
          await setDoc(userDocRef, { autoMusic: userData.autoMusic }, { merge: true });
      
          // Update the ref with the latest playlist data
          currentPlaylistRef.current = userData.autoMusic.playlists[playlistIndex];
      
          console.log("✅ Playlist updated successfully in Firestore!");
        } catch (error) {
          console.error("❌ Error updating playlist in Firestore:", error);
        }
      }      
    
      // 6. Extract the video ID for the next song and load it
      const nextSong = updatedPlaylistData[nextIndex];
      if (nextSong && nextSong.url) {
        const nextVideoId = getYoutubeVideoId(nextSong.url);
        if (nextVideoId) {
          // Delay a little bit to give the player time to reset
          setTimeout(() => {
            loadNextVideo(nextVideoId);
          }, 500); // 500 ms delay, adjust as needed
        } else {
          console.error("Could not extract next video ID");
        }
        console.log("Loaded next song:", nextSong);
      } else {
        console.error("Next song is missing a URL or player is not available");
      }
    } catch (error) {
      console.error("Error handling song end:", error);
    }
  };

  // Pause or Play the video
  const handlePause = () => {
    // console.log("clicked")
    if (playerRef.current && playerRef.current.pauseVideo) {
      if (isPaused) {
        playerRef.current.playVideo();
      } else {
        playerRef.current.pauseVideo();
      }
      setIsPaused(!isPaused);
    }
  };

  // Stop the video
  const handleStop = async () => {
    if (playerRef.current && playerRef.current.stopVideo) {
      playerRef.current.stopVideo();
    }
    // Optionally, update the playlist's next song in the database
    // (You might want to call handleSongEnd() or similar logic here.)
    await handleSongEnd();
    if (userFromPreview != undefined) { //Resetting userFromPreview for Preview mode.
      setUserForPreview(null)
    }
    toggle(); // Close the modal or perform any additional UI updates
  };
  

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // useEffect(() => {
  //   let lastRun = Date.now();
  //   let timeoutId;
  
  //   const checkAndTriggerLoop = () => {
  //     checkAndTrigger();
  //     const now = Date.now();
  //     const timeElapsed = now - lastRun;
  //     // console.log("timeElapsed => ", timeElapsed);
  
  //     // If more than 60,000ms (60 seconds) passed, throttling is likely happening
  //     if (timeElapsed > 2000) {
  //       setThrottled(true);
  //       // console.log("Throttling detected! Running every 60+ seconds.");
  //     } else {
  //       setThrottled(false);
  //     }
  
  //     // Log the current value of throttled state to verify its update
  //     setTimeout(() => {
  //       //console.log("throttled state value =>", throttled); // This will show the correct throttled state
  //     }, 0); // Ensure the state update has happened before logging it
  
  //     lastRun = now;
  //     timeoutId = setTimeout(checkAndTriggerLoop, 1000); // Re-run the loop every 1 second
  //   };
  
  //   checkAndTriggerLoop();
  
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [throttled]); // Add `throttled` to the dependency array
  
  useEffect(() => {
    checkAndTrigger();
  }, [elapsedTime]);

  const checkAndTrigger = async () => {
    const currentTime = new Date();
    setCurrentSystemTimeDisplay(formatCurrentAndStartTime(currentTime));

    const currentDayNumber = currentTime.getDay();
    let currentDay;

    if (user.settings.multiWeek && user.settings.cycleType === "day") {
      currentDay = "Monday";
    } else {
      switch (currentDayNumber) {
        case 1:
          currentDay = "Monday";
          break;
        case 2:
          currentDay = "Tuesday";
          break;
        case 3:
          currentDay = "Wednesday";
          break;
        case 4:
          currentDay = "Thursday";
          break;
        case 5:
          currentDay = "Friday";
          break;
        case 6:
          currentDay = "Saturday";
          break;
        case 0:
          currentDay = "Sunday";
          break;
        default:
          currentDay = "";
          break;
      }
    }

    const processSegments = async () => {
      if (logs) console.log("user => ", user)
      const segments = user?.autoMusic?.segments
        ? await Promise.all(
            user.autoMusic.segments
              .filter(segment => segment.active && segment?.schedule?.[currentWeek || "A"]?.[currentDay]?.active)
              .map(async segmentMap => {
                if (segmentMap && segmentMap.schedule[currentWeek || "A"]?.[currentDay]) {
                  const startTimeString = segmentMap.schedule[currentWeek || "A"][currentDay].startTime || "0:00:00";
                  const [startHours, startMinutes, startSeconds] = startTimeString.split(':'); //startSeconds needed for Preview mode.
                  const parsedStartTime = new Date();
                  parsedStartTime.setHours(startHours);
                  parsedStartTime.setMinutes(startMinutes);
                  // userFromPreview != undefined ? parsedStartTime.setSeconds(startSeconds) : parsedStartTime.setSeconds(0) //Preview mode.
                  if (startSeconds) {
                    parsedStartTime.setSeconds(startSeconds);
                  } else {
                    parsedStartTime.setSeconds(0);
                  }
                  // 🛑 Check if playlist exists, return empty object if missing
                  const playlistForSegment = user.autoMusic.playlists.find(playlist => playlist.playlistId === segmentMap.playlistId);
                  if (!playlistForSegment) {
                    if (logs) console.warn(`🚨 Skipping segment ID: ${segmentMap.playlistId} (No playlist found)`);
                    return {}; // ✅ Simply return an empty object
                  }

                  // ✅ Get the next song (or first song as fallback)
                  const nextSong = playlistForSegment.playlistData.find(song => song.next) || playlistForSegment.playlistData[0];

                  return {
                    startTime: parsedStartTime,
                    duration: segmentMap.duration,
                    playlistId: segmentMap.playlistId,
                    url: nextSong?.url || null, // ✅ Ensure there's a valid URL
                    showVideo: segmentMap.showVideo,
                    showVideoLocation: segmentMap.showVideoLocation,
                    showVideoBackdrop: segmentMap.showVideoBackdrop,
                    active: segmentMap.active,
                    buffer: parseFloat(segmentMap.duration) * 1000,
                  };
                } else {
                  return {}; // Return an empty object or handle the null case as needed
                }
              })
          )
      : [];

      const filteredSegments = segments.filter(segment => {
        const segmentStartTime = segment?.startTime?.getTime();
        const currentTimeTime = currentTime.getTime();
        return segmentStartTime >= currentTimeTime || currentTimeTime - segmentStartTime <= 3000;
      });

      if (logs) console.log("filteredSegments => ", filteredSegments)

      filteredSegments.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

      setActiveSegments(filteredSegments);

      if (filteredSegments.length > 0) {
        const nextSegment = filteredSegments[0];
        setNextSegmentDisplay(nextSegment);
        const timeDifference = nextSegment.startTime.getTime() - currentTime.getTime();
        setTimeDifferenceDisplay(timeDifference);
        // console.log("timeDifference => ", timeDifference);

         // If throttled, expand the window to 3000ms (3 seconds)
        const triggerWindow = throttled ? 3000 : 1000;
        // console.log("throttled => ", throttled);

        if (timeDifference <= 0 && timeDifference >= -triggerWindow) {
          const playlist = user.autoMusic.playlists.find(
            (playlist) => playlist.playlistId === nextSegment.playlistId
          );
          currentPlaylistRef.current = playlist

          setSegmentSnapshot(nextSegment);
          if (nextSegment.showVideo) {
            setShowVideoMusicModal(true);
          } else {
            setShowMusicModal(true);
          }
          setTimeout(() => {
            setShowVideoMusicModal(false);
            setShowMusicModal(false);
          }, nextSegment.buffer - Math.abs(timeDifference));
        }
      }
    };

    processSegments();
  };

  const fetchAudioDuration = (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = (error) => {
        reject(error);
      };
    });
  };

  const calculateBufferAddition = async (alertSound) => {
    let bufferAddition = 10;
    if (alertSound === "none") return 3;

    try {
      const duration = await fetchAudioDuration(alertSound);
      return duration + 3; // Adding 3 seconds to the duration
    } catch (error) {
      console.error('Error fetching audio duration:', error);
      return bufferAddition;
    }
  };

  const handleCountdownComplete = () => {
    if (segmentSnapshot.flash) {
      setIsFlashing(true);
    }
  };

  const toggle = () => {
    console.log("toggle")
    setShowMusicModal(!showMusicModal);
  };

  const toggleShowVideoModal = () => {
    console.log("toggleShowVideoModal")
    setShowVideoMusicModal(!showVideoMusicModal);
  };

  const formatTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatCurrentAndStartTime = (currentTime) => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
  };

  const getYoutubeVideoId = (url) => {
    try {
        let videoId = null;

        if (url.includes("youtu.be/")) {
            videoId = url.split("youtu.be/")[1]?.split(/[?&]/)[0];
        } else if (url.includes("watch?v=")) {
            videoId = url.split("v=")[1]?.split(/[?&]/)[0];
        } else if (url.includes("/embed/")) {
            videoId = url.split("/embed/")[1]?.split(/[?&]/)[0];
        } else if (url.includes("/shorts/")) {
            videoId = url.split("/shorts/")[1]?.split(/[?&]/)[0];
        } else if (url.includes("youtube-nocookie.com/embed/")) {
            videoId = url.split("/embed/")[1]?.split(/[?&]/)[0];
        }

        if (!videoId || videoId.length < 10) { // Ensure extracted ID is valid
            console.warn("Invalid or missing YouTube video ID in URL:", url);
            return null;
        }

        return videoId;
    } catch (error) {
        console.error("Error extracting YouTube video ID:", error);
        return null;
    }
};

  const getYouTubeEmbedUrl = (url) => {
    let videoId = null;
    let startTime = null;

    // Extract start time (must be a number)
    const startMatch = url.match(/(?:start=|t=)(\d+)/);
    if (startMatch) {
        startTime = startMatch[1]; // Ensure it's a valid number
    }

    // Extract video ID from different possible YouTube URL formats
    if (url.includes("youtube-nocookie.com/embed/")) {
        videoId = url.split("/embed/")[1]?.split("?")[0];
    } else if (url.includes("youtu.be/")) {
        videoId = url.split("youtu.be/")[1]?.split("?")[0];
    } else if (url.includes("youtube.com/watch?v=")) {
        videoId = url.split("v=")[1]?.split("&")[0];
    } else if (url.includes("youtube.com/embed/")) {
        videoId = url.split("/embed/")[1]?.split("?")[0];
    }

    if (!videoId) return null; // Return null if no valid video ID is found

    // Construct the embed URL
    let embedUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1&enablejsapi=1`;

    // Append start time only if it contains a valid number
    if (startTime && !isNaN(startTime)) {
        embedUrl += `&start=${startTime}`;
    }

    return embedUrl;
};

  return (
    <>
      {location.pathname === "/auto-status" ? (
          <Card>
            <CardHeader className='h3 mb-0'>Auto Music</CardHeader>
            <CardBody>
              <b>Next Music Segment:</b> {nextSegmentDisplay && nextSegmentDisplay.header || "No countdowns scheduled for the rest of the day."}
              <br></br>
              <b>Next Music Segment Start Time:</b> {nextSegmentDisplay && formatCurrentAndStartTime(nextSegmentDisplay.startTime) || "No countdowns scheduled for the rest of the day."}
              <br></br>
              <b>Current System Time:</b> {currentSystemTimeDisplay}
              <br></br>
              <b>Time Until Triggered:</b> {timeDifferenceDisplay && formatTime(timeDifferenceDisplay) || "No countdowns scheduled for the rest of the day."}
            </CardBody>
          </Card>
        ) : (
          segmentSnapshot.showVideo  ? ( //segmentSnapshot.showVideo 
            <Modal 
              isOpen={showVideoMusicModal} //showVideoMusicModal
              toggle={toggleShowVideoModal}
              modalClassName={segmentSnapshot.showVideoLocation} //segmentSnapshot.showVideoLocation
              contentClassName={segmentSnapshot.showVideoLocation === 'full-screen' ? "full-screen-modal-content" : null }
              backdrop={segmentSnapshot.showVideoBackdrop || null} 
              className="video-modal"
            >
              <ModalHeader toggle={toggleShowVideoModal}/>
                <ModalBody>
                    <iframe
                    id="youtubePlayer"
                    width={segmentSnapshot.showVideoLocation === 'full-screen' ? "100%" : "600"}
                    height={segmentSnapshot.showVideoLocation === 'full-screen' ? "100%" : "375"}
                    src={segmentSnapshot.url && getYouTubeEmbedUrl(segmentSnapshot.url)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    title="YouTube video player"
                    ></iframe>
                </ModalBody>
              </Modal>
          ) : (
              <Modal style={{width: '100px', padding: '0px', margin: '10px', backgroundColor: 'transparent', zIndex: '9999'}} isOpen={showMusicModal} toggle={toggle} modalClassName="auto-music-player-modal" backdrop={false}>
                <Row style={{margin: '0px'}}>
                  <Button onClick={handlePause}>
                    <i className={isPaused ? "fa fa-play" : "fa fa-pause"} />
                  </Button>
                  <Button onClick={handleStop}>
                    <i className="fa fa-stop" />
                  </Button>
                </Row>
                <iframe
                  id="youtubePlayer"
                  width="100%"
                  height="0"
                  src={segmentSnapshot.url && getYouTubeEmbedUrl(segmentSnapshot.url)}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="YouTube video player"
                ></iframe>
              </Modal>
          )
        )
      }
    </>
  )
};

export default UseAutoMusic;

import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase.js";
import { setDoc, doc, getDoc, collection, addDoc, updateDoc } from "firebase/firestore";
import backgroundOptionsData from "../../BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, FormFeedback} from "reactstrap";
import FontStylePicker from "../FontStylePicker";
import ReactDatetime from "react-datetime";
import FontSizePicker from "../FontSizePicker";
import { createRandomId } from "RandomId";
import ActiveDatesModal from "../ActiveDatesModal";
import Scheduling from "utils/Scheduling";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates, zoomContentBox } from "../../utils/ContentBoxUtils";
import { useDispatch } from "react-redux";

function CountdownBox({content, setHeading, updateContentBox, classId, user, classes, navbarMD, view, addContentBox, multiBox, updateContentBoxWithinMultiBox, printActive, navigationVisible}) {
  const dispatch = useDispatch()
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState()
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation()
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const date = new Date().toLocaleDateString('en-CA')
  const [editCountdownBoxModal, setEditCountdownBoxModal] = useState(false)

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-25}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const saveCountdownBoxData = () => {
    if (multiBox) {
      updateContentBoxWithinMultiBox({...content, content: {...dataFromModal}}, false)
    } else {
      updateContentBox(
        user.uid,
        classId,
        content.contentBoxId, 
        {...content, content: {...dataFromModal}},
        null
      )    
    }
    toggleEditCountdownBoxModal()
  }

const [activeDatesModal, setActiveDatesModal] = useState(false)

const toggleSetActiveDatesModal = () => {
  setActiveDatesModal(!activeDatesModal)
}

const [activeDates, setActiveDates] = useState({
  startDate: content.startDate === undefined ? "" : content.startDate,
  endDate: content.endDate === undefined ? "" : content.endDate
});

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const handleSaveBackground = async (imageFileName) => {
  if (multiBox) {
    const backgroundUrl = `url(${imageFileName})`;
    updateContentBoxWithinMultiBox({...content, background: backgroundUrl }, true)
  } else {
    await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  }
};

const notificationAlert = useRef();

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert();

  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // Format the heading based on length
    const headingToUpdate =
      updatedHeading.length > 9
        ? updatedHeading.substring(0, 9) + "..."
        : updatedHeading.length === 0
        ? "Type here..."
        : updatedHeading;

    // Update the Firestore document
    await updateDoc(contentBoxRef, {
      heading: headingToUpdate,
      userHeading: true,
    });

    successAlert();
  } catch (error) {
    console.error("Error updating content box heading:", error);
  }
};

const warningWithConfirmAndCancelMessage = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox, dispatch);
  setAlert(
    <ReactBSAlert
    style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
    onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const toggleEditCountdownBoxModal = () => {
  setEditCountdownBoxModal(prev => !prev)
}

const [dataFromModal, setDataFromModal] = useState(
  {
    eventName: content.content?.eventName || '',
    fontStyle: content.content?.fontStyle || 'helvetica',
    fontColor: content.content?.fontColor || '',
    eventDate: content.content?.eventDate || new Date().toLocaleDateString('en-CA'),
    daysToCount: content.content?.daysToCount || ''
  }
)

const handleZoomChange = async (direction) => {

  if (multiBox) {
    const zoomIncrement = direction === "up" ? 0.1 : -0.1;
    const previousZoom = content.zoom || 1;
    const newZoom = Math.max(0.1, previousZoom + zoomIncrement); // Prevents zoom from going below 0.1

    updateContentBoxWithinMultiBox({ ...content, zoom: newZoom }, true);
  } else {
    const newZoom = await zoomContentBox(
      user.uid,
      classId,
      content.contentBoxId,
      direction,
      multiBox,
      updateContentBoxWithinMultiBox
    );
  }

  // if (newZoom !== undefined) {
  //   setBoxData((prev) => ({
  //     ...prev,
  //     zoom: newZoom,
  //   }));
  // }
};

const handleDataFromModalChange = (e, date = false) => {
  if (date) {
    const formattedDate = e.format('YYYY-MM-DD'); // Adjust the format as needed
    setDataFromModal((prevData) => ({
      ...prevData,
      eventDate: formattedDate,
    }));
  } else {
    setDataFromModal((prevData) => ({
      ...prevData,
      [e.target.id]: e.target.value
    }))
  }
}

const calculateClassDays = (eventDate) => {
  if (!user || !user.settings || !user.settings.cycleType || !classId) return 0;

  const today = new Date();
  const targetDate = new Date(eventDate);
  const numberOfWeeks = user.settings.numberOfWeeks || 1; // Number of weeks in the schedule cycle
  const classIndex = classes.findIndex((c) => c.classId === classId);

  if (classIndex === -1) return 0;

  const schedule = classes[classIndex].schedule; // Get class schedule
  const currentCycleIndex = (user.settings.currentWeek || "A").charCodeAt(0) - 65;

  let classDaysCount = 0;
  let daysElapsed = 0;
  let currentDate = new Date(today);

  if (user.settings.cycleType === "day") {

    // Daily Cycle: Only consider a specific weekday (e.g., Monday)
    const cycleDay = "Monday"; // You can parameterize this if needed

    // Count how many times the class meets on that weekday in one full cycle
    let activeDaysInCycle = 0;
    for (let i = 0; i < numberOfWeeks; i++) {
      if (schedule[String.fromCharCode(65 + i)][cycleDay]?.active) {
        activeDaysInCycle++;
      }
    }
    // console.log("activeDaysInCycle => ", activeDaysInCycle)

    // Count only weekdays between today and eventDate
    let validDaysBetween = 0;
    let tempDate = new Date(today);
    while (tempDate <= targetDate) {
      const dayOfWeek = tempDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      if (dayOfWeek !== 0 && dayOfWeek !== 6) { // Exclude weekends
        validDaysBetween++;
      }
      tempDate.setDate(tempDate.getDate() + 1);
    }
    // console.log("validDaysBetween => ", validDaysBetween)

    // Calculate full cycles and remaining days within the cycle
    const fullCycles = Math.floor(validDaysBetween / numberOfWeeks);
    // console.log("fullCycles => ", fullCycles)

    const daysRemainingAfterEndOfLastFullCycleToEventDate = validDaysBetween % numberOfWeeks; //Total extra days (not class periods) after fullCycles 
    // console.log("numberOfWeeks => ", numberOfWeeks)


    let remainingClassDays = 0;

    const currentDay = user.settings.currentWeek || "A"; // Defaults to "A" if missing
    const daysInCycle = Scheduling.weekDayLetterOptions.slice(0, user.settings.numberOfWeeks); // List of valid cycle days ["A", "B", "C", ...]
    const daysRemainingInCurrentCycle = 0;

    console.log("daysInCycle => ", daysInCycle)
    const daysInCycleDuplicatedForOverflow = [...daysInCycle, ...daysInCycle]; // List of valid cycle days ["A", "B", "C", ...]
    const indexOfCurrentDay = daysInCycle.indexOf(currentDay); // Get index of current cycle day
    console.log("indexOfCurrentDay => ", indexOfCurrentDay)
    
    for (let i = 0; i < daysRemainingAfterEndOfLastFullCycleToEventDate; i++) {
      console.log("remainingDays => ", remainingDays)
      // **Cycle through the day letters correctly, wrapping back to "A" when needed**
      const cycleIndex = ((indexOfCurrentDay+1) + i)
      console.log("cycleIndex => ", cycleIndex)
      const cycleDay = daysInCycleDuplicatedForOverflow[cycleIndex]; // Get the correct cycle day letter
      console.log("cycleDay => ", cycleDay)

    
      // **Check if the class meets on this cycle day**
      if (schedule[cycleDay]?.Monday?.active) {
        remainingClassDays++;
      }
    }    

    // console.log("remainingClassDays => ", remainingClassDays)
    // console.log("activeDaysInCycle => ", activeDaysInCycle)

    classDaysCount = (fullCycles * activeDaysInCycle) + remainingClassDays;

    // console.log("(fullCycles * activeDaysInCycle) + remainingClassDays => ", classDaysCount)

  } else {
    // Weekly Cycle: Iterate over schedule and count active class days
    while (currentDate <= targetDate) {
      const dayOfWeek = currentDate.getDay(); // 0 = Sunday, ..., 6 = Saturday
      const weekIndex = (currentCycleIndex + Math.floor(daysElapsed / 7)) % numberOfWeeks; // Determine week in cycle

      const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const dayName = dayNames[dayOfWeek];

      if (schedule[String.fromCharCode(65 + weekIndex)][dayName]?.active) {
        classDaysCount++;
      }

      daysElapsed++;
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  return classDaysCount;
};


function calculateWeekDaysOnly(targetDate) {
  const target = new Date(targetDate);
  const today = new Date();
  let weekDaysCount = 0;
  let currentDate = new Date(today);

  while (currentDate <= target) {
    const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 6 = Saturday
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      weekDaysCount++;
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weekDaysCount;
}

function calculateAllDays(targetDate) {
  const target = new Date(targetDate);
  const today = new Date();
  return Math.ceil((target - today) / (1000 * 3600 * 24)); // Convert ms to days
}

function calculateDaysRemaining(targetDate) {
  if (!targetDate) return 0;

  // First check if "classDaysOnly" is selected
  if (content.content?.daysToCount === "classDaysOnly" || dataFromModal.daysToCount === "classDaysOnly") {
    // If cycleType is NOT set, fallback to weekDaysOnly
    if (!user?.settings?.cycleType) {
      return calculateWeekDaysOnly(targetDate);
    }
    return calculateClassDays(targetDate);
  } 

  // Then check if "weekDaysOnly" is selected
  if (content.content?.daysToCount === "weekDaysOnly" || dataFromModal.daysToCount === "weekDaysOnly") {
    return calculateWeekDaysOnly(targetDate);
  }

  // Default: Count all days
  return calculateAllDays(targetDate);
}


const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};

  return (
    <>
    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}
      onMouseEnter={() => setShowMainEditButton(true)}
      onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
    >
      {alert}
        <Card 
          key={content.contentBoxId}
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: location.pathname !== '/print' ? content.background : null || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
          {showMainEditButton && location.pathname !== '/add-edit-classes' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      // className="btn-round btn-icon"
                      id="editCountdown"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => toggleEditCountdownBoxModal()}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editCountdown"
                        placement="left"
                      >
                          Edit Countdown
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i className="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxBigger"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => handleZoomChange('up')}
                    >
                      <i className="fa fa-plus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxBigger"
                        placement="left"
                      >
                        Bigger
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxSmaller"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => handleZoomChange('down')}
                    >
                      <i className="fa fa-minus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxSmaller"
                        placement="left"
                      >
                        Smaller
                      </UncontrolledTooltip>
                      {user.settings.useActiveDates && !multiBox ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) : !multiBox &&
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                      <Button
                        // className="btn-round btn-icon"
                        id="copyBox"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(540%)' : 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="copyBox"
                        placement="left"
                      >
                          Duplicate Box
                      </UncontrolledTooltip>
                    {!multiBox && 
                      <>
                        <Button
                        className="button-delete"
                        id="deleteContentBox"
                        type="button"
                        style={{ color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="deleteContentBox"
                          placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                      </>
                    }
                  </>
                  )}
            </>
          )}
          <CardBody>
            {content.content !== null ?
              // <div style={{ minHeight: cardHeight.editor, overflowY: 'auto' }}>
                // <div style={{fontFamily: content.content.fontStyle, color: content.content.fontColor, display: 'flex', placeContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <div style={{fontFamily: content.content.fontStyle, color: location.pathname !== '/print' ? content.content.fontColor : null, textAlign: 'center', zoom: content.zoom || '1'}}>
                        <Row style={{margin: '20px', marginBottom: '0px', display: 'flex', justifyContent: 'center'}}>
                          <h1 style={{margin: '0px', textAlign: 'center'}}>Days Until {content.content.eventName}:</h1>
                        </Row>
                        <Row style={{margin: '20px', marginBottom: '0px', display: 'flex', justifyContent: 'center'}}>
                          <h1 style={{fontSize: '100px', marginBottom: '0px'}}>{calculateDaysRemaining(content.content.eventDate)}</h1>
                        </Row>
                  </div>
              // </div>
            :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  className="button-hover-fix"
                  style={{borderRadius: '30px'}}
                  onClick={() => toggleEditCountdownBoxModal(!editCountdownBoxModal)}
                >
                  <i className='fa fa-pencil' />
                            {' '}Edit Countdown
                </Button>
              </div>
            }
          </CardBody>
        </Card>

        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>
          
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>


          <Modal 
            style={{height: '650px'}}
            isOpen={editCountdownBoxModal}
            toggle={toggleEditCountdownBoxModal}
            backdrop={true}
            contentClassName="add-edit-countdown-modal"
          >
            <ModalHeader toggle={toggleEditCountdownBoxModal}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Edit Countdown</h4>
            </ModalHeader>
            <ModalBody style={{padding: '0'}}>
            <div style={{margin: '20px'}}>
              <Row style={{margin: '10px'}}>
                <Col md="4">
                  <FormGroup>
                    <Label>Days remaining until _____ :</Label>
                    <Input
                      defaultValue={dataFromModal.eventName}
                      placeholder="Ex: the Concert"
                      id="eventName"
                      onChange={(e) => handleDataFromModalChange(e)}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Date of Event:</Label>
                    <ReactDatetime
                      // dateFormat={false}
                      timeFormat={false}
                      // value={dataFromModal?.eventDate}
                      initialValue={dataFromModal?.eventDate}
                      id="eventDate"
                      inputProps={{
                        className: "form-control",
                        placeholder: ""
                      }}
                      onChange={(e) => handleDataFromModalChange(e, true)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Days to Count:</Label>
                    <Input
                      type="select"
                      id="daysToCount"
                      onChange={(e) => handleDataFromModalChange(e)}
                      defaultValue={dataFromModal?.daysToCount}
                    >
                      <option value="all">All</option>
                      <option value="weekDaysOnly">Weekdays Only</option>
                      <option disabled value="classDaysOnly">Class Days (Coming Soon)</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{margin: '10px'}}>
                <Col md="4">
                  <FormGroup>
                    <Label>Font Style</Label>
                    <FontStylePicker 
                      id="fontStyle"
                      onChangeFunction={handleDataFromModalChange}
                      selectedFont={dataFromModal.fontStyle}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Font Size (Coming Soon)</Label>
                    <FontSizePicker disabled={true}/>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Font Color</Label>
                    <Input
                      type="color"
                      placeholder=""
                      id="fontColor"
                      style={{height: '39px'}}
                      onChange={(e) => handleDataFromModalChange(e)}
                      value={dataFromModal.fontColor}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{margin: '10px'}}>
                <div style={{ height: '300px', margin: '10px', width: '100%'}}>
                  <Card style={{fontFamily: dataFromModal.fontStyle, color: dataFromModal.fontColor, border: '1px solid lightgrey', display: 'flex', placeContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                      <div style={{margin: '30px', marginBottom: '0px'}}>
                        <h1 style={{margin: '0px'}}>Days Until {dataFromModal.eventName}:</h1>
                      </div>
                      <div>
                        <h1 style={{fontSize: '100px'}}>{calculateDaysRemaining(dataFromModal.eventDate)}</h1>
                      </div>
                    {/* <Row>
                      <h1>{dataFromModal.eventName}:</h1>
                    </Row> */}
                  </Card>
                </div>
              </Row>
              <Row style={{margin: '10px'}}>
                <Col style={{display: 'flex', alignContent: 'center', justifyContent: 'center', margin: '0px'}}>
                  <Button
                    style={{borderRadius: '30px'}}
                    id="saveCountdownBoxData"
                    type="button"
                    size="sm"
                    onClick={() => saveCountdownBoxData()}
                  >
                    Save Countdown Box
                  </Button>
                </Col>
              </Row>
            </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
            <ModalBody>
              <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
                Copy box to:
              </h3>

              {user && classes && (
                <Row>
                    <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
                      {classes.map((x, index) => (
                        <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                          <Label check>
                            <Input
                              type="checkbox"
                              defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                              onChange={() => handleCheckboxChange(x.classId)}
                            />
                            <span className="form-check-sign" />
                            {x.className}
                          </Label>
                        </FormGroup>
                      ))}
                    </Col>
                </Row>
              )}
            </ModalBody>
            <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
              <Button 
                onClick={toggleCopyBoxModal}
                className="delete-button"
                style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
              >
                Cancel
              </Button>
              <Button 
                className="button-hover-fix"
                disabled={classesSelectedForContentBoxCopy.length < 1} 
                onClick={() => copyBoxToClasses()} 
                style={{borderRadius: '30px'}}>
                <i className="fa fa-copy" />{' '}Copy
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
    </>
  );
}

export default CountdownBox;


import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { serverTimestamp } from "firebase/firestore";
import { createRandomId, createShareCode } from "RandomId";
import jwtDecode from "jwt-decode";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormFeedback
} from "reactstrap";
import Scheduling from "utils/Scheduling";

function Register() {
  React.useEffect(() => {
    document.body.classList.toggle("register-page");
    return function cleanup() {
      document.body.classList.toggle("register-page");
    };
  });

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [leadSource, setLeadSource] = useState("");
  const [showValidation, setShowValidation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const accountCreated = new Date(Date.now() + (-5 * 60) * 60 * 1000);

  const announcements = [
    {
      html: "<p><strong>🎉 <span style=\"color: rgb(11, 192, 223);\">Welcome to Lesson Launchpad!!!</span> 🎉</strong></p>",
      text: "🎉 Welcome to Lesson Launchpad!!! 🎉",
      active: true,
      show: true,
      lastEdited: "",
      dateCreated: "",
      classIds: ["welcome1"],
      id: "id111111111",
      startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
      endDate: ""
    },
    {
      html: "<p>You can use this space for whatever you wish or turn it off completely in the Add/Edit Classes tab.</p>",
      text: "You can use this space for whatever you wish or turn it off completely in the Add/Edit Classes tab.",
      active: true,
      show: true,
      lastEdited: "",
      dateCreated: "",
      classIds: ["welcome1"],
      id: "id222222222",
      startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
      endDate: ""
    },
    {
      html: "<p>✏️Hover your mouse over this space and click the pencil button to edit.</p>",
      text: "Hover your mouse over this space and click the pencil button to edit.",
      active: true,
      show: true,
      lastEdited: "",
      dateCreated: "",
      classIds: ["welcome1"],
      id: "id333333333",
      startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
      endDate: ""
    }
  ]

  const randomIdOne = "welcomeContentBoxIdOne"
  const randomIdTwo = "welcomeContentBoxIdTwo"
  const randomIdThree = "welcomeContentBoxIdThree"
  const randomIdFour = "welcomeContentBoxIdFour"

  const welcomeBox = {
    "type": "doc",
    "content": [
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "rgb(11, 192, 223)",
                                "fontSize": "32px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "🎉 Welcome to Lesson Launchpad! 🎉"
                }
            ]
        },
        {
            "type": "horizontalRule"
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "",
                                "fontSize": "14px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "My name is Michael and I'm a former band director turned Software Developer. As a teacher I always wanted a better solution to the \"agenda slide\". I wanted to be able to present more information, but in a neat un-cluttered way. I envisioned something that looks similar to what you see here. Lesson Launchpad already has quite a few features but I have a LOT more planned. I would love to hear your feedback so feel free to reach out. I'm excited to make the best possible version of Lesson Launchpad for you."
                }
            ]
        },
        {
            "type": "horizontalRule"
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "rgb(11, 192, 223)",
                                "fontSize": "24px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "Where to start?"
                }
            ]
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "",
                                "fontSize": "14px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "Why not here? You can click directly inside this Content Box to edit its contents. You'll see that there's a wide variety of formatting options so you can customize your content in whatever way you wish. Next, you'll notice two buttons below titled \"Agenda\" and \"Sectionals\". These are two Content Boxes that show how I might have used Lesson Launchpad in my classroom. There's also a sample Google Slides box. Yes, you can use your already created google slides presentations right here in Lesson Launchpad. You can have multiple Content Boxes and toggle them on and off as you wish by clicking in the center of the Content Box buttons below. Click \"Launch\" then \"Add Content Box\" to start with a fresh box."
                }
            ]
        },
        {
            "type": "horizontalRule"
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "bold"
                        },
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "rgb(11, 192, 223)",
                                "fontSize": "24px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "Let's create your first Class!"
                }
            ]
        },
        {
            "type": "paragraph",
            "attrs": {
                "textAlign": "center"
            },
            "content": [
                {
                    "type": "text",
                    "marks": [
                        {
                            "type": "textStyle",
                            "attrs": {
                                "color": "",
                                "fontSize": "14px",
                                "fontFamily": {
                                    "fontFamily": ""
                                },
                                "lineHeight": {
                                    "lineHeight": ""
                                }
                            }
                        }
                    ],
                    "text": "You can click the Add/Edit link in the left navigation bar to add your first class. You'll notice you can choose to have the marquee and clock hidden if you find them distracting or just have no use for them. You can also set a start time for each class. If it's time for a class to start Lesson Launchpad will automatically navigate to that class for you."
                }
            ]
        }
    ]
}

const multiBox = {
"heading": "MultiBox",
"endDate": "2099-01-01",
"active": true,
"startDate": "2024-01-01",
"show": false,
"contentBoxId": randomIdFour,
"content": [
    {
        "contentBoxId": "welcomeContentBoxIdFive",
        "content": {
          "type": "doc",
          "content": [
              {
                  "type": "paragraph",
                  "attrs": {
                      "textAlign": "left"
                  },
                  "content": [
                      {
                          "type": "text",
                          "marks": [
                              {
                                  "type": "textStyle",
                                  "attrs": {
                                      "color": "",
                                      "fontSize": "40px",
                                      "fontFamily": {
                                          "fontFamily": ""
                                      },
                                      "lineHeight": {
                                          "lineHeight": ""
                                      }
                                  }
                              }
                          ],
                          "text": "Sectionals:"
                      }
                  ]
              },
              {
                  "type": "table",
                  "content": [
                      {
                          "type": "tableRow",
                          "content": [
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                          108
                                      ]
                                  },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Flutes"
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                          125
                                      ]
                                  },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Inst. Storage"
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                        108
                                    ]                          },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Parker"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "type": "tableRow",
                          "content": [
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                          108
                                      ]
                                  },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Clarinets"
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                          125
                                      ]
                                  },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Ensemble 2"
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                        108
                                    ]                          },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Mikel"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      },
                      {
                          "type": "tableRow",
                          "content": [
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                          108
                                      ]
                                  },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Trumpets"
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                          125
                                      ]
                                  },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Ensemble 1"
                                              }
                                          ]
                                      }
                                  ]
                              },
                              {
                                  "type": "tableCell",
                                  "attrs": {
                                      "colspan": 1,
                                      "rowspan": 1,
                                      "colwidth": [
                                        108
                                    ]                          },
                                  "content": [
                                      {
                                          "type": "paragraph",
                                          "attrs": {
                                              "textAlign": "center"
                                          },
                                          "content": [
                                              {
                                                  "type": "text",
                                                  "marks": [
                                                      {
                                                          "type": "textStyle",
                                                          "attrs": {
                                                              "color": null,
                                                              "fontSize": "16px",
                                                              "fontFamily": null,
                                                              "lineHeight": null
                                                          }
                                                      }
                                                  ],
                                                  "text": "Whitworth"
                                              }
                                          ]
                                      }
                                  ]
                              }
                          ]
                      }
                  ]
              }
          ]
        },
        "textEditor": true,
        "show": true,
        "active": true
    },
    {
        "content": {
            "eventName": "the Concert",
            "eventDate": getDate30DaysInFuture(),
            "fontColor": "",
            "fontStyle": "helvetica"
        },
        "countdown": true,
        "active": true,
        "show": true,
        "contentBoxId": "welcomeContentBoxIdSix"
    }
],
"multiBox": true
}

  const classes = [
    {
      classId: "welcome1",
      period: "Hi",
      className: "Sample Class",
      startTime: null,
      endTime: null,
      showMarquee: true,
      showClock: true,
      active: true,
      schedule: Scheduling.blankSchedule,
      orderOfAnnouncements: ["id111111111", "id222222222", "id333333333"],
      contentBoxesOrder: [randomIdOne, randomIdTwo, randomIdThree, randomIdFour],
      contentBoxes: [
        {
          contentBoxId: randomIdOne,
          heading: "Welcome!",
          show: true,
          content: welcomeBox,
          userHeading: true,
          active: true,
          startDate: "2024-01-01",
          endDate: "2099-12-31"
        },
        {
          contentBoxId: randomIdTwo,
          heading: "Web Box",
          show: true,
          content: "https://www.youtube.com/embed/5CwzXPYAzQQ",
          userHeading: true,
          active: true,
          startDate: "2024-01-01",
          endDate: "2099-12-31",
          iFrame: true,
        },
        {
          contentBoxId: randomIdThree,
          agendaBox: true,
          heading: "Agenda",
          show: false,
          content: '<p><strong><span style="font-size: 36pt;">Agenda:</span></strong></p><ul><li style="font-size: 30pt;"><span style="font-size: 30pt;">Warm-up</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Tuning</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Announcements</span></li></ul><hr><p style="text-align: center;"><span style="font-size: 24pt;"><em>Sectionals Leave</em></span></p><hr><ul><li style="font-size: 30pt;"><span style="font-size: 30pt;">Holst</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Ticheli</span></li><li style="font-size: 30pt;"><span style="font-size: 30pt;">Sousa</span></li></ul>',
          userHeading: true,
          active: true,
          startDate: "2024-01-01",
          endDate: "2099-12-31",
          startTime: "00:00",
          steps: [
            {
                "subTaskSteps": [],
                "title": "Bell Work",
                "seconds": 0,
                "subTask": false,
                "stepId": "id8ffac00206301",
                "minutes": "5"
            },
            {
                "subTaskSteps": [],
                "seconds": 0,
                "subTask": false,
                "title": "Review",
                "stepId": "id39f71d5bd6eea",
                "minutes": "4"
            },
            {
                "subTaskSteps": [],
                "stepId": "id892dfa498112e",
                "resourceLink": "https://www.youtube.com/watch?v=ojI2XJVMcCg",
                "subTask": false,
                "minutes": "3",
                "title": "Video",
                "seconds": 0
            },
            {
                "subTaskSteps": [
                    {
                        "minutes": 5,
                        "title": "Question #1",
                        "seconds": 0,
                        "stepId": "id15a712462333a"
                    },
                    {
                        "seconds": 0,
                        "minutes": 5,
                        "stepId": "id75a96b1360918",
                        "title": "Question #2"
                    }
                ],
                "title": "Group Discussion",
                "seconds": 0,
                "minutes": 10,
                "stepId": "idcb4dc64fe562a",
                "subTask": false
            },
            {
                "seconds": 0,
                "subTaskSteps": [],
                "title": "Kahoot",
                "subTask": false,
                "stepId": "id2364dcc209838",
                "minutes": "12"
            },
            {
                "seconds": 0,
                "title": "Exit Ticket",
                "subTask": false,
                "stepId": "id161361fc741ed",
                "subTaskSteps": [],
                "minutes": "6"
            }
        ]
        },
        multiBox
      ]
    }
  ]

  const settings = { //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    showSeconds: true, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    logo: true, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    marqueeSpeed: '150', //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    alteredSchedule: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    multiWeek: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    numberOfWeeks: 1, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    currentWeek: "A", //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    differentTimes: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    useActiveDates: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    useShareCode: false, //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
    screenTimeout: '60' //ADD NEW PROPERTIES TO addSettingsObjectToUser() FUNCTION IN APP.JS
  }

  const convertEmailToLowercase = (email) => {
    if (email && typeof email === 'string') {
      return email.toLowerCase();
    }
    return email; // Return the email as-is if it's not a string
  };

  function getDate30DaysInFuture() {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 30);
  
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  const handleGoogleLogin = async (response) => {
    window.alert("Coming Soon! :)")
    // try {
    //   const decoded = jwtDecode(response.credential);
    //   const { email, given_name: firstName, family_name: lastName, sub: uid } = decoded;
  
    //   // Check if user already exists
    //   const userDoc = await getDoc(doc(db, "users", uid));
    //   if (!userDoc.exists()) {
    //     // Create a new user document
    //     await setDoc(doc(db, "users", uid), {
    //       firstName,
    //       lastName,
    //       email: convertEmailToLowercase(email),
    //       accountCreated: serverTimestamp(),
    //       premium: false,
    //       trial: true,
    //       shareCode: createShareCode(),
    //       uid,
    //       leadData: {
    //         leadSource: leadSource || "Google Sign-In",
    //         mobile: navigator?.userAgentData?.mobile === false ? false : navigator?.userAgentData?.mobile === true ? true : ""
    //       },
    //       announcements: announcements,
    //       settings: settings,
    //       classes: classes
    //     });
    //     console.log("New user created:", email);
    //   } else {
    //     console.log("User already exists:", email);
    //   }
  
    //   // Redirect or update UI after successful login
    //   history.push("/dashboard");
    // } catch (error) {
    //   console.error("Google login failed:", error);
    //   alert("Google login failed");
    // }
  };

  // const register = async (e) => {
  //   e.preventDefault()
  //   if (isValidated()) {
  //     await createUserWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //       ).then((authUser) => {
  //         setDoc(doc(db, "users", authUser.user.uid), {
  //           firstName: firstName,
  //           lastName: lastName,
  //           email: convertEmailToLowercase(email),
  //           accountCreated: accountCreated,
  //           contentBoxTemplates: [],
  //           premium: false,
  //           trial: true,
  //           shareCode: createShareCode(),
  //           uid: authUser.user.uid, // Added this on 9/8/24 - Not sure why it was left out and will have to test if there was a reason.
  //           leadData: {
  //               leadSource: leadSource,
  //               mobile: navigator?.userAgentData?.mobile === false ? false : navigator?.userAgentData?.mobile === true ? true : ""
  //             },
  //           announcements: announcements,
  //           settings: settings,
  //           classes: classes
  //         })
  //         setTimeout(() => {
  //             history.push("/")
  //         }, 1000);
  //       }).catch((error) => {
  //         console.log(error)
  //         alert("Registration Failed")
  //       });
  //   } else {
  //     setShowValidation(true)
  //   }
  // }

  // const register = async (e) => {
  //   e.preventDefault();
  //   if (!isValidated()) {
  //     setShowValidation(true);
  //     return;
  //   }
  
  //   try {
  //     const authUser = await createUserWithEmailAndPassword(auth, email, password);
  
  //     // User document reference
  //     const userRef = doc(db, "usersNew", authUser.user.uid);
  
  //     // Create user document (without classes directly in it)
  //     await setDoc(userRef, {
  //       firstName,
  //       lastName,
  //       email: convertEmailToLowercase(email),
  //       accountCreated: accountCreated,
  //       premium: false,
  //       trial: true,
  //       shareCode: createShareCode(),
  //       uid: authUser.user.uid,
  //       leadData: {
  //         leadSource,
  //         mobile: navigator?.userAgentData?.mobile ?? ""
  //       },
  //       announcements,
  //       orderOfClasses: ["welcome1"],
  //       settings
  //     });
  
  //     // Store classes in a sub-collection
  //     for (const classData of classes) {
  //       const { contentBoxes, ...classWithoutBoxes } = classData; // Remove contentBoxes
  //       const classRef = doc(db, `usersNew/${authUser.user.uid}/classes`, classData.classId);
  //       await setDoc(classRef, classWithoutBoxes);
  
  //       // Store contentBoxes inside each class as sub-documents
  //       if (contentBoxes?.length) {
  //         for (const box of contentBoxes) {
  //           const contentBoxRef = doc(
  //             db,
  //             `usersNew/${authUser.user.uid}/classes/${classData.classId}/contentBoxes`,
  //             box.contentBoxId
  //           );
  //           await setDoc(contentBoxRef, box);
  //         }
  //       }
  //     }
  
  //     setTimeout(() => {
  //       history.push("/");
  //     }, 1000);
  //   } catch (error) {
  //     console.error("Registration failed:", error.code, error.message);
  //     alert(`Registration Failed: ${error.message}`);
  //   }
  // };

  const register = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      setShowValidation(true);
      return;
    }
  
    try {
      const authUser = await createUserWithEmailAndPassword(auth, email, password);
      const userId = authUser.user.uid;
  
      // Reference to the user document
      const userRef = doc(db, "usersNew", userId);
  
      // Extract class IDs for orderOfClasses
      const orderOfClasses = classes.map(cls => cls.classId);
  
      // Create user document (without classes directly in it)
      await setDoc(userRef, {
        firstName,
        lastName,
        email: convertEmailToLowercase(email),
        accountCreated,
        premium: false,
        trial: true,
        shareCode: createShareCode(),
        uid: userId,
        leadData: {
          leadSource,
          mobile: navigator?.userAgentData?.mobile ?? ""
        },
        announcements,
        orderOfClasses,  // ✅ Store class order
        settings
      });
  
      // Store each class and its content boxes
      for (const classData of classes) {
        const { contentBoxes, classId, ...classWithoutBoxes } = classData;

        // Extract ordered contentBox IDs
        const contentBoxesOrder = contentBoxes.map(box => box.contentBoxId);

        // Reference to class document
        const classRef = doc(db, `usersNew/${userId}/classes`, classId);

        // ✅ Fix: Ensure classId is included
        await setDoc(classRef, { 
          classId,  // 🔹 Explicitly store classId
          ...classWithoutBoxes, 
          contentBoxesOrder 
        });

        // Store each content box in its sub-collection
        if (contentBoxes?.length) {
          for (const box of contentBoxes) {
            const contentBoxRef = doc(db, `usersNew/${userId}/classes/${classId}/contentBoxes`, box.contentBoxId);
            await setDoc(contentBoxRef, box);
          }
        }
      }
  
    // ✅ Wait for Firestore to confirm user data exists before navigating
    await waitForUserData(userId);

    // ✅ Navigate after confirming data exists
    history.push("/");
  } catch (error) {
    console.error("❌ Registration failed:", error.code, error.message);
    alert(`Registration Failed: ${error.message}`);
  }
};

const waitForUserData = async (userId, maxRetries = 10, delay = 500) => {
  console.log("Waiting for new user in database...")
  const userRef = doc(db, "usersNew", userId);

  for (let i = 0; i < maxRetries; i++) {
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      console.log("✅ User data confirmed in Firestore.");
      return;
    }
    console.log("⏳ Waiting for user data to be available...");
    await new Promise((resolve) => setTimeout(resolve, delay));
  }

  console.warn("⚠️ Warning: User data was not found within the expected time.");
};

  

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      register(e); // Trigger the button click event
    }
  };

  useEffect(() => {
    /* global google*/
    google.accounts.id.initialize({
      client_id: '403855687421-r7nn7ldgheku9sds0pu0crd60i27vamq.apps.googleusercontent.com', //403855687421-r7nn7ldgheku9sds0pu0crd60i27vamq.apps.googleusercontent.com
      callback: handleGoogleLogin
    })

    google.accounts.id.renderButton(
      document.getElementById("googleRegister"),
      { theme: "outline", size: "large", shape: "pill", text: "continue_with"}
    );
  }, [])

  function isEmailValid(email) {
    // Regular expression pattern for a valid email address
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  
    // Test the email against the pattern
    return emailPattern.test(email);
  }

  const isValidated = () => {
    if (firstName && lastName && email && password) {
      if (isEmailValid(email) && password.length >= 8 && firstName.length > 0 && firstName.length <= 30 && lastName.length > 0 && lastName.length <= 30) {
        return true;
      }
    } else {
      return false;
    }
  }

  const isMobile = /Mobi/.test(navigator.userAgent);

  return (
    <>
    { !isMobile ? (
    <div className="register-page">
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="10" >
            <Card className="card-signup text-center" style={{width: '375px'}}>
              <CardHeader style={{minHeight: '20px'}}>
                <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "10px 0px" }}>
                  <div>
                  Let's get started!
                  </div>
                  {/* <div>
                  Lesson Launchpad!
                  </div>                   */}
                </h2>
              </CardHeader>
              <div
                style={{
                  fontWeight: '1000',
                  fontSize: '14px',
                  marginBottom: '20px'
                }}
              >
                Start your <span style={{
                    textDecoration: 'underline',
                    textDecorationColor: 'rgb(11, 192, 223)', /* Custom color */
                    textDecorationThickness: '3px', /* Thicker underline */
                    textUnderlineOffset: '2px' /* Adjust distance from text */
                  }}>30-DAY FREE</span> trial now
              </div>
              {/* <div id="googleRegister" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px'}}>
              </div> */}
              <CardBody>
                <div style={{marginBottom: '20px', color: '#004aad'}}>
                  <a style={{color: '#004aad'}} href="https://lessonlaunchpad.com/auth/login">Already registered? Click here to sign in.</a>
                </div>
                <Form action="" className="form" method="">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{borderRadius: '8px 0px 0px 8px'}}>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name..."
                      type="text"
                      required
                      valid={showValidation && firstName?.length > 0 && firstName?.length <= 30}
                      invalid={showValidation && firstName?.length === undefined || firstName?.length > 30}
                      style={{borderRadius: '0px 8px 8px 0px'}}
                    />
                      {showValidation && firstName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a First Name.</FormFeedback>)
                      : showValidation && firstName?.length > 30 ?
                        (<FormFeedback invalid>First Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{borderRadius: '8px 0px 0px 8px'}}>
                        <i className="nc-icon nc-circle-10" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name..."
                      type="text"
                      required
                      valid={showValidation && lastName?.length > 0 && lastName?.length <= 30}
                      invalid={showValidation && lastName?.length === undefined || lastName?.length > 30}
                      style={{borderRadius: '0px 8px 8px 0px'}}
                    />
                      {showValidation && lastName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a Last Name.</FormFeedback>)
                      : showValidation && lastName?.length > 30 ?
                        (<FormFeedback invalid>Last Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{borderRadius: '8px 0px 0px 8px'}}>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email..."
                      type="email"
                      required
                      valid={showValidation && email && isEmailValid(email)}
                      invalid={showValidation && !isEmailValid(email)}
                      style={{borderRadius: '0px 8px 8px 0px'}}
                    />
                     {showValidation && email && isEmailValid(email) ?
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      : 
                        (<FormFeedback invalid>Email Invalid</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText style={{borderRadius: '8px 0px 0px 8px'}}>
                        <i className="nc-icon nc-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password..."
                      type={showPassword ? "text" : "password"} // Toggle password visibility
                      onKeyPress={handleEnterKeyPress}
                      required
                      valid={showValidation && password?.length >= 8}
                      invalid={showValidation && password?.length < 8 || showValidation && password === undefined}
                      style={{ borderRadius: '0px', borderRight: '0px' }}
                    />
                      {showValidation && password?.length < 8 || password === undefined ?
                        (<FormFeedback invalid>Password must be atleast 8 characters.</FormFeedback>)
                      :
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                    <InputGroupAddon addonType="append">
                      <InputGroupText
                        style={{ cursor: "pointer", borderRadius: '0px 8px 8px 0px', paddingRight: '10px' }}
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i className={`fa ${showPassword ? "fa-eye" : "fa-eye-slash"}`} />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <hr></hr>
                  Where did you hear about Lesson Launchpad?
                  <InputGroup>
                    <Input 
                      onChange={(e) => setLeadSource(e.target.value)}
                      type="select"
                      onKeyPress={handleEnterKeyPress}
                      style={{border: '1px solid #DDDDDD', borderRadius: '8px'}}
                    >
                      <option defaultChecked={true}></option>
                      <option>Colleague/Friend</option>
                      <option>Convention Booth</option>
                      <option>Email</option>
                      <option>Facebook Ad</option>
                      <option>Facebook Group</option>
                      <option>Flyer</option>
                      <option>Google Ad</option>
                      <option>Google Search</option>
                      <option>Instagram Ad</option>
                      <option>Pinterest Ad</option>
                      <option>Other</option>
                    </Input>
                  </InputGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="button-fixed"
                  color="info"
                  onClick={(e) => register(e)}
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}
                >
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
    ) : (
      <div className="register-page">
        <Row>
          <Col className="ml-auto mr-auto">
            <Card style={{borderRadius: '60px'}}>
              <CardHeader>
                <h3 style={{ fontWeight: "bold", color: "#004aad", margin: "0px", fontSize: '110px' }}>
                  <div>
                  Welcome to
                  </div>
                  <div>
                  Lesson Launchpad!
                  </div>                  
                </h3>              
              </CardHeader>
              {/* <div id="googleRegister" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px', zoom: '4'}}></div> */}
              <CardBody style={{padding: '0px 60px'}}>
                <Form action="" className="form" method="">
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend" className="mobile-view-input-addon">
                      <InputGroupText style={{border: 'none'}}>
                        <i className="nc-icon nc-single-02" style={{ fontSize: '60px', borderRadius: '24px 0px 0px 24px', marginLeft: '10px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="First Name..."
                      type="text"
                      required
                      valid={showValidation && firstName?.length > 0 && firstName?.length <= 30}
                      invalid={showValidation && firstName?.length === undefined || firstName?.length > 30}
                      style={{ height: '130px', fontSize: '60px', border: 'solid', borderLeft: '0', borderRadius: '0px 24px 24px 0px'}}
                    />
                      {showValidation && firstName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a First Name.</FormFeedback>)
                      : showValidation && firstName?.length > 30 ?
                        (<FormFeedback invalid>First Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend" className="mobile-view-input-addon">
                      <InputGroupText>
                        <i className="nc-icon nc-circle-10" style={{ fontSize: '60px', borderRadius: '24px 0px 0px 24px', marginLeft: '10px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Last Name..."
                      type="text"
                      required
                      valid={showValidation && lastName?.length > 0 && lastName?.length <= 30}
                      invalid={showValidation && lastName?.length === undefined || lastName?.length > 30}
                      style={{ height: '130px', fontSize: '60px', border: 'solid', borderLeft: '0', borderRadius: '0px 24px 24px 0px'}}
                    />
                      {showValidation && lastName?.length === undefined ?
                        (<FormFeedback invalid>Please enter a Last Name.</FormFeedback>)
                      : showValidation && lastName?.length > 30 ?
                        (<FormFeedback invalid>Last Name must be less than 30 characters.</FormFeedback>)
                      : (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend" className="mobile-view-input-addon">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" style={{ fontSize: '60px', borderRadius: '24px 0px 0px 24px', marginLeft: '10px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Email..."
                      type="email"
                      required
                      valid={showValidation && email && isEmailValid(email)}
                      invalid={showValidation && !isEmailValid(email)}
                      style={{ height: '130px', fontSize: '60px', border: 'solid', borderLeft: '0', borderRadius: '0px 24px 24px 0px'}}
                    />
                     {showValidation && email && isEmailValid(email) ?
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      : 
                        (<FormFeedback invalid>Email Invalid</FormFeedback>)
                      }
                  </InputGroup>
                  <InputGroup style={{marginTop: '60px'}}>
                    <InputGroupAddon addonType="prepend" className="mobile-view-input-addon">
                      <InputGroupText>
                        <i className="nc-icon nc-key-25" style={{ fontSize: '60px', borderRadius: '24px 0px 0px 24px', marginLeft: '10px'}}/>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input 
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password..."
                      type="password"
                      onKeyPress={handleEnterKeyPress}
                      required
                      valid={showValidation && password?.length >= 8}
                      invalid={showValidation && password?.length < 8 || showValidation && password === undefined}
                      style={{ height: '130px', fontSize: '60px', border: 'solid', borderLeft: '0', borderRadius: '0px 24px 24px 0px'}}
                    />
                      {showValidation && password?.length < 8 || password === undefined ?
                        (<FormFeedback invalid>Password must be atleast 8 characters.</FormFeedback>)
                      :
                        (<FormFeedback valid>Looks good!</FormFeedback>)
                      }
                  </InputGroup>
                  <hr style={{marginTop: '60px', marginBottom: '40px'}}></hr>
                  <h1>Where did you hear about Lesson Launchpad?</h1>
                  <InputGroup>
                    <Input 
                      onChange={(e) => setLeadSource(e.target.value)}
                      type="select"
                      onKeyPress={handleEnterKeyPress}
                      style={{ height: '130px', fontSize: '30px', border: '1px solid #DDDDDD', border: 'solid', borderRadius: '24px'}}
                    >
                      <option defaultChecked={true}></option>
                      <option>Colleague/Friend</option>
                      <option>Convention Booth</option>
                      <option>Email</option>
                      <option>Facebook Ad</option>
                      <option>Facebook Group</option>
                      <option>Flyer</option>
                      <option>Google Ad</option>
                      <option>Google Search</option>
                      <option>Instagram Ad</option>
                      <option>Pinterest Ad</option>
                      <option>Other</option>
                    </Input>
                  </InputGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  onClick={(e) => register(e)}
                  style={{height: '150px', fontSize: '90px', borderRadius: '80px', margin: '50px', padding: '0px 50px'}}
                >
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
    </div>
    )}
    </>
  );
}

export default Register;

import React, {useState, useEffect, useRef} from 'react'
import { selectUser, selectClasses, fetchUserData } from "features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { Table, Input, Button, Modal, ModalBody, ModalFooter, FormGroup, Label, Row, Col, ModalHeader, Badge } from "reactstrap";
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";
import ReactBSAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Papa from "papaparse";   // CSV Parsing
import * as XLSX from "xlsx/dist/xlsx.full.min.js";
import { convertCompilerOptionsFromJson } from 'typescript';

function RosterTable({classId}) {

    const initialRosterData = {
        roster:  [
            {name: "", descriptor1: "", descriptor2: "", birthday: ""}
        ],
        descriptors: {
            descriptor1: "Descriptor 1",
            descriptor2: "Descriptor 2",
        }
    }

    const user = useSelector(selectUser);
    const classes = useSelector(selectClasses)
    const fileInputRef = useRef(null);
    const dispatch = useDispatch()
    const classIndex = classes?.findIndex((c) => c.classId === classId);
    const [editedRosterData, setEditedRosterData] = useState(classes[classIndex]?.rosterData || initialRosterData);
    const [copyRosterToClassModal, setCopyRosterToClassModal] = useState(false);
    const [classesSelectedForRosterCopy, setClassesSelectedForRosterCopy] = useState([])
    const [rosterUploadModal, setRosterUploadModal] = useState(false);
    const toggleRosterUploadModal = () => {
        setRosterUploadModal(!rosterUploadModal)
    }
    const toggleCopyRosterToClassModal = () => {
        setCopyRosterToClassModal(!copyRosterToClassModal);
        setClassesSelectedForRosterCopy([])
    }

    const handleUploadRosterButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleCheckboxChangeForRosterCopy = (classId) => {
        setClassesSelectedForRosterCopy(prev => {
          if (prev.includes(classId)) {
            // If classId is already selected, remove it
            return prev.filter(id => id !== classId);
          } else {
            // If classId is not selected, add it
            return [...prev, classId];
          }
        });
    };

    // const copyRosterToClasses = async () => {
    //     try {
    //       const docRef = doc(db, "users", user.uid);
      
    //       // Make a deep copy of the user object
    //       const userCopy = JSON.parse(JSON.stringify(user));
      
    //       // Find the index of the class that has the roster to be copied
    //       const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
      
    //       if (classIndex === -1) {
    //         console.log(`Class with ID ${classId} not found.`);
    //         return;
    //       }
      
    //       // Get the roster to copy
    //       let rosterToCopy = userCopy.classes[classIndex].rosterData.roster;
      
    //       // Copy the roster to the selected classes
    //       let classesCopy = [...userCopy.classes];
      
    //       classesSelectedForRosterCopy.forEach((classId) => {
    //         const targetClassIndex = classesCopy.findIndex((c) => c.classId === classId);
      
    //         if (targetClassIndex !== -1) {
    //             if (classesCopy[targetClassIndex].rosterData === undefined) { //Some classes won't have rosterData Object if their roster hasn't been edited yet.
    //                 // Initialize the rosterData object and roster array
    //                 classesCopy[targetClassIndex].rosterData = {
    //                     roster: [...rosterToCopy].filter(
    //                     (rosterEntry) =>
    //                         rosterEntry.name !== "" ||
    //                         rosterEntry.descriptor1 !== "" ||
    //                         rosterEntry.descriptor2 !== "" ||
    //                         rosterEntry.birthday !== ""
    //                     ),
    //                     descriptors: {
    //                         descriptor1: "Descriptor 1",
    //                         descriptor2: "Descriptor 2",
    //                     }
    //                 };
    //             } else {
    //                 // Append the roster to the target class without overwriting
    //                 classesCopy[targetClassIndex].rosterData.roster = [
    //                     ...classesCopy[targetClassIndex].rosterData.roster, 
    //                     ...rosterToCopy
    //                 ].filter(
    //                     rosterEntry => rosterEntry.name !== "" || 
    //                                 rosterEntry.descriptor1 !== "" || 
    //                                 rosterEntry.descriptor2 !== "" || 
    //                                 rosterEntry.birthday !== ""
    //                 );
    //             }
    //         } else {
    //           console.log(`Class with ID ${classId} not found.`);
    //         }
    //       });
      
    //       // Update the user's classes with the modified data
    //       userCopy.classes = classesCopy;
      
    //       // Update the Firestore document with the modified user data
    //       await setDoc(docRef, userCopy);
      
    //       // Optionally close the modal
    //       toggleCopyRosterToClassModal();
      
    //     } catch (error) {
    //       console.log("Failed to copy roster", error);
    //     }
    // };

    const [alert, setAlert] = useState(null);
  
    const successAlert = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
          title="Roster Saved!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info button-hover-fix"
          confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
          btnSize=""
        >
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
        setAlert(null);
    };

    const copyRosterToClasses = async () => {
        try {
            if (!user?.uid || !classId || !classesSelectedForRosterCopy.length) {
                console.error("🚨 Missing required parameters: User ID, Source Class ID, or Target Classes.");
                return;
            }
    
            // ✅ Reference the source class document
            const sourceClassRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
            const sourceClassSnap = await getDoc(sourceClassRef);
    
            if (!sourceClassSnap.exists()) {
                console.error(`❌ Source class with ID '${classId}' not found.`);
                return;
            }
    
            const sourceClassData = sourceClassSnap.data();
    
            if (!sourceClassData?.rosterData?.roster) {
                console.error(`❌ No roster data found for class ID '${classId}'.`);
                return;
            }
    
            // ✅ Filter out empty roster entries
            const rosterToCopy = sourceClassData.rosterData.roster.filter(
                (entry) => entry.name || entry.descriptor1 || entry.descriptor2 || entry.birthday
            );
    
            if (rosterToCopy.length === 0) {
                console.warn("⚠️ No valid roster entries to copy.");
                return;
            }
    
            // ✅ Process each selected target class
            await Promise.all(classesSelectedForRosterCopy.map(async (targetClassId) => {
                const targetClassRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, targetClassId);
                const targetClassSnap = await getDoc(targetClassRef);
    
                if (!targetClassSnap.exists()) {
                    console.error(`❌ Target class with ID '${targetClassId}' not found.`);
                    return;
                }
    
                const targetClassData = targetClassSnap.data();
    
                // ✅ Merge the rosters while ensuring no duplicate blank entries
                const updatedRoster = [
                    ...(targetClassData?.rosterData?.roster || []),
                    ...rosterToCopy,
                ].filter(entry => entry.name || entry.descriptor1 || entry.descriptor2 || entry.birthday);
    
                // ✅ Prepare Firestore update
                const updates = {
                    rosterData: {
                        roster: updatedRoster,
                        descriptors: targetClassData?.rosterData?.descriptors || {
                            descriptor1: "Descriptor 1",
                            descriptor2: "Descriptor 2",
                        },
                    },
                };
    
                // ✅ Update the target class document
                await updateDoc(targetClassRef, updates);
                console.log(`✅ Roster copied successfully to class '${targetClassId}'.`);
            }));

            // 🛑 Manually re-fetch user data after updating Firestore
            await dispatch(fetchUserData(user.uid));
    
            // ✅ Close modal after completion
            toggleCopyRosterToClassModal();
            console.log("🎉 Rosters copied successfully!");
        } catch (error) {
            console.error("❌ Failed to copy roster:", error);
        }
    };

    // const saveRoster = async () => {
    //     try {
    //         const docRef = doc(db, "users", user.uid);
    
    //         const userCopy = JSON.parse(JSON.stringify(user));
    
    //         const classIndex = userCopy.classes.findIndex(
    //         (c) => c.classId === classId
    //         );
        
    //         userCopy.classes[classIndex].rosterData = editedRosterData;

    //         await setDoc(docRef, userCopy);

    //         setEditMode(false);
    //     } catch (error) {
    //         alert('Error updating document:', error);
    //     }
    // }

    const saveRoster = async () => {
        try {
            if (!user?.uid || !classId || !editedRosterData) {
                console.error("❌ Missing required parameters: User ID, Class ID, or editedRosterData.");
                return;
            }
    
            // Reference the class document in Firestore
            const classRef = doc(
                db,
                FIREBASE_COLLECTIONS.USERS,
                user.uid,
                FIREBASE_COLLECTIONS.CLASSES,
                classId
            );
    
            // Update Firestore document with the new roster data
            await updateDoc(classRef, {
                rosterData: editedRosterData,
            });
    
            // 🛑 Manually re-fetch user data after updating Firestore
            await dispatch(fetchUserData(user.uid));
    
            console.log("✅ Roster updated successfully!");
            successAlert()
        } catch (error) {
            console.error("❌ Error updating roster:", error);
            window.alert("❌ Error updating roster: " + error.message);
        }
    };
    


    const handleRosterDataEdit = (e, index, field) => {
        const updatedData = { ...editedRosterData };
        updatedData.roster = [...updatedData.roster];
        updatedData.roster[index] = { ...updatedData.roster[index], [field]: e.target.value };
        setEditedRosterData(updatedData);
    };

    const handleRosterRowDelete = (index) => {
        const updatedData = { ...editedRosterData };
    
        // Create a new array by copying the existing roster array
        updatedData.roster = [...updatedData.roster];
    
        // Remove the item at the specified index
        updatedData.roster.splice(index, 1);
    
        // Update the state with the new roster array
        setEditedRosterData(updatedData);
    };

    const handleDescriptorChange = (e, field) => {
        const updatedData = { ...editedRosterData };
        updatedData.descriptors = { ...updatedData.descriptors };
        updatedData.descriptors[field] = e.target.value;
        setEditedRosterData(updatedData);
    }
    
    const handleAddRow = () => {
    const blankRow = { name: "", descriptor1: "", descriptor2: "", birthday: "" };
    setEditedRosterData((prev) => ({ ...prev, roster: [...prev.roster, blankRow] }));
    };

    const [editMode, setEditMode] = useState(true)
    const [editDescriptorMode, setEditDescriptorMode] = useState({descriptor1: false, descriptor2: false})

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();

        // Determine file type
        if (file.name.endsWith(".csv")) {
            reader.onload = (e) => parseCSV(e.target.result);
            reader.readAsText(file);
        } else if (file.name.endsWith(".xlsx")) {
            reader.onload = (e) => parseExcel(e.target.result);
            reader.readAsArrayBuffer(file);
        } else {
            alert("❌ Unsupported file type. Please upload a .csv or .xlsx file.");
        }
        toggleRosterUploadModal()
    };

    // 🟢 Parse CSV File
    const parseCSV = (data) => {
        Papa.parse(data, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                const parsedRoster = formatRosterData(results.data, false); // Not an Excel file
                setEditedRosterData((prev) => ({
                    ...prev,
                    roster: [...prev.roster, ...parsedRoster],
                }));
            },
        });
    };

    // 🟢 Parse Excel File
    const parseExcel = (data) => {
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Get first sheet
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet); // Convert sheet to JSON

        console.log("parsedData => ", parsedData);

        const parsedRoster = formatRosterData(parsedData, true); // Excel file
        setEditedRosterData((prev) => ({
            ...prev,
            roster: [...parsedRoster],
        }));
    };


    // 🟢 Format Data to Match Roster Structure
    const formatRosterData = (data, isExcel = false) => {
        return data.map(row => ({
            name: row["Name"] || "",
            descriptor1: row["Descriptor 1"] || "",
            descriptor2: row["Descriptor 2"] || "",
            birthday: isExcel ? parseExcelDate(row["Birthday"]) : parseDate(row["Birthday"]),
        }));
    };
    

    // 🟢 Parse and Normalize Dates
    const parseDate = (dateValue) => {
        if (!dateValue) return "";
    
        // 🟢 Handle Excel date serial numbers
        if (!isNaN(dateValue) && Number(dateValue) > 10000) {
            return moment(XLSX.SSF.parse_date_code(Number(dateValue))).format("YYYY-MM-DD");
        }
    
        // 🔹 Common date formats to recognize
        const formats = [
            "M/D/YYYY", "MM/DD/YYYY", "D/M/YYYY", "DD/MM/YYYY", // US & International formats
            "YYYY-MM-DD", "YYYY/MM/DD",                          // Standard formats
            "MMMM D, YYYY", "MMM D, YYYY",                       // Long-form (e.g., March 5, 2000)
            "MM-DD-YYYY", "M-D-YYYY",                            // Hyphenated versions
            "MM-DD-YY", "M-D-YY",                                // 2-digit year formats
        ];
    
        // 🔹 Attempt to parse using known formats
        let parsedDate = moment(dateValue, formats, true);
    
        // 🔹 Handle 2-digit year fallback (assuming 19xx or 20xx)
        if (!parsedDate.isValid() && dateValue.match(/^\d{1,2}-\d{1,2}-\d{2}$/)) {
            parsedDate = moment(dateValue, ["MM-DD-YY", "M-D-YY"], true).set("year", (y) => (y > 50 ? 1900 + y : 2000 + y));
        }
    
        return parsedDate.isValid() ? parsedDate.format("YYYY-MM-DD") : "";
    };

    const parseExcelDate = (excelSerial) => {
        if (!excelSerial || isNaN(excelSerial)) return "";
    
        const excelEpoch = moment("1899-12-30"); // Excel's base date (day 1 = 1900-01-01)
        return excelEpoch.add(Number(excelSerial), "days").format("YYYY-MM-DD");
    };
    
    

    return (
        <>
            {alert}
            <Row style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <h3 style={{ fontWeight: "bold", color: "#004aad", margin: "0px"}}>
                        Edit Roster for {classes[classIndex]?.className}
                    </h3>
            </Row>
            <Row style={{ padding: "10px" }}>
                <Col md="12" style={{ textAlign: "right" }}>
                    <div onClick={toggleCopyRosterToClassModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>Copy Roster to another class?</div>
                </Col>
            </Row>
            <div className="playlist-table" style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd", borderRadius: "8px" }}>
                <Table style={{ marginBottom: '0px' }}>
                    <thead className="text-primary" style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: 1 }}>
                        <tr>
                            <th style={{ width: '5%' }}>#</th>
                            <th style={{ width: '25%' }}>Name</th>
                            <th style={{ width: '25%' }}>Descriptor 1</th>
                            <th style={{ width: '25%' }}>Descriptor 2</th>
                            <th style={{ width: '20%' }}>Birthday</th>
                            <th style={{ width: '5%' }}>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {editedRosterData?.roster?.map((data, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                    <Input
                                        type='text'
                                        name='name'
                                        value={data.name}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'name')}
                                        disabled={!editMode}
                                        style={{ borderRadius: '8px' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type='text'
                                        name='descriptor1'
                                        value={data.descriptor1}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'descriptor1')}
                                        disabled={!editMode}
                                        style={{ borderRadius: '8px' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type='text'
                                        name='descriptor2'
                                        value={data.descriptor2}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'descriptor2')}
                                        disabled={!editMode}
                                        style={{ borderRadius: '8px' }}
                                    />
                                </td>
                                <td>
                                    <Input
                                        type='date'
                                        name='birthday'
                                        value={data.birthday}
                                        onChange={(e) => handleRosterDataEdit(e, index, 'birthday')}
                                        disabled={!editMode}
                                        style={{ borderRadius: '8px' }}
                                    />
                                </td>
                                <td className="text-center">
                                    {editMode ? (
                                        <Button className="btn-icon btn-neutral" color="danger" size="md" onClick={() => handleRosterRowDelete(index)}>
                                            <i className="fa fa-trash icon-delete" />
                                        </Button>
                                    ) : null}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            <Row className="text-center" style={{ marginTop: '0px' }}>
                <Col className="add-row-to-playlist-col" onClick={handleAddRow}>
                    <h6>Add Row</h6>
                </Col>
            </Row>
            <Row>
                <Col style={{ textAlign: 'left' }}>
                    <Button
                        onClick={toggleRosterUploadModal}  // 👈 Triggers the hidden file input
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                        style={{
                            borderRadius: '30px',
                            backgroundColor: '#28C76F',
                            width: '300px'
                        }}
                    >
                        <i className='fa fa-upload' />
                        <span>{' '}Upload Roster File <Badge>Beta</Badge></span>
                    </Button>

                    {/* Hidden file input */}
                    <Input
                        type="file"
                        accept=".csv, .xlsx"
                        onChange={handleFileUpload}
                        innerRef={fileInputRef}  // 👈 Reference to trigger input click
                        style={{ display: "none" }}  // 👈 Hide it
                    />
                </Col>
                <Col style={{ textAlign: 'right'}}>
                    <Button
                        className='button-hover-fix'
                        onClick={() => saveRoster()}
                        style={{
                            borderRadius: '30px',
                            width: '100px'
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <i className='fa fa-save' />
                        {' '}Save
                    </Button>
                </Col>
            </Row>
        <Modal isOpen={rosterUploadModal} toggle={toggleRosterUploadModal} centered size="lg" scrollable>
            <ModalHeader toggle={toggleRosterUploadModal} style={{ fontSize: "18px", fontWeight: "bold" }}>
                <p style={{ fontWeight: "bold", color: "#004aad", margin: "0px"}}>
                📄 Roster File Upload Guide
                </p>
            </ModalHeader>
            <ModalBody>
                <p>
                    To upload your class roster, please use a <strong>.CSV</strong> or <strong>.XLSX</strong> file with the following <strong>columns</strong>:
                </p>
                <p>
                    The optional columns can be completely omitted from your file or left empty.
                </p>

                <Table bordered>
                    <thead>
                        <tr style={{ textAlign: "center", border: "1px solid #dee2e6" }}>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                Name <br />
                                <span style={{ fontSize: "12px", fontWeight: "normal", color: "#6c757d" }}>(Required)</span>
                            </th>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                Descriptor 1 <br />
                                <span style={{ fontSize: "12px", fontWeight: "normal", color: "#6c757d" }}>(Optional)</span>
                            </th>
                            <th style={{ borderRight: "1px solid #dee2e6" }}>
                                Descriptor 2 <br />
                                <span style={{ fontSize: "12px", fontWeight: "normal", color: "#6c757d" }}>(Optional)</span>
                            </th>
                            <th>
                                Birthday <br />
                                <span style={{ fontSize: "12px", fontWeight: "normal", color: "#6c757d" }}>(Optional)</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>John</td>
                            <td>Red Team</td>
                            <td>Table 3</td>
                            <td>03/05/2000</td>
                        </tr>
                        <tr>
                            <td>Jane S.</td>
                            <td>Group B</td>
                            <td>Soprano</td>
                            <td>March 5, 2000</td>
                        </tr>
                        <tr>
                            <td>Emily Johnson</td>
                            <td></td>
                            <td>Flute</td>
                            <td>5-3-00</td>
                        </tr>
                    </tbody>
                </Table>
                <a href="https://docs.google.com/spreadsheets/d/1OLsi9ZL_fabVcIekOtIGm-pc7EYdyuecmjXO1mq5TNk/edit?usp=sharing" target="_blank" rel="noopener noreferrer">
                    <em>Or use this template</em>
                </a>

                <h5 className="mt-3">🎂 Accepted Birthday Formats</h5>
                <p>
                    If you include a <strong>Birthday</strong> column, please ensure dates are in one of these formats:
                </p>

                <Table bordered>
                    <thead>
                        <tr style={{ backgroundColor: "#f8f9fa" }}>
                            <th>Format</th>
                            <th>Example</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><code>M/D/YYYY</code> or <code>MM/DD/YYYY</code></td>
                            <td>3/5/2000 or 03/05/2000</td>
                        </tr>
                        <tr>
                            <td><code>D/M/YYYY</code> or <code>DD/MM/YYYY</code></td>
                            <td>5/3/2000 or 05/03/2000</td>
                        </tr>
                        <tr>
                            <td><code>YYYY-MM-DD</code></td>
                            <td>2000-03-05</td>
                        </tr>
                        <tr>
                            <td><code>YYYY/MM/DD</code></td>
                            <td>2000/03/05</td>
                        </tr>
                        <tr>
                            <td><code>MMMM D, YYYY</code></td>
                            <td>March 5, 2000</td>
                        </tr>
                        <tr>
                            <td><code>MMM D, YYYY</code></td>
                            <td>Mar 5, 2000</td>
                        </tr>
                        <tr>
                            <td><code>MM-DD-YYYY</code></td>
                            <td>03-05-2000</td>
                        </tr>
                        <tr>
                            <td><code>M-D-YY</code> or <code>MM-DD-YY</code></td>
                            <td>3-5-00 or 03-05-00</td>
                        </tr>
                    </tbody>
                </Table>

                <p className="mt-3">
                    <strong>Tip:</strong> If your date format includes two-digit years (YY), we will assume 2000s (e.g., `03-05-00` → 2000-03-05).
                </p>
            </ModalBody>
            <ModalFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                    onClick={handleUploadRosterButtonClick}  // 👈 Triggers the hidden file input
                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    style={{
                        borderRadius: '30px',
                        backgroundColor: '#28C76F',
                        width: '250px'
                    }}
                >
                    <i className='fa fa-upload' />
                    <span>{' '}Upload Roster File</span>
                </Button>            
            </ModalFooter>
        </Modal>

            <Modal scrollable isOpen={copyRosterToClassModal} toggle={toggleCopyRosterToClassModal} style={{ width: '350px' }}>
                <ModalBody>
                    <h5>Copy roster to:</h5>
                    {user && classes && classes.filter(x => x.classId !== classId).map(x => (
                        <FormGroup check key={x.classId}>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={classesSelectedForRosterCopy.includes(x.classId)}
                                    onChange={() => handleCheckboxChangeForRosterCopy(x.classId)}
                                />
                                <span className="form-check-sign" />
                                {x.className}
                            </Label>
                        </FormGroup>
                    ))}
                </ModalBody>
                <ModalFooter style={{ paddingRight: '20px' }}>
                    <Button 
                        className='button-hover-fix'
                        disabled={classesSelectedForRosterCopy.length < 1} 
                        onClick={() => copyRosterToClasses()}
                        style={{ borderRadius: '30px' }}
                    >
                        <i className="fa fa-copy" />{' '}Copy
                    </Button>
                    <Button 
                        onClick={toggleCopyRosterToClassModal}
                        style={{ borderRadius: '30px', backgroundColor: '#fa5656' }}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}
export default RosterTable;

// import { createSlice } from "@reduxjs/toolkit";
// import { doc, getDoc, collection, getDocs, onSnapshot } from "firebase/firestore";
// import { db } from "../firebase"; // Ensure correct Firestore import

// export const userSlice = createSlice({
//   name: "user",
//   initialState: {
//     user: null,
//     classes: [], // Storing class list separately
//     selectedClassContentBoxes: {}, // Store contentBoxes per class
//     status: "idle",
//     error: null,
//   },
//   reducers: {
//     login: (state, action) => {
//       const userData = action.payload;
  
//       state.user = {
//           ...userData,
//           accountCreated: userData.accountCreated?.seconds || null, // Convert Firestore Timestamp to number
//           lastLogIn: userData.lastLogIn ? userData.lastLogIn.seconds : null, // Convert Firestore Timestamp to number
//       };
//   },
//     logout: (state) => {
//       state.user = null;
//       state.classes = [];
//       state.selectedClassContentBoxes = {};
//     },
//     setClasses: (state, action) => {
//       state.classes = action.payload;
//     },
//     setClassContentBoxes: (state, action) => {
//       const { classId, contentBoxes } = action.payload;
//       state.selectedClassContentBoxes[classId] = contentBoxes;
//     },
//     setError: (state, action) => {
//       state.error = action.payload;
//     },
//   },
// });

// export const { login, logout, setClasses, setClassContentBoxes, setError } = userSlice.actions;
// export const selectUser = (state) => state.user.user;
// export const selectClasses = (state) => state.user.classes;
// export const selectClassContentBoxes = (classId) => (state) => state.user.selectedClassContentBoxes[classId] || [];

// export default userSlice.reducer;

// // 🔹 Async Function to Fetch User & Classes
// export const fetchUserData = (uid) => async (dispatch) => {
//   if (!uid) return;

//   try {
//     const userRef = doc(db, "usersNew", uid);
//     const userSnap = await getDoc(userRef);

//     if (userSnap.exists()) {
//       dispatch(login(userSnap.data()));

//       // 🔹 Fetch classes (without contentBoxes yet)
//       const classesRef = collection(db, `usersNew/${uid}/classes`);
//       const classesSnap = await getDocs(classesRef);

//       const classesData = classesSnap.docs.map((classDoc) => ({
//         id: classDoc.id, 
//         ...classDoc.data(),
//       }));

//       dispatch(setClasses(classesData));
//     } else {
//       console.error("User not found!");
//     }
//   } catch (error) {
//     dispatch(setError("Error fetching user data"));
//     console.error("Error fetching user data:", error);
//   }
// };

// export const fetchClassContentBoxes = (uid, classId) => (dispatch, getState) => {
//     if (!uid || !classId) return;
  
//     try {
//       const contentBoxesRef = collection(db, `usersNew/${uid}/classes/${classId}/contentBoxes`);
  
//       return onSnapshot(contentBoxesRef, (snapshot) => {
//         const newContentBoxes = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
  
//         // Avoid dispatching if data is the same
//         const currentContentBoxes = getState().user.selectedClassContentBoxes[classId] || [];
//         if (JSON.stringify(newContentBoxes) === JSON.stringify(currentContentBoxes)) return;
  
//         dispatch(setClassContentBoxes({ classId, contentBoxes: newContentBoxes }));
//       });
//     } catch (error) {
//       console.error("Error fetching content boxes:", error);
//     }
//   };
  

import { createSlice } from "@reduxjs/toolkit";
import { doc, getDoc, collection, getDocs, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase"; // Ensure correct Firestore import

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    classes: [], // Storing class list separately
    selectedClassContentBoxes: {}, // Store contentBoxes per class
    status: "idle",
    error: null,
  },
  reducers: {
    login: (state, action) => {
      const userData = action.payload;
  
      state.user = {
          ...userData,
          accountCreated: userData.accountCreated?.seconds || null, // Convert Firestore Timestamp to number
          lastLogIn: userData.lastLogIn ? userData.lastLogIn.seconds : null, // Convert Firestore Timestamp to number
      };
    },
    logout: (state) => {
      state.user = null;
      state.classes = [];
      state.selectedClassContentBoxes = {};
    },
    setClasses: (state, action) => {
      if (!state.user || !state.user.orderOfClasses) {
        state.classes = action.payload; // No sorting if order list doesn't exist
        return;
      }

      const orderOfClasses = state.user.orderOfClasses;
      
      // Sort classes based on orderOfClasses
      state.classes = [...action.payload].sort((a, b) => {
        return orderOfClasses.indexOf(a.classId) - orderOfClasses.indexOf(b.classId);
      });
    },
    setClassContentBoxes: (state, action) => {
      const { classId, contentBoxes } = action.payload;
      state.selectedClassContentBoxes[classId] = contentBoxes;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { login, logout, setClasses, setClassContentBoxes, setError } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export const selectClasses = (state) => state.user.classes;
export const selectClassContentBoxes = (classId) => (state) => state.user.selectedClassContentBoxes[classId] || [];

export default userSlice.reducer;

// 🔹 Async Function to Fetch User & Classes
export const fetchUserData = (uid) => async (dispatch) => {
  if (!uid) return;

  try {
    const userRef = doc(db, "usersNew", uid);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const userData = userSnap.data();
      
      dispatch(login(userData)); // Store user first

      // 🔹 Fetch classes (without contentBoxes yet)
      const classesRef = collection(db, `usersNew/${uid}/classes`);
      const classesSnap = await getDocs(classesRef);

      let classesData = classesSnap.docs.map((classDoc) => ({
        classId: classDoc.classId, 
        ...classDoc.data(),
      }));

      // ✅ Sort classes based on orderOfClasses before dispatching
      if (userData.orderOfClasses) {
        classesData.sort((a, b) => {
          return userData.orderOfClasses.indexOf(a.classId) - userData.orderOfClasses.indexOf(b.classId);
        });
      }

      //ADDED THIS BUT COULD CAUSE PROBLEMS IF USER HAS NO CLASSES...NEED TO CHECK
      if (classesData.length > 0) {
        dispatch(setClasses(classesData)); // ✅ Only dispatch if classes exist
      }    
    } else {
      console.error("User not found!");
    }
  } catch (error) {
    dispatch(setError("Error fetching user data"));
    console.error("Error fetching user data:", error);
  }
};

// 🔹 Fetch contentBoxes for a given class and sort them if needed
// export const fetchClassContentBoxes = (uid, classId) => (dispatch, getState) => {
//     if (!uid || !classId) return;
  
//     try {
//       const contentBoxesRef = collection(db, `usersNew/${uid}/classes/${classId}/contentBoxes`);
  
//       return onSnapshot(contentBoxesRef, (snapshot) => {
//         const newContentBoxes = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         // Retrieve class document to get `contentBoxesOrder`
//         const classData = getState().user.classes.find((cls) => cls.id === classId);
//         const contentBoxesOrder = classData?.contentBoxesOrder || [];

//         // ✅ Sort contentBoxes if order exists
//         newContentBoxes.sort((a, b) => {
//           return contentBoxesOrder.indexOf(a.id) - contentBoxesOrder.indexOf(b.id);
//         });

//         // Avoid dispatching if data is the same
//         const currentContentBoxes = getState().user.selectedClassContentBoxes[classId] || [];
//         if (JSON.stringify(newContentBoxes) === JSON.stringify(currentContentBoxes)) return;
  
//         dispatch(setClassContentBoxes({ classId, contentBoxes: newContentBoxes }));
//       });
//     } catch (error) {
//       console.error("Error fetching content boxes:", error);
//     }
// };

// export const fetchClassContentBoxes = (uid, classId) => (dispatch, getState) => {
//   if (!uid || !classId) return;

//   try {
//     const classRef = doc(db, `usersNew/${uid}/classes/${classId}`);
//     const contentBoxesRef = collection(db, `usersNew/${uid}/classes/${classId}/contentBoxes`);

//     return onSnapshot(classRef, async (classSnap) => {
//       if (!classSnap.exists()) {
//         console.error("Class not found");
//         return;
//       }

//       let { contentBoxesOrder = [] } = classSnap.data();

//       onSnapshot(contentBoxesRef, (snapshot) => {
//         let contentBoxes = snapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));

//         // Separate active and inactive contentBoxes
//         let activeContentBoxes = contentBoxes.filter(box => box.active);
//         let inactiveContentBoxes = contentBoxes.filter(box => !box.active);

//         // Remove missing contentBox IDs from contentBoxesOrder
//         const validContentBoxIds = new Set(activeContentBoxes.map(box => box.id));
//         // const updatedOrder = contentBoxesOrder.filter(id => validContentBoxIds.has(id));
//         const missingIds = [...validContentBoxIds].filter(id => !contentBoxesOrder.includes(id));
//         const updatedOrder = [...contentBoxesOrder.filter(id => validContentBoxIds.has(id)), ...missingIds];

//         // If the order list changed, update the class document
//         if (contentBoxesOrder.length !== updatedOrder.length) {
//           updateDoc(classRef, { contentBoxesOrder: updatedOrder }).catch(console.error);
//         }

//         // Sort active contentBoxes based on contentBoxesOrder
//         activeContentBoxes.sort((a, b) => updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id));

//         // Combine sorted active boxes with inactive ones (inactive ones go at the end)
//         const finalContentBoxes = [...activeContentBoxes, ...inactiveContentBoxes];

//         // Avoid dispatching if data is the same
//         const currentContentBoxes = getState().user.selectedClassContentBoxes[classId] || [];
//         if (JSON.stringify(finalContentBoxes) === JSON.stringify(currentContentBoxes)) return;

//         dispatch(setClassContentBoxes({ classId, contentBoxes: finalContentBoxes }));
//       });
//     });
//   } catch (error) {
//     console.error("Error fetching content boxes:", error);
//   }
// };

export const fetchClassContentBoxes = (uid, classId) => (dispatch) => {
  if (!uid || !classId) return;

  try {
    const contentBoxesRef = collection(db, `usersNew/${uid}/classes/${classId}/contentBoxes`);

    return onSnapshot(contentBoxesRef, (snapshot) => {
      const contentBoxes = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch(setClassContentBoxes({ classId, contentBoxes }));
    });
  } catch (error) {
    console.error("Error fetching content boxes:", error);
  }
};




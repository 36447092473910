import React from 'react'
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { Card, CardBody, Row, Col, Navbar, NavbarBrand,
     NavItem, UncontrolledAccordion, AccordionItem,
      AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader, Container, CardImg } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import { Link } from 'react-router-dom';
import BlogArticle from './BlogArticle';
import LandingPageTopNav from './LandingPageTopNav';
import LandingPageFooter from './LandingPageFooter';
import blogs from "/Users/michaeltucker/Documents/coding-projects/client/src/blogs.js";

const Blog = ({showPricingLink}) => {

    const history = useHistory();

    const { blogId } = useParams();

    const isMobile = /Mobi/.test(navigator.userAgent);

  return (
    <>
    { !isMobile ? (
        <div style={{ backgroundColor: 'white' }}>
            <LandingPageTopNav showPricingLink={false}/>
    
    {/************************************************* HEADLINE *************************************************/}
            {blogId === undefined ? (
                <>
                {/* <Row style={{ height: '30vh', backgroundColor: "rgb(11, 192, 223)", justifyContent: 'center', alignItems: 'center', padding: '20px 0px 20px 0px' }}>
                    <Card style={{width: '80%', height: '100%'}}>
                        <Row>
                            <Col style={{justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                <h2>Lesson Launchpad</h2>
                                <h3>The Complete Guide</h3>
                            </Col>
                            <Col>
                                <CardImg 
                                    src='https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2FGuide.png?alt=media&token=9fae221d-6f11-4833-a83e-abac512e1794'
                                    style={{height: 'fit-content', width: 'auto'}}
                                >
                                </CardImg>
                            </Col>
                        </Row>
                    </Card>
                </Row> */}
                <Row style={{margin: '20px'}}>
                    <Container style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                        {blogs.filter(card => card.show).map((card) => (
                            <Card
                                style={{ height: '300px', width: '325px', cursor: 'pointer', border: '1px solid #004aad' }}
                                key={card.id}
                                onClick={() => history.push(`blog/${card.url}`)}
                            >
                                <CardImg
                                    alt="Card image cap"
                                    src={card.cardImage}
                                    style={{
                                        height: 175,
                                        padding: 5
                                    }}
                                    top
                                    width="auto"
                                />
                                <CardHeader style={{minHeight: '0px', borderTop: '1px solid grey'}}>
                                    <h5 style={{margin: '0px'}}>{card.title}</h5>
                                </CardHeader>
                                <CardBody>
                                    {card.description}
                                </CardBody>
                            </Card>
                        ))}
                    </Container> 
                </Row> 
                </>  
            ) :
            (
                <BlogArticle />
            )}
        </div>
    ) : (
        <div style={{ backgroundColor: '#f0f0f0' }}>
            <LandingPageTopNav showPricingLink={false}/>


{/************************************************* HEADLINE *************************************************/}

        <Row style={{ height: '1000px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
        
        </Row>






    </div>
    )}
    <LandingPageFooter />
   </>
  )
}

export default Blog

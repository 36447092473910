import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { useLocation } from 'react-router-dom';
import ClassesTable from "./tables/ClassesTable";
import AutoCountdownTable from "./tables/AutoCountdownTable";
import AutoReminderTable from "./tables/AutoReminderTable";
import AutoHappyBirthdayTable from "./tables/AutoHappyBirthdayTable";
import AutoMusicTable from "./tables/AutoMusicTable";


function AddEditView() {

  const user = useSelector(selectUser);
  const classes = useSelector((state) => state.user.classes);
  const location = useLocation();
  const remindersRef = useRef(null);
  const timersRef = useRef(null);
  const classesRef = useRef(null);
  const musicRef = useRef(null);
  const birthdayRef = useRef(null);

  useEffect(() => {
    const scrollToSection = () => {
      if (location.hash === "#reminders" && remindersRef.current) {
        remindersRef.current.scrollIntoView({ block: "start" });
      } else if (location.hash === "#timers" && timersRef.current) {
        timersRef.current.scrollIntoView({ block: "start" });
      } else if (location.hash === "#classes" && classesRef.current) {
        classesRef.current.scrollIntoView({ block: "start" });
      } else if (location.hash === "#music" && musicRef.current) {
        musicRef.current.scrollIntoView({ block: "start" });
      } else if (location.hash === "#birthday" && birthdayRef.current) {
        birthdayRef.current.scrollIntoView({ block: "start" });
      }
    };
  
    const timeout = setTimeout(scrollToSection, 300);
    return () => clearTimeout(timeout);
  }, [location, remindersRef, timersRef, classesRef, musicRef, birthdayRef]); 

  return (
    <>
      <div className="content">
        <div ref={classesRef}>
          <ClassesTable props={classes} />
        </div>
        <div ref={timersRef}>
          <AutoCountdownTable props={user.autoCountdowns} />
        </div>
        <div ref={remindersRef}>
          <AutoReminderTable props={user.reminders} />
        </div>
        <div ref={musicRef}>
          <AutoMusicTable />
        </div>
        <div ref={birthdayRef}>
          <AutoHappyBirthdayTable />
        </div>
      </div>
    </>
  );
}

export default AddEditView;

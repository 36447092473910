import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";

const Scheduling = {

    weekDayLetterOptions: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"],
    daysOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
    daysOfWeekWithWeekends: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
    dayMapping: {
        "Monday": "M",
        "Tuesday": "T",
        "Wednesday": "W",
        "Thursday": "Th",
        "Friday": "F",
    },
    dayMappingWithWeekends: {
        "Monday": "M",
        "Tuesday": "T",
        "Wednesday": "W",
        "Thursday": "Th",
        "Friday": "F",
        "Saturday": "S",
        "Sunday": "Su",
    },
    dayOrder: ["M", "T", "W", "Th", "F", "S", "Su"],
    weeksOrder: (numberOfWeeks) => Scheduling.weekDayLetterOptions.slice(0, numberOfWeeks),
    days: ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
    daysLong: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'],
    monthsLong: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    suffix: ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'],

    blankSchedule: {
        "A": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "B": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "C": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "D": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "E": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "F": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "G": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "H": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "I": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "J": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
        "K": {
            "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
            "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
            "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
        },
    },

    // addWeeksToSchedule: async (setData, user, classId) => {
    //     try {
    //         const docRef = doc(db, "users", user.uid);
    //         const userCopy = JSON.parse(JSON.stringify(user));
    
    //         const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);
    //         if (classIndex === -1) return;
    
    //         const newWeeks = {};
    
    //         // Generate any missing weeks dynamically
    //         for (const week of Scheduling.weekDayLetterOptions) {
    //             if (!(week in userCopy.classes[classIndex].schedule)) {
    //                 newWeeks[week] = {
    //                 "Monday": { active: true, startTime: '00:00', endTime: '00:00' },
    //                 "Tuesday": { active: true, startTime: '00:00', endTime: '00:00' },
    //                 "Wednesday": { active: true, startTime: '00:00', endTime: '00:00' },
    //                 "Thursday": { active: true, startTime: '00:00', endTime: '00:00' },
    //                 "Friday": { active: true, startTime: '00:00', endTime: '00:00' },
    //                 "Saturday": { active: false, startTime: '00:00', endTime: '00:00' },
    //                 "Sunday": { active: false, startTime: '00:00', endTime: '00:00' }
    //                 };
    //             }
    //         }
    
    //         // If any new weeks were added, update Firestore and local state
    //         if (Object.keys(newWeeks).length > 0) {
    //         userCopy.classes[classIndex].schedule = {
    //             ...userCopy.classes[classIndex].schedule,
    //             ...newWeeks
    //         };
    
    //         // Update the document in Firestore
    //         await setDoc(docRef, userCopy);
    
    //         // Update the data state to trigger re-render
    //         setData((prevData) => ({
    //             ...prevData,
    //             schedule: { ...prevData.schedule, ...newWeeks }
    //         }));
    
    //         console.log("Missing weeks added successfully.");
    //         } else {
    //         console.log("All weeks already present. No update needed.");
    //         }
    
    //     } catch (error) {
    //         console.error("Error updating schedule: ", error);
    //     }
    // },

    addWeeksToSchedule: async (setData, user, classId) => {

        // ✅ Check if all required parameters are provided before executing
        if (!setData || !user?.uid || !classId) {
            // console.error("❌ Missing required parameters: setData, user, or classId.");
            return;
        }

        try {
            const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
            const classSnap = await getDoc(classRef);
    
            if (!classSnap.exists()) {
                console.error("Class not found in Firestore");
                return;
            }
    
            const classData = classSnap.data();
            const currentSchedule = classData?.schedule || {}; // Ensure schedule exists
            const newWeeks = {};
    
            // ✅ Ensure Scheduling.weekDayLetterOptions exists before using it
            if (!Scheduling?.weekDayLetterOptions || !Array.isArray(Scheduling.weekDayLetterOptions)) {
                console.error("Scheduling.weekDayLetterOptions is missing or not an array.");
                return;
            }
    
            // Generate any missing weeks dynamically
            for (const week of Scheduling.weekDayLetterOptions) {
                if (!(week in currentSchedule)) {
                    newWeeks[week] = {
                        "Monday": { active: true, startTime: "00:00", endTime: "00:00" },
                        "Tuesday": { active: true, startTime: "00:00", endTime: "00:00" },
                        "Wednesday": { active: true, startTime: "00:00", endTime: "00:00" },
                        "Thursday": { active: true, startTime: "00:00", endTime: "00:00" },
                        "Friday": { active: true, startTime: "00:00", endTime: "00:00" },
                        "Saturday": { active: false, startTime: "00:00", endTime: "00:00" },
                        "Sunday": { active: false, startTime: "00:00", endTime: "00:00" }
                    };
                }
            }
    
            // ✅ Ensure Firestore is only updated if there are changes
            if (Object.keys(newWeeks).length > 0) {
                await updateDoc(classRef, {
                    schedule: { ...currentSchedule, ...newWeeks },
                });
    
                // ✅ Ensure UI state updates only when necessary
                setData((prevData) => ({
                    ...prevData,
                    schedule: { ...prevData.schedule, ...newWeeks },
                }));
    
                console.log("Missing weeks added successfully.");
            } else {
                console.log("All weeks already present. No update needed.");
            }
        } catch (error) {
            console.error("Error updating schedule: ", error);
        }
    },

    getWeeksWithActiveMonday: (schedule, user) => {
        const numberOfWeeks = user.settings.numberOfWeeks ?? 1; // Default to 1 if undefined or null
        const weeksToCheck = Scheduling.weekDayLetterOptions.slice(0, numberOfWeeks); // Limit to active weeks
      
        return weeksToCheck
          .filter(week => schedule[week]?.Monday?.active)
          .join(", ");
    },

    convertTo12HourFormat: (time24) => {
        // Split the time into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);
      
        // Determine whether it's AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';
      
        // Convert hours to 12-hour format
        const hours12 = hours % 12 || 12;
      
        // Format the time as "hh:mm AM/PM"
        const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
      
        return time12;
    },

    listActiveStartTimes: (data, user) => {
        if (!data || typeof data !== 'object') return '';
      
        const uniqueTimes = new Set();
        const excludedDays =
          user.settings.multiWeek && user.settings.cycleType === "day"
            ? new Set(["Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"])
            : new Set(["Saturday", "Sunday"]);
      
        const weeksToCheck = Scheduling.weekDayLetterOptions.slice(0, user.settings.numberOfWeeks ?? 1);
      
        for (const week of weeksToCheck) {
          if (!data[week]) continue;
      
          const days = data[week];
      
          for (const dayKey in days) {
            if (!excludedDays.has(dayKey)) {
              const day = days[dayKey];
              if (day?.active) {
                uniqueTimes.add(Scheduling.convertTo12HourFormat(day.startTime));
              }
            }
          }
        }
      
        return Array.from(uniqueTimes).join(', ');
    }
}

export default Scheduling;
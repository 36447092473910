import {React, useState, useEffect, useRef} from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, selectClasses, fetchUserData } from "features/userSlice";
import { collection, onSnapshot, doc, setDoc, getDoc, deleteDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import GoogleSlidesBox from "../../components/ContentBoxes/GoogleSlidesBox.js"
import TimerStopwatchContentBox from "components/ContentBoxes/TimerStopwatchContentBox";
import PdfBox from "components/ContentBoxes/TimerStopwatchContentBox";
import ActiveDatesModal from "/Users/michaeltucker/Documents/coding-projects/client/src/components/ActiveDatesModal.js";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardSubtitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge
} from "reactstrap";
import ContentBox from "components/ContentBoxes/ContentBox";
import AgendaBox from "components/ContentBoxes/AgendaBox";
import CanvaBox from "components/ContentBoxes/CanvaBox";
import MultiBox from "components/ContentBoxes/MultiBox";
import { createRandomId } from "RandomId";
import CountdownBox from "components/ContentBoxes/CountdownBox";
import GoogleDocSheetBox from "components/ContentBoxes/GoogleDocSheetBox";
import IframeBox from "components/ContentBoxes/IframeBox.js";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, deleteMultipleContentBoxes, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates, zoomContentBox } from "../../utils/ContentBoxUtils";
import { number } from "prop-types";

function ContentBoxesTable({props, date}) {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const classes = useSelector(selectClasses); // Fetch classes from Redux
    const dispatch = useDispatch()
    const thisClass = classes.find((classObj) => classObj.classId === props.current);    
    const [activeBoxes, setActiveBoxes] = useState()
    const [storageBoxes, setStorageBoxes] = useState()
    const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
    const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
    const dayMapping = {
      "Monday": "M",
      "Tuesday": "T",
      "Wednesday": "W",
      "Thursday": "Th",
      "Friday": "F",
      "Saturday": "S",
      "Sunday": "Su",
    };
    const dayOrder = ["M", "T", "W", "Th", "F", "S", "Su"];
    const weeks = ["A", "B", "C", "D"];
    const weeksOrder = weeks.slice(0, numberOfWeeks)
    const [boxToCopyId, setBoxToCopyId] = useState()
    const [copyBoxModal, setCopyBoxModal] = useState(false)
    const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    const today = new Date().toISOString().slice(0,10)
    const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending
    const [datesSortOrder, setDatesSortOrder] = useState("asc"); // Default to ascending
    const [boxesForMultiSelect, setBoxesForMultiSelect] = useState([]);
    const headerCheckboxRef = useRef(null);
    const [boxesForMultiSelectStorage, setBoxesForMultiSelectStorage] = useState([]);
    const headerCheckboxStorageRef = useRef(null);
    const [activeDatesFromMulti, setActiveDatesFromMulti] = useState(null) //used to keep track of which button was pressed to active dates - multi select or single.

    // 🔹 Update indeterminate state of the header checkbox
    useEffect(() => {
      if (headerCheckboxRef.current) {
        if (boxesForMultiSelect.length > 0 && boxesForMultiSelect.length < activeBoxes.length) {
          headerCheckboxRef.current.indeterminate = true; // Some checked, some unchecked
        } else {
          headerCheckboxRef.current.indeterminate = false; // Fully checked or unchecked
        }
      }
      if (headerCheckboxStorageRef.current) {
        if (boxesForMultiSelectStorage.length > 0 && boxesForMultiSelectStorage.length < storageBoxes.length) {
          headerCheckboxStorageRef.current.indeterminate = true; // Some checked, some unchecked
        } else {
          headerCheckboxStorageRef.current.indeterminate = false; // Fully checked or unchecked
        }
      }
    }, [boxesForMultiSelect, activeBoxes, boxesForMultiSelectStorage, storageBoxes]);

    // 🔹 Toggle individual checkboxes when checkbox is clicked
    const handleMultiSelectCheckboxChange = (item) => {
      setBoxesForMultiSelect(prev => 
        prev.includes(item) 
          ? prev.filter(box => box !== item) // Remove item if already selected
          : [...prev, item] // Add item if not selected
      );
    };

    // 🔹 Toggle all checkboxes when header checkbox is clicked
    const handleSelectAll = () => {
      if (boxesForMultiSelect.length === activeBoxes.length) {
        setBoxesForMultiSelect([]); // Deselect all
      } else {
        setBoxesForMultiSelect(activeBoxes.map((box) => box.contentBoxId)); // Select all
      }
    };

    // 🔹 Toggle individual checkboxes when checkbox is clicked
    const handleMultiSelectCheckboxChangeStorage = (item) => {
      setBoxesForMultiSelectStorage(prev => 
        prev.includes(item) 
          ? prev.filter(box => box !== item) // Remove item if already selected
          : [...prev, item] // Add item if not selected
      );
    };

    // 🔹 Toggle all checkboxes when header checkbox is clicked
    const handleSelectAllStorage = () => {
      if (boxesForMultiSelectStorage.length === storageBoxes.length) {
        setBoxesForMultiSelectStorage([]); // Deselect all
      } else {
        setBoxesForMultiSelectStorage(storageBoxes.map((box) => box.contentBoxId)); // Select all
      }
    };

    // useEffect(() => {
    //   if (thisClass) {
    //     const sortFunction = user.settings.useActiveDates ? sortByDatesActive : sortByLastEdited;
    
    //     if (user.settings.useActiveDates === true) {
    //       const activeContentBoxes = thisClass.contentBoxes.filter((box) => {
    //         if (Array.isArray(box.startDate)) {
    //           if (box.startDate.length > 0) {
    //             if (box.startDate.includes(date.dayNamePlural)) return true
    //           }
    //         } else {
    //           const startDate = new Date(box.startDate);
    //           startDate.setHours(0, 0, 0, 1); // Normalize start date
    //           const endDate = new Date(box.endDate);
    //           endDate.setHours(24); // Adjust end date to include the whole day
    //           const dayToCompare = new Date(date.year, date.month, date.day);
    //           dayToCompare.setHours(0, 0, 0, 0);
    //           return (
    //             startDate <= dayToCompare &&
    //             endDate >= dayToCompare ||
    //             box.startDate === undefined
    //           );
    //         }
    //       });
    //       setActiveBoxes(sortFunction(activeContentBoxes));
    
    //       const storageContentBoxes = thisClass.contentBoxes.filter((box) => {
    //         if (Array.isArray(box.startDate)) {
    //           if (box.startDate.length > 0) {
    //             if (!box.startDate.includes(date.dayNamePlural)) return true
    //           }
    //         } else {
    //           const startDate = new Date(box.startDate);
    //           startDate.setHours(0, 0, 0, 1); // Normalize start date
    //           const endDate = new Date(box.endDate);
    //           endDate.setHours(24); // Adjust end date to include the whole day
    //           const dayToCompare = new Date(date.year, date.month, date.day);
    //           dayToCompare.setHours(0, 0, 0, 0);
    //           return (
    //             startDate > dayToCompare ||
    //             (startDate < dayToCompare && endDate < dayToCompare)
    //           );
    //         }
    //       });
    //       setStorageBoxes(sortFunction(storageContentBoxes));
    //     } else {
    //       setActiveBoxes(
    //         sortFunction(thisClass.contentBoxes.filter((x) => x.active === true))
    //       );
    //       setStorageBoxes(
    //         sortFunction(
    //           thisClass.contentBoxes.filter((x) => x.active === false || x.active === undefined)
    //         )
    //       );
    //     }
    //   }
    // }, [user, date, sortOrder, datesSortOrder]);

    // useEffect(() => {
    //   if (!user?.uid || !props.current) return;
    
    //   const classId = props.current;
    //   const contentBoxesRef = collection(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes`);
    
    //   // Real-time listener for Firestore updates
    //   const unsubscribe = onSnapshot(contentBoxesRef, (snapshot) => {
    //     let contentBoxes = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    
    //     const sortFunction = user.settings.useActiveDates ? sortByDatesActive : sortByLastEdited;
    
    //     if (user.settings.useActiveDates) {
    //       const activeContentBoxes = contentBoxes.filter((box) => {
    //         if (Array.isArray(box.startDate)) {
    //           return box.startDate.includes(date.dayNamePlural);
    //         } else {
    //           const [startYear, startMonth, startDay] = box.startDate?.split("-");
    //           const [endYear, endMonth, endDay] = box.endDate?.split("-");
    //           const startDate = new Date(startYear, startMonth - 1, startDay);
    //           const endDate = new Date(endYear, endMonth - 1, endDay);
    //           const dayToCompare = new Date(date.year, date.month, date.day);
    //           return startDate <= dayToCompare && endDate >= dayToCompare;
    //         }
    //       });
    
    //       const storageContentBoxes = contentBoxes.filter((box) => {
    //         if (Array.isArray(box.startDate)) {
    //           return !box.startDate.includes(date.dayNamePlural);
    //         } else {
    //           const [startYear, startMonth, startDay] = box.startDate?.split("-");
    //           const [endYear, endMonth, endDay] = box.endDate?.split("-");
    //           const startDate = new Date(startYear, startMonth - 1, startDay);
    //           const endDate = new Date(endYear, endMonth - 1, endDay);
    //           const dayToCompare = new Date(date.year, date.month, date.day);
    //           return startDate > dayToCompare || (startDate < dayToCompare && endDate < dayToCompare);
    //         }
    //       });
    
    //       setActiveBoxes(sortFunction(activeContentBoxes));
    //       setStorageBoxes(sortFunction(storageContentBoxes));
    //     } else {
    //       setActiveBoxes(sortFunction(contentBoxes.filter((box) => box.active === true)));
    //       setStorageBoxes(sortFunction(contentBoxes.filter((box) => box.active === false || box.active === undefined)));
    //     }
    //   });
    
    //   return () => unsubscribe();
    // }, [user, props.current, date, sortOrder, datesSortOrder]);

    useEffect(() => {
      if (!user?.uid || !props.current) return;
    
      const classId = props.current;
      const classRef = doc(db, `usersNew/${user.uid}/classes/${classId}`);
      const contentBoxesRef = collection(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes`);
    
      // Fetch the class document to get contentBoxesOrder
      const unsubscribeClass = onSnapshot(classRef, async (classSnap) => {
        if (!classSnap.exists()) {
          console.error("Class not found");
          return;
        }
    
        let { contentBoxesOrder = [] } = classSnap.data();
    
        // Real-time listener for contentBoxes updates
        const unsubscribeContentBoxes = onSnapshot(contentBoxesRef, (snapshot) => {
          let contentBoxes = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
    
          // Separate active and inactive contentBoxes
          let activeContentBoxes = contentBoxes.filter(box => box.active);
          let inactiveContentBoxes = contentBoxes.filter(box => !box.active);
    
          // Remove missing contentBox IDs from contentBoxesOrder
          const validContentBoxIds = new Set(activeContentBoxes.map(box => box.id));
          const updatedOrder = contentBoxesOrder.filter(id => validContentBoxIds.has(id));
    
          // If the order list changed, update the class document
          if (contentBoxesOrder.length !== updatedOrder.length) {
            updateDoc(classRef, { contentBoxesOrder: updatedOrder }).catch(console.error);
          }
    
          // Sort active contentBoxes based on contentBoxesOrder
          activeContentBoxes.sort((a, b) => updatedOrder.indexOf(a.id) - updatedOrder.indexOf(b.id));
    
          // Combine sorted active boxes with inactive ones (inactive ones go at the end)
          const finalContentBoxes = [...activeContentBoxes, ...inactiveContentBoxes];
    
          // Sorting function based on user settings
          const sortFunction = user.settings.useActiveDates ? sortByDatesActive : sortByLastEdited;
    
          if (user.settings.useActiveDates) {
            // const activeBoxes = finalContentBoxes.filter((box) => {
            //   if (Array.isArray(box.startDate)) {
            //     return box.startDate.includes(date.dayNamePlural);
            //   } else {
            //     const [startYear, startMonth, startDay] = box.startDate?.split("-");
            //     const [endYear, endMonth, endDay] = box.endDate?.split("-");
            //     const startDate = new Date(startYear, startMonth - 1, startDay);
            //     const endDate = new Date(endYear, endMonth - 1, endDay);
            //     const dayToCompare = new Date(date.year, date.month, date.day);
            //     return startDate <= dayToCompare && endDate >= dayToCompare;
            //   }
            // });
    
            // const storageBoxes = finalContentBoxes.filter((box) => {
            //   if (Array.isArray(box.startDate)) {
            //     return !box.startDate.includes(date.dayNamePlural);
            //   } else {
            //     const [startYear, startMonth, startDay] = box.startDate?.split("-");
            //     const [endYear, endMonth, endDay] = box.endDate?.split("-");
            //     const startDate = new Date(startYear, startMonth - 1, startDay);
            //     const endDate = new Date(endYear, endMonth - 1, endDay);
            //     const dayToCompare = new Date(date.year, date.month, date.day);
            //     return startDate > dayToCompare || (startDate < dayToCompare && endDate < dayToCompare);
            //   }
            // });

            // 3-13-25: STARTDATE WAS IN TIMESTAMP FORMAT CAUSING .SPLIT TO FAIL. THIS CODE IS MORE ROBUST.
            const activeBoxes = finalContentBoxes.filter((box) => {
              try {
                if (Array.isArray(box.startDate)) {
                  return box.startDate.includes(date.dayNamePlural);
                }
          
                let startDate, endDate;
          
                // ✅ Handle properly formatted "YYYY-MM-DD" date
                if (typeof box.startDate === "string" && box.startDate.includes("-")) {
                  const [startYear, startMonth, startDay] = box.startDate.split("-");
                  const [endYear, endMonth, endDay] = box.endDate?.split("-") || [];
                  startDate = new Date(startYear, startMonth - 1, startDay);
                  endDate = new Date(endYear, endMonth - 1, endDay);
                } 
                // ✅ Handle cases where the date is a full timestamp string
                else {
                  startDate = new Date(box.startDate);
                  endDate = new Date(box.endDate);
                }
          
                // ✅ Validate dates
                if (isNaN(startDate) || isNaN(endDate)) {
                  console.warn("⚠️ Invalid date format detected:", box.startDate, box.endDate);
                  return false;
                }
          
                const dayToCompare = new Date(date.year, date.month, date.day);
                return startDate <= dayToCompare && endDate >= dayToCompare;
              } catch (error) {
                console.error("❌ Error parsing dates:", error);
                return false;
              }
            });
          
            const storageBoxes = finalContentBoxes.filter((box) => {
              try {
                if (Array.isArray(box.startDate)) {
                  return !box.startDate.includes(date.dayNamePlural);
                }
          
                let startDate, endDate;
          
                // ✅ Handle properly formatted "YYYY-MM-DD" date
                if (typeof box.startDate === "string" && box.startDate.includes("-")) {
                  const [startYear, startMonth, startDay] = box.startDate.split("-");
                  const [endYear, endMonth, endDay] = box.endDate?.split("-") || [];
                  startDate = new Date(startYear, startMonth - 1, startDay);
                  endDate = new Date(endYear, endMonth - 1, endDay);
                } 
                // ✅ Handle cases where the date is a full timestamp string
                else {
                  startDate = new Date(box.startDate);
                  endDate = new Date(box.endDate);
                }
          
                // ✅ Validate dates
                if (isNaN(startDate) || isNaN(endDate)) {
                  console.warn("⚠️ Invalid date format detected:", box.startDate, box.endDate);
                  return false;
                }
          
                const dayToCompare = new Date(date.year, date.month, date.day);
                return startDate > dayToCompare || (startDate < dayToCompare && endDate < dayToCompare);
              } catch (error) {
                console.error("❌ Error parsing dates:", error);
                return false;
              }
            });
    
            setActiveBoxes(sortFunction(activeBoxes));
            setStorageBoxes(sortFunction(storageBoxes));
          } else {
            setActiveBoxes(sortFunction(finalContentBoxes.filter((box) => box.active === true)));
            setStorageBoxes(sortFunction(finalContentBoxes.filter((box) => !box.active)));
          }
        });
    
        return () => unsubscribeContentBoxes();
      });
    
      return () => unsubscribeClass();
    }, [user, props.current, date, sortOrder, datesSortOrder]);
    

    const warningWithConfirmAndCancelMessage = (contentBoxId) => {
      const classId = props.current;
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info delete-button"
          confirmBtnStyle={{borderRadius: '30px'}}
          cancelBtnBsStyle="danger button-hover-fix"
          cancelBtnStyle={{borderRadius: '30px'}}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this Content Box!
        </ReactBSAlert>
      );
    };

    const warningForMultiDelete = (fromStorage) => {
      const classId = props.current;
      const boxesForMultiDelete = fromStorage ? boxesForMultiSelectStorage : boxesForMultiSelect;
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => 
            deleteMultipleContentBoxes(user.uid, classId, boxesForMultiDelete)
              .then(() => successDelete(boxesForMultiDelete.length)) // ✅ Correct async handling
              .catch((error) => console.error("Error deleting boxes:", error)) // Handle errors properly
          }
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info delete-button"
          confirmBtnStyle={{borderRadius: '30px'}}
          cancelBtnBsStyle="danger button-hover-fix"
          cancelBtnStyle={{borderRadius: '30px'}}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          { boxesForMultiDelete.length <= 1 ?
              "You will not be able to recover this Content Box!"
            :
              `You will not be able to recover these ${boxesForMultiDelete.length} content boxes!`
          }
        </ReactBSAlert>
      );
    };

    const alertForMultiStorage = () => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => 
            moveMultipleContentBoxesToStorage(boxesForMultiSelect)
              .then(() => successMoveToStorage(boxesForMultiSelect.length)) // ✅ Correct async handling
              .catch((error) => console.error("Error moving boxes:", error)) // Handle errors properly
          }
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info delete-button"
          confirmBtnStyle={{borderRadius: '30px'}}
          cancelBtnBsStyle="danger button-hover-fix"
          cancelBtnStyle={{borderRadius: '30px'}}
          confirmBtnText="Yes!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          { boxesForMultiSelect.length <= 1 ?
              "This will move the selected Content Box to storage and it will no longer appear on your present screen."
            :
              `This will move the ${boxesForMultiSelect.length} selected content boxes to storage and they will no longer appear on your present screen.`
          }
        </ReactBSAlert>
      );
    };

    const alertForMultiActive = () => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => 
            moveMultipleContentBoxesToActive(boxesForMultiSelectStorage)
              .then(() => successMoveToActive(boxesForMultiSelectStorage.length)) // ✅ Correct async handling
              .catch((error) => console.error("Error moving boxes:", error)) // Handle errors properly
          }
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info delete-button"
          confirmBtnStyle={{borderRadius: '30px'}}
          cancelBtnBsStyle="danger button-hover-fix"
          cancelBtnStyle={{borderRadius: '30px'}}
          confirmBtnText="Yes!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          { boxesForMultiSelectStorage.length <= 1 ?
              "This will move the selected Content Box to active."
            :
              `This will move the ${boxesForMultiSelectStorage.length} selected content boxes to active.`
          }
        </ReactBSAlert>
      );
    };

    const successMoveToStorage = (numberOfBoxes) => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Moved to Storage!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info button-hover-fix"
          confirmBtnStyle={{borderRadius: '30px'}}
          btnSize=""
        >
          {numberOfBoxes > 1 ? `${numberOfBoxes} content boxes moved to storage.` : "Your conetent box has been moved to storage."}
        </ReactBSAlert>
      );
    };

    const successMoveToActive = (numberOfBoxes) => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Moved to Active!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info button-hover-fix"
          confirmBtnStyle={{borderRadius: '30px'}}
          btnSize=""
        >
          {numberOfBoxes > 1 ? `${numberOfBoxes} content boxes moved to active.` : "Your conetent box has been moved to active."}
        </ReactBSAlert>
      );
    };
  
    const successDelete = (numberOfBoxes) => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info button-hover-fix"
          confirmBtnStyle={{borderRadius: '30px'}}
          btnSize=""
        >
          {numberOfBoxes?.length > 1 ? `${numberOfBoxes} content boxes deleted successfully.` : "Your conetent box has been deleted."}
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info button-hover-fix"
          confirmBtnStyle={{borderRadius: '30px'}}
          btnSize=""
        >
          Your content box is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    // const deleteContentBox = async (contentBoxId) => {
    //   try {
    //     const classId = props.current; // Get the current class ID
    
    //     if (!user?.uid || !classId || !contentBoxId) {
    //       console.error("Missing required parameters.");
    //       return;
    //     }
    
    //     // Reference to the content box document inside the user's class subcollection
    //     const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
    
    //     // Delete the content box document
    //     await deleteDoc(contentBoxRef);
    
    //     console.log(`Content box ${contentBoxId} deleted successfully!`);
    //     successDelete(); // Show success alert
    //   } catch (error) {
    //     console.error("Error deleting content box:", error);
    //   }
    // };

    /**
 * Updates the order of content boxes in the contentBoxesOrder list of a class.
 * @param {string} contentBoxId - The ID of the content box being moved
 * @param {string} targetIndex - The index of the target content box (for determining new position)
 * @param {string} direction - "up" or "down" for movement
 */
const updateContentBoxOrder = async (contentBoxId, targetIndex, direction) => {
  const classId = props.current;
  try {
    const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
    
    // 🔹 Fetch the class document
    const classSnap = await getDoc(classRef);
    if (!classSnap.exists()) {
      console.error("Class document not found.");
      return;
    }

    let contentBoxesOrder = classSnap.data().contentBoxesOrder || [];
    
    const currentIndex = contentBoxesOrder.indexOf(contentBoxId);
    // const targetIndex = contentBoxesOrder.indexOf(targetBoxId);

    if (currentIndex === -1 || targetIndex === -1) {
      console.error("Content box not found in order list.");
      return;
    }

    let newIndex;
    if (direction === "up") {
      newIndex = targetIndex;
      if (currentIndex < newIndex) newIndex--; // Adjust index for insertion
    } else if (direction === "down") {
      newIndex = targetIndex + 1;
      if (currentIndex < newIndex) newIndex--; // Adjust index for insertion
    }

    if (newIndex < 0 || newIndex >= contentBoxesOrder.length) {
      return; // Prevent invalid moves
    }

    // 🔹 Move contentBoxId to the new position
    contentBoxesOrder.splice(currentIndex, 1);
    contentBoxesOrder.splice(newIndex, 0, contentBoxId);

    // 🔹 Update the `contentBoxesOrder` field in Firestore
    await updateDoc(classRef, { contentBoxesOrder });

    dispatch(fetchUserData(user.uid))

    console.log(`✅ Moved ${contentBoxId} to position ${newIndex}.`);
  } catch (error) {
    console.error("❌ Error updating content box order:", error);
  }
};

    const updateContentBoxes = async (updatedBoxes) => {
      try {
        const classId = props.current; // Get the current class ID

        if (!user?.uid || !classId || !updatedBoxes?.length) {
          console.error("Missing required parameters.");
          return;
        }

        // Loop through each content box and update it individually in Firestore
        const updatePromises = updatedBoxes.map(async (box) => {
          const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${box.contentBoxId}`);
          await setDoc(contentBoxRef, box, { merge: true }); // Merging ensures only updated fields are modified
        });

        await Promise.all(updatePromises); // Wait for all updates to complete

        console.log("All content boxes updated successfully!");
      } catch (error) {
        console.error("Error updating content boxes:", error);
      }
    };


    const moveToStorage = async (boxFromClick) => {
      try {
        const classId = props.current; // Get the current class ID
        const contentBoxId = boxFromClick.contentBoxId; // Get the content box ID
    
        if (!user.uid || !classId || !contentBoxId) {
          console.error("Missing required parameters.");
          return;
        }
    
        const classRef = doc(db, `usersNew/${user.uid}/classes/${classId}`);
        const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
    
        // ✅ Step 1: Update the "active" field in Firestore
        await setDoc(contentBoxRef, { active: false }, { merge: true });
    
        // ✅ Step 2: Fetch the current class data to get `contentBoxesOrder`
        const classSnap = await getDoc(classRef);
        if (!classSnap.exists()) {
          console.error("Class not found.");
          return;
        }
    
        let classData = classSnap.data();
        let contentBoxesOrder = classData.contentBoxesOrder || [];
    
        // ✅ Step 3: Remove the contentBoxId from contentBoxesOrder if it exists
        if (contentBoxesOrder.includes(contentBoxId)) {
          contentBoxesOrder = contentBoxesOrder.filter(id => id !== contentBoxId);
          await updateDoc(classRef, { contentBoxesOrder });
          console.log(`Removed content box ${contentBoxId} from contentBoxesOrder.`);
        }
    
        console.log(`Moved content box ${contentBoxId} to storage.`);
      } catch (error) {
        console.error("Error moving content box to storage:", error);
      }
    };

    const moveMultipleContentBoxesToStorage = async (contentBoxIds) => {
      try {
        const classId = props.current; // Get the current class ID
    
        if (!user.uid || !classId || !Array.isArray(contentBoxIds) || contentBoxIds.length === 0) {
          console.error("Missing required parameters or empty contentBoxIds list.");
          return;
        }
    
        const classRef = doc(db, `usersNew/${user.uid}/classes/${classId}`);
    
        // ✅ Step 1: Update "active" field in Firestore for all contentBoxes
        const batch = writeBatch(db);
        contentBoxIds.forEach((contentBoxId) => {
          const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
          batch.set(contentBoxRef, { active: false }, { merge: true });
        });
    
        await batch.commit();
        console.log("Updated active status for all selected content boxes.");
    
        // ✅ Step 2: Fetch the current class data to update `contentBoxesOrder`
        const classSnap = await getDoc(classRef);
        if (!classSnap.exists()) {
          console.error("Class not found.");
          return;
        }
    
        let classData = classSnap.data();
        let contentBoxesOrder = classData.contentBoxesOrder || [];
    
        // ✅ Step 3: Remove contentBoxIds from contentBoxesOrder if they exist
        const newContentBoxesOrder = contentBoxesOrder.filter(id => !contentBoxIds.includes(id));
    
        if (newContentBoxesOrder.length !== contentBoxesOrder.length) {
          await updateDoc(classRef, { contentBoxesOrder: newContentBoxesOrder });
          console.log(`Removed ${contentBoxIds.length} content boxes from contentBoxesOrder.`);
        }
    
        console.log(`Moved ${contentBoxIds.length} content boxes to storage.`);
      } catch (error) {
        console.error("Error moving content boxes to storage:", error);
      }
    };    

    const moveToActive = async (boxFromClick) => {
      try {
        const classId = props.current; // Get the current class ID
        const contentBoxId = boxFromClick.contentBoxId; // Get the content box ID
    
        if (!user.uid || !classId || !contentBoxId) {
          console.error("Missing required parameters.");
          return;
        }
    
        const classRef = doc(db, `usersNew/${user.uid}/classes/${classId}`);
        const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
    
        // ✅ Step 1: Update the "active" field in Firestore
        await setDoc(contentBoxRef, { active: true }, { merge: true });
    
        // ✅ Step 2: Fetch the current class data to get `contentBoxesOrder`
        const classSnap = await getDoc(classRef);
        if (!classSnap.exists()) {
          console.error("Class not found.");
          return;
        }
    
        let classData = classSnap.data();
        let contentBoxesOrder = classData.contentBoxesOrder || [];
    
        // ✅ Step 3: Ensure contentBoxId is added back to contentBoxesOrder if not already present
        if (!contentBoxesOrder.includes(contentBoxId)) {
          contentBoxesOrder.push(contentBoxId); // Add it to the end
          await updateDoc(classRef, { contentBoxesOrder });
          console.log(`Added content box ${contentBoxId} back to contentBoxesOrder.`);
        }
    
        console.log(`Moved content box ${contentBoxId} to active.`);
      } catch (error) {
        console.error("Error moving content box to active:", error);
      }
    };
    
    const moveMultipleContentBoxesToActive = async (contentBoxIds) => {
      try {
        const classId = props.current; // Get the current class ID
    
        if (!user.uid || !classId || !Array.isArray(contentBoxIds) || contentBoxIds.length === 0) {
          console.error("Missing required parameters or empty contentBoxIds list.");
          return;
        }
    
        const classRef = doc(db, `usersNew/${user.uid}/classes/${classId}`);
    
        // ✅ Step 1: Update "active" field in Firestore for all contentBoxes
        const batch = writeBatch(db);
        contentBoxIds.forEach((contentBoxId) => {
          const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
          batch.set(contentBoxRef, { active: true }, { merge: true });
        });
    
        await batch.commit();
        console.log("Updated active status for all selected content boxes.");
    
        // ✅ Step 2: Fetch the current class data to update `contentBoxesOrder`
        const classSnap = await getDoc(classRef);
        if (!classSnap.exists()) {
          console.error("Class not found.");
          return;
        }
    
        let classData = classSnap.data();
        let contentBoxesOrder = classData.contentBoxesOrder || [];
    
        // ✅ Step 3: Add contentBoxIds back to `contentBoxesOrder` if they’re not already there
        const updatedContentBoxesOrder = [...new Set([...contentBoxesOrder, ...contentBoxIds])];
    
        if (updatedContentBoxesOrder.length !== contentBoxesOrder.length) {
          await updateDoc(classRef, { contentBoxesOrder: updatedContentBoxesOrder });
          console.log(`Added ${contentBoxIds.length} content boxes back to contentBoxesOrder.`);
        }
    
        console.log(`Moved ${contentBoxIds.length} content boxes to active.`);
      } catch (error) {
        console.error("Error moving content boxes to active:", error);
      }
    };
    
    

  const [previewModal, setPreviewModal] = useState(false);
  const [previewBox, setPreviewBox] = useState();

  const toggleBoxPreview = (boxFromClick) => {
    hideAlert()
    setPreviewBox(boxFromClick)
    setPreviewModal(!previewModal)
  }

  function convertDateFormat(startDate, endDate) {
      if (Array.isArray(startDate)) {
        console.log(startDate.length)
        return startDate.length === 5 ? "Everyday" : startDate.join(", ")
      }
      // Split the input date string on "/"
      const startParts = startDate.split('-');
      const endParts = endDate.split('-');
      // Rearrange the parts to the desired format "MM/DD/YYYY"
      const newStart = `${startParts[1]}/${startParts[2]}/${startParts[0]}`;
      const newEnd = `${endParts[1]}/${endParts[2]}/${endParts[0]}`;
      return startDate === endDate ? newStart : `${newStart} - ${newEnd}`;
  }

  const [activeDatesModal, setActiveDatesModal] = useState(false);

  const [activeDates, setActiveDates] = useState();

  const toggleSetActiveDatesModal = (box) => {
    if (!activeDatesModal) {
      if (box) {
        setActiveDatesFromMulti(false)
        setActiveDates({
          startDate: box.startDate === undefined ? "" : box.startDate,
          endDate: box.endDate === undefined ? "" : box.endDate,
          contentBoxId: box.contentBoxId
        })
      } else {
        setActiveDatesFromMulti(true)
        setActiveDates({
          startDate: "",
          endDate: "",
        })
      }
      setActiveDatesModal(!activeDatesModal)
    } else {
      setActiveDatesModal(!activeDatesModal)
      setActiveDatesFromMulti(null)
      setActiveDatesFromActiveTable(null)
    }
  }

  //This is used to track which version of boxesForMultiSelect should be used in the saveContentBoxActiveDates function.
  const [activeDatesFromActiveTable, setActiveDatesFromActiveTable] = useState(null);

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
  if (activeDatesFromMulti) { //if the user clicked the set active dates button in the multi select dropdown
    let boxesToChange = activeDatesFromActiveTable ? boxesForMultiSelect : boxesForMultiSelectStorage;
    try {
      const classId = props.current; // Get the current class ID
  
      if (!user?.uid || !classId || !Array.isArray(boxesToChange) || boxesToChange.length === 0) {
        console.error("Missing required parameters or empty contentBoxIds list.");
        return;
      }
  
      const classRef = doc(db, `usersNew/${user.uid}/classes/${classId}`);
  
      // ✅ Step 1: Fetch class data to check existing contentBoxIds
      const classSnap = await getDoc(classRef);
      if (!classSnap.exists()) {
        console.error("Class not found.");
        return;
      }
  
      let classData = classSnap.data();
      let contentBoxesOrder = classData.contentBoxesOrder || [];
  
      // ✅ Step 2: Update active dates for all contentBoxes
      const batch = writeBatch(db);
      boxesToChange.forEach((contentBoxId) => {
        const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
        batch.set(contentBoxRef, {
          startDate: datesFromSetActiveDatesModal.startDate,
          endDate: datesFromSetActiveDatesModal.endDate
        }, { merge: true });
      });
  
      await batch.commit();
      console.log("Updated active dates for all selected content boxes.");
  
      // ✅ Step 3: Add missing contentBoxIds to Firestore (if not already in contentBoxesOrder)
      const updatedContentBoxesOrder = [...new Set([...contentBoxesOrder, ...boxesToChange])];
  
      if (updatedContentBoxesOrder.length !== contentBoxesOrder.length) {
        await updateDoc(classRef, { contentBoxesOrder: updatedContentBoxesOrder });
        console.log(`Added missing content boxes to contentBoxesOrder.`);
      }
  
      console.log(`Updated active dates for ${boxesToChange.length} content boxes.`);
    } catch (error) {
      console.error("Error updating content box active dates:", error);
    }

  } else { //if the user clicked the set active dates button for a single content box

    try {
      const classId = props.current; // Get the current class ID
      const contentBoxId = activeDates?.contentBoxId; // Get the content box ID
  
      if (!user?.uid || !classId || !contentBoxId) {
        console.error("Missing required parameters.");
        return;
      }
  
      // Reference to the specific content box document in Firestore
      const contentBoxRef = doc(db, `usersNew/${user.uid}/classes/${classId}/contentBoxes/${contentBoxId}`);
  
      // Update only the `startDate` and `endDate` fields
      await setDoc(contentBoxRef, {
        startDate: datesFromSetActiveDatesModal.startDate,
        endDate: datesFromSetActiveDatesModal.endDate,
      }, { merge: true });
  
      console.log(`Updated active dates for content box ${contentBoxId}`);
  
    } catch (error) {
      console.error("Error updating content box active dates:", error);
    }
  }

  // Toggle the modal after update
  setActiveDatesModal((prev) => !prev);
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy((prev) => 
    prev.includes(classId) ? prev.filter(id => id !== classId) : [...prev, classId]
  );
};

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    const classId = props.current

    const multiBox = false //multiBox is only applicable if you're copying a box out of a multiBox - this would be copying an entire multiBox.

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };

          // If the box to copy is a multiBox, create new contentBoxId's for each of the contentBoxes within the multiBox.
          if (newContentBox.multiBox && Array.isArray(newContentBox.content)) {
            newContentBox.content = newContentBox.content.map(item => ({
              ...item,
              contentBoxId: createRandomId(),
            }));
          }
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};


const sortByLastEdited = (boxes) => {
  return [...boxes].sort((a, b) => {
    const dateA = new Date(a.lastEdited);
    const dateB = new Date(b.lastEdited);

    if (sortOrder === "asc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });
};

const sortByDatesActive = (boxes) => {
  return [...boxes].sort((a, b) => {
    const dateA = a.startDate ? new Date(a.startDate) : null;
    const dateB = b.startDate ? new Date(b.startDate) : null;

    if (datesSortOrder === "asc") {
      if (!dateA) return 1; // Undefined or null dates go to the end
      if (!dateB) return -1;
      return dateA - dateB;
    } else {
      if (!dateA) return -1; // Undefined or null dates go to the start
      if (!dateB) return 1;
      return dateB - dateA;
    }
  });
};

const handleSortByLastEdited = () => {
  const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
  setSortOrder(newSortOrder);

  // Sort and update state
  if (activeBoxes) setActiveBoxes(sortByLastEdited(activeBoxes));
  if (storageBoxes) setStorageBoxes(sortByLastEdited(storageBoxes));
};

const handleSortByDatesActive = () => {
  const newSortOrder = datesSortOrder === "asc" ? "desc" : "asc";
  setDatesSortOrder(newSortOrder);

  // Sort and update state
  if (activeBoxes) setActiveBoxes(sortByDatesActive(activeBoxes));
  if (storageBoxes) setStorageBoxes(sortByDatesActive(storageBoxes));
};


  return (
    <>
      <div className="content">
        <Modal contentClassName="content-box-preview-modal" isOpen={previewModal} toggle={toggleBoxPreview}>
          <ModalHeader toggle={toggleBoxPreview}/>
          <ModalBody >
            {previewBox && (
              previewBox.slideshow ? (
                <GoogleSlidesBox content={previewBox} user={user}/>
              ) : (previewBox.timer || previewBox.stopwatch) ? (
                <TimerStopwatchContentBox content={previewBox} />
              ) : (previewBox.agendaBox) ? (
                <AgendaBox content={previewBox} user={user}/>
              ) : (previewBox.canvaBox) ? (
                <CanvaBox content={previewBox} user={user}/>
              ) : (previewBox.multiBox) ? (
                <MultiBox content={previewBox} classId={{current: 1}} user={user}/>
              ) : (previewBox.countdown) ? (
                <CountdownBox content={previewBox} user={user}/>
              ) : (previewBox.googleDocSheet) ? (
                <GoogleDocSheetBox content={previewBox} user={user}/>
              ) : (previewBox.pdf) ? (
                <PdfBox content={previewBox} user={user}/>
              ) : (previewBox.iFrame) ? (
                <IframeBox content={previewBox} user={user}/>
              ) : (
                <ContentBox content={previewBox} user={user}/>
              )
            )}
          </ModalBody>
        </Modal>
    
        <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
          <ModalBody>
            <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
              Copy box to:
            </h3>

            {user && classes && (
              <Row>
                  <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
                    {classes.map((x, index) => (
                      <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                        <Label check>
                          <Input
                            type="checkbox"
                            defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                            onChange={() => handleCheckboxChange(x.classId)}
                          />
                          <span className="form-check-sign" />
                          {x.className}
                        </Label>
                      </FormGroup>
                    ))}
                  </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
            <Button 
              onClick={toggleCopyBoxModal}
              className="delete-button"
              style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
            >
              Cancel
            </Button>
            <Button 
              className="button-hover-fix"
              disabled={classesSelectedForContentBoxCopy.length < 1} 
              onClick={() => copyBoxToClasses()} 
              style={{borderRadius: '30px'}}>
              <i className="fa fa-copy" />{' '}Copy
            </Button>
          </ModalFooter>
        </Modal>

        { activeDates &&
          <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={saveContentBoxActiveDates}/>
        }

                <Row>
                    <Col>
                      <Card>
                        <CardHeader style={{padding: '0px 20px'}}>
                          <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Active Boxes</CardTitle>
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are the boxes that will appear on your "Present" screen on the selected date.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are the boxes that will appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody style={{padding: '0px 10px'}}>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left content-box-table-header" style={{width: '6%', padding: '0px'}}>
                                    <UncontrolledDropdown group style={{width: '100%'}}>
                                        <div className="multi-select-hover-div" style={{width: '65%',  padding: '14px 16px 12px 16px'}}>
                                          <Input
                                            type="checkbox"
                                            // style={{ transform: "scale(1.5)", marginTop: '13px', marginLeft: '16px'}}
                                            style={{ transform: "scale(1.5)"}}
                                            innerRef={headerCheckboxRef} // ✅ Reference for indeterminate state
                                            checked={boxesForMultiSelect?.length === activeBoxes?.length && activeBoxes?.length > 0}
                                            onChange={handleSelectAll}
                                          />
                                        </div>
                                        <div className="multi-select-hover-div" style={{width: '35%', paddingTop: '5px'}}> 
                                          <DropdownToggle
                                            caret
                                            className="btn btn-neutral"
                                            color="secondary"
                                            style={{ padding: "0px", margin: '0px' }}
                                          />
                                        </div>

                                      {/* ✅ Dropdown Menu with Actions */}
                                      <DropdownMenu>
                                        {user.settings.useActiveDates ? (
                                          <DropdownItem disabled={boxesForMultiSelect.length === 0} onClick={() => {toggleSetActiveDatesModal(); setActiveDatesFromActiveTable(true)}}>
                                            <i className="fa fa-calendar" style={{ marginRight: "5px" }} />
                                            Set Active Dates for {boxesForMultiSelect?.length} {boxesForMultiSelect?.length === 1 ? "Box" : "Boxes"}
                                          </DropdownItem>
                                        ) : (
                                          <DropdownItem disabled={boxesForMultiSelect.length === 0} onClick={() => alertForMultiStorage()}>
                                            <i className="nc-icon nc-box" style={{ marginRight: "5px" }} />
                                            Move {boxesForMultiSelect?.length} {boxesForMultiSelect?.length === 1 ? "Box" : "Boxes"} to Storage
                                          </DropdownItem>
                                        )}
                                        {/* <DropdownItem disabled={boxesForMultiSelect.length === 0} onClick={() => window.alert("Coming soon.")}>
                                          <i className="fa fa-copy" style={{ marginRight: "5px" }} /> Copy <Badge>Coming Soon</Badge>
                                        </DropdownItem> */}
                                        <DropdownItem disabled={boxesForMultiSelect.length === 0} onClick={() => warningForMultiDelete()}>
                                          <i className="fa fa-trash icon-delete" style={{ marginRight: "5px", color: "red" }} /> 
                                          Delete {boxesForMultiSelect?.length} {boxesForMultiSelect?.length === 1 ? "Box" : "Boxes"}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                </th>
                                <th className="text-left" id="Heading" style={{width: '15%'}}>Heading</th>
                                <th className="text-left" id="Content" style={{width: '15%'}}>Content</th>
                                {user.settings.useActiveDates && <th className="text-center" id="Active" style={{width: '15%'}}>Dates Active</th> }
                                <th className="text-center" id="Name" style={{width: '10%'}}>Last Edited</th>
                                <th className="text-center" id="Order" style={{width: '10%'}}>Order</th>
                                <th className="text-center" style={{width: '20%'}}>Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"Heading"}>The name you've chosen for this box.</UncontrolledTooltip>
                            </thead>
                            {activeBoxes && activeBoxes.map((item, index) => {
                            return(
                            <tbody key={index} className="content-box-table-body">
                              <tr>
                                <td className="text-left" style={{ verticalAlign: 'middle' }}>
                                  <Input 
                                    type="checkbox" 
                                    style={{ transform: "scale(1.5)", marginLeft: '9px', marginTop: '0px', bottom: '18px' }} 
                                    checked={boxesForMultiSelect.includes(item.contentBoxId)}
                                    onChange={() => handleMultiSelectCheckboxChange(item.contentBoxId)}
                                  />
                                </td>
                                <td className="text-left">{item.heading}</td>
                                <td className="text-left">{item.contentPreview}</td>
                                {user.settings.useActiveDates && 
                                  <td className="text-center">
                                    {
                                      item?.startDate === "2000-01-02" && item?.endDate === "2099-12-30" ? "Everday" :
                                      item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) :  
                                      'n/a'
                                    }
                                  </td>
                                }
                                <td className="text-center">{item.lastEdited}</td>
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    disabled={index===0}
                                    onClick={() => updateContentBoxOrder(item.contentBoxId, index-1, "up")}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    disabled={index===activeBoxes.length-1}
                                    onClick={() => updateContentBoxOrder(item.contentBoxId, index+1, "down")}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip946942735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleBoxPreview(item)}
                                  >
                                    <i className="nc-icon nc-zoom-split" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip946942735"
                                  >
                                    Preview
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip153942735"
                                        size="md"
                                        type="button"
                                        onClick={() => moveToStorage(item)}
                                      >
                                        <i className="nc-icon nc-box" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip153942735"
                                      >
                                        Move to Storage
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip3840209385"
                                        size="md"
                                        type="button"
                                        onClick={() => {setBoxToCopyId(item.contentBoxId); toggleCopyBoxModal()}}
                                      >
                                        <i className="fa fa-copy" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip3840209385"
                                      >
                                        Duplicate Box
                                      </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.contentBoxId)}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                        </CardBody>
                        <CardHeader>
                          <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Boxes Storage</CardTitle>
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are boxes that are in storage and will not appear on your "Present" screen.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are boxes that are in storage and will not appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                              <th className="text-left content-box-table-header" style={{width: '6%', padding: '0px'}}>
                                    <UncontrolledDropdown group style={{width: '100%'}}>
                                        <div className="multi-select-hover-div" style={{width: '65%',  padding: '14px 16px 12px 16px'}}>
                                          <Input
                                            type="checkbox"
                                            // style={{ transform: "scale(1.5)", marginTop: '13px', marginLeft: '16px'}}
                                            style={{ transform: "scale(1.5)"}}
                                            innerRef={headerCheckboxStorageRef} // ✅ Reference for indeterminate state
                                            checked={boxesForMultiSelectStorage?.length === storageBoxes?.length && storageBoxes?.length > 0}
                                            onChange={handleSelectAllStorage}
                                          />
                                        </div>
                                        <div className="multi-select-hover-div" style={{width: '35%', paddingTop: '5px'}}> 
                                          <DropdownToggle
                                            caret
                                            className="btn btn-neutral"
                                            color="secondary"
                                            style={{ padding: "0px", margin: '0px' }}
                                          />
                                        </div>

                                      {/* ✅ Dropdown Menu with Actions */}
                                      <DropdownMenu>
                                      {user.settings.useActiveDates ? (
                                          <DropdownItem disabled={boxesForMultiSelectStorage.length === 0} onClick={() => {toggleSetActiveDatesModal(); setActiveDatesFromActiveTable(false)}}>
                                            <i className="fa fa-calendar" style={{ marginRight: "5px" }} />
                                            Set Active Dates for {boxesForMultiSelectStorage?.length} {boxesForMultiSelectStorage?.length === 1 ? "Box" : "Boxes"}
                                          </DropdownItem>
                                        ) : (
                                          <DropdownItem disabled={boxesForMultiSelectStorage.length === 0} onClick={() => alertForMultiActive()}>
                                            <i className="nc-icon nc-box" style={{ marginRight: "5px" }} />
                                            Move {boxesForMultiSelectStorage?.length} {boxesForMultiSelectStorage?.length === 1 ? "Box" : "Boxes"} to Active
                                          </DropdownItem>
                                        )}
                                        {/* <DropdownItem disabled={boxesForMultiSelect.length === 0} onClick={() => window.alert("Coming soon.")}>
                                          <i className="fa fa-copy" style={{ marginRight: "5px" }} /> Copy <Badge>Coming Soon</Badge>
                                        </DropdownItem> */}
                                        <DropdownItem disabled={boxesForMultiSelectStorage.length === 0} onClick={() => warningForMultiDelete()}>
                                          <i className="fa fa-trash icon-delete" style={{ marginRight: "5px", color: "red" }} /> 
                                          Delete {boxesForMultiSelectStorage?.length} {boxesForMultiSelectStorage?.length === 1 ? "Box" : "Boxes"}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                </th>
                                <th className="text-center" id="Heading">Heading</th>
                                <th className="text-center" id="Content">Content</th>
                              {user.settings.useActiveDates && 
                                <th
                                  className="text-center"
                                  id="Active"
                                  onClick={handleSortByDatesActive}
                                  style={{ cursor: "pointer" }}
                                >
                                  Dates Active {datesSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                              }
                                <th
                                  className="text-center"
                                  id="Name"
                                  onClick={handleSortByLastEdited}
                                  style={{ cursor: "pointer" }}
                                >
                                  Last Edited {sortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th className="text-center" id="Order">Order</th>
                                <th className="text-center">Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"Heading"}>The name you've chosen for this box.</UncontrolledTooltip>
                              {/* <UncontrolledTooltip delay={0} target={"Giraffe"}>If checked, Class will be available in the Present Classes dropdown.</UncontrolledTooltip> */}
                            </thead>
                            {storageBoxes && storageBoxes.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-left" style={{ verticalAlign: 'middle' }}>
                                  <Input 
                                    type="checkbox" 
                                    style={{ transform: "scale(1.5)", marginLeft: '9px', marginTop: '0px', bottom: '18px' }} 
                                    checked={boxesForMultiSelectStorage.includes(item.contentBoxId)}
                                    onChange={() => handleMultiSelectCheckboxChangeStorage(item.contentBoxId)}
                                  />
                                </td>
                                <td className="text-center">{item.heading}</td>
                                <td className="text-center">{item.contentPreview}</td>
                                {user.settings.useActiveDates && <td className="text-center">{item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) : 'n/a'}</td> }
                                <td className="text-center">{item.lastEdited}</td>
                                {/* <td className="text-center">{item.active ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td> */}
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    disabled
                                    onClick={() => handleMoveUp(index)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    disabled
                                    onClick={() => handleMoveDown(index)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip946942735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleBoxPreview(item)}
                                  >
                                    <i className="nc-icon nc-zoom-split" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip946942735"
                                  >
                                    Preview
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip159182296"
                                        size="md"
                                        type="button"
                                        onClick={() => moveToActive(item)}
                                      >
                                        <i className="nc-icon nc-basket" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip159182296"
                                      >
                                        Move to Active
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip3840209385"
                                    size="md"
                                    type="button"
                                    onClick={() => {setBoxToCopyId(item.contentBoxId); toggleCopyBoxModal()}}
                                  >
                                    <i className="fa fa-copy" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip3840209385"
                                  >
                                    Duplicate Box
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.contentBoxId)}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default ContentBoxesTable;
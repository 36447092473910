import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { Input, Row, Col, Button, Label, FormGroup, Card, CardBody } from "reactstrap";
import AgendaBox from "components/ContentBoxes/AgendaBox";
import GoogleSlidesBox from "components/ContentBoxes/GoogleSlidesBox";
import CanvaBox from "components/ContentBoxes/CanvaBox";
import ReorderContentBoxModal from "components/ReorderContentBoxModal";
import MultiBox from "components/ContentBoxes/MultiBox";
import CountdownBox from "components/ContentBoxes/CountdownBox";
import ContentBox from "components/ContentBoxes/ContentBox";
import ContentBoxForPrintView from "./ContentBoxes/ContentBoxForPrintView";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import TipTapEditor from "./TipTapEditor";
import rocket from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad-rocket.png"
import ReactDatetime from "react-datetime";
import GoogleDocSheetBox from "components/ContentBoxes/GoogleDocSheetBox";
import PdfBox from "components/ContentBoxes/PdfBox";
import { selectClassContentBoxes } from "features/userSlice";

function PrintView() {
  const user = useSelector(selectUser);
  const classes = useSelector((state) => state.user.classes);
  const [selectedClass, setSelectedClass] = useState('');
  const selectedClassContentBoxes = useSelector((state) =>
  selectedClass ? selectClassContentBoxes(selectedClass)(state) : []
  );
  const [contentBoxesForSelectedClass, setContentBoxesForSelectedClass] = useState([])
  const [classToShow, setClassToShow] = useState([]);
  const [localBoxes, setLocalBoxes] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const classIdRef = useRef(selectedClass || null);
  const navbarMD = {logo: '0', marquee: '0', clock: '0'};
  const view = 'row';
  const numberOfActiveBoxes = classToShow && classToShow?.contentBoxes?.filter((box) => box.show === true).length
  const [printActiveIndex, setPrintActiveIndex] = useState()
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
  const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const suffix = ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });
  const [sections, setSections] = useState({
    heading: {active: true, name: 'Heading', edit: false},
    announcements: {active: true, name: 'Announcements:', edit: false},
    content1: {active: true, name: 'Content:', edit: false},
    content2: {active: false, name: 'Additional Content:', edit: false},
    notes: {active: true, name: 'Notes:', edit: false},
    additional: {active: false, name: 'Additional:', edit: false},
  })
  const [initialValueNotes, setInitialValueNotes] = useState()
  const [notes, setNotes] = useState();
  const [initialValueAdditional, setInitialValueAdditional] = useState()
  const [additional, setAdditional] = useState();

  // Load sections from local storage when a class is selected
  useEffect(() => {
    if (selectedClass) {
      const savedSections = localStorage.getItem(`sections_${selectedClass}`);
      if (savedSections) {
        setSections(JSON.parse(savedSections));
      }
    }
    setInitialValueNotes( x => {
        if (selectedClass) {
            const savedNotes = localStorage.getItem(`notes_${selectedClass}`);
            if (savedNotes !== null && savedNotes !== "undefined") {
                return JSON.parse(savedNotes);
            } else {
                return {content: {
                    "type": "doc",
                    "content": [
                        {
                            "type": "paragraph",
                            "attrs": {
                                "textAlign": "left"
                            },
                            "content": [
                                {
                                    "text": "Type here...",
                                    "type": "text",
                                    "marks": [
                                        {
                                            "type": "textStyle",
                                            "attrs": {
                                                "fontSize": "14px",
                                                "color": null,
                                                "fontFamily": null,
                                                "lineHeight": null
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                    ]
                }}
            }
        }
      })
      setInitialValueAdditional( x => {
        if (selectedClass) {
            const savedAdditional = localStorage.getItem(`additional_${selectedClass}`);
            if (savedAdditional !== null && savedAdditional !== "undefined") {
                return JSON.parse(savedAdditional);
            } else {
                return {content: {
                    "type": "doc",
                    "content": [
                        {
                            "type": "paragraph",
                            "attrs": {
                                "textAlign": "left"
                            },
                            "content": [
                                {
                                    "text": "Type here...",
                                    "type": "text",
                                    "marks": [
                                        {
                                            "type": "textStyle",
                                            "attrs": {
                                                "fontSize": "14px",
                                                "color": null,
                                                "fontFamily": null,
                                                "lineHeight": null
                                            }
                                        }
                                    ]
                                }
                            ]
                        },
                    ]
                }}
            }
        }
      })
  }, [selectedClass]);

  // Save sections to local storage whenever sections change and no sections are being edited
  useEffect(() => {
    if (selectedClass) {
      const sectionsToSave = { ...sections };
  
      // Prevent saving on every new character while name property is being edited
      const allNotEditing = Object.values(sections).every(section => !section.edit);
      if (allNotEditing) {
        localStorage.setItem(`sections_${selectedClass}`, JSON.stringify(sectionsToSave));
      }

      if (selectedClassContentBoxes.length) { //This check is to prevent infinite renders when the class has no content boxes
        // ✅ Update state only if it has changed to prevent infinite renders
        setContentBoxesForSelectedClass(prevState => 
            JSON.stringify(prevState) !== JSON.stringify(selectedClassContentBoxes)
            ? selectedClassContentBoxes
            : prevState
        );
      }
    }
  }, [sections, selectedClass, selectedClassContentBoxes]);

  useEffect(() => {
    setClassToShow(classes?.find((x) => x.classId === selectedClass));
    let filteredLocalBoxes;
    if (classToShow && user.settings.useActiveDates) {
        filteredLocalBoxes = classToShow && contentBoxesForSelectedClass && contentBoxesForSelectedClass.filter((box) => {
            if (box.startDate === undefined) {
                return true;
              } else if (Array.isArray(box.startDate)) {
                if (box.startDate.length > 0) {
                  if (box.startDate.includes(date.dayNamePlural)) return true
                }
            } else {
                const startDate = new Date(box.startDate);
                startDate.setHours(0, 0, 0, 1); // Normalize start date
                const endDate = new Date(box.endDate);
                endDate.setHours(24); // Adjust end date to include the whole day
                const dayToCompare = new Date(date.year, date.month, date.day)
                dayToCompare.setHours(0,0,0,0)
                return startDate <= dayToCompare && endDate >= dayToCompare || box.startDate === undefined;
            }
        })
    } else {
        filteredLocalBoxes = classToShow && contentBoxesForSelectedClass && [...contentBoxesForSelectedClass]
    }
    setLocalBoxes(classToShow && filteredLocalBoxes);

    // Filter announcements based on classIds
    const filteredAnnouncements = user.announcements.filter(announcement => announcement.classIds.includes(selectedClass));

    let activeAnnouncements;

    if (user.settings?.useActiveDates === true) {
        activeAnnouncements = filteredAnnouncements.filter((announcement) => {
            if (announcement.startDate === undefined) {
                return true;
              } else if (Array.isArray(announcement.startDate)) {
                if (announcement.startDate.length > 0) {
                  if (announcement.startDate.includes(date.dayNamePlural)) return true
                }
            } else {
                const startDate = new Date(announcement.startDate);
                startDate.setHours(0, 0, 0, 1); // Normalize start date
                const endDate = new Date(announcement.endDate);
                endDate.setHours(24); // Adjust end date to include the whole day
                const dayToCompare = new Date(date.year, date.month, date.day)
                dayToCompare.setHours(0,0,0,0)
                return startDate <= dayToCompare && endDate >= dayToCompare || announcement.startDate === undefined;
            }
        });
    } else {
    activeAnnouncements = filteredAnnouncements.filter((announcement) => announcement.active === true);
    }

    setAnnouncements(activeAnnouncements)

  }, [selectedClass, classToShow, date, classes]);

  const firstColumn = announcements.slice(0, 5);
  const secondColumn = announcements.length > 5 ? announcements.slice(5) : [];

  const handleClassSelect = (event) => {
    setInitialValueNotes('');
    setInitialValueAdditional('');
    setSelectedClass(event.target.value);
  };

  const handlePrint = (divId) => {
    const printContents = document.getElementById(divId).innerHTML;
    const originalContents = document.body.innerHTML;

    window.onbeforeprint = () => {
        document.body.innerHTML = printContents;
    };

    window.onafterprint = () => {
        location.reload()
    };

    window.print();
  };

  const highlightBox = (index) => {
    setPrintActiveIndex(index)
  }

  const changeLocalBoxShow = (index) => {
    let boxesCopy = [...localBoxes];
    boxesCopy[index] = { ...boxesCopy[index], show: !boxesCopy[index].show };
    setLocalBoxes(boxesCopy);
};

  const handleOnBlurNotes = () => {
    localStorage.setItem(`notes_${selectedClass}`, JSON.stringify(notes));
  }

  const handleOnBlurAdditional = () => {
    localStorage.setItem(`additional_${selectedClass}`, JSON.stringify(additional));
  }

  const today = new Date();
  const formattedDate = formatDate(today);

  const handleDateForward = () => {
    let dateObject = new Date(date.year, date.month, date.day)
    let nextDay = new Date(dateObject.getTime() + (24 * 60 * 60 * 1000)); // Adds one day
    let dateData = {
      dayName: `${daysLong[nextDay.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[nextDay.getMonth()]} ${nextDay.getDate()}${suffix[nextDay.getDate()]}, ${nextDay.getFullYear()}`,
      year: nextDay.getFullYear(),
      month: nextDay.getMonth(),
      day: nextDay.getDate(),
      databaseFormattedDate: nextDay.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  const handleDateBackward = () => {
    let dateObject = new Date(date.year, date.month, date.day)
    let previousDay = new Date(dateObject.getTime() - (24 * 60 * 60 * 1000)); // Adds one day
    let dateData = {
      dayName: `${daysLong[previousDay.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[previousDay.getMonth()]} ${previousDay.getDate()}${suffix[previousDay.getDate()]}, ${previousDay.getFullYear()}`,
      year: previousDay.getFullYear(),
      month: previousDay.getMonth(),
      day: previousDay.getDate(),
      databaseFormattedDate: previousDay.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  const handleDateSet = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  function formatDate(date) {
    const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  return (
    <div className="content">
        <br></br>
            <Row className="print-view">
                <Col
                    className="checkbox-radios"
                    md="2"
                    style={{
                        height: "100vh", // Full viewport height
                        position: "sticky",
                        top: "0", // Keeps it stuck to the top when scrolling
                        overflowY: "auto", // Allows scrolling if content exceeds viewport height
                        borderRight: "1px solid #ddd", // Optional: adds a divider
                    }}
                >
                    <Card>
                    <CardBody>
                        <h5>Options</h5>
                        <FormGroup>
                            <Label for="classSelect">
                                Select Class
                            </Label>
                            <Input
                                id="classSelect"
                                name="select"
                                type="select"
                                onChange={handleClassSelect}
                                className={selectedClass === '' ? "pulse-border" : null}
                                style={{borderRadius: '8px'}}
                            >
                                <option value="">Select a class</option>
                                {user &&
                                classes?.map((x) => (
                                    <option key={x.classId} value={x.classId}>
                                    {x.className}
                                    </option>
                                ))}
                            </Input>
                        </FormGroup>
                        {user.settings.useActiveDates && selectedClass !== '' && (
                        <FormGroup>
                            <Label for="startDate">
                                Date
                            </Label>
                            {/* <Input
                                id="startDate"
                                name="startDate"
                                type="date"
                                onChange={null}
                            >
                            </Input> */}
                            <div className="printDatePicker">
                            <ReactDatetime
                                // timeFormat={"h:mm:a"}
                                value={date}
                                // initialValue={date}
                                className="printDatePicker"
                                timeFormat={false}
                                closeOnSelect
                                closeOnClickOutside
                                onChange={(e) => handleDateSet(e)}
                                inputProps={{
                                    placeholder: "Time Picker Here"
                                }}
                                style={{borderRadius: '8px'}}
                            />
                            </div>
                        </FormGroup>
                        )}
                        {( selectedClass !== '' &&
                        <>
                        <FormGroup check>
                            <Label check>
                            <Input 
                                type="checkbox"
                                defaultChecked={sections.heading.active} 
                                value={sections.heading.active} 
                                onChange={() => setSections(prev => ({
                                    ...prev,
                                    heading: { ...prev.heading, active: !prev.heading.active }
                                  }))}
                            />
                            <span className="form-check-sign" />
                                {classToShow && classToShow.className} - {date.dayName}, {date.dateString}
                                { sections.heading.edit &&
                                    <Input 
                                    type="text"
                                    style={{zoom: '.8'}}
                                    defaultValue={sections.heading.name} 
                                    onChange={(e) => setSections(prev => ({
                                        ...prev,
                                        heading: { ...prev.heading, name: e.target.value }
                                      }))}
                                    />
                                }
                            </Label>
                            <i 
                                className={ sections.heading.edit ? "fa fa-check" : "fa fa-pencil"}
                                style={{marginLeft: '5px', cursor: 'pointer'}}
                                onClick={() => setSections(prev => ({
                                    ...prev,
                                    heading: { ...prev.heading, edit: !prev.heading.edit }
                                  }))}
                            />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                            <Input 
                                type="checkbox"
                                defaultChecked={sections.announcements.active} 
                                value={sections.announcements.active} 
                                onChange={() => setSections(prev => ({
                                    ...prev,
                                    announcements: { ...prev.announcements, active: !prev.announcements.active }
                                  }))}
                            />
                            <span className="form-check-sign" />
                                {!sections.announcements.edit && sections.announcements.name}
                                { sections.announcements.edit &&
                                    <Input 
                                    type="text"
                                    style={{zoom: '.8'}}
                                    defaultValue={sections.announcements.name} 
                                    onChange={(e) => setSections(prev => ({
                                        ...prev,
                                        announcements: { ...prev.announcements, name: e.target.value }
                                      }))}
                                    />
                                }
                            </Label>
                            <i 
                                className={ sections.announcements.edit ? "fa fa-check" : "fa fa-pencil"}
                                style={{marginLeft: '5px', cursor: 'pointer'}}
                                onClick={() => setSections(prev => ({
                                    ...prev,
                                    announcements: { ...prev.announcements, edit: !prev.announcements.edit }
                                  }))}
                            />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                            <Input 
                                type="checkbox"
                                defaultChecked={sections.content1.active} 
                                value={sections.content1.active} 
                                onChange={() => setSections(prev => ({
                                    ...prev,
                                    content1: { ...prev.content1, active: !prev.content1.active }
                                  }))}
                            />
                            <span className="form-check-sign" />
                                {!sections.content1.edit && sections.content1.name}
                                { sections.content1.edit &&
                                    <Input 
                                    type="text"
                                    style={{zoom: '.8'}}
                                    defaultValue={sections.content1.name} 
                                    onChange={(e) => setSections(prev => ({
                                        ...prev,
                                        content1: { ...prev.content1, name: e.target.value }
                                      }))}
                                    />
                                }
                            </Label>
                            <i 
                                className={ sections.content1.edit ? "fa fa-check" : "fa fa-pencil"}
                                style={{marginLeft: '5px', cursor: 'pointer'}}
                                onClick={() => setSections(prev => ({
                                    ...prev,
                                    content1: { ...prev.content1, edit: !prev.content1.edit }
                                  }))}
                            />
                        </FormGroup>
                        {/* <FormGroup check>
                            <Label check>
                            <Input 
                                type="checkbox"
                                defaultChecked={sections.content2.active} 
                                onChange={() => setSections(prev => ({
                                    ...prev,
                                    content2: { ...prev.content2, active: !prev.content2.active }
                                  }))}
                            />
                            <span className="form-check-sign" />
                                {!sections.content2.edit && sections.content2.name}
                                { sections.content2.edit &&
                                    <Input 
                                    type="text"
                                    style={{zoom: '.8'}}
                                    defaultValue={sections.content2.name} 
                                    onChange={(e) => setSections(prev => ({
                                        ...prev,
                                        content2: { ...prev.content2, name: e.target.value }
                                      }))}
                                    />
                                }
                            </Label>
                            <i 
                                className={ sections.content2.edit ? "fa fa-check" : "fa fa-pencil"}
                                style={{marginLeft: '5px', cursor: 'pointer'}}
                                onClick={() => setSections(prev => ({
                                    ...prev,
                                    content2: { ...prev.content2, edit: !prev.content2.edit }
                                  }))}
                            />
                        </FormGroup> */}
                        <FormGroup check>
                            <Label check>
                            <Input 
                                type="checkbox"
                                defaultChecked={sections.notes.active} 
                                value={sections.notes.active} 
                                onChange={() => setSections(prev => ({
                                    ...prev,
                                    notes: { ...prev.notes, active: !prev.notes.active }
                                  }))}
                            />
                            <span className="form-check-sign" />
                                {!sections.notes.edit && sections.notes.name}
                                { sections.notes.edit &&
                                    <Input 
                                    type="text"
                                    style={{zoom: '.8'}}
                                    defaultValue={sections.notes.name} 
                                    onChange={(e) => setSections(prev => ({
                                        ...prev,
                                        notes: { ...prev.notes, name: e.target.value }
                                      }))}
                                    />
                                }
                            </Label>
                            <i 
                                className={ sections.notes.edit ? "fa fa-check" : "fa fa-pencil"}
                                style={{marginLeft: '5px', cursor: 'pointer'}}
                                onClick={() => setSections(prev => ({
                                    ...prev,
                                    notes: { ...prev.notes, edit: !prev.notes.edit }
                                  }))}
                            />
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                            <Input 
                                type="checkbox"
                                defaultChecked={sections.additional.active} 
                                value={sections.additional.active} 
                                onChange={() => setSections(prev => ({
                                    ...prev,
                                    additional: { ...prev.additional, active: !prev.additional.active }
                                  }))}
                            />
                            <span className="form-check-sign" />
                                {!sections.additional.edit && sections.additional.name}
                                { sections.additional.edit &&
                                    <Input 
                                    type="text"
                                    style={{zoom: '.8'}}
                                    defaultValue={sections.additional.name} 
                                    onChange={(e) => setSections(prev => ({
                                        ...prev,
                                        additional: { ...prev.additional, name: e.target.value }
                                      }))}
                                    />
                                }
                            </Label>
                            <i 
                                className={ sections.additional.edit ? "fa fa-check" : "fa fa-pencil"}
                                style={{marginLeft: '5px', cursor: 'pointer'}}
                                onClick={() => setSections(prev => ({
                                    ...prev,
                                    additional: { ...prev.additional, edit: !prev.additional.edit }
                                  }))}
                            />
                        </FormGroup>
                        </>
                        )}
                    </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <h5>Toggle Boxes</h5>
                            {selectedClass && classToShow && localBoxes && localBoxes.map((box, index) => 
                                <FormGroup check>
                                <Label check onMouseOver={() => highlightBox(index)} onMouseLeave={() => highlightBox(null)}>
                                <Input type="checkbox" value={box.show} defaultChecked={box.show} onChange={() => changeLocalBoxShow(index)}/>
                                <span className="form-check-sign" />
                                {box.heading}
                                </Label>
                            </FormGroup>
                            )}
                        </CardBody>
                    </Card>
                    <Button 
                        className="button-hover-fix"
                        onClick={() => handlePrint("contentToPrint")} 
                        style={{
                            width: '100%',
                            borderRadius: "30px",
                            fontSize: "1.1rem",
                            fontWeight: "bold",
                        }}
                    >
                        <i className='fa fa-print' />
                        {' '}Print
                    </Button>
                </Col>
                <Col
                    md="10"
                    style={{
                        overflowY: "auto", // Enables scrolling
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                {user.settings.useActiveDates && <i className="nc-icon nc-minimal-left" onClick={() => handleDateBackward()} style={{marginRight: '5px', fontSize: '40px', cursor: 'pointer'}}/>}
                <div id="contentToPrint" style={{ backgroundColor: 'white', border: 'solid 1px', padding: '40px', width: '1200px', height: '1500px', position: 'relative' }}>
                    {sections.heading.active && <h2><b>{classToShow && classToShow.className} - {date.dayName}, {date.dateString}</b></h2>}
                    {sections.announcements.active && (
                        <>
                            <h4><b>{sections.announcements.name}</b></h4>
                            <Row>
                                <Col>
                                    <ul>
                                        {firstColumn.map((announcement) => (
                                            <li key={announcement.id} style={{ fontSize: '16px' }}>
                                                {announcement.active ? announcement.text : ''}
                                            </li>
                                        ))}
                                    </ul>
                                </Col>
                                {secondColumn.length > 0 && (
                                    <Col>
                                        <ul>
                                            {secondColumn.map((announcement) => (
                                                <li key={announcement.id} style={{ fontSize: '16px' }}>
                                                    {announcement.active ? announcement.text : ''}
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                )}
                            </Row>
                        </>
                    )}
                    {sections.content1.active && (
                        <>
                            <h4><b>Content:</b></h4>
                            <Row style={{ flex: 1, border: '2px solid lightgrey', marginLeft: '5px', marginRight: '5px', padding: '10px', borderRadius: '8px' }}>
                                <PanelGroup direction="horizontal" autoSaveId={`panelSaveId-${classIdRef?.current}`} style={{overflow: 'visible'}}>
                                {selectedClass && classToShow && localBoxes && localBoxes.map((box, index) => 
                                    box.show !== true ? (
                                        <></>
                                    )
                                    :
                                    <>
                                        <Panel 
                                            defaultSize={30}
                                            minSize={25}
                                            maxSize={75}
                                            style={{overflow: 'visible', position: 'relative'}}
                                            key={`panel-${classIdRef.current}-${index}`}
                                        >
                                        {box === undefined ?
                                            <></>
                                        : box.slideshow ?
                                            <>
                                                <GoogleSlidesBox
                                                key={`googleSlidesBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                view={view}
                                                user={user}
                                                classId={classIdRef.current}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false}   
                                                />
                                            </>
                                        : box.googleDocSheet ?
                                            <>
                                                <GoogleDocSheetBox
                                                key={`googleDocBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                view={view}
                                                user={user}
                                                classId={classIdRef.current}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false}   
                                                />
                                            </>
                                        : (box.timer || box.stopwatch) ? 
                                            <>
                                                <TimerStopwatchContentBox
                                                key={`timerStopwatchBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.agendaBox) ? 
                                            <>
                                                <AgendaBox
                                                key={`agendaBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.multiBox) ? 
                                            <>
                                                <MultiBox
                                                key={`multiBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.canva) ?
                                            <>
                                                <CanvaBox
                                                key={`canvaBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                         : (box.pdf) ?
                                            <>
                                                <PdfBox
                                                key={`pdfBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.countdown) ?
                                            <>
                                                <CountdownBox
                                                key={`countdownBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : 
                                            <>
                                                <ContentBox
                                                key={`contentBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                classId={classIdRef.current}
                                                user={user}
                                                navbarMD={navbarMD}
                                                view={view}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false}
                                                readOnly
                                                />
                                            </>
                                        }
                                        </Panel>
                                        {
                                        index+1 < numberOfActiveBoxes ? (
                                            <PanelResizeHandle key={`panelResize-${classIdRef.current}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10px'}}>
                                            <i className="fa fa-exchange" style={{margin: '-7px'}}></i>
                                            </PanelResizeHandle>
                                        ) : null
                                        }
                                    </>
                                )}
                                </PanelGroup>
                            </Row>
                        </>
                    )}
                    {sections.content2.active && (
                        <>
                            <h4><b>Content:</b></h4>
                            <Row style={{ flex: 1, border: '2px solid lightgrey', marginLeft: '5px', marginRight: '5px', padding: '10px', borderRadius: '8px' }}>
                                <PanelGroup direction="horizontal" autoSaveId={`panelSaveId-${classIdRef?.current}`} style={{overflow: 'visible'}}>
                                {selectedClass && classToShow && localBoxes && localBoxes.map((box, index) => 
                                    box.show !== true ? (
                                        <></>
                                    )
                                    :
                                    <>
                                        <Panel 
                                            defaultSize={30}
                                            minSize={25}
                                            maxSize={75}
                                            style={{overflow: 'visible', position: 'relative'}}
                                            key={`panel-${classIdRef.current}-${index}`}
                                        >
                                        {box === undefined ?
                                            <></>
                                        : box.slideshow ?
                                            <>
                                                <GoogleSlidesBox
                                                key={`googleSlidesBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                view={view}
                                                user={user}
                                                classId={classIdRef.current}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false}   
                                                />
                                            </>
                                        : (box.timer || box.stopwatch) ? 
                                            <>
                                                <TimerStopwatchContentBox
                                                key={`timerStopwatchBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.agendaBox) ? 
                                            <>
                                                <AgendaBox
                                                key={`agendaBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.multiBox) ? 
                                            <>
                                                <MultiBox
                                                key={`multiBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.canva) ?
                                            <>
                                                <CanvaBox
                                                key={`canvaBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.pdf) ?
                                            <>
                                                <PdfBox
                                                key={`pdfBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : (box.countdown) ?
                                            <>
                                                <CountdownBox
                                                key={`countdownBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                navbarMD={navbarMD}
                                                classId={classIdRef.current}
                                                view={view}
                                                user={user}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        : 
                                            <>
                                                <ContentBox
                                                key={`contentBox-${classIdRef.current}-${index}-${box.contentBoxId}`}
                                                content={box}
                                                classId={classIdRef.current}
                                                user={user}
                                                navbarMD={navbarMD}
                                                view={view}
                                                multiBox={false}
                                                printActive={index === printActiveIndex ? true : false} 
                                                />
                                            </>
                                        }
                                        </Panel>
                                        {
                                        index+1 < numberOfActiveBoxes ? (
                                            <PanelResizeHandle key={`panelResize-${classIdRef.current}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '10px'}}>
                                            <i className="fa fa-exchange" style={{margin: '-7px'}}></i>
                                            </PanelResizeHandle>
                                        ) : null
                                        }
                                    </>
                                )}
                                </PanelGroup>
                            </Row>
                        </>
                    )}
                    {initialValueNotes && sections.notes.active && (
                        <>
                            <h4><b>{sections.notes.name}</b></h4>
                            <Row>
                                <Row style={{ flex: 1, border: '2px solid lightgrey', marginLeft: '20px', marginRight: '20px', height: '200px', borderRadius: '8px' }}>
                                    <TipTapEditor setEditedContent={setNotes} initialValue={initialValueNotes.content} handleOnBlur={handleOnBlurNotes} settings={{defaultFontSize: '14px', defaultFontStyle: 'helvetica'}}/>
                                </Row>
                            </Row>
                        </>
                    )}
                    {initialValueAdditional && sections.additional.active && (
                        <>
                            <h4><b>{sections.additional.name}</b></h4>
                            <Row>
                                <Row style={{ flex: 1, border: '2px solid lightgrey', marginLeft: '20px', marginRight: '20px', height: '200px', borderRadius: '8px' }}>
                                <TipTapEditor setEditedContent={setAdditional} initialValue={initialValueAdditional.content} handleOnBlur={handleOnBlurAdditional} settings={{defaultFontSize: '14px', defaultFontStyle: 'helvetica'}}/>
                                </Row>
                            </Row>
                        </>
                    )}
                    <Row 
                        style={{
                            position: 'absolute',
                            bottom: 40,
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <img src={rocket} alt="lesson-launchpad" style={{ width: '15px', height: 'auto', marginRight: '5px' }}/>
                        <div><i>Created with Lesson Launchpad</i></div>
                        <img src={rocket} alt="lesson-launchpad" style={{ width: '15px', height: 'auto', marginLeft: '5px' }}/>
                    </Row>
                </div>
                {user.settings.useActiveDates && <i className="nc-icon nc-minimal-right" onClick={() => handleDateForward()} style={{marginLeft: '5px', fontSize: '40px', cursor: 'pointer'}}/>}
                </Col>
            </Row>
    </div>
  );
}

export default PrintView;
import {React, useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { doc, updateDoc, arrayRemove, arrayUnion, getDoc, setDoc, getDocs, writeBatch, collection } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { createRandomId } from "RandomId";
import TipTapEditor from "/Users/michaeltucker/Documents/coding-projects/client/src/components/TipTapEditor.js";
import ActiveDatesModal from "/Users/michaeltucker/Documents/coding-projects/client/src/components/ActiveDatesModal.js";
import ReactHtmlParser from 'react-html-parser';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  CardSubtitle,
  Table,
  CardBody,
  UncontrolledTooltip,
  Input,
  Container,
  FormGroup,
  Label
} from "reactstrap";
import Marquee from "react-fast-marquee";
import ActiveDatesDatePicker from "components/ActiveDatesDatePicker";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";

function AddEditAnnouncementForm(props) {

  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const classes = useSelector((state) => state.user.classes) || [];
  const classId = props?.location?.state?.classId || null;
  const classIndex = classes.findIndex((x) => x.classId === classId);
  const [alert, setAlert] = useState(null);
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  // const [orderOfAnnouncements, setOrderOfAnnouncements] = useState();
  const [announcements, setAnnouncements] = useState([]);
  const [announcementsStorage, setAnnouncementsStorage] = useState();
  const [editedAnnouncement, setEditedAnnouncement] = useState(null);
  const [addEditAnnouncementModal, setAddEditAnnouncementModal] = useState(false);
  const [announcementToEdit, setAnnouncementToEdit] = useState(null);
  const [classDataForMultiAdd, setClassDataForMultiAdd] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
  const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const suffix = ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });
  const today = new Date().toISOString().slice(0,10)
  const [dateCreatedSortOrder, setDateCreatedSortOrder] = useState("asc");
  const [lastEditedSortOrder, setLastEditedSortOrder] = useState("asc");
  const [activeDates, setActiveDates] = useState({ startDate: "", endDate: "", announcementId: null });
  const [activeDatesModal, setActiveDatesModal] = useState(false)

  // 🛑 **Ensure classId is valid**
  useEffect(() => {
    if (!classId) {
      console.warn("⚠️ Missing classId in props.location.state. Check routing logic.");
    }
  }, [classId]);

  useEffect(() => {
    if (editedAnnouncement) {
      handleAnnouncementChange();
    }
  }, [editedAnnouncement]);

  const hasCheckedForOrphans = useRef(false);

useEffect(() => {
    if (!hasCheckedForOrphans.current && user && classes.length > 0) {
        const orphanedAnnouncements = findOrphanedAnnouncements(user, classes);

        if (orphanedAnnouncements.length > 0) {
            console.warn("⚠️ Removing orphaned announcements:", orphanedAnnouncements);
            removeOrphanedAnnouncements(orphanedAnnouncements);
        }

        // ✅ Prevent future re-runs
        hasCheckedForOrphans.current = true;
    }
}, [user, classes]);


/**
 * Finds orphaned announcements that no longer exist in user.announcements 
 * or are missing the current classId in their classIds list.
 * 
 * @param {Object} user - The user object containing announcements.
 * @param {Array} classes - The array of class objects.
 * @returns {Array} - A list of orphaned announcement IDs.
 */
 const findOrphanedAnnouncements = (user, classes) => {
  if (!user?.announcements || !classes?.length) {
      console.error("❌ Missing user announcements or classes list.");
      return [];
  }

  const userAnnouncementsMap = new Map(user.announcements.map(ann => [ann.id, ann]));

  let orphanedAnnouncements = [];

  classes.forEach(cls => {
      if (!cls.orderOfAnnouncements || !Array.isArray(cls.orderOfAnnouncements)) return;

      cls.orderOfAnnouncements.forEach(announcementId => {
          const announcement = userAnnouncementsMap.get(announcementId);

          // Check if announcement exists and has the classId in its classIds list
          if (!announcement || !announcement.classIds.includes(cls.classId)) {
              orphanedAnnouncements.push(announcementId);
          }
      });
  });

  return orphanedAnnouncements;
};

const removeOrphanedAnnouncements = async (orphanedAnnouncements) => {
  if (!user?.uid || !orphanedAnnouncements.length) {
      console.error("❌ Missing user ID or no orphaned announcements to remove.");
      return;
  }

  try {
      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const classesRef = collection(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES);

      // 1️⃣ **Fetch User Data**
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
          console.error("❌ User document not found.");
          return;
      }

      let userData = userSnap.data();
      let updatedAnnouncements = userData.announcements.filter(ann => !orphanedAnnouncements.includes(ann.id));

      // ✅ Update user announcements (removing orphaned ones)
      await updateDoc(userRef, { announcements: updatedAnnouncements });

      // 2️⃣ **Fetch All Classes & Remove Orphaned Announcement IDs**
      const classesSnap = await getDocs(classesRef);
      let batch = writeBatch(db);

      classesSnap.forEach((classDoc) => {
          let classData = classDoc.data();
          let updatedOrder = classData.orderOfAnnouncements?.filter(id => !orphanedAnnouncements.includes(id)) || [];

          // ✅ Only update if changes were made
          if (updatedOrder.length !== classData.orderOfAnnouncements?.length) {
              batch.update(classDoc.ref, { orderOfAnnouncements: updatedOrder });
          }
      });

      // ✅ Commit batch updates
      await batch.commit();

      // ✅ Refresh user data after deletion
      await dispatch(fetchUserData(user.uid));

      console.log(`✅ Removed orphaned announcements:`, orphanedAnnouncements);
  } catch (error) {
      console.error("❌ Error removing orphaned announcements:", error);
  }
};


    // ************** OLD HANDLE SUBMIT REPLACED BY CHATGPT SOLUTION BELOW ADDED ON 2/12/25 ***************

  // const handleSubmit = async (announcementId) => {
  //   console.log("editedAnnouncement => ", editedAnnouncement);
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     if (!Array.isArray(userCopy.announcements)) {
  //       userCopy.announcements = [];
  //     }

  //     if (announcementToEdit.announcementId === 0) { //If new announcement
  //       announcementToEdit.announcement.classIds = classDataForMultiAdd.filter((data) => data.add === true).map((data) => data.classId);
  //       userCopy.announcements.push(announcementToEdit.announcement);
  //       if (!Array.isArray(userCopy.classes[classIndex].orderOfAnnouncements)) {
  //         userCopy.classes[classIndex].orderOfAnnouncements = [];
  //       } 
  //       userCopy.classes[classIndex].orderOfAnnouncements.push(announcementToEdit.announcement.id)
  //     } else {
  //       const foundAnnouncement = userCopy.announcements.find(announcement => announcement.id === announcementId);
  //       const foundAnnouncementIndex = userCopy.announcements.findIndex(announcement => announcement.id === announcementId);
  //       const classIds = classDataForMultiAdd.filter((data) => data.add === true).map((data) => data.classId);
  
  //       if (foundAnnouncement) {
  //         // Update the found announcement
  //         foundAnnouncement.html = announcementToEdit.announcement.html;
  //         foundAnnouncement.text = announcementToEdit.announcement.text;
  //         foundAnnouncement.active = announcementToEdit.announcement.active;
  //         foundAnnouncement.show = announcementToEdit.announcement.show;
  //         foundAnnouncement.lastEdited = formattedDate;
  //         foundAnnouncement.classIds = classIds;
  //         //These were missing previously...
  //         // foundAnnouncement.announcementId = announcementToEdit.id; //I'm pretty sure I added this by mistake...leaving for now but commenting out.
  //         foundAnnouncement.startDate = announcementToEdit.announcement.startDate;
  //         foundAnnouncement.endDate = announcementToEdit.announcement.endDate;

  //         //At some point I got anncouncementId and Id mixed up and accidentally added announcementId to announcements to instead of just Id.
  //         if (foundAnnouncement.hasOwnProperty("announcementId")) {
  //           delete foundAnnouncement.announcementId;
  //         }

  //         // Update each classes orderOfAnnouncementsArray
  //         classIds.forEach((classId) => {
  //           const classIndex = userCopy.classes.findIndex(x => x.classId === classId);
  //           const classObj = userCopy.classes[classIndex];
        
  //           // Check if editedAnnouncement.id already exists in the orderOfAnnouncements array
  //           if (!classObj.orderOfAnnouncements.includes(editedAnnouncement.id)) {
  //               // If it doesn't exist, push it
  //               classObj.orderOfAnnouncements.push(editedAnnouncement.id);
  //           }
  //       });

  //       // Fix for duplicate announcementId's that was causing inability to move announcements - 2/14/24
  //       classIds.forEach((classId) => {
  //         const classIndex = userCopy.classes.findIndex(x => x.classId === classId);
  //         const classObj = userCopy.classes[classIndex];
      
  //         // Remove duplicate occurrences of editedAnnouncement.id from orderOfAnnouncements array
  //         classObj.orderOfAnnouncements = classObj.orderOfAnnouncements.filter((announcementId, index, array) => {
  //             // Keep the first occurrence of editedAnnouncement.id and remove duplicates
  //             return array.indexOf(announcementId) === index;
  //         });
  //       });
  
  //       } else {
  //         console.log("Announcement not found for editing:", announcementToEdit);
  //       }
        
  //       userCopy.announcements[foundAnnouncementIndex] = foundAnnouncement;
  //     }

  //     console.log("userCopy before setDoc => ", userCopy)
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  
  //     successAlert();
  
  //   } catch (error) {
  //     console.error('Error updating document:', error);
  //   }
  // };

  // ************** NEW HANDLE SUBMIT BY CHATGPT ADDED ON 2/12/25 ***************
  // ADDENDUM: The issue was the announcements added to the Sample Class and the Add New Class function added announcements that were missing startDate and endDate.
//   const handleSubmit = async (announcementId) => {
//     try {
//         const docRef = doc(db, "users", user.uid);
//         const userCopy = JSON.parse(JSON.stringify(user));

//         // 🛑 Ensure `announcements` exists
//         if (!Array.isArray(userCopy.announcements)) {
//             userCopy.announcements = [];
//         }

//         // 🎯 Fetch classIds from UI state
//         const classIds = classDataForMultiAdd
//             .filter(data => data.add === true)
//             .map(data => data.classId);

//         if (announcementToEdit.announcementId === 0) { 
//             // 📌 **New Announcement**
//             const newAnnouncement = {
//                 ...announcementToEdit.announcement,
//                 classIds,
//                 startDate: announcementToEdit.announcement.startDate || ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
//                 endDate: announcementToEdit.announcement.endDate || "" // 🛠 Default to empty string if missing
//             };

//             // ✅ Add to announcements array
//             userCopy.announcements.push(newAnnouncement);

//             // 🛑 Ensure `orderOfAnnouncements` exists in class
//             if (!Array.isArray(userCopy.classes[classIndex].orderOfAnnouncements)) {
//                 userCopy.classes[classIndex].orderOfAnnouncements = [];
//             }

//             // ✅ Add to orderOfAnnouncements
//             userCopy.classes[classIndex].orderOfAnnouncements.push(newAnnouncement.id);

//         } else {
//             // 📌 **Editing an Existing Announcement**
//             const foundAnnouncementIndex = userCopy.announcements.findIndex(
//                 announcement => announcement.id === announcementId
//             );

//             if (foundAnnouncementIndex !== -1) {
//                 const foundAnnouncement = userCopy.announcements[foundAnnouncementIndex];

//                 // 🛑 Remove incorrect `announcementId` key if it exists
//                 if (foundAnnouncement.hasOwnProperty("announcementId")) {
//                     delete foundAnnouncement.announcementId;
//                 }

//                 // ✅ Update announcement properties safely
//                 userCopy.announcements[foundAnnouncementIndex] = {
//                     ...foundAnnouncement,
//                     html: announcementToEdit.announcement.html,
//                     text: announcementToEdit.announcement.text,
//                     active: announcementToEdit.announcement.active,
//                     show: announcementToEdit.announcement.show,
//                     lastEdited: formattedDate,
//                     classIds,
//                     startDate: announcementToEdit.announcement.startDate || ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"], // 🛠 Safe handling
//                     endDate: announcementToEdit.announcement.endDate || "" // 🛠 Safe handling
//                 };

//                 // ✅ Update `orderOfAnnouncements` for all associated classes
//                 classIds.forEach(classId => {
//                     const classIndex = userCopy.classes.findIndex(x => x.classId === classId);
//                     if (classIndex !== -1) {
//                         const classObj = userCopy.classes[classIndex];

//                         // 🛑 Ensure orderOfAnnouncements exists
//                         if (!Array.isArray(classObj.orderOfAnnouncements)) {
//                             classObj.orderOfAnnouncements = [];
//                         }

//                         // ✅ Add if not already present
//                         if (!classObj.orderOfAnnouncements.includes(announcementId)) {
//                             classObj.orderOfAnnouncements.push(announcementId);
//                         }

//                         // 🚨 Remove duplicate occurrences
//                         classObj.orderOfAnnouncements = [...new Set(classObj.orderOfAnnouncements)];
//                     }
//                 });
//             } else {
//                 console.error("🚨 Announcement not found for editing:", announcementToEdit);
//             }
//         }

//         // ✅ Save updated data to Firestore
//         await setDoc(docRef, userCopy);
//         successAlert();

//     } catch (error) {
//         console.error("❌ Error updating document:", error);
//     }
// };

const handleSubmit = async (announcementId) => {
  try {
      const docRef = doc(db, "usersNew", user.uid);
      
      // 🎯 Fetch classIds from UI state
      const classIds = classDataForMultiAdd
          .filter(data => data.add === true)
          .map(data => data.classId);

      if (announcementToEdit.announcementId === 0) { 
          // 📌 **New Announcement**
          const newAnnouncement = {
              ...announcementToEdit.announcement,
              classIds,
              startDate: announcementToEdit.announcement.startDate || ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
              endDate: announcementToEdit.announcement.endDate || "",
          };

          // ✅ Add new announcement using Firestore's `arrayUnion`
          await updateDoc(docRef, {
              announcements: arrayUnion(newAnnouncement),
          });

          // ✅ Update `orderOfAnnouncements` in each class document
          for (const classId of classIds) {
              const classRef = doc(db, "usersNew", user.uid, "classes", classId);
              await updateDoc(classRef, {
                  orderOfAnnouncements: arrayUnion(newAnnouncement.id),
              });
          }
      } else {
          // 📌 **Editing an Existing Announcement**
          const userSnap = await getDoc(docRef);
          if (!userSnap.exists()) {
              console.error("🚨 User not found in Firestore.");
              return;
          }

          const userData = userSnap.data();
          let updatedAnnouncements = [...(userData.announcements || [])];
          const foundAnnouncementIndex = updatedAnnouncements.findIndex(announcement => announcement.id === announcementId);

          if (foundAnnouncementIndex !== -1) {
              // ✅ Update existing announcement
              updatedAnnouncements[foundAnnouncementIndex] = {
                  ...updatedAnnouncements[foundAnnouncementIndex],
                  html: announcementToEdit.announcement.html,
                  text: announcementToEdit.announcement.text,
                  active: announcementToEdit.announcement.active,
                  show: announcementToEdit.announcement.show,
                  lastEdited: formattedDate,
                  classIds,
                  startDate: announcementToEdit.announcement.startDate || ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
                  endDate: announcementToEdit.announcement.endDate || "",
              };

              // ✅ Save updated announcements array
              await updateDoc(docRef, { announcements: updatedAnnouncements });

              // ✅ Update `orderOfAnnouncements` in each class
              for (const classId of classIds) {
                  const classRef = doc(db, "usersNew", user.uid, "classes", classId);
                  const classSnap = await getDoc(classRef);
                  if (classSnap.exists()) {
                      let classData = classSnap.data();
                      let updatedOrder = Array.isArray(classData.orderOfAnnouncements) ? [...classData.orderOfAnnouncements] : [];
                      
                      // ✅ Add announcement if not already in orderOfAnnouncements
                      if (!updatedOrder.includes(announcementId)) {
                          updatedOrder.push(announcementId);
                      }

                      // ✅ Remove duplicates and update Firestore
                      await updateDoc(classRef, { orderOfAnnouncements: [...new Set(updatedOrder)] });
                  }
              }
          } else {
              console.error("🚨 Announcement not found for editing:", announcementToEdit);
          }
      }

      successAlert();

      dispatch(fetchUserData(user.uid));

  } catch (error) {
      console.error("❌ Error updating document:", error);
  }
};




  useEffect(() => {
    setAnnouncements((prev) => sortAnnouncementsByDateCreated(prev));
    setAnnouncementsStorage((prev) => sortAnnouncementsByDateCreated(prev));
  }, [user, date, dateCreatedSortOrder, lastEditedSortOrder]);

  // const handleDeleteAnnouncement = async (announcementId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);

  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const announcementIndex = userCopy.announcements.findIndex(
  //       (a) => a.id === announcementId
  //     );

  //     // Update the announcements in the document data
  //     userCopy.announcements.splice(announcementIndex, 1)

  //     // Update the orderOfAnnouncements Array in the document data
  //     if (userCopy.classes[classIndex].orderOfAnnouncements.length > 1) {
  //       userCopy.classes[classIndex].orderOfAnnouncements = userCopy.classes[classIndex].orderOfAnnouncements.filter(id => id !== announcementId);
  //     } else {
  //       userCopy.classes[classIndex].orderOfAnnouncements = []
  //     }

  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //     successDelete()

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const handleMoveToStorage = async (announcementId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     const announcementIndex = userCopy.announcements.findIndex(
  //       (a) => a.id === announcementId
  //     );
  
  //     // Update the announcements in the document data
  //     userCopy.announcements[announcementIndex].active = false;
  
  //     // Update the orderOfAnnouncements Array in the document data
  //     userCopy.classes[classIndex].orderOfAnnouncements = userCopy.classes[classIndex].orderOfAnnouncements.filter(id => id !== announcementId);
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  
  // const handleMoveToActive = async (announcementId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     const announcementIndex = userCopy.announcements.findIndex(
  //       (a) => a.id === announcementId
  //     );
  
  //     // Update the announcements in the document data
  //     userCopy.announcements[announcementIndex].active = true;
  
  //     // Update the orderOfAnnouncements Array in the document data
  //     userCopy.classes[classIndex].orderOfAnnouncements.push(announcementId);
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
        
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // const handleMoveUp = async (announcementId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     const index = userCopy.classes[classIndex].orderOfAnnouncements.indexOf(announcementId);
  
  //     if (index > 0) {
  //       // Move the element one position forward
  //       const newArray = [...userCopy.classes[classIndex].orderOfAnnouncements];
  //       [newArray[index - 1], newArray[index]] = [newArray[index], newArray[index - 1]];
  //       userCopy.classes[classIndex].orderOfAnnouncements = newArray;
  //     }
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }
  
  // const handleMoveDown = async (announcementId) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     const index = userCopy.classes[classIndex].orderOfAnnouncements.indexOf(announcementId);
  
  //     if (index < userCopy.classes[classIndex].orderOfAnnouncements.length - 1) {
  //       // Move the element one position down
  //       const newArray = [...userCopy.classes[classIndex].orderOfAnnouncements];
  //       [newArray[index], newArray[index + 1]] = [newArray[index + 1], newArray[index]];
  //       userCopy.classes[classIndex].orderOfAnnouncements = newArray;
  //     }
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);
  
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

// 🗑️ **Delete Announcement**
// const handleDeleteAnnouncement = async (announcementId) => {
//     try {
//         if (!user?.uid || !classId || !announcementId) {
//             console.error("❌ Missing required parameters: User ID, Class ID, or Announcement ID.");
//             return;
//         }

//         const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
//         const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);

//         // 1️⃣ **Remove from user.announcements**
//         const userSnap = await getDoc(userRef);
//         if (!userSnap.exists()) {
//             console.error("❌ User document not found.");
//             return;
//         }

//         let userData = userSnap.data();
//         let updatedAnnouncements = (userData.announcements || []).filter(ann => ann.id !== announcementId);

//         // ✅ Update user document
//         await updateDoc(userRef, { announcements: updatedAnnouncements });

//         // 2️⃣ **Remove from class.orderOfAnnouncements**
//         const classSnap = await getDoc(classRef);
//         if (!classSnap.exists()) {
//             console.error("❌ Class document not found.");
//             return;
//         }

//         let classData = classSnap.data();
//         let updatedOrder = (classData.orderOfAnnouncements || []).filter(id => id !== announcementId);

//         // ✅ Update class document
//         await updateDoc(classRef, { orderOfAnnouncements: updatedOrder });

//         successDelete();
//         await dispatch(fetchUserData(user.uid)); // Refresh user state

//         console.log(`✅ Announcement ${announcementId} deleted successfully!`);
//     } catch (error) {
//         console.error("❌ Error deleting announcement:", error);
//     }
// };

// 🗑️ **Delete Announcement From One Class**
const handleDeleteAnnouncement = async (announcementId) => {
  try {
      if (!user?.uid || !classId || !announcementId) {
          console.error("❌ Missing required parameters: User ID, Class ID, or Announcement ID.");
          return;
      }

      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);

      // 1️⃣ **Fetch user document to get announcements**
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
          console.error("❌ User document not found.");
          return;
      }

      let userData = userSnap.data();
      let announcements = userData.announcements || [];

      // 🔍 Find the announcement by ID
      let announcementIndex = announcements.findIndex(ann => ann.id === announcementId);
      if (announcementIndex === -1) {
          console.error("❌ Announcement not found in user data.");
          return;
      }

      let announcement = announcements[announcementIndex];

      // ✅ Check how many classIds are assigned to this announcement
      if (announcement.classIds.length === 1) {
          // 🔹 If only one class uses this announcement, DELETE it completely
          announcements = announcements.filter(ann => ann.id !== announcementId);
      } else {
          // 🔹 If multiple classes use it, REMOVE only this classId
          announcement.classIds = announcement.classIds.filter(id => id !== classId);
          announcements[announcementIndex] = announcement;
      }

      // ✅ Update user document with modified announcements list
      await updateDoc(userRef, { announcements });

      // 2️⃣ **Remove the announcement from the class's orderOfAnnouncements**
      const classSnap = await getDoc(classRef);
      if (!classSnap.exists()) {
          console.error("❌ Class document not found.");
          return;
      }

      let classData = classSnap.data();
      let updatedOrder = (classData.orderOfAnnouncements || []).filter(id => id !== announcementId);

      // ✅ Update class document with modified orderOfAnnouncements
      await updateDoc(classRef, { orderOfAnnouncements: updatedOrder });

      successDelete();
      await dispatch(fetchUserData(user.uid)); // Refresh user state

      console.log(`✅ Announcement ${announcementId} updated/deleted successfully!`);
  } catch (error) {
      console.error("❌ Error deleting announcement:", error);
  }
};

// 🗑️ **Delete Announcement From ALL Classes**
const handleDeleteAnnouncementFromMultipleClasses = async (announcementId) => {
  try {
      if (!user?.uid || !announcementId) {
          console.error("❌ Missing required parameters: User ID or Announcement ID.");
          return;
      }

      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
          console.error("❌ User document not found.");
          return;
      }

      let userData = userSnap.data();
      let announcements = userData.announcements || [];

      // 🔍 Find the announcement to ensure it exists
      let announcement = announcements.find(ann => ann.id === announcementId);
      if (!announcement) {
          console.error("❌ Announcement not found in user data.");
          return;
      }

      // 🗑️ Remove the announcement completely
      announcements = announcements.filter(ann => ann.id !== announcementId);

      // ✅ Update user document with modified announcements list
      await updateDoc(userRef, { announcements });

      // 2️⃣ **Find all classes that reference this announcement**
      const classesRef = collection(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES);
      const classesSnap = await getDocs(classesRef);

      let batch = writeBatch(db);

      classesSnap.forEach((classDoc) => {
          let classData = classDoc.data();
          let updatedOrder = (classData.orderOfAnnouncements || []).filter(id => id !== announcementId);

          if (classData.orderOfAnnouncements.length !== updatedOrder.length) {
              // Only update the class if it had the announcement
              batch.update(classDoc.ref, { orderOfAnnouncements: updatedOrder });
          }
      });

      // ✅ Commit all class updates in one batch request
      await batch.commit();

      successDelete();
      await dispatch(fetchUserData(user.uid)); // Refresh user state

      console.log(`✅ Announcement ${announcementId} deleted from all classes.`);
  } catch (error) {
      console.error("❌ Error deleting announcement from multiple classes:", error);
  }
};


// 📦 **Move Announcement to Storage**
const handleMoveToStorage = async (announcementId) => {
  try {
      if (!user?.uid || !classId || !announcementId) return;

      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);

      // 1️⃣ **Update user.announcements to mark it inactive**
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
          console.error("❌ User document not found.");
          return;
      }

      let userData = userSnap.data();
      let updatedAnnouncements = (userData.announcements || []).map(ann => 
          ann.id === announcementId ? { ...ann, active: false } : ann
      );

      // ✅ Update user document
      await updateDoc(userRef, { announcements: updatedAnnouncements });

      // 2️⃣ **Remove from class.orderOfAnnouncements**
      const classSnap = await getDoc(classRef);
      if (!classSnap.exists()) {
          console.error("❌ Class document not found.");
          return;
      }

      let classData = classSnap.data();
      let updatedOrder = (classData.orderOfAnnouncements || []).filter(id => id !== announcementId);

      // ✅ Update class document
      await updateDoc(classRef, { orderOfAnnouncements: updatedOrder });

      await dispatch(fetchUserData(user.uid)); // Refresh user state

      console.log(`✅ Announcement ${announcementId} moved to storage!`);
  } catch (error) {
      console.error("❌ Error moving announcement to storage:", error);
  }
};


// 🔥 **Move Announcement to Active**
const handleMoveToActive = async (announcementId) => {
  try {
      if (!user?.uid || !classId || !announcementId) return;

      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);

      // 1️⃣ **Update user.announcements to mark it as active**
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
          console.error("❌ User document not found.");
          return;
      }

      let userData = userSnap.data();
      let updatedAnnouncements = (userData.announcements || []).map(ann => 
          ann.id === announcementId ? { ...ann, active: true } : ann
      );

      // ✅ Update user document
      await updateDoc(userRef, { announcements: updatedAnnouncements });

      // 2️⃣ **Add back to class.orderOfAnnouncements**
      const classSnap = await getDoc(classRef);
      if (!classSnap.exists()) {
          console.error("❌ Class document not found.");
          return;
      }

      let classData = classSnap.data();
      let updatedOrder = Array.from(new Set([...(classData.orderOfAnnouncements || []), announcementId]));

      // ✅ Update class document
      await updateDoc(classRef, { orderOfAnnouncements: updatedOrder });

      await dispatch(fetchUserData(user.uid)); // Refresh user state

      console.log(`✅ Announcement ${announcementId} moved to active!`);
  } catch (error) {
      console.error("❌ Error moving announcement to active:", error);
  }
};

// ⬆️ **Move Announcement Up**
const handleMoveUp = async (announcementId) => {
    try {
        if (!user?.uid || !classId || !announcementId) return;

        const classRef = doc(
            db,
            FIREBASE_COLLECTIONS.USERS,
            user.uid,
            FIREBASE_COLLECTIONS.CLASSES,
            classId
        );

        const classSnap = await getDoc(classRef);
        if (!classSnap.exists()) return;

        const classData = classSnap.data();
        const index = classData.orderOfAnnouncements.indexOf(announcementId);

        if (index > 0) {
            const newOrder = [...classData.orderOfAnnouncements];
            [newOrder[index - 1], newOrder[index]] = [newOrder[index], newOrder[index - 1]];

            await updateDoc(classRef, { orderOfAnnouncements: newOrder });
            await dispatch(fetchUserData(user.uid));

            console.log(`✅ Announcement ${announcementId} moved up!`);
        }
        dispatch(fetchUserData(user.uid))
    } catch (error) {
        console.error("❌ Error moving announcement up:", error);
    }
};

  // ⬇️ **Move Announcement Down**
  const handleMoveDown = async (announcementId) => {
    try {
        if (!user?.uid || !classId || !announcementId) return;

        const classRef = doc(
            db,
            FIREBASE_COLLECTIONS.USERS,
            user.uid,
            FIREBASE_COLLECTIONS.CLASSES,
            classId
        );

        const classSnap = await getDoc(classRef);
        if (!classSnap.exists()) return;

        const classData = classSnap.data();
        const index = classData.orderOfAnnouncements.indexOf(announcementId);

        if (index < classData.orderOfAnnouncements.length - 1) {
            const newOrder = [...classData.orderOfAnnouncements];
            [newOrder[index], newOrder[index + 1]] = [newOrder[index + 1], newOrder[index]];

            await updateDoc(classRef, { orderOfAnnouncements: newOrder });
            await dispatch(fetchUserData(user.uid));

            console.log(`✅ Announcement ${announcementId} moved down!`);
        }

        dispatch(fetchUserData(user.uid))
    } catch (error) {
        console.error("❌ Error moving announcement down:", error);
    }
  };

  const handleAnnouncementChange = () => {
    if (editedAnnouncement?.text?.length > 100) {
      announcementCharacterLimitAlert()
    } else {
      setAnnouncementToEdit({
        ...announcementToEdit, // Spread the top-level properties
        announcement: {
          ...announcementToEdit?.announcement, // Spread the existing properties of the nested announcement
          html: editedAnnouncement.html,
          text: editedAnnouncement.text
        }
      });
    }
  };

  const handleCheckboxClickForMultiAdd = (classId) => {
    setClassDataForMultiAdd((prev) =>
      prev.map((x) =>
        x.classId === classId ? { ...x, add: !x.add } : x
      )
    );
    setEditedAnnouncement({...announcementToEdit.announcement});
  };

  const toggleAnnouncementModal = (announcementId) => {
    hideAlert()
    if (announcementId != undefined) {
      const foundAnnouncement = user.announcements.find(announcement => announcement.id === announcementId);
      setEditedAnnouncement({...foundAnnouncement});
      setAnnouncementToEdit({announcementId: announcementId, announcement: foundAnnouncement});
      setActiveDates({startDate: foundAnnouncement?.startDate || '', endDate: foundAnnouncement?.endDate || ''})
      setClassDataForMultiAdd(classes.map((x) => ({ className: x.className, classId: x.classId, add: foundAnnouncement?.classIds?.includes(x.classId) ? true : false })))
    } else {
      setClassDataForMultiAdd(classes.map((x) => ({ className: x.className, classId: x.classId, add: x.classId === classId ? true : false })))
      setAnnouncementToEdit(
        {
          announcementId: 0,
          announcement:{
            // html: "",
            // text: "",
            active: true,
            show: true,
            lastEdited: formattedDate,
            dateCreated: formattedDate,
            classIds: classDataForMultiAdd.filter((data) => data.add === true).map((data) => data.classId),
            id: createRandomId(),
            startDate: date.databaseFormattedDate,
            endDate: date.databaseFormattedDate
          }
        }
      );
    }
    //reset classDataForMultiAdd to original state when modal gets closed.
    setAddEditAnnouncementModal(!addEditAnnouncementModal)
  }

  const warningWithConfirmAndCancelMessage = (announcement) => {

    if (announcement.classIds.length > 1) { //announcement belongs to multiple classes
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px", minWidth: '700px' }}
          title="This announcement belongs to multiple classes."
          onConfirm={console.log()}
          customButtons={
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                <Button
                  className="delete-button"
                  style={{borderRadius: '30px', width: '250px'}}
                  onClick={() => handleDeleteAnnouncementFromMultipleClasses(announcement.id)}
                >
                  Delete From <u>All</u> Classes
                </Button>
                <Button 
                  className="delete-button"
                  style={{borderRadius: '30px', width: '250px'}}
                  onClick={() => handleDeleteAnnouncement(announcement.id)}
                >
                  Delete From <u>THIS</u> Class
                </Button>
              </div>
              <Button className="button-hover-fix" style={{borderRadius: '30px'}} onClick={() => cancelDelete()}>Cancel</Button>
            </div>
          }          
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          How would you like to proceed?
        </ReactBSAlert>
      );
    } else { //announcement belongs to single class
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => handleDeleteAnnouncement(announcement.id)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info delete-button"
          confirmBtnStyle={{borderRadius: '30px', width: '200px'}}
          cancelBtnBsStyle="danger button-hover-fix"
          cancelBtnStyle={{borderRadius: '30px', width: '100px'}}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this announcement!
        </ReactBSAlert>
      );
    }
  };

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
        Your announcement has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info button-hover-fix"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
        Your announcement is safe :)
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Announcements Saved!"
        onConfirm={() => toggleAnnouncementModal()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const announcementCharacterLimitAlert = () => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Announcement too long!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        This announcement is too long. Consider splitting a longer announcement into multiple smaller announcements.
      </ReactBSAlert>
    );
  }

  const hideAlert = () => {
    setAlert(null);
  };

  // useEffect(() => {
  //   const currentOrderOfAnnouncements = classes[classIndex]?.orderOfAnnouncements;
  //   setOrderOfAnnouncements(currentOrderOfAnnouncements);
  
  //   let activeAnnouncements = [];
  //   let storageAnnouncements = [];
  
  //   const today = new Date(date.year, date.month, date.day);
  //   today.setHours(0, 0, 0, 0); // Normalize today's date
  
  //   if (user.settings.useActiveDates) {
  //     activeAnnouncements = user.announcements?.filter(announcement => {
  //       if (announcement.startDate === undefined && announcement.classIds.includes(classId)) {
  //           return true;
  //       } else if (Array.isArray(announcement.startDate)) {
  //         if (announcement.startDate.length > 0) {
  //           if (announcement.startDate.includes(date.dayNamePlural)) return true
  //         }
  //       } else if (announcement.startDate && announcement.endDate) {
  //           const [startYear, startMonth, startDay] = announcement.startDate.split('-');
  //           const [endYear, endMonth, endDay] = announcement.endDate.split('-');
  //           const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
  //           const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
  //           const dayToCompare = new Date(date.year, date.month, date.day, 0, 0, 0, 0);
  //           return announcement.classIds.includes(classId) && startDate <= dayToCompare && endDate >= dayToCompare;
  //       }
  //       return false; // If x.startDate or x.endDate is undefined, exclude the announcement.
  //   }) || [];
  
  //     storageAnnouncements = user.announcements?.filter(announcement => {
  //       if (announcement.startDate && announcement.endDate) {
  //         if (Array.isArray(announcement.startDate)) {
  //           if (announcement.startDate.length > 0) {
  //             if (!announcement.startDate.includes(date.dayNamePlural)) {
  //               return true
  //             }
  //           }
  //         } else {
  //           const [startYear, startMonth, startDay] = announcement.startDate?.split('-');
  //           const [endYear, endMonth, endDay] = announcement.endDate?.split('-');
  //           const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
  //           const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
  //           const dayToCompare = new Date(date.year, date.month, date.day, 0, 0, 0, 0)
  //           return announcement.classIds.includes(classId) && (startDate > dayToCompare || startDate < dayToCompare && endDate < dayToCompare);
  //         }
  //       }
  //     }) || [];
  //   } else {
  //     activeAnnouncements = user.announcements?.filter(x => x.classIds.includes(classId) && x.active) || [];
  //     storageAnnouncements = user.announcements?.filter(x => x.classIds.includes(classId) && !x.active) || [];
  //   }
  
  //   if (currentOrderOfAnnouncements) {
  //     activeAnnouncements.sort((a, b) => {
  //       const indexA = currentOrderOfAnnouncements.indexOf(a.id);
  //       const indexB = currentOrderOfAnnouncements.indexOf(b.id);
  //       return indexA - indexB;
  //     });
  
  //     storageAnnouncements.sort((a, b) => {
  //       const indexA = currentOrderOfAnnouncements.indexOf(a.id);
  //       const indexB = currentOrderOfAnnouncements.indexOf(b.id);
  //       return indexA - indexB;
  //     });
  //   }
  
  //   setAnnouncements(activeAnnouncements);
  //   setAnnouncementsStorage(storageAnnouncements);
  
  //   setClassDataForMultiAdd(classes?.map(x => ({
  //     className: x.className,
  //     classId: x.classId,
  //     add: x.classId === classId
  //   })));
  
  // }, [user, date, classId, orderOfAnnouncements]);

  useEffect(() => {
    const currentOrderOfAnnouncements = classes[classIndex]?.orderOfAnnouncements;
  
    let activeAnnouncements = [];
    let storageAnnouncements = [];
  
    const today = new Date(date.year, date.month, date.day);
    today.setHours(0, 0, 0, 0); // Normalize today's date
  
    if (user.settings.useActiveDates) {
      activeAnnouncements = user.announcements?.filter(announcement => {
        // 🔹 First filter by classId
        if (!announcement.classIds.includes(classId)) return false;

        if (announcement.startDate === undefined) {
            return true;
        } else if (Array.isArray(announcement.startDate)) {
            return announcement.startDate.length > 0 && announcement.startDate.includes(date.dayNamePlural);
        } else if (announcement.startDate && announcement.endDate) {
            const [startYear, startMonth, startDay] = announcement.startDate.split('-');
            const [endYear, endMonth, endDay] = announcement.endDate.split('-');
            const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
            const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
            return startDate <= today && endDate >= today;
        }
        return false;
      }) || [];
  
      storageAnnouncements = user.announcements?.filter(announcement => {
        // 🔹 First filter by classId
        if (!announcement.classIds.includes(classId)) return false;

        if (announcement.startDate && announcement.endDate) {
          if (Array.isArray(announcement.startDate)) {
            return announcement.startDate.length > 0 && !announcement.startDate.includes(date.dayNamePlural);
          } else {
            const [startYear, startMonth, startDay] = announcement.startDate?.split('-');
            const [endYear, endMonth, endDay] = announcement.endDate?.split('-');
            const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
            const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
            return startDate > today || (startDate < today && endDate < today);
          }
        }
        return false;
      }) || [];
    } else {
      activeAnnouncements = user.announcements?.filter(x => x.classIds.includes(classId) && x.active) || [];
      storageAnnouncements = user.announcements?.filter(x => x.classIds.includes(classId) && !x.active) || [];
    }
  
    if (currentOrderOfAnnouncements) {
      const sortByOrder = (a, b) => {
        const indexA = currentOrderOfAnnouncements.indexOf(a.id);
        const indexB = currentOrderOfAnnouncements.indexOf(b.id);
        return indexA - indexB;
      };

      activeAnnouncements.sort(sortByOrder);
      storageAnnouncements.sort(sortByOrder);
    }
  
    setAnnouncements(activeAnnouncements);
    setAnnouncementsStorage(storageAnnouncements);
  
    setClassDataForMultiAdd(classes?.map(x => ({
      className: x.className,
      classId: x.classId,
      add: x.classId === classId
    })));
  
  }, [user, date, classId, classes]);

  

  function convertDateFormat(startDate, endDate) {
    if (Array.isArray(startDate)) {
      return startDate.length === 5 ? "Everyday" : startDate.join(", ")
    }
    // Split the input date string on "/"
    const startParts = startDate.split('-');
    const endParts = endDate.split('-');
    // Rearrange the parts to the desired format "MM/DD/YYYY"
    const newStart = `${startParts[1]}/${startParts[2]}/${startParts[0]}`;
    const newEnd = `${endParts[1]}/${endParts[2]}/${endParts[0]}`;
    return startDate === endDate ? newStart : `${newStart} - ${newEnd}`;
  }

  const handleDateSet = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePicker(!showDatePicker);
  }

  const handleDateForward = () => {
    let dateObject = new Date(date.year, date.month, date.day);
  
    // Move to the next day by incrementing the date, not by adding milliseconds
    dateObject.setDate(dateObject.getDate() + 1);
  
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    };
  
    setDate(dateData);
  };

  const handleDateBackward = () => {
    let dateObject = new Date(date.year, date.month, date.day);
  
    // Move to the previous day by decrementing the date
    dateObject.setDate(dateObject.getDate() - 1);
  
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dayNamePlural: `${daysLong[dateObject.getDay()]}s`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    };
  
    setDate(dateData);
  };

  // This is to prevent error from TipTapEditor
  const handleOnBlur = () => {
    return
  }

  const toggleSetActiveDatesModal = (announcement) => {
    if (!activeDatesModal) {
      setActiveDates({
        startDate: announcement?.startDate === undefined ? "" : announcement?.startDate,
        endDate: announcement?.endDate === undefined ? "" : announcement?.endDate,
        announcementId: announcement.id
      })
    }
    setActiveDatesModal(!activeDatesModal)
  }

//   const saveAnnouncementActiveDates = async (datesFromSetActiveDatesModal) => {
//     try {
//       const docRef = doc(db, "users", user.uid);
  
//       const userCopy = JSON.parse(JSON.stringify(user));

//       const announcementIndex = userCopy.announcements.findIndex(
//         (a) => a.id === activeDates.announcementId
//       );
 
//       // Update the announcements in the document data
//       userCopy.announcements[announcementIndex].startDate = datesFromSetActiveDatesModal.startDate;
//       userCopy.announcements[announcementIndex].endDate = datesFromSetActiveDatesModal.endDate;

//       // Update the document in Firestore
//       await setDoc(docRef, userCopy);

//       toggleSetActiveDatesModal();
//     } catch (error) {
//       console.error('Error updating document:', error);
//     }
// };

const saveAnnouncementActiveDates = async (datesFromSetActiveDatesModal) => {
  try {
      if (!user?.uid || !activeDates.announcementId || !datesFromSetActiveDatesModal) {
          console.error("❌ Missing required parameters: User ID, Announcement ID, or Dates.");
          return;
      }

      // Reference the user document
      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

      // Fetch the user document
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
          console.error("❌ User document not found.");
          return;
      }

      const userData = userSnap.data();

      // console.log("userData.announcements =>", userData.announcements);
      // console.log("activeDates.announcementId =>", activeDates.announcementId);

      // Find the announcement in the array
      const announcementIndex = userData.announcements.findIndex(a => a.id === activeDates.announcementId);

      if (announcementIndex === -1) {
          console.error(`❌ Announcement ID ${activeDates.announcementId} not found in user document.`);
          return;
      }

      // Update the announcement's startDate and endDate
      const updatedAnnouncements = [...userData.announcements];
      updatedAnnouncements[announcementIndex] = {
          ...updatedAnnouncements[announcementIndex],
          startDate: datesFromSetActiveDatesModal.startDate,
          endDate: datesFromSetActiveDatesModal.endDate
      };

      // Update Firestore document with the new announcements array
      await updateDoc(userRef, {
          announcements: updatedAnnouncements
      });

      // Refresh user data in Redux store
      await dispatch(fetchUserData(user.uid));

      toggleSetActiveDatesModal();

      console.log(`✅ Active dates updated for Announcement ${activeDates.announcementId}!`);
  } catch (error) {
      console.error("❌ Error updating announcement active dates:", error);
  }
};

const sortAnnouncementsByDateCreated = (announcements) => {
  if (!Array.isArray(announcements)) {
    // console.warn("Invalid announcements data. Expected an array but got:", announcements);
    return []; // Return an empty array if announcements is not iterable
  }

  return [...announcements].sort((a, b) => {
    const dateA = new Date(a.dateCreated || 0); // Default to epoch if dateCreated is missing
    const dateB = new Date(b.dateCreated || 0); // Default to epoch if dateCreated is missing
    return dateCreatedSortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });
};

const sortAnnouncementsByLastEdited = (announcements) => {
  return [...announcements].sort((a, b) => {
    const dateA = new Date(a.lastEdited);
    const dateB = new Date(b.lastEdited);
    return lastEditedSortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });
};

const handleSortByDateCreated = () => {
  const newSortOrder = dateCreatedSortOrder === "asc" ? "desc" : "asc";
  setDateCreatedSortOrder(newSortOrder);

  setAnnouncements((prev) => sortAnnouncementsByDateCreated(prev));
  setAnnouncementsStorage((prev) => sortAnnouncementsByDateCreated(prev));
};

const handleSortByLastEdited = () => {
  const newSortOrder = lastEditedSortOrder === "asc" ? "desc" : "asc";
  setLastEditedSortOrder(newSortOrder);

  setAnnouncements((prev) => sortAnnouncementsByLastEdited(prev));
  setAnnouncementsStorage((prev) => sortAnnouncementsByLastEdited(prev));
};

  if (!classId) {
    return (
      <div className="content text-center">
        <h2 style={{ color: "red", fontWeight: "bold" }}>🚨 No Class Selected</h2>
        <p>Please select a class before accessing the announcement form.</p>
        <Button 
          color="primary"
          onClick={() => props.history.push("/add-edit-classes")} // Redirect to classes page
        >
          Go to Classes
        </Button>
      </div>
    );
  }

  return (
    <div className="content">
      <Modal contentClassName="add-edit-announcement-modal" isOpen={addEditAnnouncementModal} toggle={toggleAnnouncementModal}>
          <ModalHeader toggle={toggleAnnouncementModal} className="add-announcements-modal-header">
            <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Add Annoucement</h4>
          </ModalHeader>
          <ModalBody>
          Enter your announcement below. Each announcement is limited to 100 characters. Longer announcements should be split into multiple announcements.
            <Card className="mt-2" style={{backgroundImage:  classes[classIndex]?.backgroundAnnouncements || '', border: '1px solid #ccc'}}>
              <CardHeader style={{padding: '0px 10px'}}>
                <CardTitle tag="h2">
                  <TipTapEditor setEditedContent={setEditedAnnouncement} initialValue={announcementToEdit?.announcement?.html || null} height={null} handleOnBlur={handleOnBlur} settings={user.settings}/>
                </CardTitle>
              </CardHeader>
            </Card>
            <Row>
              <Button 
                variant="primary" 
                size="lg"
                onClick={() => setShowPreview(!showPreview)}
                style={{
                  padding: "10px 30px",
                  borderRadius: "30px",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  margin: '0px 10px',
                  backgroundColor: !showPreview ? '#28C76F' : '#fa5656'
                }}
              >
                {showPreview ? "Hide Preview" : "Show Preview"}
              </Button>
            </Row>
            {showPreview &&
              <div>
                <Card className="mt-2" style={{backgroundImage:  user.classes[classIndex]?.backgroundAnnouncements || '', width: '100%', height: '115px', border: '1px solid #ccc'}}>
                  <CardHeader>
                    <CardTitle tag="h2">
                      <Marquee 
                        speed={user && user.settings && user.settings?.marqueeSpeed ? user.settings?.marqueeSpeed : '125'} 
                        gradientWidth="0"
                        style={{overflow: 'hidden'}}
                      >
                        {announcementToEdit?.announcement?.html != undefined ? (
                          <div>
                            {ReactHtmlParser(announcementToEdit?.announcement?.html)}
                          </div>
                        ) : 
                          <div style={{fontFamily: user.settings?.defaultFontStyle || null, fontSize: '48px'}}>
                            Type here...
                          </div>
                        }
                      </Marquee>
                    </CardTitle>
                  </CardHeader>
                </Card>
              </div>
              }
            
{/*             
            Add this announcement to: 
            {classDataForMultiAdd && classDataForMultiAdd.map((x) => 
              <div>
                <Input type="checkbox" checked={x.add} onChange={() => handleCheckboxClickForMultiAdd(x.classId)}></Input>
                {x.className}
              </div>
            )} */}

<Card className="mt-3 p-3" style={{ border: '1px solid #ddd', borderRadius: '10px' }}>
  <CardHeader style={{ borderBottom: '1px solid #ccc', padding: '10px 15px', minHeight: '0px' }}>
    <h5 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Add this announcement to:</h5>
  </CardHeader>
  <CardBody>
    <Row className="ml-2">
      {classDataForMultiAdd && classDataForMultiAdd.map((x) => (
        <Col xs={12} sm={6} md={4} key={x.classId} className="d-flex align-items-center mb-2">
          <FormGroup check>
            <Label check className="mb-0" style={{ fontSize: "1rem", fontWeight: "500" }}>
              <Input 
                type="checkbox" 
                checked={x.add} 
                onChange={() => handleCheckboxClickForMultiAdd(x.classId)}
                style={{ marginRight: "10px", transform: "scale(1.2)" }} 
              />
              <span className="form-check-sign" />
                {x.className}
            </Label>
          </FormGroup>
        </Col>
      ))}
    </Row>
  </CardBody>
</Card>
          </ModalBody>
          <div className="m-2 ml-5" style={{textAlign: 'center'}}>
            <Button 
              className="button-hover-fix"
              variant="primary" 
              size="lg"
              onClick={() => handleSubmit(announcementToEdit.announcementId)}
              style={{
                padding: "10px 30px",
                borderRadius: "30px",
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              <i className='fa fa-save' />
              {' '}Save               
            </Button>
          </div>
        </Modal>

        {activeDates && 
          <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={saveAnnouncementActiveDates}/>
        }
        <Card style={{padding: '12px 0px 5px 0px'}}>
            <div 
              tag="h4"
              style={{display: 'flex', alignContent: 'center', justifyContent: 'center', fontWeight: "bold", color: "#004aad", fontSize: '30px' }}
            >
              Editing announcements for {classes[classIndex].className}
            </div>
        {user.settings.useActiveDates && 
        <Row id="present-navbar-datepicker-section" style={{display: 'flex', alignContent: 'center', justifyContent: 'center', height: '45px'}}>
                        {user.settings.useActiveDates ? (
                          <div className="contentBoxTableDatePicker">
                            <ActiveDatesDatePicker
                              date={date}
                              showDatePicker={showDatePicker}
                              setShowDatePicker={setShowDatePicker}
                              handleDateSet={handleDateSet}
                              handleDateBackward={handleDateBackward}
                              handleDateForward={handleDateForward}
                              noActiveBoxesAlert={null}
                              noBoxesSetToShowAlert={null}
                            />
                          </div>
                        )
                        : null }
                      </Row>
        }
        </Card>

                      <Card style={{padding: '20px'}}>
                        <CardHeader>
                          <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Active Announcements</CardTitle>
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are the announcements that will appear on your "Present" screen on the selected date.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are the announcements that will appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left" id="Announcement" style={{width: user.settings.useActiveDates ? '33%' : '45%'}}>Announcement</th>
                                {user.settings.useActiveDates && <th className="text-center" id="active-dates" style={{width: '20%'}}>Active Dates</th>}
                                <th
                                  className="text-center"
                                  id="date-created"
                                  style={{ width: "11%", cursor: "pointer" }}
                                  onClick={handleSortByDateCreated}
                                >
                                  Date Created {dateCreatedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th
                                  className="text-center"
                                  id="last-edited"
                                  style={{ width: "11%", cursor: "pointer" }}
                                  onClick={handleSortByLastEdited}
                                >
                                  Last Edited {lastEditedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th className="text-center" id="Order" style={{width: '10%'}}>Order</th>
                                <th className="text-center" style={{width: '15%'}}>Actions</th>
                              </tr>
                            </thead>
                            {user && props && announcements && announcements.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-left">{item.text}</td>
                                {user.settings.useActiveDates && 
                                  <td className="text-center">
                                    {
                                      item?.startDate === "2000-01-02" && item?.endDate === "2099-12-30" ? "Everday" :
                                      item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) :  
                                      'n/a'
                                    }
                                  </td>
                                }
                                {/* {user.settings.useActiveDates && <td className="text-center">{item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) : 'n/a'}</td> } */}
                                <td className="text-center">{item.dateCreated}</td>
                                <td className="text-center">{item.lastEdited}</td>
                                {/* <td className="text-center">{item.active ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td> */}
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    disabled={index===0}
                                    onClick={() => handleMoveUp(item.id)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    disabled={index===announcements.length-1}
                                    onClick={() => handleMoveDown(item.id)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleAnnouncementModal(item.id)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-edit" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip153942735"
                                        size="md"
                                        type="button"
                                        onClick={() => handleMoveToStorage(item.id)}
                                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                        >
                                        <i className="nc-icon nc-box" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip153942735"
                                      >
                                        Move to Storage
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                          <Button
                            className='button-hover-fix'
                            style={{
                              padding: "10px 20px",
                              borderRadius: "20px",
                              fontSize: ".9rem",
                              fontWeight: "bold",
                            }}
                            onClick={() => toggleAnnouncementModal()}
                          >
                            <i className='fa fa-plus' />
                            {' '}Add Announcement  
                          </Button>
                        </CardBody>
                      </Card>






                      <Card>
                        <CardHeader>
                          {user.settings.useActiveDates ? (
                            <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Inactive Announcements</CardTitle>
                          ) : (
                            <CardTitle tag="h4" style={{ fontWeight: "bold", color: "#004aad" }}>Announcement Storage</CardTitle>
                          )}
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are the announcements that will NOT appear on your "Present" screen on the selected date.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are announcements that are in storage and will NOT appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left" id="Announcement" style={{width: user.settings.useActiveDates ? '45%' : '65%'}}>Announcement</th>
                                {user.settings.useActiveDates && <th className="text-center" id="active-dates" style={{width: '20%'}}>Active Dates</th>}
                                <th
                                  className="text-center"
                                  id="date-created"
                                  style={{ width: "12%", cursor: "pointer" }}
                                  onClick={handleSortByDateCreated}
                                >
                                  Date Created {dateCreatedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th
                                  className="text-center"
                                  id="last-edited"
                                  style={{ width: "12%", cursor: "pointer" }}
                                  onClick={handleSortByLastEdited}
                                >
                                  Last Edited {lastEditedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th className="text-center" style={{width: '15%'}}>Actions</th>
                              </tr>
                            </thead>
                            {user && props && announcementsStorage && announcementsStorage.map(((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-left">{item.text}</td>
                                {user.settings.useActiveDates && <td className="text-center">{item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) : 'n/a'}</td> }
                                <td className="text-center">{item.dateCreated}</td>
                                <td className="text-center">{item.lastEdited}</td>                             
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleAnnouncementModal(item.id)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-edit" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip159182296"
                                        size="md"
                                        type="button"
                                        onClick={() => handleMoveToActive(item.id)}
                                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                      >
                                        <i className="nc-icon nc-basket" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip159182296"
                                      >
                                        Move to Active
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )}))}
                          </Table>
                          {alert}
                        </CardBody>
                      </Card>
    </div>
  );
}

export default AddEditAnnouncementForm;

// import { useEffect, useRef } from "react";
// import moment from "moment";
// import { doc, updateDoc, getDoc  } from 'firebase/firestore';
// import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
// import { useSelector } from "react-redux";
// import { selectUser } from "features/userSlice";
// import { FIREBASE_COLLECTIONS } from "../src/config/firebaseCollections";

// const useHandleDayWeekCycleChange = (userSettings) => {
//   const user = useSelector(selectUser); // ✅ Valid inside a hook
//   const notificationRef = useRef(null);  // ✅ Create the ref inside the hook

// const handleSaveSettings = async (newSettings) => {
//     try {
//         if (!user?.uid) {
//             console.error("User ID is missing.");
//             return;
//         }

//         const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

//         // Fetch existing user data
//         const userSnap = await getDoc(userRef);
//         if (!userSnap.exists()) {
//             console.error("User document not found.");
//             return;
//         }

//         const userData = userSnap.data();

//         // Merge new settings with existing settings
//         const updatedSettings = {
//             ...userData.settings,
//             ...newSettings,
//         };

//         // Update only the `settings` field in Firestore
//         await updateDoc(userRef, { settings: updatedSettings });

//         console.log("✅ Settings updated successfully:", updatedSettings);
//         showCycleChangedAlert(newSettings);
//     } catch (error) {
//         console.error("❌ Error updating settings in Firestore:", error);
//     }
// };


//   function showCycleChangedAlert(newSettings) {
//     if (notificationRef?.current) {  // ✅ Ensure ref is initialized before using it
//       const capitalizedCycleType = newSettings.cycleType.charAt(0).toUpperCase() + newSettings.cycleType.slice(1);
  
//       var options = {
//         place: 'bc',
//         message: (
//           <div style={{ textAlign: "center", fontSize: "16px" }}>
//             <strong>{capitalizedCycleType} Change Detected</strong>
//             <br />
//             Lesson Launchpad has transitioned to <strong>{capitalizedCycleType} {newSettings.currentWeek}</strong>.
//             <br />
//             Go to <a href="/settings" style={{ color: "white", fontWeight: "bold", textDecoration: "underline" }}>SETTINGS</a> to change.
//           </div>
//         ),
//         type: 'info',
//         icon: "now-ui-icons ui-1_bell-53",
//         autoDismiss: 10
//       };
  
//       notificationRef.current.notificationAlert(options);
//     } else {
//       console.warn("❌ Notification Alert is not initialized.");
//     }
//   }  

//   useEffect(() => {
//     if (!userSettings?.multiWeek || !userSettings.cycleType || !userSettings.numberOfWeeks) return;

//     const lastCheckedDate = localStorage.getItem("lastCheckedDateForDayWeeklyCycleChange");
//     const today = moment().startOf("day");
//     const lastChecked = lastCheckedDate ? moment(lastCheckedDate) : null;

//     let currentLetterIndex = userSettings.currentWeek
//       ? userSettings.currentWeek.charCodeAt(0) - 65
//       : 0; // Convert "A" -> 0, "B" -> 1, etc.

//     const totalLetters = userSettings.numberOfWeeks;

//     if (userSettings.cycleType === "day") {
//       let daysToAdvance = 0;
//       if (lastChecked) {
//         let dayPointer = lastChecked.clone();
//         while (dayPointer.isBefore(today, "day")) {
//           if (dayPointer.isoWeekday() < 6) daysToAdvance++; // Mon-Fri only
//           dayPointer.add(1, "day");
//         }
//       } else {
//         daysToAdvance = 1;
//       }
//       currentLetterIndex = (currentLetterIndex + daysToAdvance) % totalLetters;
//     } else if (userSettings.cycleType === "week") {
//       let weeksToAdvance = 0;
//       if (lastChecked) {
//         let weekPointer = lastChecked.clone();
//         while (weekPointer.isBefore(today, "week")) {
//           weeksToAdvance++;
//           weekPointer.add(1, "week");
//         }
//       } else {
//         weeksToAdvance = 1;
//       }

//       if (today.isoWeekday() === 1) {
//         currentLetterIndex = (currentLetterIndex + weeksToAdvance) % totalLetters;
//       }
//     }

//     const newCurrentWeek = String.fromCharCode(65 + currentLetterIndex);

//     if (newCurrentWeek !== userSettings.currentWeek) {
//       handleSaveSettings({ ...userSettings, currentWeek: newCurrentWeek });
//     }

//     // Store last run date
//     localStorage.setItem("lastCheckedDateForDayWeeklyCycleChange", today.toISOString());
//   }, [userSettings]);

//   return notificationRef;  // ✅ Return the notificationRef
// };

// export default useHandleDayWeekCycleChange;

import { useEffect, useRef } from "react";
import moment from "moment";
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { FIREBASE_COLLECTIONS } from "../src/config/firebaseCollections";

const useHandleDayWeekCycleChange = (userSettings) => {
    const user = useSelector(selectUser); // ✅ Get user info
    const notificationRef = useRef(null);  // ✅ Create ref for notifications

    const handleSaveSettings = async (newSettings) => {
        try {
            if (!user?.uid) {
                console.error("User ID is missing.");
                return;
            }

            const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

            // Fetch existing user data
            const userSnap = await getDoc(userRef);
            if (!userSnap.exists()) {
                console.error("User document not found.");
                return;
            }

            const userData = userSnap.data();

            // Merge new settings with existing settings
            const updatedSettings = {
                ...userData.settings,
                ...newSettings,
            };

            // Update only the `settings` field in Firestore
            await updateDoc(userRef, { settings: updatedSettings });

            console.log("✅ Settings updated successfully:", updatedSettings);
            showCycleChangedAlert(newSettings);
        } catch (error) {
            console.error("❌ Error updating settings in Firestore:", error);
        }
    };

    function showCycleChangedAlert(newSettings) {
        if (notificationRef?.current) {  // ✅ Ensure ref is initialized before using it
            const capitalizedCycleType = newSettings.cycleType.charAt(0).toUpperCase() + newSettings.cycleType.slice(1);

            var options = {
                place: 'bc',
                message: (
                    <div style={{ textAlign: "center", fontSize: "16px" }}>
                        <strong>{capitalizedCycleType} Change Detected</strong>
                        <br />
                        Lesson Launchpad has transitioned to <strong>{capitalizedCycleType} {newSettings.currentWeek}</strong>.
                        <br />
                        Go to <a href="/settings" style={{ color: "white", fontWeight: "bold", textDecoration: "underline" }}>SETTINGS</a> to change.
                    </div>
                ),
                type: 'info',
                icon: "now-ui-icons ui-1_bell-53",
                autoDismiss: 10
            };

            notificationRef.current.notificationAlert(options);
        } else {
            console.warn("❌ Notification Alert is not initialized.");
        }
    }

    useEffect(() => {
        if (!userSettings?.multiWeek || !userSettings.cycleType || !userSettings.numberOfWeeks || !user?.uid) return;

        const fetchAndUpdateCycle = async () => {
            try {
                const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
                const userSnap = await getDoc(userRef);

                if (!userSnap.exists()) {
                    console.error("❌ User document not found.");
                    return;
                }

                const userData = userSnap.data();
                const lastCheckedDateFirestore = userData.settings?.lastCheckedDateForDayWeeklyCycleChange || null;
                const today = moment().startOf("day");
                const lastChecked = lastCheckedDateFirestore ? moment(lastCheckedDateFirestore) : null;

                let currentLetterIndex = userSettings.currentWeek
                    ? userSettings.currentWeek.charCodeAt(0) - 65
                    : 0; // Convert "A" -> 0, "B" -> 1, etc.

                const totalLetters = userSettings.numberOfWeeks;

                if (userSettings.cycleType === "day") {
                    let daysToAdvance = 0;
                    if (lastChecked) {
                        let dayPointer = lastChecked.clone();
                        while (dayPointer.isBefore(today, "day")) {
                            if (dayPointer.isoWeekday() < 6) daysToAdvance++; // Mon-Fri only
                            dayPointer.add(1, "day");
                        }
                    } else {
                        daysToAdvance = 1;
                    }
                    currentLetterIndex = (currentLetterIndex + daysToAdvance) % totalLetters;
                } else if (userSettings.cycleType === "week") {
                    let weeksToAdvance = 0;
                    if (lastChecked) {
                        let weekPointer = lastChecked.clone();
                        while (weekPointer.isBefore(today, "week")) {
                            weeksToAdvance++;
                            weekPointer.add(1, "week");
                        }
                    } else {
                        weeksToAdvance = 1;
                    }

                    if (today.isoWeekday() === 1) {
                        currentLetterIndex = (currentLetterIndex + weeksToAdvance) % totalLetters;
                    }
                }

                const newCurrentWeek = String.fromCharCode(65 + currentLetterIndex);

                if (newCurrentWeek !== userSettings.currentWeek) {
                    await handleSaveSettings({
                        ...userSettings,
                        currentWeek: newCurrentWeek,
                        lastCheckedDateForDayWeeklyCycleChange: today.toISOString(),
                    });
                } else {
                    // Update only last checked date to avoid unnecessary writes
                    await updateDoc(userRef, {
                        "settings.lastCheckedDateForDayWeeklyCycleChange": today.toISOString(),
                    });
                }
            } catch (error) {
                console.error("❌ Error updating cycle settings:", error);
            }
        };

        fetchAndUpdateCycle();
    }, [userSettings, user?.uid]);

    return notificationRef;  // ✅ Return the notificationRef
};

export default useHandleDayWeekCycleChange;

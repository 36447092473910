
import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase.js";
import { setDoc, doc, getDoc, collection, addDoc, updateDoc } from "firebase/firestore";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, FormFeedback} from "reactstrap";
import canvaShare from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/canva-share-link.gif";
import { createRandomId } from "RandomId";
import FilePicker from '../FilePicker';
import stampIcon from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/stamp-solid.svg";
import FullscreenModal from "../FullscreenModal";
import ActiveDatesModal from "../ActiveDatesModal";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates } from "../../utils/ContentBoxUtils";
import { useDispatch } from "react-redux";
import { fetchUserData } from "features/userSlice.js";


function PdfBox({content, setHeading, updateContentBox, classId, user, classes, navbarMD, view, addContentBox, multiBox, updateContentBoxWithinMultiBox, printActive, readOnly, navigationVisible, inFullscreenModal}) {
  const [editedContent, setEditedContent] = useState();
  const dispatch = useDispatch()
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState()
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation()
  const date = new Date().toLocaleDateString('en-CA')
  const [boxToCopyId, setBoxToCopyId] = useState()
  const [copyBoxModal, setCopyBoxModal] = useState(false)
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [filePickerModal, setFilePickerModal] = useState(false);
  const [fullscreenModal, setFullscreenModal] = useState(false);

  const toggleFullscreenModal = () => {
    setFullscreenModal(!fullscreenModal)
  }

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-25}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

const [activeDatesModal, setActiveDatesModal] = useState(false)

const toggleSetActiveDatesModal = () => {
  setActiveDatesModal(!activeDatesModal)
}

const [activeDates, setActiveDates] = useState({
  startDate: content.startDate === undefined ? "" : content.startDate,
  endDate: content.endDate === undefined ? "" : content.endDate
});

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const handleSaveBackground = async (imageFileName) => {
  if (multiBox) {
    const backgroundUrl = `url(${imageFileName})`;
    updateContentBoxWithinMultiBox({...content, background: backgroundUrl }, true)
  } else {
    await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  }
};

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert();
  console.log("updatedHeading => ", updatedHeading)

  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // Format the heading based on length
    const headingToUpdate =
      updatedHeading.length > 9
        ? updatedHeading.substring(0, 9) + "..."
        : updatedHeading.length === 0
        ? "Type here..."
        : updatedHeading;

    // Update the Firestore document
    await updateDoc(contentBoxRef, {
      heading: headingToUpdate,
      userHeading: true,
    });

    successAlert();
  } catch (error) {
    console.error("Error updating content box heading:", error);
  }
};

const warningWithConfirmAndCancelMessage = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox, dispatch);
  setAlert(
    <ReactBSAlert
    style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
    onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

  const [showCanvaBoxAlert, setShowCanvaBoxAlert] = useState(false);
  const [canvaLink, setCanvaLink] = useState();
  const [isCanvaLinkValid, setIsCanvaLinkValid] = useState(true);

  const handleCanvaLinkChange = (value) => {
    const isValidInput = /^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/.test(value);
    const appendedValue = value + "?embed"
    setIsCanvaLinkValid(isValidInput);
    setCanvaLink(appendedValue);
  }

  const toggleCanvaBoxAlert = () => {
    setShowCanvaBoxAlert(prev => !prev)
    setCanvaLink("");
  }

  const saveFirestoreUrlForPdfBox = (firestoreLink) => {
    if (!multiBox) {
      updateContentBox(
        user.uid,
        classId,
        content.contentBoxId, 
        {...content, content: firestoreLink},
        null
      )
    } else {
      updateContentBoxWithinMultiBox({
        ...content,
        content: firestoreLink,
      })
    }
    toggleFilePickerModal()
  }

  const addCanvaBoxConfirmAlert = (e) => {
    if (/^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/.test(canvaLink.replace(/\?embed$/, ''))) {
      if (!multiBox) {
        updateContentBox(
          content.contentBoxId, 
          {...content, content: canvaLink}
        )
      } else {
        updateContentBoxWithinMultiBox({
          ...content,
          content: canvaLink
        })
      }
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Canva Box Updated!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
        </ReactBSAlert>
      );
      toggleCanvaBoxAlert()
    } else {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Invalid Canva Link!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          <p><b>You entered:</b> {canvaLink.replace(/\?embed$/, '')}</p>
          <br></br>
          <p>A valid URL will start with 'https://www.canva.com/design' and end with '/view'.</p>
        </ReactBSAlert>
      );
    }
  };

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};

const toggleFilePickerModal = () => {
  setFilePickerModal(!filePickerModal);
};

  return (
    <>
    {showCanvaBoxAlert && (
          <ReactBSAlert
          showCancel
          title="Add Canva Box"
          style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
          onConfirm={(e) => addCanvaBoxConfirmAlert(e)}
          onCancel={() => toggleCanvaBoxAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize=""
          validationRegex={/^https:\/\/www\.canva\.com\/design\/[^\/]+\/[^\/]+\/view$/}
          validationMsg={"Invalid URL. A valid URL will start with 'https://www.canva.com/design' and end with '/view'."}
        >
        <img src={canvaShare} alt="canva-share-gif" style={{ width: '400px', height: 'auto', marginBottom: '20px', border: '2px solid black' }}/>
        <div>
          <hr />
          <br></br>
          <div>
            Input the smart embed link from your Canva Project:
            <ol>
              <li>In your Canva project, click Share.</li>
              <li>Click More.</li>
              <li>Click Embed.</li>
              <li>Copy the smart embed link.</li>
            </ol>
            <Input
              type="text"
              onChange={(e) => handleCanvaLinkChange(e.target.value)}
              placeholder={"Your Canva embed link here..."}
              required={true}
              valid={isCanvaLinkValid}
              invalid={!isCanvaLinkValid}
            />
            <FormFeedback type="invalid">
              {/* Validation error message */}
              {isCanvaLinkValid ? null : 'Invalid input!'}
            </FormFeedback>
          </div>
        </div>
        </ReactBSAlert>
    )}

    <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
      <ModalBody>
        <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
          Copy box to:
        </h3>

        {user && classes && (
          <Row>
              <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
                {classes.map((x, index) => (
                  <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                    <Label check>
                      <Input
                        type="checkbox"
                        defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                        onChange={() => handleCheckboxChange(x.classId)}
                      />
                      <span className="form-check-sign" />
                      {x.className}
                    </Label>
                  </FormGroup>
                ))}
              </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
        <Button 
          onClick={toggleCopyBoxModal}
          className="delete-button"
          style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        >
          Cancel
        </Button>
        <Button 
          className="button-hover-fix"
          disabled={classesSelectedForContentBoxCopy.length < 1} 
          onClick={() => copyBoxToClasses()} 
          style={{borderRadius: '30px'}}>
          <i className="fa fa-copy" />{' '}Copy
        </Button>
      </ModalFooter>
    </Modal>

    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px'}}
      onMouseEnter={() => setShowMainEditButton(true)}
      onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
    >
      {alert}
        <Card 
          key={content.contentBoxId}
          style={{ height: multiBox || inFullscreenModal ? '100%' : cardHeight.card, backgroundImage: content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
          {!inFullscreenModal && showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    {!multiBox && 
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="editContentBoxHeading"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                          size="sm"
                          onClick={() => editBoxNameAlert(content.contentBoxId)}
                        >
                          <i className="fa fa-pencil" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="editContentBoxHeading"
                          placement="left"
                        >
                            Edit Heading
                        </UncontrolledTooltip>
                      </>
                    }
                      <Button
                        // className="btn-round btn-icon"
                        id="changePdfFile"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(60%)' : 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 9px', zIndex: '1049', overflow: 'visible'}}
                        size="sm"
                        onClick={() => toggleFilePickerModal()}
                      >
                      <i className="fa fa-folder" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changePdfFile"
                        placement="left"
                      >
                          Change PDF File
                      </UncontrolledTooltip>
                      <Button
                        // className="btn-round btn-icon"
                        id="changeContentBoxBackground"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleBackgroundContentBoxModal()}
                      >
                      <i className="nc-icon nc-palette"></i>             
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                    {user.settings.useActiveDates && !multiBox ? (
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="setContentBoxActiveDates"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => toggleSetActiveDatesModal()}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="setContentBoxActiveDates"
                          placement="left"
                        >
                            Set Active Dates
                        </UncontrolledTooltip>
                      </>
                    ) : !multiBox &&
                    (
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="sendBoxToStorage"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="sendBoxToStorage"
                          placement="left"
                        >
                            Send Box to Storage
                        </UncontrolledTooltip>
                      </>
                    )}
                      <Button
                        // className="btn-round btn-icon"
                        id="copyBox"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(300%)' : 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="copyBox"
                        placement="left"
                      >
                          Duplicate Box
                      </UncontrolledTooltip>
                      <Button
                        // className="btn-round btn-icon"
                        id="toggleFullscreenModal"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(420%)' : 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {toggleFullscreenModal()}}
                      >
                        <i className="fa fa-expand"></i>                        
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="toggleFullscreenModal"
                        placement="left"
                      >
                          Expand
                      </UncontrolledTooltip>
                    { !multiBox &&
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="saveContentBoxAsTemplate"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => addTemplateAlert(content)}
                        >
                          <img
                              src={stampIcon}
                              alt="stamp"
                              style={{ height: '13px'}}
                          />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="saveContentBoxAsTemplate"
                          placement="left"
                        >
                            Save as Template
                        </UncontrolledTooltip>
                        <Button
                        className="button-delete"
                        id="deleteContentBox"
                        type="button"
                        style={{color: 'white', position: 'absolute', transform: 'translateY(900%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="deleteContentBox"
                          placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                      </>
                    }
                  </>
                  )}
            </>
          )}
          <CardBody>
            {content.content !== null ?
              <div style={{ minHeight: '100%', overflowY: 'auto' }}>
                <iframe 
                  loading="lazy"
                  style={{position: "absolute", width: "100%", height: "100%", top: 0, left: 0, border: "none", padding: "10px", margin: "0px"}}
                  src={content.content}
                  allow="fullscreen"
                />
              </div>
            :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  className="button-hover-fix"
                  style={{borderRadius: '30px'}}
                  onClick={() => toggleFilePickerModal()}
                >
                  <i className='fa fa-upload' />
                            {' '}Upload PDF File
                </Button>
              </div>
            }
          </CardBody>
        </Card>

        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>

          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}}>
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <FullscreenModal 
            fullscreenModal={fullscreenModal} 
            toggleFullscreenModal={toggleFullscreenModal}
            box={content} 
            navbarMD={navbarMD} 
            classIdRef={classId}
            view={view}
            user={user}
            multiBox={true}
            addContentBox={addContentBox}
            updateContentBox={updateContentBox}
            navigationVisible={navigationVisible}
          />
          <FilePicker toggleFilePickerModal={toggleFilePickerModal} fileType={'pdf'} isOpen={filePickerModal} onOpenClick={saveFirestoreUrlForPdfBox} />
        </Col>
    </>
  );
}

export default PdfBox;

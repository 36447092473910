import React, { useState, useEffect } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Table, Button, UncontrolledTooltip, Input, FormGroup, Label } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { doc, getDoc, setDoc, updateDoc  } from "firebase/firestore";
import { db, storage } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { createPlaylistId } from 'RandomId';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FIREBASE_COLLECTIONS } from "../../src/config/firebaseCollections";

export default function PlaylistModal({showPlaylistModal, togglePlaylistModal, playlistToEditId, onChangeToAddNewPlaylistToMusicSegment}) { 
    const user = useSelector(selectUser);
    const dispatch = useDispatch()
    const foundPlaylist = user.autoMusic?.playlists?.find((playlist) => playlist.playlistId === playlistToEditId);
    const [playlistToEdit, setPlaylistToEdit] = useState({
        name: "",
        playlistId: createPlaylistId(),
        playlistData: [{ next: true, active: true, url: "", name: "" }]
    });
    const [editMode, setEditMode] = useState(false);
    const [addYouTubePlaylistModal, setAddYouTubePlaylistModal] = useState(false)
    const [youtubePlaylistUrl, setYoutubePlaylistUrl] = useState("");  // ✅ Store input value
    const [urlIsInvalid, setUrlIsInvalid] = useState(false);  // ✅ Track invalid URL state
    const [alert, setAlert] = useState(false);
    const [oneOrMorePlaylistSongsInvalid, setOneOrMorePlaylistSongsInvalid] = useState(false)

    useEffect(() => {
        if (foundPlaylist) {
            setPlaylistToEdit(foundPlaylist);
            setEditMode(true);
        } else {
            // ✅ Reset modal when creating a new playlist
            setPlaylistToEdit({
                name: "",
                playlistId: createPlaylistId(),
                playlistData: [{ next: true, active: true, url: "", name: "" }]
            });
            setEditMode(false);
        }
    }, [foundPlaylist, playlistToEditId]);  // ✅ Depend on `playlistToEditId` to reset correctly
    

    const playlistData = [
        {
            next: false,
            active: true,
            url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
            name: ""
        },
        {
            next: false,
            active: true,
            url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
            name: ""
        },
        {
            next: true,
            active: true,
            url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
            name: ""
        },
        {
            next: false,
            active: true,
            url: "https://www.youtube.com/watch?v=Co7fwXjOYXM",
            name: ""
        }
    ];

    const API_KEY = "AIzaSyC23oa1B57nZFG3dGsXOZGz57imPB2sisM";

    // ✅ Watch for invalid song names and update state
useEffect(() => {
    if (playlistToEdit.playlistData.some(item => item.name === "Title Not Found")) {
        setOneOrMorePlaylistSongsInvalid(true);
    } else {
        setOneOrMorePlaylistSongsInvalid(false);
    }
}, [playlistToEdit.playlistData]);  // 🔹 Dependency array ensures it updates correctly

const getYoutubeTitleFromPage = async (videoUrl) => {
    if (videoUrl.length < 1) return;

    try {
        const videoId = getYoutubeVideoId(videoUrl);
        if (!videoId) throw new Error("Invalid YouTube URL");

        const response = await fetch(
            `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${API_KEY}`
        );

        const data = await response.json();
        return data.items[0]?.snippet?.title || "Title Not Found";
    } catch (error) {
        console.error("Error fetching video title:", error);
        return "Title Not Found";
    }
};

    const getYoutubeVideoId = (url) => {
        let videoId = null;
  
        try {
            // ✅ Handle `youtu.be/` short links
            if (url.includes("youtu.be/")) {
                videoId = url.split("youtu.be/")[1]?.split(/[?&]/)[0];
            }
            // ✅ Handle `watch?v=` links
            else if (url.includes("watch?v=")) {
                videoId = url.split("v=")[1]?.split(/[?&]/)[0];
            }
            // ✅ Handle `/embed/` links
            else if (url.includes("/embed/")) {
                videoId = url.split("/embed/")[1]?.split(/[?&]/)[0];
            }
            // ✅ Handle `shorts/` links
            else if (url.includes("/shorts/")) {
                videoId = url.split("/shorts/")[1]?.split(/[?&]/)[0];
            }

            // 🔴 If no valid video ID found, return null
            if (!videoId) {
                console.warn("Invalid YouTube URL:", url);
                return null;
            }

            return videoId;
        } catch (error) {
            console.error("Error extracting YouTube video ID:", error);
            return null;
        }
    };

    const handleYoutubeUrlChange = async (e, index) => {
        const url = e.target.value;
    
        // ✅ Immediately update URL
        setPlaylistToEdit((prevData) => {
            const updatedPlaylistData = [...prevData.playlistData];
            updatedPlaylistData[index] = { ...updatedPlaylistData[index], url }; 
            return { ...prevData, playlistData: updatedPlaylistData };
        });
    
        // ✅ Fetch video title using YouTube API
        let videoName = await getYoutubeTitleFromPage(url);
    
        // ✅ Update title separately
        setPlaylistToEdit((prevData) => {
            const updatedPlaylistData = [...prevData.playlistData];
            updatedPlaylistData[index] = { ...updatedPlaylistData[index], name: videoName }; 
            return { ...prevData, playlistData: updatedPlaylistData };
        });
    };

    const handleAddRow = () => {
        setPlaylistToEdit((prevData) => ({
            ...prevData,
            playlistData: [
                ...prevData.playlistData,
                { next: false, active: true, url: "", name: "" } // ✅ New empty row
            ]
        }));
    };

    // const handleMoveUp = (index) => {
    //     if (index === 0) return; // Can't move up the first item
    
    //     setPlaylistToEdit((prevData) => {
    //         const updatedPlaylistData = [...prevData.playlistData];
    //         [updatedPlaylistData[index], updatedPlaylistData[index - 1]] = 
    //             [updatedPlaylistData[index - 1], updatedPlaylistData[index]]; // Swap elements
    
    //         return { ...prevData, playlistData: updatedPlaylistData };
    //     });
    // };
    
    // const handleMoveDown = (index) => {
    //     if (index === playlistToEdit.playlistData.length - 1) return; // Can't move down the last item
    
    //     setPlaylistToEdit((prevData) => {
    //         const updatedPlaylistData = [...prevData.playlistData];
    //         [updatedPlaylistData[index], updatedPlaylistData[index + 1]] = 
    //             [updatedPlaylistData[index + 1], updatedPlaylistData[index]]; // Swap elements
    
    //         return { ...prevData, playlistData: updatedPlaylistData };
    //     });
    // };

    const deletePlaylistRow = (index) => {
        setPlaylistToEdit((prevData) => {
            const updatedPlaylistData = prevData.playlistData.filter((_, idx) => idx !== index);
    
            return { ...prevData, playlistData: updatedPlaylistData };
        });
    };
    

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    
    //     try {
    //         // Clone user object to avoid direct mutations
    //         const docRef = doc(db, "users", user.uid);
    //         const userCopy = JSON.parse(JSON.stringify(user));
    
    //         // 🛑 Ensure `autoMusic` and `playlists` exist
    //         if (!userCopy.autoMusic) {
    //             userCopy.autoMusic = { playlists: [] };
    //         }
    
    //         if (!userCopy.autoMusic.playlists) {
    //             userCopy.autoMusic.playlists = [];
    //         }
    
    //         // ❌ Prevent saving if the playlist name is empty
    //         if (!playlistToEdit.name.trim()) {
    //             window.alert("❌ Playlist name cannot be empty.");
    //             return;
    //         }
    
    //         // 🔍 Remove empty rows (no URL or name)
    //         let cleanedPlaylistData = playlistToEdit.playlistData.filter(
    //             (item) => item.url.trim() !== "" && item.name.trim() !== ""
    //         );
    
    //         // ❌ Prevent saving if all rows are empty
    //         if (cleanedPlaylistData.length === 0) {
    //             window.alert("❌ Playlist must contain at least one valid entry with both a name and a URL.");
    //             return;
    //         }
    
    //         // 🛑 Ensure at least one item has `next: true`
    //         if (!cleanedPlaylistData.some((item) => item.next)) {
    //             cleanedPlaylistData[0].next = true; // ✅ Set first valid row as `next: true`
    //         }
    
    //         // 🔄 Update or add the playlist
    //         const existingPlaylistIndex = userCopy.autoMusic.playlists.findIndex(
    //             (playlist) => playlist.playlistId === playlistToEdit.playlistId
    //         );
    
    //         if (existingPlaylistIndex !== -1) {
    //             // ✅ Update existing playlist
    //             userCopy.autoMusic.playlists[existingPlaylistIndex] = {
    //                 ...playlistToEdit,
    //                 playlistData: cleanedPlaylistData, // Save cleaned data
    //             };
    //         } else {
    //             // ✅ Add new playlist
    //             userCopy.autoMusic.playlists.push({
    //                 ...playlistToEdit,
    //                 playlistData: cleanedPlaylistData, // Save cleaned data
    //             });
    //         }
    
    //         // 📝 Save to Firestore
    //         await setDoc(docRef, userCopy);
    
    //         console.log("✅ Final Result Saved:", userCopy);

    //         //This is to add the new playlist to the new music segment automatically so that way the user doesn't have to go select
    //         //the new playlist they just added from the playlist dropdown. This does it automatically.
    //         onChangeToAddNewPlaylistToMusicSegment(playlistToEdit);
    
    //         togglePlaylistModal(); // Close modal after saving
    //     } catch (error) {
    //         console.error("❌ Error updating document:", error);
    //         window.alert("❌ Error updating document: " + error.message);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (oneOrMorePlaylistSongsInvalid) {
            invalidSongAlert();
            return;
        }

        try {
            if (!user?.uid || !playlistToEdit) {
                console.error("❌ User ID or playlist data is missing.");
                return;
            }

            // Reference user's document
            const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.error("❌ User document not found.");
                return;
            }

            const userData = userSnap.data();

            // 🛑 Ensure `autoMusic` collection exists
            const existingPlaylists = userData.autoMusic?.playlists || [];

            // ❌ Prevent saving if the playlist name is empty
            if (!playlistToEdit.name.trim()) {
                noPlaylistNameAlert();
                return;
            }

            // 🔍 Remove empty rows (no URL or name)
            let cleanedPlaylistData = playlistToEdit.playlistData.filter(
                (item) => item.url.trim() !== "" && item.name.trim() !== ""
            );

            // ❌ Prevent saving if all rows are empty
            if (cleanedPlaylistData.length === 0) {
                window.alert("❌ Playlist must contain at least one valid entry with both a name and a URL.");
                return;
            }

            // 🛑 Ensure at least one item has `next: true`
            if (!cleanedPlaylistData.some((item) => item.next)) {
                cleanedPlaylistData[0].next = true; // ✅ Set first valid row as `next: true`
            }

            // 🔄 Update or add the playlist
            const existingPlaylistIndex = existingPlaylists.findIndex(
                (playlist) => playlist.playlistId === playlistToEdit.playlistId
            );

            let updatedPlaylists;
            if (existingPlaylistIndex !== -1) {
                // ✅ Update existing playlist
                updatedPlaylists = [...existingPlaylists];
                updatedPlaylists[existingPlaylistIndex] = {
                    ...playlistToEdit,
                    playlistData: cleanedPlaylistData, // Save cleaned data
                };
            } else {
                // ✅ Add new playlist
                updatedPlaylists = [
                    ...existingPlaylists,
                    { ...playlistToEdit, playlistData: cleanedPlaylistData },
                ];
            }

            // 📝 Save updated playlists to Firestore
            await updateDoc(userRef, {
                [`autoMusic.playlists`]: updatedPlaylists,
            });

            console.log("✅ Final Result Saved:", updatedPlaylists);

            // Automatically add the new playlist to the new music segment
            onChangeToAddNewPlaylistToMusicSegment(playlistToEdit);

            await dispatch(fetchUserData(user.uid));

            // Close modal after saving
            togglePlaylistModal();
        } catch (error) {
            console.error("❌ Error updating document:", error);
            window.alert("❌ Error updating document: " + error.message);
        }
    };


    const getYoutubePlaylistId = (url) => {
        const match = url.match(/[?&]list=([a-zA-Z0-9_-]+)/);
        return match ? match[1] : null;
    };
    
    const getYoutubePlaylistVideos = async (playlistUrl) => {
        const playlistId = getYoutubePlaylistId(playlistUrl);
        if (!playlistId) return [];
    
        try {
            const response = await fetch(
                `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=100&playlistId=${playlistId}&key=${API_KEY}`
            );
            const data = await response.json();
    
            return data.items.map((item) => ({
                next: false,
                active: true,
                url: `https://www.youtube.com/watch?v=${item.snippet.resourceId.videoId}`,
                name: item.snippet.title,
            }));
        } catch (error) {
            console.error("❌ Error fetching playlist videos:", error);
            return [];
        }
    };

    // const handleYoutubePlaylistUrlChange = async (e, index) => {
    //     const url = e.target.value;
    
    //     // ✅ Immediately update URL
    //     setPlaylistToEdit((prevData) => {
    //         const updatedPlaylistData = [...prevData.playlistData];
    //         updatedPlaylistData[index] = { ...updatedPlaylistData[index], url }; 
    //         return { ...prevData, playlistData: updatedPlaylistData };
    //     });
    
    //     // ✅ Check if it's a **playlist URL**
    //     if (url.includes("list=")) {
    //         let playlistVideos = await getYoutubePlaylistVideos(url);
    
    //         // ✅ Add all playlist videos
    //         setPlaylistToEdit((prevData) => ({
    //             ...prevData,
    //             playlistData: [...prevData.playlistData, ...playlistVideos],
    //         }));
    
    //         return;
    //     }
    
    //     // ✅ Fetch **single video** title for normal URLs
    //     let videoName = await getYoutubeTitleFromPage(url);
    
    //     // ✅ Update the single video title
    //     setPlaylistToEdit((prevData) => {
    //         const updatedPlaylistData = [...prevData.playlistData];
    //         updatedPlaylistData[index] = { ...updatedPlaylistData[index], name: videoName }; 
    //         return { ...prevData, playlistData: updatedPlaylistData };
    //     });
    // };

    const handleAddYoutubePlaylist = async () => {
        if (!youtubePlaylistUrl.trim()) {
            setUrlIsInvalid(true); // ✅ Mark as invalid if empty
            return;
        }

        let playlistVideos = await getYoutubePlaylistVideos(youtubePlaylistUrl);

        if (playlistVideos.length === 0) {
            setUrlIsInvalid(true); // ✅ Mark as invalid if no videos found
            return;
        }

        // ✅ Reset input field & close modal
        setUrlIsInvalid(false);
        setYoutubePlaylistUrl("");
        setAddYouTubePlaylistModal(false);

        // ✅ Add all playlist videos
        setPlaylistToEdit((prevData) => ({
            ...prevData,
            playlistData: [...prevData.playlistData, ...playlistVideos],
        }));
    };

    // const handlePlaylistDelete = async () => {
    //     try {
    //         // Clone user object to avoid direct mutations
    //         const docRef = doc(db, "users", user.uid);
    //         const userCopy = JSON.parse(JSON.stringify(user));
    
    //         // Ensure `autoMusic` and `playlists` exist
    //         if (!userCopy.autoMusic || !userCopy.autoMusic.playlists) {
    //             console.warn("No playlists found to delete.");
    //             return;
    //         }
    
    //         // Find index of playlist to delete
    //         const playlistIndex = userCopy.autoMusic.playlists.findIndex(
    //             (playlist) => playlist.playlistId === playlistToEdit.playlistId
    //         );
    
    //         // If playlist not found, return
    //         if (playlistIndex === -1) {
    //             console.warn("Playlist not found.");
    //             return;
    //         }
    
    //         // Remove playlist from the array
    //         userCopy.autoMusic.playlists.splice(playlistIndex, 1);
    
    //         // Save updated user data to Firestore
    //         await setDoc(docRef, userCopy);
    
    //         console.log("✅ Playlist deleted successfully:", playlistToEdit.playlistId);
    
    //         // Show success alert
    //         successDelete();
    
    //         // Close modal after deleting
    //         togglePlaylistModal();
    //     } catch (error) {
    //         console.error("❌ Error deleting playlist:", error);
    //         window.alert("❌ Error deleting playlist: " + error.message);
    //     }
    // };

    const handlePlaylistDelete = async () => {
        try {
            if (!user?.uid || !playlistToEdit?.playlistId) {
                console.error("❌ User ID or Playlist ID is missing.");
                return;
            }

            // Reference user's Firestore document
            const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.error("❌ User document not found.");
                return;
            }

            const userData = userSnap.data();

            // Ensure `autoMusic.playlists` exist
            if (!userData.autoMusic?.playlists || userData.autoMusic.playlists.length === 0) {
                console.warn("⚠️ No playlists found to delete.");
                return;
            }

            // Filter out the playlist to be deleted
            const updatedPlaylists = userData.autoMusic.playlists.filter(
                (playlist) => playlist.playlistId !== playlistToEdit.playlistId
            );

            // Update Firestore with the new playlists array
            await updateDoc(userRef, { "autoMusic.playlists": updatedPlaylists });

            console.log("✅ Playlist deleted successfully:", playlistToEdit.playlistId);

            // Show success alert
            successDelete();

            // Close modal after deleting
            togglePlaylistModal();
        } catch (error) {
            console.error("❌ Error deleting playlist:", error);
            window.alert("❌ Error deleting playlist: " + error.message);
        }
    };

    const noPlaylistNameAlert = () => {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="No Playlist Name"
            onConfirm={() => hideAlert()}
            confirmBtnBsStyle="info button-hover-fix"
            confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
            confirmBtnText="Okay"
            btnSize=""
          >
            Please add a name for your playlist then try again.
          </ReactBSAlert>
        );
    };

    const invalidSongAlert = () => {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="One or More Invalid Songs"
            onConfirm={() => hideAlert()}
            confirmBtnBsStyle="info button-hover-fix"
            confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
            confirmBtnText="Okay"
            btnSize=""
          >
            Please edit any songs whose title's say "Title Not Found" then try again.
          </ReactBSAlert>
        );
    };

    const deletePlaylistAlert = () => {
        setAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px" }}
            title="Are you sure?"
            onConfirm={() => handlePlaylistDelete()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
            cancelBtnBsStyle="danger"
            cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
            confirmBtnText="Yes, delete it!"
            cancelBtnText="Cancel"
            showCancel
            btnSize=""
          >
            You will not be able to recover this playlist and will need to choose a new playlist for any music segment that is using it.
          </ReactBSAlert>
        );
      };

      const successDelete = () => {
        setAlert(
          <ReactBSAlert
            success
            style={{ display: "block", marginTop: "-100px" }}
            title="Deleted!"
            onConfirm={() => hideAlert()}
            onCancel={() => hideAlert()}
            confirmBtnBsStyle="info"
            btnSize=""
          >
            Your playlist has been deleted.
          </ReactBSAlert>
        );
      };

      const hideAlert = () => {
        setAlert(null);
      };

    return (
        <Modal contentClassName="add-edit-playlist-modal" isOpen={showPlaylistModal} toggle={togglePlaylistModal}>
            {alert}
            <ModalHeader toggle={togglePlaylistModal}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Add/Edit Playlist</h4>
            </ModalHeader>
            <ModalBody>
                <Row className="mt-3 mb-4">
                    <Col>
                        <Input
                            type="text"
                            value={playlistToEdit?.name}
                            onChange={(e) =>
                                setPlaylistToEdit((prevData) => ({
                                    ...prevData,
                                    name: e.target.value,
                                }))
                            }
                            invalid={playlistToEdit.name?.length < 1}
                            placeholder="Enter A Name For Your Playlist Here..."
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                textAlign: "center",
                                borderRadius: '8px'
                            }}
                        />
                    </Col>
                </Row>

                {/* Table Container */}
                <div className='playlist-table' style={{ maxHeight: "400px", overflowY: "auto", border: "1px solid #ddd", borderRadius: "8px" }}>
                    <Table style={{ marginBottom: '0px' }} >
                        <thead className="text-primary" style={{ position: "sticky", top: "0", backgroundColor: "white", zIndex: 1}}>
                            <tr>
                                <th className="text-center" id="autoMusicPlaylistNext" style={{ width: '8%' }}>Next</th>
                                <th className="text-left" id="autoMusicPlaylistYouTubeURL" style={{ width: '32%' }}>YouTube URL</th>
                                <th className="text-left" id="autoMusicPlaylistYouTubeTitle" style={{ width: '32%' }}>YouTube Title</th>
                                <th className="text-center" id="autoMusicPlaylistYouTubeActive" style={{ width: '8%' }}>Active</th>
                                <th className="text-center" id="autoMusicPlaylistYouTubeOrder" style={{ width: '10%' }}>Order</th>
                                <th className="text-center" id="autoMusicPlaylistYouTubeActions" style={{ width: '10%' }}>Actions</th>
                                <UncontrolledTooltip delay={0} target={"autoMusicPlaylistNext"} placement="bottom">
                                This is the song that will play next. Auto Music will automatically cycle through your playlist, but you can manually set the next song by clicking the next checkbox.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} target={"autoMusicPlaylistYouTubeURL"} placement="bottom">
                                Enter the YouTube URL here.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} target={"autoMusicPlaylistYouTubeTitle"} placement="bottom">
                                Lesson Launchpad will attempt to get the name of the video from YouTube for you.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} target={"autoMusicPlaylistYouTubeActive"} placement="bottom">
                                If checked, the song will play. If unchecked, the song will not play.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} target={"autoMusicPlaylistYouTubeOrder"} placement="bottom">
                                Change the order of the songs in this playlist.
                                </UncontrolledTooltip>
                                <UncontrolledTooltip delay={0} target={"autoMusicPlaylistDeleteSong"} placement="bottom">
                                    Remove this song from the playlist.
                                </UncontrolledTooltip>
                            </tr>
                        </thead>

                        <tbody>
                            {playlistToEdit?.playlistData?.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-center">
                                        <Input
                                            type="checkbox"
                                            checked={item.next}
                                            onChange={(e) => {
                                                setPlaylistToEdit((prevData) => ({
                                                    ...prevData,
                                                    playlistData: prevData.playlistData.map((el, idx) => ({
                                                        ...el,
                                                        next: idx === index ? e.target.checked : false
                                                    }))
                                                }));
                                            }}
                                            style={{
                                                marginLeft: '-5px',
                                                marginTop: '-6px',
                                                transform: 'scale(2)',
                                            }}
                                        />
                                    </td>
                                    <td className="text-left">
                                        <Input
                                            placeholder='Paste YouTube URL here...'
                                            type="text"
                                            value={item.url}
                                            invalid={item.url.length < 1}
                                            style={{borderRadius: '8px'}}
                                            onChange={(e) => handleYoutubeUrlChange(e, index)}
                                        />
                                    </td>
                                    <td className="text-left">
                                        <Input 
                                            type="text" 
                                            value={item.name} 
                                            disabled 
                                            style={{borderRadius: '8px'}} 
                                            invalid={item.name === "Title Not Found"} 
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Input
                                            type="checkbox"
                                            checked={item.active}
                                            onChange={(e) => {
                                                setPlaylistToEdit((prevData) => ({
                                                    ...prevData,
                                                    playlistData: prevData.playlistData.map((el, idx) =>
                                                        idx === index ? { ...el, active: e.target.checked } : el
                                                    )
                                                }));
                                            }}
                                            style={{
                                                marginLeft: '-5px',
                                                marginTop: '-6px',
                                                transform: 'scale(2)'
                                            }}
                                        />
                                    </td>
                                    <td className="text-center">
                                        <Button className="btn-icon btn-neutral" color="secondary" size="md" onClick={() => handleMoveUp(index)}>
                                            <i className="nc-icon nc-minimal-up" />
                                        </Button>
                                        <Button className="btn-icon btn-neutral" color="secondary" size="md" onClick={() => handleMoveDown(index)}>
                                            <i className="nc-icon nc-minimal-down" />
                                        </Button>
                                    </td>
                                    <td className="text-center">
                                        <Button id="autoMusicPlaylistDeleteSong" className="btn-icon btn-neutral" color="danger" size="md" onClick={() => deletePlaylistRow(index)}>
                                            <i className="fa fa-trash icon-delete" />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>

                

                {/* Add Row Button (Stationary Below Table) */}
                <Row className="text-center" style={{ marginTop: '0px' }}>
                    <Col className="add-row-to-playlist-col" onClick={handleAddRow}>
                        <h6>Add Row</h6>
                    </Col>
                </Row>
            </ModalBody>

            {/* 🎵 YouTube Playlist Input Row */}
            <Row className="m-2">
                <Col style={{ textAlign: 'left' }}>
                    {!addYouTubePlaylistModal ? (
                        <Button
                            onClick={() => setAddYouTubePlaylistModal(true)}
                            onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                            onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            style={{
                                borderRadius: '30px',
                                marginLeft: '40px',
                                backgroundColor: '#28C76F',
                                width: '250px'
                            }}
                        >
                            <i className='fa fa-plus' />
                            <span>{' '}Add YouTube Playlist</span>
                        </Button>
                    ) : (
                        <div 
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '3px 12px',
                                borderRadius: '30px',
                                margin: '10px 0px 10px 40px',
                                width: '350px',
                                height: '41px',
                                backgroundColor: '#28C76F',
                            }}
                        >
                            <Input
                                type="text"
                                placeholder="Enter YouTube Playlist URL Here..."
                                value={youtubePlaylistUrl}
                                style={{                         
                                    width: '225px',
                                    borderRadius: '8px',
                                    height: '35px',
                                    flexGrow: 1,
                                    marginRight: '8px',
                                    border: urlIsInvalid ? "2px solid red" : "none"
                                }}
                                onChange={(e) => setYoutubePlaylistUrl(e.target.value)}
                            />
                            <i 
                                className='fa fa-check' 
                                style={{ fontSize: '20px', color: 'white', cursor: 'pointer' }}
                                onClick={handleAddYoutubePlaylist}
                                onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}  
                            />
                        </div>
                    )}
                </Col>
                <Col style={{ textAlign: 'right'}}>
                    <Button
                        onClick={(e) => handleSubmit(e)}
                        style={{
                            borderRadius: '30px',
                            width: '100px'
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <i className='fa fa-save' />
                        {' '}Save
                    </Button>
                    <Button
                    className='button-delete'
                        onClick={() => deletePlaylistAlert()}
                        style={{
                            borderRadius: '30px',
                            width: '100px',
                            marginRight: '40px'
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
                        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                    >
                        <i className='fa fa-trash' />
                        {' '}Delete
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
}

import React, { useState, useEffect } from 'react'
import { Input, Card, Row, Col, CardTitle, CardHeader, CardFooter, Button, UncontrolledTooltip, CardSubtitle, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function SidebarNotificationAndAlert({data}) {
    const [showModal, setShowModal] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    const handleModalToggle = () => setShowModal(!showModal);

    useEffect(() => {
        if (!data?.startTime || !data?.endTime) return;
    
        const now = Date.now();
    
        const start = data.startTime.seconds * 1000 + Math.floor(data.startTime.nanoseconds / 1e6);
        const end = data.endTime.seconds * 1000 + Math.floor(data.endTime.nanoseconds / 1e6);
    
        if (now >= start && now <= end) {
          setIsVisible(true);
        }
    }, [data]);

    return (
        <>
            {isVisible && 
                <i
                className="fa fa-bell"
                style={{
                    position: 'absolute',
                    bottom: -3,
                    right: -3,
                    background: 'white',
                    color: '#fa5656',
                    borderRadius: '50%',
                    padding: '3px',
                    fontSize: '12px',
                    zIndex: 9999,
                }}
                onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.2)";
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                }}
                onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleModalToggle()}}
                >
                </i>
            }
            <Modal isOpen={showModal} toggle={handleModalToggle}>
                <ModalHeader toggle={handleModalToggle} style={{border: '0px'}}>
                </ModalHeader>
                <ModalBody style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', padding: '0px 20px' }}>
                    <h4 style={{margin: '5px'}}>{data?.title}</h4>
                    <p>{data?.message}</p>
                    {/* <table border="1" cellpadding="8" cellspacing="0">
                        <thead>
                        <tr>
                        <th>Date</th>
                        <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Thursday, March 27th</td>
                        <td>7:00pm EST</td>
                        </tr>
                        <tr>
                        <td>Sunday, March 30th</td>
                        <td>3:00pm EST</td>
                        </tr>
                        <tr>
                        <td>Tuesday, April 1st</td>
                        <td>1:00pm EST</td>
                        </tr>
                    </tbody>
                    </table> */}

                </ModalBody>
                <ModalFooter style={{border: '0px', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    {data?.button.active && 
                        <a
                        href={data?.button.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                        >
                        <Button
                            className='button-hover-fix'
                            style={{
                            borderRadius: '30px'
                            }}
                        >
                            {data?.button.text}
                        </Button>
                        </a>
                    }
                </ModalFooter>
            </Modal>
        </>
    )
}

export default SidebarNotificationAndAlert
import React, { useState } from "react";
import { Row, Col, FormGroup, Input, Label, UncontrolledTooltip } from "reactstrap";
import Scheduling from '/Users/michaeltucker/Documents/coding-projects/client/src/utils/Scheduling.js';

const ScheduleManager = ({ data, handleScheduleChange, user }) => {
  const [currentWeek, setCurrentWeek] = useState(Scheduling.weekDayLetterOptions[0]);
  const numberOfWeeks = user.settings.numberOfWeeks ?? 1;
  const multiWeek = user.settings.multiWeek || false;
  const differentTimes = user.settings.differentTimes || false;
  const useDays = user.settings.cycleType === "day" || false;

  const cycleWeeks = (direction) => {
    let currentIndex = Scheduling.weekDayLetterOptions.indexOf(currentWeek);

    if (direction === "forward") {
      currentIndex = currentIndex === numberOfWeeks - 1 ? 0 : currentIndex + 1;
    } else {
      currentIndex = currentIndex === 0 ? numberOfWeeks - 1 : currentIndex - 1;
    }
    setCurrentWeek(Scheduling.weekDayLetterOptions[currentIndex]);
  };

  const ScheduleCheckboxes = ({ schedule, week }) => (
    multiWeek && useDays ? (
      <Row style={{ margin: "10px 0px", display: "flex", justifyContent: "space-evenly" }}>
        <Col key={"Monday"} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Label for={"monday"} style={{ fontSize: "14px", fontWeight: "bold", cursor: "help" }} id="activeToolTip">Active</Label>
          <UncontrolledTooltip delay={0} target={"activeToolTip"} placement="top">
            Does this class meet on this day? If yes, check the box.
          </UncontrolledTooltip>
          <FormGroup style={{ display: "flex", alignItems: "center" }}>
            <Input
              onChange={(e) => handleScheduleChange(week, "Monday", "active", e.target.checked)}
              type="checkbox"
              id={"monday"}
              checked={schedule?.[week]?.["Monday"]?.active || false}
              style={{ transform: "scale(2)", position: "initial", marginLeft: "0px" }}
            />
          </FormGroup>
        </Col>
      </Row>
    ) : (
      <Row style={{ margin: "10px 0px", display: "flex", justifyContent: "space-evenly" }}>
        {Scheduling.daysOfWeek.map((day) => (
          <Col key={day} style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            <Label for={day.toLowerCase()} style={{ fontSize: "14px", fontWeight: "bold", cursor: "help" }} id="activeToolTip">{day}</Label>
            <UncontrolledTooltip delay={0} target={"activeToolTip"} placement="top">
              Does this class meet on this day? If yes, check the box.
            </UncontrolledTooltip>
            <FormGroup style={{ display: "flex", alignItems: "center" }}>
              <Input
                onChange={(e) => handleScheduleChange(week, day, "active", e.target.checked)}
                type="checkbox"
                id={day.toLowerCase()}
                checked={schedule?.[week]?.[day]?.active || false}
                style={{ transform: "scale(2)", position: "initial", marginLeft: "0px" }}
              />
            </FormGroup>
          </Col>
        ))}
      </Row>
    )
  );

  const WeekSelectorForMultiWeek = () => (
    <Row style={{ marginTop: "30px", marginBottom: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <i
        className="nc-icon nc-minimal-left"
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => cycleWeeks("backward")}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
      <h3
        style={{ marginLeft: "15px", marginRight: "15px", marginBottom: "0px", fontWeight: "bold", color: "#004aad", cursor: "help" }}
        id="dayWeekSelectorForMultiWeek"
      >
        <UncontrolledTooltip delay={0} target={"dayWeekSelectorForMultiWeek"} placement="top">
          Use the arrows to switch {useDays ? "days" : "weeks"}.
        </UncontrolledTooltip>
        {useDays ? "Day" : "Week"} {currentWeek}
      </h3>
      <i
        className="nc-icon nc-minimal-right"
        style={{ cursor: "pointer", fontSize: "20px" }}
        onClick={() => cycleWeeks("forward")}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      />
    </Row>
  );

  const ScheduleRow = ({ week, day }) => (
    <Row style={{ marginLeft: "10px", display: "flex", justifyContent: "space-evenly" }}>
      <Col md="3"></Col>
      <Col md="2" style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <label id="activeToolTip" style={{ fontSize: "14px", cursor: "help" }}>
          {multiWeek && useDays ? "Active" : day}
        </label>
        <UncontrolledTooltip delay={0} target={"activeToolTip"} placement="top">
          {multiWeek && useDays ? "Does this class meet on this day?" : "Is this day active?"}
        </UncontrolledTooltip>
        <FormGroup style={{ display: "flex", alignItems: "center" }}>
          <Input
            onChange={(e) => handleScheduleChange(week, day, "active", e.target.checked)}
            type="checkbox"
            id={day.toLowerCase()}
            checked={data.schedule[week]?.[day]?.active || false}
            style={{ transform: "scale(2)", position: "initial", marginLeft: "0px", marginTop: "14px" }}
          />
        </FormGroup>
      </Col>
      <Col md="4">
        <label id="startTimeTip" style={{ fontSize: "14px", cursor: "help" }}>
          Start Time
        </label>
        <UncontrolledTooltip delay={0} target={"startTimeTip"} placement="top">
          What time does this class start on this day?
        </UncontrolledTooltip>
        <FormGroup>
          <Input
            onChange={(e) => handleScheduleChange(week, day, "startTime", e.target.value)}
            type="time"
            id="startTime"
            style={{ borderRadius: "8px" }}
            value={data.schedule[week]?.[day]?.active ? data.schedule[week]?.[day]?.startTime || "" : ""}
            step="1"
          />
        </FormGroup>
      </Col>
      <Col md="3"></Col>
    </Row>
  );

  return (
    <>
      {differentTimes && !multiWeek && (
        <>
          {Scheduling.daysOfWeek.map((day) => (
            <ScheduleRow key={day} week="A" day={day} />
          ))}
        </>
      )}

      {differentTimes && multiWeek && (
        <>
          <WeekSelectorForMultiWeek />
          {user.settings.cycleType === "day" ? (
            <ScheduleRow key={currentWeek} week={currentWeek} day="Monday"/>
          ) : (
            Scheduling.daysOfWeek.map((day) => (
              <ScheduleRow key={day} week={currentWeek} day={day}/>
            ))
          )}
        </>
      )}

      {!multiWeek && !differentTimes && <ScheduleCheckboxes schedule={data.schedule} week="A" />}

      {multiWeek && !differentTimes && (
        <>
          <WeekSelectorForMultiWeek />
          <ScheduleCheckboxes schedule={data.schedule} week={currentWeek} />
        </>
      )}
    </>
  );
};

export default ScheduleManager;

import React from 'react'
import { NavLink } from 'react-router-dom';
import { Card, CardBody, Row, Col, Navbar, NavbarBrand,
     NavItem, UncontrolledAccordion, AccordionItem,
      AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import { Link } from 'react-router-dom';

const LandingPage = () => {

    const isMobile = /Mobi/.test(navigator.userAgent);

  return (
    <>
    { !isMobile ? (
            <div style={{ backgroundColor: '#f0f0f0' }}>
            <Row style={{justifyContent: 'center', backgroundColor: "rgb(11, 192, 223)"}}>
                <Card style={{width: '80%', marginTop: '1%'}}>
                    <Navbar className='landing-navbar'>
                        <NavbarBrand>
                            <a href={"https://www.lessonlaunchpad.com"}>
                            <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '175px', height: 'auto' }}/>
                            </a>
                        </NavbarBrand>
    
                        {/* <div className="ml-auto d-flex align-items-center">
                            <NavLink to="/why-lesson-launchpad" style={{ color: 'black', marginRight: '50px' }}>
                                Why Lesson Launchpad?
                            </NavLink>
                            <NavLink to="/pricing" style={{ color: 'black', marginRight: '0px' }}>
                                Pricing
                            </NavLink>
                        </div> */}
                        
                        <div className="ml-auto d-flex align-items-center">
                            <NavLink to="/why-lesson-launchpad" style={{ color: 'black', marginRight: '50px' }}>
                                Why Lesson Launchpad?
                            </NavLink>
    
                            <NavLink to="/auth/login" style={{ color: 'black', marginRight: '50px' }}>
                            Login
                            </NavLink>
    
                            <Button tag={Link} to="/auth/register" style={{ marginLeft: '10px' }}>
                            Register
                            </Button>
                        </div>
                    </Navbar>
                </Card>
            </Row>
    
    {/************************************************* HEADLINE *************************************************/}
    
            <Row style={{ height: '700px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ textAlign: 'center', marginBottom: '280px' }}>
                <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '4rem', textShadow: '1px 1px 0px #004aad' }}>
                <b>Your Lesson</b>
                </h1>
                <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '8rem', marginTop: '-1rem' }}>
                <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>AUTOMATED</i></b></div>
                </h1>
                <Button
                tag={Link}
                to="/auth/register"
                className="landing-btn-no-hover"
                style={{
                    backgroundColor: 'white',
                    color: 'rgb(11, 192, 223)',
                    height: '50px',
                    width: '200px',
                    fontSize: '1.5rem',
                }}
                >
                Get Started
                </Button>
            </div>
            <img
                className='landing-page-macbook'
                src={macbook}
                alt="lesson-launchpad-on-macbook"
            />
            </Row>
    
    {/************************************************* MACBOOK IMAGE SPACING *************************************************/}
    
            <Row style={{ height: '350px' }}>
    
            </Row>
    
    {/************************************************* FEATURES *************************************************/}
    
            <Row style={{ justifyContent: 'center', fontFamily: 'Helvetica, Arial, sans-serif', height: '430px' }}>
                <Col md="2"></Col>
                <Col md="4" className="d-flex flex-column" style={{ minHeight: '100%', marginTop: '25px' }}>
                    <div className="align-self-center">
                    <h1 style={{ color: '#004aad', textShadow: '1px 1px 1px rgb(11, 192, 223)'}}><b>Current Features:</b></h1>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Scrolling Marquee</li>
                        <li>Advanced Text Editor</li>
                        <li>Clock</li>
                        <li>Google Slides Compatible</li>
                        <li>Timer</li>
                        <li>Stopwatch</li>
                        <li>Scheduled Reminders</li>
                        <li>Automation Features:</li>
                            <ul>
                                <li>Auto Class Change</li>
                                <li>Auto Countdown</li>
                            </ul>
                    </ul>
                    </div>
                </Col>
                <Col md="4" className="d-flex flex-column" style={{ minHeight: '100%', marginTop: '25px' }}>
                    <div className="align-self-center">
                    <h1 style={{ color: '#004aad', textShadow: '1px 1px 1px rgb(11, 192, 223)'}}><b>Coming Soon:</b></h1>
                    <ul style={{ fontSize: '18px' }}>
                        <li>Themes*</li>
                        <li>Flash Cards*</li>
                        <li>Random Student Picker*</li>
                        <li>Automation Features:</li>
                            <ul>
                                <li>Auto Happy Birthday*</li>
                                <li>Auto Music Between Classes*</li>
                            </ul>
    
                    </ul>
                    </div>
                </Col>
                <Col md="2"></Col>
            </Row>
    
    {/************************************************* USE CASES *************************************************/}
    
    {/* Carousel? */}
    <hr></hr>

    
    {/************************************************* PRICING *************************************************/}
    
    <Row style={{ height: '550px', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', marginBottom: '100px', marginTop: '50px' }}>
            <h1 style={{ color: '#004aad', textShadow: '1px 1px 1px rgb(11, 192, 223)'}}>
            <b><i> Cool! How much does it cost?</i></b>
            </h1>
            <h3 style={{ color: '#004aad', marginBottom: '5px', fontWeight: '600'}} >
            Unlock the power of Lesson Launchpad at an unbeatable value:
            </h3>
            <h5 style={{ color: '#004aad'}} >
            <li>Just $2.99 per month</li>
            </h5>
            <h5 style={{ color: '#004aad'}} >
            <li>Or enjoy a full year of access for only $29.99</li>
            </h5>
            <h5 style={{ color: '#004aad'}} >
            </h5>
            <h3 style={{ color: '#004aad', marginBottom: '5px', marginTop: '30px', fontWeight: '600'}} >
            But wait, there's more:
            </h3>
            <h5 style={{ color: '#004aad'}} >
            <li>Dive in risk-free with a 30-day full-featured trial.</li>
            </h5>
            <h5 style={{ color: '#004aad'}} >
            <li>No credit card required until you're ready to take the plunge.</li>
            </h5>
            {/* <Button
            tag={Link}
            to="/auth/register"
            className="landing-btn-no-hover"
            style={{
                backgroundColor: 'white',
                color: 'rgb(11, 192, 223)',
                height: '50px',
                width: '200px',
                fontSize: '1.5rem',
            }}
            >
            Get Started
            </Button> */}
            <hr></hr>
             <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: '#004aad', height: '75px', width: 'auto', fontSize: '20px' }}>
                <div className="d-flex align-items-center">
                <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
                Discover the possibilities with Lesson Launchpad today!
                </div>
            </Button>
        </div>
    </Row>


    {/************************************************* Footer *************************************************/}

    <Row style={{ height: '100px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
            <div style={{margin: '20px', color: 'white'}}>
                <NavLink to="/privacy" style={{color: 'white'}}>Privacy Policy</NavLink>
            </div>
            <div style={{margin: '20px', color: 'white'}}>
                <NavLink to="/contact" style={{color: 'white'}}>Contact</NavLink>
            </div>
    </Row>
    
    
    {/************************************************* DEMO *************************************************/}
    
            {/* <Row style={{minHeight: '2000px', backgroundColor: 'pink'}}>
                <div style={{ backgroundColor: 'red', height: '800px', width: '1600px', margin: '50px', padding: '10px'}}>
                    <PresentDemoForLandingPage />
                </div>
            </Row> */}
    
    
    {/************************************************* FOOTER *************************************************/}
    
            {/* <Row className="ml-auto" style={{ backgroundColor: "rgb(11, 192, 223)", height: '100px', justifyContent: 'center' }}>
            <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '75px', width: 'auto', fontSize: '20px' }}>
                <div className="d-flex align-items-center">
                <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
                Try Lesson Launchpad NOW
                </div>
            </Button>
            </Row>
     */}
    
    
    
    
        </div>
    ) : (
        <div style={{ backgroundColor: '#f0f0f0' }}>
        <Row style={{justifyContent: 'center', backgroundColor: "rgb(11, 192, 223)"}}>
            <Card style={{width: '90%', marginTop: '2%', height: '175px'}}>
                <Navbar className='landing-navbar'>
                    <NavbarBrand>
                        <a href={"https://www.lessonlaunchpad.com"}>
                        <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '400px', height: 'auto' }}/>
                        </a>
                    </NavbarBrand>
                    
                    <div className="ml-auto d-flex align-items-center">
                        <NavLink to="/auth/login" style={{ color: 'black', marginRight: '50px', fontSize: '40px' }}>
                        Login
                        </NavLink>

                        <Button tag={Link} to="/auth/register" style={{ marginLeft: '10px', height: '100px', fontSize: '60px' }}>
                        Register
                        </Button>
                    </div>
                </Navbar>
            </Card>
        </Row>

{/************************************************* HEADLINE *************************************************/}

        <Row style={{ height: '1000px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ textAlign: 'center', marginBottom: '400px' }}>
            <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '6rem', textShadow: '1px 1px 0px #004aad' }}>
            <b>Your Lesson</b>
            </h1>
            <h1 style={{ color: 'white', fontWeight: '1000', fontSize: '12rem', marginTop: '-1rem' }}>
            <div style={{ textShadow: '2px 2px 0px #004aad'}}><b><i>AUTOMATED</i></b></div>
            </h1>
            <Button
            tag={Link}
            to="/auth/register"
            className="landing-btn-no-hover"
            style={{
                backgroundColor: 'white',
                color: 'rgb(11, 192, 223)',
                height: '100px',
                width: '500px',
                fontSize: '60px',
            }}
            >
            Get Started
            </Button>
        </div>
        <img
            className='landing-page-macbook'
            src={macbook}
            alt="lesson-launchpad-on-macbook"
            style={{width: '90%', marginTop: '200px'}}
        />
        </Row>

{/************************************************* MACBOOK IMAGE SPACING *************************************************/}

        <Row style={{ height: '700px' }}>

        </Row>

{/************************************************* FEATURES *************************************************/}

        <Row style={{ justifyContent: 'center', fontFamily: 'Helvetica, Arial, sans-serif', height: '1900px' }}>
            <div className="d-flex flex-column" style={{  }}>
                <div className="align-self-center">
                <h1 style={{ color: 'rgb(11, 192, 223)', textShadow: '1px 1px 1px #004aad', fontSize: '120px'}}><b>Current Features</b></h1>
                <ul style={{ fontSize: '60px', marginLeft: '100px' }}>
                    <li>Scrolling Marquee</li>
                    <li>Advanced Text Editor</li>
                    <li>Clock</li>
                    <li>Google Slides Compatible</li>
                    <li>Timer</li>
                    <li>Stopwatch</li>
                    <li>Scheduled Reminders</li>
                    <li>Automation Features:</li>
                        <ul>
                            <li>Auto Class Change</li>
                            <li>Auto Countdown</li>
                        </ul>
                </ul>
                </div>
            </div>
            <div className="d-flex flex-column" style={{  }}>
                <div className="align-self-center">
                <h1 style={{ color: 'rgb(11, 192, 223)', textShadow: '1px 1px 1px #004aad', fontSize: '120px'}}><b>Coming Soon</b></h1>
                <ul style={{ fontSize: '60px' }}>
                    <li>Themes*</li>
                    <li>Flash Cards*</li>
                    <li>Random Student Picker*</li>
                    <li>Automation Features:</li>
                        <ul>
                            <li>Auto Happy Birthday*</li>
                            <li>Auto Music Between Classes*</li>
                        </ul>

                </ul>
                </div>
            </div>
        </Row>

{/************************************************* USE CASES *************************************************/}

{/* Carousel? */}


{/************************************************* PRICING *************************************************/}

<Row style={{ height: '1300px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ textAlign: 'center', marginBottom: '100px', marginLeft: '10%', marginRight: '10%', marginTop: '50px' }}>
        <h1 style={{ color: 'white', fontWeight: '1000', textShadow: '1px 1px 0px #004aad', fontSize: '80px' }}>
        <b><i> Cool! How much does it cost?</i></b>
        </h1>
        <h3 style={{ color: 'white', marginBottom: '5px', fontWeight: '600', fontSize: '60px'}} >
        Unlock the power of Lesson Launchpad at an unbeatable value:
        </h3>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <li>Just $2.99 per month.</li>
        </h5>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <li>Or enjoy a full year of access for only $29.99.</li>
        </h5>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        </h5>
        <h3 style={{ color: 'white', marginBottom: '5px', marginTop: '30px', fontWeight: '600', fontSize: '60px'}} >
        But wait, there's more:
        </h3>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <li>Dive in risk-free with a 30-day full-featured trial.</li>
        </h5>
        <h5 style={{ color: 'white', fontSize: '50px'}} >
        <li>No credit card required until you're ready to take the plunge.</li>
        </h5>
        {/* <Button
        tag={Link}
        to="/auth/register"
        className="landing-btn-no-hover"
        style={{
            backgroundColor: 'white',
            color: 'rgb(11, 192, 223)',
            height: '50px',
            width: '200px',
            fontSize: '1.5rem',
        }}
        >
        Get Started
        </Button> */}
         <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '150px', width: 'auto', fontSize: '50px', borderRadius: '30px', marginTop: '50px' }}>
            <div className="d-flex align-items-center">
            <img src={logo} alt="lesson-launchpad-logo" style={{ width: '125px', height: 'auto', marginRight: '15px' }} />
            Discover the possibilities with Lesson Launchpad today!
            </div>
        </Button>
    </div>
</Row>

        {/* 30 day full featured trial. No credit card required. Try anything you want, no obligations. */}


{/************************************************* DEMO *************************************************/}

        {/* <Row style={{minHeight: '2000px', backgroundColor: 'pink'}}>
            <div style={{ backgroundColor: 'red', height: '800px', width: '1600px', margin: '50px', padding: '10px'}}>
                <PresentDemoForLandingPage />
            </div>
        </Row> */}


{/************************************************* FOOTER *************************************************/}

        {/* <Row className="ml-auto" style={{ backgroundColor: "rgb(11, 192, 223)", height: '100px', justifyContent: 'center' }}>
        <Button tag={Link} to="/auth/register" className="landing-btn-no-hover" style={{ backgroundColor: 'white', color: "rgb(11, 192, 223)", height: '75px', width: 'auto', fontSize: '20px' }}>
            <div className="d-flex align-items-center">
            <img src={logo} alt="lesson-launchpad-logo" style={{ width: '50px', height: 'auto', marginRight: '15px' }} />
            Try Lesson Launchpad NOW
            </div>
        </Button>
        </Row>
 */}




    </div>
    )}
   </>
  )
}

export default LandingPage

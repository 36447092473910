import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser } from 'features/userSlice';
import { useLocation } from 'react-router-dom';
import { Input, Card, Row, Col, CardTitle, CardHeader, CardFooter, Button, UncontrolledTooltip, CardSubtitle, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Contact from './Contact';
import { NavLink, useHistory, Prompt } from 'react-router-dom';
import Changelog from 'components/Changelog';
import Switch from "react-bootstrap-switch";
import { createShareCode } from 'RandomId';
import ReactBSAlert from "react-bootstrap-sweetalert";
import UpdateUserEmail from 'components/UpdateUserEmail';
import FontSizePicker from 'components/FontSizePicker';
import FontStylePicker from 'components/FontStylePicker';
import Scheduling from 'utils/Scheduling';
import InfoBubbleButton from 'components/InfoBubbleButton';
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";
import { fetchUserData } from 'features/userSlice';

const Thing = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch()
  const [settings, setSettings] = useState(user?.settings || {});
  const [dirty, setDirty] = useState(false); // Track if settings are "dirty"
  const [saved, setSaved] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  const [updateUserEmailModal, setUpdateUserEmailModal] = useState(false);
  const formattedShareURL = useMemo(() => user?.shareCode ? `www.lessonlaunchpad.com/share/${user.shareCode}` : "", [user]);
  const buttonRefs = useRef({}); // Store button refs for each tooltip
  const tooltipRefs = useRef({}); // Store refs for each tooltip element
  const [tooltips, setTooltips] = useState({}); // State to control open tooltips

  // Function to toggle a specific tooltip
  const toggleTooltip = (key) => {
    setTooltips((prev) => ({
      ...prev,
      [key]: !prev[key], // Toggle only the specific tooltip
    }));
  };

  // Close tooltips when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      Object.keys(tooltipRefs.current).forEach((key) => {
        if (
          tooltips[key] &&
          tooltipRefs.current[key] &&
          !tooltipRefs.current[key].contains(event.target) &&
          !buttonRefs.current[key]?.contains(event.target)
        ) {
          setTooltips((prev) => ({ ...prev, [key]: false }));
        }
      });
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [Object.keys(tooltips).length]); // Use Object.keys() to avoid re-render loops

  const changelogRef = useRef(null);
  const contactRef = useRef(null)
  const location = useLocation();

  useEffect(() => {
    const scrollToSection = () => {
      if (location.hash === "#changelog" && changelogRef.current) {
        changelogRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      } else if (location.hash === "#contact" && contactRef.current) {
        contactRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };
  
    const timeout = setTimeout(scrollToSection, 300);
    return () => clearTimeout(timeout);
  }, [location, changelogRef, contactRef]); 

  const toggleUpdateUserEmailModal = () => setUpdateUserEmailModal(!updateUserEmailModal);
 
  // Track the initial settings to compare with later
  const initialSettingsRef = useRef(settings);

  const toggleModal = () => setModal(!modal);

  const handleSettingChange = (newSettings) => {
    // console.log(newSettings)
    if (JSON.stringify(initialSettingsRef.current) !== JSON.stringify(newSettings)) {
      setDirty(true); // Mark settings as dirty if they've changed
    } else {
      setDirty(false); // Reset dirty flag if reverted to original
    }
    setSettings((prevSettings) => ({
      ...prevSettings,
      ...newSettings,
    }));
  };

  // const saveSettings = async () => {
  //   if (!user || !dirty) return; // Only save if settings are dirty and user exists

  //   if (settings.multiWeek === true && settings.cycleType === undefined) {
  //     noCycleTypeAlert()
  //   }

  //   try {
  //     const docRef = doc(db, "users", user.uid);

  //     // Create a deep copy of the user object
  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     // Merge new settings into existing settings
  //     userCopy.settings = {
  //       ...userCopy.settings,
  //       ...settings
  //     };

  //     // Update the document in Firestore with the modified userCopy
  //     await setDoc(docRef, userCopy, { merge: true });

  //     setSaved(true); // Indicate settings have been saved
  //     setDirty(false); // Reset dirty flag since settings are now in sync
  //     initialSettingsRef.current = settings; // Update initial settings to the current saved state
  
  //     // Set a timer to reset the saved state after 2 seconds
  //     setTimeout(() => {
  //       setSaved(false);
  //     }, 2000);
  
  //   } catch (error) {
  //     console.error('Error updating settings in Firestore:', error);
  //   }
  // };

  const saveSettings = async () => {
    if (!user?.uid || !dirty) return; // ✅ Only save if settings are dirty and user exists

    if (settings.multiWeek === true && settings.cycleType === undefined) {
        noCycleTypeAlert();
        return;
    }

    try {
        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("🚨 User document not found.");
            return;
        }

        // ✅ Only update `settings` field in Firestore
        await updateDoc(userRef, {
            settings: {
                ...userSnap.data().settings,
                ...settings, // Merge new settings
            },
        });

        console.log("✅ User settings updated successfully!");

        // ✅ Update local state after successful save
        setSaved(true); // Indicate settings have been saved
        setDirty(false); // Reset dirty flag since settings are now in sync
        initialSettingsRef.current = settings; // Update initial settings to the current saved state

        // ⏳ Reset the saved state after 2 seconds
        setTimeout(() => {
            setSaved(false);
        }, 2000);

        dispatch(fetchUserData(user.uid))

    } catch (error) {
        console.error("❌ Error updating settings in Firestore:", error);
    }
  };


  const handleNavigateAway = (e) => {
    if (typeof e === "object" && e.preventDefault) {
      e.preventDefault(); // Prevent navigation if preventDefault is available
    }
  
    if (dirty) {
      toggleModal(); // Show the modal if there are unsaved changes
      return false; // Return false to prevent navigation
    }
  
    return true; // Allow navigation if there are no unsaved changes
  };

  const handleContinueWithoutSaving = () => {
    toggleModal();
    setDirty(false); // Reset dirty flag to avoid loop
    history.push(history.location.pathname); // Continue navigation
  };

  const handleShareCodeToggle = async () => {
    // console.log("user.settings.useShareCode => ", user.settings.useShareCode)
    // console.log("user.shareCode => ", user.shareCode)
    if (user.settings.useShareCode === undefined || user.shareCode === undefined) {
      // Create share code
      const shareCode = createShareCode();

      // Store it in shareCodes collection
      try {
        console.log("Attempting to write share code to database");
        await setDoc(doc(db, "shareCodes", shareCode), { uid: user.uid });
      } catch {
        console.log("Failed to write share code to database");
        return; // Exit the function if the share code creation fails
      }

      // Add it to user object
      try {
        console.log("Attempting to update user document");
        const docRef = doc(db, 'users', user.uid);

        const userCopy = JSON.parse(JSON.stringify(user));

        userCopy.settings = {
          ...userCopy.settings,
          useShareCode: true
        };

        userCopy.shareCode = shareCode

        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      } catch {
        console.log("Failed to write share code to user document");
        return;
      }

      handleSettingChange({
        ...settings,
        useShareCode: true,
      })
    } else {

      if (user.settings.useShareCode === true) { // Everything already exists and the user is just trying to turn off useShareCode
        handleSettingChange({
          ...settings,
          useShareCode: !user.settings?.useShareCode,
        })
      } else { // The user is trying to turn on useShareCode
        if (user.shareCode) { // I think this should always be true because of the user.shareCode === undefined check above

          const docRef = doc(db, 'shareCodes', user.shareCode);
          // console.log("docRef => ", docRef)
  
          try {
            //Search shareCodes collection for user's shareCode
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) { //If it already exists
              console.log('Share Code is in collection');
              handleSettingChange({
                ...settings,
                useShareCode: true,
              })
            } else {
              // Attempting to write missing share code to shareCodes collection
              try {
                console.log("Attempting to write share code to shareCodes collection");
                await setDoc(doc(db, "shareCodes", user.shareCode), { uid: user.uid });
                handleSettingChange({
                  ...settings,
                  useShareCode: true,
                })
              } catch {
                console.log("Failed to write share code to shareCodes collection");
                return; // Exit the function if the share code creation fails
              }
            }
          } catch (error) {
            console.error('Error checking document existence:', error);
          }
  
        }
      }
    }
  };

  const areYouSureAlertForAlteredScheduleMode = (boolean) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you REALLY sure?"
        onConfirm={() => {
          handleSettingChange({
            ...settings,
            alteredSchedule: boolean,
          })
          hideAlert()
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{backgroundColor: '#dc3545', borderRadius: '30px', width: '100px'}}
        confirmBtnText="Yes!"
        confirmBtnStyle={{backgroundColor: 'rgb(11, 192, 223)', borderRadius: '30px', width: '100px'}}
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        This will turn off all of your automations and is great for a situation where your school is operating on a different schedule for the day.
        <div style={{textAlign: 'left', padding: '0px 85px', marginTop: '20px'}}>
          <ul>
            <li>Auto Navigate</li>
            <li>Auto Countdown Timers</li>
            <li>Auto Reminders</li>
            <li>Auto Happy Birthday</li>
            <li>Auto Start Agendas</li>
          </ul>
        </div>
        You must manually turn this setting off once you are ready to resume your automations.
      </ReactBSAlert>
    );
  };

  const areYouSureAlertForActiveDates = (boolean) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you REALLY sure?"
        onConfirm={() => {
          handleSettingChange({
            ...settings,
            useActiveDates: boolean,
          })
          hideAlert()
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{backgroundColor: '#dc3545', borderRadius: '30px', width: '100px'}}
        confirmBtnText="Yes!"
        confirmBtnStyle={{backgroundColor: 'rgb(11, 192, 223)', borderRadius: '30px', width: '100px'}}
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        <div>Turning on Active Dates will make Lesson Launchpad operate in a fundamentally different way.</div>
        <div style={{marginTop: '10px'}}>It's great if you want more control of what content is visble on the screen from day-to-day, but can cause confusion for users who don't understand how it works.</div>
        <div style={{marginTop: '10px'}}>Don't worry! It's completely reversable by turning this toggle off so feel free to give it a shot.</div>
        <div style={{ marginTop: '10px' }}>
          You can learn more about Active Dates 
          <a 
            href="https://www.youtube.com/watch?v=jv0BA6KHQ1w" 
            target="_blank" 
            rel="noopener noreferrer"
            style={{ marginLeft: "5px", color: "#004aad", textDecoration: "underline", fontWeight: "bold" }}
          >
            HERE
          </a>
        </div>
      </ReactBSAlert>
    );
  };

  const noCycleTypeAlert = () => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="No Cycle Type Selected!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{backgroundColor: '#dc3545', borderRadius: '30px', width: '100px'}}
        confirmBtnText="Okay"
        confirmBtnStyle={{backgroundColor: 'rgb(11, 192, 223)', borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
        You have selected <strong>"Yes"</strong> for <strong>"Does your school use a non-traditional day or weekly cycle?"</strong> but you have not selected
        a daily or weekly cycle type. Please choose one or the other, or select "No" if you do not need a non-traditional cycle.
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const calculateDaysRemaining = () => {
    if (!user || !user.accountCreated) return 0;
  
    let accountCreatedDate;
  
    // 🟢 Handle old Firestore Timestamp format (object with seconds)
    if (typeof user.accountCreated === "object" && user.accountCreated.seconds) {
      const milliseconds = user.accountCreated.seconds * 1000 + (user.accountCreated.nanoseconds || 0) / 1e6;
      accountCreatedDate = new Date(milliseconds);
    } 
    // 🟢 Handle new Redux-parsed format (Unix timestamp in seconds)
    else if (typeof user.accountCreated === "number") {
      accountCreatedDate = new Date(user.accountCreated * 1000); // Convert seconds to milliseconds
    } 
    // 🟢 Handle string timestamp (e.g., "March 13, 2025 at 1:21:13 PM UTC-4")
    else if (typeof user.accountCreated === "string") {
      accountCreatedDate = new Date(Date.parse(user.accountCreated));
    }
  
    if (!accountCreatedDate || isNaN(accountCreatedDate.getTime())) return 0; // Fallback if invalid
  
    // 🔹 Calculate days remaining
    const daysRemaining = 30 - Math.floor((Date.now() - accountCreatedDate) / (1000 * 60 * 60 * 24));
  
    return Math.max(daysRemaining, 0); // 🔹 Ensure non-negative result
  };

  return (
    <div className='content'>
      {alert}
      <Prompt
        when={dirty}
        message={handleNavigateAway}
      />
      <Modal isOpen={modal} toggle={toggleModal} className="settings-did-you-mean-to-save-modal">
        <ModalHeader toggle={toggleModal} style={{borderBottom: 'none', padding: '10px 20px'}}>
          <h3 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Did you mean to save?</h3>
        </ModalHeader>
        <ModalBody style={{textAlign: 'center', fontSize: '16px'}}>
          You have unsaved changes. Would you like to save before leaving?
        </ModalBody>
        <ModalFooter style={{borderTop: 'none', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
          <Button color="primary" onClick={() => { saveSettings(); toggleModal(); }} style={{backgroundColor: 'rgb(11, 192, 223)'}}>
            Save
          </Button>
          <Button color="secondary" onClick={handleContinueWithoutSaving} style={{backgroundColor: '#dc3545'}}>
            Continue without saving
          </Button>
        </ModalFooter>
      </Modal>
      
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardHeader>
                <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Settings</h2>
            </CardHeader>

            <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Col>
                <CardSubtitle style={{fontWeight: 'bold'}}>
                  Present
                </CardSubtitle>
                <hr></hr>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <Switch
                    value={settings.showSeconds}
                    onChange={(el, boolean) => handleSettingChange({
                      ...settings,
                      showSeconds: boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px' }}>Display seconds on Clock</span>
                </label>
                <label style={{ margin: '15px' }} id="marqueeSpeed">
                  <UncontrolledTooltip
                    delay={0}
                    target={"marqueeSpeed"}
                    placement="top"
                  >
                    Change the speed of the scrolling marquee on the present screen.
                  </UncontrolledTooltip>
                  <span>Marquee Speed</span>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={settings.marqueeSpeed || '150'}
                    onChange={(e) => handleSettingChange({
                      ...settings,
                      marqueeSpeed: e.target.value,
                    })}
                    style={{borderRadius: '8px'}}
                  > <option value={'0'}>
                      None
                    </option>
                    <option value={'50'}>
                      Largo
                    </option>
                    <option value={'100'}>
                      Andante
                    </option>
                    <option value={'150'}>
                      Moderato
                    </option>
                    <option value={'200'}>
                      Allegro
                    </option>
                    <option value={'250'}>
                      Vivace
                    </option>
                  </Input>
                </label>
                <label style={{ margin: '15px' }} id="screenTimeout">
                  <UncontrolledTooltip
                    delay={0}
                    target={"screenTimeout"}
                    placement="top"
                  >
                    Use this setting to keep the present screen turned on.
                  </UncontrolledTooltip>
                  <span>Screen Timeout</span>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={settings.screenTimeout || '60'}
                    onChange={(e) => handleSettingChange({
                      ...settings,
                      screenTimeout: e.target.value,
                    })}
                    style={{borderRadius: '8px'}}
                  > <option value={'0'}>
                      Use System Setting
                    </option>
                    <option value={'30'}>
                      30 minutes
                    </option>
                    <option value={'45'}>
                      45 minutes
                    </option>
                    <option value={'60'}>
                      1 hour (default)
                    </option>
                    <option value={'90'}>
                      1 hour 30 minutes
                    </option>
                    <option value={'120'}>
                      2 hours
                    </option>
                    <option value={'180'}>
                      3 hours
                    </option>
                    <option value={'240'}>
                      4 hours
                    </option>
                  </Input>
                </label>
                <br></br>
                <CardSubtitle style={{fontWeight: 'bold', marginTop: '20px'}}>
                  Automations
                </CardSubtitle>
                <hr></hr>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }} id={"alteredSchedule"}>
                  <UncontrolledTooltip
                    delay={0}
                    target={"alteredSchedule"}
                    placement="top"
                  >
                    A convenient way to turn off any automations when your school is operating on a different schedule for the day.
                  </UncontrolledTooltip>
                  <Switch
                    value={!settings.alteredSchedule || false} //This is reversed because this used to be alteredScheduleMode
                    onChange={(el, boolean) => !settings.alteredSchedule ? areYouSureAlertForAlteredScheduleMode(!boolean) : handleSettingChange({
                      ...settings,
                      alteredSchedule: !boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px' }}>Automations On/Off</span>
                </label>
              </Col>
              <Col>
                <CardSubtitle style={{fontWeight: 'bold'}}>
                  Default Styles
                </CardSubtitle>
                <hr></hr>
                <div id="default-pickers" style={{ display: 'flex', gap: '10px', marginBottom: '20px', marginTop: '10px' }}>
                  <label style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="defaultFontSize">
                    <UncontrolledTooltip delay={0} target={"defaultFontSize"} placement="top">
                      Change the default font size for the text-editor content box.
                    </UncontrolledTooltip>
                    <span>Font Size</span>
                    <FontSizePicker
                      selectedSize={settings.defaultFontSize || '14px'}
                      onChangeFunction={(e) => handleSettingChange({
                        ...settings,
                        defaultFontSize: e.target.value,
                      })}
                    />
                  </label>
                  <label style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="defaultFontStyle">
                    <UncontrolledTooltip delay={0} target={"defaultFontStyle"} placement="top">
                      Change the default font style for the text-editor content box.
                    </UncontrolledTooltip>
                    <span>Font Style</span>
                    <FontStylePicker
                      selectedFont={settings.defaultFontStyle || 'Helvetica'}
                      onChangeFunction={(e) => handleSettingChange({
                        ...settings,
                        defaultFontStyle: e.target.value,
                      })}
                    />
                  </label>
                </div>
                <div id="default-pickers" style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                  <label style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="defaultFontColor">
                    <UncontrolledTooltip delay={0} target={"defaultFontColor"} placement="top">
                    (Coming Soon) Change the default font color.
                    </UncontrolledTooltip>
                    <span>Font Color</span>
                    <Input
                      disabled
                      type="color"
                      style={{borderRadius: '8px', height: '50px'}}
                    >
                    </Input>
                  </label>
                  <label style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="defaultContentBoxBackground">
                    <UncontrolledTooltip delay={0} target={"defaultContentBoxBackground"} placement="top">
                      (Coming Soon) Change the default content box background.
                    </UncontrolledTooltip>
                    <span>Box Background</span>
                    <div
                      style={{
                        borderRadius: '8px', 
                        border: '1px solid #DDDDDD', 
                        width: '100%', 
                        height: '50px',
                        backgroundColor: '#E3E3E3',
                        cursor: 'not-allowed'
                      }}
                    >
                      <div
                        style={{
                          margin: '10px 13px',
                          border: '1px solid #66615b', 
                          height: '28px',
                          backgroundColor: '#E3E3E3'
                        }}
                      >

                      </div>
                    </div>
                  </label>
                </div>
                <div id="default-pickers" style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
                  <label style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="defaultPrimaryButtonColor">
                    <UncontrolledTooltip delay={0} target={"defaultPrimaryButtonColor"} placement="top">
                    (Coming Soon) Change the primary color used for buttons on the Present page to better match your theme.
                    </UncontrolledTooltip>
                    <span>Button Primary</span>
                    <Input
                      disabled
                      type="color"
                      style={{borderRadius: '8px', height: '50px'}}
                      value={'#0bc0df'}
                    >
                    </Input>
                  </label>
                  <label style={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'center' }} id="defaultSecondaryButtonColor">
                    <UncontrolledTooltip delay={0} target={"defaultSecondaryButtonColor"} placement="top">
                    (Coming Soon) Change the secondary color used for buttons on the Present page to better match your theme.
                    </UncontrolledTooltip>
                    <span>Button Secondary</span>
                    <Input
                      disabled
                      type="color"
                      style={{borderRadius: '8px', height: '50px'}}
                      value={'#004aad'}
                    >
                    </Input>
                  </label>
                </div>
                <br></br>
                <CardSubtitle style={{fontWeight: 'bold'}}>
                  Active Dates
                </CardSubtitle>
                <hr></hr>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <Switch
                    value={settings.useActiveDates || false}
                    // onChange={(el, boolean) => handleSettingChange({
                    //   ...settings,
                    //   useActiveDates: boolean,
                    // })}

                    onChange={(el, boolean) => !settings.useActiveDates ? areYouSureAlertForActiveDates(boolean) : handleSettingChange({
                      ...settings,
                      useActiveDates: boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px', marginRight: '10px' }}>Use Active Dates</span>
                  <div ref={(el) => (buttonRefs.current["activeDatesToolTip"] = el)}>
                    <InfoBubbleButton onClick={() => toggleTooltip("activeDatesToolTip")} />
                  </div>
                </label>
                {buttonRefs.current["activeDatesToolTip"] && (
                  <Tooltip
                    target={buttonRefs.current["activeDatesToolTip"]}
                    isOpen={tooltips["activeDatesToolTip"] || false}
                    toggle={() => toggleTooltip("activeDatesToolTip")}
                    popperClassName="custom-tooltip"
                    trigger="manual" // Prevents hover behavior
                    innerRef={(el) => (tooltipRefs.current["activeDatesToolTip"] = el)}
                  >
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px", maxWidth: '650px' }}>
                      {/* Column 1 */}
                      <div style={{ textAlign: "center" }}>
                        <p style={{ marginBottom: "5px" }}>
                          <strong>With Active Dates set to "On", you can:</strong>
                        </p>
                        <p style={{ marginBottom: "5px" }}>
                          Schedule your content boxes and announcements to appear on specific dates OR
                          specific days of the week. This gives you much more control over your content 
                          AND allows you to plan content in advance.
                        </p>
                        <p>
                        Turning on Active Dates will make Lesson Launchpad operate in a fundamentally different way
                        so it's important to understand how it works.
                        </p>
                        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                          <a
                            href="/faq#active-dates"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#004aad", textDecoration: "underline" }}
                          >
                            CLICK HERE TO LEARN MORE
                          </a>
                        </p>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <img 
                          src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2FPick%20Active%20Dates%20Dialog.png?alt=media&token=bbcd73af-2f44-4049-a04d-acd814285b98"
                          alt="Set Active Dates Dialog" 
                          style={{ width: "300px", height: "auto", cursor: "pointer" }} 
                        />
                      </div>
                    </div>
                  </Tooltip> 
                )}
              </Col>
              <Col>
                <CardSubtitle style={{fontWeight: 'bold'}}>
                  Scheduling
                </CardSubtitle>
                <hr></hr>

                <label style={{ margin: '0px 15px 5px' }}>
                  <span>Does your school use a non-traditional day or weekly cycle?</span>
                </label>
                
                <div
                  style={{ 
                    margin: '0px 15px', 
                    display: 'flex', 
                    alignItems: 'center', // Vertically align items
                    gap: '5px',            // Space between the span and button
                  }}
                >
                  <Input
                    name="select"
                    type="select"
                    value={settings.multiWeek || false}
                    onChange={(e) => {
                      const isMultiWeek = e.target.value === "true"; // Convert the value to a boolean
                      handleSettingChange({
                      ...settings,
                      multiWeek: isMultiWeek,
                    })}}
                    style={{borderRadius: '8px'}}
                  >
                    <option value="false">
                      No
                    </option>
                    <option value="true">
                      Yes
                    </option>
                  </Input>
                  <div ref={(el) => (buttonRefs.current["multiWeekToolTip"] = el)}>
                    <InfoBubbleButton onClick={() => toggleTooltip("multiWeekToolTip")} />
                  </div>
                </div>

                {buttonRefs.current["multiWeekToolTip"] && (
                  <Tooltip
                    target={buttonRefs.current["multiWeekToolTip"]}
                    isOpen={tooltips["multiWeekToolTip"] || false}
                    toggle={() => toggleTooltip("multiWeekToolTip")}
                    popperClassName="custom-tooltip"
                    trigger="manual" // Prevents hover behavior
                    innerRef={(el) => (tooltipRefs.current["multiWeekToolTip"] = el)}
                    placement="left"
                  >
                    <div style={{ textAlign: "center", maxWidth: "300px" }}>
                      <p style={{ marginBottom: "5px" }}>
                        <strong>Select "Yes" if:</strong>
                      </p>
                      <p style={{ marginBottom: "5px" }}>
                        Your class schedule changes from week to week
                        (e.g., A Week / B Week schedule).
                      </p>
                      <strong>OR</strong>
                      <p style={{ marginTop: "5px", marginBottom: "10px" }}>
                        If your class schedule doesn’t follow the standard 5-day school week.
                      </p>
                      <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                        Not sure? -{" "}
                        <a
                          href="/faq#schedules"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "#004aad", textDecoration: "underline" }}
                        >
                          CLICK HERE
                        </a>
                      </p>
                    </div>
                  </Tooltip>  
                )}
                {settings.multiWeek && 
                <>
                  <label style={{ margin: '20px 15px 5px' }}>
                    <span>Does your schedule use a daily or weekly cycle?</span>
                  </label>

                  <div
                    style={{ 
                      margin: '0px 15px', 
                      display: 'flex', 
                      alignItems: 'center', // Vertically align items
                      gap: '5px',            // Space between the span and button
                    }}
                  >
                    <Input
                      id="cycleType"
                      name="select"
                      type="select"
                      value={settings.cycleType || null}
                      invalid={settings.cycleType === null || settings.cycleType === undefined}
                      onChange={(e) => handleSettingChange({
                        ...settings,
                        cycleType: e.target.value,
                      })}
                      style={{ borderRadius: '8px' }}
                    >
                      <option key={1} value={null}>--</option>
                      <option key={2} value={"week"}>Weekly</option>
                      <option key={3} value={"day"}>Daily</option>
                    </Input> 
                    <div ref={(el) => (buttonRefs.current["cycleTypeToolTip"] = el)}>
                      <InfoBubbleButton onClick={() => toggleTooltip("cycleTypeToolTip")} />
                    </div>
                  </div> 

                  {buttonRefs.current["cycleTypeToolTip"] && (
                    <Tooltip
                      target={buttonRefs.current["cycleTypeToolTip"]}
                      isOpen={tooltips["cycleTypeToolTip"] || false}
                      toggle={() => toggleTooltip("cycleTypeToolTip")}
                      popperClassName="custom-tooltip"
                      trigger="manual" // Prevents hover behavior
                      innerRef={(el) => (tooltipRefs.current["cycleTypeToolTip"] = el)}
                      placement="left"
                    >
                      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px", maxWidth: '650px' }}>
                        {/* Column 1 */}
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: "bold", marginBottom: "5px" }}>Daily Example</h6>
                          <a href="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Fdaily-schedule-example.png?alt=media&token=a1959da3-5fe8-4a85-8c35-3a493adf1166" target="_blank" rel="noopener noreferrer">
                            <img 
                              src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Fdaily-schedule-example.png?alt=media&token=a1959da3-5fe8-4a85-8c35-3a493adf1166" 
                              alt="Daily Example" 
                              style={{ width: "300px", height: "auto", borderRadius: "8px", cursor: "pointer" }} 
                            />
                            Click to enlarge
                          </a>
                        </div>

                        {/* Column 2 */}
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: "bold", marginBottom: "5px" }}>Weekly Example</h6>
                          <a href="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Fweekly-schedule-example.png?alt=media&token=20628a83-3d2b-4d20-9949-c461b448ddd2" target="_blank" rel="noopener noreferrer">
                            <img 
                              src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Fweekly-schedule-example.png?alt=media&token=20628a83-3d2b-4d20-9949-c461b448ddd2"
                              alt="Weekly Example" 
                              style={{ width: "300px", height: "auto", borderRadius: "8px", cursor: "pointer" }} 
                            />
                            Click to enlarge
                          </a>
                        </div>
                      </div>
                    </Tooltip>
                  )}

                  {settings.cycleType !== null && settings.cycleType !== undefined && (
                    <>
                      <label style={{ margin: '20px 15px 5px' }}>
                        <span>How many different {settings.cycleType === "day" ? "days" : "weeks"} does your schedule contain?</span>
                      </label>

                      <div
                        style={{ 
                          margin: '0px 15px', 
                          display: 'flex', 
                          alignItems: 'center', // Vertically align items
                          gap: '5px',            // Space between the span and button
                        }}
                      >
                        <Input
                          id="numberOfWeeks"
                          name="select"
                          type="select"
                          value={settings.numberOfWeeks || 1}
                          onChange={(e) => handleSettingChange({
                            ...settings,
                            numberOfWeeks: e.target.value,
                          })}
                          style={{borderRadius: '8px'}}
                        >
                          {Array.from({ length: Scheduling.weekDayLetterOptions.length }, (_, i) => i + 1).map((num) => (
                            <option key={num} value={num} disabled={num === 1 && true}>
                              {num === 1 ? "--" : `${num} ${settings.cycleType}${num > 1 ? "s" : ""}`}
                            </option>
                          ))}
                        </Input>
                      </div>

                      <label style={{ margin: '20px 15px 5px' }}>
                        <span>Current {settings.cycleType === "day" ? "Day" : "Week"}</span>
                      </label>
                      <div
                        style={{ 
                          margin: '0px 15px', 
                          display: 'flex', 
                          alignItems: 'center', // Vertically align items
                          gap: '5px',            // Space between the span and button
                        }}
                      >
                        <Input
                          id="currentWeek"
                          name="select"
                          type="select"
                          value={settings.currentWeek || ""}
                          onChange={(e) => handleSettingChange({
                            ...settings,
                            currentWeek: e.target.value,
                          })}
                          style={{borderRadius: '8px'}}
                        >
                          <option disabled value={""}>
                            --
                          </option>
                          {Array.from({ length: settings.numberOfWeeks }, (_, i) => String.fromCharCode(65 + i)).map((letter) => (
                            <option key={letter} value={letter}>
                              {settings.cycleType === "week" ? `Week ${letter}` : `Day ${letter}`}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </>
                  )}
                </>
                }
                  <label style={{ margin: '20px 15px 5px' }}>
                    <span>Do you need different times for the same class?</span>
                  </label>

                  <div
                    style={{ 
                      margin: '0px 15px', 
                      display: 'flex', 
                      alignItems: 'center', // Vertically align items
                      gap: '5px',            // Space between the span and button
                    }}
                  >
                    <Input
                      id="differentTimes"
                      name="select"
                      type="select"
                      value={settings.differentTimes || false}
                      onChange={(e) => {
                        const isDifferentTimes = e.target.value === "true"; // Convert the value to a boolean
                        handleSettingChange({
                        ...settings,
                        differentTimes: isDifferentTimes,
                      })}}
                      style={{borderRadius: '8px'}}
                    >
                      <option value="false">
                        No
                      </option>
                      <option value="true">
                        Yes
                      </option>
                    </Input>
                    <div ref={(el) => (buttonRefs.current["differentTimesToolTip"] = el)}>
                      <InfoBubbleButton onClick={() => toggleTooltip("differentTimesToolTip")} />
                    </div>
                  </div>
                  {buttonRefs.current["differentTimesToolTip"] && (
                    <Tooltip
                      target={buttonRefs.current["differentTimesToolTip"]}
                      isOpen={tooltips["differentTimesToolTip"] || false}
                      toggle={() => toggleTooltip("differentTimesToolTip")}
                      popperClassName="custom-tooltip"
                      trigger="manual" // Prevents hover behavior
                      innerRef={(el) => (tooltipRefs.current["differentTimesToolTip"] = el)}
                      placement="left"
                    >
                      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "15px", maxWidth: '650px' }}>
                        {/* Column 1 */}
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: "bold", marginBottom: "5px" }}>No</h6>
                        </div>

                        {/* Column 2 */}
                        <div style={{ textAlign: "center" }}>
                          <h6 style={{ fontWeight: "bold", marginBottom: "5px" }}>Yes</h6>
                        </div>
                      </div>
                      <img 
                          src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Fdifferent-times.png?alt=media&token=21d4ccd9-e0dd-49bd-be36-9925a23578a5"
                          alt="Different Times Examples" 
                          style={{ width: "300px", height: "auto", borderRadius: "8px", cursor: "pointer" }} 
                        />
                    </Tooltip>
                  )}
              </Col>
              <Col>
                <CardSubtitle style={{fontWeight: 'bold'}}>
                  Share
                </CardSubtitle>
                <hr></hr>
                  <label id="switch-useShareCode" style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <Switch
                      value={settings.useShareCode || false}
                      onChange={handleShareCodeToggle}
                    />
                    <span style={{ marginLeft: '10px' }}>Share Lesson Content</span>
                  </label>
                  <Tooltip
                    placement="top"
                    autohide={false}
                    isOpen={tooltipOpen}
                    target="switch-useShareCode"
                    toggle={toggle}
                  >
                    Share your lesson content with anyone by using a lesson launchpad share link.
                  </Tooltip>
                  {settings?.useShareCode && (
                    <>
                      <div>
                        Your share URL is:
                      </div>
                      <br></br>
                      <div style={{ textAlign: 'center', fontSize: '14px' }}>
                        {formattedShareURL && 
                          <a href={`https://${formattedShareURL}`} id="shareURL">
                            {formattedShareURL}
                          </a>
                        }
                        <UncontrolledTooltip
                          delay={0}
                          target={"shareURL"}
                          placement="top"
                          autohide={false}
                        >
                          Share this link with others so they can view a read-only copy of your present screen content.
                        </UncontrolledTooltip>
                      </div>
                    </>
                  )}
                  <br></br>
                <CardSubtitle style={{fontWeight: 'bold'}}>
                  Account
                </CardSubtitle>
                  <hr></hr>
                  {user && user.premium ?
                    <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <a href='https://billing.stripe.com/p/login/9AQ29QgsR09R9bOfYY' style={{width: '100%'}}>
                          <Button style={{ fontSize: '14px', width: '100%', borderRadius: '30px' }} className="button-hover-fix">
                              Customer Portal
                          </Button>
                      </a>
                    </Row>
                  :  user.trial ?
                    <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <p>You are currently on a 30-day free trial.</p>
                      <p>You have {calculateDaysRemaining()} days remaining on your free trial.</p>
                      <NavLink to={{pathname: '/pricing'}} style={{width: '100%'}}>
                          <Button style={{ fontSize: '12px', width: '100%', borderRadius: '30px' }} className="button-hover-fix">
                              Subscribe to Lesson Launchpad
                          </Button>
                      </NavLink>
                    </Row>
                  :
                    <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <br></br>
                      <p>Your trial has ended.</p>
                      <NavLink to={{pathname: '/pricing'}} style={{width: '100%'}}>
                          <Button style={{ fontSize: '12px', width: '100%', borderRadius: '30px' }} className="button-hover-fix">
                              Subscribe to Lesson Launchpad
                          </Button>
                      </NavLink>
                    </Row>
                  }
                  <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <Button style={{ fontSize: '14px', width: '100%', borderRadius: '30px' }} className="button-hover-fix" onClick={toggleUpdateUserEmailModal}>
                      Change User Email
                    </Button>
                  </Row>
              </Col>
            </Col>
            <CardFooter>
              <Button
                className="button-hover-fix"
                color="primary"
                onClick={saveSettings}
                style={{ 
                  marginTop: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  padding: "10px 30px",
                  borderRadius: "30px",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}>
                {saved ? "Settings Saved" : <><i className='fa fa-save' style={{marginRight: '10px'}}/>{' '}Save Settings</>}
                {saved && <i className="fa fa-check" style={{ marginLeft: '10px', color: 'white' }} />}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={updateUserEmailModal} toggle={toggleUpdateUserEmailModal} style={{width: '300px'}}>
        <UpdateUserEmail />
      </Modal>

      <div ref={contactRef}>
        <Contact />
      </div>  

      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>                      
          <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '89vw' }}>
            <div>
              <h2 style={{margin: '20px', fontWeight: "bold", color: "#004aad"}}><a style={{margin: '20px', fontWeight: "bold", color: "#004aad"}} href="https://docs.google.com/forms/d/e/1FAIpQLSdN4JmHvASf4h_-8btowJfug5jbvv5rxOVH4x0j6uERVTYMmg/viewform?usp=sf_link" target="_blank">Click HERE to leave a Testimonial for our Landing Page.</a></h2>
            </div>
          </Card>
        </Col>
      </Row>
      <div ref={changelogRef} id="changelog-section">
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '60px' }}>
          <Changelog />
        </Row>
      </div>
    </div>
  );
};

export default Thing;


import React, { useState, useEffect, useRef } from "react";
import { doc, getDoc, setDoc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch, useLocation, NavLink } from "react-router-dom";
import Sidebar from "components/Sidebar/Sidebar.js";
import Present from "views/Present.js"
import routes from "routes.js";
import { useSelector } from "react-redux";
import { selectUser, selectClasses } from "features/userSlice";
import ReactBSAlert from "react-bootstrap-sweetalert";
import NotificationAlert from "react-notification-alert";
import { Button, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardTitle, Col, CardHeader } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import maintenanceLogo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/maintenance-logo.png";
import useHandleDayWeekCycleChange from "handleDayWeekCycleChange";
import { FIREBASE_COLLECTIONS } from "../../src/config/firebaseCollections";
import AddEditView from "views/AddEditView";
import AddEditClassForm from "views/forms/AddEditClassForm";

var ps;

function Admin(props) {

  const user = useSelector(selectUser);
  const classes = useSelector(selectClasses);
  const notificationAlert = useRef();
  const helpSessionAlert = useRef();
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [activeColor, setActiveColor] = React.useState("info");
  const [classRoutes, setClassRoutes] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [navigationVisible, setNavigationVisible] = useState(true);
  const mainPanel = React.useRef();
  const ONE_DAY_IN_MS = 12 * 60 * 60 * 1000;
  const ONE_WEEK_IN_MS = 24 * 60 * 60 * 1000 * 7;
  const pathname = location.pathname
  const showNavigationVisibleButton = pathname !== "/add-edit-classes" && pathname !== "/print" && pathname !== "/settings" && pathname !== "/faq"
  const [latestUpdateModal, setLatestUpdateModal] = useState(false)
  const [latestUpdateData, setLatestUpdateData] = useState()
  const [showDBModal, setShowDBModal] = useState(false);

  const handleDBCloseModal = () => {
    // Save a flag in localStorage to indicate that the user has seen the modal
    localStorage.setItem('dbChangeNotified', 'true');
    // Close the modal (e.g., set state to hide the modal)
    setShowDBModal(false);
  };

  useEffect(() => {
    // Check localStorage for the flag; if not present, show the modal
    const notified = localStorage.getItem('dbChangeNotified');
    if (!notified) {
      setShowDBModal(true);
    }
  }, []);

  const toggleLatestUpdateModal = () => {
    setLatestUpdateModal((prev) => !prev)
  }
  useEffect(() => {
    getClassRoutes();
  }, [props]);
  
  const toggleNavigationVisible = () => {
    setNavigationVisible(!navigationVisible);
  };

  const notificationRef = useHandleDayWeekCycleChange(user?.settings);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      // Check if mainPanel.current is defined before initializing PerfectScrollbar
      if (mainPanel.current) {
        ps = new PerfectScrollbar(mainPanel.current);
      }
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        // Check if ps is defined before destroying it
        if (ps) {
          ps.destroy();
        }
        document.documentElement.className += " perfect-scrollbar-off";
        document.documentElement.classList.remove("perfect-scrollbar-on");
      }
    };
  }, [mainPanel]); // Include mainPanel in the dependency array

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    // Check if mainPanel.current is defined before setting scrollTop
    if (mainPanel.current) {
      mainPanel.current.scrollTop = 0;
    }
  }, [location]);

  function detectMobileView() {
    // Define the threshold width for considering it a mobile view
    const mobileThreshold = 768; // You can adjust this value as needed
  
    // Get the current viewport width
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
  
    // Check if the viewport width is below the threshold
    if (viewportWidth < mobileThreshold) {
      alert('This is a mobile view!');
    }
  }
  
  // Call the function when the page loads or as needed
  window.addEventListener('load', detectMobileView);

  // const getClassRoutes = () => {
  //   const classRoutesArray = [];
  //   user && user.classes.map((item) => {
  //     // Limit the name to 23 characters and add "..." if it exceeds that length
  //     const truncatedName = item.className
  //       ? item.className.length > 23
  //         ? item.className.substring(0, 23) + "..."
  //         : item.className
  //       : item.classId; //If className is null or undefined, use classId instead.
  
  //     const classPath = "/" + item.classId;
      
  //     classRoutesArray.push({
  //       path: classPath,
  //       name: truncatedName, // Use the truncated name
  //       mini: item.period != null ? item.period.replace(/ .*/, "") : ">",
  //       component: Present,
  //       layout: "/admin",
  //       classId: item.classId,
  //       showMarquee: item.showMarquee,
  //       showClock: item.showClock,
  //       visible: item.active ? true : false,
  //     });
  //   });
  //   const newRoutes = [...routes];
  //   newRoutes[0].views = classRoutesArray;
  //   setClassRoutes(routes);
  // };

  const getClassRoutes = () => {
    // if (!classes || classes.length === 0) return;

    const classRoutesArray = classes.map((item) => {

        const truncatedName = item.className
            ? item.className.length > 23
                ? item.className.substring(0, 23) + "..."
                : item.className
            : item.classId;

        return {
            path: "/" + item.classId,
            name: truncatedName,
            mini: item.period ? item.period.replace(/ .*/, "") : ">",
            component: Present,
            layout: "/admin",
            classId: item.classId,
            showMarquee: item.showMarquee,
            showClock: item.showClock,
            visible: item.active || false,
        };
    });

    const newRoutes = [...routes];
    newRoutes[0].views = classRoutesArray;
    setClassRoutes(newRoutes);
};

  
  const getRoutes = (classRoutes) => {
    return classRoutes && classRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route 
            path={prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // const handleActiveClick = (color) => {
  //   setActiveColor(color);
  // };
  // const handleBgClick = (color) => {
  //   setBackgroundColor(color);
  // };
  // const handleMiniClick = () => {
  //   if (document.body.classList.contains("sidebar-mini")) {
  //     setSidebarMini(false);
  //   } else {
  //     setSidebarMini(true);
  //   }
  //   document.body.classList.toggle("sidebar-mini");
  // };

  const [mobileAlert, setMobileAlert] = useState(null);
  const [trialAlert, setTrialAlert] = useState(null);

    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(orientation: portrait)").matches;
    
    const hideMobileAlert = () => {
      setMobileAlert(null);
    };

    const hideTrialAlert = () => {
      setTrialAlert(null);
    };

    const setDontShowMobileAlert = () => {
      localStorage.setItem("dontShowMobileAlert", "true")
    }
    
    // Function to show an alert if the user is on a mobile device
    function showAlertForMobile() {
      if (isMobileDevice) { 
        setMobileAlert(
          <ReactBSAlert
            warning
            style={{ display: "block", marginTop: "-100px", zoom: '3'}}
            title="Are you on a mobile device?"
            onConfirm={() => hideMobileAlert()}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="danger"
            confirmBtnText="Close"
            btnSize=""
            >
            <div>
              We think first impressions are important and Lesson Launchpad is designed for use on a desktop computer in the landscape orientation.
              Feel free to continue, just know that it really does look a lot better than this. We promise. :)
            </div>
            <br></br>
            <div>
              <input
                type="checkbox"
                onChange={() => setDontShowMobileAlert()}
              >
              </input>
              {' '}Do not show again.
            </div>
          </ReactBSAlert>
        );
      };
    }

    function showAlertForTrialPeriod() {
      if (user && notificationAlert.current) {
        if (!user.premium && user.trial) {
          const currentDate = new Date();
    
          // Handle both formats of accountCreated
          let milliseconds;
          if (typeof user.accountCreated === "number") {
            // Direct timestamp (Unix time in seconds)
            milliseconds = user.accountCreated * 1000;
          } else if (user.accountCreated?.seconds) {
            // Firestore timestamp format { seconds, nanoseconds }
            milliseconds = user.accountCreated.seconds * 1000 + (user.accountCreated.nanoseconds || 0) / 1e6;
          } else {
            console.error("❌ Invalid accountCreated format:", user.accountCreated);
            return;
          }
    
          const accountCreatedDate = new Date(milliseconds);
          const daysElapsed = Math.floor((currentDate - accountCreatedDate) / (1000 * 60 * 60 * 24));
          const daysRemaining = Math.max(30 - daysElapsed, 0); // Ensure it doesn't go negative
    
          var options = {
            place: 'br',
            message: (
              <div>
                <b>{daysRemaining} DAYS REMAINING ON YOUR TRIAL:</b> You have {daysRemaining} days remaining on your free trial.{" "}
                <a style={{ color: 'white' }} target="_blank" rel="noopener noreferrer" href={'/pricing'}>
                  <b>CLICK HERE</b>
                </a>{" "}
                to see pricing details.
              </div>
            ),
            type: 'info',
            icon: "now-ui-icons ui-1_bell-53",
          };
          console.log("options => ", options)
          notificationAlert.current.notificationAlert(options);
        }
        // else if (!user.premium && !user.trial) {
        //   var options = {};
        //   options = {
        //     place: 'br',
        //     message: (
        //       <div>
        //         <div>
        //           Your trial period ends on January 19th, 2025. <a style={{color: 'white'}} target="_blank" href={'/pricing'}><b>CLICK HERE</b></a> to see pricing details.
        //         </div>
        //       </div>
        //     ),
        //     type: 'info',
        //     icon: "now-ui-icons ui-1_bell-53",
        //   };
        //   notificationAlert.current.notificationAlert(options);
        // }
      }
    }

    // function showAlertForHelpSessions() {
    //   if (user && helpSessionAlert.current) {
    //     var options = {};
    //     options = {
    //       place: 'bc',
    //       message: (
    //         <div>
    //           <div>
    //             Need help with Lesson Launchpad? {' '}
    //             <a style={{color: 'white'}} target="_blank" href={'https://docs.google.com/forms/d/e/1FAIpQLSdNtDowlK2t78HnMQ3b6HvIfjyr_3QCCjICjTErJHO6alWWbQ/viewform?usp=sf_link'}><b>CLICK HERE</b></a> to sign up for a help session.
    //           </div>
    //         </div>
    //       ),
    //       type: 'info',
    //       icon: "now-ui-icons ui-1_bell-53",
    //     };
    //     //helpSessionAlert.current.notificationAlert(options);
    //   }
    // }

    useEffect(() => {
      const showMobileAlert = localStorage.getItem("dontShowMobileAlert")

      if (showMobileAlert === null) {
        showAlertForMobile();
      }

      const currentTimestamp = Date.now();

      if (!user?.premium && user.trial) {
        // Get the timestamp when the alert was last shown from localStorage
        const lastAlertTimestamp = localStorage.getItem('lastAlertTimestamp');

        // console.log("lastAlertTimestamp => ", lastAlertTimestamp)
        // console.log("currentTimestamp => ", currentTimestamp)
        // console.log("currentTimestamp - lastAlertTimestamp => ", currentTimestamp - lastAlertTimestamp)
        // console.log("ONE_DAY_IN_MS => ", ONE_DAY_IN_MS)
        // console.log("currentTimestamp - lastAlertTimestamp >= ONE_DAY_IN_MS => ", currentTimestamp - lastAlertTimestamp >= ONE_DAY_IN_MS)

        // If the last alert timestamp is not set or it's been more than a day
        if (!lastAlertTimestamp || currentTimestamp - lastAlertTimestamp >= ONE_DAY_IN_MS) {
          showAlertForTrialPeriod();
  
          // Update the last alert timestamp in localStorage
          localStorage.setItem('lastAlertTimestamp', currentTimestamp.toString());
        }
      }
      // const lastHelpSessionAlertTimestamp = localStorage.getItem('lastHelpSessionAlertTimestamp');
      //   // If the last alert timestamp is not set or it's been more than a day
      //   if (!lastHelpSessionAlertTimestamp || currentTimestamp - lastHelpSessionAlertTimestamp >= ONE_WEEK_IN_MS) {
      //     showAlertForHelpSessions();
  
      //     // Update the last alert timestamp in localStorage
      //     localStorage.setItem('lastHelpSessionAlertTimestamp', currentTimestamp.toString());
      //   }
    }, [user]);

    useEffect(() => {
      if (user) {
        setLoadingComplete(true);
        // updateDateLastUsedProperty()
        updateUsageStats()
      }
    }, [user]);

    function updateUsageStats() {
      // const userEmail = user.email;
    }

    // const updateDateLastUsedProperty = async () => {
    //   const today = new Date();
    //   today.setHours(0, 0, 0, 0); // Normalize to midnight
    
    //   try {
    //     const docRef = doc(db, "users", user.uid);
    //     const userCopy = JSON.parse(JSON.stringify(user));
    
    //     if (!user.lastLogIn) {
    //       userCopy.lastLogIn = today;
    //       await setDoc(docRef, userCopy);
    //     } else {
    //       const existingLastLogIn = new Date(user.lastLogIn.seconds * 1000);
    //       existingLastLogIn.setHours(0, 0, 0, 0); // Normalize to midnight
    
    //       if (existingLastLogIn.getTime() !== today.getTime()) {
    //         userCopy.lastLogIn = today;
    //         await setDoc(docRef, userCopy);
    //       } else {
    //         console.log("Last Login already set for today.");
    //       }
    //     }
    //   } catch (error) {
    //     console.error("❌ Error updating document:", error);
    //   }
    // };

  const updateDateLastUsedProperty = async () => {
      try {
          if (!user?.uid) {
              console.error("❌ User ID is missing.");
              return;
          }

          // Reference Firestore document
          const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
          const userSnap = await getDoc(userRef);

          if (!userSnap.exists()) {
              console.error("❌ User document not found.");
              return;
          }

          const userData = userSnap.data();

          const today = new Date();
          today.setHours(0, 0, 0, 0); // Normalize to midnight

          let lastLogInDate = userData.lastLogIn?.seconds
              ? new Date(userData.lastLogIn.seconds * 1000)
              : null;

          if (lastLogInDate) {
              lastLogInDate.setHours(0, 0, 0, 0);
          }

          if (!lastLogInDate || lastLogInDate.getTime() !== today.getTime()) {
              await updateDoc(userRef, { lastLogIn: Timestamp.fromDate(today) });
              console.log("✅ Last login updated to today.");
          } else {
              console.log("⚠️ Last login already set for today.");
          }
      } catch (error) {
          console.error("❌ Error updating last login:", error);
      }
  };
    
    useEffect(() => {
      let isMounted = true;
    
      const fetchData = async () => {
        try {
          if (!user) return; // Ensure user is loaded before proceeding
    
          const docRef = doc(db, "changelog", "LuNLZFQfBnGc1zeE2lNA");
          const docSnap = await getDoc(docRef);
    
          if (!docSnap.exists()) {
            console.log("No such document!");
            return;
          }
    
          const changelogData = docSnap.data();
    
          // Convert object to array and sort by date (descending order)
          const sortedChangelog = Object.values(changelogData).sort((a, b) => new Date(b.date) - new Date(a.date));
    
          if (!user.lastLogIn) {
            await updateDateLastUsedProperty(); // Ensure lastLogIn is set before running update logic
            return;
          }
    
          const lastLogInDate = new Date(user.lastLogIn.seconds * 1000);
          lastLogInDate.setHours(0, 0, 0, 0); // Normalize last login to midnight
    
          const parseDateString = (dateStr) => {
            const [month, day, year] = dateStr.split('/').map(Number);
            return new Date(year, month - 1, day); // Month is 0-based in JS Date
          };
    
          const recentUpdates = sortedChangelog
            .filter(update => new Date(parseDateString(update.date)) > lastLogInDate)
            .slice(0, 3);
    
          if (recentUpdates.length > 0) {
            await updateDateLastUsedProperty(); // Update lastUsed date BEFORE showing the modal
            setLatestUpdateData(recentUpdates);
            toggleLatestUpdateModal();
          } else {
            await updateDateLastUsedProperty(); // Update lastUsed even if no new updates exist
            console.log("No new updates since last login.");
          }
    
        } catch (error) {
          console.error('Error fetching document:', error);
        }
      };
    
      fetchData();
    
      return () => {
        isMounted = false;
      };
    }, []);    
    


  if (!loadingComplete) {
    return(
      <Route render={() => 
        <Row
            style={{
                display: 'flex',
                justifyContent: 'center', /* Horizontally center */
                alignItems: 'center', /* Vertically center */
                minHeight: '100vh' /* Ensures it covers the entire viewport height */
            }}
        >
            <Spinner color="info">Loading...</Spinner>
        </Row>
        } 
      />
    )
  }

  const darkMode = false;

  console.log("*****START******")
  // console.log("user => ", user)
  // console.log("classes => ", classes)
  // console.log("classRoutes => ", classRoutes)
  // console.log("*****END******")

  return (
    (user && (user.premium || user.trial)) && classRoutes && !location.pathname.includes('/share/') ? ( //Add user.settings.admin for Maintenance
    // user && classRoutes && !location.pathname.includes('/share/') ? (
      <>
      <NotificationAlert ref={notificationAlert} zIndex={9999}/>
      <NotificationAlert ref={helpSessionAlert} zIndex={9999}/>
      <NotificationAlert ref={notificationRef} zIndex={9999} />

      <Modal isOpen={showDBModal} centered toggle={handleDBCloseModal}> 
        <div className="text-center">
          <img 
            src={logo} 
            alt="lesson-launchpad-logo" 
            style={{ 
              width: '100px', 
              height: 'auto', 
              marginTop: '40px',
              marginBottom: '15px', 
              display: 'block', 
              marginLeft: 'auto', 
              marginRight: 'auto' 
            }} 
          />
        </div>
        
        <ModalHeader 
          className="h4 text-center w-100" 
          style={{ 
            border: '0px', 
            margin: '0px', 
            color: '#004aad', 
            fontWeight: 600 // Use numeric value for consistency 
          }}
        >
          Important Update
        </ModalHeader>

        <ModalBody className="text-center">
          Lesson Launchpad has undergone a major database update to improve performance and reliability.  
          You shouldn't experience any disruptions, but if you notice anything unusual, please reach out at 
          <a href="mailto:michael@lessonlaunchpad.com"> michael@lessonlaunchpad.com </a> 
          or use the contact form in Settings.
        </ModalBody>

        <ModalFooter 
          className="d-flex justify-content-center w-100" 
          style={{ border: '0px' }}
        >
          <Button 
            className="button-hover-fix" 
            style={{ borderRadius: '30px', padding: '10px 20px' }}
            onClick={handleDBCloseModal}
          >
            Got It!
          </Button>
        </ModalFooter>
      </Modal>



      {latestUpdateData && //latestUpdateData
        <Modal 
          className="latest-updates-modal"
          isOpen={latestUpdateModal} //latestUpdateModal
          toggle={toggleLatestUpdateModal}
          scrollable
        >
          <ModalHeader toggle={toggleLatestUpdateModal}>
              <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Latest Updates:</h4>
          </ModalHeader>
          {latestUpdateData[0].image ? ( //latestUpdateData[0].image
            <ModalBody>
              <img src={latestUpdateData[0].image}></img>
            </ModalBody>
          ) : (
            <ModalBody>
              {latestUpdateData && latestUpdateData.map((updateMap) => (
                <>
                <h5 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
                {updateMap.date}
                </h5>
                <div style={{ marginLeft: '10px' }}>
                  <ul>
                    {updateMap.changes
                      .split('. ')
                      .filter(change => change.trim() !== '') // Remove empty items
                      .map((change, index) => (
                        <li key={index}>{change.trim()}{change.endsWith('.') ? '' : '.'}</li>
                      ))}
                  </ul>
                </div>
                </>
              ))}
            </ModalBody>
          )}
          <ModalFooter style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', width: '100%' }}>
            To see past updates, visit our{" "}
            <a 
              href="/settings#changelog" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ fontWeight: 'bold', textDecoration: 'none', color: 'blue' }}
            >
              Changelog
            </a>
          </ModalFooter>
        </Modal>
      }

      <div className={darkMode ? "wrapper-dark" : "wrapper"}>
        {mobileAlert}
        {trialAlert}
          <Sidebar
            {...props}
            routes={classRoutes}
            bgColor={backgroundColor}
            activeColor={activeColor}
            navigationVisible={navigationVisible}
          />
        {showNavigationVisibleButton &&
          <button
            style={{
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              border: 'none',
              position: 'fixed',
              left: navigationVisible ? '79px' : '-2px', 
              top: '50%', 
              transform: 'translateY(-50%)', 
              width: '17px', 
              height: '100px', 
              backgroundColor: 'rgb(11, 192, 223)',
              color: 'white',
              zIndex: 1000
            }}
            onClick={() => toggleNavigationVisible()}
          >
            <i className={navigationVisible ? "fa fa-caret-left" : "fa fa-caret-right"}></i>
          </button>
        }       
        <div className={darkMode ? "main-panel-dark" : "main-panel"} ref={mainPanel} style={{width: navigationVisible ? null : '100%'}}>
          {/* <AdminNavbar {...props} handleMiniClick={handleMiniClick} /> */}
          {/* <Switch>
            { false && user && classes.length < 1 ?
              <>
                {console.log("#1")}
                <Route exact path="/" render={(routeProps) => (
                  <Row style={{ flex: 1, alignContent: "center", backgroundColor: 'lightgrey', height: '100vh' }}>
                    <Col
                      md={{
                        offset: 3,
                        size: 6
                      }}
                      sm="12"
                    >
                      <Card body className="text-center" style={{ marginBottom: '0px', border: '1px solid red'}}>
                        <CardHeader className="h2" style={{marginBottom: '0px'}}>NO CLASSES</CardHeader>
                        <CardBody>
                          <div className="h6">
                            CLICK HERE TO ADD A NEW CLASS
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )} />
                <Route exact path="/add-edit-classes" component={AddEditView}></Route>
                <Route exact path="/form" component={AddEditClassForm}></Route>
              </>
            : 
              // <Route exact path="/"><Present props={{classId: user?.classes[0].classId, showMarquee: user?.classes[0].showMarquee, showClock: user?.classes[0].showClock, navigationVisible: navigationVisible}}/></Route>
              <Route 
                exact 
                path="/" 
                render={(routeProps) => (
                  <Present 
                    {...routeProps} 
                    props={{ 
                      classId: classes?.[0]?.classId, 
                      showMarquee: classes?.[0]?.showMarquee, 
                      showClock: classes?.[0]?.showClock, 
                    }}
                  />
                )} 
              />
            }
            {getRoutes(classRoutes)}
            {/* <Redirect exact from="/" to={classRoutes[0].views[0].path} />
          </Switch> */}
          <Switch>
              <Route 
                exact 
                path="/" 
                render={(routeProps) => (
                  <Present 
                    {...routeProps} 
                    props={{ 
                      classId: classes?.[0]?.classId, 
                      showMarquee: classes?.[0]?.showMarquee, 
                      showClock: classes?.[0]?.showClock, 
                    }}
                  />
                )} 
              />
            {getRoutes(classRoutes)}
          </Switch>
        </div>
      </div>
      </>
    ) : user && !location.pathname.includes('/share/') && classRoutes ? //Add && user.settings.admin === true for Maintenance
    (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <img src={logo} alt="lesson-launchpad-logo" style={{ width: '300px', height: 'auto', marginBottom: '25px' }}/>
        <Row>
          <h3>Your trial has ended. Please subscribe to continue using Lesson Launchpad.</h3>
        </Row>
        <Row>
          <NavLink to={{ pathname: '/pricing' }}>
            <Button className="button-hover-fix" style={{ marginLeft: '15px', borderRadius: '30px' }}>
              Click Here to Subscribe to Lesson Launchpad
            </Button>
          </NavLink>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <Row>
          <h6>If you feel you have reached this page in error, please email <a href="mailto:michael@lessonlaunchpad.com">michael@lessonlaunchpad.com</a>.</h6>
        </Row>
      </div>
    // ) : (!user.settings.admin) ? (
    //   <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
    //     <img src={maintenanceLogo} alt="lesson-launchpad-logo" style={{ width: '300px', height: 'auto', marginBottom: '25px' }}/>
    //     <Row>
    //       <h3>Lesson Launchpad is currently undergoing maintenance.</h3>
    //     </Row>
    //   </div>
    ) : (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
        <img src={logo} alt="lesson-launchpad-logo" style={{ width: '300px', height: 'auto', marginBottom: '25px' }}/>
        <Row>
        <h1>WELCOME!</h1>
        </Row>
        <Row>
          <Button 
            style={{borderRadius: '30px', zoom: '2'}} 
            className="button-hover-fix" 
            onClick={() => window.location.reload()}
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
          >
            CLICK HERE TO LAUNCH
          </Button>
        </Row>
        <Row style={{bottom: '10px'}}>
        {/* <h6>If page refresh brings you back here, please email <a href="mailto:michael@lessonlaunchpad.com">michael@lessonlaunchpad.com</a>.</h6> */}
        </Row>
      </div>
    )
  );
}

export default Admin;

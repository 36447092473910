import React, { useState } from 'react';
import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { doc, updateDoc } from 'firebase/firestore';
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { Button, ModalHeader, ModalBody } from 'reactstrap';
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";


const UpdateUserEmail = () => {
  const auth = getAuth();
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  // const handleEmailUpdate = async (e) => {
  //   e.preventDefault();
  //   setMessage('');
  //   setError('');
  
  //   const user = auth.currentUser;
  
  //   if (user && password) {
  //     const credential = EmailAuthProvider.credential(user.email, password);
  
  //     try {
  //       await reauthenticateWithCredential(user, credential);
  
  //       // Update email in Firebase Authentication first
  //       await updateEmail(user, newEmail);
  
  //       // Update only the email field in Firestore
  //       const docRef = doc(db, "users", user.uid);
  //       await setDoc(docRef, { email: newEmail }, { merge: true }); // Use merge to update only email
  
  //       setMessage('Email updated successfully!');
  //     } catch (error) {
  //       // Rollback Firebase email update if Firestore fails
  //       await updateEmail(user, user.email); // Revert email
  //       setError('Failed to update email. Please try again.');
  //       console.error(error);
  //     }
  //   } else {
  //     setError('Please enter your password to update your email.');
  //   }
  // };

  const handleEmailUpdate = async (e) => {
      e.preventDefault();
      setMessage("");
      setError("");

      const user = auth.currentUser;

      if (!user || !password || !newEmail) {
          setError("❌ Please enter your password and new email.");
          return;
      }

      const credential = EmailAuthProvider.credential(user.email, password);

      try {
          // **Step 1: Re-authenticate the user**
          await reauthenticateWithCredential(user, credential);
          console.log("✅ User re-authenticated.");

          // **Step 2: Update email in Firebase Authentication**
          await updateEmail(user, newEmail);
          console.log("✅ Firebase Auth email updated.");

          // **Step 3: Update email in Firestore**
          const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
          await updateDoc(userRef, { email: newEmail }); // ✅ Only updates email field

          setMessage("✅ Email updated successfully!");
      } catch (error) {
          console.error("❌ Error updating email:", error);

          // **Rollback Firebase email update if Firestore fails**
          try {
              await updateEmail(user, user.email); // Revert email
              console.log("🔄 Rolled back email update.");
          } catch (rollbackError) {
              console.error("❌ Error rolling back email:", rollbackError);
          }

          setError("❌ Failed to update email. Please try again.");
      }
  };
  
  

  return (
    <>
      <ModalHeader>Update Email</ModalHeader>
      <ModalBody>
        <form onSubmit={handleEmailUpdate}>
            <div>
            <label>New Email:</label>
            <input 
                type="email" 
                value={newEmail} 
                onChange={(e) => setNewEmail(e.target.value)} 
                required 
            />
            </div>
            <div>
            <label>Current Password:</label>
            <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
            />
            </div>
            <Button type="submit">Update Email</Button>
        </form>
        {message && <p style={{ color: 'green' }}>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </ModalBody>
    </>
  );
};

export default UpdateUserEmail;

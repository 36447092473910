import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { db } from "../../firebase.js";
import { doc, updateDoc, getDoc, getDocs, setDoc, collection, addDoc } from "firebase/firestore";
import backgroundOptionsData from "../../BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, ListGroup, ListGroupItem, Progress, CardTitle, CardHeader, InputGroup} from "reactstrap";
import AgendaStep from "./AgendaStep";
import { createRandomId } from "RandomId";
import FontStylePicker from "../FontStylePicker";
import stampIcon from "../../assets/img/stamp-solid.svg";
import ActiveDatesModal from "../ActiveDatesModal";
import AgendaBoxCopyForDebug from "./AgendaBoxCopyForDebug";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates, zoomContentBox } from "../../utils/ContentBoxUtils";
import { useDispatch } from "react-redux";
import { fetchClassContentBoxes } from "../../features/userSlice"; // Import Redux action


function AgendaBox({content, classId, user, classes, navbarMD, view, printActive, readOnly, clockKey, navigationVisible}) {
  const dispatch = useDispatch()
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [showMainEditButton, setShowMainEditButton] = useState();
  const [showButtons, setShowButtons] = useState();
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [localAgendaBoxData, setLocalAgendaBoxData] = useState();
  const [boxData, setBoxData] = useState();
  const [boxToCopyId, setBoxToCopyId] = useState()
  const [copyBoxModal, setCopyBoxModal] = useState(false)
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [boxToLinkId, setBoxToLinkId] = useState();
  const [linkBoxModal, setLinkBoxModal] = useState(false);
  const [classesSelectedForContentBoxLink, setClassesSelectedForContentBoxLink] = useState([]);
  const today = new Date().toISOString().slice(0,10)
  const stepRefs = useRef([]); // Ref array to track all steps
  const [agendaBoxVersion, setAgendaBoxVersion] = useState(0); //required to force re-render of AgendaBox preview in edit modal

  useEffect(() => {
    setShowMainEditButton(content.steps.length === 0 ? true : false);
    setShowButtons(content.steps.length === 0 ? true : false);
    setLocalAgendaBoxData(content);
    setBoxData(content);
  }, [content]);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-175}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

const saveAgendaBoxData = async () => {
  try {
    if (!user?.uid || !classId || !content?.contentBoxId) {
      console.error("Missing required identifiers: user ID, class ID, or content box ID.");
      return;
    }

    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      content.contentBoxId
    );

    // Create a new Date object for lastEdited
    const currentDate = new Date();
    const formattedDate = `${(currentDate.getMonth() + 1).toString().padStart(2, "0")}/${currentDate.getDate().toString().padStart(2, "0")}/${currentDate.getFullYear()}`;

    const updatedAgendaBox = {
      ...localAgendaBoxData,
      lastEdited: formattedDate
    };

    // Update the content box in Firestore
    await updateDoc(contentBoxRef, updatedAgendaBox);

    // Retrieve the fully updated content box from Firestore
    const updatedDocSnap = await getDoc(contentBoxRef);
    if (updatedDocSnap.exists()) {
      const updatedDoc = updatedDocSnap.data();

      // Handle linked content boxes (update across multiple classes)
      if (updatedDoc.linkedBox) {
        await updateLinkedContentBoxes(user.uid, content.contentBoxId, updatedDoc);
      }
    }

    setShowEditAgendaForm(!showEditAgendaForm);
    setBoxData({ ...updatedAgendaBox });

    console.log("Agenda box updated successfully.");

    if (dispatch) {
      dispatch(fetchClassContentBoxes(user.uid, classId));
      console.log("🔄 User data refreshed after template save.");
  }
  } catch (error) {
    console.error("Error updating agenda box:", error);
  }
};

/**
 * Updates all linked content boxes in other classes that share the same contentBoxId.
 * @param {string} userId - The user's UID.
 * @param {string} contentBoxId - The ID of the content box to update.
 * @param {Object} updatedDoc - The updated document data from Firestore.
 */
 const updateLinkedContentBoxes = async (userId, contentBoxId, updatedDoc) => {
  try {
    // Reference the classes collection
    const classesRef = collection(db, FIREBASE_COLLECTIONS.USERS, userId, FIREBASE_COLLECTIONS.CLASSES);
    const classesSnap = await getDocs(classesRef);

    // Iterate through each class to find linked content boxes
    const updatePromises = classesSnap.docs.map(async (classDoc) => {
      const classId = classDoc.id;
      const contentBoxRef = doc(
        db,
        FIREBASE_COLLECTIONS.USERS,
        userId,
        FIREBASE_COLLECTIONS.CLASSES,
        classId,
        FIREBASE_COLLECTIONS.CONTENT_BOXES,
        contentBoxId
      );

      const contentBoxSnap = await getDoc(contentBoxRef);

      if (contentBoxSnap.exists() && contentBoxSnap.data().linkedBox) {
        // Update only linked content boxes using the latest data from Firestore
        return updateDoc(contentBoxRef, updatedDoc);
      }
    });

    await Promise.all(updatePromises);

    console.log(`✅ Successfully updated all linked content boxes with ID: ${contentBoxId}`);
  } catch (error) {
    console.error("❌ Error updating linked content boxes:", error);
  }
};

const handleZoomChange = async (direction) => {
  const newZoom = await zoomContentBox(
    user.uid,
    classId,
    content.contentBoxId,
    direction,
    false,
    null
  );

  if (newZoom !== undefined) {
    setBoxData((prev) => ({
      ...prev,
      zoom: newZoom,
    }));
  }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content?.startDate === undefined ? "" : content.startDate,
    endDate: content?.endDate === undefined ? "" : content.endDate
  });

  const addTemplateAlert = (contentBox) => {
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="What would you like to call this template?"
        onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        btnSize=""
        defaultValue={contentBox.heading}
      />
    );
  };
  
  const addTemplateConfirmAlert = (e, contentBox) => {
    saveContentBoxTemplate(user, e, contentBox, dispatch);
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
        title={
          <p>
            You entered: <b>{e}</b>
          </p>
        }
      />
    );
  };

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);

const toggleBackgroundContentBoxModal = () => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
};

const toggleSetShowEditAgendaFormModal = () => {
  if (JSON.stringify(localAgendaBoxData) !== JSON.stringify(content)) {
    launchAlertForUnsavedAgendaData();
  } else {
    setShowEditAgendaForm(!showEditAgendaForm);
  }
};

const handleSaveBackground = async (imageFileName) => {
  await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  setAgendaBoxVersion(prev => prev + 1); // Force re-render
};

const warningWithConfirmAndCancelMessage = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: '#dc3545'}}
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const launchAlertForUnsavedAgendaData = () => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="You have unsaved changes!"
      onConfirm={() => {saveAgendaBoxData(); hideAlert()}}
      onCancel={() => {setShowEditAgendaForm(!showEditAgendaForm); hideAlert()}}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: 'rgb(11, 192, 223)', borderRadius: '30px'}}
      cancelBtnBsStyle="danger"
      confirmBtnText="Save changes!"
      cancelBtnStyle={{backgroundColor: '#dc3545', borderRadius: '30px'}}
      cancelBtnText="Close anyway"
      showCancel
      btnSize=""
    >
      You will not be able to recover your edits!
    </ReactBSAlert>
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

function convertSecondsToMinutes(step) {
  const totalSeconds = (Number(step.seconds) + (Number(step.minutes) * 60)) - Number(step.timeElapsed)
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

const [showEditAgendaForm, setShowEditAgendaForm] = useState(false);

const addAgendaStep = () => {
  const newStep = {
    stepId: createRandomId(),
    title: '', // Placeholder for step text
    minutes: 0,
    seconds: 0,
    subTask: false,
    subTaskSteps: []
  };
  // Add a new step object to the steps array
  setLocalAgendaBoxData(prevData => ({
    ...prevData,
    steps: [...prevData.steps, newStep]
  }));
};

const addAgendaSubTaskStep = (stepId) => {
  const stepIndex = localAgendaBoxData.steps.findIndex((step) => step.stepId === stepId);

  if (stepIndex !== -1) {
    const newSubTaskStep = {
      stepId: createRandomId(),
      title: '', // Placeholder for step text
      minutes: 0,
      seconds: 0,
    };

    setLocalAgendaBoxData(prevData => {
      const newSteps = [...prevData.steps]; // Create a copy of steps
      
      // Correcting how subTaskSteps are updated
      const updatedSubTaskSteps = newSteps[stepIndex].subTaskSteps 
        ? [...newSteps[stepIndex].subTaskSteps, newSubTaskStep] // If it exists, spread and add new
        : [newSubTaskStep]; // Otherwise, start a new array

      newSteps[stepIndex] = {...newSteps[stepIndex], subTaskSteps: updatedSubTaskSteps}; // Update the step object immutably

      return { ...prevData, steps: newSteps }; // Return new object with updated steps
    });
  }
};

function deleteAgendaStep(stepId) {
  const updatedData = localAgendaBoxData.steps.filter(step => step.stepId !== stepId);
  setLocalAgendaBoxData(prevData => ({
    ...prevData,
    steps: updatedData
  }));

  setAgendaBoxVersion(prev => prev + 1); // Force re-render

}

function deleteAgendaSubTaskStep(stepIndex, subTaskStepId) {
  // Use a functional update to ensure you're working with the most up-to-date state
  setLocalAgendaBoxData(prevData => {
    // Create a deep copy of the steps to avoid any direct mutation
    let newSteps = prevData.steps.map(step => ({
      ...step,
      subTaskSteps: step.subTaskSteps ? [...step.subTaskSteps] : []
    }));

    // Filter the subTaskSteps array to remove the specified subTaskStep
    newSteps[stepIndex].subTaskSteps = newSteps[stepIndex].subTaskSteps.filter(subTaskStep => 
      subTaskStep.stepId !== subTaskStepId
    );

    // Return the updated state object
    return {
      ...prevData,
      steps: newSteps
    };
  });
}

const handleAgendaTitleChange = (e) => {
  setLocalAgendaBoxData((prevData) => ({
    ...prevData,
    heading: e.target.value,
  }));

  setAgendaBoxVersion((prev) => prev + 1); // Increment version to trigger re-render
};

const handleAgendaStartTimeChange = (e) => {
  const startTime = e.target.value;

  setLocalAgendaBoxData((prevData) => ({
    ...prevData,
    startTime: startTime,
  }));

  // console.log('old time => ', e._d.toTimeString())

  setAgendaBoxVersion((prev) => prev + 1); // Increment version to trigger re-render
};

const handleStepTextChange = (stepId, e) => {
  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step) =>
      step.stepId === stepId ? { ...step, title: e.target.value } : step
    );

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleSubTaskStepTextChange = (stepIndex, stepId, e) => {
  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step, index) => {
      if (index === stepIndex) {
        const updatedSubTaskSteps = step.subTaskSteps.map((subTaskStep) =>
          subTaskStep.stepId === stepId ? { ...subTaskStep, title: e.target.value } : subTaskStep
        );

        return { ...step, subTaskSteps: updatedSubTaskSteps };
      }
      return step;
    });

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleStepDurationChange = (stepId, e, minute) => {
  const field = minute ? "minutes" : "seconds";
  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step) =>
      step.stepId === stepId ? { ...step, [field]: e.target.value } : step
    );

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleSubTaskStepDurationChange = (stepIndex, stepId, e, minute) => {
  const field = minute ? "minutes" : "seconds";
  const input = parseInt(e.target.value, 10) || 0;

  setLocalAgendaBoxData((prevData) => {
    const updatedSteps = prevData.steps.map((step, index) => {
      if (index === stepIndex) {
        const updatedSubTaskSteps = step.subTaskSteps.map((subTaskStep) =>
          subTaskStep.stepId === stepId ? { ...subTaskStep, [field]: input } : subTaskStep
        );

        // Calculate total duration from all subTaskSteps
        const totalMinutes = updatedSubTaskSteps.reduce((acc, sub) => acc + (sub.minutes || 0), 0);
        const totalSeconds = updatedSubTaskSteps.reduce((acc, sub) => acc + (sub.seconds || 0), 0);

        // Normalize total duration
        const normalizedMinutes = totalMinutes + Math.floor(totalSeconds / 60);
        const normalizedSeconds = totalSeconds % 60;

        return {
          ...step,
          subTaskSteps: updatedSubTaskSteps,
          minutes: normalizedMinutes,
          seconds: normalizedSeconds,
        };
      }
      return step;
    });

    return { ...prevData, steps: updatedSteps };
  });

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const handleAgendaFontChange = (e) => {
  setLocalAgendaBoxData((prevData) => ({
    ...prevData,
    [e.target.id]: e.target.value,
  }));

  setAgendaBoxVersion((prev) => prev + 1); // Increment version
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const toggleLinkedBoxModal = () => {
  if (linkBoxModal) {
    setClassesSelectedForContentBoxLink([])
  } else {
    setClassesSelectedForContentBoxLink([classId])
  }
  setLinkBoxModal(!linkBoxModal)
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const handleCheckboxChangeForLinkedBoxes = (classId) => {
  setClassesSelectedForContentBoxLink(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const addLinkedBoxToClasses = async () => {
  try {
    if (!boxToLinkId || classesSelectedForContentBoxLink.length === 0) {
      console.error("No content box selected or no classes selected for linking.");
      return;
    }

    // Reference the original content box document
    const originalBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      boxToLinkId
    );

    const originalBoxSnap = await getDoc(originalBoxRef);

    if (!originalBoxSnap.exists()) {
      console.error("Original content box not found.");
      return;
    }

    let boxToLink = originalBoxSnap.data();

    // Ensure the linked box has the same contentBoxId
    const linkedBoxData = { ...boxToLink, linkedBox: true };

    // Process each selected class
    await Promise.all(
      classesSelectedForContentBoxLink.map(async (targetClassId) => {
        const targetBoxDocRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          boxToLinkId // ✅ Ensure document ID matches `contentBoxId`
        );

        // ✅ Add the linked content box
        if (targetClassId === classId) {
          await setDoc(targetBoxDocRef, linkedBoxData, { merge: true });
        } else {
          await setDoc(targetBoxDocRef, linkedBoxData);
        }

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId
        if (!updatedOrder.includes(boxToLinkId)) {
          updatedOrder.push(boxToLinkId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxLink.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "link");

    toggleLinkedBoxModal();
    console.log("✅ Linked content box added and order updated successfully!");
  } catch (error) {
    console.error("❌ Failed to add linked content box:", error);
  }
};

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    const multiBox = false; //DECLARING THIS FOR NOW TO AVOID REFACTOR

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const handleStepMove = (index, direction) => {
  let updatedSteps = [...localAgendaBoxData.steps];
  
  if (index > 0 && direction === "up") {
    // Swap the current item with the one above it
    [updatedSteps[index], updatedSteps[index - 1]] = [
      updatedSteps[index - 1],
      updatedSteps[index],
    ];
  }
  if (index < localAgendaBoxData.steps.length - 1 && direction === "down") {
    // Swap the current item with the one below it
    [updatedSteps[index], updatedSteps[index + 1]] = [
      updatedSteps[index + 1],
      updatedSteps[index],
    ];
  }

  setLocalAgendaBoxData({
    ...localAgendaBoxData,
    steps: updatedSteps,
    version: (localAgendaBoxData.version || 0) + 1 // Increment the version to force re-render
  });
};

const handleSubTaskStepMove = (stepIndex, subTaskStepIndex, direction) => {
  // Create a deep copy of the steps array
  const updatedSteps = localAgendaBoxData.steps.map((step, idx) => {
    if (idx === stepIndex) {
      return {
        ...step,
        subTaskSteps: [...step.subTaskSteps] // Ensure subTaskSteps is a new array
      };
    }
    return step;
  });

  // Perform the swap on the copied subTaskSteps array
  const updatedSubTaskSteps = updatedSteps[stepIndex].subTaskSteps;
  if (subTaskStepIndex > 0 && direction === "up") {
    [updatedSubTaskSteps[subTaskStepIndex], updatedSubTaskSteps[subTaskStepIndex - 1]] = [
      updatedSubTaskSteps[subTaskStepIndex - 1],
      updatedSubTaskSteps[subTaskStepIndex],
    ];
  } else if (subTaskStepIndex < updatedSubTaskSteps.length - 1 && direction === "down") {
    [updatedSubTaskSteps[subTaskStepIndex], updatedSubTaskSteps[subTaskStepIndex + 1]] = [
      updatedSubTaskSteps[subTaskStepIndex + 1],
      updatedSubTaskSteps[subTaskStepIndex],
    ];
  }

  // Update the state with the new steps array
  setLocalAgendaBoxData({
    ...localAgendaBoxData,
    steps: updatedSteps,
    version: (localAgendaBoxData.version || 0) + 1 // Increment the version to force re-render
  });
};

const [showAddResourceLink, setShowAddResourceLink] = useState(false);
const [indexOfStepToEditResource, setIndexOfStepToEditResource] = useState();
const [indexOfSubStepToEditResource, setIndexOfSubStepToEditResource] = useState();
const [stepOrSubStep, setStepOrSubStep] = useState();
const [resourceLink, setResourceLink] = useState();
const [isResourceLinkValid, setIsResourceLinkValid] = useState(true);


const handleAddResourceLinkClick = (stepIndex, subStepIndex, stepOrSubStep) => {
  if (stepOrSubStep?.length > 0) { //Opening Modal
    setShowAddResourceLink(!showAddResourceLink);
    const resourceLinkToEdit = stepOrSubStep === "step" ? localAgendaBoxData.steps[stepIndex]?.resourceLink || null : localAgendaBoxData.steps[stepIndex].subTaskSteps[subStepIndex]?.resourceLink || null;
    setResourceLink(resourceLinkToEdit);
    setIndexOfStepToEditResource(stepIndex);
    setIndexOfSubStepToEditResource(subStepIndex)
    setStepOrSubStep(stepOrSubStep);
  } else { //Closing Modal
    setShowAddResourceLink(!showAddResourceLink);
    setResourceLink(null);
    setIndexOfStepToEditResource(null);
    setIndexOfSubStepToEditResource(null);
    setStepOrSubStep(null);
  }
}

const handleResourceLinkChange = (value) => {
  if (!/^https?:\/\//i.test(value)) {
    value = `https://${value}`;
  }
  setResourceLink(value);
  setIsResourceLinkValid(validateUrl(value));
}

const validateUrl = (value) => {
  try {
    new URL(value);
    return true;
  } catch (_) {
    return false;
  }
};

const handleResourceChangeSubmit = () => {
  if (stepOrSubStep === "step") {
    let updatedSteps = [...localAgendaBoxData.steps];

    // Create a new object with the updated resourceLink
    let updatedStep = {
      ...updatedSteps[indexOfStepToEditResource],
      resourceLink: resourceLink
    };

    // Replace the old step with the new one
    updatedSteps[indexOfStepToEditResource] = updatedStep;

    // Update the state with the new steps array
    setLocalAgendaBoxData({
      ...localAgendaBoxData,
      steps: updatedSteps,
    });
  } else if (stepOrSubStep === "subStep") {
    let updatedSteps = [...localAgendaBoxData.steps];

    // Create a new object with the updated resourceLink for the subStep
    let updatedSubTaskSteps = [...updatedSteps[indexOfStepToEditResource].subTaskSteps];
    updatedSubTaskSteps[indexOfSubStepToEditResource] = {
      ...updatedSubTaskSteps[indexOfSubStepToEditResource],
      resourceLink: resourceLink
    };

    // Create a new object for the step that contains the updated subTaskSteps
    let updatedStep = {
      ...updatedSteps[indexOfStepToEditResource],
      subTaskSteps: updatedSubTaskSteps
    };

    // Replace the old step with the new one
    updatedSteps[indexOfStepToEditResource] = updatedStep;

    // Update the state with the new steps array
    setLocalAgendaBoxData({
      ...localAgendaBoxData,
      steps: updatedSteps,
    });
  } else {
    window.alert("error");
  }

  setShowAddResourceLink(!showAddResourceLink);
};

const calculateTotalTime = () => {
  let runningTotal = 0; // Use let since the value is updated

  // Accumulate total seconds from steps
  localAgendaBoxData.steps.forEach(step => {
    const stepMinutes = step.minutes * 60 || 0; // Handle potential undefined values
    const stepSeconds = step.seconds || 0; // Handle potential undefined values
    runningTotal += Number(stepMinutes) + Number(stepSeconds);
  });

  // Calculate minutes and seconds
  const totalMinutes = Math.floor(runningTotal / 60); // Convert total seconds to minutes
  const remainingSeconds = runningTotal % 60; // Get the remaining seconds

  return `${totalMinutes} minutes ${remainingSeconds.toString()} seconds`; // Format with leading zero for seconds
};

useEffect(() => {
  if (boxData?.steps) {
    stepRefs.current = boxData.steps.map((_, i) => stepRefs.current[i] || React.createRef());
  }
}, [boxData?.steps]);

useLayoutEffect(() => {
  setTimeout(() => {
    if (boxData) {
      const activeStepIndex = boxData.steps.findIndex((step) => step.active);
      if (activeStepIndex !== -1 && stepRefs.current[activeStepIndex]?.current) {
        stepRefs.current[activeStepIndex].current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, 50); // Delay to ensure DOM updates
}, [boxData?.steps]);

  return boxData && (
    <>
      <Modal className="open-widget-modal" isOpen={showAddResourceLink} toggle={handleAddResourceLinkClick} size="sm">
        <ModalHeader toggle={handleAddResourceLinkClick}></ModalHeader>
        <ModalBody>
          <div>
            Input your link.
            <Input
              type="text"
              onChange={(e) => handleResourceLinkChange(e.target.value)}
              placeholder={"Your link here..."}
              required={true}
              defaultValue={resourceLink}
              valid={isResourceLinkValid}
              invalid={!isResourceLinkValid}
              prefix="http://"
            />
          </div>
        </ModalBody>
        <ModalFooter style={{display: 'flex', justifyContent: 'center', alignContent: 'center', border: '0px', paddingBottom: '10px'}}>
          <Button
            disabled={!isResourceLinkValid}
            onClick={() => handleResourceChangeSubmit()}
            style={{borderRadius: '30px'}}
          >
            Add Link
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
  <ModalBody>
    <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
      Copy box to:
    </h3>

    {user && classes && (
      <Row>
          <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
            {classes.map((x, index) => (
              <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                <Label check>
                  <Input
                    type="checkbox"
                    defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                    onChange={() => handleCheckboxChange(x.classId)}
                  />
                  <span className="form-check-sign" />
                  {x.className}
                </Label>
              </FormGroup>
            ))}
          </Col>
      </Row>
    )}
  </ModalBody>
  <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
    <Button 
      onClick={toggleCopyBoxModal}
      className="delete-button"
      style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
    >
      Cancel
    </Button>
    <Button 
      className="button-hover-fix"
      disabled={classesSelectedForContentBoxCopy.length < 1} 
      onClick={() => copyBoxToClasses()} 
      style={{borderRadius: '30px'}}>
      <i className="fa fa-copy" />{' '}Copy
    </Button>
  </ModalFooter>
</Modal>

  <Modal isOpen={linkBoxModal} toggle={toggleLinkedBoxModal} centered style={{width: '450px'}}>
      <ModalBody>
        <h3 style={{ textAlign: "center", marginBottom: "15px", fontWeight: "bold", color: "#004aad", margin: "0px"}}>
          Add this Linked Box to:
        </h3>
        <Row>
          <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '130px'}}>
            {user && classes && classes.map((x, index) => 
              <FormGroup check key={`linkBoxModalClassOptions-${index}`}>
                <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={classesSelectedForContentBoxLink.length > 0 && classesSelectedForContentBoxLink.includes(x.classId)}
                  onChange={() => handleCheckboxChangeForLinkedBoxes(x.classId)}
                  disabled={x.classId === classId}
                />                
                <span className="form-check-sign" />
                {x.className}
                </Label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
        <Button 
          onClick={toggleLinkedBoxModal} 
          className="delete-button" 
          style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        >
          Cancel
        </Button>
        <Button
          className="button-hover-fix"
          disabled={classesSelectedForContentBoxLink.length < 1}
          onClick={() => addLinkedBoxToClasses()}
          style={{borderRadius: '30px'}}
        >
          <i className="fa fa-link" />{' '}Link Box
        </Button>
      </ModalFooter>
    </Modal>


    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px',  height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}
      onMouseEnter={() => content.steps.length !== 0 && setShowMainEditButton(true)}
      onMouseLeave={() => { content.steps.length !== 0 && setShowMainEditButton(false); setShowButtons(false)}}
    >
        {alert}
        <Card 
          key={content?.contentBoxId}
          style={{ height: location.pathname === '/print' ? '800px' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content?.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
          {showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      className={content.steps.length === 0 ? "empty-agenda-box-alert" : null}
                      id="editAgendaForm"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => setShowEditAgendaForm(!showEditAgendaForm)}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editAgendaForm"
                        placement="left"
                      >
                          Edit Agenda
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i className="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxBigger"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => handleZoomChange('up')}
                    >
                      <i className="fa fa-plus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxBigger"
                        placement="left"
                      >
                        Make Agenda Text Bigger
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxSmaller"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => handleZoomChange('down')}
                    >
                      <i className="fa fa-minus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxSmaller"
                        placement="left"
                      >
                        Make Agenda Text Smaller
                      </UncontrolledTooltip>
                      {user.settings?.useActiveDates ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) :
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>

                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="stamp"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip>

                      <Button
                        // className="btn-round btn-icon"
                        id="makeLinkedBox"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(900%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {setBoxToLinkId(content.contentBoxId); toggleLinkedBoxModal()}}
                      >
                        <i className="fa fa-link"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="makeLinkedBox"
                        placement="left"
                      >
                          Make Linked Box (Beta)
                      </UncontrolledTooltip>
                    {/* <Button
                    // className="btn-round btn-icon"
                    id="saveContentBoxAsTemplate"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '9999'}}
                    size="sm"
                    onClick={() => addTemplateAlert(content)}
                    >
                      <i className="fa fa-copy" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip> */}
                    <Button
                    className="button-delete"
                    id="deleteContentBox"
                    type="button"
                    style={{ color: 'white', position: 'absolute', transform: 'translateY(1020%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="deleteContentBox"
                        placement="left"
                      >
                          Delete
                      </UncontrolledTooltip>
                  </>
                  )}
            </>
          )}

          {content.steps.length === 0 ? (
            <>
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  className="button-hover-fix"
                  onClick={() => setShowEditAgendaForm(!showEditAgendaForm)}
                  style={{
                    borderRadius: '30px'
                  }}
                >
                  <i className='fa fa-pencil' />
                            {' '}Edit Agenda
                </Button>
              </div>
            </>
          ) : (
            <>
              <AgendaBoxCopyForDebug navbarMD={navbarMD} navigationVisible={navigationVisible} content={localAgendaBoxData} zoom={boxData.zoom} key={agendaBoxVersion}/>
            </>
          )}

        </Card>
      </Col>

        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>
        
        <Modal
          isOpen={backgroundContentBoxModal}
          toggle={toggleBackgroundContentBoxModal}
          backdrop={false}
          scrollable
        >
          <ModalHeader toggle={toggleBackgroundContentBoxModal} className="background-modal-header"/>
          <ModalBody style={{padding: '0'}} className="agenda-body">
            <Container>
              <Row xs="6">
                {backgroundOptionsData.map((option, index) => (
                  <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                    <div className="image-container">
                      <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        <Modal
          className="agenda-edit-modal"
          isOpen={showEditAgendaForm}
          toggle={toggleSetShowEditAgendaFormModal}
          backdrop={true}
          scrollable
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "90vh", // Prevents modal from growing beyond the screen
          }}
        >
          <ModalHeader className="agenda-edit-modal-header" toggle={toggleSetShowEditAgendaFormModal} style={{ flexShrink: 0 }}>
            <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px", padding: '0px' }}>Agenda Editor</h4>
          </ModalHeader>
          <ModalBody
            style={{
              flex: "1 1 auto", // Allows the body to grow while keeping footer fixed
              padding: "0px"
            }}
            className="agenda-body"
          >
          <Row>
          <Col 
            md="7"
            style={{
              borderRight: "1px solid #ccc",
              overflowY: "auto", // Enables scrolling inside this column
            }}
          >
            <Row style={{padding: "15px 40px"}}>
              <Col md="8">
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    defaultValue={localAgendaBoxData.heading}
                    placeholder="Ex: Agenda"
                    onChange={(e) => handleAgendaTitleChange(e)}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4">
                <FormGroup>
                  <Label>Start Time</Label>
                  {/* <ReactDatetime
                    dateFormat={false}
                    timeFormat={"h:mm A"} // Ensure proper format with uppercase "A" for AM/PM
                    value={moment(localAgendaBoxData?.startTime, "h:mm A")} // Ensure correct parsing
                    inputProps={{
                      className: "form-control",
                      placeholder: "Click to select time..."
                    }}
                    onChange={handleAgendaStartTimeChange}
                  /> */}
                  <Input
                    placeholder="Click to select time..."
                    onChange={handleAgendaStartTimeChange}
                    type="time"
                    id="startTime"
                    value={localAgendaBoxData?.startTime}
                    step="1"  // Allows seconds input
                    style={{ borderRadius: '8px' }}
                  />    
                </FormGroup>
              </Col>
            </Row>
            <hr></hr>
            <Row>
              <Col md="4" style={{padding: '0px 60px'}}>
                <FormGroup>
                  <Label>Title Font</Label>
                  <FontStylePicker
                    id={"titleFont"}
                    onChangeFunction={handleAgendaFontChange}
                    selectedFont={localAgendaBoxData.titleFont || "montserrat"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Title Font Color</Label>
                  <Input
                    type="color"
                    placeholder=""
                    id="titleColor"
                    style={{height: '39px'}}
                    onChange={(e) => handleAgendaFontChange(e)}
                    value={localAgendaBoxData.titleColor}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4" style={{padding: '0px 60px', borderLeft: '1px solid #ccc', borderRight: '1px solid #ccc'}}>
                <FormGroup>
                  <Label>Step Font</Label>
                  <FontStylePicker
                    id={"stepFont"}
                    onChangeFunction={handleAgendaFontChange}
                    selectedFont={localAgendaBoxData.stepFont || "montserrat"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Step Font Color</Label>
                  <Input
                    type="color"
                    placeholder=""
                    id="stepColor"
                    style={{height: '39px'}}
                    onChange={(e) => handleAgendaFontChange(e)}
                    value={localAgendaBoxData.stepColor}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md="4" style={{padding: '0px 60px'}}>
                <FormGroup>
                  <Label>Sub-step Font</Label>
                  <FontStylePicker
                    id={"subTaskStepFont"}
                    onChangeFunction={handleAgendaFontChange}
                    selectedFont={localAgendaBoxData.subTaskStepFont || "montserrat"}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Sub-step Font Color</Label>
                  <Input
                    type="color"
                    placeholder=""
                    id="subTaskStepColor"
                    style={{height: '39px'}}
                    onChange={(e) => handleAgendaFontChange(e)}
                    value={localAgendaBoxData.subTaskStepColor}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
            {localAgendaBoxData && localAgendaBoxData.steps?.map((step, index) => (
              <React.Fragment key={`fragment-AgendaStep-${classId}-${index}-${step.stepId}`}>
              <AgendaStep
                step={step}
                stepsLength={localAgendaBoxData.steps?.length}
                index={index}
                deleteAgendaStep={deleteAgendaStep}
                handleStepTextChange={handleStepTextChange}
                handleStepDurationChange={handleStepDurationChange}
                addAgendaSubTaskStep={addAgendaSubTaskStep}
                deleteAgendaSubTaskStep={deleteAgendaSubTaskStep}
                handleSubTaskStepTextChange={handleSubTaskStepTextChange}
                handleSubTaskStepDurationChange={handleSubTaskStepDurationChange}
                handleStepMove={handleStepMove}
                handleSubTaskStepMove={handleSubTaskStepMove}
                handleAddResourceLinkClick={handleAddResourceLinkClick}
              />
              </React.Fragment>
            ))}
            <hr></hr>
            <Row>
              <Col style={{left: '50px'}}>
                <p onClick={() => addAgendaStep()} style={{textDecoration: 'underline', cursor: 'pointer'}}><i className="fa fa-plus" />{' '}Add Step</p>
              </Col>
            </Row>
          </Col>
            <Col>
              <div style={{zoom: '.9'}}>
                <Col style={{ flex: "1", minHeight: "0", padding: '4px',  height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}>
                  <Card 
                    key={`edit-modal-preview-${content?.contentBoxId}`}
                    style={{ height: location.pathname === '/print' ? '800px' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content?.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
                  >
                    <AgendaBoxCopyForDebug content={localAgendaBoxData} key={agendaBoxVersion} />
                  </Card>
                </Col>
              </div>
            </Col>
          </Row>
          </ModalBody>
          <ModalFooter
            style={{
              flexShrink: 0,
              position: "sticky",
              bottom: "0",
              backgroundColor: "white",
              width: "100%",
              boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)", // Adds shadow for better visibility
              zIndex: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Col>
              <b>Total Duration: </b> {calculateTotalTime()}
            </Col>
            <Col style={{textAlign: 'center'}}>
              <Button
                className="button-hover-fix"
                id="submitAgenda"
                type="button"
                size="sm"
                onClick={() => saveAgendaBoxData()}
                style={{
                  padding: "10px 30px",
                  borderRadius: "30px",
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                }}
              >
                 <i className='fa fa-save' />
                            {' '}Save Agenda
              </Button>
            </Col>
            <Col></Col>
          </ModalFooter>
        </Modal>

    </>
  );
}

export default AgendaBox;
import { useState } from 'react';
import { doc, getDoc, setDoc, updateDoc, collection, getDocs } from 'firebase/firestore';
import { db } from "../../firebase.js";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { Card, CardBody, Col, CardFooter, FormFeedback, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";

export function useLinkedBoxManager({ user, classId, classes, setAlert, hideAlert }) {
    console.log("classId => ", classId)
  const [linkedBoxModalOpen, setLinkedBoxModalOpen] = useState(false);
  const [boxToLinkId, setBoxToLinkId] = useState();
  const [classesSelectedForContentBoxLink, setClassesSelectedForContentBoxLink] = useState([]);

  console.log("classesSelectedForContentBoxLink => ", classesSelectedForContentBoxLink)

  const toggleLinkedBoxModal = () => {
    if (linkedBoxModalOpen) {
      setClassesSelectedForContentBoxLink([]);
    } else {
      setClassesSelectedForContentBoxLink([classId]);
    }
    setLinkedBoxModalOpen(!linkedBoxModalOpen);
  };

  const handleCheckboxChangeForLinkedBoxes = (classId) => {
    setClassesSelectedForContentBoxLink(prev => {
      if (prev.includes(classId)) {
        return prev.filter(id => id !== classId);
      } else {
        return [...prev, classId];
      }
    });
  };

  const addLinkedBoxToClasses = async () => {
    try {
      if (!boxToLinkId || classesSelectedForContentBoxLink.length === 0) {
        console.error("No content box selected or no classes selected for linking.");
        return;
      }

      const originalBoxRef = doc(
        db,
        FIREBASE_COLLECTIONS.USERS,
        user.uid,
        FIREBASE_COLLECTIONS.CLASSES,
        classId,
        FIREBASE_COLLECTIONS.CONTENT_BOXES,
        boxToLinkId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);

      if (!originalBoxSnap.exists()) {
        console.error("Original content box not found.");
        return;
      }

      let boxToLink = originalBoxSnap.data();
      const linkedBoxData = { ...boxToLink, linkedBox: true };

      await Promise.all(
        classesSelectedForContentBoxLink.map(async (targetClassId) => {
          const targetBoxDocRef = doc(
            db,
            FIREBASE_COLLECTIONS.USERS,
            user.uid,
            FIREBASE_COLLECTIONS.CLASSES,
            targetClassId,
            FIREBASE_COLLECTIONS.CONTENT_BOXES,
            boxToLinkId
          );

          if (targetClassId === classId) {
            await setDoc(targetBoxDocRef, linkedBoxData, { merge: true });
          } else {
            await setDoc(targetBoxDocRef, linkedBoxData);
          }

          const targetClassRef = doc(
            db,
            FIREBASE_COLLECTIONS.USERS,
            user.uid,
            FIREBASE_COLLECTIONS.CLASSES,
            targetClassId
          );

          const targetClassSnap = await getDoc(targetClassRef);
          if (!targetClassSnap.exists()) return;

          const targetClassData = targetClassSnap.data();
          let updatedOrder = targetClassData.contentBoxesOrder || [];

          if (!updatedOrder.includes(boxToLinkId)) {
            updatedOrder.push(boxToLinkId);
            await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
          }
        })
      );

      const classNameListForAlert = classes
        .filter(classItem => classesSelectedForContentBoxLink.includes(classItem.classId))
        .map(classItem => classItem.className);

        setAlert(
            <ReactBSAlert
              success
              style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
              title="Linked Box Created!"
              onConfirm={hideAlert}
              onCancel={hideAlert}
              confirmBtnBsStyle="info button-hover-fix"
              confirmBtnStyle={{ borderRadius: '30px', width: '100px' }}
              btnSize=""
            >
              This box is now linked in:
              {classNameListForAlert.map((className, idx) => (
                <li key={idx}>{className}</li>
              ))}
            </ReactBSAlert>
          );

      toggleLinkedBoxModal();
      console.log("✅ Linked content box added and order updated successfully!");
    } catch (error) {
      console.error("❌ Failed to add linked content box:", error);
    }
  };

  return {
    linkedBoxModalOpen,
    setBoxToLinkId,
    toggleLinkedBoxModal,
    classesSelectedForContentBoxLink,
    handleCheckboxChangeForLinkedBoxes,
    addLinkedBoxToClasses
  };
}

export function LinkedBoxModal({ linkedBoxModalOpen, toggleLinkedBoxModal, classes, classesSelectedForContentBoxLink, handleCheckboxChangeForLinkedBoxes, classId, addLinkedBoxToClasses }) {
    return (
        <Modal isOpen={linkedBoxModalOpen} toggle={toggleLinkedBoxModal} centered style={{width: '450px'}}>
            <ModalBody>
                <h3 style={{ textAlign: "center", marginBottom: "15px", fontWeight: "bold", color: "#004aad", margin: "0px"}}>
                Add this Linked Box to:
                </h3>
                <Row>
                <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '130px'}}>
                    {classes && classes.map((x, index) => 
                    <FormGroup check key={`linkBoxModalClassOptions-${index}`}>
                        <Label check>
                        <Input
                        type="checkbox"
                        defaultChecked={classesSelectedForContentBoxLink.length > 0 && classesSelectedForContentBoxLink.includes(x.classId)}
                        onChange={() => handleCheckboxChangeForLinkedBoxes(x.classId)}
                        disabled={x.classId === classId}
                        />                
                        <span className="form-check-sign" />
                        {x.className}
                        </Label>
                    </FormGroup>
                    )}
                </Col>
                </Row>
            </ModalBody>
            <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
                <Button 
                onClick={toggleLinkedBoxModal} 
                className="delete-button" 
                style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
                >
                Cancel
                </Button>
                <Button
                className="button-hover-fix"
                disabled={classesSelectedForContentBoxLink.length < 1}
                onClick={() => addLinkedBoxToClasses()}
                style={{borderRadius: '30px'}}
                >
                <i className="fa fa-link" />{' '}Link Box
                </Button>
            </ModalFooter>
        </Modal>
    )
}

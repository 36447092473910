
import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase.js";
import { setDoc, doc, getDoc, collection, addDoc, updateDoc } from "firebase/firestore";
import GoogleSlidesWrapper from "../GoogleSlidesWrapper"; // Import the wrapper component
import backgroundOptionsData from "../../BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import powerpoint from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/powerpoint-link.gif"
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, FormFeedback, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import { createRandomId } from "RandomId";
import ActiveDatesModal from "../ActiveDatesModal";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates } from "../../utils/ContentBoxUtils";
import { useDispatch } from "react-redux";

function PowerpointBox({content, navbarMD, view, user, classes, classId, multiBox, updateContentBox, updateContentBoxWithinMultiBox, printActive, navigationVisible}) {
  const dispatch = useDispatch()
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [slideNumber, setSlideNumber] = useState(
    parseInt(localStorage.getItem('slideNumber'), 10) || 1
  );
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const today = new Date().toISOString().slice(0,10);

  // Update localStorage whenever slideNumber changes
  useEffect(() => {
    localStorage.setItem('slideNumber', slideNumber);
  }, [slideNumber]);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-25}px`});
    };

    const handleFullscreenChange = () => {
      const isFullscreen = document.fullscreenElement !== null;
      if (!isFullscreen) {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188;
        const cardHeight = windowHeight - desiredDistance;
        setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
        // toggleFullScreenButton();
        setIsFullScreen(false);
      } else {
        // setIsFullScreen(true)
      }
    };
  
    window.addEventListener("resize", updateCardHeight);
    // window.addEventListener("resize", console.log("resize"));
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    // document.addEventListener("fullscreenchange", console.log("fullscreenchange"));
  
    updateCardHeight();
  
    return () => {
      window.removeEventListener("resize", updateCardHeight);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [navbarMD, view, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false)

  useEffect(() => {
    // This code will run after the component has mounted
    const element = document.getElementsByClassName('punch-viewer-container');
    if (element) {
      // Do something with the element
      console.log('Found element:', element);
    } else {
      console.log('Element not found');
    }
  }, []); // Empty dependency array ensures this effect runs only once after initial render

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      document.getElementById("slides").requestFullscreen().catch(err => {
        console.log(`Error attempting to enable fullscreen: ${err.message}`);
      });
      toggleFullScreenButton();
      setIsFullScreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().then(() => {
          const windowHeight = window.innerHeight;
          const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188;
          const cardHeight = windowHeight - desiredDistance;
          setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
          toggleFullScreenButton();
          setIsFullScreen(false);
        }).catch(err => {
          console.log(`Error attempting to exit fullscreen: ${err.message}`);
        });
      }
    }
  };

  const [showFullScreenButton, setShowFullScreenButton] = useState(false)

  const toggleFullScreenButton = () => {
    setShowFullScreenButton(!showFullScreenButton)
  }

  const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

//   const saveContentBoxActiveDates = async (datesFromSetActiveDatesModal) => {
//     try {
//       const docRef = doc(db, "users", user.uid);
  
//       const userCopy = JSON.parse(JSON.stringify(user));
  
//       // Find the class index based on classId
//       const classIndex = userCopy.classes.findIndex(
//         (c) => c.classId === classId
//       );
  
//       if (classIndex !== -1) {
//         // Find the content box index based on contentBoxId
//         const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//           (box) => box.contentBoxId === content.contentBoxId
//         );
  
//         if (contentBoxIndex !== -1) {
//           // Make a copy of the existing content box using the spread operator
//           const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
//           // Update the content box with the new data
//           updatedContentBox.startDate = datesFromSetActiveDatesModal.startDate;
//           updatedContentBox.endDate = datesFromSetActiveDatesModal.endDate;
  
//           // Update the content box in the document data
//           userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
//           // Update the document in Firestore
//           await setDoc(docRef, userCopy); // Replace with the correct path to your document
  
//         }
//         toggleSetActiveDatesModal();
//       }
//     } catch (error) {
//       console.error('Error updating document:', error);
//     }
// };

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

//adf

// const saveBackgroundContentBox = async (imageFileName) => {
//   const thing = `url(${imageFileName})`
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     if (multiBox) {
//       updateContentBoxWithinMultiBox({...content, background: thing }, true)
//     } else {
//       const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//         (box) => box.contentBoxId === content.contentBoxId
//       );
    
//       userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;
    
//       // Update the document in Firestore
//       await setDoc(docRef, userCopy);
//     }

//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
// }

const handleSaveBackground = async (imageFileName) => {
  if (multiBox) {
    const backgroundUrl = `url(${imageFileName})`;
    updateContentBoxWithinMultiBox({...content, background: backgroundUrl }, true)
  } else {
    await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  }
};

const notificationAlert = useRef();

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert();

  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // Format the heading based on length
    const headingToUpdate =
      updatedHeading.length > 9
        ? updatedHeading.substring(0, 9) + "..."
        : updatedHeading.length === 0
        ? "Type here..."
        : updatedHeading;

    // Update the Firestore document
    await updateDoc(contentBoxRef, {
      heading: headingToUpdate,
      userHeading: true,
    });

    successAlert();
  } catch (error) {
    console.error("Error updating content box heading:", error);
  }
};

// const sendBoxToStorage = async () => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//       (box) => box.contentBoxId === content.contentBoxId
//     );

//     userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

//     // Update the document in Firestore
//     await setDoc(docRef, userCopy);

//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
// }

const warningWithConfirmAndCancelMessage = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

// const deleteContentBox = async (contentBoxId) => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//       (box) => box.contentBoxId === contentBoxId
//     );

//     // Remove the content box at the specified index
//     userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

//     // Update the document in Firestore
//     await setDoc(docRef, userCopy);

//     // // Update the local state with the updated document data
//     // setStateContent(userCopy);

//     successDelete()

//     console.log('Content box deleted successfully!');
//   } catch (error) {
//     console.error('Error deleting content box:', error);
//   }
// }
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const [slideDuration, setSlideDuration] = useState(""); 
  const [slideLoop, setSlideLoop] = useState(false);
  const [slideLink, setSlideLink] = useState("");
  const [slideAutoplay, setSlideAutoplay] = useState(false);

  const handleSlideDurationChange = (event) => {
    const selectedValue = event.target.value;
    setSlideDuration(selectedValue);
  };

  const handleSlideLoopChange = (event) => {
    const selectedValue = event.target.value;
    setSlideLoop(selectedValue);
  };

  const handleSlideLinkChange = (value) => {
    let modifiedValue;
    if (value.includes("<iframe")) {
      const srcMatch = value.match(/src="([^"]*)"/);
      console.log(srcMatch)
      const cleanUrlString = srcMatch[1].replace(/amp;/g, '');
      modifiedValue = cleanUrlString;
    } else {
      modifiedValue = value + "&action=embedview&wdbipreview=true";
    }
    
    setIsGoogleSlideLinkValid(true);
    setSlideLink(modifiedValue);
}

  const handleSlideAutoplayChange = (value) => {
    setSlideAutoplay(value)
  }

  const options = [];
  for (let i = 1; i <= 60; i++) {
    options.push(<option key={i} value={i}>{`${i} Second${i !== 1 ? 's' : ''}`}</option>);
  }

  const [showSlideshowAlert, setShowSlideshowAlert] = useState(false);

  const toggleShowSlideshowAlert = () => {
    setShowSlideshowAlert(prev => !prev)
    setSlideDuration("");
    setSlideLoop(false);
    setSlideLink("");
    setSlideAutoplay(false)
  }

  const [isGoogleSlideLinkValid, setIsGoogleSlideLinkValid] = useState(true); // Set initial validation state


const addSlideshowConfirmAlert = (e) => {
  if (true) { //This was url regex valdiation elsewhere so set to true here.
    if (!multiBox) {
      updateContentBox(
        user.uid,
        classId,
        content.contentBoxId, 
        {...content, content: slideLink},
        null
      )
    } else {
      updateContentBoxWithinMultiBox({
        ...content,
        content: slideLink,
      })
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="PowerPoint Added!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
    toggleShowSlideshowAlert()
  } else {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Invalid PowerPoint Link!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        <p><b>You entered:</b> {slideLink}</p>
        <br></br>
        <p>A valid URL will start with 'https://docs.google.com/presentation' and end with 'usp=sharing'.</p>
        <br></br>
        <a href="https://support.microsoft.com/en-us/office/embed-a-presentation-in-a-web-page-or-blog-19668a1d-2299-4af3-91e1-ae57af723a60" target="_blank"><b>CLICK HERE </b></a>for more info.
      </ReactBSAlert>
    );
  }
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

// const copyBoxToClasses = async () => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     // Find the class index based on classId
//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     let boxToCopy = {};

//     if (multiBox) {
//       boxToCopy = {...content, heading: 'PowerPoint Box'}
//     } else {
//       boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
//     }
    
//     boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

//     let classesCopy = [...userCopy.classes];

//     classesSelectedForContentBoxCopy.forEach(classId => {
//       const classIndex = classesCopy.findIndex(c => c.classId === classId);

//       if (classIndex !== -1) {
//         // Copy the contentBoxes array and add the new box
//         const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
//         classesCopy[classIndex] = {
//           ...classesCopy[classIndex],
//           contentBoxes: updatedContentBoxes
//         };
//       } else {
//         console.log(`Class with ID ${classId} not found.`);
//       }
//     });

//     userCopy.classes = classesCopy;

//     await setDoc(docRef, userCopy);

//     toggleCopyBoxModal()

//   } catch (error) {
//     console.log("failed", error);
//   }
// };

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};


  return (
    <>

      {showSlideshowAlert && (
          <ReactBSAlert
          showCancel
          title="Add PowerPoint Box"
          style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
          onConfirm={(e) => addSlideshowConfirmAlert(e)}
          onCancel={() => toggleShowSlideshowAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize=""
          // validationRegex={/(docs\.google\.com\/presentation).*sharing/}
          // validationMsg={"Invalid URL. A valid URL will start with 'https://docs.google.com/presentation' and end with 'usp=sharing'. Vist 'https://support.google.com/drive/answer/2494822?hl=en' for more info"}
          // dependencies={[slideAutoplay]}
        >
        <img src={powerpoint} alt="powerpoint-gif" style={{ width: '400px', height: 'auto', marginBottom: '20px', border: '2px solid black' }}/>
        <div>
          <hr />
          <div>
          <p>
            This PowerPoint Box is in beta.
          </p>
          <p>
          Input the share embed code to your PowerPoint Slideshow. You can find more info <a href="https://support.microsoft.com/en-us/office/embed-a-presentation-in-a-web-page-or-blog-19668a1d-2299-4af3-91e1-ae57af723a60#:~:text=Open%20your%20presentation%20in%20PowerPoint,Share%2C%20and%20then%20click%20Embed.&text=In%20the%20Embed%20box%2C%20under,the%20blog%20or%20web%20page." target="_blank">HERE</a>.
          </p>
          <p>
            For best results, choose "Anyone with the link" for the access level if available.
          </p>
          <Input
            type="textarea"
            onChange={(e) => handleSlideLinkChange(e.target.value)}
            placeholder={"Your PowerPoint embed code here..."}
            required={true}
            // valid={isGoogleSlideLinkValid}
            // invalid={!isGoogleSlideLinkValid}
          />
          <FormFeedback type="invalid">
            {/* Validation error message */}
            {isGoogleSlideLinkValid ? null : 'Invalid input!'}
          </FormFeedback>
          </div>
          {/* <br></br>
          <div>
          Would you like the slideshow to autoplay?
          <Input
            type="select"
            onChange={(e) => handleSlideAutoplayChange(e.target.value)}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Input>
          </div>
          {slideAutoplay && (
            <>
              <br></br>
              <div>
                Slide Duration
                <Input
                  disabled={slideAutoplay ? false : true}
                  type="select"
                  onChange={handleSlideDurationChange}
                  value={slideDuration}
                  defaultValue={10}
                >
                  {options}
                </Input>
              </div>
              <br></br>
              <div>
                Loop Slideshow
                <Input
                  disabled={slideAutoplay ? false : true}
                  type="select"
                  onChange={handleSlideLoopChange}
                  value={slideLoop}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Input>
              </div>
            </>
          )} */}
          <hr />
        </div>
        </ReactBSAlert>
        )}

        <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
          <ModalBody>
            <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
              Copy box to:
            </h3>

            {user && classes && (
              <Row>
                  <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
                    {classes.map((x, index) => (
                      <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                        <Label check>
                          <Input
                            type="checkbox"
                            defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                            onChange={() => handleCheckboxChange(x.classId)}
                          />
                          <span className="form-check-sign" />
                          {x.className}
                        </Label>
                      </FormGroup>
                    ))}
                  </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
            <Button 
              onClick={toggleCopyBoxModal}
              className="delete-button"
              style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
            >
              Cancel
            </Button>
            <Button 
              className="button-hover-fix"
              disabled={classesSelectedForContentBoxCopy.length < 1} 
              onClick={() => copyBoxToClasses()} 
              style={{borderRadius: '30px'}}>
              <i className="fa fa-copy" />{' '}Copy
            </Button>
          </ModalFooter>
        </Modal>


      <Col 
        style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px'}}
        onMouseEnter={() => {
          setShowMainEditButton(true);
          toggleFullScreenButton();
        }}
        onMouseLeave={() => {
          setShowMainEditButton(false);
          setShowButtons(false);
          toggleFullScreenButton();
        }}
      >
        {alert}
        <Card 
          key={content.contentBoxId} 
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
        {showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
        {showButtons && location.pathname !== '/add-edit-classes' && (
            <>
              {!multiBox && 
                <>
                  <Button
                    // className="btn-round btn-icon"
                    id="editContentBoxHeading"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => editBoxNameAlert(content.contentBoxId)}
                  >
                    <i className="fa fa-pencil" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="editContentBoxHeading"
                    placement="left"
                  >
                      Edit Heading
                  </UncontrolledTooltip>
                </>
              }
              <Button
                // className="btn-round btn-icon"
                id="changePowerPointLink"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(60%)' : 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 9px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowSlideshowAlert(!showSlideshowAlert)}
                >
                <i className="fa fa-link" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changePowerPointLink"
                  placement="left"
                >
                    Change PowerPoint Link
                </UncontrolledTooltip>
              <Button
               // className="btn-round btn-icon"
               id="changeContentBoxBackground"
               type="button"
               style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
               size="sm"
               onClick={() => toggleBackgroundContentBoxModal()}
              >
                <i className="nc-icon nc-palette"></i>             
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changeContentBoxBackground"
                  placement="left"
                >
                    Change Background
                </UncontrolledTooltip>
                {user.settings.useActiveDates && !multiBox ? (
                  <>
                    <Button
                      // className="btn-round btn-icon"
                      id="setContentBoxActiveDates"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => toggleSetActiveDatesModal()}
                    >
                      <i className="fa fa-calendar" />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="setContentBoxActiveDates"
                      placement="left"
                    >
                        Set Active Dates
                    </UncontrolledTooltip>
                  </>
                ) : !multiBox &&
                (
                  <>
                    <Button
                      // className="btn-round btn-icon"
                      id="sendBoxToStorage"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                    >
                      <i className="nc-icon nc-box" />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="sendBoxToStorage"
                      placement="left"
                    >
                        Send Box to Storage
                    </UncontrolledTooltip>
                  </>
                )}
              <Button
                // className="btn-round btn-icon"
                id="copyBox"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(300%)' : 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                size="sm"
                onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
              >
                <i className="fa fa-copy" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="copyBox"
                  placement="left"
                >
                    Duplicate Box
                </UncontrolledTooltip>
                { !multiBox &&
                  <>
                    <Button
                      className="button-delete"
                      id="deleteContentBox"
                      type="button"
                      style={{ color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                    <UncontrolledTooltip
                      delay={0}
                      target="deleteContentBox"
                      placement="left"
                    >
                        Delete
                    </UncontrolledTooltip>
                  </>
                }
              </>
            )}
          </>
        )}
          <CardBody id={`powerpoint-${content.content}`}>
          {content.content !== null ?
            <div style={{height: '100%'}}>
              {console.log(content.content)}
              <iframe src={content.content} width="100%" height="100%" frameborder="0">This is an embedded <a target="_blank" href="https://office.com">Microsoft Office</a> presentation, powered by <a target="_blank" href="https://office.com/webapps">Office</a>.</iframe>

              </div>
              :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  className="button-hover-fix"
                  style={{borderRadius: '30px'}}
                  onClick={() => setShowSlideshowAlert(!showSlideshowAlert)}
                >
                  <i className='fa fa-link' />
                            {' '}Add PowerPoint Link
                </Button>
              </div>
            }
          </CardBody>
        </Card>
        
        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>

          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
      </Col>
    </>
  );
}

export default PowerpointBox;

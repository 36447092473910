import React, { useState } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button
} from 'reactstrap';

function LineHeightPicker({id, onChangeFunction, selectedLineHeight}) {

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const lineHeights = ['.1', '.5', '1', '1.5', '2', '2.5', '3'];
      
    const toggle = () => setDropdownOpen((prevState) => !prevState); 

    const handleChange = (event) => {
        onChangeFunction(event);
    };

    return (
        <Dropdown className="line-height-picker" isOpen={dropdownOpen} toggle={toggle} direction='down'>
            <DropdownToggle caret ><i className="fa fa-text-height"></i></DropdownToggle>
            <DropdownMenu className="line-height-picker-menu">
            {lineHeights.map((lineHeight) => (
                <DropdownItem key={lineHeight} value={lineHeight} onClick={handleChange}>{lineHeight}</DropdownItem>
            ))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default LineHeightPicker

import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase.js";
import { setDoc, doc, getDoc, collection, addDoc, updateDoc } from "firebase/firestore";
import GoogleSlidesWrapper from "../GoogleSlidesWrapper"; // Import the wrapper component
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import googleSlides from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/googleslides.gif"
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, FormFeedback, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import { createRandomId } from "RandomId";
import ActiveDatesModal from "../ActiveDatesModal";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates } from "../../utils/ContentBoxUtils";
import { useLinkedBoxManager, LinkedBoxModal } from "/Users/michaeltucker/Documents/coding-projects/client/src/components/ContentBoxes/LinkedBoxManager.js";

function GoogleSlidesBox({content, navbarMD, view, user, classes, classId, multiBox, updateContentBox, updateContentBoxWithinMultiBox, printActive, navigationVisible}) {
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [slideNumber, setSlideNumber] = useState(
    parseInt(localStorage.getItem(`${classId}-${content.content}-slideNumber`), 10) || 1
  );
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const hideAlert = () => setAlert(null);
  const location = useLocation();
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const today = new Date().toISOString().slice(0,10);
  const {
    linkedBoxModalOpen,
    setBoxToLinkId,
    toggleLinkedBoxModal,
    classesSelectedForContentBoxLink,
    handleCheckboxChangeForLinkedBoxes,
    addLinkedBoxToClasses
  } = useLinkedBoxManager({ user, classId, classes, setAlert, hideAlert });

  // Update localStorage whenever slideNumber changes
  useEffect(() => {
    localStorage.setItem(`${classId}-${content.content}-slideNumber`, slideNumber);
  }, [slideNumber]);

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-25}px`});
    };

    const handleFullscreenChange = () => {
      const isFullscreen = document.fullscreenElement !== null;
      if (!isFullscreen) {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188;
        const cardHeight = windowHeight - desiredDistance;
        setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
        // toggleFullScreenButton();
        setIsFullScreen(false);
      } else {
        // setIsFullScreen(true)
      }
    };
  
  window.addEventListener("resize", updateCardHeight);
  // window.addEventListener("resize", console.log("resize"));
  document.addEventListener("fullscreenchange", handleFullscreenChange);
  // document.addEventListener("fullscreenchange", console.log("fullscreenchange"));

  updateCardHeight();

  return () => {
    window.removeEventListener("resize", updateCardHeight);
    document.removeEventListener("fullscreenchange", handleFullscreenChange);
  };
}, [navbarMD, view, navigationVisible]);

// to stop the warning of calling setState of unmounted component
React.useEffect(() => {
  return function cleanup() {
    var id = window.setTimeout(null, 0);
    while (id--) {
      window.clearTimeout(id);
    }
  };
}, []);

const [isFullScreen, setIsFullScreen] = useState(false)

useEffect(() => {
  // This code will run after the component has mounted
  const element = document.getElementsByClassName('punch-viewer-container');
  if (element) {
    // Do something with the element
    console.log('Found element:', element);
  } else {
    console.log('Element not found');
  }
}, []); // Empty dependency array ensures this effect runs only once after initial render

const toggleFullScreen = () => {
  if (!isFullScreen) {
    document.getElementById(`slides-${content.content}`).requestFullscreen().catch(err => {
      console.log(`Error attempting to enable fullscreen: ${err.message}`);
    });
    toggleFullScreenButton();
    setIsFullScreen(true);
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen().then(() => {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188;
        const cardHeight = windowHeight - desiredDistance;
        setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
        toggleFullScreenButton();
        setIsFullScreen(false);
      }).catch(err => {
        console.log(`Error attempting to exit fullscreen: ${err.message}`);
      });
    }
  }
};

const [showFullScreenButton, setShowFullScreenButton] = useState(false)

const toggleFullScreenButton = () => {
  setShowFullScreenButton(!showFullScreenButton)
}

const [activeDatesModal, setActiveDatesModal] = useState(false)

const toggleSetActiveDatesModal = () => {
  setActiveDatesModal(!activeDatesModal)
}

const [activeDates, setActiveDates] = useState({
  startDate: content.startDate === undefined ? "" : content.startDate,
  endDate: content.endDate === undefined ? "" : content.endDate
});

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const handleSaveBackground = async (imageFileName) => {
  if (multiBox) {
    const backgroundUrl = `url(${imageFileName})`;
    updateContentBoxWithinMultiBox({...content, background: backgroundUrl }, true)
  } else {
    await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  }
};

const notificationAlert = useRef();

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert();

  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // Format the heading based on length
    const headingToUpdate =
      updatedHeading.length > 9
        ? updatedHeading.substring(0, 9) + "..."
        : updatedHeading.length === 0
        ? "Type here..."
        : updatedHeading;

    // Update the Firestore document
    await updateDoc(contentBoxRef, {
      heading: headingToUpdate,
      userHeading: true,
    });

    successAlert();
  } catch (error) {
    console.error("Error updating content box heading:", error);
  }
};

const warningWithConfirmAndCancelMessage = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};


  const [slideDuration, setSlideDuration] = useState(content.slideDuration || ""); 
  const [slideLoop, setSlideLoop] = useState(content.slideLoop || false);
  const [slideLink, setSlideLink] = useState(content.slideLink || "");
  const [slideAutoplay, setSlideAutoplay] = useState(content.autoplay || false);

  const handleSlideDurationChange = (event) => {
    const selectedValue = event.target.value;
    setSlideDuration(selectedValue);
  };

  const handleSlideLoopChange = (event) => {
    const selectedValue = event.target.value;
    setSlideLoop(selectedValue);
  };

  const handleSlideLinkChange = (value) => {
    const isValidInput = /(docs\.google\.com\/presentation).*sharing/.test(value);
    setIsGoogleSlideLinkValid(isValidInput);
    setSlideLink(value);
  }

  const handleSlideAutoplayChange = (value) => {
    setSlideAutoplay(value)
  }

  const options = [];
  for (let i = 1; i <= 60; i++) {
    options.push(<option key={i} value={i}>{`${i} Second${i !== 1 ? 's' : ''}`}</option>);
  }

  const [showSlideshowAlert, setShowSlideshowAlert] = useState(false);

  const toggleShowSlideshowAlert = () => {
    setShowSlideshowAlert(prev => !prev)
    setSlideDuration("");
    setSlideLoop(false);
    setSlideLink("");
    setSlideAutoplay(false)
  }

  const [isGoogleSlideLinkValid, setIsGoogleSlideLinkValid] = useState(true); // Set initial validation state


const addSlideshowConfirmAlert = (e) => {
  if (/(docs\.google\.com\/presentation).*sharing/.test(slideLink)) {
    if (!multiBox) {
      updateContentBox(
        user.uid,
        classId,
        content.contentBoxId, 
        {...content, 
          content: slideLink,
          autoplay: slideAutoplay,
          slideDuration: slideDuration,
          loop: slideLoop
        },
        null
      )
    } else {
      updateContentBoxWithinMultiBox({
        ...content,
        content: slideLink,
        autoplay: slideAutoplay,
        slideDuration: slideDuration,
        loop: slideLoop
      })
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Google Slides Added!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
    toggleShowSlideshowAlert()
  } else {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Invalid Google Slides Link!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        <p><b>You entered:</b> {slideLink}</p>
        <br></br>
        <p>A valid URL will start with 'https://docs.google.com/presentation' and end with 'usp=sharing'.</p>
        <br></br>
        <a href="https://support.google.com/drive/answer/2494822?hl=en" target="_blank"><b>CLICK HERE </b></a>for more info.
      </ReactBSAlert>
    );
  }
};

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};

  return (
    <>
      {showSlideshowAlert && (
          <ReactBSAlert
          showCancel
          title="Add Google Slides Box"
          style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
          onConfirm={(e) => addSlideshowConfirmAlert(e)}
          onCancel={() => toggleShowSlideshowAlert()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          btnSize=""
          validationRegex={/(docs\.google\.com\/presentation).*sharing/}
          validationMsg={"Invalid URL. A valid URL will start with 'https://docs.google.com/presentation' and end with 'usp=sharing'. Vist 'https://support.google.com/drive/answer/2494822?hl=en' for more info"}
          dependencies={[slideAutoplay]}
        >
        <img src={googleSlides} alt="google-slides-gif" style={{ width: '400px', height: 'auto', marginBottom: '20px', border: '2px solid black' }}/>
        <div>
          <hr />
          <br></br>
          <div>
          Input the link to your Google Slideshow
          <Input
            type="text"
            onChange={(e) => handleSlideLinkChange(e.target.value)}
            defaultValue={content.content || null}
            placeholder={"Your Google Slides Share link here..."}
            required={true}
            valid={isGoogleSlideLinkValid}
            invalid={!isGoogleSlideLinkValid}
          />
          <FormFeedback type="invalid">
            {/* Validation error message */}
            {isGoogleSlideLinkValid ? null : 'Invalid input!'}
          </FormFeedback>
          </div>
          <br></br>
          <div>
          Would you like the slideshow to autoplay?
          <Input
            type="select"
            onChange={(e) => handleSlideAutoplayChange(e.target.value)}
            defaultValue={slideAutoplay}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Input>
          </div>
          {slideAutoplay && (
            <>
              <br></br>
              <div>
                Slide Duration
                <Input
                  disabled={slideAutoplay ? false : true}
                  type="select"
                  onChange={handleSlideDurationChange}
                  value={slideDuration}
                  defaultValue={slideDuration}
                >
                  {options}
                </Input>
              </div>
              <br></br>
              <div>
                Loop Slideshow
                <Input
                  disabled={slideAutoplay ? false : true}
                  type="select"
                  onChange={handleSlideLoopChange}
                  value={slideLoop}
                  defaultValue={slideLoop}
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Input>
              </div>
            </>
          )}
          <hr />
        </div>
        </ReactBSAlert>
        )}

        <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
          <ModalBody>
            <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
              Copy box to:
            </h3>

            {user && classes && (
              <Row>
                  <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
                    {classes.map((x, index) => (
                      <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                        <Label check>
                          <Input
                            type="checkbox"
                            defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                            onChange={() => handleCheckboxChange(x.classId)}
                          />
                          <span className="form-check-sign" />
                          {x.className}
                        </Label>
                      </FormGroup>
                    ))}
                  </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
            <Button 
              onClick={toggleCopyBoxModal}
              className="delete-button"
              style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
            >
              Cancel
            </Button>
            <Button 
              className="button-hover-fix"
              disabled={classesSelectedForContentBoxCopy.length < 1} 
              onClick={() => copyBoxToClasses()} 
              style={{borderRadius: '30px'}}>
              <i className="fa fa-copy" />{' '}Copy
            </Button>
          </ModalFooter>
        </Modal>

        <LinkedBoxModal 
          linkedBoxModalOpen={linkedBoxModalOpen}
          toggleLinkedBoxModal={toggleLinkedBoxModal}
          classes={classes}
          classesSelectedForContentBoxLink={classesSelectedForContentBoxLink}
          handleCheckboxChangeForLinkedBoxes={handleCheckboxChangeForLinkedBoxes}
          classId={classId}
          addLinkedBoxToClasses={addLinkedBoxToClasses}
        />

      <Col 
        style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px'}}
        onMouseEnter={() => {
          setShowMainEditButton(true);
          toggleFullScreenButton();
          }
        }
        onMouseLeave={() => {
          setShowMainEditButton(false);
          setShowButtons(false);
          toggleFullScreenButton();
          }
        }
      >
        {alert}
        <Card 
          key={content.contentBoxId} 
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
        {showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
        {showButtons && location.pathname !== '/add-edit-classes' && (
            <>
              {!multiBox &&
                <>
                  <Button
                    // className="btn-round btn-icon"
                    id="editContentBoxHeading"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => editBoxNameAlert(content.contentBoxId)}
                  >
                    <i className="fa fa-pencil" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="editContentBoxHeading"
                    placement="left"
                  >
                      Edit Heading
                  </UncontrolledTooltip>
                </>
              }
              <Button
                // className="btn-round btn-icon"
                id="changeSlidesLink"
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(60%)' : 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 9px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowSlideshowAlert(!showSlideshowAlert)}
                >
                <i className="fa fa-link" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changeSlidesLink"
                  placement="left"
                >
                    Change Google Slides Link
                </UncontrolledTooltip>
              <Button
               // className="btn-round btn-icon"
               id="changeContentBoxBackground"
               type="button"
               style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
               size="sm"
               onClick={() => toggleBackgroundContentBoxModal()}
              >
                <i className="nc-icon nc-palette"></i>             
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="changeContentBoxBackground"
                  placement="left"
                >
                    Change Background
                </UncontrolledTooltip>
                {user.settings.useActiveDates && !multiBox ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) : !multiBox &&
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
              <Button
              // className="btn-round btn-icon"
              id="copyBox"
              type="button"
              style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(300%)' : 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
              size="sm"
              onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
              // onClick={null}
              >
                <i className="fa fa-copy" />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="copyBox"
                  placement="left"
                >
                    Duplicate Box
                </UncontrolledTooltip>
                <Button
                  // className="btn-round btn-icon"
                  id="makeLinkedBox"
                  type="button"
                  style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                  size="sm"
                  onClick={() => {setBoxToLinkId(content.contentBoxId); toggleLinkedBoxModal()}}
                >
                  <i className="fa fa-link"></i>
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="makeLinkedBox"
                  placement="left"
                >
                    Make Linked Box
                </UncontrolledTooltip>
              {!multiBox && 
                <>
                  <Button
                    className="button-delete"
                    id="deleteContentBox"
                    type="button"
                    style={{ color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                  >
                    <i className="fa fa-trash" />
                  </Button>
                  <UncontrolledTooltip
                    delay={0}
                    target="deleteContentBox"
                    placement="left"
                  >
                      Delete
                  </UncontrolledTooltip>
                </>
              } 
            </>
            )}
            </>
          )}
          <CardBody id={`slides-${content.content}`}>
          {content.content !== null ?
            <div style={{height: '100%'}}>
              <GoogleSlidesWrapper
                url={content.content}
                width={'100%'}
                height={isFullScreen ? '100%' : '100%'}
                // height={cardHeight.editor}
                containerStyle={{ maxHeight: isFullScreen ? '100%' : null, overflowY: 'auto', border: '0px' }}
                // containerStyle={{ maxHeight: cardHeight.editor, overflowY: 'auto' }}
                autoplay={content.autoplay}
                slideDuration={content.slideDuration}
                loop={content.loop}
                position={slideNumber}
              />
              {showFullScreenButton ? (
                <>
                  <Row style={{ position: 'absolute', bottom: '12px', left: '30px', right: '30px', background: 'none', opacity: '90%', textAlign: 'left', cursor: 'pointer', height:'50px', marginTop: '0px'}}>
                    <Col style={{ display: 'inline-block'}}>
                      <Button className="btn-round btn-icon" size="sm" id="google-slides-button" style={{marginRight: '5px'}} onClick={() => slideNumber > 1 ? setSlideNumber(prev => prev - 1) : null}>
                        <i className="nc-icon nc-minimal-left" />
                      </Button>
                      <Button className="m-0" size="sm" style={{height:'40px'}}>
                        Slide {slideNumber}
                      </Button>
                      <Button className="btn-round btn-icon" size="sm" id="google-slides-button" style={{background: 'rgb(11, 192, 223) !important'}} onClick={() => setSlideNumber(prev => prev + 1)}>
                        <i className="nc-icon nc-minimal-right" />
                      </Button>
                    </Col>
                    <Col style={{ display: 'inline-block', marginTop: '5px'}}>
                      <Button className="float-right m-0" size="sm" onClick={() => toggleFullScreen()}>
                        {!isFullScreen ? 'Full Screen' : 'Exit'}
                      </Button>
                    </Col>
                  </Row>
                </>
                ) : null}
              </div>
              :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  className="button-hover-fix"
                  style={{borderRadius: '30px'}}
                  onClick={() => setShowSlideshowAlert(!showSlideshowAlert)}
                >
                  <i className='fa fa-link' />
                            {' '}Add Google Slides Link
                </Button>
              </div>
            }
          </CardBody>
        </Card>

        <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>

          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
      </Col>
    </>
  );
}

export default GoogleSlidesBox;


import React, { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { doc, updateDoc, getDoc, setDoc, collection, addDoc, getDocs, deleteDoc } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, ButtonGroup, Modal, ModalBody, ModalHeader, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import ContentBox from "components/ContentBoxes/ContentBox";
import GoogleSlidesBox from "components/ContentBoxes/GoogleSlidesBox.js"
import TimerStopwatchContentBox from "components/ContentBoxes/TimerStopwatchContentBox";
import AgendaBox from "components/ContentBoxes/AgendaBox";
import CanvaBox from "components/ContentBoxes/CanvaBox";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import CountdownBox from "components/ContentBoxes/CountdownBox";
import stampIcon from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/stamp-solid.svg";
import GoogleDocSheetBox from "components/ContentBoxes/GoogleDocSheetBox";
import PowerpointBox from "components/ContentBoxes/PowerpointBox";
import PdfBox from "components/ContentBoxes/PdfBox";
import { createRandomId } from "RandomId";
import IframeBox from "components/ContentBoxes/IframeBox";
import ActiveDatesModal from "../ActiveDatesModal";
import { useDispatch } from "react-redux";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates } from "../../utils/ContentBoxUtils";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";

function MultiBox({content, setHeading, updateContentBox, classId, user, classes, navbarMD, view, addContentBox, printActive, navigationVisible}) {
  const [editedContent, setEditedContent] = useState();
  const [cardHeight, setCardHeight] = useState({card: "auto"}); // Initially set the height to 'auto'
  const dispatch = useDispatch()
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState()
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation()
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });
  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight+15 : cardHeight+65}px`, editor: `${cardHeight-25}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

const [activeDatesModal, setActiveDatesModal] = useState(false)

const toggleSetActiveDatesModal = () => {
  setActiveDatesModal(!activeDatesModal)
}

const [activeDates, setActiveDates] = useState({
  startDate: content.startDate === undefined ? "" : content.startDate,
  endDate: content.endDate === undefined ? "" : content.endDate
});

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const [backgroundChangeElement, setBackgroundChangeElement] = useState('TopBox');

const toggleBackgroundContentBoxModal = () => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  saveBackgroundMultiBox()
};

const [topBackground, setTopBackground] = useState(content.content[0]?.background)
const [bottomBackground, setBottomBackground] = useState(content.content[1]?.background)

// const swapBoxes = async () => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     if (classIndex !== -1) {
//       // Find the multiBox index based on contentBoxId
//       const multiBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//         (box) => box.contentBoxId === content.contentBoxId
//       );

//       if (multiBoxIndex !== -1) {
//         // Make a copy of the existing multiBox using the spread operator
//         const updatedMultiBox = { ...userCopy.classes[classIndex].contentBoxes[multiBoxIndex] };

//         // Swap the first two content items
//         const temp = updatedMultiBox.content[0];
//         updatedMultiBox.content[0] = updatedMultiBox.content[1];
//         updatedMultiBox.content[1] = temp;

//         // Update the content box in the document data
//         userCopy.classes[classIndex].contentBoxes[multiBoxIndex] = updatedMultiBox;

//         // Update the document in Firestore
//         await setDoc(docRef, userCopy);
//       }
//     }
//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
// };

const swapBoxes = async () => {
  try {
    if (!user?.uid || !classId || !content?.contentBoxId) {
      console.error("Missing required identifiers: User ID, Class ID, or Content Box ID.");
      return;
    }

    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      content.contentBoxId
    );

    // Fetch the current content box data
    const contentBoxSnap = await getDoc(contentBoxRef);

    if (!contentBoxSnap.exists()) {
      console.error("Content box not found.");
      return;
    }

    const contentBoxData = contentBoxSnap.data();

    // Ensure there are at least two content items to swap
    if (contentBoxData.content?.length < 2) {
      console.error("Not enough content items to swap.");
      return;
    }

    // Swap the first two content items
    const updatedContent = [...contentBoxData.content];
    [updatedContent[0], updatedContent[1]] = [updatedContent[1], updatedContent[0]];

    // Update Firestore with the new content order
    await updateDoc(contentBoxRef, { content: updatedContent });

    console.log("✅ Content boxes swapped successfully!");
  } catch (error) {
    console.error("❌ Error swapping content boxes:", error);
  }
};


const saveBackgroundMultiBox = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the multiBox index based on contentBoxId
      const multiBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === content.contentBoxId
      );

      if (multiBoxIndex !== -1) {
        // Make a copy of the existing mutliBox using the spread operator
        const updatedMultiBox = { ...userCopy.classes[classIndex].contentBoxes[multiBoxIndex]};

        updatedMultiBox.content[0].background = topBackground
        updatedMultiBox.content[1].background = bottomBackground

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[multiBoxIndex] = updatedMultiBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const notificationAlert = useRef();

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
    // <ReactBSAlert
    //   input
    //   showCancel
    //   style={{
    //     display: 'block',
    //     marginTop: '-100px',
    //     padding: '20px',
    //     borderRadius: '12px',
    //     boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
    //     textAlign: 'center',
    //   }}
    //   title={
    //     <h4 style={{ fontWeight: 'bold', color: '#004aad' }}>
    //       What would you like to name this box?
    //     </h4>
    //   }
    //   onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
    //   onCancel={() => hideAlert()}
    //   confirmBtnBsStyle="primary"
    //   cancelBtnBsStyle="secondary"
    //   confirmBtnText="Save"
    //   cancelBtnText="Cancel"
    //   btnSize="lg"
    //   inputStyle={{
    //     borderRadius: '8px',
    //     border: '1px solid #ccc',
    //     padding: '10px',
    //     width: '80%',
    //     margin: '10px auto',
    //     display: 'block',
    //   }}
    //   defaultValue={content.heading}
    // />
  );
};

// const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
//   hideAlert();

//   try {
//     // Reference the specific content box document in Firestore
//     const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

//     // Format the heading based on length
//     const headingToUpdate =
//       updatedHeading.length > 9
//         ? updatedHeading.substring(0, 9) + "..."
//         : updatedHeading.length === 0
//         ? "Type here..."
//         : updatedHeading;

//     // Update the Firestore document
//     await updateDoc(contentBoxRef, {
//       heading: headingToUpdate,
//       userHeading: true,
//     });

//     successAlert();
//   } catch (error) {
//     console.error("Error updating content box heading:", error);
//   }
// };

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert();

  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // Format the heading based on length
    const headingToUpdate =
      updatedHeading.length > 9
        ? updatedHeading.substring(0, 9) + "..."
        : updatedHeading.length === 0
        ? "Type here..."
        : updatedHeading;

    // Update the Firestore document
    await updateDoc(contentBoxRef, {
      heading: headingToUpdate,
      userHeading: true,
    });

    successAlert();
  } catch (error) {
    console.error("Error updating content box heading:", error);
  }
};

// const updateContentBoxWithinMultiBox = async (updatedBox, fromContentBox) => {
//   console.log("updatedBox => ", updatedBox)
//   try {
//     const docRef = doc(db, "users", user.uid);

//     // Create a deep copy of the user object
//     const userCopy = JSON.parse(JSON.stringify(user));

//     // Find the class index based on classId
//     const classIndex = userCopy.classes.findIndex((c) => c.classId === classId);

//     if (classIndex !== -1) {
//       // Find the multiBox index based on contentBoxId
//       const multiBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//         (box) => box.contentBoxId === content.contentBoxId
//       );

//       if (multiBoxIndex !== -1) {
//         // Find the content box within the multiBox
//         const contentBoxIndex = userCopy.classes[classIndex].contentBoxes[multiBoxIndex].content.findIndex(
//           (box) => box.contentBoxId === updatedBox.contentBoxId
//         );

//         if (contentBoxIndex !== -1) {
//           // Update the content box within the multiBox
//           userCopy.classes[classIndex].contentBoxes[multiBoxIndex].content[contentBoxIndex] = updatedBox;

//           // Update the document in Firestore
//           await setDoc(docRef, userCopy);

//           // Trigger success alert if not fromContentBox
//           if (!fromContentBox) {
//             successAlert();
//           }
//         }
//       }
//     }
//   } catch (error) {
//     console.error('Error updating document:', error);
//   }
// };

const updateContentBoxWithinMultiBox = async (updatedBox, fromContentBox) => {
  console.log("HERE aspodifnaopisndpfin")
  try {
    if (!user?.uid || !classId || !content?.contentBoxId || !updatedBox?.contentBoxId) {
      console.error("❌ Missing required identifiers.");
      return;
    }

    console.log("updatedBox => ", updatedBox);

    // Reference the MultiBox document in Firestore
    const multiBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      content.contentBoxId // MultiBox ID
    );

    // Fetch the MultiBox document
    const multiBoxSnap = await getDoc(multiBoxRef);

    if (!multiBoxSnap.exists()) {
      console.error("❌ MultiBox not found.");
      return;
    }

    const multiBoxData = multiBoxSnap.data();

    // Find the index of the content box inside the MultiBox
    const contentBoxIndex = multiBoxData.content.findIndex(
      (box) => box.contentBoxId === updatedBox.contentBoxId
    );

    if (contentBoxIndex === -1) {
      console.error("❌ Content box not found inside MultiBox.");
      return;
    }

    // Update the specific content box within the MultiBox
    const updatedMultiBox = { ...multiBoxData };
    updatedMultiBox.content[contentBoxIndex] = updatedBox;

    // Update Firestore document
    await setDoc(multiBoxRef, updatedMultiBox, { merge: true });

    // Show success alert only if not fromContentBox
    if (!fromContentBox) {
      successAlert();
    }

    console.log("✅ Content box inside MultiBox updated successfully!");
  } catch (error) {
    console.error("❌ Error updating document:", error);
  }
};

const deleteBoxChoiceRef = useRef();

const warningWithConfirmAndCancelMessage = (multiBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      showCancel
      title="Delete MultiBox or Individual Content Box?"
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      onConfirm={() => {
        const choice = deleteBoxChoiceRef.current.value;
        if (choice === "entire") {
          deleteContentBox(user.uid, classId, multiBoxId);
        } else if (choice === "topBox" || choice === "bottomBox") {
          deleteContentBoxWithinMultiBox(choice, multiBoxId);
        } else if (choice === "unstack") {
          unstackMultiBox(multiBoxId);
        }
        hideAlert();
      }}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: '#dc3545'}}
      confirmBtnText="Delete"
      cancelBtnBsStyle="danger"
      btnSize=""
    >
      <div>
        <Input
          id="timerStopwatchSelect"
          name="select"
          type="select"
          onChange={(e) => deleteBoxChoiceRef.current = e.target}
          required={true}
          defaultValue={"none"}
        >
          <option disabled value="none">Pick one...</option>
          <option value="entire">Delete entire MultiBox including both boxes</option>
          <option value="unstack">Delete multibox but keep both boxes</option>
          <option value="topBox">Delete top box only</option>
          <option value="bottomBox">Delete bottom box only</option>
        </Input>
      </div>
    </ReactBSAlert>
  )

}

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

// const deleteMultiBox = async (multiBoxId) => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     const multiBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//       (box) => box.contentBoxId === multiBoxId
//     );

//     // Remove the content box at the specified index
//     userCopy.classes[classIndex].contentBoxes.splice(multiBoxIndex, 1);

//     // Update the document in Firestore
//     await setDoc(docRef, userCopy);

//     // // Update the local state with the updated document data
//     // setStateContent(userCopy);

//     successDelete()

//     console.log('Content box deleted successfully!');
//   } catch (error) {
//     console.error('Error deleting content box:', error);
//   }
// }

// const deleteContentBoxWithinMultiBox = async (boxToDelete, multiBoxId) => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     const multiBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//       (box) => box.contentBoxId === multiBoxId
//     );

//     const updatedMultiBox = { ...userCopy.classes[classIndex].contentBoxes[multiBoxIndex]};

//     const boxToKeep = boxToDelete === "topBox" ? 
//         {...updatedMultiBox.content[1], startDate: date.databaseFormattedDate, endDate: date.databaseFormattedDate}
//       : 
//         {...updatedMultiBox.content[0], startDate: date.databaseFormattedDate, endDate: date.databaseFormattedDate}

//     // Update the content box in the document data
//     userCopy.classes[classIndex].contentBoxes.push(boxToKeep);

//     // Remove the content box at the specified index
//     userCopy.classes[classIndex].contentBoxes.splice(multiBoxIndex, 1);

//     // Update the document in Firestore
//     await setDoc(docRef, userCopy);

//     console.log('Content box deleted successfully!');
//   } catch (error) {
//     console.error('Error in process:', error);
//   }
// }

const deleteContentBoxWithinMultiBox = async (boxToDelete, multiBoxId) => {
  try {
    if (!user?.uid || !classId || !multiBoxId) {
      console.error("❌ Missing required parameters.");
      return;
    }

    // 🔹 Reference the multiBox document
    const multiBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      multiBoxId
    );

    const multiBoxSnap = await getDoc(multiBoxRef);

    if (!multiBoxSnap.exists()) {
      console.error("❌ MultiBox not found.");
      return;
    }

    const multiBoxData = multiBoxSnap.data();

    if (!multiBoxData.content || multiBoxData.content.length !== 2) {
      console.error("❌ MultiBox does not have exactly two content boxes.");
      return;
    }

    // 🔹 Generate a new unique ID for the remaining box
    const newContentBoxId = createRandomId();

    // 🔹 Determine which box to keep and update its properties
    const formattedDate = new Date().toISOString().split("T")[0];

    const boxToKeep = boxToDelete === "topBox" 
      ? { ...multiBoxData.content[1], contentBoxId: newContentBoxId, startDate: formattedDate, endDate: formattedDate }
      : { ...multiBoxData.content[0], contentBoxId: newContentBoxId, startDate: formattedDate, endDate: formattedDate };
    

    // 🔹 Reference the new single content box document (ensuring it has the correct ID)
    const newContentBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      newContentBoxId
    );

    // 🔹 Save the new single content box to Firestore with the correct ID
    await setDoc(newContentBoxRef, boxToKeep);

    // 🔹 Remove the old multiBox ID from the contentBoxOrder list
    const classRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId
    );

    const classSnap = await getDoc(classRef);

    if (!classSnap.exists()) {
      console.error("❌ Class document not found.");
      return;
    }

    const classData = classSnap.data();
    let updatedContentBoxOrder = classData.contentBoxesOrder?.filter(id => id !== multiBoxId) || [];

    // 🔹 Add the new single contentBoxId to the order list
    updatedContentBoxOrder.push(newContentBoxId);

    // 🔹 Update the class document with the new contentBoxesOrder
    await updateDoc(classRef, { contentBoxesOrder: updatedContentBoxOrder });

    // 🔹 Delete the old multiBox document
    await deleteDoc(multiBoxRef);

    console.log("✅ Content box deleted successfully and updated with new single box!");
  } catch (error) {
    console.error("❌ Error in process:", error);
  }
};


// const unstackMultiBox = async (multiBoxId) => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     const multiBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//       (box) => box.contentBoxId === multiBoxId
//     );

//     const updatedMultiBox = { ...userCopy.classes[classIndex].contentBoxes[multiBoxIndex]};

//     const boxToKeep1 = {...updatedMultiBox.content[0], startDate: date.databaseFormattedDate, endDate: date.databaseFormattedDate};
//     const boxToKeep2 = {...updatedMultiBox.content[1], startDate: date.databaseFormattedDate, endDate: date.databaseFormattedDate};

//     // Update the content box in the document data
//     userCopy.classes[classIndex].contentBoxes.push(boxToKeep1, boxToKeep2);

//     // Remove the content box at the specified index
//     userCopy.classes[classIndex].contentBoxes.splice(multiBoxIndex, 1);

//     // Update the document in Firestore
//     await setDoc(docRef, userCopy);

//     console.log('MultiBox unstacked successfully!');
//   } catch (error) {
//     console.error('Error in process:', error);
//   }
// }

const unstackMultiBox = async (multiBoxId) => {
  try {
    if (!user?.uid || !classId || !multiBoxId) {
      console.error("❌ Missing required identifiers: User ID, Class ID, or MultiBox ID.");
      return;
    }

    // Reference the MultiBox document in Firestore
    const multiBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      multiBoxId
    );

    // Fetch the MultiBox document
    const multiBoxSnap = await getDoc(multiBoxRef);

    if (!multiBoxSnap.exists()) {
      console.error("❌ MultiBox not found.");
      return;
    }

    const multiBoxData = multiBoxSnap.data();

    // Ensure there are at least two content items to unstack
    if (!multiBoxData.content || multiBoxData.content.length < 2) {
      console.error("❌ Not enough content items to unstack.");
      return;
    }

    // Generate new unique contentBoxIds
    const newContentBoxId1 = createRandomId();
    const newContentBoxId2 = createRandomId();

    // Create new individual boxes from the MultiBox content
    const boxToKeep1 = {
      ...multiBoxData.content[0],
      contentBoxId: newContentBoxId1,
      startDate: date.databaseFormattedDate,
      endDate: date.databaseFormattedDate,
    };

    const boxToKeep2 = {
      ...multiBoxData.content[1],
      contentBoxId: newContentBoxId2,
      startDate: date.databaseFormattedDate,
      endDate: date.databaseFormattedDate,
    };

    // Reference the class document to update contentBoxOrder
    const classRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId
    );

    const classSnap = await getDoc(classRef);

    if (!classSnap.exists()) {
      console.error("❌ Class document not found.");
      return;
    }

    const classData = classSnap.data();
    let updatedContentBoxOrder = [...(classData.contentBoxesOrder || [])];

    // Find the position of the multiBox in contentBoxOrder
    const multiBoxIndex = updatedContentBoxOrder.indexOf(multiBoxId);

    if (multiBoxIndex !== -1) {
      // Remove the multiBoxId
      updatedContentBoxOrder.splice(multiBoxIndex, 1);

      // Insert the new contentBoxIds at the same position
      updatedContentBoxOrder.splice(multiBoxIndex, 0, newContentBoxId1, newContentBoxId2);
    } else {
      // If multiBoxId is not found, just append the new contentBoxes
      updatedContentBoxOrder.push(newContentBoxId1, newContentBoxId2);
    }

    // Reference the contentBoxes collection to add the new boxes
    const contentBoxesCollectionRef = collection(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES
    );

    // Save the new individual content boxes
    await Promise.all([
      setDoc(doc(contentBoxesCollectionRef, newContentBoxId1), boxToKeep1),
      setDoc(doc(contentBoxesCollectionRef, newContentBoxId2), boxToKeep2),
      updateDoc(classRef, { contentBoxesOrder: updatedContentBoxOrder }), // ✅ Update contentBoxOrder
      deleteDoc(multiBoxRef), // ✅ Remove the original MultiBox
    ]);

    console.log("✅ MultiBox unstacked successfully!");
  } catch (error) {
    console.error("❌ Error in process:", error);
  }
};




const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox, dispatch);
  setAlert(
    <ReactBSAlert
    style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
    onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

  return (
    <>
      <NotificationAlert className="content-box-save-alert" ref={notificationAlert} zIndex={9999} style={{width: '100px'}}/>
      {alert}
      { !isMobile ? (
      <>
            <PanelGroup
              direction="vertical"
              autoSaveId={`panelSaveId-multiBox-${classId}-${content.contentBoxId}`}
              key={`panelGroup-multiBox-${classId}-${content.contentBoxId}`}
              // key={`panelGroupKey-multiBox-${classId}-${createRandomId()}`}
              // style={{overflow: 'visible', height: location.pathname === '/print' ? '485px' : cardHeight.card, border: showButtons ? 'blue solid 2px' : null}}
              style={{overflow: 'visible', border: showButtons ? 'blue solid 2px' : 'transparent solid 2px', borderRadius: '15px', height: cardHeight.card}}
              onMouseEnter={() => setShowMainEditButton(true)}
              onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
              >

            {showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') &&  (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`MultiBoxOptions-${content.contentBoxId}`}
                type="button"
                style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(-45%)', left: '-6px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`MultiBoxOptions-${content.contentBoxId}`}
                  placement="right"
                >
                    MultiBox Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      // className="btn-round btn-icon"
                      id="editContentBoxHeading"
                      type="button"
                      style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(75%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => editBoxNameAlert(content.contentBoxId)}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editContentBoxHeading"
                        placement="right"
                      >
                          Edit Heading
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="swapMultiBoxBoxes"
                      type="button"
                      style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(195%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => swapBoxes()}
                    >
                      <i className="fa fa-refresh" style={{rotate: "90deg"}}></i>                    
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="swapMultiBoxBoxes"
                        placement="right"
                      >
                          Swap Boxes
                      </UncontrolledTooltip>
                    {/* <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(300%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049', overflow: 'visible'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i className="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="right"
                      >
                          Change Background
                      </UncontrolledTooltip> */}
                      {user.settings.useActiveDates ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(315%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="right"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) :
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(315%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="right"
                          >
                              Send MultiBox to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ background: '#004aad', color: 'white', position: 'absolute', transform: 'translateY(435%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="lesson-launchpad-white-logo"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="right"
                      >
                          Save MultiBox as Template
                      </UncontrolledTooltip> 
                    <Button
                    className="button-delete"
                    id="deleteContentBox"
                    type="button"
                    style={{ color: 'white', position: 'absolute', transform: 'translateY(555%)', left: '-9px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049', overflow: 'visible'}}
                    size="sm"
                    onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                    >
                      <i className="fa fa-trash" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="deleteContentBox"
                        placement="right"
                      >
                          Delete
                      </UncontrolledTooltip>
                  </>
                  )}
                </>
              )}
              {content.content &&
                content.content.filter((box) => box.show).map((box, index) => {
                  if (box && box.show) {
                    if (box.slideshow) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                        <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <GoogleSlidesBox
                          key={`googleSlidesBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          view={view}
                          user={user}
                          classes={classes}
                          classId={classId}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.powerPoint) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                        <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <PowerpointBox
                          key={`powerPointBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.pdf) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                        <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <PdfBox
                          key={`pdfBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.googleDocSheet) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                        <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <GoogleDocSheetBox
                          key={`googleDocBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.timer || box.stopwatch) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                        <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <TimerStopwatchContentBox
                          key={`timerStopwatchBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.iFrame) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                      <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <IframeBox
                          key={`iFrameBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.agendaBox) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                      <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <AgendaBox
                          key={`agendaBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.canva) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                        <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <CanvaBox
                          key={`canvaBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          addContentBox={addContentBox}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else if (box.countdown) {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                      <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible'}} key={`panel-multiBox-${classId}-${index}`}>
                        <CountdownBox
                          key={`countdownBox-multiBox-${classId}-${index}`}
                          content={box}
                          navbarMD={navbarMD}
                          classId={classId}
                          view={view}
                          user={user}
                          classes={classes}
                          addContentBox={addContentBox}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    } else {
                      return (
                      <React.Fragment key={`fragment-${classId}-${index}-${box.contentBoxId}`}>
                      <Panel defaultSize={50} minSize={25} maxSize={75} style={{overflow: 'visible', padding: '0px'}} key={`panel-multiBox-${classId}-${index}`}>
                        <ContentBox
                          key={`contentBox-multiBox-${classId}-${index}`}
                          content={box}
                          updateContentBox={updateContentBox}
                          setHeading={setHeading}
                          classId={classId}
                          user={user}
                          classes={classes}
                          navbarMD={navbarMD}
                          view={view}
                          updateContentBoxWithinMultiBox={updateContentBoxWithinMultiBox}
                          multiBox
                          printActive={printActive}
                          navigationVisible={navigationVisible}
                        />
                      </Panel>
                      {
                        index < content.content.length-1 ? (
                          <PanelResizeHandle key={`panelResize-multiBox-${classId}-${index}`} className="PanelResizeHandle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '10px'}}>
                            <i className="fa fa-exchange" style={{margin: '-7px', transform: "rotate(90deg)"}}></i>
                          </PanelResizeHandle>
                        ) : null
                      }
                      </React.Fragment>
                      )
                    }
                  }
                })}
            </PanelGroup>

          {/* </CardBody>
        </Card> */}
          {/* <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates.startDate || today}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates.endDate || today}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveContentBoxActiveDates(content.contentBoxId)}>Save Active Dates</Button>
          </Modal> */}

          {/* <Modal
      isOpen={activeDatesModal}
      toggle={toggleSetActiveDatesModal}
      scrollable
      style={{ padding: '20px' }}
    >
      <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
      <ModalBody>
        <Container>
          <Row xs="2">
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <Input
                id="startDate"
                name="startDate"
                type="date"
                onChange={handleActiveDateChange}
                value={activeDates.startDate}
              />
            </FormGroup>
            <FormGroup>
              <Label for="endDate">End Date</Label>
              <Input
                id="endDate"
                name="endDate"
                type="date"
                onChange={handleActiveDateChange}
                value={activeDates.endDate}
              />
            </FormGroup>
          </Row>
          <Row className="my-3">
            <Button color="primary" onClick={setToday} className="me-2">
              Today
            </Button>
            <Button color="secondary" onClick={setThisWeek}>
              This Week
            </Button>
          </Row>
          <Row>
            <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
          </Row>
        </Container>
      </ModalBody>
      <Button onClick={() => saveContentBoxActiveDates(activeDates)}>Save Active Dates</Button>
    </Modal> */}

    <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>


          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} className="background-modal-header"/>
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row>
                  <ButtonGroup className="w-100">
                    <Col xs={6}>
                      <Button
                        outline
                        block
                        onClick={() => setBackgroundChangeElement('TopBox')}
                        active={backgroundChangeElement === 'TopBox'}
                      >
                        Top Box
                      </Button>
                    </Col>
                    <Col xs={6}>
                      <Button
                        outline
                        block
                        onClick={() => setBackgroundChangeElement('BottomBox')}
                        active={backgroundChangeElement === 'BottomBox'}
                      >
                        Bottom Box
                      </Button>
                    </Col>
                  </ButtonGroup>
                </Row>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col 
                      key={index} 
                      onClick={() => {
                        backgroundChangeElement === 'TopBox' ? setTopBackground(`url(${option.image})`) :
                        backgroundChangeElement === 'BottomBox' ? setBottomBackground(`url(${option.image})`) :
                        null
                      }}
                      style={{ padding: '0px' }}
                    >
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
        {/* </Col> */}
      </>
    ) : (

      <Col style={{zoom: '1' }}>
        <Card 
          key={content.contentBoxId}
          style={{ height: '95vh'}}
        >
          <CardBody>
            <div id="editable" style={{ overflowY: 'auto' }}>
            </div>
          </CardBody>
        </Card>
        </Col>

    )}
    </>
  );
}

export default MultiBox;

import {React, useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import centerMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-middle.png";
import centerTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-top.png";
import centerBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/center-bottom.png";
import leftBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-bottom.png";
import leftMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-middle.png";
import leftTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/left-top.png";
import rightBottom from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-bottom.png";
import rightMiddle from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-middle.png";
import rightTop from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/right-top.png";
import isScheduleNormal from "ScheduleChecker";
import Scheduling from "utils/Scheduling";
import ScheduleManager from "components/ScheduleManager";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip,
  ModalHeader,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label
} from "reactstrap";

function AddEditReminderForm() {

  const location = useLocation();
  const { state } = location;
  const item = state?.reminder;
  const user = useSelector(selectUser);
  const dispatch = useDispatch()
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
  const [currentWeek, setCurrentWeek] = useState("A") //this is not the settings currentWeek - "A" should remain the default
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  // const [selectedOption, setSelectedOption] = useState("center-top");

  const handleSelect = (value) => {
    setData((prevData) => ({
      ...prevData,
      modalClassName: value,
    }));
  };

  const toggleLocationDropdown = () => {
    setLocationDropdownOpen(!locationDropdownOpen);
  }

  const modalOptions = [
    { value: "left-top", label: "Left-Top", image: leftTop },
    { value: "center-top", label: "Center-Top", image: centerTop },
    { value: "right-top-auto-music", label: "Right-Top", image: rightTop },
    { value: "left-middle", label: "Left-Middle", image: leftMiddle },
    { value: "center-middle", label: "Center-Middle", image: centerMiddle },
    { value: "right-middle-auto-music", label: "Right-Middle", image: rightMiddle },
    { value: "left-bottom", label: "Left-Bottom", image: leftBottom },
    { value: "center-bottom", label: "Center-Bottom", image: centerBottom },
    { value: "right-bottom-auto-music", label: "Right-Bottom", image: rightBottom },
  ];

  useEffect(() => {
    Scheduling.addWeeksToSchedule(setData, user, item?.classId);
  }, []);

  const [data, setData] = useState({
    startTime: item?.startTime || "00:00",
    duration: item?.duration || 0,
    active: item?.active === false ? false : item?.active === true ? true : true,
    reminderMessage: item?.reminderMessage || '',
    days: [],
    schedule: item?.schedule || Scheduling.blankSchedule,
    modalClassName: item?.modalClassName || '',
  });

  const [alert, setAlert] = useState(null);
  // to stop the warning of calling setState of unmounted component
  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const docRef = doc(db, "users", user.uid);

  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const newReminder = {
  //       startTime: data.startTime,
  //       duration: data.duration,
  //       active: data.active,
  //       reminderMessage: data.reminderMessage,
  //       days: data.days,
  //       schedule: data.schedule,
  //       modalClassName: data.modalClassName,
  //     }

  //     if (!userCopy.reminders) {
  //       userCopy.reminders = []; // Initialize it as an empty array if it doesn't exist
  //     }

  //     // Update the content box in the document data
  //     if (item === undefined) {
  //       userCopy.reminders.push(newReminder);
  //     } else {
  //       userCopy.reminders[state.index] = data 
  //     }

  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //     successAlert();
  //   } catch (error) {
  //     window.alert('Error updating Reminder:', error);
  //     console.log('Error updating Reminder:', error);
  //   }
  // }

const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        if (!user?.uid) {
            console.error("User ID is missing.");
            return;
        }

        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("User document not found.");
            return;
        }

        // Fetch existing user data
        const userData = userSnap.data();

        // Ensure `reminders` exists
        if (!userData.reminders) {
            userData.reminders = [];
        }

        // Construct new reminder object
        const newReminder = {
            startTime: data.startTime,
            duration: data.duration,
            active: data.active,
            reminderMessage: data.reminderMessage,
            days: data.days,
            schedule: data.schedule,
            modalClassName: data.modalClassName,
        };

        if (item === undefined) {
            // Adding a new reminder
            userData.reminders.push(newReminder);
        } else {
            // Updating an existing reminder at `state.index`
            userData.reminders[state.index] = data;
        }

        // Update only the `reminders` field in Firestore
        await updateDoc(userRef, {
            reminders: userData.reminders
        });

        // 🛑 Manually re-fetch user data after updating Firestore
        await dispatch(fetchUserData(user.uid));

        console.log("✅ Successfully saved reminder:", newReminder);
        successAlert();
    } catch (error) {
        console.error("❌ Error updating Reminder:", error);
        window.alert("❌ Error updating Reminder: " + error.message);
    }
};


  const potentialInvalidScheduleAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => (data.duration < 10) ? durationSetToZeroAlert(event) : handleSubmit(event)}
        onCancel={() => hidePotentialInvalidScheduleAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        It appears as though your scheduled start time(s) for this reminder aren't typical.
      </ReactBSAlert>
    );
  }

  const durationSetToZeroAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => handleSubmit(event)}
        onCancel={() => hideDurationSetToZeroAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        {`Your duration is set to ${data.duration}, which means your reminder will only appear on the screen for ${data.duration} seconds.`}
      </ReactBSAlert>
    );
  }

  const hidePotentialInvalidScheduleAlert = () => {
    setAlert(null);
  }

  const hideDurationSetToZeroAlert = (e) => {
    setAlert(null);
  }

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Reminder Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes#reminders")
  };

  function handleFormNonDays(e) {
    const { id, value, type, checked } = e.target;

    // Check if the input element is a checkbox
    if (type === 'checkbox') {
      // Update the state based on the checkbox's checked property
      setData((prevData) => ({
        ...prevData,
        [id]: checked, // Update the corresponding property in the state
      }));
    } else {
      // For non-checkbox inputs, update the state based on the input's value
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  }

  function handleFormDays(e) {
    const { value, checked } = e.target;

    // Clone the existing days map from state
    const updatedDays = [ ...data.days ];

    if (data.days.length === 0) {
      updatedDays.push(
        {
          M: false,
          T: false,
          W: false,
          Th: false,
          F: false,
        }
      )
    }

    // Update the value in the map based on checkbox state
    updatedDays[0][value] = checked;

    // Update the state with the new days map
    setData((prevData) => ({
      ...prevData,
      days: updatedDays,
    }));
  }

  function handleSecondWeekFormDays(e) {
    const { value, checked } = e.target;

    // Clone the existing days map from state
    const updatedDays = [ ...data.days ];

    if (data.days.length === 1) {
      updatedDays.push(
        {
          M: false,
          T: false,
          W: false,
          Th: false,
          F: false,
        }
      )
    }

    // Update the value in the map based on checkbox state
    updatedDays[1][value] = checked;

    // Update the state with the new days map
    setData((prevData) => ({
      ...prevData,
      days: updatedDays,
    }));
  }

  const handleScheduleChange = (week, day, field, value) => {

    // Create a copy of the schedule to avoid mutating the state directly
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    
    // Update the specified field in the schedule
    updatedSchedule[week][day][field] = value;
  
    // Set the updated schedule in your state or wherever you store it
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  function handleSingleStartTime(e) {
    const startTime = e.target.value;
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
  
    for (const group in updatedSchedule) {
      for (const day in updatedSchedule[group]) {
        updatedSchedule[group][day] = { ...updatedSchedule[group][day], startTime };
      }
    }
  
    setData({ ...data, schedule: updatedSchedule, startTime: startTime });
  }

  return (
    <>
      <div className="content">
        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Col md="6">
            <Card style={{ padding: '20px' }}>
              <ModalHeader className="add-edit-form-close-toggle" toggle={() =>  history.goBack()} style={{border: 'none', padding: '0px'}}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
                  {item === undefined ? "Add Reminder" : "Edit Reminder"}
                </h4>
              </ModalHeader>
              <CardBody>
                <Form
                  className="form-horizontal"
                  noValidate // Remove 'noValidate' if you want to use HTML5 validation as well
                  validated={validated}
                >
                  <Row style={{ marginTop: "0px"}}>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold" }}>Reminder Message</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: It's time for the volleyball players to leave."
                          type="text"
                          id="reminderMessage"
                          value={data.reminderMessage}
                          style={{borderRadius: '8px'}}
                          valid={data.reminderMessage.length <= 100} // Display valid feedback
                          invalid={data.reminderMessage.length > 100} // Display invalid feedback
                          >
                        </Input>
                        {data.reminderMessage.length > 100 ? (
                          <FormFeedback invalid>Alert Message is too long (max 100 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row>
                    <Col md={!differentTimes ? "2" : "2"} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="activeTip" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Active</label>
                      <FormGroup >
                      <UncontrolledTooltip
                        delay={0}
                        target={"activeTip"}
                        placement="top"
                      >
                        If checked, this reminder will activate at the time requested.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="active" 
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {!differentTimes &&
                    <Col md="3" style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"startTime"}
                        placement="top"
                      >
                        This is the time you want the reminder to appear on the screen.
                      </UncontrolledTooltip>
                      <label id="startTime" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Start Time</label>
                      <FormGroup>
                        <Input required onChange={(e) => handleSingleStartTime(e)} type="time" id="startTime" value={data.startTime} style={{borderRadius: '8px'}}/>
                      </FormGroup>
                    </Col>
                    }
                    <UncontrolledTooltip
                        delay={0}
                        target={"duration"}
                        placement="top"
                      >
                        This is how long in seconds you want your reminder to remain on the screen. You can always manually close it before this.
                      </UncontrolledTooltip>
                    <Col md={!differentTimes ? "3" : "5"} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="duration" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Duration</label>
                      <FormGroup>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <Input required onChange={(e) => handleFormNonDays(e)} type="number" min={1} max={3600} id="duration" value={data.duration} style={{borderRadius: '8px'}}/>
                          <span style={{ fontSize: "10px", fontWeight: "lighter" }}>seconds</span>
                        </div>
                      </FormGroup>
                    </Col>

                    <Col md={!differentTimes ? "4" : "5"} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <label id="locationTipReminderForm" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column', fontWeight: "bold" }}>Location</label>
                        <UncontrolledTooltip delay={0} target={"locationTipReminderForm"} placement="top">
                          This is the location on the screen where your video will show.
                        </UncontrolledTooltip>
                        <Dropdown isOpen={locationDropdownOpen} toggle={toggleLocationDropdown} style={{ width: "200px" }}>
                        <DropdownToggle
                          caret 
                          style={{ 
                            borderRadius: "8px", 
                            width: "100%", 
                            margin: '0px', 
                            backgroundColor: 'white',
                            border: '1px solid #DDDDDD',
                            color: '#66615b',
                          }}
                        >
                          {modalOptions.find((option) => option.value === data.modalClassName)?.label || "Select Position"}
                        </DropdownToggle>
                        <DropdownMenu className="countdown-timer-modal-location-picker" disabled={!data.showVideo}> 
                          {modalOptions.map((option) => (
                            <DropdownItem
                              key={option.value} 
                              onClick={() => handleSelect(option.value)} 
                              style={{ 
                                display: "flex", 
                                flexDirection: "column",
                                alignItems: "center", 
                                justifyContent: "center", 
                                padding: "2px 10px 5px 10px", 
                                textAlign: "center" 
                              }}
                            >
                              <span style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "5px" }}>
                                {option.label}
                              </span>
                              
                              {locationDropdownOpen && ( // ✅ Only render images when dropdown is open to avoid page height issue
                                <img
                                  src={option.image}
                                  alt={option.label}
                                  style={{ width: "auto", height: "85px" }}
                                />
                              )}
                              
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </Col>
                  </Row>

                  <hr></hr>

                  <ScheduleManager 
                    data={data} 
                    handleScheduleChange={handleScheduleChange} 
                    user={user} 
                  />

                </Form>
              </CardBody>
              <CardFooter style={{display: 'flex', alignContent: 'center', justifyContent: 'center', marginTop: '0px'}}>
                <Button 
                  className="btn-round button-hover-fix"
                  color="info"
                  type="submit"
                  onClick={(e) => (isScheduleNormal(data.schedule) && data.duration >= 10) ? handleSubmit(e) : isScheduleNormal(data.schedule) === false ? potentialInvalidScheduleAlert(e) : data.duration < 10 ? durationSetToZeroAlert(e) : null}
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}>
                    <i className='fa fa-save' />
                    {' '}Save                  
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddEditReminderForm;

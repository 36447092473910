import React from "react";
import { Col, Button, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";

export default function AgendaSubStep({stepIndex, subTaskStepIndex, subTaskStep, subTaskStepArrayLength, deleteAgendaSubTaskStep, handleSubTaskStepTextChange, handleSubTaskStepDurationChange, handleSubTaskStepMove, handleAddResourceLinkClick}) {

  return (
    <Row key={`agendaForm-agendaSubStep-${subTaskStepIndex}`} className="m-0">
    <Col md="1" style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '15px', paddingLeft: '27px', justifyContent: 'center', left: '40px', paddingRight: '30px'}}>
      <Button
        className="btn-icon btn-neutral"
        color="secondary"
        id="tooltip159182282"
        size="md"
        type="button"
        style={{margin: '0px', height: '25px'}}
        onClick={() => handleSubTaskStepMove(stepIndex, subTaskStepIndex, "up")}
        disabled={subTaskStepIndex === 0}
      >
        <i className="nc-icon nc-minimal-up" />
      </Button>
      <Button
        className="btn-icon btn-neutral"
        color="secondary"
        id="tooltip159185983"
        size="md"
        type="button"
        style={{margin: '0px', height: '25px'}}
        onClick={() => handleSubTaskStepMove(stepIndex, subTaskStepIndex, "down")}
        disabled={subTaskStepIndex === subTaskStepArrayLength-1}
      >
        <i className="nc-icon nc-minimal-down" />
      </Button>
    </Col>
    <Col md="4" style={{left: '40px', paddingRight: '40px'}}>
      <FormGroup>
        <Label>Sub-step Text</Label>
        <Input
          placeholder="Ex: Agenda"
          id="title"
          value={subTaskStep.title}
          onChange={(e) => handleSubTaskStepTextChange(stepIndex, subTaskStep.stepId, e)}
        />
      </FormGroup>
    </Col>
    <Col md="2" style={{paddingRight: '0px'}}>
      <FormGroup>
        <Label>Resource</Label>
        <div>
          <i id="linkForSubStep" className="fa fa-link" style={{ fontSize: '22px', cursor: 'pointer', margin: '7px' }} onClick={() => handleAddResourceLinkClick(stepIndex, subTaskStepIndex, "subStep")}></i>
            <UncontrolledTooltip
            delay={0}
            target="linkForSubStep"
            placement="left"
            >
                Add resource link for Sub-step.
            </UncontrolledTooltip>
          <i id="resourceUploadForSubStep" className="fa fa-upload" style={{ fontSize: '22px', color: 'lightgrey', margin: '7px' }} onClick={() => null}></i>
            <UncontrolledTooltip
            delay={0}
            target="resourceUploadForSubStep"
            placement="left"
          >
                Coming Soon: Upload resource for Sub-step.
            </UncontrolledTooltip>
          <i id="contentBoxForStep" className="fa fa-file" style={{ fontSize: '22px', color: 'lightgrey', margin: '7px' }} onClick={() => null}></i>
            <UncontrolledTooltip
            delay={0}
            target="contentBoxForStep"
            placement="left"
          >
                Coming Soon: Add a content box for step.
            </UncontrolledTooltip>
          </div>
      </FormGroup>
    </Col>
    <Col md="4" style={{display: 'flex'}}>
      <FormGroup style={{flexGrow: '1'}}>
        <Label>Sub-step Duration</Label>
        <div style={{display: 'flex', width: '100%'}}> 
          <div style={{paddingLeft: '2px', paddingRight: '2px', flexGrow: '1'}}>
            <div className="inputWithUnit">
                <Input
                    min={0}
                    max={60}
                    type="number"
                    id="minutes"
                    placeholder="Minutes"
                    value={subTaskStep.minutes}
                    onChange={(e) => handleSubTaskStepDurationChange(stepIndex, subTaskStep.stepId, e, true)}
                />
                <span>minutes</span>
            </div>
          </div>
          <div style={{paddingLeft: '2px', paddingRight: '2px', flexGrow: '1'}}>
            <div className="inputWithUnit">
                <Input
                    min={0}
                    max={60}
                    type="number"
                    id="seconds"
                    placeholder="Seconds"
                    value={subTaskStep.seconds}
                    onChange={(e) => handleSubTaskStepDurationChange(stepIndex, subTaskStep.stepId, e, false)}

                />
                <span>seconds</span>
            </div>
          </div>
        </div>
        </FormGroup>
    </Col>
    <Col md="1" style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '0px', justifyContent: 'center'}}>
        <Button
            className="button-delete"
            id="deleteContentBox"
            type="button"
            style={{ color: 'white', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', marginTop: '23px'}}
            size="sm"
            onClick={() => deleteAgendaSubTaskStep(stepIndex, subTaskStep.stepId)}
        >
        <i className="fa fa-trash" />
        </Button>
            <UncontrolledTooltip
            delay={0}
            target="deleteContentBox"
            placement="left"
            >
                Delete
            </UncontrolledTooltip>
    </Col>
  </Row>
  )
}
import React from 'react'
import { selectUser } from "features/userSlice";
import { useSelector } from "react-redux";
import UseAutoCountdown from "AutoCountdown";
import UseAutoReminder from "AutoReminder";
import UseAutoNavigate from "AutoNavigate";
import { Card, CardBody, Row, Col, CardHeader, CardSubtitle } from "reactstrap";

const AutoFeaturesStatus = ({userFromAdminPanel}) => {
    const user = userFromAdminPanel ? userFromAdminPanel : useSelector(selectUser);
    const classes = useSelector((state) => state.user.classes);

    const daysOrder = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

    const week = user.settings.currentWeek === "A" ? 0 : user.settings.currentWeek === "B" ? 1 : user.settings.currentWeek === "C" ? 2 : user.settings.currentWeek === "D" ? 3 : null;
    const dayForDayOfTheWeek = new Date().getDay()
    const dayOfTheWeek = dayForDayOfTheWeek === 1 ? 0 : dayForDayOfTheWeek === 2 ? 1 : dayForDayOfTheWeek === 3 ? 2 : dayForDayOfTheWeek === 4 ? 3 : dayForDayOfTheWeek === 5 ? 4 : dayForDayOfTheWeek === 6 ? 5 : dayForDayOfTheWeek === 7 ? 6 : null;

    function convertTo12HourFormat(time24) {
        // Split the time into hours and minutes
        const [hours, minutes] = time24.split(':').map(Number);
      
        // Determine whether it's AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';
      
        // Convert hours to 12-hour format
        const hours12 = hours % 12 || 12;
      
        // Format the time as "hh:mm AM/PM"
        const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
      
        return time12;
      }

      console.log(user)

    return (
        <>
            <div className="content">
                {user.settings.alteredSchedule ? <h3 style={{color: 'red', background: 'pink'}}>*** You have Altered Schedule mode turned on. This means none of your automations will be active. ***</h3> : null}
                <Card style={{padding: '10px 20px'}}>
                    <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Features Status</h2>
                    </CardHeader>                  
                    <CardSubtitle>***Need to fix UseAutoNavigate, UseAutoCountdown, and UseAutoReminder to show userData from Admin Panel***</CardSubtitle>                <Row>
                    <Col>
                        <UseAutoNavigate />
                        <UseAutoCountdown />
                    </Col>
                    <Col>
                        <UseAutoReminder />
                        <Card>
                            <CardHeader className='h3 mb-0'>User Settings</CardHeader>
                            <CardBody>
                            <b>Altered Schedule:</b> {user.settings.alteredSchedule ? 'On' : 'Off'}{'    <- If "On", all automation features will be turned off.'}
                            <br></br>
                            <b>Multi-Week Schedule:</b> {user.settings.multiWeek ? 'Yes' : 'No'}
                            <br></br>
                            <b>How many different weeks does your schedule contain?</b> {user.settings.numberOfWeeks}
                            <br></br>
                            <b>Current Week:</b> {user.settings.currentWeek}
                            <br></br>
                            <b>Do you need different times for the same class?</b> {user.settings.differentTimes ? 'Yes' : 'No'}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </Card>

                <Card>
                    <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Class Schedules</h2>
                    </CardHeader>   
                {classes?.map(classMap => (
                        <CardBody style={{padding: '0px 30px'}}>
                        <h3 style={{ fontWeight: "bold", color: "#004aad", margin: "0px 0px 10px 0px" }}>{classMap.className}</h3>
                        <br></br>
                        {/* <b>Active:</b>{' '}<span style={{color: classMap?.active ? 'green' : 'red'}}>{classMap?.active ? 'True' : 'False'}</span> */}
                        <br></br>
                        {Object.keys(classMap?.schedule).sort().slice(0, user.settings.numberOfWeeks).map((level, levelIndex) => (
                            <div style={{ padding: '0px 30px' }} key={level}>
                                {console.log("level => ", level)}
                                <h4><b>Week {level}</b></h4>
                                <div style={{ display: 'flex', padding: '0px 30px' }}>
                                    {daysOrder.map((day, dayIndex) => (
                                        <React.Fragment key={day}>
                                            <div 
                                                style={{
                                                    paddingLeft: '20px', 
                                                    paddingRight: '20px', 
                                                    borderRadius: '10px', 
                                                    border: levelIndex === week && dayIndex === dayOfTheWeek ? '2px solid green' : null
                                                }}
                                            >
                                                <h5><b>{day}</b></h5>
                                                <p> <b>Start Time:</b>{' '}{convertTo12HourFormat(classMap.schedule[level][day].startTime)}</p>
                                                <p> <b>End Time:</b>{' '}{convertTo12HourFormat(classMap.schedule[level][day].endTime)}</p>
                                                <p> <b>Active:</b>{' '}<span style={{ color: classMap.schedule[level][day].active ? 'green' : 'red' }}>
                                                    {classMap.schedule[level][day].active ? 'True' : 'False'}
                                                </span></p>
                                            </div>

                                            {/* Render vertical rule only if it's not the last item */}
                                            {dayIndex < daysOrder.length - 1 && (
                                                <div style={{ borderLeft: "2px solid black", height: "150px", margin: "0 10px" }}></div>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <hr></hr>

                        </CardBody>

                ))}
                </Card>
            </div>
        </>
    )
}

export default AutoFeaturesStatus;

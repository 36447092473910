import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase.js";
import { doc, updateDoc, getDoc, setDoc, collection, addDoc, getDocs } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";
import backgroundOptionsData from "../../BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation, useHistory } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import TipTapEditor from "../TipTapEditor";
import stampIcon from "../../assets/img/stamp-solid.svg";
import { createRandomId } from "RandomId";
import FullscreenModal from "../FullscreenModal";
import ActiveDatesModal from "../ActiveDatesModal";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteContentBox, sendBoxToStorage, saveBackgroundContentBox, saveContentBoxActiveDates } from "../../utils/ContentBoxUtils";
import { useDispatch } from "react-redux";


function ContentBox({content, updateContentBox, classId, user, classes, navbarMD, view, multiBox, updateContentBoxWithinMultiBox, printActive, readOnly, singleLineFormattingToolbar, navigationVisible, inFullscreenModal}) {
  const dispatch = useDispatch()
  const [editedContent, setEditedContent] = useState();
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState();
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation();
  const [editable, setEditable] = useState(false);
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const [fullscreenModal, setFullscreenModal] = useState(false);
  const history = useHistory();

  const toggleFullscreenModal = () => {
    setFullscreenModal(!fullscreenModal)
  }

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      if (multiBox) {
        const card = document.getElementById(`editor-box-card-${content.contentBoxId}`);
        const cardHeight = card.getBoundingClientRect().height;
        // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-27}px`});
        setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-27}px`});
      } else {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
        const cardHeight = windowHeight - desiredDistance;
        // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-27}px`});
        setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-27}px`});
      }
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const handleOnBlur = () => {
    if (editedContent) multiBox ? 
      updateContentBoxWithinMultiBox({...content, content: editedContent.content}, true)
    : 
      autoSaveContentBox(content.contentBoxId, editedContent)
  }

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      handleOnBlur();
      // event.preventDefault(); //3-2-25: Commented out to prevent Chrome from prompting are you sure message.
      // event.returnValue = ""; // Required for Chrome //3-2-25: Commented out to prevent Chrome from prompting are you sure message.
    };
  
    const unlisten = history.listen((location, action) => {
      if (["PUSH", "POP", "REPLACE"].includes(action)) {
        handleOnBlur();
      }
    });
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      unlisten();
    };
  }, [history, handleOnBlur]); // Added `handleOnBlur`

// const autoSaveContentBox = async (contentBoxId, updatedContent) => {

//   try {
//     // Reference the specific content box document in Firestore
//     const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, "classes", classId, "contentBoxes", contentBoxId);

//     // Format heading and content preview safely
//     const safeHeading = headingInsideContentBox || "";
//     const headingToUpdate = !content.userHeading // 3-2-25: changed from updatedContent.userHeading because that was always undefined.
//       ? safeHeading.length > 9
//         ? safeHeading.substring(0, 9) + "..."
//         : safeHeading || "Type here..."
//       : content.heading; // 3-2-25: changed from updatedContent.heading.

//     const safeContentPreview = contentPreview || "";
//     const contentPreviewToUpdate = safeContentPreview.substring(0, 30) + "...";

//     // Date formatting
//     const currentDate = new Date();
//     const formattedDate = `${(currentDate.getMonth() + 1).toString().padStart(2, "0")}/${currentDate
//       .getDate()
//       .toString()
//       .padStart(2, "0")}/${currentDate.getFullYear()}`;

//     // Update the Firestore document with only changed fields
//     await updateDoc(contentBoxRef, {
//       content: updatedContent.content,
//       heading: headingToUpdate,
//       contentPreview: contentPreviewToUpdate,
//       lastEdited: formattedDate,
//     });

//     // Handle linked content boxes (update across multiple classes)
//     if (updatedContent.linkedBox) {
//       await updateLinkedContentBoxes(user.uid, contentBoxId, updatedContent);
//     }

//     showAlertForContentBoxSave();
//   } catch (error) {
//     console.error("Error updating content box:", error);
//   }
// };

const autoSaveContentBox = async (contentBoxId, updatedContent) => {
  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      contentBoxId
    );

    // Format heading and content preview safely
    const safeHeading = headingInsideContentBox || "";
    const headingToUpdate = !content.userHeading
      ? safeHeading.length > 9
        ? safeHeading.substring(0, 9) + "..."
        : safeHeading || "Type here..."
      : content.heading;

    const safeContentPreview = contentPreview || "";
    const contentPreviewToUpdate = safeContentPreview.substring(0, 30) + "...";

    // Date formatting
    const currentDate = new Date();
    const formattedDate = `${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${currentDate.getDate().toString().padStart(2, "0")}/${currentDate.getFullYear()}`;

    // Update the Firestore document with only changed fields
    await updateDoc(contentBoxRef, {
      content: updatedContent.content,
      heading: headingToUpdate,
      contentPreview: contentPreviewToUpdate,
      lastEdited: formattedDate,
    });

    // Retrieve the fully updated content box from Firestore
    const updatedDocSnap = await getDoc(contentBoxRef);
    if (updatedDocSnap.exists()) {
      const updatedDoc = updatedDocSnap.data();

      // Handle linked content boxes (update across multiple classes)
      if (updatedDoc.linkedBox) {
        await updateLinkedContentBoxes(user.uid, contentBoxId, updatedDoc);
      }
    }

    showAlertForContentBoxSave();
  } catch (error) {
    console.error("Error updating content box:", error);
  }
};


/**
 * Updates all linked content boxes in other classes that share the same contentBoxId.
 * @param {string} userId - The user's UID.
 * @param {string} contentBoxId - The ID of the content box to update.
 * @param {Object} updatedDoc - The updated document data from Firestore.
 */
 const updateLinkedContentBoxes = async (userId, contentBoxId, updatedDoc) => {
  try {
    // Reference the classes collection
    const classesRef = collection(db, FIREBASE_COLLECTIONS.USERS, userId, FIREBASE_COLLECTIONS.CLASSES);
    const classesSnap = await getDocs(classesRef);

    // Iterate through each class to find linked content boxes
    const updatePromises = classesSnap.docs.map(async (classDoc) => {
      const classId = classDoc.id;
      const contentBoxRef = doc(
        db,
        FIREBASE_COLLECTIONS.USERS,
        userId,
        FIREBASE_COLLECTIONS.CLASSES,
        classId,
        FIREBASE_COLLECTIONS.CONTENT_BOXES,
        contentBoxId
      );

      const contentBoxSnap = await getDoc(contentBoxRef);

      if (contentBoxSnap.exists() && contentBoxSnap.data().linkedBox) {
        // Update only linked content boxes using the latest data from Firestore
        return updateDoc(contentBoxRef, updatedDoc);
      }
    });

    await Promise.all(updatePromises);

    console.log(`✅ Successfully updated all linked content boxes with ID: ${contentBoxId}`);
  } catch (error) {
    console.error("❌ Error updating linked content boxes:", error);
  }
};

// const autoSaveContentBoxWithinMultiBox = async (contentBoxId, updatedContent) => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     // Find the class index based on classId
//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     if (classIndex !== -1) {
//       // Find the content box index based on contentBoxId
//       const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
//         (box) => box.contentBoxId === contentBoxId
//       );

//       if (contentBoxIndex !== -1) {
//         // Make a copy of the existing content box using the spread operator
//         const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
//         // console.log(updatedContentBox)

//         // Update the content box with the new data
//         updatedContentBox.content = updatedContent.content;
//         if (headingInsideContentBox) {
//           if (headingInsideContentBox.length > 9) {
//             updatedContentBox.heading = headingInsideContentBox.substring(0, 9) + "..."
//           } else if (headingInsideContentBox.length === 0) {
//             updatedContentBox.heading = "Type here..."
//           } else {
//             updatedContentBox.heading = headingInsideContentBox
//           }
//         }

//         updatedContentBox.contentPreview = contentPreview?.substring(0, 30) + "..."

//         // Create a new Date object
//         var currentDate = new Date();

//         // Get individual components of the date
//         var year = currentDate.getFullYear();
//         var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
//         var day = currentDate.getDate();

//         // Create a formatted string
//         var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

//         updatedContentBox.lastEdited = formattedDate;

//         // Update the content box in the document data
//         userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

//         // Update the document in Firestore
//         await setDoc(docRef, userCopy);
//         showAlertForContentBoxSave();
//       }
//     }
//   } catch (error) {
//     console.error('Error updating document:', error);
//     // Check if the error message does not include the specific error you want to exclude
//     if (!error.message.includes("Cannot read properties of undefined (reading 'content')")) {
//       showAlertForContentBoxSaveFailure();
//     }
//   }
// };

const [activeDatesModal, setActiveDatesModal] = useState(false)

const toggleSetActiveDatesModal = () => {
  setActiveDatesModal(!activeDatesModal)
}

const [activeDates, setActiveDates] = useState({
  startDate: content.startDate === undefined ? "" : content.startDate,
  endDate: content.endDate === undefined ? "" : content.endDate
});

const handleSaveActiveDates = async (dates) => {
  await saveContentBoxActiveDates(user.uid, classId, content.contentBoxId, dates, toggleSetActiveDatesModal);
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const handleSaveBackground = async (imageFileName) => {
  if (multiBox) {
    const backgroundUrl = `url(${imageFileName})`;
    updateContentBoxWithinMultiBox({...content, background: backgroundUrl }, true)
  } else {
    await saveBackgroundContentBox(user.uid, classId, content.contentBoxId, imageFileName);
  }
};

const notificationAlert = useRef();

function showAlertForContentBoxSave() {
  const options = {
    place: "tc",
    message: (
      <div>
        <div>
          <b>Content Box Saved!</b>
        </div>
      </div>
    ),
    type: "info",
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };

  if (notificationAlert.current) {
    notificationAlert.current.notificationAlert(options);
  } else {
    console.warn("Notification alert reference is unavailable.");
  }
}

function showAlertForContentBoxSaveFailure() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box NOT Saved!</b>
        </div>
      </div>
    ),
    type: 'danger',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  if (notificationAlert.current) {
    notificationAlert.current.notificationAlert(options);
  }
}

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert();

  try {
    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // Format the heading based on length
    const headingToUpdate =
      updatedHeading.length > 9
        ? updatedHeading.substring(0, 9) + "..."
        : updatedHeading.length === 0
        ? "Type here..."
        : updatedHeading;

    // Update the Firestore document
    await updateDoc(contentBoxRef, {
      heading: headingToUpdate,
      userHeading: true,
    });

    successAlert();
  } catch (error) {
    console.error("Error updating content box heading:", error);
  }
};

const warningWithConfirmAndCancelMessage = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(user.uid, classId, contentBoxId)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{backgroundColor: '#dc3545', borderRadius: '30px'}}
      cancelBtnBsStyle="danger"
      cancelBtnStyle={{borderRadius: '30px'}}
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const copyBoxOrLinkedBoxCreatedAlert = (classNameList, type) => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title={type === "link" ? "Linked Box Created!" : "Box Copied!"}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info button-hover-fix"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
      {type === "link" ? "This box is now linked in:" : "This box was copied to:"}
        {classNameList && classNameList.map(className => (
          <li>{className}</li>
        ))}
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox, dispatch);
  setAlert(
    <ReactBSAlert
    style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
    onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

// const copyBoxToClasses = async () => {
//   try {
//     const docRef = doc(db, "users", user.uid);

//     const userCopy = JSON.parse(JSON.stringify(user));

//     // Find the class index based on classId
//     const classIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );

//     let boxToCopy = {};

//     if (multiBox) {
//       boxToCopy = {...content, header: 'Text Editor Box'}
//     } else {
//       boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
//     }
    
//     boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

//     let classesCopy = [...userCopy.classes];

//     classesSelectedForContentBoxCopy.forEach(classId => {
//       const classIndex = classesCopy.findIndex(c => c.classId === classId);

//       if (classIndex !== -1) {
//         // Copy the contentBoxes array and add the new box
//         const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
//         classesCopy[classIndex] = {
//           ...classesCopy[classIndex],
//           contentBoxes: updatedContentBoxes
//         };
//       } else {
//         console.log(`Class with ID ${classId} not found.`);
//       }
//     });

//     userCopy.classes = classesCopy;

//     await setDoc(docRef, userCopy);

//     toggleCopyBoxModal()

//   } catch (error) {
//     console.log("failed", error);
//   }
// };

// const copyBoxToClasses = async (content) => {
//   try {
//     if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
//       console.error("No content box selected or no classes selected for copy.");
//       return;
//     }

//     // Reference the original content box document
//     const originalBoxRef = doc(
//       db, 
//       FIREBASE_COLLECTIONS.USERS, 
//       user.uid, 
//       FIREBASE_COLLECTIONS.CLASSES, 
//       classId, 
//       FIREBASE_COLLECTIONS.CONTENT_BOXES, 
//       boxToCopyId
//     );
    
//     const originalBoxSnap = await getDoc(originalBoxRef);

//     if (!originalBoxSnap.exists()) {
//       console.error("Original content box not found.");
//       return;
//     }

//     let boxToCopy = originalBoxSnap.data();

//     // Generate a new unique ID for the copied content box
//     const newContentBoxId = createRandomId();

//     // Copy the content box to selected classes
//     await Promise.all(
//       classesSelectedForContentBoxCopy.map(async (targetClassId) => {
//         const targetContentBoxRef = doc(
//           db,
//           FIREBASE_COLLECTIONS.USERS,
//           user.uid,
//           FIREBASE_COLLECTIONS.CLASSES,
//           targetClassId,
//           FIREBASE_COLLECTIONS.CONTENT_BOXES,
//           newContentBoxId // Explicitly set document ID
//         );

//         await setDoc(targetContentBoxRef, {
//           ...boxToCopy,
//           contentBoxId: newContentBoxId, // Ensure the same ID is used
//         });
//       })
//     );

//     toggleCopyBoxModal();
//     console.log("✅ Content box copied successfully with consistent IDs!");
//   } catch (error) {
//     console.error("❌ Failed to copy content box:", error);
//   }
// };

const copyBoxToClasses = async () => {
  try {
    if (!boxToCopyId || classesSelectedForContentBoxCopy.length === 0) {
      console.error("❌ No content box selected or no classes selected for copy.");
      return;
    }

    // ✅ If `multiBox === false`, fetch the original content box
    let boxToCopy = null;
    if (!multiBox) {
      const originalBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        user.uid, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        boxToCopyId
      );

      const originalBoxSnap = await getDoc(originalBoxRef);
      if (!originalBoxSnap.exists()) {
        console.error("❌ Original content box not found.");
        return;
      }

      boxToCopy = originalBoxSnap.data();
    }

    // ✅ Process each target class
    await Promise.all(
      classesSelectedForContentBoxCopy.map(async (targetClassId) => {
        let newContentBoxId;
        let newContentBox;

        // ✅ Handle multiBox scenario
        if (multiBox) {
          newContentBoxId = createRandomId();
          newContentBox = { ...content, contentBoxId: newContentBoxId };
        } 
        // ✅ Standard Copy Logic (for non-multiBox cases)
        else {
          newContentBoxId = createRandomId();
          newContentBox = { ...boxToCopy, contentBoxId: newContentBoxId };
        }

        // Reference the new content box in Firestore
        const targetContentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          newContentBoxId
        );

        // ✅ Save new content box to Firestore
        await setDoc(targetContentBoxRef, newContentBox);

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId in order
        if (!updatedOrder.includes(newContentBoxId)) {
          updatedOrder.push(newContentBoxId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxCopy.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "copy");

    console.log("✅ Content box copied and order updated successfully!");
    toggleCopyBoxModal();
  } catch (error) {
    console.error("❌ Failed to copy content box:", error);
  }
};

const [boxToLinkId, setBoxToLinkId] = useState()
const [linkBoxModal, setLinkBoxModal] = useState(false)
const [classesSelectedForContentBoxLink, setClassesSelectedForContentBoxLink] = useState([]);

const toggleLinkedBoxModal = () => {
  if (linkBoxModal) {
    setClassesSelectedForContentBoxLink([])
  } else {
    setClassesSelectedForContentBoxLink([classId])
  }
  setLinkBoxModal(!linkBoxModal)
}

const handleCheckboxChangeForLinkedBoxes = (classId) => {
  setClassesSelectedForContentBoxLink(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

// const addLinkedBoxToClasses = async () => {
//   try {
//     const docRef = doc(db, "users", user.uid);
    
//     const userCopy = JSON.parse(JSON.stringify(user));
    
//     // Find the class index based on classId
//     const currentClassIndex = userCopy.classes.findIndex(
//       (c) => c.classId === classId
//     );
    
//     let boxToCopy = userCopy.classes[currentClassIndex].contentBoxes.find(box => box.contentBoxId === boxToLinkId);
    
//     // Add "linkedBox: true" property
//     boxToCopy = { ...boxToCopy, linkedBox: true };
    
//     let classesCopy = [...userCopy.classes];

//     classesSelectedForContentBoxLink.forEach(targetClassId => {
//       const targetClassIndex = classesCopy.findIndex(c => c.classId === targetClassId);

//       if (targetClassIndex !== -1) {
//         // If the target class is the current class, remove the old box before adding the updated one
//         if (targetClassId === classId) {
//           const updatedContentBoxes = classesCopy[targetClassIndex].contentBoxes.filter(box => box.contentBoxId !== boxToLinkId);
//           classesCopy[targetClassIndex] = {
//             ...classesCopy[targetClassIndex],
//             contentBoxes: [...updatedContentBoxes, boxToCopy]  // Add the updated box with `linkedBox: true`
//           };
//         } else {
//           // For other classes, just add the new box to contentBoxes
//           classesCopy[targetClassIndex] = {
//             ...classesCopy[targetClassIndex],
//             contentBoxes: [...classesCopy[targetClassIndex].contentBoxes, boxToCopy]
//           };
//         }
//       } else {
//         console.log(`Class with ID ${targetClassId} not found.`);
//       }
//     });

//     userCopy.classes = classesCopy;

//     console.log("userCopy => ", userCopy)

//     await setDoc(docRef, userCopy);

//     toggleLinkedBoxModal();

//   } catch (error) {
//     console.log("failed", error);
//   }
// };

const addLinkedBoxToClasses = async () => {
  try {
    if (!boxToLinkId || classesSelectedForContentBoxLink.length === 0) {
      console.error("No content box selected or no classes selected for linking.");
      return;
    }

    // Reference the original content box document
    const originalBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      user.uid,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      boxToLinkId
    );

    const originalBoxSnap = await getDoc(originalBoxRef);

    if (!originalBoxSnap.exists()) {
      console.error("Original content box not found.");
      return;
    }

    let boxToLink = originalBoxSnap.data();

    // Ensure the linked box has the same contentBoxId
    const linkedBoxData = { ...boxToLink, linkedBox: true };

    // Process each selected class
    await Promise.all(
      classesSelectedForContentBoxLink.map(async (targetClassId) => {
        const targetBoxDocRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          boxToLinkId // ✅ Ensure document ID matches `contentBoxId`
        );

        // ✅ Add the linked content box
        if (targetClassId === classId) {
          await setDoc(targetBoxDocRef, linkedBoxData, { merge: true });
        } else {
          await setDoc(targetBoxDocRef, linkedBoxData);
        }

        // ✅ Update contentBoxesOrder in the target class
        const targetClassRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          user.uid,
          FIREBASE_COLLECTIONS.CLASSES,
          targetClassId
        );

        const targetClassSnap = await getDoc(targetClassRef);
        if (!targetClassSnap.exists()) {
          console.warn(`⚠️ Target class (${targetClassId}) not found.`);
          return;
        }

        const targetClassData = targetClassSnap.data();
        let updatedOrder = targetClassData.contentBoxesOrder || [];

        // ✅ Prevent duplicate contentBoxId
        if (!updatedOrder.includes(boxToLinkId)) {
          updatedOrder.push(boxToLinkId);

          // ✅ Save updated order to Firestore
          await updateDoc(targetClassRef, { contentBoxesOrder: updatedOrder });
        }
      })
    );

    const classNameListForAlert = classes
      .filter(classItem => classesSelectedForContentBoxLink.includes(classItem.classId))
      .map(classItem => classItem.className);

    copyBoxOrLinkedBoxCreatedAlert(classNameListForAlert, "link");

    toggleLinkedBoxModal();
    console.log("✅ Linked content box added and order updated successfully!");
  } catch (error) {
    console.error("❌ Failed to add linked content box:", error);
  }
};



  return (
    <>

    {/* <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
      <ModalBody>
        <h5>Copy box to:</h5>
        {user && classes && classes.map((x, index) => 
          <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
            <Label check>
            <Input
              type="checkbox"
              defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
              onChange={() => handleCheckboxChange(x.classId)}
            />                
            <span className="form-check-sign" />
            {x.className}
            </Label>
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter style={{border: '0px'}}>
        <Button onClick={toggleCopyBoxModal}>Cancel</Button>
        <Button 
          disabled={classesSelectedForContentBoxCopy.length < 1} 
          onClick={() => copyBoxToClasses()}
        >
          <i className="fa fa-copy" />{' '}Copy
        </Button>
      </ModalFooter>
    </Modal> */}

<Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal} centered style={{width: '400px'}}>
  <ModalBody>
    <h3 style={{ textAlign: "center", marginBottom: "25px", fontWeight: "bold", color: "#004aad"}}>
      Copy box to:
    </h3>

    {user && classes && (
      <Row>
          <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '90px'}}>
            {classes.map((x, index) => (
              <FormGroup key={`contentBox-copy-box-modal-formGroup-${index}`} check>
                <Label check>
                  <Input
                    type="checkbox"
                    defaultChecked={classesSelectedForContentBoxCopy.includes(x.classId)}
                    onChange={() => handleCheckboxChange(x.classId)}
                  />
                  <span className="form-check-sign" />
                  {x.className}
                </Label>
              </FormGroup>
            ))}
          </Col>
      </Row>
    )}
  </ModalBody>
  <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
    <Button 
      onClick={toggleCopyBoxModal}
      className="delete-button"
      style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
    >
      Cancel
    </Button>
    <Button 
      className="button-hover-fix"
      disabled={classesSelectedForContentBoxCopy.length < 1} 
      onClick={() => copyBoxToClasses()} 
      style={{borderRadius: '30px'}}>
      <i className="fa fa-copy" />{' '}Copy
    </Button>
  </ModalFooter>
</Modal>


    <Modal isOpen={linkBoxModal} toggle={toggleLinkedBoxModal} centered style={{width: '450px'}}>
      <ModalBody>
        <h3 style={{ textAlign: "center", marginBottom: "15px", fontWeight: "bold", color: "#004aad", margin: "0px"}}>
          Add this Linked Box to:
        </h3>
        <Row>
          <Col xs={12} className="d-flex flex-column align-items-left" style={{paddingLeft: '130px'}}>
            {user && classes && classes.map((x, index) => 
              <FormGroup key={`contentBox-linked-box-modal-formGroup-${index}`} check>
                <Label check>
                <Input
                  type="checkbox"
                  defaultChecked={classesSelectedForContentBoxLink.length > 0 && classesSelectedForContentBoxLink.includes(x.classId)}
                  onChange={() => handleCheckboxChangeForLinkedBoxes(x.classId)}
                  disabled={x.classId === classId}
                />                
                <span className="form-check-sign" />
                {x.className}
                </Label>
              </FormGroup>
            )}
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter style={{ border: "0px", display: "flex", justifyContent: "center" }}>
        <Button 
          onClick={toggleLinkedBoxModal} 
          className="delete-button" 
          style={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        >
          Cancel
        </Button>
        <Button
          className="button-hover-fix"
          disabled={classesSelectedForContentBoxLink.length < 1}
          onClick={() => addLinkedBoxToClasses()}
          style={{borderRadius: '30px'}}
        >
          <i className="fa fa-link" />{' '}Link Box
        </Button>
      </ModalFooter>
    </Modal>

    { !isMobile ? (
    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px'}}
      onMouseEnter={() => setShowMainEditButton(true)}
      onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
    >
      <NotificationAlert className="content-box-save-alert" ref={notificationAlert} zIndex={9999} style={{width: '100px'}}/>
      {alert}
        <Card
          id={`editor-box-card-${content.contentBoxId}`}
          key={content.contentBoxId}
          style={{ height: multiBox || inFullscreenModal ? '100%' : cardHeight.card, backgroundImage: location.pathname !== '/print' && content.background || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
          // onMouseEnter={() => setShowMainEditButton(true)}
          // onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
        >
          {!inFullscreenModal && showMainEditButton && location.pathname !== '/add-edit-classes' && location.pathname !== '/print' && !location.pathname.includes('/share/') && !readOnly && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    {!multiBox &&
                    <>
                      <Button
                        // className="btn-round btn-icon"
                        id="editContentBoxHeading"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                        size="sm"
                        onClick={() => editBoxNameAlert(content.contentBoxId)}
                      >
                      <i className="fa fa-pencil" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editContentBoxHeading"
                        placement="left"
                      >
                          Edit Heading
                      </UncontrolledTooltip>
                    </>
                    }
                    <Button
                      // className="btn-round btn-icon"
                      id="changeContentBoxBackground"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(60%)' : 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i className="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                      {user.settings.useActiveDates && !multiBox ? (
                        <>
                          <Button
                            // className="btn-round btn-icon"
                            id="setContentBoxActiveDates"
                            type="button"
                            style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                            size="sm"
                            onClick={() => toggleSetActiveDatesModal()}
                            // onClick={null}
                          >
                            <i className="fa fa-calendar" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) : !multiBox &&
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage(user.uid, classId, content.contentBoxId)}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                    <Button
                      // className="btn-round btn-icon"
                      id="copyBox"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(180%)' : 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      // onClick={null}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="copyBox"
                          placement="left"
                        >
                            Duplicate Box
                        </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="saveContentBoxAsTemplate"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(300%)' : 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => addTemplateAlert(content)}
                    >
                      <img
                          src={stampIcon}
                          alt="stamp"
                          style={{ height: '13px'}}
                      />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="saveContentBoxAsTemplate"
                        placement="left"
                      >
                          Save as Template
                      </UncontrolledTooltip>
                    <Button
                        // className="btn-round btn-icon"
                        id="toggleFullscreenModal"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(420%)' : 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {toggleFullscreenModal()}}
                      >
                        <i className="fa fa-expand"></i>                        
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="toggleFullscreenModal"
                        placement="left"
                      >
                          Expand
                      </UncontrolledTooltip>
                    {!multiBox && 
                      <>
                        <Button
                          // className="btn-round btn-icon"
                          id="makeLinkedBox"
                          type="button"
                          style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 8px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => {setBoxToLinkId(content.contentBoxId); toggleLinkedBoxModal()}}
                        >
                          <i className="fa fa-link"></i>
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="makeLinkedBox"
                          placement="left"
                        >
                            Make Linked Box (Beta)
                        </UncontrolledTooltip>
                        <Button
                          className="button-delete"
                          id="deleteContentBox"
                          type="button"
                          style={{color: 'white', position: 'absolute', transform: multiBox ? 'translateY(540%)' : 'translateY(900%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                          size="sm"
                          onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="deleteContentBox"
                            placement="left"
                          >
                              Delete
                          </UncontrolledTooltip>
                      </>
                    }
                  </>
                  )}
            </>
          )}
  
          <CardBody>
            <div id="editable" style={{ height: '100%', overflowY: 'scroll' }}>
              <TipTapEditor setEditedContent={setEditedContent} initialValue={content.content} handleOnBlur={handleOnBlur} readOnly={readOnly} singleLineFormattingToolbar={singleLineFormattingToolbar} settings={user?.settings}/>
            </div>
          </CardBody>
        </Card>
          
          <ActiveDatesModal activeDatesModal={activeDatesModal} toggleSetActiveDatesModal={toggleSetActiveDatesModal} activeDates={activeDates} saveActiveDates={handleSaveActiveDates}/>
          
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} className="background-modal-header"/>
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => handleSaveBackground(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>
          <FullscreenModal 
            fullscreenModal={fullscreenModal} 
            toggleFullscreenModal={toggleFullscreenModal}
            box={content} 
            navbarMD={navbarMD} 
            classIdRef={classId}
            view={view}
            user={user}
            multiBox={true}
            updateContentBox={updateContentBox}
            navigationVisible={navigationVisible}
          />        
        </Col>

    ) : (

      <Col style={{zoom: '1' }}>
        {/* <Card 
          key={content.contentBoxId}
          style={{ height: '95vh'}}
        >
          <CardBody>
            <div id="editable" style={{ overflowY: 'auto' }}>
            <Editor style={{ height: '93vh'}}
              apiKey="oaerr57l3odwcwxvr9j5en2h3v40g4nhjfopxvyhugjrurbq"
              onEditorChange={(newValue, editor) => {
                  setEditedContent({...editedContent, content: newValue})
                  setContentPreview(editor.getContent({format: 'text'}))
                  if (!content.userHeading) {
                    setHeading(editor.getContent({format: 'text'}))
                    setHeadingInsideContentBox(editor.getContent({format: 'text'}))
                  }
                  
              }}
              onBlur={() => {
                  autoSaveContentBox(content.contentBoxId, editedContent)
              }}
              init={{
                resize: false,
                branding: false,
                statusbar: false,
                min_height: 200,
                selector: 'div#editable',
                inline: true,
                menubar: false,
                nonbreaking_force_tab: true,
                toolbar_mode: 'floating',
                placeholder: 'Type here...',
                font_size_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 30pt 36pt 42pt 48pt 56pt 64pt 72pt',
                plugins: 'image link lists media table emoticons nonbreaking',
                toolbar: ['undo redo | fontfamily fontsize | bold italic underline strikethrough backcolor forecolor ',
                          'align lineheight | checklist numlist bullist indent outdent | link image media table mergetags emoticons charmap hr | copy paste'],
              }}
              initialValue={content.content}
            />
            </div>
          </CardBody>
        </Card> */}
        </Col>

    )}
    </>
  );
}

export default ContentBox;

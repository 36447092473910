import { doc, deleteDoc, updateDoc, setDoc, getDoc, collection, getDocs, arrayRemove, writeBatch } from "firebase/firestore";
import { db } from "../firebase";
import { FIREBASE_COLLECTIONS } from "../config/firebaseCollections";
import { fetchUserData } from "/Users/michaeltucker/Documents/coding-projects/client/src/features/userSlice.js"; // Import Redux action

/**
 * Deletes a content box from Firestore and removes it from the contentBoxesOrder list.
 * @param {string} userId - The user's UID
 * @param {string} classId - The class ID
 * @param {string} contentBoxId - The ID of the content box to delete
 */
 export const deleteContentBox = async (userId, classId, contentBoxId) => {
  try {
    const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, userId, FIREBASE_COLLECTIONS.CLASSES, classId);
    const contentBoxRef = doc(classRef, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // 🔹 Fetch the class document
    const classSnap = await getDoc(classRef);
    if (!classSnap.exists()) {
      console.error("Class document not found.");
      return;
    }

    // 🔹 Delete the content box document
    await deleteDoc(contentBoxRef);
    console.log(`✅ Content box ${contentBoxId} deleted successfully!`);

    // 🔹 Remove the contentBoxId from the contentBoxesOrder array
    await updateDoc(classRef, {
      contentBoxesOrder: arrayRemove(contentBoxId)
    });

    console.log(`✅ Removed ${contentBoxId} from contentBoxesOrder.`);
  } catch (error) {
    console.error("❌ Error deleting content box:", error);
  }
};

/**
 * Deletes multiple content boxes from Firestore and removes them from the contentBoxesOrder list.
 * @param {string} userId - The user's UID
 * @param {string} classId - The class ID
 * @param {string[]} contentBoxIds - An array of content box IDs to delete
 */
 export const deleteMultipleContentBoxes = async (userId, classId, contentBoxIds) => {
  try {
    if (!userId || !classId || !contentBoxIds.length) {
      console.error("🚨 Missing required parameters: User ID, Class ID, or Content Box IDs.");
      return;
    }

    const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, userId, FIREBASE_COLLECTIONS.CLASSES, classId);
    const contentBoxesCollectionRef = collection(classRef, FIREBASE_COLLECTIONS.CONTENT_BOXES);

    // 🔹 Fetch the class document
    const classSnap = await getDoc(classRef);
    if (!classSnap.exists()) {
      console.error("❌ Class document not found.");
      return;
    }

    // 🔹 Start a batch operation
    const batch = writeBatch(db);

    contentBoxIds.forEach((contentBoxId) => {
      const contentBoxRef = doc(contentBoxesCollectionRef, contentBoxId);
      batch.delete(contentBoxRef); // Queue deletion
      console.log(`🗑 Queued deletion for content box: ${contentBoxId}`);
    });

    // 🔹 Commit the batch delete
    await batch.commit();
    console.log(`✅ Deleted ${contentBoxIds.length} content boxes successfully!`);

    // 🔹 Remove the deleted contentBoxIds from contentBoxesOrder
    await updateDoc(classRef, {
      contentBoxesOrder: arrayRemove(...contentBoxIds),
    });

    console.log(`✅ Removed content box IDs from contentBoxesOrder.`);
  } catch (error) {
    console.error("❌ Error deleting multiple content boxes:", error);
  }
};


/**
 * Updates a content box in Firestore
 * @param {string} userId - The user's UID
 * @param {string} classId - The class ID
 * @param {string} contentBoxId - The ID of the content box
 * @param {object} updates - The fields to update
 */
// export const updateContentBox = async (userId, classId, contentBoxId, updates) => {
//   try {
//     const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, userId, "classes", classId, "contentBoxes", contentBoxId);
//     await updateDoc(contentBoxRef, updates);
//     console.log("Content box updated successfully!");
//   } catch (error) {
//     console.error("Error updating content box:", error);
//   }
// };

/**
 * Copies a content box to multiple classes
 * @param {string} userId - The user's UID
 * @param {string} classId - The class ID of the source class
 * @param {string} contentBoxId - The ID of the content box to copy
 * @param {array} targetClassIds - The IDs of the target classes
 */
export const copyContentBoxToClasses = async (userId, classId, contentBoxId, targetClassIds) => {
  try {
    const contentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, userId, "classes", classId, "contentBoxes", contentBoxId);
    const contentBoxSnap = await getDoc(contentBoxRef);

    if (!contentBoxSnap.exists()) {
      console.error("Content box not found!");
      return;
    }

    const contentBoxData = contentBoxSnap.data();

    for (const targetClassId of targetClassIds) {
      const newContentBoxId = crypto.randomUUID(); // Generate a new unique ID
      const newContentBoxRef = doc(db, FIREBASE_COLLECTIONS.USERS, userId, "classes", targetClassId, "contentBoxes", newContentBoxId);

      await setDoc(newContentBoxRef, {
        ...contentBoxData,
        contentBoxId: newContentBoxId, // Assign new ID
      });

      console.log(`Copied content box to class: ${targetClassId}`);
    }
  } catch (error) {
    console.error("Error copying content box:", error);
  }
};

/**
 * Fetches all content boxes for a specific class
 * @param {string} userId - The user's UID
 * @param {string} classId - The class ID
 * @returns {array} - Array of content box objects
 */
export const fetchContentBoxesForClass = async (userId, classId) => {
  try {
    const contentBoxesRef = collection(db, FIREBASE_COLLECTIONS.USERS, userId, "classes", classId, "contentBoxes");
    const contentBoxesSnap = await getDocs(contentBoxesRef);
    return contentBoxesSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error fetching content boxes:", error);
    return [];
  }
};

/**
 * Sends a content box to storage by setting active to false and removing it from contentBoxesOrder
 * @param {string} userId - The user's UID
 * @param {string} classId - The class ID
 * @param {string} contentBoxId - The ID of the content box to delete
 */
 export const sendBoxToStorage = async (userId, classId, contentBoxId) => {
  try {
    if (!userId || !classId || !contentBoxId) {
      throw new Error("Missing required parameters: userId, classId, or contentBoxId.");
    }

    const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, userId, FIREBASE_COLLECTIONS.CLASSES, classId);
    const contentBoxRef = doc(classRef, FIREBASE_COLLECTIONS.CONTENT_BOXES, contentBoxId);

    // ✅ Step 1: Update `active` field in Firestore
    await updateDoc(contentBoxRef, { active: false });

    // ✅ Step 2: Fetch the current class data
    const classSnap = await getDoc(classRef);
    if (!classSnap.exists()) {
      console.error("Class not found.");
      return;
    }

    let classData = classSnap.data();
    let contentBoxesOrder = classData.contentBoxesOrder || [];

    // ✅ Step 3: Remove contentBoxId from contentBoxesOrder if it exists
    if (contentBoxesOrder.includes(contentBoxId)) {
      contentBoxesOrder = contentBoxesOrder.filter(id => id !== contentBoxId);
      await updateDoc(classRef, { contentBoxesOrder });
      console.log(`Removed content box ${contentBoxId} from contentBoxesOrder.`);
    }

    console.log("Content box moved to storage successfully.");
  } catch (error) {
    console.error("Error moving content box to storage:", error);
  }
};


/**
 * Updates the background of a content box in Firestore.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} classId - The ID of the class containing the content box.
 * @param {string} contentBoxId - The ID of the content box to update.
 * @param {string} imageFileName - The file name of the background image.
 * @param {boolean} isMultiBox - Whether the content box is part of a multi-box.
 * @param {Function} updateContentBoxWithinMultiBox - Function to update a multi-box content.
 * @param {Object} content - The content box object (only needed for multi-box updates).
 *
 * @returns {Promise<void>} A promise that resolves when the background update is complete.
 */
export const saveBackgroundContentBox = async (
  userId,
  classId,
  contentBoxId,
  imageFileName,
  isMultiBox = false,
  updateContentBoxWithinMultiBox = null,
  content = null
) => {
  try {
    if (!userId || !classId || !contentBoxId || imageFileName === undefined || imageFileName === null) {
        throw new Error("Missing required parameters: userId, classId, contentBoxId, or imageFileName.");
    }

    const backgroundUrl = `url(${imageFileName})`;

    if (isMultiBox && updateContentBoxWithinMultiBox && content) {
      updateContentBoxWithinMultiBox({ ...content, background: backgroundUrl }, true);
    } else {
      // Reference the specific content box document in Firestore
      const contentBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        userId, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        contentBoxId
      );

      // Update only the background field
      await updateDoc(contentBoxRef, {
        background: backgroundUrl,
      });
    }

    console.log("Content box background updated successfully.");
  } catch (error) {
    console.error("Error updating content box background:", error);
  }
};

/**
 * Updates the active start and end dates of a content box in Firestore.
 *
 * @param {string} userId - The ID of the user.
 * @param {string} classId - The ID of the class containing the content box.
 * @param {string} contentBoxId - The ID of the content box to update.
 * @param {Object} activeDates - Object containing `startDate` and `endDate`.
 * @param {Function} toggleSetActiveDatesModal - Function to close the active dates modal.
 *
 * @returns {Promise<void>} A promise that resolves when the update is complete.
 */
 export const saveContentBoxActiveDates = async (
    userId,
    classId,
    contentBoxId,
    activeDates,
    toggleSetActiveDatesModal
  ) => {
    try {
      if (!userId || !classId || !contentBoxId || !activeDates?.startDate || !activeDates?.endDate) {
        throw new Error("Missing required parameters: userId, classId, contentBoxId, startDate, or endDate.");
      }
  
      // Reference the specific content box document in Firestore
      const contentBoxRef = doc(
        db, 
        FIREBASE_COLLECTIONS.USERS, 
        userId, 
        FIREBASE_COLLECTIONS.CLASSES, 
        classId, 
        FIREBASE_COLLECTIONS.CONTENT_BOXES, 
        contentBoxId
      );
  
      // Update the Firestore document with the new active dates
      await updateDoc(contentBoxRef, {
        startDate: activeDates.startDate,
        endDate: activeDates.endDate,
      });
  
      // Close the modal after saving (if function is provided)
      if (toggleSetActiveDatesModal) {
        toggleSetActiveDatesModal();
      }
  
      console.log("Content box active dates updated successfully.");
    } catch (error) {
      console.error("Error updating active dates:", error);
    }
  };
  
  /**
   * Adjusts the zoom level of a content box.
   * @param {string} userId - The user's ID.
   * @param {string} classId - The class ID containing the content box.
   * @param {string} contentBoxId - The content box ID.
   * @param {string} direction - "up" to zoom in, "down" to zoom out.
   * @param {boolean} multiBox - Whether this is a multi-box content.
   * @param {Function} updateContentBoxWithinMultiBox - Function to update multi-box state.
   */
  export const zoomContentBox = async (
    userId,
    classId,
    contentBoxId,
    direction,
    multiBox = false,
    updateContentBoxWithinMultiBox
  ) => {
    try {
      if (!userId || !classId || !contentBoxId) {
        console.error("Missing required identifiers: user ID, class ID, or content box ID.");
        return;
      }
  
      const zoomIncrement = direction === "up" ? 0.1 : -0.1;
  
      if (multiBox) {
        console.log("Yes, it's a multibox")
        // Handle multiBox logic
        const previousZoom = content.zoom || 1;
        const newZoom = Math.max(0.1, previousZoom + zoomIncrement); // Prevents zoom from going below 0.1
  
        updateContentBoxWithinMultiBox({ ...content, zoom: newZoom }, true);
        console.log(`MultiBox zoom updated to ${newZoom}.`);
        // return newZoom;
      } else {
        // Reference the specific content box document in Firestore
        const contentBoxRef = doc(
          db,
          FIREBASE_COLLECTIONS.USERS,
          userId,
          FIREBASE_COLLECTIONS.CLASSES,
          classId,
          FIREBASE_COLLECTIONS.CONTENT_BOXES,
          contentBoxId
        );
  
        // Fetch the current content box data
        const contentBoxSnap = await getDoc(contentBoxRef);
  
        if (!contentBoxSnap.exists()) {
          console.error("Content box document not found.");
          return;
        }
  
        const contentBoxData = contentBoxSnap.data();
        const previousZoom = contentBoxData.zoom || 1;
        const newZoom = Math.max(0.1, previousZoom + zoomIncrement); // Prevents zoom from going below 0.1
  
        // Update only the zoom field in Firestore
        await updateDoc(contentBoxRef, { zoom: newZoom });
  
        console.log(`Content box zoom updated to ${newZoom}.`);
        return newZoom;
      }
    } catch (error) {
      console.error("Error updating content box zoom:", error);
    }
};

/**
 * Updates a content box in Firestore.
 * @param {string} userId - The user's ID.
 * @param {string} classId - The class ID containing the content box.
 * @param {string} contentBoxId - The content box ID.
 * @param {object} updatedContent - The updated content box data.
 * @param {Function} setBoxes - Function to update local state.
 */
export const updateContentBox = async (
  userId,
  classId,
  contentBoxId,
  updatedContent,
  setBoxes
) => {
  try {
    if (!userId || !classId || !contentBoxId) {
      console.error("Missing required identifiers: user ID, class ID, or content box ID.");
      return;
    }

    // Reference the specific content box document in Firestore
    const contentBoxRef = doc(
      db,
      FIREBASE_COLLECTIONS.USERS,
      userId,
      FIREBASE_COLLECTIONS.CLASSES,
      classId,
      FIREBASE_COLLECTIONS.CONTENT_BOXES,
      contentBoxId
    );

    // Fetch current contentBox data (if needed)
    const contentBoxSnap = await getDoc(contentBoxRef);
    if (!contentBoxSnap.exists()) {
      console.error("Content box not found.");
      return;
    }

    let updatedContentBox = { ...updatedContent };

    // Ensure heading logic
    if (!updatedContentBox.userHeading) {
      if (updatedContentBox.heading === undefined) {
        updatedContentBox.heading = "Heading";
      } else {
        if (updatedContentBox.heading.length > 9) {
          updatedContentBox.heading = updatedContentBox.heading.substring(0, 9) + "...";
        } else if (updatedContentBox.heading.length === 0) {
          updatedContentBox.heading = "Type here...";
        }
      }
    }

    // Update only the changed fields in Firestore
    await updateDoc(contentBoxRef, updatedContentBox);

    // 🔁 If this is a linked box, propagate to other classes
    if (updatedContentBox.linkedBox) {
      await updateLinkedContentBoxes(userId, contentBoxId, updatedContentBox);
    }

    // Update local state
    if (setBoxes) {
      setBoxes((prevBoxes) =>
        prevBoxes.map((box) => (box.contentBoxId === contentBoxId ? { ...box, ...updatedContentBox } : box))
      );
    }

    console.log("Content box updated successfully!");
  } catch (error) {
    console.error("Error updating content box:", error);
  }
};

/**
 * Updates all linked content boxes in other classes that share the same contentBoxId.
 * @param {string} userId - The user's UID.
 * @param {string} contentBoxId - The ID of the content box to update.
 * @param {Object} updatedDoc - The updated document data from Firestore.
 */
 const updateLinkedContentBoxes = async (userId, contentBoxId, updatedDoc) => {
  try {
    // Reference the classes collection
    const classesRef = collection(db, FIREBASE_COLLECTIONS.USERS, userId, FIREBASE_COLLECTIONS.CLASSES);
    const classesSnap = await getDocs(classesRef);

    // Iterate through each class to find linked content boxes
    const updatePromises = classesSnap.docs.map(async (classDoc) => {
      const classId = classDoc.id;
      const contentBoxRef = doc(
        db,
        FIREBASE_COLLECTIONS.USERS,
        userId,
        FIREBASE_COLLECTIONS.CLASSES,
        classId,
        FIREBASE_COLLECTIONS.CONTENT_BOXES,
        contentBoxId
      );

      const contentBoxSnap = await getDoc(contentBoxRef);

      if (contentBoxSnap.exists() && contentBoxSnap.data().linkedBox) {
        // Update only linked content boxes using the latest data from Firestore
        return updateDoc(contentBoxRef, updatedDoc);
      }
    });

    await Promise.all(updatePromises);

    console.log(`✅ Successfully updated all linked content boxes with ID: ${contentBoxId}`);
  } catch (error) {
    console.error("❌ Error updating linked content boxes:", error);
  }
};

  


import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { CardBody, Col, Button, Row, ListGroup, ListGroupItem, Progress, CardHeader, UncontrolledTooltip } from "reactstrap";
import usePersistentTimer from "../../workers/usePersistentTimer";

function AgendaBoxCopyForDebug({ navbarMD, printActive, navigationVisible, content, zoom }) {
  const { elapsedTime } = usePersistentTimer(); // Reliable ticking timer
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [boxData, setBoxData] = useState();
  const stepRefs = useRef([]); // Ref array to track all steps
  const logs = false;

//   const content = {
//     "titleFont": "acme",
//     "show": true,
//     "endDate": "2025-01-31",
//     "userHeading": true,
//     "startDate": [
//         "Mondays",
//         "Tuesdays",
//         "Wednesdays",
//         "Thursdays",
//         "Fridays"
//     ],
//     "startTime": "00:47:00 GMT-0500 (Eastern Standard Time)",
//     "subTaskStepColor": "#1fe523",
//     "lastEdited": "01/30/2025",
//     "subTaskStepFont": "bangers",
//     "agendaBox": true,
//     "stepColor": "#d41616",
//     "steps": [
//         {
//           "subTaskSteps": [
//             {
//                 "title": "SubtaskStep #1",
//                 "minutes": 5,
//                 "seconds": 0,
//                 "stepId": "idc013211149bfc"
//             },
//             {
//               "title": "SubtaskStep #2",
//               "minutes": 10,
//               "seconds": 0,
//               "stepId": "idc013211149bfc"
//             }
//         ],
//             "seconds": "0",
//             "stepId": "id36682c3729571",
//             "minutes": "15",
//             "subTask": false,
//             "title": "Step 1",
//             "resourceLink": "https://www.youtube.com"
//         },
//         {
//             "subTask": false,
//             "seconds": 0,
//             "stepId": "id6b245bc82641e",
//             "title": "Step 2",
//             "minutes": 20,
//             "subTaskSteps": [
//                 {
//                     "title": "SubtaskStep #1",
//                     "minutes": 10,
//                     "seconds": 0,
//                     "stepId": "idc013211149bfc"
//                 },
//                 {
//                   "title": "SubtaskStep #2",
//                   "minutes": 10,
//                   "seconds": 0,
//                   "stepId": "idc013211149bfc"
//                 }
//             ]
//         }
//     ],
//     "stepFont": "alata",
//     "active": true,
//     "contentBoxId": "idbb9e8e806908b",
//     "heading": "Agenda Test Again",
//     "titleColor": "#2d21d4",
//     "background": "url()"
// }

useEffect(() => {
      // Function to update the card height based on window height
      const updateCardHeight = () => {
        const windowHeight = window.innerHeight;
        const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
        const cardHeight = windowHeight - desiredDistance;
        setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-175}px`});
      };
  
      // Call the updateCardHeight function when the window is resized
      window.addEventListener("resize", updateCardHeight);
  
      // Initial call to set the card height
      updateCardHeight();
  
      // Remove the event listener when the component is unmounted
      return () => {
        window.removeEventListener("resize", updateCardHeight);
      };
    }, [navbarMD, navigationVisible]);

// ✅ Initialize state only once
useEffect(() => {
  const initializedSteps = content.steps.map((step) => ({
      ...step,
      active: false,
      timeElapsed: 0,
      completed: false,
      subTaskSteps: step.subTaskSteps?.map((subTask) => ({
          ...subTask,
          active: false,
          timeElapsed: 0,
          completed: false
      })) || [],
  }));
  setBoxData({ ...content, steps: initializedSteps });
}, []);

// const parseStartTime = (timeString) => {
//   console.log("timeString in parseStartTime => ", timeString)
//   try {
//     const today = new Date(); // Get today's date
//     const [hours, minutes, seconds] = timeString.split(":").map(Number); // Split and parse the time string
//     console.log("seconds in parseStartTime => ", seconds)
//     const parsedDate = new Date(
//       today.getFullYear(),
//       today.getMonth(),
//       today.getDate(),
//       hours, // Hours
//       minutes, // Minutes
//       seconds || 0 // Seconds (default to 0 if not provided)
//     );

//     if (isNaN(parsedDate.getTime())) {
//       throw new Error("Invalid date parsing");
//     }

//     return parsedDate;
//   } catch (error) {
//     console.error("Error parsing startTime:", timeString, error);
//     return null;
//   }
// };

const parseStartTime = (timeString) => {
  if (logs) console.log("timeString in parseStartTime => ", timeString);
  try {
    const today = new Date(); // Get today's date

    // Remove timezone information (anything after " GMT")
    const cleanTimeString = timeString.split(" GMT")[0];

    // Split and parse the time components
    const [hours, minutes, seconds] = cleanTimeString.split(":").map(Number);
    if (logs) console.log("seconds in parseStartTime => ", seconds);

    // Construct a new Date object using today's date and extracted time
    const parsedDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      hours, // Hours
      minutes, // Minutes
      seconds || 0 // Default seconds to 0 if not provided
    );

    if (isNaN(parsedDate.getTime())) {
      throw new Error("Invalid date parsing");
    }

    return parsedDate;
  } catch (error) {
    console.error("Error parsing startTime:", timeString, error);
    return null;
  }
};


useEffect(() => {
  if (!boxData?.startTime) return;

  const now = new Date();
  const startTime = parseStartTime(boxData.startTime);

  if (logs) console.log("startTime => ", startTime)

  if (!startTime) {
    if (logs) console.error("Invalid startTime format:", boxData.startTime);
    return;
  }

  const timeDifference = now.getTime() - startTime.getTime(); // Difference in ms

  if (timeDifference > 3000) {
    if (logs) console.log("Start time was too long ago. Ignoring.");
    return;
  }

  if (timeDifference >= 0 && timeDifference <= 3000) {
    if (logs) console.log("Start time was within the last 3 seconds. Starting now.");
    handleStart();
    return;
  }

  const timeUntilStart = Math.max(0, startTime.getTime() - now.getTime());

  if (logs) console.log(`handleStart() will be triggered in ${timeUntilStart / 1000} seconds.`);

  const timer = setTimeout(() => {
    handleStart();
  }, timeUntilStart);

  return () => clearTimeout(timer);
}, [boxData?.startTime]);

// ✅ Process Steps & Subtasks without infinite loop
useEffect(() => {
  if (!isRunning || isPaused || !boxData) return;

  setBoxData((prevBoxData) => {
      if (!prevBoxData) return prevBoxData; // 🛑 Ensure state exists before modifying

      let updatedSteps = [...prevBoxData.steps]; // Clone steps to modify safely
      let shouldUpdate = false;

      for (let stepIndex = 0; stepIndex < updatedSteps.length; stepIndex++) {
          let step = updatedSteps[stepIndex];

          if (step.active) {
              let hasActiveSubtask = step.subTaskSteps.some(subTask => subTask.active);

              if (!hasActiveSubtask && step.subTaskSteps.length > 0) {
                  // ✅ If no active subtask exists but subtasks are present, activate the first one
                  step.subTaskSteps[0].active = true;
                  shouldUpdate = true;
              }

              for (let subIndex = 0; subIndex < step.subTaskSteps.length; subIndex++) {
                  let subTask = step.subTaskSteps[subIndex];

                  if (subTask.active) {
                      if (subTask.timeElapsed < subTask.minutes * 60 + subTask.seconds) {
                          step.timeElapsed += 1;
                          subTask.timeElapsed += 1;
                          shouldUpdate = true;
                      } else {
                          // 🛑 If subtask is finished, mark it as complete
                          subTask.active = false;
                          subTask.completed = true;

                          if (subIndex + 1 < step.subTaskSteps.length) {
                              // ✅ Move to the next subtask
                              step.subTaskSteps[subIndex + 1].active = true;
                              shouldUpdate = true;
                          } else {
                              // 🛑 No more subtasks, deactivate main step
                              step.active = false;
                              step.completed = true;
                              if (stepIndex + 1 < updatedSteps.length) {
                                  // ✅ Move to the next step
                                  updatedSteps[stepIndex + 1].active = true;
                                  shouldUpdate = true;
                              } else {
                                  // 🛑 No more steps, stop the agenda
                                  setIsRunning(false);
                              }
                          }
                      }
                      break; // ✅ Exit loop since we found the active subtask
                  }
              }

              if (!shouldUpdate) {
                  if (step.timeElapsed < step.minutes * 60 + step.seconds) {
                      step.timeElapsed += 1;
                      shouldUpdate = true;
                  } else {
                      // 🛑 Main step is finished, deactivate it
                      step.active = false;
                      step.completed = true;
                      if (stepIndex + 1 < updatedSteps.length) {
                          updatedSteps[stepIndex + 1].active = true;
                          shouldUpdate = true;
                      } else {
                          // 🛑 No more steps, stop the agenda
                          setIsRunning(false);
                      }
                  }
              }

              if (shouldUpdate) break; // ✅ Prevent redundant updates
          }
      }

      return shouldUpdate ? { ...prevBoxData, steps: updatedSteps } : prevBoxData; // ✅ Only update state if needed
  });
}, [elapsedTime]); // ✅ Runs every second

// ✅ Start Button
const handleStart = () => {
  setIsRunning(true);
  setIsPaused(false);
  setBoxData((prev) => {
      let updatedSteps = prev.steps.map((step, stepIndex) => {
          let updatedSubTasks = step.subTaskSteps.map((subTask, subTaskIndex) => ({
              ...subTask,
              active: subTaskIndex === 0 && stepIndex === 0, // ✅ Activate first subtask of first step
              timeElapsed: 0,
              completed: false
          }));

          return {
              ...step,
              active: stepIndex === 0, // ✅ Activate first step
              timeElapsed: 0,
              completed: false,
              subTaskSteps: updatedSubTasks,
          };
      });

      return { ...prev, steps: updatedSteps };
  });
};

// ✅ Pause Button
const handlePause = () => {
  setIsPaused((prev) => !prev);
};

// ✅ Start from Step/Subtask
const handleStartFromStep = (stepIndex, subTaskIndex = 0) => {
  
  setIsRunning(true);
  setIsPaused(false);

  setBoxData((prev) => {
      let updatedSteps = prev.steps.map((step, i) => {
          let isCurrentStep = i === stepIndex;

          // ✅ Calculate `timeElapsed` by summing the time of completed subtasks
          let accumulatedTime = isCurrentStep && subTaskIndex > 0 
              ? step.subTaskSteps
                    .slice(0, subTaskIndex) // Get all previous subtasks
                    .reduce((total, subTask) => total + (subTask.minutes * 60 + subTask.seconds), 0) 
              : 0;

          return {
              ...step,
              active: isCurrentStep, 
              timeElapsed: isCurrentStep ? accumulatedTime : step.timeElapsed, // ✅ Use accumulated time if starting from a subtask
              completed: i < stepIndex, // ✅ Mark previous steps as completed
              subTaskSteps: step.subTaskSteps.map((subTask, j) => ({
                  ...subTask,
                  active: isCurrentStep && j === subTaskIndex, // ✅ Only the selected subtask is active
                  timeElapsed: isCurrentStep && j === subTaskIndex ? 0 : subTask.timeElapsed,
                  completed: isCurrentStep && j < subTaskIndex, // ✅ Mark previous subtasks as completed
              })),
          };
      });

      return { ...prev, steps: updatedSteps };
  });
};


  function convertSecondsToMinutes(step) {
    const totalSeconds = (Number(step.seconds) + (Number(step.minutes) * 60)) - Number(step.timeElapsed)
    const minutes = Math.floor(totalSeconds / 60);
    const remainingSeconds = totalSeconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    if (boxData?.steps) {
      stepRefs.current = boxData.steps.map((_, i) => stepRefs.current[i] || React.createRef());
    }
  }, [boxData?.steps]);
  
  useLayoutEffect(() => {
    setTimeout(() => {
      if (boxData) {
        const activeStepIndex = boxData.steps.findIndex((step) => step.active);
        if (activeStepIndex !== -1 && stepRefs.current[activeStepIndex]?.current) {
          stepRefs.current[activeStepIndex].current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }
    }, 50); // Delay to ensure DOM updates
  }, [boxData?.steps]);

  // console.log("boxData => ", boxData)
  return boxData && (
    <>
      <CardHeader>
        <h1 style={{marginBottom: '0px', fontFamily: boxData.titleFont, color: location.pathname !== '/print' ? boxData.titleColor : null, zoom: zoom || '1.1'}}>
            {boxData.heading}
        </h1>
        </CardHeader>
        <CardBody className="agenda-body" style={{paddingTop: '0px', overflowY: 'auto', marginTop: '0px', }}>
          <div style={{ minHeight: cardHeight.editor, overflowY: 'auto', zoom: zoom || '1'}}>
            <ListGroup flush>
            { boxData && boxData.steps && boxData.steps.map((step, stepIndex) => (
              <div key={`agenda-agendaStep-${stepIndex}`} style={{border: '0px'}}>
                <div ref={stepRefs.current[stepIndex]}></div>
                <ListGroupItem
                  active={step.active}
                  style={{
                    paddingTop: '2px',
                    paddingBottom: '10px',
                    borderRadius: step.active && '8px',
                    border: '0px',
                    backgroundImage: !step.active && location.pathname !== '/print' ? boxData?.background : null || ''
                  }}
                >
                  <Row>
                    <Col style={{display: "flex"}}>
                      {step.resourceLink ? (
                        <>
                          <div 
                            style={{marginBottom: '0px', 
                            fontWeight: step.active ? 'bold' : null, 
                            fontFamily: boxData.stepFont, 
                            cursor: 'pointer',
                            fontSize: '36px',
                            color: location.pathname === '/print' ? 'black' : boxData.stepColor !== null ? boxData.stepColor : 'black',
                            }}
                            onClick={() => handleStartFromStep(stepIndex)}
                          >
                          {step.title}
                          </div>
                          <a 
                            href={step.resourceLink} 
                            target="_blank" 
                            style={{
                              color: location.pathname === '/print' ? 'black' : boxData.stepColor !== null ? boxData.stepColor || 'black' : 'black',
                              textDecoration: 'none' // Optional, to remove underline from the link
                            }}
                          >
                            <i
                              className="fa fa-link"
                              style={{margin: '12px', fontSize: '20px', cursor: 'pointer'}}
                            />
                          </a>
                          {' '}
                          <i className={step.completed ? "fa fa-check" : null} style={{fontSize: '40px', marginLeft: '8px'}}/>
                        </>
                      )
                      :
                      (
                        <>
                          <div 
                            style={{marginBottom: '0px', 
                            fontWeight: step.active ? 'bold' : null, 
                            fontFamily: boxData.stepFont, 
                            cursor: 'pointer',
                            fontSize: '36px',
                            color: location.pathname === '/print' ? 'black' : boxData.stepColor !== null ? boxData.stepColor : 'black',
                            }}
                            onClick={() => handleStartFromStep(stepIndex)}
                          >
                          {step.title}
                          </div>
                          {' '}
                          <i className={step.completed ? "fa fa-check" : null} style={{fontSize: '40px', marginLeft: '8px'}}/>
                        </>
                      )
                      }
                    </Col>
                  {step.active && (
                    <Col>
                      <h4 style={{marginTop: '4px', marginBottom: '0px', textAlign: 'right'}}>{convertSecondsToMinutes(step)}</h4>
                    </Col>
                  )}
                  </Row>
                  {step.active && (
                    <Progress
                    animated
                    striped
                    value={step.timeElapsed}
                    style={{height: '40px'}}
                    max={Number(step.minutes)*60 + Number(step.seconds)}
                  >
                    </Progress>
                  )}
                  {step.subTaskSteps && step.subTaskSteps.map((subTaskStep, subIndex) => (
                    <ListGroup flush key={`agenda-agendaSubTaskStep-${subIndex}`}>
                    <ListGroupItem
                      key={`agenda-agendaSubStep-${subIndex}`}
                      disabled={step.completed}
                      active={subTaskStep.active}
                      style={{borderRadius: subTaskStep.active && '8px', backgroundImage: !subTaskStep.active && location.pathname !== '/print' ? boxData?.background : null || '', paddingTop: '2px', paddingBottom: '2px', backgroundColor: step.active ? '#007bff' : null}}
                    >
                      <Row>
                        <Col style={{display: "flex"}}>
                          {subTaskStep.resourceLink ? (
                            <>
                              <div 
                                style={{marginBottom: '0px', 
                                fontWeight: subTaskStep.active ? 'bold' : null, 
                                fontFamily: boxData.subTaskStepFont, 
                                cursor: 'pointer',
                                fontSize: '28px',
                                color: location.pathname === '/print' ? 'black' : boxData.subTaskStepColor !== null ? boxData.subTaskStepColor : 'black',
                                }}
                                onClick={() => handleStartFromStep(stepIndex, subIndex)}
                              >
                              {subTaskStep.title}
                              </div>
                              <a 
                                href={subTaskStep.resourceLink} 
                                target="_blank" 
                                style={{
                                  color: location.pathname === '/print' ? 'black' : boxData.subTaskStepColor !== null ? boxData.subTaskStepColor || 'black' : 'black',
                                  textDecoration: 'none' // Optional, to remove underline from the link
                                }}
                              >
                                <i
                                  className="fa fa-link"
                                  style={{margin: '12px', fontSize: '16px', cursor: 'pointer'}}
                                />
                              </a>
                              {' '}
                              <i className={subTaskStep.completed ? "fa fa-check" : null} style={{fontSize: '33px', marginLeft: '5px'}}/>
                            </>
                          )
                          :
                          (
                            <>
                              <div 
                                style={{marginBottom: '0px', 
                                fontWeight: subTaskStep.active ? 'bold' : null, 
                                fontFamily: boxData.subTaskStepFont, 
                                cursor: 'pointer',
                                fontSize: '28px',
                                color: location.pathname === '/print' ? 'black' : boxData.subTaskStepColor !== null ? boxData.subTaskStepColor : 'black',
                                }}
                                onClick={() => handleStartFromStep(stepIndex, subIndex)}
                              >
                              {subTaskStep.title}
                              </div>
                              {' '}
                              <i className={subTaskStep.completed ? "fa fa-check" : null} style={{fontSize: '33px', marginLeft: '5px'}}/>
                            </>
                          )
                          }
                        </Col>
                      {subTaskStep.active && (
                        <Col>
                          <h4 style={{marginTop: '4px', marginBottom: '0px', textAlign: 'right'}}>{convertSecondsToMinutes(subTaskStep)}</h4>
                        </Col>
                      )}
                      </Row>
                      {subTaskStep.active && (
                        <Progress
                          animated
                          striped
                          value={subTaskStep.timeElapsed}
                          style={{height: '20px'}}
                          max={Number(subTaskStep.minutes)*60 + Number(subTaskStep.seconds)}
                        />
                      )}
                    </ListGroupItem>
                    </ListGroup>
                  ))}
                </ListGroupItem>
              </div>
            )
          )}
          </ListGroup>
        </div>

          
          {/* Play/Pause Buttons */}
          {!isRunning ? (
            <>
              <Button 
                id="manualPlay" 
                type="button" 
                onClick={handleStart}
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', left: '8px', bottom: '0px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', overflow: 'visible', zIndex: '888'}}
              >
                  <i className="fa fa-play" />
              </Button>
                <UncontrolledTooltip
                delay={0}
                target="manualPlay"
                placement="left"
              >
                  Manual Start
              </UncontrolledTooltip>
            </>
          ) : (
              <>
                  <Button 
                    id="manualPause"
                    type="button"
                    onClick={handlePause}
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', left: '8px', bottom: '0px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', overflow: 'visible', zIndex: '888'}}
                  >
                      <i className={isPaused ? "fa fa-play" : "fa fa-pause"} />
                  </Button>
              </>
          )}
        </CardBody>
    </>
  );
}

export default AgendaBoxCopyForDebug;
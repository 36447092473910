import {React, useState, useEffect, useRef} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { setDoc, doc, updateDoc, arrayUnion, collection } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { createRandomId } from "RandomId";
import { NavLink } from 'react-router-dom';
import isScheduleNormal from "ScheduleChecker";
import Scheduling from "/Users/michaeltucker/Documents/coding-projects/client/src/utils/Scheduling.js"
import ScheduleManager from "components/ScheduleManager";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip,
  ModalHeader,
  Label
} from "reactstrap";

function AddEditClassForm() {

  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  const item = state?.item;
  const user = useSelector(selectUser);
  const history = useHistory();
  const classIdForNewClass = useRef(createRandomId())
  const announcementIdForNewClass = useRef(createRandomId())
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
  const [currentWeek, setCurrentWeek] = useState("A") //this is not the settings currentWeek - "A" should remain the default
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;

  console.log("addEditClassForm")

  useEffect(() => {
    if (item) {
      Scheduling.addWeeksToSchedule(setData, user, item?.classId);
    }
  }, []);

  const [data, setData] = useState({
    autoHappyBirthday: item?.autoHappyBirthday || null,
    classId: item?.classId,
    className: item?.className || null,
    period: item?.period || null,
    startTime: item?.startTime || '',
    endTime: item?.endTime || '',
    showMarquee: item?.showMarquee === false ? false : item?.showMarquee === true ? true : true,
    showClock: item?.showClock === false ? false : item?.showClock === true ? true : true,
    active: item?.active === false ? false : item?.active === true ? true : true,
    contentBoxes: item?.contentBoxes || [],
    orderOfAnnouncements: item?.orderOfAnnouncements || [],
    days: [],
    schedule: item?.schedule || Scheduling.blankSchedule,
    rosterData: item?.rosterData || null,
    background: item?.background || null,
    backgroundAnnouncements: item?.backgroundAnnouncements || null,
    backgroundClock: item?.backgroundClock || null,
    clockVersion: item?.clockVersion || null,
    clockFontColor: item?.clockFontColor || null,
  });

  const [alert, setAlert] = useState(null);
  // to stop the warning of calling setState of unmounted component
  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !data.className || data.className.length < 1 || data.className.length > 30 || 
      data.period?.length < 1 || data.period?.length > 5 || 
      /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className)
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  }, [data]);
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validated) {
  //     try {
  //       const docRef = doc(db, "users", user.uid);
  
  //       const userCopy = JSON.parse(JSON.stringify(user));
  
  //       const newClass = {
  //         classId: classIdForNewClass.current,
  //         period: data.period,
  //         className: data.className,
  //         days: data.days,
  //         schedule: data.schedule,
  //         startTime: data.startTime,
  //         endTime: data.endTime,
  //         active: data.active === false ? false : true,
  //         showClock: data.showClock === false ? false : true,
  //         showMarquee: data.showMarquee === false ? false : true,
  //         orderOfAnnouncements: [announcementIdForNewClass.current],
  //         contentBoxes: [
  //           {
  //             contentBoxId: createRandomId(),
  //             heading: "Type here...",
  //             show: true,
  //             active: true
  //           }
  //         ]
  //       }
    
  //       const sampleAnnouncementToAdd = {
  //         html: "<p>This is a sample announcement.</p>",
  //         text: "This is a sample announcement.",
  //         active: true,
  //         show: true,
  //         startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
  //         endDate: "",
  //         lastEdited: formattedDate,
  //         dateCreated: formattedDate,
  //         classIds: [classIdForNewClass.current],
  //         id: announcementIdForNewClass.current
  //       }
  
  //       const classIndex = userCopy.classes.findIndex(
  //         (c) => c.classId === data.classId
  //       );
  
  //       // Update the content box in the document data
  //       if (item === undefined) {
  //         userCopy.classes.push(newClass);
  //         userCopy.announcements.push(sampleAnnouncementToAdd);
  //       } else {
  //         userCopy.classes[classIndex] = data
  //       }
  
  //       // Update the document in Firestore
  //       await setDoc(docRef, userCopy);
  
  //       successAlert();
  //     } catch (error) {
  //       console.log('Error updating document:', error);
  //     }
  //   } else {
  //     invalidEntryAlert()
  //   }
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validated) {
  //     try {
  //       const userDocRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
  //       const classesCollectionRef = collection(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES);
  
  //       const newClass = {
  //         classId: classIdForNewClass.current,
  //         period: data.period,
  //         className: data.className,
  //         days: data.days,
  //         schedule: data.schedule,
  //         startTime: data.startTime,
  //         endTime: data.endTime,
  //         active: data.active !== false, // Default to true if not explicitly false
  //         showClock: data.showClock !== false,
  //         showMarquee: data.showMarquee !== false,
  //         orderOfAnnouncements: [announcementIdForNewClass.current],
  //       };
  
  //       const sampleAnnouncementToAdd = {
  //         html: "<p>This is a sample announcement.</p>",
  //         text: "This is a sample announcement.",
  //         active: true,
  //         show: true,
  //         startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
  //         endDate: "",
  //         lastEdited: formattedDate,
  //         dateCreated: formattedDate,
  //         classIds: [classIdForNewClass.current],
  //         id: announcementIdForNewClass.current,
  //       };
  
  //       if (!item) {
  //         // 🟢 Create new class document
  //         const classDocRef = doc(classesCollectionRef, classIdForNewClass.current);
  //         await setDoc(classDocRef, newClass);
  
  //         // 🟢 Add a default content box for the new class
  //         const contentBoxRef = doc(classDocRef, FIREBASE_COLLECTIONS.CONTENT_BOXES, createRandomId());
  //         await setDoc(contentBoxRef, {
  //           contentBoxId: contentBoxRef.id,
  //           heading: "Type here...",
  //           show: true,
  //           active: true,
  //         });
  
  //         // 🟢 Update announcements inside the user document
  //         await updateDoc(userDocRef, {
  //           announcements: arrayUnion(sampleAnnouncementToAdd),
  //         });
  //       } else {
  //         // 🟢 Update an existing class document
  //         const existingClassDocRef = doc(classesCollectionRef, data.classId);
  //         await setDoc(existingClassDocRef, data, { merge: true });
  //       }
  
  //       successAlert();

  //       // 🛑 Manually re-fetch user data after updating Firestore
  //       await dispatch(fetchUserData(user.uid));

  //     } catch (error) {
  //       console.error("Error updating document:", error);
  //     }
  //   } else {
  //     invalidEntryAlert();
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validated) {
      invalidEntryAlert();
      return;
    }

    try {
      const userDocRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
      const classesCollectionRef = collection(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES);

      // 🟢 Generate IDs for the class and default content box
      const newClassId = classIdForNewClass.current;
      const defaultContentBoxId = createRandomId();

      const newClass = {
        classId: newClassId,
        period: data.period,
        className: data.className,
        days: data.days,
        schedule: data.schedule,
        startTime: data.startTime,
        endTime: data.endTime,
        active: data.active !== false, // Default to true if not explicitly false
        showClock: data.showClock !== false,
        showMarquee: data.showMarquee !== false,
        orderOfAnnouncements: [announcementIdForNewClass.current],
        contentBoxesOrder: [defaultContentBoxId], // ✅ Add contentBoxesOrder
      };

      const sampleAnnouncementToAdd = {
        html: "<p>This is a sample announcement.</p>",
        text: "This is a sample announcement.",
        active: true,
        show: true,
        startDate: ["Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays"],
        endDate: "",
        lastEdited: formattedDate,
        dateCreated: formattedDate,
        classIds: [newClassId],
        id: announcementIdForNewClass.current,
      };

      if (!item) {
        // 🟢 Create new class document with the `contentBoxesOrder` field
        const classDocRef = doc(classesCollectionRef, newClassId);
        await setDoc(classDocRef, newClass);

        // 🟢 Create default content box within the class
        const contentBoxRef = doc(classDocRef, FIREBASE_COLLECTIONS.CONTENT_BOXES, defaultContentBoxId);
        await setDoc(contentBoxRef, {
          contentBoxId: defaultContentBoxId, // Ensure it matches Firestore document ID
          heading: "Type here...",
          show: true,
          active: true,
        });

        // 🟢 Update user's orderOfClasses list to include new classId
        await updateDoc(userDocRef, {
          orderOfClasses: arrayUnion(newClassId), // ✅ Ensures the class is added to the order list
          announcements: arrayUnion(sampleAnnouncementToAdd),
        });
      } else {
        // 🟢 Update an existing class document
        const existingClassDocRef = doc(classesCollectionRef, data.classId);
        await setDoc(existingClassDocRef, data, { merge: true });
      }

      successAlert();

      // 🛑 Manually re-fetch user data after updating Firestore
      await dispatch(fetchUserData(user.uid));

    } catch (error) {
      console.error("❌ Error updating document:", error);
    }
  };


  

  const potentialInvalidScheduleAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => (!data.active) ? classNotSetToActiveAlert(event) : handleSubmit(event)}
        onCancel={() => hidePotentialInvalidScheduleAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        It appears as though your scheduled start time for this class isn't between the hours of 7am and 5pm which isn't typical.
      </ReactBSAlert>
    );
  }

  const classNotSetToActiveAlert = (e) => {
    const event = e;
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={(e) => handleSubmit(event)}
        onCancel={() => hideClassNotSetToActiveAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger"
        cancelBtnStyle={{borderRadius: '30px', backgroundColor: '#fa5656'}}
        confirmBtnText="Continue with Save!"
        cancelBtnText="Let me check again."
        showCancel
        btnSize=""
      >
        You do not have the Active checkbox checked for this class which means it will not appear in your "Present Classes" dropdown.
      </ReactBSAlert>
    );
  }

  const invalidEntryAlert = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Invalid entry!"
        onConfirm={() => hideInvalidEntryAlert()}
        onCancel={() => hideInvalidEntryAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      />
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px", borderRadius: '15px', width: '500px', color: '#004aad' }}
        title="Class Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', width: '100px'}}
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hidePotentialInvalidScheduleAlert = () => {
    setAlert(null);
  }

  const hideClassNotSetToActiveAlert = () => {
    setAlert(null);
  }

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes")
  };

  const hideInvalidEntryAlert = () => {
    setAlert(null);
  };

  function handleFormNonDays(e) {
    const { id, value, type, checked } = e.target;

    // Check if the input element is a checkbox
    if (type === 'checkbox') {
      // Update the state based on the checkbox's checked property
      setData((prevData) => ({
        ...prevData,
        [id]: checked, // Update the corresponding property in the state
      }));
    } else {
      // For non-checkbox inputs, update the state based on the input's value
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  }

  const handleTimesForSingleTimeClasses = (e) => {
    const newTime = e.target.value;

    const id = e.target.id

    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));

    for (const weekKey in updatedSchedule) {
      if (updatedSchedule.hasOwnProperty(weekKey)) {
        const week = updatedSchedule[weekKey];
        for (const dayKey in week) {
          if (week.hasOwnProperty(dayKey)) {
            // Update the startTime property for each day
            week[dayKey][id] = newTime;
          }
        }
      }
    }

    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  }

  const handleScheduleChange = (week, day, field, value) => {

    // Create a copy of the schedule to avoid mutating the state directly
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    
    // Update the specified field in the schedule
    updatedSchedule[week][day][field] = value;
  
    // Set the updated schedule in your state or wherever you store it
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  return (
    <>
      <div className="content">
        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Col md="6">
            <Card style={{ padding: '20px' }}>
              <ModalHeader className="add-edit-form-close-toggle" toggle={() =>  history.goBack()} style={{border: 'none', padding: '0px'}}>
                <h4 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
                  {item === undefined ? "Add Class" : "Edit Class"}
                </h4>
              </ModalHeader>
              <CardBody>
                <Form
                  className="form-horizontal"
                  noValidate // Remove 'noValidate' if you want to use HTML5 validation as well
                  validated={validated.toString()}
                >
                  <Row>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold"}}>Class Name</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: 9th Grade Algebra"
                          type="text"
                          id="className"
                          value={data.className}
                          required // Add 'required' for HTML5 validation
                          valid={data.className?.length > 0 && data.className?.length <= 30} // Display valid feedback
                          invalid={data.className != null && data.className?.length === 0 || data.className?.length > 30 || /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className)}
                          style={{borderRadius: '8px'}}
                        />
                        {data.className?.length === 0 ? (
                          <FormFeedback invalid>Please provide a class name.</FormFeedback>
                        ) : data.className?.length > 30 ? (
                          <FormFeedback invalid>Class name is too long (max 30 characters).</FormFeedback>
                        ) : /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className) ? (
                          <FormFeedback invalid>{"Your class contains invalid characters. Please avoid using the following characters: \" # % & ' ( ) + , / : ; < = > ? @ [ \\ ] ^ ` { | }"}</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <label style={{ fontSize: '14px', fontWeight: "bold" }}>Period</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: 1st"
                          type="text"
                          id="period"
                          value={data.period}
                          required
                          valid={data.period?.length > 0 && data.period?.length <= 4} // Display valid feedback
                          invalid={data.period != null && data.period?.length === 0 || data.period?.length > 4} // Display invalid feedback
                          style={{borderRadius: '8px'}}
                        >
                        </Input>
                        <small id="periodHelpBlock" class="form-text text-muted">
                          Must be between 1-4 characters.
                        </small>
                        {data.period?.length === 0 ? (
                          <FormFeedback invalid>Please provide a 4 character or less period identifier.</FormFeedback>
                        ) : data.period?.length > 4 ? (
                          <FormFeedback invalid>Period identifier is too long (max 4 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <hr></hr>

                  <Row style={{marginTop: "10px", marginLeft: "10px", marginBottom: '30px', display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="showMarqueeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Marquee</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <UncontrolledTooltip
                        delay={0}
                        target={"showMarqueeTip"}
                        placement="top"
                      >
                        If checked, class will include a scrolling marquee you can use for announcements, etc.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="showMarquee" 
                          checked={data.showMarquee}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="showClockTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Clock</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"showClockTip"}
                        placement="top"
                      >
                        If checked, class will include a clock.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="showClock" 
                          checked={data.showClock}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="activeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Active</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"activeTip"}
                        placement="top"
                      >
                        If checked, class will appear in classes dropdown.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="active" 
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <hr></hr>



                {!differentTimes && 
                  <Row style={{marginTop: "30px", marginLeft: "10px", marginBottom: '30px', display:'flex', justifyContent: 'space-evenly'}}>
                  <Col>
                      <UncontrolledTooltip
                        delay={0}
                        target={"timeTip"}
                        placement="top"
                      >
                        Providing class times will allow Lesson Launchpad to auto navigate to your next class when it's time for it to start.
                      </UncontrolledTooltip>
                      <label id="timeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleTimesForSingleTimeClasses(e)} type="time" id="startTime" value={data.schedule.A.Monday.startTime} style={{borderRadius: '8px'}}/>
                      </FormGroup>
                    </Col>
                    <UncontrolledTooltip
                        delay={0}
                        target={"timeTip"}
                        placement="top"
                      >
                        Providing class times will allow Lesson Launchpad to auto navigate to your next class when it's time for it to start.
                      </UncontrolledTooltip>
                    <Col>
                      <label id="timeTip" style={{ fontSize: '14px', fontWeight: "bold" }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleTimesForSingleTimeClasses(e)} type="time" id="endTime" value={data.schedule.A.Monday.endTime} style={{borderRadius: '8px'}}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  }

                  <hr></hr>

                  <ScheduleManager 
                    data={data} 
                    handleScheduleChange={handleScheduleChange} 
                    user={user} 
                  />

                  <Row style={{marginTop: "30px", display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                    <NavLink to="/settings" style={{ color: 'black' }}>
                      Need different scheduling options?
                    </NavLink>
                  </Row>

                </Form>
              </CardBody>
              <CardFooter style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                <Button 
                  className="btn-round button-hover-fix"
                  color="info"
                  type="submit"
                  onClick={(e) => (isScheduleNormal(data.schedule) && data.active) ? handleSubmit(e) : isScheduleNormal(data.schedule) === false ? potentialInvalidScheduleAlert(e) : !data.duration ? classNotSetToActiveAlert(e) : null}
                  style={{
                    padding: "10px 30px",
                    borderRadius: "30px",
                    fontSize: "1.1rem",
                    fontWeight: "bold",
                  }}>
                    <i className='fa fa-save' />
                    {' '}Save                
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddEditClassForm;

import { Card, CardBody, Row, Col, Navbar, NavbarBrand, Carousel, Container, CardImgOverlay, CardText, CardTitle,
    NavItem, UncontrolledAccordion, AccordionItem,
     AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader, CardFooter, CardImg } from "reactstrap";
import { NavLink } from 'react-router-dom';
import logoWithText from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-with-text.png";
import { Link } from 'react-router-dom';

const LandingPageTopNav = ({showPricingLink, scrollToPricing}) => {

    const isMobile = /Mobi/.test(navigator.userAgent);

    return (
        !isMobile ? (
            <Row style={{justifyContent: 'center', backgroundColor: "white"}}>
                <Card className='border' style={{width: '80%', marginTop: '1%'}}>
                    <Navbar className='landing-navbar'>
                        <NavbarBrand>
                            <a href={"https://www.lessonlaunchpad.com"}>
                            <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '175px', height: 'auto' }}/>
                            </a>
                        </NavbarBrand>
                        
                        <div className="mr-auto d-flex align-items-left">
                            <NavLink to="/blog/why-lesson-launchpad" style={{ color: 'black', marginLeft: '50px' }}>
                                Why Lesson Launchpad?
                            </NavLink>

                            {showPricingLink && 
                                <NavLink to="#" onClick={scrollToPricing} style={{ color: 'black', marginLeft: '50px' }}>
                                    Pricing
                                </NavLink>
                            }
                        </div>
                        <div className="ml-auto d-flex align-items-right">
                            {/* <NavLink to="/auth/login" style={{ color: 'black', marginRight: '50px' }}>
                            Login
                            </NavLink> */}

                            <Button tag={Link} to="/auth/login" 
                                className="button-inverted"
                                style={{
                                    marginLeft: '10px',
                                }}
                            >
                                
                            Log In
                            </Button>
    
                            <Button className="button-fixed" tag={Link} to="/auth/register" style={{ marginLeft: '10px', borderRadius: '8px' }}>
                            Try it now
                            </Button>
                        </div>
                    </Navbar>
                </Card>
            </Row>
        ) : (
            <Row style={{justifyContent: 'center', backgroundColor: "white"}}>
                <Card style={{width: '90%', marginTop: '2%', height: '175px'}}>
                    <Navbar className='landing-navbar'>
                        <NavbarBrand>
                            <a href={"https://www.lessonlaunchpad.com"}>
                            <img src={logoWithText} alt="lesson-launchpad-logo" style={{ width: '400px', height: 'auto' }}/>
                            </a>
                        </NavbarBrand>
                        
                        <div className="ml-auto d-flex align-items-center">
                            {showPricingLink && 
                                <NavLink to="#" onClick={scrollToPricing} style={{ color: 'black', marginRight: '70px', fontSize: '50px'  }}>
                                    Pricing
                                </NavLink>
                            }

                            <NavLink to="/auth/login" style={{ color: 'black', marginRight: '70px', fontSize: '50px' }}>
                            Login
                            </NavLink>

                            <Button tag={Link} to="/auth/register" style={{ marginLeft: '10px', height: '100px', fontSize: '60px' }}>
                            Register
                            </Button>
                        </div>
                    </Navbar>
                </Card>
            </Row>
        )
    )

}

export default LandingPageTopNav

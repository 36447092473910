import React, { useState, useEffect } from "react";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { Row, Col } from "reactstrap";
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/lesson-launchpad.svg";
import ReactBSAlert from "react-bootstrap-sweetalert";

function LogInView() {
    const [alert, setAlert] = useState(null);
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(orientation: portrait)").matches;
    
    const hideAlert = () => {
        setAlert(null);
      };
    
      // Function to show an alert if the user is on a mobile device
    function showAlertForMobile() {
    if (isMobileDevice) {
            setAlert(
                <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you on a mobile device?"
                onConfirm={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Close"
                btnSize=""
                >
                We think first impressions are important and Lesson Launchpad is designed for use on a desktop computer in the landscape orientation.
                Feel free to continue, just know that it really does look a lot better than this. We promise. :)
                </ReactBSAlert>
            );
        };
    }
    

    // Call the showAlertForMobile function when the component mounts
    useEffect(() => {
    showAlertForMobile();
    }, []);

    const isMobile = /Mobi/.test(navigator.userAgent);

    return(
        <>
        { !isMobile ? (
        <div style={{ height: '100vh', backgroundColor: 'white' }}>
            {alert}
            <Row style={{ height: '100%' }}>
                <Col md="1"></Col>
                <Col md="4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <img src={logo} alt="lesson-launchpad-logo" style={{ width: '400px', height: 'auto' }}/>
                    </div>
                </Col>
                <Col md="6">
                    <div id="login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginLeft: '15%', marginRight: '15%' }}>
                        <Login />
                    </div>
                </Col>
                <Col md="1"></Col>
            </Row>
        </div>
        ) : (
        <div style={{ height: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center'}}>
            {alert}
            <div style={{ textAlign: 'center' }}>
                <div>
                    <img src={logo} alt="lesson-launchpad-logo" style={{ width: '1000px', height: 'auto' }}/>
                </div>
                <div id="login">
                    <Login />
                </div>
            </div>
        </div>
        )}
        </>
    )
}

export default LogInView;
import {React, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ContentBoxesTable from "/Users/michaeltucker/Documents/coding-projects/client/src/views/tables/ContentBoxesTable.js";
import RosterTable from "components/RosterTable";
import Scheduling from "/Users/michaeltucker/Documents/coding-projects/client/src/utils/Scheduling.js"
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import { deleteDoc, doc, collection, getDocs, updateDoc, arrayRemove, writeBatch, getDoc } from "firebase/firestore";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  CardSubtitle,
  Badge
} from "reactstrap";
import ActiveDatesDatePicker from "components/ActiveDatesDatePicker";

function ClassesTable({props}) {

  const [alert, setAlert] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const classes = useSelector((state) => state.user.classes);
  const [storageModal, setStorageModal] = useState(false);
  const [rosterModal, setRosterModal] = useState(false);
  const [classId, setClassId] = useState();
  const multiWeek = user.settings?.multiWeek || false;
  const numberOfWeeks = user.settings?.numberOfWeeks || 2;
  const cycleType = user.settings?.cycleType || "week";
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      dayName: `${Scheduling.daysLong[dateObject.getDay()]}`,
      dateString: `${Scheduling.monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${Scheduling.suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });

  const handleDateForward = () => {
    let dateObject = new Date(date.year, date.month, date.day)
    let nextDay = new Date(dateObject.getTime() + (24 * 60 * 60 * 1000)); // Adds one day
    let dateData = {
      dayName: `${Scheduling.daysLong[nextDay.getDay()]}`,
      dateString: `${Scheduling.monthsLong[nextDay.getMonth()]} ${nextDay.getDate()}${Scheduling.suffix[nextDay.getDate()]}, ${nextDay.getFullYear()}`,
      year: nextDay.getFullYear(),
      month: nextDay.getMonth(),
      day: nextDay.getDate(),
      databaseFormattedDate: nextDay.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  const handleDateBackward = () => {
    let dateObject = new Date(date.year, date.month, date.day)
    let previousDay = new Date(dateObject.getTime() - (24 * 60 * 60 * 1000)); // Adds one day
    let dateData = {
      dayName: `${Scheduling.daysLong[previousDay.getDay()]}`,
      dateString: `${Scheduling.monthsLong[previousDay.getMonth()]} ${previousDay.getDate()}${Scheduling.suffix[previousDay.getDate()]}, ${previousDay.getFullYear()}`,
      year: previousDay.getFullYear(),
      month: previousDay.getMonth(),
      day: previousDay.getDate(),
      databaseFormattedDate: previousDay.toLocaleDateString('en-CA')
    }
    setDate(dateData)
  }

  const handleDateSet = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${Scheduling.daysLong[dateObject.getDay()]}`,
      dateString: `${Scheduling.monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePicker(!showDatePicker);
  }

  const handleDateSetContentBoxStorage = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${Scheduling.daysLong[dateObject.getDay()]}`,
      dateString: `${Scheduling.monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${Scheduling.suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePicker(!showDatePicker);
  }

  const warningWithConfirmAndCancelMessage = (classId) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => confirmDeleteAlert(classId)}
        onCancel={() => cancelDelete()}
        confirmBtnBsStyle="info delete-button"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        cancelBtnBsStyle="danger button-hover-fix"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        cancelBtnStyle={{borderRadius: '30px'}}
        showCancel
        btnSize=""
      >
        You will not be able to recover this class or the content it contains!
      </ReactBSAlert>
    );
  };

  const confirmDeleteAlert = (classId) => {
    const classObj = classes.find(x => x.classId === classId);
    const className = classObj ? classObj.className : "Unknown Class";
  
    setAlert(
      <ReactBSAlert
        input
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        onConfirm={(e) => deleteClass(e, classId)}
        onCancel={hideAlert}
        confirmBtnBsStyle="info delete-button"
        confirmBtnStyle={{borderRadius: '30px'}}
        confirmBtnText="Delete"
        cancelBtnBsStyle="danger button-hover-fix"
        cancelBtnStyle={{borderRadius: '30px'}}
        btnSize=""
      >
        <div style={{ textAlign: "center" }}>
          <strong style={{ color: "red" }}>
            ⚠️ This action is <u>not reversible</u>! ⚠️
          </strong>
          <br />
          <br />
          Type the class name below to confirm deletion:
          <br />
          <span style={{ fontWeight: "bold", fontSize: "1.1rem", color: "#007bff" }}>
            {className}
          </span>
        </div>
      </ReactBSAlert>
    );
  };
  

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)'}}
        btnSize=""
      >
        Your class has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info button-hover-fix"
        confirmBtnStyle={{borderRadius: '30px', backgroundColor: 'rgb(11, 192, 223)', width: '100px'}}
        btnSize=""
      >
        Your class is safe :)
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  // const deleteClass = async (classId) => {
  //   // console.log(classId)
  //   try {
  //     const docRef = doc(db, "users", user.uid);
  
  //     const userCopy = JSON.parse(JSON.stringify(user));

  //     const classIndex = userCopy.classes.findIndex(
  //       (c) => c.classId === classId
  //     );
  
  //     // Remove the class at the specified index
  //     userCopy.classes.splice(classIndex, 1);
  //     console.log(userCopy)
  
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //     successDelete()
  
  //     console.log('Content box deleted successfully!');
  //   } catch (error) {
  //     console.error('Error deleting content box:', error);
  //   }
  // }

  const deleteClass = async (e, classId) => {
    if (!user?.uid || !classId) {
      console.error("🚨 Missing required parameters: User ID or Class ID.");
      return;
    }
  
    const classObj = classes.find(x => x.classId === classId);
    if (classObj.className !== e) {
      window.alert("Class name entered did not match. Cancelling delete.");
      hideAlert();
      return;
    }
  
    try {
      // ✅ Reference the user document
      const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
  
      // ✅ Reference the class document in the classes subcollection
      const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classId);
  
      // ✅ Reference the contentBoxes sub-collection
      const contentBoxesRef = collection(classRef, FIREBASE_COLLECTIONS.CONTENT_BOXES);
      
      // ✅ Fetch all contentBox documents
      const contentBoxesSnapshot = await getDocs(contentBoxesRef);
  
      if (!contentBoxesSnapshot.empty) {
        const batch = writeBatch(db);
  
        contentBoxesSnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
  
        // ✅ Commit batch delete for contentBoxes
        await batch.commit();
        console.log(`✅ Deleted all contentBoxes for class ${classId}.`);
      }
  
      // ✅ Delete the class document from Firestore
      await deleteDoc(classRef);
      console.log(`✅ Class ${classId} deleted successfully!`);
  
      // ✅ Remove the classId from orderOfClasses
      await updateDoc(userRef, {
        orderOfClasses: arrayRemove(classId)
      });
  
      console.log(`✅ Removed ${classId} from orderOfClasses in user document.`);
  
      // ✅ Handle announcements stored inside the user document
      const userSnap = await getDoc(userRef);
      if (userSnap.exists()) {
        const userData = userSnap.data();
        let announcements = userData.announcements || [];
  
        let updatedAnnouncements = announcements
          .map((announcement) => {
            if (announcement.classIds.includes(classId)) {
              const updatedClassIds = announcement.classIds.filter(id => id !== classId);
              return updatedClassIds.length > 0 
                ? { ...announcement, classIds: updatedClassIds } // ✅ Keep if other classes remain
                : null; // ✅ Mark for deletion if empty
            }
            return announcement;
          })
          .filter(Boolean); // ✅ Remove null values (deleted announcements)
  
        // ✅ Update the announcements array in Firestore
        await updateDoc(userRef, { announcements: updatedAnnouncements });
        console.log("✅ Announcements updated after class deletion.");
      }
  
      // ✅ Optionally trigger UI update or state refresh
      successDelete();
  
      window.location.reload();
    } catch (error) {
      console.error("❌ Error deleting class:", error);
    }
  };

  // const handleMoveUp = (index) => {
  //   if (index > 0) {
  //     // Swap the current item with the one above it
  //     const updatedClasses = [...props];
  //     [updatedClasses[index], updatedClasses[index - 1]] = [
  //       updatedClasses[index - 1],
  //       updatedClasses[index],
  //     ];
  //     updateClasses(updatedClasses)
  //   }
  // };

  // const handleMoveDown = (index) => {
  //   if (index < props.length - 1) {
  //     // Swap the current item with the one below it
  //     const updatedClasses = [...props];
  //     [updatedClasses[index], updatedClasses[index + 1]] = [
  //       updatedClasses[index + 1],
  //       updatedClasses[index],
  //     ];
  //     updateClasses(updatedClasses)
  //   }
  // };

  const handleMoveUp = async (index) => {
    if (index > 0) {
        try {
            const updatedOrder = [...user.orderOfClasses];

            // Swap the classId at the current index with the one above it
            [updatedOrder[index], updatedOrder[index - 1]] = [
                updatedOrder[index - 1],
                updatedOrder[index]
            ];

            // ✅ Update Firestore
            const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
            await updateDoc(userRef, { orderOfClasses: updatedOrder });

            console.log("✅ Updated class order:", updatedOrder);

            dispatch(fetchUserData(user.uid))
        } catch (error) {
            console.error("❌ Error updating class order:", error);
        }
    }
};

const handleMoveDown = async (index) => {
    if (index < user.orderOfClasses.length - 1) {
        try {
            const updatedOrder = [...user.orderOfClasses];

            // Swap the classId at the current index with the one below it
            [updatedOrder[index], updatedOrder[index + 1]] = [
                updatedOrder[index + 1],
                updatedOrder[index]
            ];

            // ✅ Update Firestore
            const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);
            await updateDoc(userRef, { orderOfClasses: updatedOrder });

            console.log("✅ Updated class order:", updatedOrder);

            dispatch(fetchUserData(user.uid))
        } catch (error) {
            console.error("❌ Error updating class order:", error);
        }
    }
};


  // const updateClasses = async (updatedClasses) => {
  //   try {
  //     const docRef = doc(db, "users", user.uid);

  //     const userCopy = JSON.parse(JSON.stringify(user));
  
  //     userCopy.classes = updatedClasses
  //     // Update the document in Firestore
  //     await setDoc(docRef, userCopy);

  //     // console.log("saved")
  //   } catch (error) {
  //     alert('Error updating document:', error);
  //   }
  // }

  const updateClasses = async (updatedClasses) => {
    if (!user?.uid || !updatedClasses || !Array.isArray(updatedClasses)) {
        console.error("🚨 Missing required parameters: User ID or valid class data.");
        return;
    }

    try {
        const batch = writeBatch(db); // ✅ Use batch for multiple updates

        updatedClasses.forEach((classData) => {
            if (!classData.classId) {
                console.error("⚠️ Skipping class update due to missing classId.");
                return;
            }

            // ✅ Reference the class document in the `classes` subcollection
            const classRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid, FIREBASE_COLLECTIONS.CLASSES, classData.classId);

            // ✅ Add update operation to batch
            batch.set(classRef, classData, { merge: true }); // Merging ensures existing fields aren't deleted
        });

        // ✅ Commit all updates in one go
        await batch.commit();

        console.log("✅ All classes updated successfully!");

    } catch (error) {
        console.error("❌ Error updating classes:", error);
        alert("Error updating document: " + error.message);
    }
  };


  const toggleStorageModal = (classId) => {
    hideAlert()
    setClassId(classId)
    setStorageModal(!storageModal)
  }

  const toggleRosterModal = (classId) => {
    hideAlert()
    setClassId(classId)
    setRosterModal(!rosterModal)
  }

  return (
    <>
      <div className="content">


        <Modal
          modalClassName="content-boxes-table"
          contentClassName={user.settings.useActiveDates ? "content-box-storage-table-active-dates" : "content-box-storage-table"} 
          isOpen={storageModal} 
          toggle={toggleStorageModal}
        >
          <ModalHeader toggle={toggleStorageModal} style={{padding: '10px'}}>
            <Row id="present-navbar-datepicker-section" style={{display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
            {user.settings.useActiveDates ? (
              <div className="contentBoxTableDatePicker">
                <ActiveDatesDatePicker
                  date={date}
                  showDatePicker={showDatePicker}
                  setShowDatePicker={setShowDatePicker}
                  handleDateSet={handleDateSet}
                  handleDateBackward={handleDateBackward}
                  handleDateForward={handleDateForward}
                  noActiveBoxesAlert={null}
                  noBoxesSetToShowAlert={null}
                />
              </div>
            )
            : null }
            </Row>
          </ModalHeader>
          <ModalBody style={{padding: '10px 10px'}} >
            <ContentBoxesTable props={{current: classId}} date={date}/>
          </ModalBody>
        </Modal>


        <Modal contentClassName="content-box-storage-table" isOpen={rosterModal} toggle={toggleRosterModal} scrollable>
          <ModalHeader toggle={toggleRosterModal}/>
          <ModalBody className="agenda-body" style={{paddingTop: '0px'}}>
            <RosterTable classId={classId} classes={classes}/>
          </ModalBody>
        </Modal>


                <Row>
                    <Col>
                      <Card>
                        <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Classes</h2>
                        </CardHeader>
                        <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                          The Classes Table holds the basic information for each of your classes. By entering a Start Time, Lesson Launchpad will automatically navigate to your class at the time you've selected.
                            By unchecking the Active box, a class will no longer appear in the Present Classes dropdown. You can also uncheck the Marquee and Clock boxes to hide those if you do not wish to use them.
                        </CardSubtitle>
                        <CardBody>
                        {props.length === 0 ? (
                          <div style={{ 
                            textAlign: "center", 
                            padding: "20px", 
                            fontSize: "1.5em", 
                            fontWeight: "bold", 
                            color: "#fa5656" 
                          }}>
                            You have no classes!
                            <br />
                            Click the <strong>"Add Class"</strong> button to get started.
                            <br />
                          </div>
                        ) : (
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="class-table-Period" style={{width: '4%'}}>Period</th>
                                <th className="text-center" id="class-table-Name" style={{width: '16%'}}>Name</th>
                                <th className="text-center" id="class-table-Days" style={{width: '15%'}}>Class Days</th>
                                <th className="text-center" id="class-table-03984512" style={{width: '9%'}}>Start Time</th>
                                {/* <th className="text-center" id="Zebra" style={{width: '9%'}}>End Time</th> */}
                                <th className="text-center" id="class-table-Roster" style={{width: '9%'}}>Roster</th>
                                <th className="text-center" id="class-table-Announcements" style={{width: '7%'}}>Announcements</th>
                                <th className="text-center" id="class-table-Boxes" style={{width: '5%'}}>Boxes</th>
                                <th className="text-center" id="class-table-Marquee" style={{width: '5%'}}>Marquee</th>
                                <th className="text-center" id="class-table-Clock" style={{width: '5%'}}>Clock</th>
                                <th className="text-center" id="class-table-Giraffe" style={{width: '5%'}}>Active</th>
                                <th className="text-center" id="class-table-Order" style={{width: '8%'}}>Order</th>
                                <th className="text-center" style={{ width: '8%', paddingBottom: '0px' }}>
                                  Actions
                                  <div style={{ marginTop: '0px', fontSize: '0.7em', color: '#666', display: 'flex', justifyContent: 'center', gap: '15px' }}>
                                    <span>Edit</span>
                                    <span>Delete</span>
                                  </div>
                                </th>
                              </tr>
                              
                              <UncontrolledTooltip delay={0} target={"class-table-03984512"}>
                                  Lesson Launchpad will automatically switch to this class at this time.
                              </UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Period"}>The period or other identifier you've chosen for your class.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Days"}>The days of the week that this class meets.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Name"}>The name you've chosen for your class.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Marquee"}>If checked, Marquee will be visible.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Clock"}>If checked, Clock will be visible.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Giraffe"}>If checked, Class will be available in the Present Classes dropdown.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"class-table-Boxes"}>Click the icon to view a list of your content boxes for this class.</UncontrolledTooltip>
                            </thead>
                            {props && props.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center" style={{ textTransform: 'uppercase' }}>{item.period}</td>
                                <td className="text-center" style={{ textTransform: 'capitalize' }}>{item.className}</td>
                                <td className={multiWeek && cycleType === "week" ? "text-left" : "text-center"}>
                                {multiWeek && cycleType === "day" ? (
                                  <div>
                                    {Scheduling.getWeeksWithActiveMonday(item.schedule, user)}
                                  </div>
                                ) : (
                                  <>
                                    {!multiWeek && item.schedule?.A ? (
                                      <div>
                                        {Scheduling.dayOrder
                                          .filter(day => 
                                            item.schedule.A[Object.keys(Scheduling.dayMapping).find(key => Scheduling.dayMapping[key] === day)]?.active
                                          )
                                          .join(', ') || "--"}
                                      </div>
                                    ) : null}

                                    {multiWeek &&
                                      Scheduling.weeksOrder(numberOfWeeks).map((week) => (
                                        <div key={week}>
                                          <strong>
                                            {(cycleType ?? "week") === "week" ? "Week" : "Day"} {week}:
                                          </strong>{" "}
                                          {Scheduling.dayOrder
                                            .filter((day) =>
                                              item.schedule?.[week]?.[Object.keys(Scheduling.dayMapping).find((key) => Scheduling.dayMapping[key] === day)]?.active
                                            )
                                            .join(', ') || "--"}
                                        </div>
                                      ))}
                                  </>
                                )}
                                </td>
                                <td className="text-center">{Scheduling.listActiveStartTimes(item.schedule, user)}</td>
                                {/* <td className="text-center">{listActiveEndTimes(item.schedule)}</td> */}
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id={`viewEditClassRosterButtonToolTip${item.classId}`}
                                    size="md"
                                    type="button"
                                    onClick={() => toggleRosterModal(item.classId)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-users" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target={`viewEditClassRosterButtonToolTip${item.classId}`}
                                  >
                                    View/Edit Class Roster
                                  </UncontrolledTooltip> */}
                                </td>
                                <td className="text-center">
                                  <NavLink 
                                    to={{
                                      pathname: '/announcement-form',
                                      state: { classId: item.classId }
                                    }}
                                    style={{ textDecoration: 'none', cursor: 'text' }}
                                  >
                                    <Button
                                      className="btn-icon btn-neutral"
                                      color="info"
                                      id={`viewAnnouncementsButtonToolTip${item.classId}`}
                                      size="md"
                                      type="button"
                                      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                    >
                                      <i className="fa fa-bullhorn" />
                                    </Button>
                                  </NavLink>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target={`viewAnnouncementsButtonToolTip${item.classId}`}
                                  >
                                    View Announcements
                                  </UncontrolledTooltip> */}
                                </td>
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id={`viewContentBoxesButtonToolTip${item.classId}`}
                                    size="md"
                                    type="button"
                                    onClick={() => toggleStorageModal(item.classId)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="nc-icon nc-app" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target={`viewContentBoxesButtonToolTip${item.classId}`}
                                  >
                                    View Content Boxes
                                  </UncontrolledTooltip> */}
                                </td>
                                <td className="text-center">{item.showMarquee ? <Badge className="button-success">On</Badge> : <Badge className="button-delete">Off</Badge>}</td>
                                <td className="text-center">{item.showClock ? <Badge className="button-success">On</Badge> : <Badge className="button-delete">Off</Badge>}</td>
                                <td className="text-center">{item.active ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}</td>
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="class-table-tooltip159182282"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveUp(index)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="class-table-tooltip159185983"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveDown(index)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                <NavLink to={{ pathname: "/form", state: { item } }}>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="success"
                                    id={`editClassButtonToolTip${item.classId}`}
                                    size="md"
                                    type="button"
                                    style={{ marginRight: "7px" }}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-edit icon-edit" />
                                  </Button>
                                </NavLink>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target={`editClassButtonToolTip${item.classId}`}
                                  >
                                    Edit
                                  </UncontrolledTooltip> */}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id={`deleteClassButtonToolTip${item.classId}`}
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.classId)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target={`deleteClassButtonToolTip${item.classId}`}
                                  >
                                    Delete
                                  </UncontrolledTooltip> */}
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                        )}
                          {alert}
                          <NavLink to={{pathname: "/form"}} style={{color: 'white'}}>
                            <Button
                              className="button-hover-fix"
                              style={{
                                padding: "10px 20px",
                                borderRadius: "20px",
                                fontSize: ".9rem",
                                fontWeight: "bold",
                                width: '185px'
                              }}
                            >
                            <i className='fa fa-plus' />
                            {' '}Add Class
                            </Button>
                          </NavLink>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default ClassesTable;
import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { setDoc, doc, getDoc, updateDoc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Scheduling from "/Users/michaeltucker/Documents/coding-projects/client/src/utils/Scheduling.js"
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  CardSubtitle,
  Badge
} from "reactstrap";

function AutoCountdownTable() {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const dispatch = useDispatch()
    const multiWeek = user.settings?.multiWeek || false;
    const numberOfWeeks = user.settings?.numberOfWeeks || 2;
    const cycleType = user.settings?.cycleType || "week";
    const differentTimes = user.settings.differentTimes || false;

    const warningWithConfirmAndCancelMessage = (index) => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteCountdownTimer(index)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this timer!
        </ReactBSAlert>
      );
    };
  
    const successDelete = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your timer has been deleted.
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your timer is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    // const deleteCountdownTimer = async (index) => {
    //   try {
    //     const docRef = doc(db, "users", user.uid);
    
    //     const userCopy = JSON.parse(JSON.stringify(user));
    
    //     // Remove the class at the specified index
    //     userCopy.countdownTimers.splice(index, 1);
    
    //     // Update the document in Firestore
    //     await setDoc(docRef, userCopy);
  
    //     successDelete()
    
    //     console.log('Countdown Timer deleted successfully!');
    //   } catch (error) {
    //     console.error('Error deleting content box:', error);
    //   }
    // }

  const deleteCountdownTimer = async (index) => {
    try {
        if (!user?.uid) {
            console.error("❌ User ID is missing.");
            return;
        }

        // 🔹 Reference the user document in Firestore
        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

        // 🔹 Fetch the current user document
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("❌ User document not found.");
            return;
        }

        // 🔹 Get current countdown timers (ensure it exists)
        const userData = userSnap.data();
        const countdownTimers = userData.countdownTimers || [];

        if (index < 0 || index >= countdownTimers.length) {
            console.error("❌ Invalid index for countdown timer deletion.");
            return;
        }

        // 🔹 Remove the countdown timer at the given index
        countdownTimers.splice(index, 1);

        // 🔹 Update Firestore document with the modified countdownTimers array
        await updateDoc(userRef, { countdownTimers });

        // 🛑 Manually re-fetch user data after updating Firestore
        await dispatch(fetchUserData(user.uid));

        successDelete();
        console.log("✅ Countdown Timer deleted successfully!");
    } catch (error) {
        console.error("❌ Error deleting countdown timer:", error);
    }
  };


    // const duplicateCountdownTimer = async (index) => {
    //   try {
    //     const docRef = doc(db, "users", user.uid);
    
    //     const userCopy = JSON.parse(JSON.stringify(user));
    
    //     // Make a copy of the selected countdown
    //     const countdownCopy = {...userCopy.countdownTimers[index]};

    //     // Add copy to the countdownTimers array
    //     userCopy.countdownTimers.push(countdownCopy);

    //     // Update the document in Firestore
    //     await setDoc(docRef, userCopy);
      
    //   } catch (error) {
    //     console.error('Error deleting content box:', error);
    //   }
    // }

  const duplicateCountdownTimer = async (index) => {
    try {
        if (!user?.uid) {
            console.error("❌ User ID is missing.");
            return;
        }

        // 🔹 Reference the user document in Firestore
        const userRef = doc(db, FIREBASE_COLLECTIONS.USERS, user.uid);

        // 🔹 Fetch the current user document
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
            console.error("❌ User document not found.");
            return;
        }

        // 🔹 Get current countdown timers (ensure it exists)
        const userData = userSnap.data();
        const countdownTimers = userData.countdownTimers || [];

        if (index < 0 || index >= countdownTimers.length) {
            console.error("❌ Invalid index for countdown timer duplication.");
            return;
        }

        // 🔹 Make a copy of the selected countdown (no extra ID)
        const countdownCopy = { ...countdownTimers[index] };

        // 🔹 Add copy to the countdownTimers array
        const updatedCountdownTimers = [...countdownTimers, countdownCopy];

        // 🔹 Update Firestore document with the modified countdownTimers array
        await updateDoc(userRef, { countdownTimers: updatedCountdownTimers });

        console.log("✅ Countdown Timer duplicated successfully!");

        // 🛑 Manually re-fetch user data after updating Firestore
        await dispatch(fetchUserData(user.uid));

    } catch (error) {
        console.error("❌ Error duplicating countdown timer:", error);
    }
  };

  return (
    <>
      <div className="content">
                <Row>
                    <Col>
                      <Card>
                        <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                          <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Countdown Timers</h2>
                        </CardHeader>
                        <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                          Countdown Timers can be used to trigger timers at specific times. The most common use is the "You must be in your seat by X minutes after the bell" alarm. Lesson Launchpad will automatically launch your timer at the time you request.
                        </CardSubtitle>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="countdown-table-lakjsdfadsf" style={{width: '8%'}}>Start Time</th>
                                <th className="text-center" id="countdown-table-ubqweoivafa" style={{width: '8%'}}>Duration</th>
                                <th className="text-center" id="countdown-table-opoanealkjd" style={{width: '15%'}}>Days Active</th>
                                <th className="text-center" id="countdown-table-yqweribasdf" style={{width: '15%'}}>Header Message</th>
                                <th className="text-center" id="countdown-table-qlkabadsfkd" style={{width: '15%'}}>Alert Message</th>
                                <th className="text-center" id="countdown-table-pldjabakela" style={{width: '12%'}}>Alert Sound</th>
                                <th className="text-center" id="countdown-table-pasdfaskedf" style={{width: '7%'}}>Background</th>
                                <th className="text-center" id="countdown-table-basdfitjdba" style={{width: '7%'}}>Active</th>
                                <th className="text-center" style={{ width: '15%', paddingBottom: '0px' }}>
                                  Actions
                                  <div style={{ marginTop: '0px', fontSize: '0.6em', color: '#666', display: 'flex', justifyContent: 'center', gap: '12px' }}>
                                    <span>Edit</span>
                                    <span>Duplicate</span>
                                    <span>Delete</span>
                                  </div>
                                </th>
                              </tr>
                              {user.countdownTimers && user.countdownTimers.length > 0 && (
                                <>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-lakjsdfadsf"}>This is the time at which your timer will start.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-ubqweoivafa"}>Your timer will start with this many seconds and countdown from there.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-opoanealkjd"}>Your timer will be active on these days only.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-yqweribasdf"}>This is the message that will display at the top of your timer.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-qlkabadsfkd"}>If checked, a message of your choice will show upon countdown completion.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-pldjabakela"}>If checked, a sound of your choice will play upon countdown completion.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-pasdfaskedf"}>If checked, the background behind the timer will be darker.</UncontrolledTooltip>
                                  <UncontrolledTooltip delay={0} target={"countdown-table-basdfitjdba"}>If checked, this countdown timer will trigger at the requested time.</UncontrolledTooltip>
                                </>
                              )}
                            </thead>
                            {user && user.countdownTimers && user.countdownTimers.map((countdown, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center" >{!differentTimes ? Scheduling.convertTo12HourFormat(countdown.startTime) : Scheduling.listActiveStartTimes(countdown.schedule, user)}</td>
                                <td className="text-center" >{countdown.duration} seconds</td>
                                <td className={multiWeek && cycleType === "week" ? "text-left" : "text-center"}>
                                {multiWeek && cycleType === "day" ? (
                                  <div>
                                    {Scheduling.getWeeksWithActiveMonday(countdown.schedule, user)}
                                  </div>
                                ) : (
                                  <>
                                    {!multiWeek && countdown.schedule?.A ? (
                                      <div>
                                        {Scheduling.dayOrder
                                          .filter(day => 
                                            countdown.schedule.A[Object.keys(Scheduling.dayMapping).find(key => Scheduling.dayMapping[key] === day)]?.active
                                          )
                                          .join(', ') || "--"}
                                      </div>
                                    ) : null}

                                    {multiWeek &&
                                      Scheduling.weeksOrder(numberOfWeeks).map((week) => (
                                        <div key={week}>
                                          <strong>
                                            {(cycleType ?? "week") === "week" ? "Week" : "Day"} {week}:
                                          </strong>{" "}
                                          {Scheduling.dayOrder
                                            .filter((day) =>
                                            countdown.schedule?.[week]?.[Object.keys(Scheduling.dayMapping).find((key) => Scheduling.dayMapping[key] === day)]?.active
                                            )
                                            .join(', ') || "--"}
                                        </div>
                                      ))}
                                  </>
                                )}
                                </td>
                                <td className="text-center">{`${countdown.header}` || "--"}</td>
                                <td className="text-center">{`${countdown.alertMessage}` || "--"}</td>
                                <td className="text-center">{countdown.youtube ? countdown.youtubeSrc : countdown.alertSoundName}</td>
                                <td className="text-center">{countdown.backdrop ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}</td>
                                <td className="text-center">{countdown.active ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}</td>
                                <td className="text-center">
                                <NavLink to={{ pathname: "/countdown-form", state: { countdown, index } }}>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="countdown-table-tooltip159182735"
                                    size="md"
                                    type="button"
                                    style={{ marginRight: "10px" }}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-edit icon-edit" />
                                  </Button>
                                </NavLink>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip> */}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="countdown-table-tooltip80495849302"
                                    size="md"
                                    type="button"
                                    style={{marginRight: '10px'}}
                                    onClick={() => duplicateCountdownTimer(index)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-copy" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip80495849302"
                                  >
                                    Duplicate
                                  </UncontrolledTooltip> */}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="countdown-table-tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(index)}
                                    onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                                    onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                                  >
                                    <i className="fa fa-trash icon-delete" />
                                  </Button>
                                  {/* <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip> */}
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                          <NavLink to={{pathname: "/countdown-form"}} style={{color: 'white'}}>
                            <Button
                            className="button-hover-fix"
                              style={{
                                padding: "10px 10px",
                                borderRadius: "20px",
                                fontSize: ".9rem",
                                fontWeight: "bold",
                                width: '190px'
                              }}
                            >
                              <i className='fa fa-plus' />
                            {' '}Add Countdown
                            </Button>
                          </NavLink>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default AutoCountdownTable;
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { Modal, ModalBody, Button } from "reactstrap";
import CountdownTimer from 'components/CountdownTimer.js';
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import usePersistentTimer from "/Users/michaeltucker/Documents/coding-projects/client/src/workers/usePersistentTimer.js";

const UseAutoCountdown = ({clockKey}) => {
  const user = useSelector(selectUser);
  const location = useLocation();
  const [showCountdownModal, setShowCountdownModal] = useState(false);
  const [activeCountdowns, setActiveCountdowns] = useState([]);
  const [countdownSnapshot, setCountdownSnapshot] = useState({});
  const [currentWeek, setCurrentWeek] = useState(user.settings.multiWeek ? user.settings.currentWeek : "A");
  const [nextCountdownDisplay, setNextCountdownDisplay] = useState();
  const [timeDifferenceDisplay, setTimeDifferenceDisplay] = useState();
  const [currentSystemTimeDisplay, setCurrentSystemTimeDisplay] = useState();
  const [isFlashing, setIsFlashing] = useState(false);
  const [throttled, setThrottled] = useState(false); // State to track if the interval is being throttled
  const { elapsedTime } = usePersistentTimer(); // Reliable ticking timer

  // useEffect(() => {
  //   let lastRun = Date.now();
  //   let timeoutId;
  
  //   const checkAndTriggerLoop = () => {
  //     checkAndTrigger();
  //     const now = Date.now();
  //     const timeElapsed = now - lastRun;
  //     // console.log("timeElapsed => ", timeElapsed);
  
  //     // If more than 60,000ms (60 seconds) passed, throttling is likely happening
  //     if (timeElapsed > 2000) {
  //       setThrottled(true);
  //       // console.log("Throttling detected! Running every 60+ seconds.");
  //     } else {
  //       setThrottled(false);
  //     }
  
  //     // Log the current value of throttled state to verify its update
  //     setTimeout(() => {
  //       //console.log("throttled state value =>", throttled); // This will show the correct throttled state
  //     }, 0); // Ensure the state update has happened before logging it
  
  //     lastRun = now;
  //     timeoutId = setTimeout(checkAndTriggerLoop, 1000); // Re-run the loop every 1 second
  //   };
  
  //   checkAndTriggerLoop();
  
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [user, clockKey, throttled]); // Add `throttled` to the dependency array

  useEffect(() => {
      checkAndTrigger();
  }, [user, clockKey, elapsedTime]);

  const checkAndTrigger = async () => {
    const currentTime = new Date();
    setCurrentSystemTimeDisplay(formatCurrentAndStartTime(currentTime));

    const currentDayNumber = currentTime.getDay();
    let currentDay;

    if (user.settings.multiWeek && user.settings.cycleType === "day") {
      currentDay = "Monday";
    } else {
      switch (currentDayNumber) {
        case 1:
          currentDay = "Monday";
          break;
        case 2:
          currentDay = "Tuesday";
          break;
        case 3:
          currentDay = "Wednesday";
          break;
        case 4:
          currentDay = "Thursday";
          break;
        case 5:
          currentDay = "Friday";
          break;
        case 6:
          currentDay = "Saturday";
          break;
        case 0:
          currentDay = "Sunday";
          break;
        default:
          currentDay = "";
          break;
      }
    }

    const processCountdowns = async () => {
      const countdowns = user && user.countdownTimers
        ? await Promise.all(
            user.countdownTimers
              .filter(countdownMap => countdownMap.active && countdownMap?.schedule?.[currentWeek || "A"]?.[currentDay]?.active)
              .map(async countdownMap => {
                if (countdownMap && countdownMap.schedule[currentWeek || "A"]?.[currentDay]) {
                  const startTimeString = countdownMap.schedule[currentWeek || "A"][currentDay].startTime || "0:00";
                  const [startHours, startMinutes, startSeconds] = startTimeString.split(':');
                  const parsedStartTime = new Date();
                  parsedStartTime.setHours(startHours);
                  parsedStartTime.setMinutes(startMinutes);
                  if (startSeconds) {
                    parsedStartTime.setSeconds(startSeconds);
                  } else {
                    parsedStartTime.setSeconds(0);
                  }

                  // console.log("parsedStartTime => ", parsedStartTime); 

                  let bufferAddition = 10;
                  if (countdownMap.youtube === true) {
                    bufferAddition = 500;
                  } else if (countdownMap.alertSound && countdownMap.alertSound.startsWith('http')) {
                    bufferAddition = await calculateBufferAddition(countdownMap.alertSound);
                  } else {
                    if (countdownMap.alertSound === "long") bufferAddition = 18;
                    if (countdownMap.alertSound === "medium") bufferAddition = 11;
                    if (countdownMap.alertSound === "short") bufferAddition = 8;
                    if (countdownMap.alertSound === "none") bufferAddition = 11;
                  }

                  return {
                    startTime: parsedStartTime,
                    duration: countdownMap.duration,
                    header: countdownMap.header,
                    alertMessage: countdownMap.alertMessage,
                    alertSound: countdownMap.alertSound,
                    alertSoundName: countdownMap.alertSoundName,
                    youtube: countdownMap.youtube,
                    youtubeSrc: countdownMap.youtubeSrc,
                    active: countdownMap.active,
                    buffer: (parseFloat(countdownMap.duration) + bufferAddition) * 1000,
                    modalClassName: countdownMap.modalClassName,
                    backdrop: countdownMap.backdrop,
                    flash: countdownMap.youtube ? false : countdownMap.flash || false,
                  };
                } else {
                  return {}; // Return an empty object or handle the null case as needed
                }
              })
          )
        : [];

      const filteredCountdowns = countdowns.filter(countdown => {
        const countdownTime = countdown?.startTime?.getTime();
        const currentTimeTime = currentTime.getTime();
        return countdownTime >= currentTimeTime || currentTimeTime - countdownTime <= 3000;
      });

      filteredCountdowns.sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

      setActiveCountdowns(filteredCountdowns);

      if (filteredCountdowns.length > 0) {
        const nextCountdown = filteredCountdowns[0];
        setNextCountdownDisplay(nextCountdown);
        const timeDifference = nextCountdown.startTime.getTime() - currentTime.getTime();
        setTimeDifferenceDisplay(timeDifference);
        // console.log("timeDifference => ", timeDifference);

         // If throttled, expand the window to 3000ms (3 seconds)
        const triggerWindow = throttled ? 3000 : 1000;
        // console.log("throttled => ", throttled);

        if (timeDifference <= 0 && timeDifference >= -triggerWindow) {
          // console.log("HERE")
          setCountdownSnapshot(nextCountdown);
          setShowCountdownModal(true);
          setTimeout(() => {
            setShowCountdownModal(false);
            setIsFlashing(false);
          }, nextCountdown.buffer - Math.abs(timeDifference));
        }
      }
    };

    processCountdowns();
  };

  const fetchAudioDuration = (url) => {
    return new Promise((resolve, reject) => {
      const audio = new Audio(url);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = (error) => {
        reject(error);
      };
    });
  };

  const calculateBufferAddition = async (alertSound) => {
    let bufferAddition = 10;
    if (alertSound === "none") return 3;

    try {
      const duration = await fetchAudioDuration(alertSound);
      return duration + 3; // Adding 3 seconds to the duration
    } catch (error) {
      console.error('Error fetching audio duration:', error);
      return bufferAddition;
    }
  };

  const handleCountdownComplete = () => {
    if (countdownSnapshot.flash) {
      setIsFlashing(true);
    }
  };

  const toggle = () => {
    setShowCountdownModal(!showCountdownModal);
  };

  const formatTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatCurrentAndStartTime = (currentTime) => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
  };

  return (
    <>
      {location.pathname === "/auto-status" ? (
        <Card>
          <CardHeader className='h3 mb-0'>Auto Countdown</CardHeader>
          <CardBody>
            <b>Next Countdown:</b> {nextCountdownDisplay && nextCountdownDisplay.header || "No countdowns scheduled for the rest of the day."}
            <br></br>
            <b>Next Countdown Start Time:</b> {nextCountdownDisplay && formatCurrentAndStartTime(nextCountdownDisplay.startTime) || "No countdowns scheduled for the rest of the day."}
            <br></br>
            <b>Current System Time:</b> {currentSystemTimeDisplay}
            <br></br>
            <b>Time Until Triggered:</b> {timeDifferenceDisplay && formatTime(timeDifferenceDisplay) || "No countdowns scheduled for the rest of the day."}
          </CardBody>
        </Card>
      ) : (
        showCountdownModal && (
          <Modal isOpen={showCountdownModal} toggle={toggle} modalClassName={countdownSnapshot.modalClassName || null} backdrop={countdownSnapshot.backdrop || false}>
            <ModalBody style={{padding: '10px 25px'}}>
              <CountdownTimer props={countdownSnapshot} onComplete={handleCountdownComplete} modalClassName={countdownSnapshot.modalClassName} backdrop={countdownSnapshot.backdrop}/>
              <div style={{display: 'flex', alignContent: 'center', justifyContent: 'center', margin: '0px', padding: '0px'}}>
                <Button color="primary" onClick={() => toggle()} style={{ marginTop: '10px', marginBottom: '0px', borderRadius: '30px' }}>
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )
      )}
      {isFlashing && <div className="flash-effect" style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1045}}></div>}
    </>
  );
};

export default UseAutoCountdown;

import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectUser, fetchUserData } from "features/userSlice";
import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Switch from "react-bootstrap-switch";
import { FIREBASE_COLLECTIONS } from "../../config/firebaseCollections";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  Badge,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardSubtitle
} from "reactstrap";

function AutoHappyBirthdayTable({props}) {
    const user = useSelector(selectUser);
    const dispatch = useDispatch()
    const classes = useSelector((state) => state.user.classes);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [classIdToEdit, setClassIdToEdit] = useState(null);
    const [formState, setFormState] = useState({
        active: false,
        launchTime: "",
        background: "",
        sound: "",
        close: ""
    });

    const toggleEditModal = (classData = null, classId) => {
        if (classData) {
            setClassIdToEdit(classId);
            setFormState({
                active: classData.autoHappyBirthday?.active || false,
                launchTime: classData.autoHappyBirthday?.launchTime || 0,
                background: classData.autoHappyBirthday?.background || "",
                sound: classData.autoHappyBirthday?.sound || "",
                close: classData.autoHappyBirthday?.close || 30
            });
            setSelectedClass(classData);
        }
        setShowEditModal(!showEditModal);
    }

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormState({
            ...formState,
            [id]: value
        });
    }

    const handleSwitchChange = (id, value) => {
        setFormState({
            ...formState,
            [id]: value
        });
    }

  //   const handleSubmit = async () => {
  //     if (selectedClass) {
  //         try {
  //             const docRef = doc(db, "users", user.uid);
  
  //             // Create a deep copy of the user object
  //             const userCopy = JSON.parse(JSON.stringify(user));
  
  //             // Find the class index based on classId
  //             const classIndex = userCopy.classes.findIndex(
  //                 (c) => c.classId === classIdToEdit
  //             );
  
  //             if (classIndex !== -1) {
  //                 // Update the autoHappyBirthday field with the new form data
  //                 userCopy.classes[classIndex].autoHappyBirthday = {
  //                     ...formState
  //                 };
  
  //                 // Update the document in Firestore with the modified userCopy
  //                 await setDoc(docRef, userCopy);
  
  //                 // Optionally, close the modal after saving
  //                 toggleEditModal();
  //                 setClassIdToEdit(null);
  //             }
  //         } catch (error) {
  //             console.error("Error updating document: ", error);
  //         }
  //     }
  // };  

  const handleSubmit = async () => {
    if (selectedClass && user?.uid && classIdToEdit) {
        try {
            // Reference the specific class document in the Firestore subcollection
            const classRef = doc(
                db,
                FIREBASE_COLLECTIONS.USERS,
                user.uid,
                FIREBASE_COLLECTIONS.CLASSES,
                classIdToEdit
            );

            // Update only the `autoHappyBirthday` field without affecting other data
            await updateDoc(classRef, {
                autoHappyBirthday: { ...formState },
            });

            await dispatch(fetchUserData(user.uid));

            // Optionally, close the modal after saving
            toggleEditModal();
            setClassIdToEdit(null);

            console.log(`✅ autoHappyBirthday updated for class: ${classIdToEdit}`);
        } catch (error) {
            console.error("❌ Error updating class document:", error);
        }
    } else {
        console.error("❌ Missing required data: selectedClass, user ID, or class ID.");
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <h2 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>Auto Happy Birthday</h2>
              </CardHeader>
              <CardSubtitle style={{textAlign: 'center', padding: '0px 20px'}}>
                Launch a Happy Birthday message for your students on their birthday or the nearest class after their birthday. 
                  You must have entered student's birthdays in the roster for a class for Auto Happy Birthday to work. 
                  To enter student birthdays click the Roster button in the Classes table above.
              </CardSubtitle>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center" style={{width: '22%'}}>Class</th>
                      <th className="text-center" id="birthday-table-j34820asioa" style={{width: '9%'}}>Roster Data</th>
                      <th className="text-center" id="birthday-table-basdf098bqw" style={{width: '9%'}}>On/Off</th>
                      <th className="text-center" id="birthday-table-as9d8ifiq0b" style={{width: '14%'}}>Launch Time</th>
                      <th className="text-center" id="birthday-table-i94i8nb9eeq" style={{width: '14%'}}>Background</th>
                      <th className="text-center" id="birthday-table-s2234insidv" style={{width: '14%'}}>Sound</th>
                      <th className="text-center" id="birthday-table-kslwir90909" style={{width: '9%'}}>Close</th>
                      <th className="text-center" style={{width: '9%'}}>Edit</th>
                      <UncontrolledTooltip delay={0} target={"birthday-table-j34820asioa"}>Have you entered birthdays in your roster for this class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"birthday-table-basdf098bqw"}>Do you want to use Auto-Happy Birthday for this class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"birthday-table-as9d8ifiq0b"}>When should the Happy Birthday popup launch during your class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"birthday-table-i94i8nb9eeq"}>Coming Soon...</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"birthday-table-s2234insidv"}>Coming Soon...</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"birthday-table-kslwir90909"}>The amount of time the popup will appear on screen before closing.</UncontrolledTooltip>
                    </tr>
                  </thead>
                  {user && classes?.length > 0 ? classes.map((classMap, index) => {
                    return(
                      <tbody key={index}>
                        <tr>
                          <td className="text-center" >{classMap.className}</td>
                          <td className="text-center">{classMap.rosterData?.roster?.some(student => student.birthday) ? <Badge className="button-success">Yes</Badge> : <Badge className="button-delete">No</Badge>}</td>                          
                          <td className="text-center" >{classMap.autoHappyBirthday?.active ? <Badge className="button-success">On</Badge> : <Badge className="button-delete">Off</Badge> }</td>
                          <td className="text-center" >
                            {classMap.autoHappyBirthday?.launchTime === 0
                              ? "At start"
                              : classMap.autoHappyBirthday?.launchTime
                              ? `After ${classMap.autoHappyBirthday?.launchTime} seconds`
                              : "--"
                            }
                          </td>
                          <td className="text-center">{classMap.autoHappyBirthday?.background || "--"}</td>
                          <td className="text-center">{classMap.autoHappyBirthday?.sound || "--"}</td>
                          <td className="text-center">
                            {classMap.autoHappyBirthday?.close === "manual"
                              ? "Manual"
                              : classMap.autoHappyBirthday?.close
                              ? `After ${classMap.autoHappyBirthday?.close} seconds`
                              : "--"
                            }
                          </td>                          
                          <td className="text-center">
                            <Button
                              className="btn-icon btn-neutral"
                              id="birthday-table-230923510293"
                              color="info"
                              size="md"
                              type="button"
                              onClick={() => toggleEditModal(classMap, classMap.classId)}
                              onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.12)")}
                              onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
                            >
                              <i className="fa fa-edit" />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    )}) : <p>You must have at least one class to use this feature.</p>}
                </Table>
              </CardBody>
            </Card>
          </Col>         
        </Row>
      </div>

      <Modal isOpen={showEditModal} toggle={toggleEditModal} className="edit-auto-happy-birthday-modal">
        <ModalHeader toggle={toggleEditModal}>
          <h5 style={{ fontWeight: "bold", color: "#004aad", margin: "0px" }}>
            Edit Auto Happy Birthday for: <div>{classes?.find(cls => cls.classId === classIdToEdit)?.className}</div>
          </h5>
        </ModalHeader>
        <ModalBody>
        {(() => {
          const selectedClass = classes?.find(cls => cls.classId === classIdToEdit);
          
          // Check if there are no birthdays in the roster
          if (selectedClass && !selectedClass.rosterData?.roster?.some(student => student.birthday)) {
            return (
              <div className="alert alert-warning" role="alert" style={{borderRadius: '8px', backgroundColor: '#fa5656'}}>
                Notice: This class does not contain any birthdays in its roster. Without any birthdays entered, Auto Happy Birthday will not work. You can edit a class's roster by clicking the Roster button in the classes table.
              </div>
            );
          }
          return null;
        })()}
          <Row>
            <Col md="2">
            <label style={{ fontSize: '14px', fontWeight: "bold"}}>On/Off</label>
              <div>
                <Switch
                  id="active"
                  onChange={(el, state) => handleSwitchChange('active', state)}
                  value={formState.active}
                  onText="On"
                  offText="Off"
                />
              </div>
            </Col>
            <Col md="5">
            <label style={{ fontSize: '14px', fontWeight: "bold"}}>Launch Time</label>
              <Input
                type="select"
                id="launchTime"
                value={formState.launchTime}
                onChange={handleInputChange}
                style={{borderRadius: '8px'}}
              >
                <option value="0">At class start</option>
                <option value="5">5 minutes after class start</option>
                <option value="10">10 minutes after class start</option>
                <option value="15">15 minutes after class start</option>
                <option value="20">20 minutes after class start</option>
                <option value="25">25 minutes after class start</option>
                <option value="30">30 minutes after class start</option>
                <option value="random">Random</option>
              </Input>
            </Col>
            <Col md="5">
            <label style={{ fontSize: '14px', fontWeight: "bold"}}>Close</label>
              <Input
                type="select"
                id="close"
                value={formState.close}
                onChange={handleInputChange}
                style={{borderRadius: '8px'}}
              >
                <option value="manual">Manual</option>
                <option value="20">Show for 20 Seconds</option>
                <option value="30">Show for 30 Seconds</option>
                <option value="40">Show for 40 Seconds</option>
                <option value="50">Show for 50 Seconds</option>
                <option value="60">Show for 60 Seconds</option>
              </Input>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col>
              <Label disabled for="background" id="i94i8nb9eeq">Background Choice (Coming Soon)</Label>
              <Input
                id="background"
                value={formState.background}
                onChange={handleInputChange}
                disabled
                style={{borderRadius: '8px'}}
              />
            </Col>
            <Col>
              <Label disabled for="sound" id="s2234insidv">Sound (Coming Soon)</Label>
              <Input
                id="sound"
                value={formState.sound}
                onChange={handleInputChange}
                disabled
                style={{borderRadius: '8px'}}
              />
            </Col>
          </Row>
        </ModalBody>
        <div className="m-2" style={{textAlign: 'center'}}>
          <Button
            className="button-hover-fix"
            onClick={handleSubmit}
            style={{
                borderRadius: '30px',
                width: '100px'
            }}
          >
            <i className='fa fa-save' />
            {' '}Save            
          </Button>
        </div>
        {/* <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Save</Button>
          <Button color="secondary" onClick={() => (toggleEditModal() && setClassIdToEdit(null))}>Cancel</Button>
        </ModalFooter> */}
      </Modal>
    </>
  );
}

export default AutoHappyBirthdayTable;

import React, { useEffect, useState } from "react";
import axios from "axios";

const StripeData = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchChurnedCustomers = async () => {
            try {
                const response = await axios.get("http://localhost:5001/api/stripe/churned-customers"); // Your backend endpoint
                const sortedCustomers = response.data.sort((a, b) => new Date(b.canceledAt) - new Date(a.canceledAt));
                setCustomers(sortedCustomers);
                setLoading(false);
            } catch (err) {
                setError("Failed to load churned customers.");
                setLoading(false);
            }
        };

        fetchChurnedCustomers();
    }, []);

    if (loading) return <p>Loading churned customers...</p>;
    if (error) return <p style={{ color: "red" }}>{error}</p>;

    return (
        <div style={{ padding: "20px" }}>
            <h2>Churned Customers</h2>
            <table style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}>
                <thead>
                    <tr style={{ backgroundColor: "#004aad", color: "white" }}>
                        <th style={{ padding: "10px", textAlign: "left" }}>Name</th>
                        <th style={{ padding: "10px", textAlign: "left" }}>Email</th>
                        <th style={{ padding: "10px", textAlign: "left" }}>Canceled Date</th>
                    </tr>
                </thead>
                <tbody>
                    {customers.map((customer) => (
                        <tr key={customer.id} style={{ borderBottom: "1px solid #ddd" }}>
                            <td style={{ padding: "10px" }}>{customer.name || "N/A"}</td>
                            <td style={{ padding: "10px" }}>{customer.email || "N/A"}</td>
                            <td style={{ padding: "10px" }}>{new Date(customer.canceledAt).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StripeData;
